import { createRoot } from "react-dom/client";
import { Auth } from "@aws-amplify/auth";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/700.css";
import "./index.css";

import config from "./config";
import { initialiseAnalytics } from "./libs/amplitudeAnalytics";
import App from "./containers/App";
import GlobalInteractionProvider from "./providers/GlobalInteraction";
import OpenCloseStackProvider from "./providers/OpenCloseStack";
import DragDropProvider from "./providers/DragDrop";
import FileDropStackProvider from "./providers/FileDropStack";
import SnackBoxProvider from "./providers/SnackBox";

if (process.env.REACT_APP_STAGE === "prod") {
  initialiseAnalytics();
}

Auth.configure({
  mandatorySignIn: true,
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
});

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <GlobalInteractionProvider>
        <OpenCloseStackProvider>
          <DragDropProvider>
            <FileDropStackProvider>
              <SnackBoxProvider>
                <App />
                <div id="portal-container"></div>
              </SnackBoxProvider>
            </FileDropStackProvider>
          </DragDropProvider>
        </OpenCloseStackProvider>
      </GlobalInteractionProvider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
