import { nanoid } from "nanoid";
import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";

import { mergeClassNames } from "../../../libs/components";

export interface OnChangeProps {
  empty: boolean;
  complete: boolean;
}

interface CreditCardInputProps {
  onChange: (props: OnChangeProps) => void;
  onError?: (message: string | null) => void;
  onBlur?: () => void;
  labelText: string;
  hideLabel?: boolean;
  labelClassName?: string;
  id?: string;
  disabled?: boolean;
}

const CreditCardInput: React.FC<CreditCardInputProps> = ({
  onChange,
  onError,
  onBlur,
  labelText,
  hideLabel = false,
  labelClassName = "",
  id = nanoid(),
  disabled = false,
}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      <label
        className={mergeClassNames(
          `font-bold block mb-2 default-transition ${
            error ? "text-red-500" : ""
          } ${hideLabel ? "sr-only" : ""}`,
          labelClassName
        )}
        htmlFor={id}
      >
        {labelText}
      </label>
      <div
        className={`h-12 w-full default-transition ${
          ready ? "opacity-100" : "opacity-0"
        }`}
      >
        <CardElement
          id={id}
          onChange={(e) => {
            if (onError) {
              onError(e.error ? e.error.message : null);
            }

            setError(!!e.error);
            onChange({ empty: e.empty, complete: e.complete });
          }}
          onBlur={onBlur}
          onReady={() => setReady(true)}
          options={{
            classes: {
              base: "px-2 py-3 border-2 rounded-lg block h-12 w-full bg-transparent placeholder-gray-500 border-gray-300 default-transition",
              focus: "border-purple-500",
              invalid: "border-red-500",
            },
            style: {
              base: {
                fontSize: "16px",
              },
            },
            disabled,
          }}
        />
      </div>
    </>
  );
};

export default CreditCardInput;
