export function getImageDimensions(imageUrl: string) {
  return new Promise<[number, number]>((resolve, reject) => {
    try {
      const image = new Image();

      image.onload = () => {
        resolve([image.naturalWidth, image.naturalHeight]);
      };
      image.src = imageUrl;
    } catch (e) {
      reject(`Failed to load image ${imageUrl}`);
    }
  });
}
