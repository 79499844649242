import React, { useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { Send as SendIcon } from "react-feather";

import { useForm } from "react-hook-form";

import NewPasswordContainer from "../containers/NewPassword";
import { useAsyncState } from "../libs/hooks/general";
import H1 from "../components/headings/H1";
import InternalLink from "../components/links/Internal";
import SignupContainer from "../components/signup/Container";
import FormInput from "../components/form/Input";
import PrimaryButton from "../components/buttons/Primary";
import LinkButton from "../components/buttons/Link";
import Divider from "../components/Divider";

type FormValues = {
  email: string;
};

const formSchema = Joi.object({
  email: Joi.string().required().email({ tlds: false }),
});

const ForgotPasswordPage: React.FC = () => {
  const [emailSentTo, setEmailSentTo] = React.useState<string | null>(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    mode: "onSubmit",
    criteriaMode: "all",
    resolver: joiResolver(formSchema),
  });

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const { loading, loadingText, setLoading, error, setError, clearError } =
    useAsyncState();

  const onSubmit = async ({ email: rawEmail }: FormValues) => {
    const email = rawEmail.toLowerCase();
    setLoading(true);
    clearError();

    try {
      await Auth.forgotPassword(email);
      setLoading(false);
      setEmailSentTo(email);
    } catch (e: any) {
      setLoading(false);
      setEmailSentTo(null);
      if (e.code === "UserNotFoundException") {
        setError({
          title: "Have we met yet?",
          message: (
            <>
              Sorry, we don't recognise that email but{" "}
              <InternalLink className="alert-link" to="/signup">
                we'd love to get to know you better.
              </InternalLink>
            </>
          ),
        });
      } else {
        setLoading(false);
        setError({
          title: "Something went wrong",
          message: (
            <>
              This never usually happens. Honest. Please try again.
              <br />
              {e.message}
            </>
          ),
        });
      }
    }

    return true;
  };

  return (
    <SignupContainer>
      <div style={{ width: "576px", zIndex: 1 }}>
        {!emailSentTo && (
          <>
            <H1 className="my-4">Reset your password</H1>
            <p className="mb-6">
              Just let us know the email you use to sign in to Seenly and we’ll
              help you get your password back.
              <br />
              Already have an account?{" "}
              <InternalLink to="/login" id="sign-up-login">
                Log in
              </InternalLink>
            </p>
          </>
        )}
        {emailSentTo && (
          <>
            <H1 className="my-4 flex items-center">
              Email Sent <SendIcon className="ml-3" height={34} width={34} />
            </H1>
            <p className="mb-6">
              In your inbox you'll find an email with the instructions on how to
              reset your password.
            </p>
          </>
        )}
        {error()}
        <form className="pt-1" onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            {...register("email")}
            labelText="Email"
            labelClassName="mt-4"
            type="text"
            error={!!errors.email}
          />
          {!emailSentTo && (
            <PrimaryButton
              className="mt-8 w-full text-xl text-white"
              type="submit"
              disabled={loading}
              id="send-reset-email"
            >
              {loadingText({
                loading: "Sending reset email...",
                default: "Send Password Reset Email!",
              })}
            </PrimaryButton>
          )}
          {emailSentTo && (
            <LinkButton
              type="submit"
              disabled={loading}
              id="resend-reset-code"
              className="mt-1 ml-auto"
            >
              {loadingText({
                loading: "Sending reset email...",
                default: "Click here to resend a new code",
              })}
            </LinkButton>
          )}
        </form>

        {emailSentTo && (
          <div className="mt-6 ml-0">
            <Divider direction="horizontal" className="ml-0" />
            <NewPasswordContainer email={emailSentTo} />
          </div>
        )}
      </div>
    </SignupContainer>
  );
};

export default ForgotPasswordPage;
