import { nanoid } from "nanoid";
import React, {
  createRef,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import SnackBoxContext, {
  SnackBoxItem,
  ContentStackContextProps,
} from "../contexts/SnackBox";

import SnackBar from "../components/SnackBar";

interface SnackBoxContextProviderProps {
  children: ReactNode;
}
const SnackBoxContextProvider: React.FC<SnackBoxContextProviderProps> = ({
  children,
}) => {
  const [items, setItems] = useState<SnackBoxItem[]>([]);

  const create: ContentStackContextProps["create"] = useCallback(
    ({ content, dismissTrigger, userDismissable }) => {
      const id = nanoid();

      setItems((prevItems) => {
        const newItems = prevItems.slice();
        const ref = createRef<HTMLDivElement>();
        newItems.push({
          id,
          ref,
          snackBarElement: (
            <SnackBar
              ref={ref}
              dismissTrigger={dismissTrigger}
              userDismissable={userDismissable}
              dismiss={() => {
                setItems((prevItems) => {
                  return prevItems.filter((item) => item.id !== id);
                });
              }}
            >
              {content}
            </SnackBar>
          ),
        });
        return newItems;
      });
    },
    []
  );

  const contextValue = useMemo(() => ({ items, create }), [create, items]);

  return (
    <SnackBoxContext.Provider value={contextValue}>
      {children}
    </SnackBoxContext.Provider>
  );
};

export default SnackBoxContextProvider;
