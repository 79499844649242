import React from "react";
import { CollectionIcon } from "@heroicons/react/outline";

import { mergeClassNames } from "../../libs/components";

interface SmartScheduleIconProps {
  fill?: boolean;
  fillColour?: string;
  className?: string;
}
const SmartScheduleIcon: React.FC<SmartScheduleIconProps> = ({
  fill = false,
  fillColour = "bg-purple-500",
  className = "",
}) => {
  return (
    <div className="relative">
      {fill && (
        <div
          className={`w-1/4 h-1/2 absolute top-1/2 left-1/2 -translate-x-full -translate-y-1/2 opacity-40 ${fillColour}`}
        ></div>
      )}
      <CollectionIcon
        className={mergeClassNames("h-6 w-6 -rotate-90", className)}
        fill={fill ? "currentColor" : "none"}
        fillOpacity={0.4}
      />
    </div>
  );
};

export default SmartScheduleIcon;
