import React, { ComponentProps, useMemo } from "react";
import {
  ExternalLink as ExternalLinkIcon,
  Star as StarIcon,
} from "react-feather";

import { Post } from "../../types/posts";
import { Platform } from "../../types/platforms";

import config from "../../config";
import { useActiveWorkspace, useShowModal } from "../../libs/hooks/app";
import { usePostAnalytics } from "../../libs/hooks/postAnalytics";
import { getPlatformTypeName } from "../../libs/platform";
import { getMetricsForPost } from "../../libs/postAnalytics";

import Modal from "../../components/Modal";
import PostPreview from "../../components/posts/Preview";
import H2 from "../../components/headings/H2";
import ExternalLink from "../../components/links/External";
import PlatformIcon from "../../components/icons/Platform";
import PrimaryButton from "../../components/buttons/Primary";
import DailySnapshot from "../../components/analytics/DailySnapshot";
import DailySnapshotLoading from "../../components/analytics/DailySnapshotLoading";
import OverTime from "../../components/analytics/OverTime";
import OverTimeLoading from "../../components/analytics/OverTimeLoading";
import InteractionsHeatMap from "../../components/analytics/InteractionsHeatMap";
import InteractionsHeatMapLoading from "../../components/analytics/InteractionsHeatMapLoading";
import CommentsSentiment from "../../components/analytics/CommentsSentiment";
import CommentsSentimentLoading from "../../components/analytics/CommentsSentimentLoading";

import { ReactComponent as AnalyticsImage } from "../../images/analytics.svg";

import Overview from "./viewPost/Overview";
import Details from "./viewPost/Details";

interface ViewPostModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  post: Post;
  platform: Platform;
  close: () => void;
}

const ViewPostModal: React.FC<ViewPostModalProps> = ({
  post,
  platform,
  ...modalProps
}) => {
  const showModal = useShowModal();
  const workspace = useActiveWorkspace();
  const hasSubscription = !!workspace.subscription;
  const hasNeverHadSubscription = !workspace.hasHadTrial && !hasSubscription;
  const { data: postAnalytics, isLoading: isLoadingPostAnalytics } =
    usePostAnalytics(post.id, {
      enabled: hasSubscription,
    });
  const platformEntity = platform.entities[post.platformEntityId];
  const canDisplayAnalytics = isLoadingPostAnalytics || !!postAnalytics;
  const metricPropertyNames = useMemo(() => {
    return getMetricsForPost(post, platformEntity.type);
  }, [post, platformEntity.type]);

  return (
    <Modal {...modalProps}>
      <div className="px-2 pb-2 pt-1 sm:px-8 sm:pb-8 sm:pt-6 flex flex-wrap gap-4 bg-gray-50 grow">
        <Details className="shrink-0" post={post} platform={platform} />
        <div className="mx-auto">
          <div className="flex items-center">
            <PlatformIcon type={platform.type} />
            <H2 className="ml-1 text-xl text-gray-500">Preview</H2>
            {post.externalUrl && (
              <ExternalLink
                className="ml-auto mr-6 flex items-center"
                href={post.externalUrl}
              >
                <ExternalLinkIcon className="h-4 w-4" />
                <span className="ml-2">
                  View on {getPlatformTypeName(platform.type)}
                </span>
              </ExternalLink>
            )}
          </div>
          <PostPreview
            className="mt-2 mx-auto"
            post={post}
            platformEntity={platformEntity}
          />
        </div>
      </div>

      {hasSubscription ? (
        <div
          className="px-2 pb-2 pt-1 sm:px-8 sm:pb-8 sm:pt-6 grow"
          style={{ maxWidth: "66rem" }}
        >
          <div>
            <H2 className="text-normal uppercase text-gray-400">Overview</H2>
            <Overview
              className="mt-2"
              metricPropertyNames={metricPropertyNames}
              post={post}
              analytics={postAnalytics}
            />
          </div>
          {canDisplayAnalytics && (
            <div className="mt-12">
              <H2 className="text-normal uppercase text-gray-400">
                Daily snapshot
              </H2>
              {isLoadingPostAnalytics ? (
                <DailySnapshotLoading
                  className="mt-2"
                  metricPropertyNames={metricPropertyNames}
                />
              ) : (
                <DailySnapshot
                  className="mt-2"
                  metricPropertyNames={metricPropertyNames}
                  platformType={postAnalytics!.type}
                  utcOffset={postAnalytics!.utcOffset}
                  periodStart={postAnalytics!.periodStart}
                  byDayOffset={postAnalytics!.byDayOffset}
                />
              )}
            </div>
          )}
          {canDisplayAnalytics && (
            <div className="mt-12">
              <H2 className="text-normal uppercase text-gray-400">Over time</H2>
              {isLoadingPostAnalytics ? (
                <OverTimeLoading className="mt-2" />
              ) : (
                <OverTime
                  className="mt-2"
                  metricPropertyNames={metricPropertyNames}
                  platformType={postAnalytics!.type}
                  utcOffset={postAnalytics!.utcOffset}
                  periodStart={postAnalytics!.periodStart}
                  byDayOffset={postAnalytics!.byDayOffset}
                />
              )}
            </div>
          )}
          {canDisplayAnalytics && (
            <div className="mt-12">
              <H2 className="text-normal uppercase text-gray-400">
                Interactions heat map
              </H2>

              <div className="mt-2" style={{ width: 0, minWidth: "100%" }}>
                {isLoadingPostAnalytics ? (
                  <InteractionsHeatMapLoading />
                ) : (
                  <InteractionsHeatMap
                    interactions={postAnalytics!.interactions}
                  />
                )}
              </div>
            </div>
          )}
          {canDisplayAnalytics && (
            <div className="mt-12">
              <H2 className="text-normal uppercase text-gray-400">
                Comments sentiment
              </H2>

              {isLoadingPostAnalytics ? (
                <CommentsSentimentLoading className="mt-2" />
              ) : (
                <CommentsSentiment
                  className="mt-2"
                  comments={postAnalytics!.comments}
                />
              )}
            </div>
          )}
          {!canDisplayAnalytics && (
            <div className="mt-12 flex flex-col items-center">
              <AnalyticsImage
                className="flex-shrink-0 text-purple-500"
                style={{ width: 300, height: 300 }}
              />
              <H2>Detailed analytics unavailable</H2>
            </div>
          )}
        </div>
      ) : (
        <div className="px-2 pb-2 pt-1 sm:px-8 sm:pb-8 sm:pt-6 grow">
          <div className="relative flex flex-col items-center">
            <div style={{ width: 600 }}>
              <H2>Interested in the numbers?</H2>
              <p className="mt-6">
                If you're a numbers person and would like to see the{" "}
                <strong>analytics</strong> for your post's performance over time
                then we've got you covered!
              </p>
              {hasNeverHadSubscription ? (
                <p className="mt-4">
                  Try our{" "}
                  <strong>
                    {config.prices.PUBLISH_MONTHLY.displayName} plan
                  </strong>{" "}
                  for{" "}
                  <strong>
                    free for {config.DEFAULT_TRIAL_PERIOD_DAYS} days
                  </strong>{" "}
                  (no credit card required) to see the analytics for your posts.
                </p>
              ) : (
                <p className="mt-4">
                  Upgrade to our{" "}
                  <strong>
                    {config.prices.PUBLISH_MONTHLY.displayName} plan
                  </strong>{" "}
                  to see the analytics for your posts.
                </p>
              )}
            </div>
            <PrimaryButton
              className="mt-10"
              onClick={() =>
                showModal("changeSubscription", {
                  workspace,
                  redirectOnSuccess: false,
                  defaultPriceId: config.prices.PUBLISH_MONTHLY.id,
                })
              }
            >
              <StarIcon className="mr-2" />
              {hasNeverHadSubscription
                ? `Start your ${config.DEFAULT_TRIAL_PERIOD_DAYS}-day free trial`
                : `Upgrade to our ${config.prices.PUBLISH_MONTHLY.displayName} plan to see anlytics`}
            </PrimaryButton>
            {hasNeverHadSubscription && (
              <p className="mt-2">No credit card required!</p>
            )}
            <AnalyticsImage
              className="mt-12 flex-shrink-0 text-purple-500"
              style={{ width: 400, height: 400 }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ViewPostModal;
