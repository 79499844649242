import Amplitude from "amplitude-js";

import config from "../config";

export async function initialiseAnalytics() {
  if (process.env.REACT_APP_STAGE === "prod") {
    Amplitude.getInstance().init(config.AMPLITUDE_ID);
  }
}

export interface logEventParams {
  event: string;
  userId?: string | null;
  title?: string;
  id?: string;
  text?: string;
  url?: string;
  urlParams?: string;
}

export async function analyticsLogEvent({
  event,
  userId,
  title,
  id,
  text,
  url,
  urlParams,
}: logEventParams): Promise<any> {
  if (process.env.REACT_APP_STAGE === "prod") {
    if (userId !== undefined) {
      Amplitude.setUserId(userId);
    }

    Amplitude.getInstance().logEvent(event, {
      title,
      id,
      text,
      url,
      urlParams,
    });
  }
}
