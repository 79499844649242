import React from "react";
import { CogIcon } from "@heroicons/react/outline";

import { PostQueue } from "../../../types/postQueues";
import { UpdatePostQueueProps } from "../../../libs/hooks/postQueues";

import DropdownMenu from "../../../components/menus/Dropdown";
import TertiaryButton from "../../../components/buttons/Tertiary";
import ContentStack from "../../../components/animations/ContentStack";
import LoaderIcon from "../../../components/icons/Loader";

import Home from "./settings/Home";
import ChangePostFrequency from "./settings/ChangePostFrequency";

interface SmartSchedulesQueueSettingsProps {
  postQueue: PostQueue;
  updatePostQueue: (
    updateProps: UpdatePostQueueProps["updateProps"]
  ) => Promise<any>;
  isUpdating: boolean;
  className?: string;
}
const SmartSchedulesQueueSettings: React.FC<
  SmartSchedulesQueueSettingsProps
> = ({ postQueue, updatePostQueue, isUpdating, className = "" }) => {
  return (
    <DropdownMenu className={className} xAlign="right">
      <TertiaryButton className="bg-transparent" size="sm">
        {isUpdating ? (
          <LoaderIcon className="mr-1 h-5 w-5" />
        ) : (
          <CogIcon className="mr-1 h-5 w-5" />
        )}{" "}
        Settings
      </TertiaryButton>
      <div className="w-80">
        <ContentStack defaultContentId="home">
          <Home
            contentId="home"
            postQueue={postQueue}
            updatePostQueue={updatePostQueue}
            isUpdating={isUpdating}
          />
          <ChangePostFrequency
            contentId="changePostFrequency"
            postQueue={postQueue}
            updatePostQueue={updatePostQueue}
            isUpdating={isUpdating}
          />
        </ContentStack>
      </div>
    </DropdownMenu>
  );
};

export default SmartSchedulesQueueSettings;
