import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  UserPreferences,
  UserPreferencesUpdateProps,
  WorkspaceUserPreferencesUpdateProps,
} from "../../types/userPreferences";
import { ApiResponse } from "../../types/general";

import { useDecoratedReactQuery } from "./general";
import { useApiClient } from "./app";
import { USER_PREFERENCES_QUERY_KEYS } from "../queryKeys";
import { patchObject } from "../utils";
import { optimisticallyUpdateQueryCacheObject } from "../queryCache";

const DEFAULT_DAYS_PER_WEEK = 7;
const DEFAULT_DAY_STARTS_AT = 8;
const DEFAULT_SHOW_HOURS = 12;

export const useUserPreferences = (userId: string) => {
  const apiClient = useApiClient();
  return useQuery(USER_PREFERENCES_QUERY_KEYS.detail(userId), async () => {
    const { data: response } = await apiClient.get<
      ApiResponse<UserPreferences>
    >(`/user_preferences/${userId}`);
    return response.data;
  });
};

export const useWorkspaceUserPreferences = (
  userId: string,
  workspaceId: string
) => {
  const apiClient = useApiClient();
  return useQuery(
    USER_PREFERENCES_QUERY_KEYS.detail(userId),
    async () => {
      const { data: response } = await apiClient.get<
        ApiResponse<UserPreferences>
      >(`/user_preferences/${userId}`);
      return response.data;
    },
    {
      select: (userPreferences) => {
        const workspacePreferences =
          userPreferences.workspaces && userPreferences.workspaces[workspaceId]
            ? userPreferences.workspaces[workspaceId]
            : { userId, workspaceId };

        return {
          ...workspacePreferences,
          calendarDaysPerWeek:
            workspacePreferences.calendarDaysPerWeek || DEFAULT_DAYS_PER_WEEK,
          calendarDayStartsAt:
            workspacePreferences.calendarDayStartsAt || DEFAULT_DAY_STARTS_AT,
          calendarShowHours:
            workspacePreferences.calendarShowHours || DEFAULT_SHOW_HOURS,
        };
      },
    }
  );
};

interface UpdateUserPreferencesProps {
  userId: string;
  updateProps: UserPreferencesUpdateProps;
}
export const useUpdateUserPreferences = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ userId, updateProps }: UpdateUserPreferencesProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<UserPreferences>
        >(`/user_preferences/${userId}`, updateProps);

        return response.data;
      },
      {
        onMutate: async ({ userId, updateProps }) => {
          const existingUserPreferences =
            queryClient.getQueryData<UserPreferences>(
              USER_PREFERENCES_QUERY_KEYS.detail(userId)
            );

          if (!existingUserPreferences) {
            return null;
          }

          const newUserPreferences = patchObject(
            existingUserPreferences,
            updateProps
          );

          const revert =
            await optimisticallyUpdateQueryCacheObject<UserPreferences>(
              queryClient,
              {
                detail: USER_PREFERENCES_QUERY_KEYS.detail(userId),
              },
              userId,
              () => newUserPreferences,
              "userId"
            );

          return { revert };
        },
        onError: (error, props, context) => {
          if (!context) {
            return;
          }

          const { revert } = context as {
            revert: () => void;
          };

          revert();
          queryClient.invalidateQueries(
            USER_PREFERENCES_QUERY_KEYS.detail(props.userId)
          );
        },

        onSuccess: (userPreferences, { userId }) => {
          queryClient.setQueryData(
            USER_PREFERENCES_QUERY_KEYS.detail(userId),
            userPreferences
          );
        },
      }
    ),
    () => ({ title: "Failed to update user preferences" })
  );
};

interface UpdateWorkspaceUserPreferencesProps {
  userId: string;
  workspaceId: string;
  updateProps: WorkspaceUserPreferencesUpdateProps;
}
export const useUpdateWorkspaceUserPreferences = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({
        userId,
        workspaceId,
        updateProps,
      }: UpdateWorkspaceUserPreferencesProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<UserPreferences>
        >(`/user_preferences/${userId}`, {
          workspaces: {
            [workspaceId]: updateProps,
          },
        });

        return response.data;
      },
      {
        onMutate: async ({ userId, workspaceId, updateProps }) => {
          const existingUserPreferences =
            queryClient.getQueryData<UserPreferences>(
              USER_PREFERENCES_QUERY_KEYS.detail(userId)
            );

          if (!existingUserPreferences) {
            return null;
          }

          const newWorkspacePrefs = patchObject(
            existingUserPreferences.workspaces || {},
            {
              [workspaceId]: updateProps,
            }
          );

          const newUserPreferences: UserPreferences = {
            ...existingUserPreferences,
            workspaces: {
              ...existingUserPreferences.workspaces,
              ...newWorkspacePrefs,
            },
          };

          const revert =
            await optimisticallyUpdateQueryCacheObject<UserPreferences>(
              queryClient,
              {
                detail: USER_PREFERENCES_QUERY_KEYS.detail(userId),
              },
              userId,
              () => newUserPreferences,
              "userId"
            );

          return { revert };
        },
        onError: (error, props, context) => {
          if (!context) {
            return;
          }

          const { revert } = context as {
            revert: () => void;
          };

          revert();
          queryClient.invalidateQueries(
            USER_PREFERENCES_QUERY_KEYS.detail(props.userId)
          );
        },
        onSuccess: (userPreferences, { userId }) => {
          queryClient.setQueryData(
            USER_PREFERENCES_QUERY_KEYS.detail(userId),
            userPreferences
          );
        },
      }
    ),
    () => ({ title: "Failed to update user preferences" })
  );
};
