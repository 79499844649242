import React, { ComponentProps, useMemo } from "react";
import { DateTime } from "luxon";
import Joi from "joi";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AlertCircle as AlertCircleIcon } from "react-feather";
import { CreditCardIcon } from "@heroicons/react/outline";

import { PaymentMethod } from "../../types/paymentMethods";
import { Workspace } from "../../types/workspaces";
import {
  useCreatePaymentMethod,
  useDeletePaymentMethod,
  usePaymentMethods,
} from "../../libs/hooks/paymentMethods";
import { useAsyncState, useSnackBarFactory } from "../../libs/hooks/general";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import DangerButton from "../../components/buttons/Danger";
import DeletedPaymentMethodSnackBarContent from "../../components/snackBarContent/DeletedPaymentMethod";
import TertiaryButton from "../../components/buttons/Tertiary";
import PaymentMethodSelector, {
  PaymentMethodSelectorValue,
  paymentMethodSelectorValueSchema,
} from "../../components/form/PaymentMethodSelector";
import {
  useActiveWorkspaceTimeZone,
  useCurrentUser,
} from "../../libs/hooks/app";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import { joiResolver } from "@hookform/resolvers/joi";
import H4 from "../../components/headings/H4";
import Checkbox from "../../components/form/Checkbox";

interface FormValues {
  replace: boolean;
  paymentMethod: PaymentMethodSelectorValue;
}

const formSchema = Joi.object({
  replace: Joi.boolean().required(),
  paymentMethod: paymentMethodSelectorValueSchema.required(),
});

interface DeletePaymentMethodModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  paymentMethod: PaymentMethod;
  workspaces: Workspace[];
  close: () => void;
}

const DeletePaymentMethodModal: React.FC<DeletePaymentMethodModalProps> = ({
  paymentMethod,
  workspaces,
  isVisible,
  close,
  ...modalProps
}) => {
  const user = useCurrentUser();
  const timeZone = useActiveWorkspaceTimeZone();
  const elements = useElements();
  const createSnackBar = useSnackBarFactory();
  const { data, isLoading: isLoadingPaymentMethods } = usePaymentMethods(
    user.id
  );
  const replacementPaymentMethods = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((candidate) => candidate.id !== paymentMethod.id);
  }, [data, paymentMethod.id]);
  const {
    mutateAsync: createPaymentMethod,
    errorAlert: createPaymentMethodErrorAlert,
    clearErrorAlert: clearCreatePaymentMethodErrorAlert,
  } = useCreatePaymentMethod();
  const {
    mutateAsync: deletePaymentMethod,
    errorAlert: deletePaymentMethodErrorAlert,
    clearErrorAlert: clearDeletePaymentMethodErrorAlert,
  } = useDeletePaymentMethod();
  const {
    loading: isSubmitting,
    setLoading: setIsSubmitting,
    loadingText: submitLoadingText,
  } = useAsyncState();
  const {
    register,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema, { convert: false }),
    defaultValues: {
      replace: true,
      paymentMethod: {
        type: "NEW",
        value: {
          empty: true,
          complete: false,
        },
      },
    },
  });
  const watchReplace = watch("replace");
  const watchPaymentMethod = watch("paymentMethod");
  const hasPaymentMethod = watchPaymentMethod
    ? watchPaymentMethod.type === "NEW"
      ? !watchPaymentMethod.value.empty
      : watchPaymentMethod.value !== null
    : false;
  const hasSubscriptions =
    paymentMethod.subscriptionIds && paymentMethod.subscriptionIds.length > 0;
  const canSubmit = hasSubscriptions
    ? !watchReplace || (watchReplace && hasPaymentMethod)
    : true;
  const hasFormErrors = Object.keys(errors).length > 0;
  const inUseWorkspaces = useMemo(() => {
    const workspacesBySubscriptionId = workspaces.reduce<{
      [subscriptionId: string]: Workspace;
    }>((carry, workspace) => {
      if (workspace.subscription) {
        carry[workspace.subscription.id] = workspace;
      }
      return carry;
    }, {});

    return paymentMethod.subscriptionIds
      ? paymentMethod.subscriptionIds
          .map((subscriptionId) => {
            return workspacesBySubscriptionId[subscriptionId];
          })
          .filter((workspace) => workspace !== undefined)
      : [];
  }, [paymentMethod.subscriptionIds, workspaces]);
  const isInUse = inUseWorkspaces.length > 0;
  const displayCreated = DateTime.fromISO(paymentMethod.created)
    .setZone(timeZone)
    .toFormat("DD");

  const onSubmit: SubmitHandler<FormValues> = async ({
    paymentMethod: paymentMethodData,
  }) => {
    if (isSubmitting) {
      return;
    }

    if (!canSubmit) {
      return;
    }

    setIsSubmitting(true);
    clearCreatePaymentMethodErrorAlert();
    clearDeletePaymentMethodErrorAlert();

    try {
      let replacementPaymentMethodId: string | undefined = undefined;

      if (paymentMethodData.type === "NEW" && !paymentMethodData.value.empty) {
        // The user has entered a new card so create the payment method.
        const cardElement = elements ? elements.getElement(CardElement) : null;

        if (!cardElement) {
          return;
        }

        const newPaymentMethod = await createPaymentMethod({ cardElement });
        replacementPaymentMethodId = newPaymentMethod.id;
      } else if (paymentMethodData.type === "EXISTING") {
        // The user has selected one of their existing payment methods.
        const paymentMethodId = paymentMethodData.value;
        const paymentMethod = replacementPaymentMethods.find(
          (candidate) => candidate.id === paymentMethodId
        );

        if (!paymentMethod) {
          return;
        }

        replacementPaymentMethodId = paymentMethod.id;
      }

      await deletePaymentMethod({
        paymentMethod,
        replacementPaymentMethodId,
      });

      createSnackBar({
        content: (
          <DeletedPaymentMethodSnackBarContent last4={paymentMethod.last4} />
        ),
      });

      close();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="px-8 pb-8 pt-6" style={{ width: 500 }}>
        <H3>Delete payment method</H3>
        <p className="mt-8">
          Are you sure that you'd like to remove this payment method from
          Seenly?
        </p>

        <div className="mt-4 p-4 rounded-lg bg-white border-2 border-gray-200">
          <div className="flex items-center">
            <div className="flex items-center">
              <CreditCardIcon className="flex-shrink-0 w-6 h-6" />
              <span className="ml-2">{`${paymentMethod.brand[0].toUpperCase()}${paymentMethod.brand.slice(
                1
              )}`}</span>
            </div>
          </div>
          <div className="mt-8 flex items-center justify-between">
            <div>
              <div className="font-bold text-xs text-gray-400">NUMBER</div>
              <div>{`•••• ${paymentMethod.last4}`}</div>
            </div>
            <div>
              <div className="font-bold text-xs text-gray-400">CREATED</div>
              <div>{displayCreated}</div>
            </div>
            <div>
              <div className="font-bold text-xs text-gray-400">EXPIRY</div>
              <div>{`${paymentMethod.expMonth}/${paymentMethod.expYear}`}</div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          {isInUse && (
            <div className="mt-8 p-2 rounded-lg border-2 border-gray-200">
              <H4 className="flex items-center">
                <AlertCircleIcon className="w-6 h-6 text-red-500" />
                <span className="ml-2">Payment method in use</span>
              </H4>
              <p className="mt-2">
                This payment method is currently in use by the following
                workspaces:
              </p>
              <ul className="mt-2 list-disc list-inside">
                {inUseWorkspaces.map((workspace) => (
                  <li key={workspace.id} className="ml-4">
                    {workspace.name}
                  </li>
                ))}
              </ul>

              <p className="mt-4">
                Your subscriptions for those workspaces will not automatically
                renew once this payment method has been deleted.
              </p>
              <p className="mt-4">
                Would you like us to update your subscriptions with a different
                payment method as part of the deletion process?
              </p>
              <label className="mt-4 flex items-center justify-center">
                <Checkbox {...register("replace")} />
                <span className="ml-2">Yes, update my subscriptions</span>
              </label>
              <Controller
                control={control}
                name="paymentMethod"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PaymentMethodSelector
                    className="mt-4"
                    value={value}
                    paymentMethods={replacementPaymentMethods}
                    loading={isLoadingPaymentMethods}
                    labelText="Replacement payment method"
                    error={!!errors.paymentMethod}
                    disabled={!watchReplace || isSubmitting}
                    onChange={onChange}
                    onBlur={onBlur}
                    onError={(message) => {
                      if (message) {
                        setError("paymentMethod", { message });
                      } else {
                        clearErrors("paymentMethod");
                      }
                    }}
                  />
                )}
              />
            </div>
          )}

          {isInUse && !watchReplace && (
            <p className="mt-8 text-red-500">
              Your workspace subscriptions will not automatically renew at the
              end of the period.
            </p>
          )}

          {createPaymentMethodErrorAlert({ className: "mt-8" })}
          {deletePaymentMethodErrorAlert({ className: "mt-8" })}

          <DangerButton
            className="mt-8 w-full"
            type="submit"
            disabled={isSubmitting || !canSubmit || hasFormErrors}
          >
            {submitLoadingText({
              loading: `Deleting payment method...`,
              default: `Yep, delete the payment method.`,
            })}
          </DangerButton>
          <TertiaryButton
            className="mt-2 w-full"
            onClick={() => close()}
            disabled={isSubmitting}
          >
            No, thanks.
          </TertiaryButton>
        </form>
      </div>
    </Modal>
  );
};

export default DeletePaymentMethodModal;
