import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "../../types/general";

import { useApiClient } from "./app";
import { TWITTER_QUERY_KEYS } from "../queryKeys";

interface UserData {
  id: string;
  name: string;
  username: string;
  profileImageUrl: string;
  isVerified?: boolean;
}

interface UseSearchTwitterUsersProps {
  platformId: string;
  query: string;
}
export const useSearchTwitterUsers = (
  { platformId, query }: UseSearchTwitterUsersProps,
  options = { enabled: true, keepPreviousData: true }
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useQuery(
    TWITTER_QUERY_KEYS.list({ platformId, query }),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<UserData[]>>(
        "/proxies/twitter",
        {
          params: {
            action: "searchUsers",
            platformId,
            query,
          },
        }
      );

      return response.data;
    },
    {
      ...options,
      onSuccess: (twitterUsers) => {
        twitterUsers.forEach((twitterUser) => {
          queryClient.setQueryData(
            TWITTER_QUERY_KEYS.detail(twitterUser.id),
            twitterUser
          );
        });
      },
    }
  );
};

export const useTwitterUser = (platformId: string, userId: string) => {
  const apiClient = useApiClient();

  return useQuery(TWITTER_QUERY_KEYS.detail(userId), async () => {
    const { data: response } = await apiClient.get<ApiResponse<UserData>>(
      "/proxies/twitter",
      {
        params: {
          action: "getUser",
          platformId,
          userId,
        },
      }
    );

    return response.data;
  });
};
