import { SwitchHorizontalIcon } from "@heroicons/react/outline";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import { mergeClassNames } from "../../libs/components";
import { useHasChanged } from "../../libs/hooks/general";
import { PaymentMethod } from "../../types/paymentMethods";
import TertiaryButton from "../buttons/Tertiary";

import CreditCardInput, { OnChangeProps } from "./input/CreditCard";
import PaymentMethodSelect from "./select/PaymentMethod";

interface NewValue {
  type: "NEW";
  value: OnChangeProps;
}

interface ExistingValue {
  type: "EXISTING";
  value: string;
}

export type PaymentMethodSelectorValue = NewValue | ExistingValue;

const newValueSchema = Joi.object({
  type: Joi.string().equal("NEW").required(),
  value: Joi.object({
    empty: Joi.boolean().required(),
    complete: Joi.boolean().required(),
  })
    .required()
    .unknown(false),
}).unknown(false);

const existingValueSchema = Joi.object({
  type: Joi.string().equal("EXISTING").required(),
  value: Joi.string().required(),
}).unknown(false);

export const paymentMethodSelectorValueSchema = Joi.alternatives(
  newValueSchema,
  existingValueSchema
);

interface PaymentMethodSelectorProps {
  value: PaymentMethodSelectorValue;
  paymentMethods: PaymentMethod[];
  onChange: (newValue: PaymentMethodSelectorValue) => void;
  error?: boolean;
  onBlur?: () => void;
  onError?: (message: string | null) => void;
  loading?: boolean;
  labelText?: string;
  hideLabel?: boolean;
  className?: string;
  disabled?: boolean;
}

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({
  value,
  paymentMethods,
  onChange,
  onBlur,
  onError,
  error = false,
  loading = false,
  labelText = "Payment method",
  hideLabel = false,
  className = "",
  disabled = false,
}) => {
  const hasExisting = paymentMethods.length > 0;
  const [showExisting, setShowExisting] = useState(true);
  const [status, setStatus] = useState<"loading" | "initialising" | "ready">(
    loading ? "loading" : "initialising"
  );
  const hasTypeChanged = useHasChanged(value.type);
  const containerClassName = mergeClassNames("flex flex-col", className);

  useEffect(() => {
    if (!loading && status === "loading") {
      setStatus("initialising");
    }
  }, [loading, status]);

  useEffect(() => {
    if (status === "initialising") {
      if (hasExisting) {
        setShowExisting(true);
      } else {
        setShowExisting(false);
      }

      setStatus("ready");
    }
  }, [hasExisting, paymentMethods, showExisting, status]);

  useEffect(() => {
    if (status === "ready" && hasTypeChanged) {
      setShowExisting(value.type === "EXISTING");
    }
  }, [hasTypeChanged, status, value.type]);

  if (loading) {
    return (
      <div className={containerClassName}>
        {!hideLabel && <div className="font-bold mb-2">{labelText}</div>}
        <div className="w-full h-12 rounded-lg bg-gray-200 animate-pulse"></div>
        <div className="mt-2 w-full h-12 rounded-lg bg-gray-200 animate-pulse"></div>
      </div>
    );
  }

  if (showExisting) {
    const existingValue =
      value && value.type === "EXISTING" ? value.value : null;

    return (
      <div className={containerClassName}>
        <PaymentMethodSelect
          toggleButtonClassName="w-full h-12 justify-start"
          value={existingValue || undefined}
          paymentMethods={paymentMethods}
          onChange={(newPaymentMethodId) => {
            onChange({
              type: "EXISTING",
              value: newPaymentMethodId,
            });
          }}
          error={error}
          onBlur={onBlur}
          labelText={labelText}
          hideLabel={hideLabel}
          disabled={disabled}
        />
        <TertiaryButton
          className="mt-2 w-full"
          onClick={() => setShowExisting(false)}
          disabled={disabled}
        >
          <SwitchHorizontalIcon className="w-6 h-6" />
          <span className="ml-2">Add new payment method</span>
        </TertiaryButton>
      </div>
    );
  }

  return (
    <div className={containerClassName}>
      <CreditCardInput
        labelText={labelText}
        hideLabel={hideLabel}
        onChange={(newValues) => {
          onChange({
            type: "NEW",
            value: newValues,
          });
        }}
        onBlur={onBlur}
        onError={onError}
      />
      <TertiaryButton
        className="mt-2 w-full"
        onClick={() => setShowExisting(true)}
        disabled={!hasExisting || disabled}
      >
        <SwitchHorizontalIcon className="w-6 h-6" />
        <span className="ml-2">Use existing payment method</span>
      </TertiaryButton>
    </div>
  );
};

export default PaymentMethodSelector;
