import React, { Fragment } from "react";

import { Subscription } from "../../../types/subscriptions";

import { mergeClassNames } from "../../../libs/components";
import { useInvoicesForSubscription } from "../../../libs/hooks/invoices";
import { useActiveWorkspaceTimeZone } from "../../../libs/hooks/app";

import LoaderIcon from "../../../components/icons/Loader";
import SecondaryButton from "../../../components/buttons/Secondary";
import InvoiceTableRow from "../../../components/tables/rows/Invoice";
import InvoiceTableHeader from "../../../components/tables/headers/Invoice";

interface SubscriptionInvoicesSectionProps {
  subscription: Subscription;
  className?: string;
}
const SubscriptionInvoicesSection: React.FC<
  SubscriptionInvoicesSectionProps
> = ({ subscription, className = "" }) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const { fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, data } =
    useInvoicesForSubscription({
      subscriptionId: subscription.id,
      limit: 10,
    });
  const pages = data ? data.pages : [];
  const hasInvoices =
    data && data.pages.length > 0 && data.pages[0].invoices.length > 0;

  return (
    <div
      className={mergeClassNames(
        `relative ${isLoading ? "w-full h-16" : ""}`,
        className
      )}
    >
      {isLoading && (
        <div className="absolute inset-0 rounded-lg flex items-center justify-center text-gray-400">
          <LoaderIcon />
          <span className="ml-2">Loading invoices...</span>
        </div>
      )}
      <div
        className={`default-transition ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
      >
        {!isLoading && !hasInvoices && (
          <p className="p-10 w-full rounded-lg text-center text-gray-400 bg-gray-100">
            No invoices for this workspace
          </p>
        )}
        {!isLoading && hasInvoices && (
          <table className="w-full table-auto">
            <InvoiceTableHeader />
            <tbody>
              {pages.map(({ invoices }, index) => {
                return (
                  <Fragment key={index}>
                    {invoices.map((invoice) => {
                      return (
                        <InvoiceTableRow
                          key={invoice.id}
                          invoice={invoice}
                          timeZone={timeZone}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
        {!isLoading && hasInvoices && (
          <SecondaryButton
            className="mt-2 mx-auto w-96"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? (
              <>
                <LoaderIcon />
                <span className="ml-2">Loading more invoices...</span>
              </>
            ) : hasNextPage ? (
              "Load more invoices"
            ) : (
              "All invoices loaded"
            )}
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default SubscriptionInvoicesSection;
