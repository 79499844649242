import React, { useMemo } from "react";

import { useActiveWorkspace, useShowModal } from "../../../../libs/hooks/app";
import { usePlatforms } from "../../../../libs/hooks/platforms";
import { usePost } from "../../../../libs/hooks/posts";

import LinkButton from "../../../../components/buttons/Link";
import PostPreview from "../../../../components/posts/Preview";

import PostLoading from "./PostLoading";

interface AnalyticsBodyTopPostProps {
  title: string;
  postId: string;
  className?: string;
}

const AnalyticsBodyTopPost: React.FC<AnalyticsBodyTopPostProps> = ({
  title,
  postId,
  className = "",
}) => {
  const showModal = useShowModal();
  const workspace = useActiveWorkspace();
  const { data: post, isLoading: isLoadingPost } = usePost(postId);
  const { data: platforms, isLoading: isLoadingPlatforms } = usePlatforms(
    workspace.id
  );
  const platform = useMemo(() => {
    if (!post || !platforms) {
      return null;
    }

    const platform = platforms.find(
      (candidate) => candidate.id === post.platformId
    );

    return platform || null;
  }, [platforms, post]);
  const platformEntity = useMemo(() => {
    if (!post || !platform) {
      return null;
    }

    return platform.entities[post.platformEntityId] || null;
  }, [platform, post]);
  const isLoading = isLoadingPost || isLoadingPlatforms;
  const canRenderPost = !isLoading && !!post && !!platform && !!platformEntity;

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="font-bold">{title}</div>
        {canRenderPost && (
          <LinkButton
            className="text-sm"
            onClick={() => showModal("viewPost", { post, platform })}
          >
            View post analytics
          </LinkButton>
        )}
      </div>
      {canRenderPost && (
        <PostPreview
          className="mt-2"
          post={post}
          platformEntity={platformEntity}
        />
      )}
      {!isLoading && !canRenderPost && (
        <p className="mt-2 w-full h-40 flex items-center justify-center rounded-xl bg-white border border-gray-200">
          Unable to render post
        </p>
      )}
      {isLoading && <PostLoading className="mt-2" />}
    </div>
  );
};

export default AnalyticsBodyTopPost;
