import H6 from "../headings/H6";

interface SectionTitleProps {
  title: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => {
  return <H6 className="font-semibold text-gray-500">{title}</H6>;
};

export default SectionTitle;
