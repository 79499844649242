import React from "react";
import { mergeClassNames } from "../../../libs/components";
import { useShowModal } from "../../../libs/hooks/app";

import TertiaryButton from "../../../components/buttons/Tertiary";

interface ProfileSecuritySectionProps {
  className?: string;
}

const ProfileSecuritySection: React.FC<ProfileSecuritySectionProps> = ({
  className = "",
}) => {
  const showModal = useShowModal();

  return (
    <div>
      <TertiaryButton
        className={mergeClassNames("w-full", className)}
        onClick={() => showModal("changePassword")}
      >
        Change password
      </TertiaryButton>
    </div>
  );
};

export default ProfileSecuritySection;
