import React, { useState } from "react";
import {
  Image as ImageIcon,
  UploadCloud as UploadCloudIcon,
} from "react-feather";

import {
  ImageAttachment,
  InternalImageAttachment,
  UnsplashImageAttachment,
} from "../../../../types/posts";
import { mergeClassNames } from "../../../../libs/components";

import UnsplashIcon from "../../../../components/icons/Unsplash";
import ContentSwitchContainer from "../../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../../components/animations/contentSwitch/Content";

import Gallery from "./imageAttachments/Gallery";
import Unsplash from "./imageAttachments/Unsplash";
import Upload from "./imageAttachments/Upload";
import Button from "../../../../components/buttons/Button";
import SecondaryButton from "../../../../components/buttons/Secondary";
import {
  isInternalImageAttachment,
  isUnsplashImageAttachment,
} from "../../../../libs/post";

const CONTENT_BUTTONS = [
  {
    contentId: "gallery",
    icon: <ImageIcon />,
    text: "Gallery",
  },
  {
    contentId: "upload",
    icon: <UploadCloudIcon />,
    text: "Upload",
  },
  {
    contentId: "unsplash",
    icon: <UnsplashIcon className="h-6 w-6" />,
    text: "Unsplash",
  },
] as const;

interface PostTextAreaImageAttachmentsProps {
  close: () => void;
  imageAttachments: ImageAttachment[];
  setImageAttachments: (newImageAttachments: ImageAttachment[]) => void;
  className?: string;
}
const PostTextAreaImageAttachments: React.FC<
  PostTextAreaImageAttachmentsProps
> = ({ close, imageAttachments, setImageAttachments, className = "" }) => {
  const [contentId, setContentId] = useState<"gallery" | "unsplash" | "upload">(
    "gallery"
  );

  return (
    <div className={mergeClassNames("h-full flex flex-col", className)}>
      <div className="p-2 shrink-0 flex items-center">
        {CONTENT_BUTTONS.map((buttonConfig, index) => {
          const isSelected = contentId === buttonConfig.contentId;
          return (
            <Button
              key={buttonConfig.contentId}
              className={`py-2 flex flex-grow bg-gradient-to-r ${
                index === 0 ? "" : "ml-2"
              } ${
                isSelected
                  ? "text-purple-500 from-purple-100 to-blue-100"
                  : "hover:text-purple-500 hover:from-purple-100 hover:to-blue-100 focus:text-purple-500 focus:from-purple-100 focus:to-blue-100"
              }`}
              size="sm"
              onClick={() => setContentId(buttonConfig.contentId)}
            >
              {buttonConfig.icon}
              <span className="ml-2">{buttonConfig.text}</span>
            </Button>
          );
        })}
      </div>
      <ContentSwitchContainer
        className="grow overflow-hidden"
        contentContainerClassName="w-full h-full overflow-hidden"
        contentId={contentId}
        transitionSpeed="fast"
      >
        <ContentSwitchContent contentId="gallery">
          <Gallery
            imageAttachments={
              imageAttachments.filter(
                isInternalImageAttachment
              ) as InternalImageAttachment[]
            }
            addImage={(newImageAttachment) => {
              setImageAttachments(imageAttachments.concat(newImageAttachment));
            }}
            removeImage={(imageId) => {
              setImageAttachments(
                imageAttachments.filter(
                  (candidate) =>
                    (candidate as InternalImageAttachment).fileId !== imageId
                )
              );
            }}
            showUpload={() => setContentId("upload")}
          />
        </ContentSwitchContent>
        <ContentSwitchContent contentId="unsplash">
          <Unsplash
            imageAttachments={
              imageAttachments.filter(
                isUnsplashImageAttachment
              ) as UnsplashImageAttachment[]
            }
            addImage={(newImageAttachment) => {
              setImageAttachments(imageAttachments.concat(newImageAttachment));
            }}
            removeImage={(imageId) => {
              setImageAttachments(
                imageAttachments.filter(
                  (candidate) =>
                    (candidate as UnsplashImageAttachment).id !== imageId
                )
              );
            }}
          />
        </ContentSwitchContent>
        <ContentSwitchContent contentId="upload">
          <Upload
            addImages={(newImageAttachments) => {
              setImageAttachments(imageAttachments.concat(newImageAttachments));
            }}
          />
        </ContentSwitchContent>
      </ContentSwitchContainer>
      <div className="p-2 shrink-0">
        <SecondaryButton className="w-full" fill={true} onClick={close}>
          Done
        </SecondaryButton>
      </div>
    </div>
  );
};

export default PostTextAreaImageAttachments;
