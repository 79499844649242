import React from "react";
import { useFacebookPage } from "../../../../../../../libs/hooks/facebook";
import { PlatformEntity } from "../../../../../../../types/platforms";

import SelectedBase from "./Base";

interface PostTextAreaMentionSelectedFacebookProps {
  platformId: string;
  entityType: PlatformEntity["type"];
  entityId: string;
  onDelete: () => void;
  isVisible?: boolean;
}
const PostTextAreaMentionSelectedFacebook: React.FC<
  PostTextAreaMentionSelectedFacebookProps
> = ({ platformId, entityId, onDelete, isVisible = true }) => {
  const { isLoading, data } = useFacebookPage(platformId, entityId);

  if (!isVisible) {
    return null;
  }

  return (
    <SelectedBase
      isLoading={isLoading}
      name={data ? data.name : null}
      vanityName={data ? data.username : undefined}
      pictureUrl={data ? data.pictureUrl : undefined}
      isVerified={data ? data.isVerified : undefined}
      onDelete={onDelete}
    />
  );
};

export default PostTextAreaMentionSelectedFacebook;
