import React, { ComponentProps, useMemo, useState } from "react";

import { Post } from "../../types/posts";
import { FormPublishedPostAction } from "../../types/publishedPostActions";
import { Platform } from "../../types/platforms";

import { useActiveWorkspace, useShowModal } from "../../libs/hooks/app";
import { usePlatforms } from "../../libs/hooks/platforms";

import ModalContentStack from "../../components/ModalContentStack";

import Loading from "./multiPostPublishedPostActions/Loading";
import Error from "./multiPostPublishedPostActions/Error";
import NoSubscription from "./multiPostPublishedPostActions/NoSubscription";
import EditActions from "./multiPostPublishedPostActions/EditActions";
import PostsPicker from "./multiPostPublishedPostActions/PostsPicker";
import Submit from "./multiPostPublishedPostActions/Submit";

interface MultiPostPublishedPostActionsModalProps
  extends Omit<ComponentProps<typeof ModalContentStack>, "children"> {
  posts: Post[];
  close: () => void;
}

const MultiPostPublishedPostActionsModal: React.FC<
  MultiPostPublishedPostActionsModalProps
> = ({ isVisible, close, posts, ...modalProps }) => {
  const nonFacebookPosts = useMemo(() => {
    return posts.filter((post) => post.type !== "FACEBOOK");
  }, [posts]);
  const showModal = useShowModal();
  const [editingPost, setEditingPost] = useState<Post>(nonFacebookPosts[0]);
  const [preventModalClose, setPreventModalClose] = useState(false);
  const [actionFormValues, setActionFormValues] = useState<{
    [postId: string]: FormPublishedPostAction[];
  }>({});
  const isDirty = Object.values(actionFormValues).some((formValues) => {
    return formValues.length > 0;
  });
  const workspace = useActiveWorkspace();
  const {
    isLoading: isLoadingPlatforms,
    isError: isErrorPlatforms,
    data: platformsData,
  } = usePlatforms(workspace.id);

  const hasSubscription = !!workspace.subscription;
  const isLoading = isLoadingPlatforms;
  const isError = isErrorPlatforms;
  const platforms = useMemo(() => platformsData || [], [platformsData]);
  const platformsById = useMemo(() => {
    return platforms.reduce<{ [platformId: string]: Platform }>(
      (carry, platform) => {
        carry[platform.id] = platform;
        return carry;
      },
      {}
    );
  }, [platforms]);

  return (
    <ModalContentStack
      {...modalProps}
      isVisible={isVisible}
      close={
        preventModalClose
          ? undefined
          : () => {
              if (isDirty) {
                showModal("confirmClose", {
                  onConfirm: close,
                });
                return false;
              } else {
                close();
              }
            }
      }
      defaultContentId={
        hasSubscription
          ? isLoading
            ? "loading"
            : isError
            ? "error"
            : "postsPicker"
          : "noSubscription"
      }
    >
      <Loading contentId="loading" />

      <Error contentId="error" />

      <NoSubscription contentId="noSubscription" workspace={workspace} />

      <PostsPicker
        contentId="postsPicker"
        posts={nonFacebookPosts}
        platformsById={platformsById}
        actionFormValues={actionFormValues}
        setActionFormValues={setActionFormValues}
        setPreventModalClose={setPreventModalClose}
        setEditingPost={(newEditingPost) => {
          setEditingPost(newEditingPost);
        }}
      />

      <EditActions
        contentId="editActions"
        post={editingPost}
        platforms={platforms}
        defaultValues={{
          actions: (actionFormValues[editingPost.id] as any[]) || [],
        }}
        onSubmit={(post, formData) => {
          setActionFormValues((prevValues) => {
            return {
              ...prevValues,
              [post.id]: formData.actions,
            };
          });
        }}
      />

      <Submit
        contentId="submit"
        setPreventModalClose={setPreventModalClose}
        actionFormValues={actionFormValues}
        close={close}
      />
    </ModalContentStack>
  );
};

export default MultiPostPublishedPostActionsModal;
