import React, { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";

import { useCurrentUser } from "../../libs/hooks/app";
import { useCreateWorkspace } from "../../libs/hooks/workspaces";
import { useUpdateUserPreferences } from "../../libs/hooks/userPreferences";

import Modal from "../../components/Modal";
import H2 from "../../components/headings/H2";
import PrimaryButton from "../../components/buttons/Primary";
import Input from "../../components/form/Input";

interface AddWorkspaceModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  close: () => void;
}

type FormValues = {
  name: string;
};

const formSchema = Joi.object({
  name: Joi.string().required(),
});

const AddWorkspaceModal: React.FC<AddWorkspaceModalProps> = ({
  isVisible,
  close,
  ...modalProps
}) => {
  const user = useCurrentUser();
  const {
    mutateAsync: createWorkspace,
    isLoading: isLoadingCreateWorkspace,
    loadingText: loadingTextCreateWorkspace,
    errorAlert,
  } = useCreateWorkspace();
  const {
    mutateAsync: updateUserPreferences,
    isLoading: isLoadingUpdatePreferences,
    loadingText: loadingTextUpdatePreferences,
  } = useUpdateUserPreferences();
  const isLoading = isLoadingCreateWorkspace || isLoadingUpdatePreferences;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema),
  });

  useEffect(() => {
    if (isVisible) {
      setFocus("name");
    }
  }, [isVisible, setFocus]);

  const addWorkspace = async (data: FormValues) => {
    const { workspace } = await createWorkspace(data);
    await updateUserPreferences({
      userId: user.id,
      updateProps: { activeWorkspaceId: workspace.id },
    });
    close();
  };

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="p-8 relative" style={{ width: 400 }}>
        <H2 className="mb-4 text-2xl text-black w-full">Add new workspace</H2>
        <p>
          You can create multiple workspaces to group your socials and quickly
          switch between them.
        </p>

        {errorAlert({ className: "mt-4" })}

        <form onSubmit={handleSubmit(addWorkspace)}>
          <Input
            {...register("name")}
            labelText="Workspace name"
            labelClassName="mt-4"
            type="text"
            error={!!errors.name}
            style={{ resize: "none" }}
            disabled={isLoading}
          />
          <PrimaryButton
            type="submit"
            className="mt-4 w-full"
            disabled={isLoading}
          >
            {isLoadingCreateWorkspace
              ? loadingTextCreateWorkspace({
                  loading: "Adding...",
                  default: "Add",
                })
              : isLoadingUpdatePreferences
              ? loadingTextUpdatePreferences({
                  loading: "Loading...",
                  default: "Add",
                })
              : "Add"}
          </PrimaryButton>
        </form>
      </div>
    </Modal>
  );
};

export default AddWorkspaceModal;
