import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Platform } from "../types/platforms";
import { savePlatformOAuthResponse } from "../libs/localStorage";

function isValidProvider(provider: string) {
  return (
    provider === "linkedin" || provider === "twitter" || provider === "facebook"
  );
}

const AuthCallback: React.FC = () => {
  const { provider } = useParams<{ provider: string }>();

  useEffect(() => {
    if (provider && isValidProvider(provider)) {
      savePlatformOAuthResponse({
        platformType: provider.toUpperCase() as Platform["type"],
        response: window.location.search,
      });
    }

    window.close();
  }, [provider]);

  return <div></div>;
};

export default AuthCallback;
