import React, { ComponentProps, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import { useCurrentUser } from "../../libs/hooks/app";
import {
  useResendUserEmailVerificationCode,
  useVerifyUserEmail,
} from "../../libs/hooks/users";
import { useSnackBarFactory } from "../../libs/hooks/general";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import VerificationCodeInput from "../../components/form/input/VerificationCode";
import PrimaryButton from "../../components/buttons/Primary";
import TertiaryButton from "../../components/buttons/Tertiary";
import ChangesSavedSnackBarContent from "../../components/snackBarContent/ChangesSaved";

type FormValues = {
  code: string;
};

const formSchema = Joi.object({
  code: Joi.string().required(),
});

interface VerifyEmailAddressModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  close: () => void;
}

const VerifyEmailAddressModal: React.FC<VerifyEmailAddressModalProps> = ({
  isVisible,
  close,
  ...modalProps
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const user = useCurrentUser();
  const createSnackBar = useSnackBarFactory();
  const {
    isLoading: isVerifying,
    loadingText: verifyingLoadingText,
    errorAlert: verifyingErrorAlert,
    mutateAsync: verifyEmail,
  } = useVerifyUserEmail();
  const {
    isLoading: isResending,
    loadingText: resendLoadingText,
    errorAlert: resendingErrorAlert,
    mutateAsync: resendCode,
  } = useResendUserEmailVerificationCode();
  const {
    control,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema),
  });
  const hasErrors = Object.keys(errors).length > 0;
  const watchCode = watch("code");
  const currentCodeLength = watchCode ? watchCode.length : 0;

  const onSubmit = async ({ code }: FormValues) => {
    const isVerified = await verifyEmail(code);

    if (isVerified) {
      createSnackBar({
        content: (
          <ChangesSavedSnackBarContent message="Email address verified" />
        ),
      });
      close();
    }
  };

  useEffect(() => {
    if (isVisible) {
      setFocus("code");
    }
  }, [isVisible, setFocus]);

  useEffect(() => {
    if (currentCodeLength === 6 && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }, [currentCodeLength]);

  return (
    <Modal
      {...modalProps}
      closeButtonClassName="hidden"
      isVisible={isVisible}
      close={undefined}
    >
      <div className="p-8" style={{ width: 500 }}>
        <H3>Verify new email address</H3>
        <p className="mt-4">
          We've sent a verification code to your new email address{" "}
          <strong>{user.email}</strong>
        </p>
        <p className="mt-2">
          Please check your email for the code and enter it below.
        </p>
        <form className="pt-1" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="code"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <VerificationCodeInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                labelClassName="mt-4"
                error={!!errors.code}
              />
            )}
          />
          {verifyingErrorAlert({ className: "mt-6" })}
          <PrimaryButton
            ref={submitButtonRef}
            className="mt-6 w-full text-xl text-white flex items-center"
            type="submit"
            disabled={isVerifying}
          >
            {verifyingLoadingText({
              default: "Verify",
              loading: "Verifying...",
            })}
          </PrimaryButton>
        </form>
        {resendingErrorAlert({ className: "mt-4" })}
        <TertiaryButton
          className="mt-4 w-full"
          disabled={isVerifying || isResending || hasErrors || !isDirty}
          onClick={() => resendCode()}
        >
          {resendLoadingText({
            default: "Send a new code",
            loading: "Sending new code code...",
          })}
        </TertiaryButton>
      </div>
    </Modal>
  );
};

export default VerifyEmailAddressModal;
