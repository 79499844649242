import { ComponentProps, forwardRef } from "react";
import { Link } from "react-router-dom";
import { mergeClassNames } from "../../libs/components";

const InternalTertiaryButtonLink = forwardRef<
  HTMLAnchorElement,
  ComponentProps<typeof Link>
>(({ className = "", ...props }, ref) => {
  return (
    <Link
      ref={ref}
      className={mergeClassNames(
        "py-3 px-4 flex items-center justify-center rounded-lg font-bold default-transition bg-gray-200 hover:text-black hover:bg-gray-300 focus:text-black focus:bg-gray-300",
        className
      )}
      {...props}
    />
  );
});

export default InternalTertiaryButtonLink;
