import React, { ComponentProps } from "react";
import { mergeClassNames } from "../../libs/components";

import Badge from "./Badge";

interface DangerBadgeProps extends ComponentProps<typeof Badge> {}

const DangerBadge: React.FC<DangerBadgeProps> = ({
  className = "",
  ...props
}) => {
  return (
    <Badge
      className={mergeClassNames("bg-red-100 text-red-700", className)}
      {...props}
    />
  );
};

export default DangerBadge;
