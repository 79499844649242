import React from "react";
import { Trash as TrashIcon } from "react-feather";

import DangerButton from "../../../../../../../components/buttons/Danger";

import EntityCard from "../EntityCard";

interface PostTextAreaMentionSelectedBaseProps {
  isLoading: boolean;
  name: string | null;
  vanityName?: string;
  pictureUrl?: string;
  isVerified?: boolean;
  onDelete: () => void;
}
const PostTextAreaMentionSelectedBase: React.FC<
  PostTextAreaMentionSelectedBaseProps
> = ({ isLoading, name, vanityName, pictureUrl, isVerified, onDelete }) => {
  return (
    <div className="p-2 h-full flex flex-col">
      {isLoading && (
        <div className="p-2 mt-2 flex items-center">
          <div className="flex-shrink-0 w-10 h-10 rounded-full animate-pulse bg-gray-200"></div>
          <div className="ml-2 flex items-center justify-center h-10 w-full">
            <div className="w-full">
              <div className="h-4 rounded animate-pulse bg-gray-200"></div>
              <div className="mt-1 h-3 rounded animate-pulse bg-gray-200"></div>
            </div>
          </div>
        </div>
      )}
      {name && (
        <EntityCard
          className="p-2"
          name={name}
          vanityName={vanityName}
          pictureUrl={pictureUrl}
          isVerified={isVerified}
        />
      )}
      {!isLoading && !name && (
        <div className="p-2 mt-2 flex items-center">
          <div className="h-10 text-gray-500">Unable to find user</div>
        </div>
      )}
      <DangerButton
        className="mt-auto w-full"
        size="sm"
        onClick={() => onDelete()}
      >
        <TrashIcon className="mr-2 h-4 w-4" />
        Remove mention
      </DangerButton>
    </div>
  );
};

export default PostTextAreaMentionSelectedBase;
