import { useEffect, useState, forwardRef } from "react";
import { mergeClassNames } from "../../../libs/components";
import { useHasChanged, usePrevious } from "../../../libs/hooks/general";
import { clamp, isModifierKeyPressed } from "../../../libs/utils";

interface ClampedNumberInputProps {
  value: number;
  onChange: (newNumber: number) => void;
  min: number;
  max: number;
  className?: string;
}

const ClampedNumberInput = forwardRef<
  HTMLInputElement,
  ClampedNumberInputProps
>(({ value, onChange, min, max, className = "" }, ref) => {
  const prevValue = usePrevious(value);
  const hasMinChanged = useHasChanged(min);
  const hasMaxChanged = useHasChanged(max);
  const [newValue, setNewValue] = useState<number>(value);
  const minDigitLength = Math.max(`${min}`.length, 2);
  const hasValueChanged = value !== prevValue && value !== newValue;

  useEffect(() => {
    if (!hasValueChanged && (hasMinChanged || hasMaxChanged)) {
      const clampedNewValue = clamp(newValue, min, max);

      setNewValue(clampedNewValue);

      if (clampedNewValue !== newValue) {
        onChange(clampedNewValue);
      }
    }
  }, [
    min,
    max,
    hasMinChanged,
    hasMaxChanged,
    newValue,
    onChange,
    hasValueChanged,
  ]);

  useEffect(() => {
    if (hasValueChanged) {
      setNewValue(value);
    }
  }, [hasValueChanged, newValue, prevValue, value]);

  return (
    <input
      ref={ref}
      className={mergeClassNames("bg-transparent", className)}
      type="number"
      value={newValue}
      onKeyDown={(e) => {
        if (!isModifierKeyPressed(e) && e.key === "Enter") {
          e.preventDefault();

          const clampedNewValue = clamp(newValue, min, max);

          setNewValue(clampedNewValue);

          if (clampedNewValue !== value) {
            onChange(clampedNewValue);
          }
        }
      }}
      onChange={(e) => {
        const newInput = parseInt(e.target.value, 10);
        const newNumber = isNaN(newInput) ? min : newInput;
        const newNumberDigitLength = `${newNumber}`.length;
        const hasEnoughDigits = newNumberDigitLength >= minDigitLength;
        const localMin = hasEnoughDigits ? min : 1;
        const clampedNewNumber = clamp(newNumber, localMin, max);

        setNewValue(clampedNewNumber);

        if (
          hasEnoughDigits &&
          clampedNewNumber >= min &&
          value !== clampedNewNumber
        ) {
          onChange(clampedNewNumber);
        }
      }}
      onFocus={(e) => e.target.select()}
      onBlur={() => {
        const clampedNewValue = clamp(newValue, min, max);

        setNewValue(clampedNewValue);

        if (clampedNewValue !== value) {
          onChange(clampedNewValue);
        }
      }}
    />
  );
});

export default ClampedNumberInput;
