import React, { ComponentProps } from "react";

import Modal from "./Modal";
import ContentSwitch from "./animations/ContentSwitch";

type ModalContentSwitchProps = Omit<ComponentProps<typeof Modal>, "children"> &
  ComponentProps<typeof ContentSwitch>;

const ModalContentSwitch: React.FC<ModalContentSwitchProps> = ({
  children,
  defaultContentId = "home",
  defaultHeight = 0,
  defaultWidth = 0,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps}>
      <ContentSwitch
        defaultContentId={defaultContentId}
        defaultHeight={defaultHeight}
        defaultWidth={defaultWidth}
      >
        {children}
      </ContentSwitch>
    </Modal>
  );
};

export default ModalContentSwitch;
