import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Lock as LockIcon } from "react-feather";

import { PostFormValues } from "../../../../types/posts";
import { mergeClassNames } from "../../../../libs/components";

import ContentSwitchContainer from "../../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../../components/animations/contentSwitch/Content";
import CheckboxToggle from "../../../../components/form/CheckboxToggle";
import SmartScheduleIcon from "../../../../components/icons/SmartSchedule";

import Manual from "./whenToPost/Manual";
import Smart from "./whenToPost/Smart";
import PostFormRow from "../Row";

interface PostFormWhenToPostSectionProps {
  timeZone: string;
  className?: string;
}
const PostFormWhenToPostSection: React.FC<PostFormWhenToPostSectionProps> = ({
  timeZone,
  className = "",
}) => {
  const { control, watch } = useFormContext<PostFormValues>();
  const watchStatus = watch("status");

  return (
    <div className={mergeClassNames("w-full group", className)}>
      <h3 className="font-bold text-sm text-gray-300 default-transition group-focus-within:text-purple-500">
        WHEN TO POST
      </h3>

      <Controller
        control={control}
        name="status"
        render={({ field: { onChange, value, ref } }) => {
          return (
            <PostFormRow
              className="mt-1"
              icon={
                <div className="h-full flex items-center">
                  {value !== "QUEUED" ? (
                    <LockIcon className="h-6 w-6" />
                  ) : (
                    <SmartScheduleIcon className="h-6 w-6" />
                  )}
                </div>
              }
              content={
                <div className="ml-4">
                  <CheckboxToggle
                    ref={ref}
                    className="w-full"
                    checkedContentClassName="px-4 py-2 text-center"
                    uncheckedContentClassName="px-4 py-2 text-center"
                    checkedContent={"Fixed time"}
                    uncheckedContent={"Smart schedule"}
                    checked={value === "SCHEDULED"}
                    onChange={(e) => {
                      onChange(e.target.checked ? "SCHEDULED" : "QUEUED");
                    }}
                  />
                </div>
              }
            />
          );
        }}
      />

      <ContentSwitchContainer
        contentId={watchStatus === "QUEUED" ? "smart" : "manual"}
      >
        <ContentSwitchContent contentId="manual">
          <Manual timeZone={timeZone} />
        </ContentSwitchContent>
        <ContentSwitchContent contentId="smart">
          <Smart />
        </ContentSwitchContent>
      </ContentSwitchContainer>
    </div>
  );
};

export default PostFormWhenToPostSection;
