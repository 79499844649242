import {
  useMemo,
  useState,
  forwardRef,
  useRef,
  useEffect,
  ComponentProps,
  ReactNode,
} from "react";
import {
  Search as SearchIcon,
  X as XIcon,
  ChevronDown as ChevronDownIcon,
  Check as CheckIcon,
} from "react-feather";
import { escapeRegExp } from "../../libs/utils";
import { mergeClassNames, mergeRefs } from "../../libs/components";

import Button from "../buttons/Button";
import OutlineButton from "../buttons/Outline";
import DropdownMenuButton from "../buttons/DropdownMenu";
import IconButton from "../buttons/Icon";
import DropdownMenu from "../menus/Dropdown";

export interface SelectOption {
  text: string;
  value: string;
}

interface SelectProps {
  options: SelectOption[];
  highlightOptions?: SelectOption[];
  labelText: string;
  value?: SelectOption["value"];
  onChange?: (newValue: SelectOption["value"]) => void;
  onBlur?: () => void;
  name?: string;
  renderSelectedOption?: (option?: SelectOption) => ReactNode;
  renderOption?: (option: SelectOption) => ReactNode;
  ButtonElement?: typeof Button;
  labelClassName?: string;
  dropdownClassName?: string;
  dropdownXAlign?: ComponentProps<typeof DropdownMenu>["xAlign"];
  toggleButtonClassName?: string;
  includeSearch?: boolean;
  error?: boolean;
  hideLabel?: boolean;
  hideDropdownIcon?: boolean;
  disabled?: boolean;
}

const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      options,
      highlightOptions = [],
      value = "",
      onChange,
      onBlur,
      labelText,
      name,
      renderSelectedOption,
      renderOption,
      ButtonElement = OutlineButton,
      labelClassName = "",
      dropdownClassName = "",
      dropdownXAlign,
      toggleButtonClassName = "",
      includeSearch = false,
      error = false,
      hideLabel = false,
      hideDropdownIcon = false,
      disabled = false,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [internalValue, setInternalValue] = useState(value);
    const selectedOption = useMemo(() => {
      return highlightOptions
        .concat(options)
        .find((option) => option.value === internalValue);
    }, [highlightOptions, internalValue, options]);
    const filteredOptions = useMemo(() => {
      if (includeSearch && searchTerm !== "") {
        const escapedSearchTerm = escapeRegExp(searchTerm);
        const searchRegex = new RegExp(escapedSearchTerm, "ig");
        return options.filter((option) => searchRegex.test(option.text));
      } else {
        return options;
      }
    }, [includeSearch, options, searchTerm]);
    let buttonClassName = "max-w-full font-normal";

    if (ButtonElement === OutlineButton) {
      buttonClassName = `${buttonClassName} ${error ? "border-red-500" : ""}`;
    }

    useEffect(() => {
      if (value) {
        setInternalValue(value);
      }
    }, [value]);

    useEffect(() => {
      if (inputRef && inputRef.current) {
        // Check if react-hook-form has set a default value and update
        // out internal state if it has so we can track and display the value.
        setInternalValue(inputRef.current.value);
      }
    }, []);

    const buildOptionButtons = (options: SelectOption[]) => {
      return options.map((option, index) => {
        const isSelected = option.value === value;
        return (
          <DropdownMenuButton
            key={option.value || `null-${index}`}
            className={isSelected ? "text-purple-500" : ""}
            onClick={() => {
              if (
                (!selectedOption || option.value !== selectedOption.value) &&
                inputRef.current
              ) {
                const newValue = "" + option.value;
                setInternalValue(newValue);

                if (onChange) {
                  onChange(newValue);
                }
              }
            }}
          >
            {isSelected && <CheckIcon className="w-4 h-4" />}
            <span className={`ml-2 ${!isSelected ? "pl-4" : ""}`}>
              {renderOption ? renderOption(option) : option.text}
            </span>
          </DropdownMenuButton>
        );
      });
    };

    return (
      <>
        <label
          className={
            hideLabel
              ? "sr-only"
              : mergeClassNames(
                  `font-bold block mb-2 default-transition ${
                    error ? "text-red-500" : ""
                  }`,
                  labelClassName
                )
          }
        >
          {labelText}
          <input
            ref={mergeRefs([ref, inputRef])}
            className="sr-only"
            name={name}
            value={internalValue}
            tabIndex={-1}
            readOnly={true}
          />
        </label>
        <DropdownMenu
          className={dropdownClassName}
          closeOnSelect={true}
          onMenuToggle={(isOpen) => {
            if (isOpen && includeSearch) {
              setSearchTerm("");
            }
          }}
          xAlign={dropdownXAlign}
        >
          <ButtonElement
            className={mergeClassNames(buttonClassName, toggleButtonClassName)}
            disabled={disabled}
          >
            {renderSelectedOption ? (
              renderSelectedOption(selectedOption)
            ) : (
              <div className="flex items-center w-full">
                <span className={`truncate ${hideDropdownIcon ? "" : "mr-2"}`}>
                  {selectedOption ? selectedOption.text : ""}
                </span>
                {!hideDropdownIcon && (
                  <ChevronDownIcon className="flex-shrink-0 ml-auto" />
                )}
              </div>
            )}
          </ButtonElement>
          <div onBlur={onBlur}>
            {includeSearch && (
              <div
                className="px-2 w-full flex items-center border-b border-gray-200 focus-within:text-purple-500 focus-within:border-purple-500"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="px-2 flex-shrink-0">
                  <SearchIcon />
                </div>
                <input
                  className="flex-grow"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <IconButton
                  className={`flex-shrink-0 ${
                    searchTerm === "" ? "opacity-0" : "opacity-100"
                  }`}
                  tabIndex={searchTerm === "" ? -1 : 0}
                  onClick={() => setSearchTerm("")}
                  title="Clear search"
                >
                  <XIcon />
                </IconButton>
              </div>
            )}
            <div
              className="p-2 overflow-x-hidden overflow-y-auto"
              style={{ maxHeight: 400 }}
            >
              {highlightOptions.length > 0 && (
                <>
                  {buildOptionButtons(highlightOptions)}
                  <div className="w-full h-px my-2 bg-gray-200"></div>
                </>
              )}
              {buildOptionButtons(filteredOptions)}
            </div>
          </div>
        </DropdownMenu>
      </>
    );
  }
);

export default Select;
