import React from "react";
import {
  Info as InfoIcon,
  CornerUpRight as CornerUpRightIcon,
  Repeat as RepeatIcon,
} from "react-feather";
import { Controller, useFormContext } from "react-hook-form";
import CheckboxToggle from "../../../../../components/form/CheckboxToggle";
import Tooltip from "../../../../../components/Tooltip";

import { PostFormValues } from "../../../../../types/posts";
import PostFormRow from "../../Row";

const PostFormWhenToPostSectionSmart: React.FC = () => {
  const { control } = useFormContext<PostFormValues>();

  return (
    <>
      <div className="pt-2 pl-10 flex items-center">
        <InfoIcon className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400" />
        <p className="text-sm">
          Seenly will automatically post this content at the best time to
          maximise engagement without competing with your existing content
        </p>
      </div>
      <Controller
        control={control}
        name="recycle"
        render={({ field: { onChange, value, ref } }) => {
          const isRecycle = value;

          return (
            <PostFormRow
              className="mt-4"
              icon={
                <div className="h-full flex items-center">
                  {isRecycle ? (
                    <RepeatIcon className="h-6 w-6" />
                  ) : (
                    <CornerUpRightIcon className="h-6 w-6" />
                  )}
                </div>
              }
              content={
                <div className="ml-4">
                  <Tooltip
                    content={
                      isRecycle
                        ? "A copy of this content will be added back to your smart schedule drafts after it has been published"
                        : "This content will not be added back to your smart schedule after publishing"
                    }
                  >
                    <CheckboxToggle
                      ref={ref}
                      className="w-full"
                      checked={!isRecycle}
                      onChange={() => {
                        onChange(!isRecycle);
                      }}
                      checkedContentClassName="px-4 py-2 text-center"
                      uncheckedContentClassName="px-4 py-2 text-center"
                      checkedContent="Publish once"
                      uncheckedContent="Recycle after publishing"
                    />
                  </Tooltip>
                </div>
              }
            />
          );
        }}
      />
    </>
  );
};

export default PostFormWhenToPostSectionSmart;
