import React, { useState, useRef, useEffect } from "react";
import H4 from "../headings/H4";
import SectionTitle from "./SsectionTitle";

interface WordCountSelectorProps {
  onChange: (value: number) => void;
}

const WordCountSelector: React.FC<WordCountSelectorProps> = ({ onChange }) => {
  const [wordCount, setWordCount] = useState(50);
  const scrollContainerRef = useRef<HTMLInputElement>(null);

  const onSliderChange = (event: any) => {
    const newWordCount = Number(event.target.value);
    setWordCount(newWordCount);
    onChange(newWordCount);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const scrollPercentage =
          scrollContainerRef.current.scrollLeft /
          (scrollContainerRef.current.scrollWidth -
            scrollContainerRef.current.clientWidth);
        const newWordCount = Math.floor(scrollPercentage * 100); // Assuming maximum word count is 100
        setWordCount(newWordCount);
        onChange(newWordCount);
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onChange]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2">
        <SectionTitle title={"Approximate Words"}></SectionTitle>
        <input
          id="wordCountInput"
          type="number"
          min="0"
          className="w-20 border border-gray-300 rounded-lg p-2 bg-gray-200"
          onChange={(e) => onChange(parseInt(e.target.value))}
          value={wordCount}
        />
      </div>
      <input
        ref={scrollContainerRef}
        type="range"
        min="1"
        max="250"
        value={wordCount}
        className="slider w-full accent-amber-400"
        id="myRange"
        onChange={onSliderChange}
      />
    </div>
  );
};

export default WordCountSelector;
