import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Calendar as CalendarIcon,
  Plus as PlusIcon,
} from "react-feather";

import {
  useActiveWorkspace,
  useCurrentUser,
  useNow,
  useActiveWorkspaceTimeZone,
  useShowModal,
} from "../libs/hooks/app";
import { useWorkspaceUserPreferences } from "../libs/hooks/userPreferences";
import { usePostsWithScheduledProp } from "../libs/hooks/posts";
import { usePlatforms } from "../libs/hooks/platforms";
import { useQueryParams } from "../libs/hooks/general";
import {
  hasScheduleFuturePostsLimit,
  getAvailableScheduleFuturePosts,
} from "../libs/subscriptions";

import IconButton from "../components/buttons/Icon";
import PrimaryButton from "../components/buttons/Primary";
import PageActionButton from "../components/buttons/PageAction";
import DatePicker from "../components/menus/DatePicker";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import Calendar from "../containers/Calendar";
import CalendarSettingsMenu from "../containers/menus/CalendarSettings";
import UpgradeSubscriptionButton from "../containers/buttons/UpgradeSubscription";

const SchedulePage: React.FC = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const showModal = useShowModal();
  const user = useCurrentUser();
  const workspace = useActiveWorkspace();
  const { isLoading: isLoadingPreferences, data: workspacePreferences } =
    useWorkspaceUserPreferences(user.id, workspace.id);
  const timeZone = useActiveWorkspaceTimeZone();
  const dayQueryParam = queryParams.get("day") || "";
  const requestedDay = dayQueryParam
    ? DateTime.fromISO(dayQueryParam, { zone: timeZone })
    : null;
  const today = useNow();
  const [startOfWeek, setStartOfWeek] = useState(
    requestedDay && requestedDay.isValid
      ? requestedDay.startOf("week")
      : today.startOf("week")
  );
  const { data: posts } = usePostsWithScheduledProp(
    workspace.id,
    startOfWeek.toISO(),
    startOfWeek.plus({ days: 7 }).toISO()
  );
  const { data: platforms } = usePlatforms(workspace.id);
  const todayISODate = today.toISODate();
  const lastWeekISODate = startOfWeek.minus({ weeks: 1 }).toISODate();
  const nextWeekISODate = startOfWeek.plus({ weeks: 1 }).toISODate();
  const hasPostsLimit = hasScheduleFuturePostsLimit(workspace);
  const availablePostsCount = getAvailableScheduleFuturePosts(workspace);
  const isAtPostLimit = availablePostsCount !== null && availablePostsCount < 1;

  useEffect(() => {
    const paramDay = dayQueryParam
      ? DateTime.fromISO(dayQueryParam, { zone: timeZone })
      : null;
    const day =
      paramDay && paramDay.isValid
        ? paramDay
        : DateTime.fromISO(todayISODate, { zone: timeZone });

    setStartOfWeek(day.startOf("week"));
  }, [dayQueryParam, timeZone, todayISODate]);

  return (
    <AppPageWithHeader>
      <div className="h-full w-full overflow-hidden flex flex-col">
        <div className="pt-4 px-4 w-full flex-shrink-0 flex items-center">
          <PrimaryButton
            id="create-post-cal-button"
            onClick={() =>
              isAtPostLimit
                ? showModal("changeSubscription", {
                    workspace,
                    limitExceeded: true,
                    redirectOnSuccess: false,
                  })
                : showModal("createPost", {})
            }
          >
            <PlusIcon className="mr-2" />
            Create content
          </PrimaryButton>

          {hasPostsLimit && (
            <span
              className={`ml-4 ${
                availablePostsCount !== null && availablePostsCount > 0
                  ? "text-gray-500"
                  : "text-red-500"
              }`}
            >{`${availablePostsCount} ${
              availablePostsCount === 1 ? "post" : "posts"
            } remaining`}</span>
          )}
          {hasPostsLimit && (
            <UpgradeSubscriptionButton
              className="ml-2"
              workspace={workspace}
              context="calendar"
            />
          )}
          <PageActionButton
            className="ml-auto"
            onClick={() => {
              navigate(`/calendar?day=${today.toISODate()}`);
            }}
          >
            Today
          </PageActionButton>
          <IconButton
            className="ml-2 from-gray-200 to-gray-200 text-gray-600"
            title={`Show previous week from ${lastWeekISODate}`}
            onClick={() => {
              navigate(`/calendar?day=${lastWeekISODate}`);
            }}
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            className="ml-2 from-gray-200 to-gray-200 text-gray-600"
            title={`Show next week from ${nextWeekISODate}`}
            onClick={() => {
              navigate(`/calendar?day=${nextWeekISODate}`);
            }}
          >
            <ArrowRightIcon />
          </IconButton>
          <div className="ml-2">
            <DatePicker
              buttonClassName="rounded-full px-2 py-2 bg-gray-200 text-gray-600"
              buttonContent={<CalendarIcon className="text-gray-600" />}
              value={startOfWeek.toSeconds()}
              xAlign="right"
              closeOnSelect={true}
              highlightWeek={true}
              timeZone={timeZone}
              onChange={(newDate) => {
                if (newDate) {
                  const newStartOfWeekISODate = DateTime.fromSeconds(newDate, {
                    zone: timeZone,
                  })
                    .startOf("week")
                    .toISODate();
                  navigate(`/calendar?day=${newStartOfWeekISODate}`);
                }
              }}
            />
          </div>
          <CalendarSettingsMenu className="ml-2" />
        </div>
        {!isLoadingPreferences && (
          <div className="flex-grow overflow-hidden">
            <Calendar
              className="pt-4"
              today={today}
              startOfWeek={startOfWeek}
              daysPerWeek={workspacePreferences!.calendarDaysPerWeek}
              dayStartsAt={workspacePreferences!.calendarDayStartsAt}
              showHours={workspacePreferences!.calendarShowHours}
              posts={posts || []}
              platforms={platforms || []}
            />
          </div>
        )}
      </div>
    </AppPageWithHeader>
  );
};

export default SchedulePage;
