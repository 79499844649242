import React, { useEffect, useRef, useState } from "react";

import { mergeClassNames } from "../../../../libs/components";
import { ImageAttachment } from "../../../../types/posts";

import PostImageAttachmentImage from "../../../images/PostAttachment";

const COLOURS = {
  GRAY: "rgba(0, 0, 0, 0.6)",
};
const MULTI_IMAGE_HEIGHT = 552;

interface LinkedInPostPreviewImagesProps {
  imageAttachments: ImageAttachment[];
  className?: string;
}
const LinkedInPostPreviewImages: React.FC<LinkedInPostPreviewImagesProps> = ({
  imageAttachments,
  className = "",
}) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const [imageContainerWidth, setImageContainerWidth] = useState(0);

  const slot1 = imageAttachments.length > 0 ? imageAttachments[0] : null;
  const slot2 = imageAttachments.length > 4 ? imageAttachments[1] : null;
  const slot3 =
    imageAttachments.length > 4
      ? imageAttachments[2]
      : imageAttachments.length > 1
      ? imageAttachments[1]
      : null;
  const slot4 =
    imageAttachments.length > 4
      ? imageAttachments[3]
      : imageAttachments.length > 2
      ? imageAttachments[2]
      : null;
  const slot5 =
    imageAttachments.length > 4
      ? imageAttachments[4]
      : imageAttachments.length > 3
      ? imageAttachments[3]
      : null;
  const canShowImages = imageContainerWidth > 0 && !!slot1;
  const excessImages = Math.max(0, imageAttachments.length - 5);

  useEffect(() => {
    if (imageContainerRef.current) {
      setImageContainerWidth(imageContainerRef.current.clientWidth);
    }
  }, []);

  return (
    <div
      ref={imageContainerRef}
      className={mergeClassNames(
        "overflow-hidden w-full flex flex-wrap",
        className
      )}
      style={{
        height: !!slot3 ? MULTI_IMAGE_HEIGHT : undefined,
      }}
    >
      {canShowImages && (
        <>
          <div
            className={`relative ${
              slot2 || (slot3 && !slot4) ? `w-1/2` : "w-full"
            } ${slot3 && slot4 ? "h-3/5" : "h-full"}`}
          >
            <PostImageAttachmentImage
              className="w-full h-full object-center object-cover border border-transparent"
              containerClassName="absolute inset-0"
              imageAttachment={slot1!}
            />
          </div>
          {slot2 && (
            <div className="w-1/2 h-3/5 relative">
              <PostImageAttachmentImage
                className="w-full h-full object-center object-cover border border-transparent"
                containerClassName="absolute inset-0"
                imageAttachment={slot2}
              />
            </div>
          )}
          {slot3 && (
            <div
              className={`relative ${slot4 && slot5 ? "w-1/3" : "w-1/2"} ${
                slot4 ? "h-2/5" : "h-full"
              }`}
            >
              <PostImageAttachmentImage
                className="w-full h-full object-center object-cover border border-transparent"
                containerClassName="absolute inset-0"
                imageAttachment={slot3}
              />
            </div>
          )}
          {slot4 && (
            <div className={`h-2/5 relative ${slot5 ? "w-1/3" : "w-1/2"}`}>
              <PostImageAttachmentImage
                className="w-full h-full object-center object-cover border border-transparent"
                containerClassName="absolute inset-0"
                imageAttachment={slot4}
              />
            </div>
          )}
          {slot5 && (
            <div className="h-2/5 w-1/3 relative">
              <PostImageAttachmentImage
                className="w-full h-full object-center object-cover border border-transparent"
                containerClassName="absolute inset-0"
                imageAttachment={slot5}
              />
              {excessImages && (
                <div
                  className="absolute inset-0 border border-transparent flex items-center justify-center text-3xl text-white"
                  style={{ backgroundColor: COLOURS.GRAY }}
                >
                  {`+${excessImages}`}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LinkedInPostPreviewImages;
