import React, {
  useCallback,
  useEffect,
  useMemo,
  startTransition,
  useState,
} from "react";
import TwitterText from "twitter-text";
import { ContentState } from "draft-js";
import { Controller, useFormContext } from "react-hook-form";
import {
  Paperclip as PaperclipIcon,
  AtSign as AtSignIcon,
  Link as LinkIcon,
} from "react-feather";
import {
  AnnotationIcon,
  LightBulbIcon,
  FlagIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";

import { Platform, SelectedEntities } from "../../../../types/platforms";
import {
  PostFormExtentionContent,
  PostFormValues,
} from "../../../../types/posts";
import { mergeClassNames } from "../../../../libs/components";
import {
  appendEntityToEndOfContent,
  getEntitiesByTypeFromContentState,
  replaceEntityDisplayText,
} from "../../../../libs/postTextArea";
import { useDebouncedFunction } from "../../../../libs/hooks/general";

import PostFormRow from "../Row";
import PostTextArea from "../TextArea";
import PostAttachment from "../Attachment";
import { CHARACTER_LIMIT } from "../textArea/schemas";
import PrimaryTextButton from "../../../../components/buttons/PrimaryText";
import Tooltip from "../../../../components/Tooltip";
import CheckboxToggle from "../../../../components/form/CheckboxToggle";
import { ensureUrlHasProtocol } from "../../../../libs/utils";
import BulkUpload from "../input/BulkUpload";
import { WorkspaceSubscription } from "../../../../types/workspaces";

function getHasIncompleteMentions(
  platformTypes: Platform["type"][],
  contentState: ContentState
) {
  const entitiesByType = getEntitiesByTypeFromContentState(contentState);

  for (const mentionEntity of Object.values(entitiesByType.MENTION)) {
    const entityData = mentionEntity.entity.getData();
    if (
      !platformTypes.every(
        (platformType) => !!entityData.mentions[platformType]
      )
    ) {
      // If not every platform has a mention set then there are
      // incomplete mentions.
      return true;
    }
  }

  const mentions: string[] = [];

  for (const contentBlock of contentState.getBlockMap().toArray()) {
    if (mentions.length > 0) {
      break;
    }

    TwitterText.extractMentionsWithIndices(contentBlock.getText()).forEach(
      (candidate) => {
        if (!contentBlock.getEntityAt(candidate.indices[0])) {
          mentions.push(candidate.screenName);
        }
      }
    );
  }

  return mentions.length > 0;
}

function extractCandidateLinkAttachments(contentState: ContentState) {
  const urls = TwitterText.extractUrlsWithIndices(contentState.getPlainText());
  return urls.map(({ url }) => ensureUrlHasProtocol(url));
}

interface PostFormWhatToPostSectionProps {
  allowedSocials: PostFormValues["socials"];
  selectedPlatformTypes: Platform["type"][];
  extensionContent: PostFormExtentionContent;
  setExtensionContent: (newExtensionContent: PostFormExtentionContent) => void;
  className?: string;
  workspaceSubscription: WorkspaceSubscription | undefined;
  onValidCSVUpload: Function;
}
const PostFormWhatToPostSection: React.FC<PostFormWhatToPostSectionProps> = ({
  allowedSocials,
  selectedPlatformTypes,
  extensionContent,
  setExtensionContent,
  className = "",
  workspaceSubscription,
  onValidCSVUpload,
}) => {
  const [hasIncompleteMentions, setHasIncompleteMentions] = useState(false);
  const [candidateLinkAttachments, setCandidateLinkAttachments] = useState<
    string[]
  >([]);
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext<PostFormValues>();

  const watchSelectedSocials = watch("socials");
  const watchContentState = watch("contentState");
  const watchAttachment = watch("attachment");
  const hasLinksToAttach =
    !watchAttachment && candidateLinkAttachments.length > 0;

  const selectedEntities = useMemo(() => {
    const byPlatformId = watchSelectedSocials.reduce<{
      [platformId: string]: SelectedEntities;
    }>((carry, { platform, platformEntityId }) => {
      const platformEntity = platform.entities[platformEntityId];

      if (platform && platformEntity) {
        if (carry[platform.id]) {
          carry[platform.id].entities.push(platformEntity);
        } else {
          carry[platform.id] = {
            platform,
            entities: [platformEntity],
          };
        }
      }

      return carry;
    }, {});

    return Object.values(byPlatformId);
  }, [watchSelectedSocials]);
  const selectedEntitiesByPlatformType = useMemo(() => {
    return selectedEntities.reduce<{
      [platformType: string]: SelectedEntities[];
    }>((carry, selected) => {
      const platformType = selected.platform.type;
      if (carry[platformType]) {
        carry[platformType].push(selected);
      } else {
        carry[platformType] = [selected];
      }

      return carry;
    }, {});
  }, [selectedEntities]);
  const platformTypes = useMemo(
    () => Object.keys(selectedEntitiesByPlatformType) as Platform["type"][],
    [selectedEntitiesByPlatformType]
  );

  let characterLimit: number | undefined = undefined;
  let countCharacters: (text: string) => number = (text) => text.length;
  let textAreaMustContainAttachmentLinkUrl = false;
  let canEditLinkPreview = true;

  if (watchSelectedSocials.length === 1) {
    switch (watchSelectedSocials[0].platform.type) {
      case "LINKEDIN":
        characterLimit = CHARACTER_LIMIT.LINKEDIN;
        break;
      case "TWITTER":
        textAreaMustContainAttachmentLinkUrl = true;
        canEditLinkPreview = false;
        characterLimit = CHARACTER_LIMIT.TWITTER;
        countCharacters = (text: string) => {
          const { weightedLength } = TwitterText.parseTweet(text);
          return weightedLength;
        };
        break;
    }
  }

  const characterCount = countCharacters(watchContentState.getPlainText());
  const hasCharacters = characterCount > 0;
  const showWritingPromptButton =
    !hasCharacters &&
    extensionContent !== "writing-prompt" &&
    (workspaceSubscription?.status === "active" ||
      workspaceSubscription?.status === "trialing"); //Only active and trialing users have access to the Seenly AI Assistant

  const ensureTextAreaContainsLinkAttachmentUrl = (
    contentState: ContentState,
    url: string
  ) => {
    // Some platforms (e.g. Twitter) require that the url from the link attachment
    // be present in the actual post text as well.

    const entitiesByType = getEntitiesByTypeFromContentState(contentState);
    const linkEntities = entitiesByType.LINK;
    const attachmentLinkEntity = Object.values(linkEntities).find(
      (candidate) => candidate.entity.getData().useAsAttachment
    );

    if (!attachmentLinkEntity) {
      // No Link entity is flagged as being used as an attachment so append
      // the text to the end.
      return appendEntityToEndOfContent(
        contentState,
        "LINK",
        {
          text: url,
          url: url,
          useAsAttachment: true,
        },
        url
      );
    }

    const contentBlock = contentState.getBlockForKey(
      attachmentLinkEntity.blockKey
    );

    if (!contentBlock) {
      return null;
    }

    const urlText = contentBlock
      .getText()
      .substring(attachmentLinkEntity.start, attachmentLinkEntity.end);

    if (urlText !== url) {
      let newContentState = replaceEntityDisplayText(
        attachmentLinkEntity.blockKey,
        attachmentLinkEntity.entityKey,
        contentState,
        url
      );

      return newContentState.mergeEntityData(attachmentLinkEntity.entityKey, {
        text: url,
        url,
      });
    }

    return null;
  };

  const checkForTextAreaHints = useCallback(() => {
    // Use a transition here to deprioritise the update. Tell React not to
    // block rendering of any more important parts of the UI.
    startTransition(() => {
      setHasIncompleteMentions(
        getHasIncompleteMentions(platformTypes, watchContentState)
      );
      setCandidateLinkAttachments(
        extractCandidateLinkAttachments(watchContentState)
      );
    });
  }, [platformTypes, watchContentState]);

  const handleTextAreaBlur = () => {
    checkForTextAreaHints();

    if (!textAreaMustContainAttachmentLinkUrl) {
      return;
    }

    const currentAttachment = getValues("attachment");

    if (
      currentAttachment &&
      currentAttachment.type === "LINK" &&
      TwitterText.isValidUrl(currentAttachment.link.url, false, false)
    ) {
      const contentState = getValues("contentState");
      const newContentState = ensureTextAreaContainsLinkAttachmentUrl(
        contentState,
        currentAttachment.link.url
      );

      if (newContentState) {
        // There is some weirdness where react-hook-form or draft-js doesn't
        // detect changes in the contentState form value during a blur event
        // so we can just pop this off into the end of the event loop to run
        // after the blur event handling is finished.
        window.requestAnimationFrame(() => {
          setValue("contentState", newContentState);
        });
      }
    }
  };

  const handleAttachmentChange = useDebouncedFunction(
    ({
      newAttachment,
      oldAttachment,
    }: {
      newAttachment: PostFormValues["attachment"];
      oldAttachment: PostFormValues["attachment"];
    }) => {
      if (!textAreaMustContainAttachmentLinkUrl) {
        return;
      }

      if (
        newAttachment &&
        newAttachment.type === "LINK" &&
        newAttachment.link.url.length > 0 &&
        TwitterText.isValidUrl(newAttachment.link.url, false, false)
      ) {
        const contentState = getValues("contentState");
        const newContentState = ensureTextAreaContainsLinkAttachmentUrl(
          contentState,
          newAttachment.link.url
        );

        if (newContentState) {
          setValue("contentState", newContentState);
        }
      }
    }
  );

  const handleOnClickUrl = useCallback(
    (url: string) => {
      const currentAttachment = getValues("attachment");

      if (
        !currentAttachment ||
        (currentAttachment.type === "LINK" &&
          currentAttachment.link.url !== url)
      ) {
        setValue("attachment", {
          type: "LINK",
          link: {
            url,
          },
        });
      }
    },
    [getValues, setValue]
  );

  useEffect(() => {
    if (textAreaMustContainAttachmentLinkUrl) {
      const attachment = getValues("attachment");

      if (
        attachment &&
        attachment.type === "LINK" &&
        attachment.link.url.length > 0 &&
        TwitterText.isValidUrl(attachment.link.url, false, false)
      ) {
        const contentState = getValues("contentState");
        const newContentState = ensureTextAreaContainsLinkAttachmentUrl(
          contentState,
          attachment.link.url
        );

        if (newContentState) {
          setValue("contentState", newContentState);
        }
      }
    }
  }, [getValues, setValue, textAreaMustContainAttachmentLinkUrl]);

  useEffect(() => {
    checkForTextAreaHints();
  }, [checkForTextAreaHints]);

  return (
    <div className={mergeClassNames("group", className)}>
      <div className="relative">
        <h3 className="font-bold text-sm text-gray-300 default-transition group-focus-within:text-purple-500">
          WHAT TO POST
        </h3>
        {showWritingPromptButton && (
          <div className="absolute top-1/2 right-0 -translate-y-1/2">
            <PrimaryTextButton
              className="font-normal leading-5"
              size="sm"
              onClick={() => setExtensionContent("writing-prompt")}
            >
              <LightBulbIcon className="mr-2 h-6 w-6" />
              Click here for Seenly AI Assistant!
            </PrimaryTextButton>
          </div>
        )}
      </div>
      <PostFormRow
        className="mt-2"
        contentContainerClassName="overflow-hidden"
        icon={
          <div className="mt-3 flex flex-col items-center">
            <AnnotationIcon
              className={`h-6 w-6 default-transition
              ${!errors.contentState ? "" : "text-red-500"}
            `}
            />
            {hasIncompleteMentions && (
              <Tooltip
                content={
                  <p className="w-40">
                    <strong>Incomplete mentions</strong>
                    <br />
                    Click on the @mention phrase in your post text to search for
                    a person or page to mention
                  </p>
                }
              >
                <AtSignIcon className="mt-2 h-4 w-4 default-transition" />
              </Tooltip>
            )}
            {hasLinksToAttach && (
              <Tooltip
                content={
                  <p className="w-40">
                    <strong>Link attachments</strong>
                    <br />
                    Click on one of the links in your post text to add it as an
                    attachment
                  </p>
                }
              >
                <LinkIcon className="mt-2 h-4 w-4 default-transition" />
              </Tooltip>
            )}
          </div>
        }
        content={
          <div className="ml-4">
            <Controller
              control={control}
              name="contentState"
              render={({ field: { onChange, onBlur, value } }) => (
                <PostTextArea
                  selectedEntities={selectedEntities}
                  value={value as ContentState}
                  placeHolder="What would you like to say?"
                  onChange={onChange}
                  onBlur={() => {
                    handleTextAreaBlur();
                    onBlur();
                  }}
                  onClickUrl={handleOnClickUrl}
                  error={!!errors.contentState}
                  characterLimit={characterLimit}
                  countCharacters={countCharacters}
                />
              )}
            />
          </div>
        }
      />
      {selectedPlatformTypes &&
        selectedPlatformTypes.length === 1 &&
        !selectedPlatformTypes.includes("TWITTER") &&
        !selectedPlatformTypes.includes("FACEBOOK") && (
          <BulkUpload
            allowedSocials={allowedSocials}
            onValidCSVUpload={onValidCSVUpload}
          />
        )}

      <PostFormRow
        contentContainerClassName="overflow-hidden"
        icon={
          <div className="h-12 flex items-center">
            <PaperclipIcon
              className={`h-6 w-6 default-transition
              ${!errors.attachment ? "" : "text-red-500"}
            `}
            />
          </div>
        }
        content={
          <Controller
            control={control}
            name="attachment"
            render={({ field: { onChange, value } }) => (
              <PostAttachment
                className="pl-4"
                selectedPlatformTypes={selectedPlatformTypes}
                canEditLinkPreview={canEditLinkPreview}
                candidateLinkAttachments={candidateLinkAttachments}
                value={value}
                onChange={(newAttachment) => {
                  handleAttachmentChange({
                    newAttachment,
                    oldAttachment: value,
                  });
                  onChange(newAttachment);

                  if (
                    extensionContent === "image-attachments" &&
                    newAttachment === null
                  ) {
                    setExtensionContent(null);
                  }
                }}
                showImageAttachments={() =>
                  setExtensionContent("image-attachments")
                }
                showVideoAttachment={() =>
                  setExtensionContent("video-attachment")
                }
                isImageAttachmentsVisible={
                  extensionContent === "image-attachments"
                }
                isVideoAttachmentVisible={
                  extensionContent === "video-attachment"
                }
                error={!!errors.attachment}
              />
            )}
          />
        }
      />
      <Controller
        control={control}
        name="draft"
        render={({ field: { onChange, value, ref } }) => {
          const isDraft = value;

          return (
            <PostFormRow
              className="mt-4"
              icon={
                <div className="h-full flex items-center">
                  {isDraft ? (
                    <FlagIcon className="h-6 w-6" />
                  ) : (
                    <CheckCircleIcon className="h-6 w-6" />
                  )}
                </div>
              }
              content={
                <div className="ml-4">
                  <Tooltip
                    content={
                      isDraft
                        ? "This content is a work in progress and should not be published to the social accounts"
                        : "This content requires no more changes and is ready to be published to the social accounts"
                    }
                  >
                    <CheckboxToggle
                      ref={ref}
                      className="w-full"
                      checked={!isDraft}
                      onChange={() => {
                        onChange(!isDraft);
                      }}
                      checkedContentClassName="px-4 py-2 text-center"
                      uncheckedContentClassName="px-4 py-2 text-center"
                      checkedContent="Ready for publishing"
                      uncheckedContent="Save as a draft"
                    />
                  </Tooltip>
                </div>
              }
            />
          );
        }}
      />
    </div>
  );
};

export default PostFormWhatToPostSection;
