import React, { ReactNode, useRef } from "react";
import { useTransition, animated } from "@react-spring/web";

interface ExpandCollapseAnimationProps {
  children: ReactNode;
  className?: string;
}
const ExpandCollapseAnimation: React.FC<ExpandCollapseAnimationProps> = ({
  children,
  className = "",
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const transitions = useTransition(children, {
    keys: (item) => (item ? "true" : "false"),
    from: { opacity: 0, height: 0, width: "auto" },
    enter: () => async (next) => {
      const height =
        contentRef && contentRef.current ? contentRef.current.clientHeight : 0;
      const width =
        contentRef && contentRef.current ? contentRef.current.clientWidth : 0;

      await next({ opacity: 0, width, height: 0, immediate: true });
      await next({
        opacity: 1,
        height,
        immediate: false,
      });
      await next({
        height: "auto",
        width: "auto",
        immediate: true,
      });
    },
    leave: () => async (next) => {
      const height =
        contentRef && contentRef.current ? contentRef.current.clientHeight : 0;
      const width =
        contentRef && contentRef.current ? contentRef.current.clientWidth : 0;

      await next({ opacity: 1, height, width, immediate: true });
      await next({ opacity: 0, height: 0, immediate: false });
      await next({ width: "auto", immediate: true });
    },
  });

  return transitions((style, item) => {
    return (
      item && (
        <animated.div
          className={`relative ${className}`}
          style={{
            opacity: style.opacity,
            height: style.height,
            width: style.width,
            overflow: style.opacity.to((o) => (o < 1 ? "hidden" : "visible")),
          }}
        >
          <animated.div
            ref={contentRef}
            className="w-full overflow-auto"
            style={{
              position: style.opacity.to((o) =>
                o > 0 && o < 1 ? "absolute" : (undefined as any)
              ),
              top: style.opacity.to((o) =>
                o > 0 && o < 1 ? 0 : (undefined as any)
              ),
              left: style.opacity.to((o) =>
                o > 0 && o < 1 ? 0 : (undefined as any)
              ),
            }}
          >
            {item}
          </animated.div>
        </animated.div>
      )
    );
  });
};

export default ExpandCollapseAnimation;
