import React, { ComponentProps } from "react";

import Modal from "./Modal";
import ContentStack from "./animations/ContentStack";

interface ModalContentStackProps
  extends Omit<ComponentProps<typeof Modal>, "children">,
    Omit<ComponentProps<typeof ContentStack>, "defaultContentId"> {
  defaultContentId?: string;
}

const ModalContentStack: React.FC<ModalContentStackProps> = ({
  children,
  defaultContentId = "home",
  ...modalProps
}) => {
  return (
    <Modal {...modalProps}>
      <ContentStack defaultContentId={defaultContentId}>
        {children}
      </ContentStack>
    </Modal>
  );
};

export default ModalContentStack;
