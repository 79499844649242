import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { Controller, useFormContext } from "react-hook-form";
import { Calendar as CalendarIcon, Clock as ClockIcon } from "react-feather";

import { Platform } from "../../../../../types/platforms";
import { PostFormValues } from "../../../../../types/posts";

import PostFormRow from "../../Row";
import DateInput from "../../input/Date";
import TimeInput from "../../input/Time";

interface PostFormWhenToPostSectionManualProps {
  timeZone: string;
}
const PostFormWhenToPostSectionManual: React.FC<
  PostFormWhenToPostSectionManualProps
> = ({ timeZone }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<PostFormValues>();

  const watchSelectedSocials = watch("socials");
  const selectedPlatformTypes = useMemo(() => {
    return watchSelectedSocials.reduce<Platform["type"][]>(
      (carry, { platform }) => {
        if (!carry.includes(platform.type)) {
          carry.push(platform.type);
        }

        return carry;
      },
      []
    );
  }, [watchSelectedSocials]);

  return (
    <div>
      <Controller
        control={control}
        name="scheduled"
        render={({ field: { onChange, value } }) => {
          const localNow = DateTime.local().setZone(timeZone);
          const scheduledDateTime = DateTime.fromISO(value, {
            zone: timeZone,
          });
          const isToday =
            localNow.toISODate() === scheduledDateTime.toISODate();

          return (
            <>
              <PostFormRow
                icon={
                  <div className="h-full flex items-center">
                    <CalendarIcon
                      className={`h-6 w-6 default-transition ${
                        !errors.scheduled ? "" : "text-red-500"
                      }`}
                    />
                  </div>
                }
                content={
                  <DateInput
                    className="ml-4"
                    value={value}
                    timeZone={timeZone}
                    selectedPlatformTypes={selectedPlatformTypes}
                    onChange={onChange}
                    error={!!errors.scheduled}
                  />
                }
              />
              <PostFormRow
                className="mt-1"
                icon={
                  <div className="h-full flex items-center">
                    <ClockIcon
                      className={`h-6 w-6 default-transition ${
                        !errors.scheduled ? "" : "text-red-500"
                      }`}
                    />
                  </div>
                }
                content={
                  <TimeInput
                    className="ml-4"
                    value={value}
                    timeZone={timeZone}
                    selectedPlatformTypes={selectedPlatformTypes}
                    allowPast={!isToday}
                    onChange={onChange}
                    error={!!errors.scheduled}
                  />
                }
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default PostFormWhenToPostSectionManual;
