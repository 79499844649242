import React, { useEffect, useRef, useState } from "react";
import { Search as SearchIcon, Check as CheckIcon } from "react-feather";
import { useScroll, Handler } from "@use-gesture/react";

import { clampImageSize } from "../../../../../libs/utils";
import { useAsyncState } from "../../../../../libs/hooks/general";
import { useApiClient } from "../../../../../libs/hooks/app";
import { UnsplashImageAttachment } from "../../../../../types/posts";

import LoaderIcon from "../../../../../components/icons/Loader";
import Input from "../../../../../components/form/Input";
import LazyImage from "../../../../../components/images/Lazy";
import ExternalLink from "../../../../../components/links/External";

import { ReactComponent as NoResultsImage } from "../../../../../images/searching.svg";
import { ReactComponent as DesignInspirationImage } from "../../../../../images/design-inspiration.svg";

const LOAD_NEW_PAGE_SCROLL_THRESHOLD = 400;
const MAX_PREVIEW_HEIGHT = 250;
const MAX_PREVIEW_WIDTH = 400;

interface UnsplashUser {
  id: string;
  bio: string | null;
  first_name: string;
  instagram_username: string | null;
  last_name: string | null;
  links: {
    followers: string;
    following: string;
    html: string;
    likes: string;
    photos: string;
    portfolio: string;
    self: string;
  };
  location: string | null;
  name: string;
  portfolio_url: string | null;
  profile_image: {
    small: string;
    medium: string;
    large: string;
  };
  total_collections: number;
  total_likes: number;
  total_photos: number;
  twitter_username: string | null;
  updated_at: string;
  username: string;
}

interface UnsplashPhoto {
  id: string;
  created_at: string;
  updated_at: string;
  urls: {
    full: string;
    raw: string;
    regular: string;
    small: string;
    thumb: string;
  };
  alt_description: string | null;
  blur_hash: string | null;
  color: string | null;
  description: string | null;
  height: number;
  likes: number;
  links: {
    self: string;
    html: string;
    download: string;
    download_location: string;
  };
  promoted_at: string | null;
  width: number;
  user: UnsplashUser;
}

interface SearchResponse {
  results: UnsplashPhoto[];
  total: number;
  total_pages: number;
}

interface PostExtensionContentImageAttachmentsUnsplashProps {
  imageAttachments: UnsplashImageAttachment[];
  addImage: (image: UnsplashImageAttachment) => void;
  removeImage: (imageId: string) => void;
}

const PostExtensionContentImageAttachmentsUnsplash: React.FC<
  PostExtensionContentImageAttachmentsUnsplashProps
> = ({ imageAttachments, addImage, removeImage }) => {
  const apiClient = useApiClient();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isLoadingRandomImages, setIsLoadingRandomImages] = useState(true);
  const [images, setImages] = useState<UnsplashPhoto[]>([]);
  const { loading, setLoading, error, setError, clearError } = useAsyncState();
  const [searchValue, setSearchValue] = useState("");
  const [loadedPages, setLoadedPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const hasImages = images.length > 0;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const searchImages = async (query: string, page: number) => {
    const { data: response } = await apiClient.get("/proxies/unsplash", {
      params: {
        action: "search",
        query,
        page,
      },
    });
    const { data } = response as { data: SearchResponse };

    return [data.results, data.total_pages] as [UnsplashPhoto[], number];
  };

  const newImageSearch = async (newValue: string) => {
    const newSearchValue = newValue.trim();
    clearError();
    setLoadedPages(0);
    setTotalPages(0);
    setSearchValue(newSearchValue);
    setIsLoadingRandomImages(false);

    if (newSearchValue !== "") {
      setLoading(true);
      try {
        const [newImages, newtotalPages] = await searchImages(
          newSearchValue,
          1
        );
        setImages(newImages);
        setLoadedPages(1);
        setTotalPages(newtotalPages);
      } catch (e) {
        setImages([]);
        setError({
          title: "Whoops!",
          message: "Something went wrong. Please try again.",
        });
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleImageClick = (image: UnsplashPhoto, isSelected: boolean) => {
    const downloadLocation = image.links.download_location;
    const uri = downloadLocation.replace("https://api.unsplash.com", "");

    apiClient.get("/proxies/unsplash", {
      params: {
        action: "triggerDownload",
        uri,
      },
    });

    if (isSelected) {
      removeImage(image.id);
    } else {
      addImage({
        id: image.id,
        type: "UNSPLASH",
        title: image.description || image.alt_description || "Unknown",
        height: image.height,
        width: image.width,
        blurHash: image.blur_hash,
        urls: image.urls,
        author: {
          id: image.user.id,
          name: image.user.name,
          url: image.user.links.html,
        },
      });
    }
  };

  const handleImageContainerScroll: Handler<"scroll"> = async () => {
    if (
      !loading &&
      totalPages !== 0 &&
      loadedPages < totalPages &&
      scrollContainerRef.current
    ) {
      const clientheight = scrollContainerRef.current.clientHeight;
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      const scrollTop = scrollContainerRef.current.scrollTop;
      const distanceFromEnd = scrollHeight - clientheight - scrollTop;

      if (distanceFromEnd < LOAD_NEW_PAGE_SCROLL_THRESHOLD) {
        setLoading(true);
        try {
          const [newImages, newtotalPages] = await searchImages(
            searchValue,
            loadedPages + 1
          );
          setImages(images.concat(newImages));
          setLoadedPages(loadedPages + 1);
          setTotalPages(newtotalPages);
        } catch (e) {
          // Ignore errors here since it's just loading new pages. The user won't even notice
          // it failed and there is no need to bother them.
        }
        setLoading(false);
      }
    }
  };

  const scrollBindings = useScroll(handleImageContainerScroll);

  useEffect(() => {
    const loadRandomImages = async () => {
      const { data: response } = await apiClient.get("/proxies/unsplash", {
        params: {
          action: "random",
          count: 30,
        },
      });
      const { data } = response as { data: UnsplashPhoto[] };

      if (!inputRef.current || inputRef.current.value === "") {
        setImages(data);
        setLoadedPages(1);
        setTotalPages(1);
      }

      setIsLoadingRandomImages(false);
    };

    loadRandomImages();
  }, [apiClient]);

  return (
    <div className="relative w-full h-full overflow-hidden flex flex-col">
      {error({ className: "mb-2" })}
      <div className="p-2 shrink-0">
        <div className="flex items-center relative focus-within:text-purple-500">
          <Input
            ref={inputRef}
            className="pl-10 py-2 pr-4 placeholder-gray-500 border-gray-200 bg-gray-200 hover:bg-white focus:bg-white"
            labelText="Search Unsplash for free high-resolution photos"
            hideLabel={true}
            placeholder="Search Unsplash for free high-resolution photos"
            value={searchValue}
            debounceOnChange={newImageSearch}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-2">
            {loading ? <LoaderIcon /> : <SearchIcon />}
          </div>
        </div>
        <div className="pr-1 flex items-center justify-end text-sm text-gray-400">
          <span>
            powered by{" "}
            <ExternalLink href="https://unsplash.com?utm_source=seenly&utm_medium=referral">
              Unsplash
            </ExternalLink>
          </span>
        </div>
      </div>
      {hasImages && (
        <div
          ref={scrollContainerRef}
          className="grow overflow-x-hidden overflow-y-auto"
          {...scrollBindings()}
        >
          <div className="px-2 pb-2 flex flex-wrap justify-center items-start">
            {images.map((image) => {
              const isSelected = !!imageAttachments.find(
                (candidate) => candidate.id === image.id
              );
              const [clampedHeight, clampedWidth] = clampImageSize(
                image.height,
                image.width,
                MAX_PREVIEW_HEIGHT,
                MAX_PREVIEW_WIDTH
              );

              return (
                <div
                  key={image.id}
                  className={`m-1 shrink-0 relative rounded shadow bg-white border-4 border-transparent default-transition hover:scale-105 hover:shadow-lg hover:z-10 ${
                    isSelected ? "border-sky-200" : ""
                  }`}
                >
                  <button
                    type="button"
                    className="block"
                    onClick={(e) => {
                      if (e.defaultPrevented) {
                        return;
                      }

                      handleImageClick(image, isSelected);
                    }}
                  >
                    <div style={{ height: clampedHeight, width: clampedWidth }}>
                      <LazyImage
                        className="block"
                        blurHash={image.blur_hash}
                        src={image.urls.small}
                        alt={image.alt_description || ""}
                      />
                    </div>

                    {isSelected && (
                      <div className="absolute top-1 left-1 h-6 w-6 rounded-full bg-sky-200 flex items-center justify-center">
                        <CheckIcon className="h-5 w-5 text-sky-700" />
                      </div>
                    )}
                  </button>
                  <div className="px-2 text-left text-gray-600">
                    <div className="text-xs leading-4">Photo by</div>
                    <ExternalLink
                      className="block max-w-full truncate text-sm leading-4"
                      href={`${image.user.links.html}?utm_source=seenly&utm_medium=referral`}
                    >
                      {image.user.name}
                    </ExternalLink>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!loading && !hasImages && !!searchValue && (
        <div className="grow flex items-center justify-center">
          <div>
            <p className="text-lg" style={{ width: 0, minWidth: "100%" }}>
              Looks like Unsplash couldn't find any images for "{searchValue}"
            </p>

            <NoResultsImage className="mt-4 text-sky-200 w-96 h-96" />
          </div>
        </div>
      )}
      {isLoadingRandomImages && (
        <div className="grow flex items-center justify-center">
          <div>
            <div className="flex items-center">
              <LoaderIcon className="w-8 h-8" />
              <p className="ml-2 text-lg">
                Loading some random images for a little inspiration...
              </p>
            </div>

            <DesignInspirationImage className="mt-4 text-sky-200 w-96 h-96" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PostExtensionContentImageAttachmentsUnsplash;
