import React, { ComponentProps } from "react";
import { InternalFile } from "../../types/files";

import { clampImageSize } from "../../libs/utils";
import { mergeClassNames } from "../../libs/components";
import { useActiveWorkspaceFileAccessToken } from "../../libs/hooks/app";
import { buildFileUrl } from "../../libs/files";

import Modal from "../../components/Modal";
import InternalImage from "../../components/images/Internal";

interface ViewInternalFileModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  file: InternalFile;
  close: () => void;
}

const ViewInternalFileModal: React.FC<ViewInternalFileModalProps> = ({
  isVisible,
  close,
  file,
  className = "",
  closeButtonClassName = "",
  ...modalProps
}) => {
  const fileAccessToken = useActiveWorkspaceFileAccessToken();
  const fileHeight = file.height || 0;
  const fileWidth = file.width || 0;
  const [clampedHeight, clampedWidth] = clampImageSize(
    fileHeight,
    fileWidth,
    window.innerHeight - 48,
    window.innerWidth - 48
  );
  const fileUrl = buildFileUrl(file.path, fileAccessToken || "");

  return (
    <Modal
      {...modalProps}
      className={mergeClassNames(
        "sm:my-auto sm:mx-auto md:my-auto md:mx-auto bg-transparent shadow-none overflow-hidden justify-self-center",
        className
      )}
      closeButtonClassName={mergeClassNames(
        "from-white to-white",
        closeButtonClassName
      )}
      isVisible={isVisible}
      close={close}
    >
      <div
        className={`rounded-lg w-full h-full overflow-hidden ${
          clampedHeight === 0 ? "opacity-0" : "opacity-100"
        }`}
        style={{
          width: clampedWidth,
          height: clampedHeight,
        }}
      >
        {file.type === "IMAGE" && (
          <InternalImage
            containerClassName="h-full text-sky-500"
            file={file}
            preview={false}
          />
        )}
        {file.type === "VIDEO" && isVisible && (
          <video
            controls={true}
            style={{
              width: clampedWidth,
              height: clampedHeight,
            }}
          >
            <source src={fileUrl} type={file.contentType} />
          </video>
        )}
      </div>
    </Modal>
  );
};

export default ViewInternalFileModal;
