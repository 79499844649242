import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { animated, useTransition } from "@react-spring/web";

import { useOpenCloseStack } from "../libs/hooks/general";
import { mergeClassNames } from "../libs/components";

export interface DrawerProps {
  isVisible: boolean;
  close: () => void;
  children: React.ReactNode;
  left?: boolean;
  width?: number;
  widthUnit?: "px" | "rem";
  className?: string;
  onStatusChange?: (
    newStatus: "opening" | "open" | "closing" | "closed"
  ) => void;
}

const Drawer: React.FC<DrawerProps> = ({
  isVisible,
  close,
  children,
  left = true,
  width = 20,
  widthUnit = "rem",
  className = "",
  onStatusChange,
}) => {
  const transitions = useTransition(isVisible, {
    from: { x: left ? -100 : 100 },
    enter: { x: 0 },
    leave: { x: left ? -100 : 100 },
    onRest: () => {
      if (onStatusChange) {
        onStatusChange(isVisible ? "open" : "closed");
      }
    },
  });

  const openCloseHandlers = useOpenCloseStack(isVisible, close);

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(isVisible ? "opening" : "closing");
    }
  }, [isVisible, onStatusChange]);

  return ReactDOM.createPortal(
    transitions(({ x }, item, t) => {
      return item ? (
        <div
          className={`fixed z-10 top-0 left-0 right-0 bottom-0 min-h-screen min-w-screen overscroll-contain overflow-hidden default-transition bg-black/50 ${
            t.phase === "mount" || t.phase === "leave"
              ? "opacity-0 backdrop-blur-none"
              : "opacity-100 backdrop-blur-sm"
          }`}
        >
          <animated.aside
            className={mergeClassNames(
              `absolute top-0 h-full overflow-x-hidden overflow-y-auto overscroll-contain shadow-lg bg-white ${
                left ? "left-0" : "right-0"
              }`,
              className
            )}
            style={{
              width: `${width}${widthUnit}`,
              transform: x.to((per) => {
                return per === 0 ? "" : `translate3d(${per}%, 0, 0)`;
              }),
            }}
            {...openCloseHandlers}
          >
            {children}
          </animated.aside>
        </div>
      ) : null;
    }),
    document.getElementById("portal-container") || document.body
  );
};

export default Drawer;
