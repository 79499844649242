import { ComponentProps, useState } from "react";

import { LinkedInMentionEntityData } from "../../../../../../../types/posts";
import { getPlatformTypeName } from "../../../../../../../libs/platform";
import { useSearchLinkedInEntities } from "../../../../../../../libs/hooks/linkedin";

import DropdownMenuButton from "../../../../../../../components/buttons/DropdownMenu";

import SearchBase from "./Base";
import EntityCard from "../EntityCard";

const PLATFORM_TYPE_DISPLAY_NAME = getPlatformTypeName("LINKEDIN");

interface PostTextAreaMentionSearchLinkedInProps
  extends Omit<
    ComponentProps<typeof SearchBase>,
    | "children"
    | "isLoading"
    | "debouncedOnSearchTermChange"
    | "platformDisplayName"
  > {
  platformId: string;
  onClick: (value: LinkedInMentionEntityData) => void;
  isVisible?: boolean;
}
const PostTextAreaMentionSearchLinkedIn = ({
  platformId,
  onClick,
  defaultSearchTerm = "",
  isVisible = true,
  ...rest
}: PostTextAreaMentionSearchLinkedInProps) => {
  const [internalSearchTerm, setInternalSearchTerm] =
    useState(defaultSearchTerm);
  const { isFetching, data } = useSearchLinkedInEntities(
    { platformId, query: internalSearchTerm },
    { enabled: !!internalSearchTerm, keepPreviousData: true }
  );
  const entities = data || [];
  const hasResults = entities.length;

  if (!isVisible) {
    return null;
  }

  return (
    <SearchBase
      platformDisplayName={PLATFORM_TYPE_DISPLAY_NAME}
      isLoading={isFetching}
      defaultSearchTerm={defaultSearchTerm}
      debouncedOnSearchTermChange={setInternalSearchTerm}
      {...rest}
    >
      {entities.map((entityData) => {
        return (
          <DropdownMenuButton
            key={entityData.id}
            title={entityData.name}
            onClick={() =>
              onClick({
                id: entityData.id,
                text: entityData.name,
                platformType: "LINKEDIN",
                type: entityData.type,
              })
            }
          >
            <EntityCard
              name={entityData.name}
              vanityName={entityData.vanityName}
              pictureUrl={entityData.pictureUrl}
            />
          </DropdownMenuButton>
        );
      })}
      {!isFetching && !hasResults && (
        <p className="p-2 w-full text-center text-gray-500">No results</p>
      )}
    </SearchBase>
  );
};

export default PostTextAreaMentionSearchLinkedIn;
