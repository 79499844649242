import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { ImageAttachment } from "../../../../types/posts";

import { mergeClassNames } from "../../../../libs/components";
import { clamp } from "../../../../libs/utils";

import PostImageAttachmentImage from "../../../images/PostAttachment";

const COLOURS = {
  GRAY: "rgba(0, 0, 0, 0.4)",
};

const ONE_IMAGE_HEIGHT = undefined;
const TWO_TO_FOUR_IMAGE_HEIGHT = 100;
const FIVE_OR_MORE_IMAGE_HEIGHT = 83.33;

/*
1. undefined
2. 100%
3. 100%
4. 100%
5. 83%
*/

interface FacebookPostPreviewImagesProps {
  imageAttachments: ImageAttachment[];
  className?: string;
}
const FacebookPostPreviewImages: React.FC<FacebookPostPreviewImagesProps> = ({
  imageAttachments,
  className = "",
}) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const [imageContainerWidth, setImageContainerWidth] = useState(0);

  const slot1 = imageAttachments.length > 0 ? imageAttachments[0] : null;
  const slot2 = imageAttachments.length > 4 ? imageAttachments[1] : null;
  const slot3 =
    imageAttachments.length > 4
      ? imageAttachments[2]
      : imageAttachments.length > 1
      ? imageAttachments[1]
      : null;
  const slot4 =
    imageAttachments.length > 4
      ? imageAttachments[3]
      : imageAttachments.length > 2
      ? imageAttachments[2]
      : null;
  const slot5 =
    imageAttachments.length > 4
      ? imageAttachments[4]
      : imageAttachments.length > 3
      ? imageAttachments[3]
      : null;
  const canShowImages = imageContainerWidth > 0 && !!slot1;
  const excessImages = Math.max(0, imageAttachments.length - 5);
  const imageLayout = clamp(imageAttachments.length, 1, 5);
  let paddingBottom: CSSProperties["paddingBottom"] = undefined;

  if (imageLayout === 1) {
    paddingBottom = ONE_IMAGE_HEIGHT;
  } else if (imageLayout >= 2 && imageLayout <= 4) {
    paddingBottom = `${TWO_TO_FOUR_IMAGE_HEIGHT}%`;
  } else if (imageLayout >= 5) {
    paddingBottom = `${FIVE_OR_MORE_IMAGE_HEIGHT}%`;
  }

  useEffect(() => {
    if (imageContainerRef.current) {
      setImageContainerWidth(imageContainerRef.current.clientWidth);
    }
  }, []);

  return (
    <div
      ref={imageContainerRef}
      className={mergeClassNames("w-full", className)}
    >
      <div
        className="relative w-full overflow-hidden"
        style={{
          paddingBottom,
        }}
      >
        <div
          className={`h-full w-full overflow-hidden flex flex-wrap ${
            paddingBottom === undefined ? "" : "absolute top-0 left-0"
          }`}
        >
          {canShowImages && (
            <>
              <div
                className={`relative ${
                  imageLayout === 1 || imageLayout === 3 ? "w-full" : "w-1/2"
                } ${
                  imageLayout === 1 || imageLayout === 2
                    ? "h-full"
                    : imageLayout === 4
                    ? "h-1/2"
                    : "h-3/5"
                }`}
              >
                <PostImageAttachmentImage
                  className="w-full h-full object-center object-cover border-2 border-transparent"
                  containerClassName="absolute inset-0"
                  imageAttachment={slot1!}
                />
              </div>
              {slot2 && (
                <div className="w-1/2 h-3/5 relative">
                  <PostImageAttachmentImage
                    className="w-full h-full object-center object-cover border-2 border-transparent"
                    containerClassName="absolute inset-0"
                    imageAttachment={slot2}
                  />
                </div>
              )}
              {slot3 && (
                <div
                  className={`relative ${
                    imageLayout === 2
                      ? "w-1/2"
                      : imageLayout === 5
                      ? "w-1/3"
                      : "w-1/2"
                  } ${
                    imageLayout === 2
                      ? "h-full"
                      : imageLayout === 4
                      ? "h-1/2"
                      : "h-2/5"
                  }`}
                >
                  <PostImageAttachmentImage
                    className="w-full h-full object-center object-cover border-2 border-transparent"
                    containerClassName="absolute inset-0"
                    imageAttachment={slot3}
                  />
                </div>
              )}
              {slot4 && (
                <div
                  className={`relative ${
                    imageLayout === 5 ? "w-1/3" : "w-1/2"
                  } ${imageLayout === 4 ? "h-1/2" : "h-2/5"}`}
                >
                  <PostImageAttachmentImage
                    className="w-full h-full object-center object-cover border-2 border-transparent"
                    containerClassName="absolute inset-0"
                    imageAttachment={slot4}
                  />
                </div>
              )}
              {slot5 && (
                <div
                  className={`relative ${
                    imageLayout === 5 ? "w-1/3" : "w-1/2"
                  } ${imageLayout === 4 ? "h-1/2" : "h-2/5"}`}
                >
                  <PostImageAttachmentImage
                    className="w-full h-full object-center object-cover border-2 border-transparent"
                    containerClassName="absolute inset-0"
                    imageAttachment={slot5}
                  />
                  {excessImages && (
                    <div
                      className="absolute top-0 left-0 w-full h-full border-2 border-transparent flex items-center justify-center text-3xl text-white"
                      style={{ backgroundColor: COLOURS.GRAY }}
                    >
                      {`+${excessImages}`}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FacebookPostPreviewImages;
