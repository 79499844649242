import React, { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import {
  Slash as SlashIcon,
  AlertOctagon as AlertOctagonIcon,
  UploadCloud as UploadCloudIcon,
} from "react-feather";

import { InternalFileVideo } from "../../types/files";

import { useUpload } from "../../libs/hooks/uploads";
import { useFile } from "../../libs/hooks/files";
import {
  useActiveWorkspaceFileAccessToken,
  useDateTimeFactory,
} from "../../libs/hooks/app";
import {
  buildFileUrl,
  getDescriptionForInvalidPlatform,
} from "../../libs/files";
import { mergeClassNames } from "../../libs/components";

import LoaderIcon from "../icons/Loader";

import LazyImage from "./Lazy";
import Tooltip from "../Tooltip";
import PlatformIcon from "../icons/Platform";
import { Platform } from "../../types/platforms";

const GENERATE_PREVIEW_CUTOFF = 2; // Minutes;
const GENERATING_PREVIEW_REFETCH_INTERVAL = 2000; // 1 second.

interface InternalVideoPreviewImageProps {
  file: InternalFileVideo;
  showInvalidPlatforms?: boolean;
  className?: string;
  containerClassName?: string;
}

const InternalVideoPreviewImage: React.FC<InternalVideoPreviewImageProps> = ({
  file,
  showInvalidPlatforms = true,
  className = "",
  containerClassName = "",
}) => {
  const mountedRef = useRef(true);
  const [showGifPreview, setShowGifPreview] = useState(false);
  const [isGifPreviewReady, setIsGifPreviewReady] = useState(false);
  const [isLoadingGifPreview, setIsLoadingGifPreview] = useState(false);
  const upload = useUpload(file.id);
  const fileAccessToken = useActiveWorkspaceFileAccessToken();
  const createDateTime = useDateTimeFactory();
  const isComplete = file.status === "COMPLETE";
  const hasPreview = !!file.preview;
  const hasInvalidPlatforms =
    !!file.invalidPlatforms && file.invalidPlatforms.length > 0;
  const invalidPlatformTypes =
    showInvalidPlatforms && hasInvalidPlatforms
      ? file.invalidPlatforms!.reduce<{ [platformType: string]: string[] }>(
          (carry, invalidPlatform) => {
            const platformType = invalidPlatform.platformType;

            if (!carry[platformType]) {
              carry[platformType] = [];
            }

            carry[platformType].push(
              getDescriptionForInvalidPlatform(invalidPlatform)
            );

            return carry;
          },
          {}
        )
      : {};
  let uploadPercent = 100;

  if (upload) {
    switch (upload.status) {
      case "PENDING":
        uploadPercent = 0;
        break;
      case "IN_PROGRESS":
        uploadPercent = Math.round(upload.percent);
        break;
      case "COMPLETE":
        uploadPercent = 100;
        break;
      case "FAILED":
        uploadPercent = 0;
        break;
    }
  }

  const isUploading = !isComplete && uploadPercent < 100;

  let isGeneratingPreview =
    (uploadPercent === 100 && !isComplete) || (isComplete && !hasPreview);

  if (isGeneratingPreview && file.created) {
    const fileCreated = createDateTime(file.created);
    const now = DateTime.local().setZone(fileCreated.zone);
    const minutesSinceCreated = now.diff(fileCreated, "minutes").as("minutes");

    isGeneratingPreview = minutesSinceCreated < GENERATE_PREVIEW_CUTOFF;
  }

  useFile(file.id, {
    enabled: isGeneratingPreview,
    refetchInterval: isGeneratingPreview
      ? GENERATING_PREVIEW_REFETCH_INTERVAL
      : false,
  });

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (isUploading || isGeneratingPreview || !hasPreview) {
    return (
      <div
        className={mergeClassNames(
          "relative w-full h-full flex-shrink-0",
          containerClassName
        )}
      >
        <div
          className={mergeClassNames(
            "w-full h-full flex items-center justify-center",
            className
          )}
        >
          {isUploading && (
            <div className="flex items-center">
              <UploadCloudIcon className="h-6 w-6" />
              <span className="ml-2">{`${uploadPercent}%`}</span>
            </div>
          )}
          {isGeneratingPreview && (
            <div className="flex items-center">
              <LoaderIcon className="h-6 w-6" />
              <span className="ml-2">Generating preview...</span>
            </div>
          )}
          {!isUploading && !isGeneratingPreview && (
            <div className="flex items-center">
              <AlertOctagonIcon className="h-6 w-6" />
              <span className="ml-2">No preview available</span>
            </div>
          )}
        </div>
        {hasInvalidPlatforms && showInvalidPlatforms && (
          <div className="p-1 absolute bottom-1 right-1 flex items-center text-red-500">
            {Object.keys(invalidPlatformTypes).map((platformType) => {
              const errorMessages = invalidPlatformTypes[platformType];
              return (
                <Tooltip
                  key={platformType}
                  className="ml-2"
                  content={
                    <ul>
                      {errorMessages.map((message) => (
                        <li key={message}>{message}</li>
                      ))}
                    </ul>
                  }
                >
                  <div className="relative h-10 w-10 flex items-center justify-center">
                    <PlatformIcon
                      className="h-6 w-6"
                      type={platformType as Platform["type"]}
                    />
                    <SlashIcon className="absolute top-0 left-0 h-10 w-10" />
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  const gifUrl = buildFileUrl(file.preview!.gifPath, fileAccessToken || "");
  const screenshotUrl = buildFileUrl(
    file.preview!.screenshotPath,
    fileAccessToken || ""
  );
  const blurHash = file.preview!.screenshotBlurhash;

  return (
    <div
      className={mergeClassNames("relative w-full h-full", containerClassName)}
      onMouseEnter={() => {
        setShowGifPreview(true);

        if (!isGifPreviewReady && !isLoadingGifPreview) {
          setIsLoadingGifPreview(true);
          const gif = new Image();
          gif.onload = () => {
            if (mountedRef.current) {
              setIsLoadingGifPreview(false);
              setIsGifPreviewReady(true);
            }
          };

          gif.src = gifUrl;
        }
      }}
      onMouseLeave={() => setShowGifPreview(false)}
    >
      <LazyImage
        className={className}
        containerClassName={mergeClassNames(
          "flex items-center justify-center bg-black",
          containerClassName
        )}
        LoadingClassName="bg-white"
        src={showGifPreview && isGifPreviewReady ? gifUrl : screenshotUrl}
        blurHash={blurHash}
        alt={file.name}
      />

      {isLoadingGifPreview && (
        <div className="p-1 absolute top-1 left-1 rounded bg-gray-700 text-white text-xs flex items-center">
          <LoaderIcon className="mr-1 h-4 w-4" />
          Loading 3 second preview...
        </div>
      )}
      {hasInvalidPlatforms && showInvalidPlatforms && (
        <div className="p-1 absolute bottom-1 right-1 flex items-center text-red-500">
          {Object.keys(invalidPlatformTypes).map((platformType) => {
            const errorMessages = invalidPlatformTypes[platformType];
            return (
              <Tooltip
                key={platformType}
                className="ml-2"
                content={
                  <ul>
                    {errorMessages.map((message) => (
                      <li key={message}>{message}</li>
                    ))}
                  </ul>
                }
              >
                <div className="relative h-10 w-10 flex items-center justify-center">
                  <PlatformIcon
                    className="h-6 w-6"
                    type={platformType as Platform["type"]}
                  />
                  <SlashIcon className="absolute top-0 left-0 h-10 w-10" />
                </div>
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InternalVideoPreviewImage;
