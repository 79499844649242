import React from "react";

import H3 from "../../../components/headings/H3";
import LoaderIcon from "../../../components/icons/Loader";

interface CreatePostModalCreatePostModalLoadingProps {
  contentId: "loading";
  isError?: boolean;
}

const CreatePostModalLoading: React.FC<
  CreatePostModalCreatePostModalLoadingProps
> = ({ isError = false }) => {
  return (
    <div
      className="p-8 flex items-center justify-center"
      style={{ width: 1000, minHeight: 700 }}
    >
      {isError ? (
        <H3 className="text-red-500">Error loading files and socials</H3>
      ) : (
        <LoaderIcon className="w-10 h-10" />
      )}
    </div>
  );
};

export default CreatePostModalLoading;
