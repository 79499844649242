import React, { useEffect, useState } from "react";

import { getPlatformTypeName } from "../../../libs/platform";
import {
  useContentSwitch,
  useSnackBarFactory,
} from "../../../libs/hooks/general";

import {
  AddPlatformContent,
  BaseComponentProps,
  ConnectFacebook,
  ConnectLinkedIn,
  ConnectTwitter,
} from "../../../types/modals/AddPlatform";
import { Platform } from "../../../types/platforms";

import H3 from "../../../components/headings/H3";
import PlatformIcon from "../../../components/icons/Platform";
import LoaderIcon from "../../../components/icons/Loader";
import CreatedPlatformSnackBarContent from "../../../components/snackBarContent/CreatedPlatform";

import { ReactComponent as LoadingImage } from "../../../images/add-platform/loading.svg";

interface ConnectBaseProps extends BaseComponentProps {
  tryAgainContent: ConnectFacebook | ConnectTwitter | ConnectLinkedIn;
  platformType: Platform["type"];
  createPlatform: () => Promise<{
    platform: Platform;
    meta: { isNew: boolean };
  }>;
}

const AddPlatformModalConnectBase: React.FC<ConnectBaseProps> = ({
  isVisible,
  tryAgainContent,
  platformType,
  createPlatform,
  close,
}) => {
  const { showContent } = useContentSwitch<AddPlatformContent>("home");
  const createSnackBar = useSnackBarFactory();
  const [startedProvisioning, setStartedProvisioning] = useState(false);
  const platformTypeName = getPlatformTypeName(platformType);

  useEffect(() => {
    if (isVisible && !startedProvisioning) {
      const beginPlatformProvisioning = async () => {
        try {
          const { platform, meta } = await createPlatform();

          if (!platform) {
            throw new Error("Failed to create platform");
          }

          if (meta.isNew) {
            createSnackBar({
              content: (
                <CreatedPlatformSnackBarContent
                  platformType={platform.type}
                  platformName={platform.name}
                />
              ),
            });
            close();
          } else {
            showContent({ id: "alreadyExists", data: { platform } });
          }
        } catch (e: any) {
          let title = "Whoops!";
          let message = e.message || e;

          if (e.code && e.code === "100") {
            title = "Required permissions declined";
            message =
              "Unfortunately Seenly can't manage your content without you approving the requested permissions";
          }

          showContent({
            id: "error",
            data: {
              title,
              message,
              platformType,
              tryAgainContent,
            },
          });
        }
      };

      beginPlatformProvisioning();
      setStartedProvisioning(true);
    }
  }, [
    tryAgainContent,
    createPlatform,
    startedProvisioning,
    isVisible,
    platformType,
    showContent,
    createSnackBar,
    close,
  ]);

  return (
    <div className="p-8 flex flex-col" style={{ width: 600 }}>
      <H3 className="flex-shrink-0 flex items-center">
        <PlatformIcon className="mr-2" type={platformType} colour={true} />
        Connect {platformTypeName}
      </H3>
      <div className="relative flex-grow flex flex-col items-center justify-center">
        <LoadingImage className="mt-4 flex-shrink-0 w-72 h-64 text-purple-500" />
        <div className="p-4 w-full flex items-center justify-center">
          <LoaderIcon className="h-12 w-12 text-purple-500" />
          <div className="ml-4">
            <H3>Loading...</H3>
            <p>Connecting your account with {platformTypeName}...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlatformModalConnectBase;
