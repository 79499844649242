import React from "react";
import { Image as ImageIcon } from "react-feather";

import { mergeClassNames } from "../../../../libs/components";
import { LinkAttachment } from "../../../../types/posts";

const COLOURS = {
  BLACK: "rgb(15, 20, 25)",
  BORDER_GRAY: "rgb(207, 217, 222)",
  GRAY: "rgb(83, 100, 113)",
};

interface TwitterPostPreviewLinkProps {
  linkAttachment: LinkAttachment;
  className?: string;
}
const TwitterPostPreviewLink: React.FC<TwitterPostPreviewLinkProps> = ({
  linkAttachment,
  className = "",
}) => {
  return (
    <div
      className={mergeClassNames(
        `rounded-2xl overflow-hidden relative w-full font-twitter flex items-center`,
        className
      )}
      style={{
        border: `1px solid ${COLOURS.BORDER_GRAY}`,
        fontSize: "15px",
      }}
    >
      <div
        className="shrink-0"
        style={{
          width: 130,
          borderRight: `1px solid ${COLOURS.BORDER_GRAY}`,
        }}
      >
        <div className="relative" style={{ paddingBottom: "100%" }}>
          {linkAttachment.thumbnail ? (
            <img
              className="absolute w-full h-full object-cover"
              src={linkAttachment.thumbnail.url}
              alt=""
            />
          ) : (
            <div className="absolute w-full h-full flex items-center justify-center">
              <ImageIcon className="w-12 h-12" />
            </div>
          )}
        </div>
      </div>
      <div className="p-3 overflow-hidden">
        <div className="leading-5" style={{ color: COLOURS.GRAY }}>
          {linkAttachment.domainName}
        </div>
        <div
          className="leading-5 truncate text-ellipsis"
          style={{ marginTop: 2, color: COLOURS.BLACK }}
        >
          {linkAttachment.title}
        </div>
        <div
          className="leading-5 break-words overflow-hidden text-ellipsis"
          style={{
            marginTop: 2,
            color: COLOURS.GRAY,
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
          }}
        >
          {linkAttachment.description}
        </div>
      </div>
    </div>
  );
};

export default TwitterPostPreviewLink;
