import React, { useRef, useEffect } from "react";
import { Calendar as CalendarIcon } from "react-feather";

import AppPageWithHeader from "../containers/AppPageWithHeader";

import H2 from "../components/headings/H2";
import InternalPrimaryButtonLink from "../components/links/InternalPrimaryButton";

import { ReactComponent as PageNotFoundImage } from "../images/page-not-found.svg";

const NotFoundPage: React.FC = () => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (linkRef.current) {
      linkRef.current.focus();
    }
  }, []);

  return (
    <AppPageWithHeader>
      <div className="pt-20 w-full h-full flex justify-center">
        <div className="relative flex flex-col items-center">
          <div style={{ width: 600 }}>
            <H2>Sorry, we can't find that page!</H2>
            <p className="mt-6">
              However there's no need to panic, you can simply click the button
              below to head on over to your schedule.
            </p>
          </div>
          <InternalPrimaryButtonLink
            ref={linkRef}
            className="mt-10"
            to="/calendar"
          >
            <CalendarIcon className="mr-2" />
            Take me to my calendar
          </InternalPrimaryButtonLink>
          <PageNotFoundImage
            className="text-sky-200"
            style={{ width: 500, height: 500 }}
          />
        </div>
      </div>
    </AppPageWithHeader>
  );
};

export default NotFoundPage;
