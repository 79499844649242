import React from "react";
import { Platform } from "../../types/platforms";
import PlatformIcon from "../icons/Platform";

interface DeletedPlatformSnackBarContentProps {
  platformType: Platform["type"];
  platformName: string;
}
const DeletedPlatformSnackBarContent: React.FC<
  DeletedPlatformSnackBarContentProps
> = ({ platformType, platformName }) => {
  return (
    <div className="flex items-center">
      <PlatformIcon className="flex-shrink-0 w-6 h-6" type={platformType} />
      <span className="ml-2">
        <strong>{platformName}</strong> disconnected
      </span>
    </div>
  );
};

export default DeletedPlatformSnackBarContent;
