import React from "react";
import { Check as CheckIcon } from "react-feather";

import { Workspace } from "../../../types/workspaces";
import config from "../../../config";
import { useContentStack } from "../../../libs/hooks/general";

import H2 from "../../../components/headings/H2";
import PrimaryButton from "../../../components/buttons/Primary";

import { ReactComponent as UpgradeImage } from "../../../images/upgrade.svg";

interface ChangeSubscriptionModalFreeLimitProps {
  contentId: "freeLimit";
  workspace: Workspace;
  onChange: (newPriceId: string) => void;
}
const ChangeSubscriptionModalFreeLimit: React.FC<
  ChangeSubscriptionModalFreeLimitProps
> = ({ workspace, onChange }) => {
  const { push } = useContentStack();
  const displayPrice = `$${Math.ceil(
    config.prices.PUBLISH_MONTHLY.unitAmount / 100
  )} per month`;

  return (
    <div className="p-8 flex flex-col" style={{ width: 500 }}>
      <H2 className="flex-shrink-0 text-2xl text-black w-full">
        Need more from Seenly?
      </H2>
      <p className="mt-4">
        It looks like you've outgrown the free tier and want to take your social
        to the next level.
      </p>
      <p className="mt-4">Don't worry, we've got you covered!</p>
      {workspace.hasHadTrial ? (
        <>
          <p className="mt-4">
            Upgrade to our{" "}
            <strong>{config.prices.PUBLISH_MONTHLY.displayName}</strong>{" "}
            subscription and you'll get:
          </p>
          <ul className="mt-4 mx-auto text-lg">
            {config.prices.PUBLISH_MONTHLY.features.map((feature, index) => {
              return (
                <li key={index} className="flex items-center">
                  <CheckIcon className="mr-2 text-purple-500" /> {feature}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p className="mt-4">
          You can trial our{" "}
          <strong>{config.prices.PUBLISH_MONTHLY.displayName}</strong>{" "}
          subscription for free for{" "}
          <strong>{config.DEFAULT_TRIAL_PERIOD_DAYS} days!</strong>
        </p>
      )}

      <UpgradeImage
        className="mt-4 mx-auto flex-shrink-0 text-purple-500"
        style={{ width: 300, height: 300 }}
      />

      <PrimaryButton
        className="mt-10"
        onClick={() => {
          if (workspace.hasHadTrial) {
            onChange(config.prices.PUBLISH_MONTHLY.id);
            push("payment");
          } else {
            push("freeTrial");
          }
        }}
      >
        {workspace.hasHadTrial
          ? `Upgrade for only ${displayPrice}!`
          : "Sure, I'll take a free trial!"}
      </PrimaryButton>
    </div>
  );
};

export default ChangeSubscriptionModalFreeLimit;
