import React, { useEffect, useRef, useState, useCallback } from "react";
import { Image as ImageIcon, Link as LinkIcon } from "react-feather";
import { useSpring, animated } from "@react-spring/web";

import { mergeClassNames } from "../../libs/components";
import { getImageDimensions } from "../../libs/images";

import ExternalLink from "./External";

interface LinkPreviewCardProps {
  url: string;
  thumbnailUrl?: string;
  thumbnailHeight?: number;
  thumbnailWidth?: number;
  containerWidth?: number;
  title?: string;
  description?: string;
  domainName?: string;
  className?: string;
}

const MIN_LANDSCAPE_RATIO = 1.3;
const IDEAL_LANDSCAPE_RATIO = 1.9;
const TITLE_HEIGHT = 25;
const DESCRIPTION_HEIGHT = 40;
const DOMAIN_HEIGHT = 25;
const SUMMARY_HEIGHT = TITLE_HEIGHT + DESCRIPTION_HEIGHT + DOMAIN_HEIGHT;
const MIN_PORTRAIT_SIZE = 1.25 * SUMMARY_HEIGHT;
const MAX_PORTRAIT_SIZE = 2 * SUMMARY_HEIGHT;

function shouldRenderImageLandscape(
  containerWidth: number,
  width: number,
  height: number
) {
  // The width has to be decently larger than height to make it look ok.
  const isLandscapeRatio = width / height >= MIN_LANDSCAPE_RATIO;
  const previewHeight = Math.round(containerWidth / IDEAL_LANDSCAPE_RATIO);
  const isWiderThanContainer = width >= containerWidth;
  const isTallerThanPreviewHeight = height >= previewHeight;
  return (
    isLandscapeRatio || (isWiderThanContainer && isTallerThanPreviewHeight)
  );
}

function buildImageConfig(maxWidth: number, width: number, height: number) {
  if (shouldRenderImageLandscape(maxWidth, width, height)) {
    return {
      isLandscape: true,
      width: maxWidth,
      height: Math.round(maxWidth / IDEAL_LANDSCAPE_RATIO),
    };
  } else {
    const size = Math.min(
      Math.max(maxWidth / 5, MIN_PORTRAIT_SIZE),
      MAX_PORTRAIT_SIZE
    );
    return {
      isLandscape: false,
      width: size,
      height: size,
    };
  }
}

const LinkPreviewCard: React.FC<LinkPreviewCardProps> = ({
  url,
  thumbnailUrl,
  thumbnailHeight,
  thumbnailWidth,
  containerWidth,
  title,
  description,
  domainName,
  className = "",
}) => {
  const [spring, springApi] = useSpring(() => ({ opacity: 0 }));
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [imageConfig, setImageConfig] = useState(
    !!containerWidth && !!thumbnailHeight && !!thumbnailWidth
      ? buildImageConfig(containerWidth, thumbnailWidth, thumbnailHeight)
      : {
          isLandscape: true,
          height: MIN_PORTRAIT_SIZE,
          width: MIN_PORTRAIT_SIZE,
        }
  );

  const updateImageConfig = useCallback(
    (newWidth: number, newHeight: number) => {
      const maxWidth = containerWidth
        ? containerWidth
        : containerRef.current
        ? containerRef.current.clientWidth
        : 0;

      setImageConfig(buildImageConfig(maxWidth, newWidth, newHeight));
    },
    [containerWidth]
  );

  useEffect(() => {
    if (thumbnailUrl) {
      if (thumbnailHeight && thumbnailWidth) {
        updateImageConfig(thumbnailWidth, thumbnailHeight);
        springApi.start({ opacity: 1 });
      } else {
        getImageDimensions(thumbnailUrl).then(([width, height]) => {
          updateImageConfig(width, height);
          springApi.start({ opacity: 1 });
        });
      }
    } else {
      setImageConfig({
        isLandscape: false,
        width: MIN_PORTRAIT_SIZE,
        height: MIN_PORTRAIT_SIZE,
      });
      springApi.start({ opacity: 1 });
    }
  }, [
    springApi,
    thumbnailHeight,
    thumbnailUrl,
    thumbnailWidth,
    updateImageConfig,
  ]);

  return (
    <div ref={containerRef} className="w-full">
      <ExternalLink
        className={mergeClassNames(
          "w-full font-normal no-underline",
          className
        )}
        href={url}
        aria-label={title}
      >
        <animated.div
          className={`w-full flex overflow-hidden rounded-lg text-gray-500 bg-gray-50 border border-gray-200 ${
            imageConfig.isLandscape ? "flex-col" : ""
          }`}
          style={spring}
        >
          <div
            className={`flex-shrink-0 flex items-center justify-center overflow-hidden border-gray-200 box-content ${
              imageConfig.isLandscape ? "border-b" : "border-r"
            }`}
            style={{ height: imageConfig.height, width: imageConfig.width }}
          >
            {thumbnailUrl ? (
              <img
                className="w-full h-full object-cover"
                src={thumbnailUrl}
                alt=""
              />
            ) : (
              <ImageIcon className="h-12 w-12" />
            )}
          </div>
          <div className="flex-grow flex items-center justify-center overflow-hidden">
            <div className="px-2 w-full text-sm overflow-hidden">
              {(title || !imageConfig.isLandscape) && (
                <div
                  className="text-black font-bold truncate text-ellipsis"
                  style={{
                    height: TITLE_HEIGHT,
                    lineHeight: `${TITLE_HEIGHT}px`,
                  }}
                >
                  {title}
                </div>
              )}
              {(description || !imageConfig.isLandscape) && (
                <p
                  className="overflow-hidden break-words overflow-ellipsis"
                  style={{
                    height: DESCRIPTION_HEIGHT,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                  }}
                >
                  {description}
                </p>
              )}
              {(domainName || !imageConfig.isLandscape) && (
                <div
                  className="text-gray-400 flex items-center justify-end"
                  style={{ height: DOMAIN_HEIGHT }}
                >
                  <LinkIcon height={14} />
                  <span>{domainName}</span>
                </div>
              )}
            </div>
          </div>
        </animated.div>
      </ExternalLink>
    </div>
  );
};

export default LinkPreviewCard;
