import React, { ReactNode } from "react";

import { mergeClassNames } from "../../../libs/components";

interface AnalyticsOverviewCardProps {
  icon: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  footer?: ReactNode;
  className?: string;
  footerClassName?: string;
}
const AnalyticsOverviewCard: React.FC<AnalyticsOverviewCardProps> = ({
  className = "",
  footerClassName = "",
  icon,
  title,
  subtitle,
  footer,
}) => {
  return (
    <div className={mergeClassNames("p-2 w-56 rounded-xl", className)}>
      <div className="flex items-center">
        <div className="shrink-0 h-14 w-14 rounded-xl flex items-center justify-center bg-neutral-900 bg-opacity-20">
          <div className="h-6 w-6 text-white">{icon}</div>
        </div>
        <div className="grow ml-2 flex flex-col truncate">
          <div className="text-white opacity-80 uppercase">{title}</div>
          <div className="text-3xl font-bold text-white">{subtitle}</div>
        </div>
      </div>
      <div
        className={mergeClassNames(
          "mt-2 h-6 rounded-lg text-xs text-neutral-900 text-opacity-50 bg-white bg-opacity-50",
          footerClassName
        )}
      >
        {footer !== undefined && footer}
      </div>
    </div>
  );
};

export default AnalyticsOverviewCard;
