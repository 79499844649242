import React, { ReactNode } from "react";
import { X } from "react-feather";
import { mergeClassNames } from "../../libs/components";

interface AlertProps {
  className?: string;
  closeButtonClassName?: string;
  title?: ReactNode;
  icon?: ReactNode;
  dismissable?: boolean;
  onDismiss?: () => void;
  message: ReactNode;
}

const Alert: React.FC<AlertProps> = ({
  title = null,
  icon = null,
  className = "",
  closeButtonClassName = "",
  dismissable = false,
  onDismiss = null,
  message,
}) => {
  return (
    <div
      className={mergeClassNames(
        "relative flex items-center rounded-lg",
        className
      )}
      role="alert"
    >
      {icon && <div className="pl-4 flex-shrink-0">{icon}</div>}
      <div className="py-2 px-4 text-left flex-grow">
        {title && <h3 className="font-bold mb-1">{title}</h3>}
        <div>{message}</div>
      </div>
      {dismissable && onDismiss && (
        <div className="flex-shrink-0 h-full">
          <button
            className={mergeClassNames(
              "m-1 p-1 rounded-full  default-transition",
              closeButtonClassName
            )}
            type="button"
            title="Close"
            onClick={() => onDismiss()}
          >
            {<X className="w-4 h-4" />}
          </button>
        </div>
      )}
    </div>
  );
};

export default Alert;
