import React, { useEffect, useRef, useState } from "react";
import { useContentSwitch } from "../../../libs/hooks/general";
import { getPlatformTypeName } from "../../../libs/platform";
import { Platform } from "../../../types/platforms";
import {
  BaseComponentProps,
  ConnectLinkedIn,
  ConnectFacebook,
  ConnectTwitter,
  AddPlatformContent,
} from "../../../types/modals/AddPlatform";

import H3 from "../../../components/headings/H3";
import PrimaryButton from "../../../components/buttons/Primary";
import SecondaryButton from "../../../components/buttons/Secondary";
import PlatformIcon from "../../../components/icons/Platform";

import { ReactComponent as AuthImage } from "../../../images/add-platform/auth.svg";
import LoaderIcon from "../../../components/icons/Loader";

interface ConsentProps extends BaseComponentProps {
  platformType: Platform["type"];
  fetchConnectContent: () => Promise<
    ConnectLinkedIn | ConnectTwitter | ConnectFacebook
  >;
}

const AddPlatformModalConsent: React.FC<ConsentProps> = ({
  isVisible,
  platformType,
  fetchConnectContent,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { showContent } = useContentSwitch<AddPlatformContent, "home">("home");
  const isLoadingConnectContentRef = useRef(false);
  const [connectContent, setConnectContent] = useState<
    ConnectLinkedIn | ConnectTwitter | ConnectFacebook | null
  >(null);
  const platformTypeName = getPlatformTypeName(platformType);

  useEffect(() => {
    if (isVisible && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !connectContent && !isLoadingConnectContentRef.current) {
      const loadConnectContent = async () => {
        const newConnectContent = await fetchConnectContent();
        setConnectContent(newConnectContent);
      };

      isLoadingConnectContentRef.current = true;
      loadConnectContent();
    }
  }, [connectContent, fetchConnectContent, isVisible]);

  return (
    <div className="p-8" style={{ width: 600 }}>
      <div className="h-full w-full flex flex-col">
        <H3 className="flex-shrink-0 flex items-center">
          <PlatformIcon className="mr-2" type={platformType} colour={true} />
          Connect {platformTypeName}
        </H3>
        <div className="flex-grow flex flex-col">
          <div className="flex-grow flex items-center">
            <div className="pr-4">
              <p>
                Seenly requires permission to access {platformTypeName} on your
                behalf.
              </p>
              <p className="mt-4">
                You may revoke this permission at any time either via{" "}
                {platformTypeName} or Seenly.
              </p>
            </div>
            <AuthImage
              className="flex-shrink-0 text-purple-500"
              height={200}
              width={270}
            />
          </div>
          <p className="mt-4">
            Before proceeding please make sure you are either:
          </p>
          <ul className="list-disc pl-5">
            <li className="mt-2">
              Logged in to the {platformTypeName} account you wish to manage
              with Seenly; or
            </li>
            <li className="mt-2">
              Not currently logged in to {platformTypeName}
            </li>
          </ul>
          <div className="flex-shrink-0 mt-4">
            <PrimaryButton
              ref={buttonRef}
              className="mt-4 block w-full"
              onClick={() =>
                connectContent ? showContent(connectContent) : null
              }
              disabled={!connectContent}
            >
              {!connectContent ? (
                <div className="flex items-center">
                  <LoaderIcon />
                  <span className="ml-2">{`Preparing ${platformTypeName}`}</span>
                </div>
              ) : (
                `Open ${platformTypeName} to grant permission`
              )}
            </PrimaryButton>
            <SecondaryButton
              className="mt-2 block w-full"
              onClick={() => showContent({ id: "home" })}
            >
              No, thanks.
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlatformModalConsent;
