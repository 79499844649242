import React, { useContext, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Auth } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import UnauthenticatedAppContext from "../contexts/app/Unauthenticated";
import { USERS_QUERY_KEYS } from "../libs/queryKeys";
import { useAsyncState } from "../libs/hooks/general";

import H1 from "../components/headings/H1";
import PrimaryButton from "../components/buttons/Primary";
import InternalLink from "../components/links/Internal";
import BrandLogoLink from "../components/links/BrandLogo";
import FormInput from "../components/form/Input";
import FormPasswordInput from "../components/form/input/Password";

import { ReactComponent as LoginSplash } from "../images/login-splash.svg";
import UnauthenticatedFooter from "../containers/footers/UnauthenticatedFooter";

type FormValues = {
  email: string;
  password: string;
};

const formSchema = Joi.object({
  email: Joi.string().required().email({ tlds: false }),
  password: Joi.string().required(),
});

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    criteriaMode: "all",
    resolver: joiResolver(formSchema),
  });
  const { setEmail, setPassword } = useContext(UnauthenticatedAppContext);
  const { loading, loadingText, setLoading, error, setError, clearError } =
    useAsyncState();

  const onSubmit = async ({ email: rawEmail, password }: FormValues) => {
    const email = rawEmail.toLowerCase();
    setLoading(true);
    setEmail(email);
    setPassword(password);
    clearError();

    try {
      await Auth.signIn(email, password);
      // Reset the users queries so that they reload now that we've logged in.
      queryClient.resetQueries(USERS_QUERY_KEYS.all);
      navigate("/");
    } catch (e: any) {
      setLoading(false);

      if (e.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        navigate("/signup/confirm");
      } else if (e.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (e.code === "NotAuthorizedException") {
        setError({
          title: "Whoops!",
          message: (
            <>
              Looks like you've got the wrong password. Never fear, you can{" "}
              <InternalLink className="alert-link" to="/forgot-password">
                reset it here.
              </InternalLink>
            </>
          ),
        });
      } else if (e.code === "UserNotFoundException") {
        setError({
          title: "Have we met yet?",
          message: (
            <>
              Sorry, we don't recognise that email but{" "}
              <InternalLink className="alert-link" to="/signup">
                we'd love to get to know you better.
              </InternalLink>
            </>
          ),
        });
      } else {
        setError({
          title: "This is awkward...",
          message:
            "We're not entirely sure what went wrong. Can you please try again?",
        });
      }
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <div className="flex w-screen h-screen">
      <Helmet>
        <title>Seenly - log in</title>
      </Helmet>
      <div
        className="px-4 relative flex-shrink-0 h-full w-full flex items-center md:px-20"
        style={{ maxWidth: 576 }}
      >
        <div className="absolute top-0 left-0 pl-4 pt-4 flex items-center">
          <BrandLogoLink />
        </div>
        <div className="w-full">
          <H1 className="mb-4">Log In</H1>
          <p className="mb-4">
            Don't already have an account?{" "}
            <InternalLink to="/signup" id="login-create-account">
              Create an account
            </InternalLink>
          </p>
          {error()}
          <form className="pt-1" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              {...register("email")}
              labelText="Email"
              labelClassName="mt-4"
              type="text"
              error={!!errors.email}
            />
            <FormPasswordInput
              {...register("password")}
              labelClassName="mt-4"
              error={!!errors.password}
            />
            <PrimaryButton
              className="mt-6 w-full text-xl text-white"
              type="submit"
              disabled={loading}
              id="login"
            >
              {loadingText({
                loading: "Logging in...",
                default: "Log in",
              })}
            </PrimaryButton>
          </form>

          <div className="text-center mt-5">
            <InternalLink to="/forgot-password">Forgot password?</InternalLink>
          </div>
        </div>
        <UnauthenticatedFooter className="absolute bottom-0 left-0 w-full" />
      </div>
      <div className="relative hidden md:flex items-center w-full h-full overflow-hidden">
        <div className="absolute top-0 right-0 py-28 z-10 text-center lg:px-28">
          <div className="text-6xl font-bold text-gray-50">Simple social</div>
        </div>
        <div
          className="absolute top-0 right-0 bg-gradient-to-r from-blue-800 to-blue-900 h-3/5 w-full lg:rounded-lg lg:m-20 lg:w-3/5 lg:h-3/5"
          style={{ zIndex: 1 }}
        ></div>
        <div
          className="absolute bottom-0 left-0 bg-teal-200 w-full h-1/2 lg:m-20 lg:rounded-lg lg:block lg:h-3/5 lg:w-3/5"
          style={{ zIndex: 0 }}
        ></div>
        <div
          className="px-2 absolute bottom-0 right-0 overflow-hidden w-full h-full lg:mx-20 lg:w-3/4 lg:h-3/4"
          style={{ zIndex: 2 }}
        >
          <LoginSplash className="w-full h-full text-indigo-200" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
