import React, { FunctionComponent, useState } from "react";
import { ChevronDown as ChevronDownIcon } from "react-feather";

import { PostFormValues } from "../../../../../types/posts";
import { Platform, PlatformEntity } from "../../../../../types/platforms";
import { getPlatformTypeName } from "../../../../../libs/platform";
import { clamp } from "../../../../../libs/utils";

import H4 from "../../../../../components/headings/H4";
import Select from "../../../../../components/form/Select";
import PlatformIcon from "../../../../../components/icons/Platform";

interface FormPostPreviewsContainerProps {
  platformType: Platform["type"];
  socials: PostFormValues["socials"];
  children: FunctionComponent<{ platformEntity: PlatformEntity }>;
  className?: string;
}
const FormPostPreviewsContainer: React.FC<FormPostPreviewsContainerProps> = ({
  platformType,
  socials,
  children,
  className = "",
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const index = clamp(selectedIndex, 0, socials.length - 1);
  const platformEntity =
    socials[index].platform.entities[socials[index].platformEntityId];
  const hasMultipleSocials = socials.length > 1;
  const platformTypeName = getPlatformTypeName(platformType);

  return (
    <div className={className}>
      <div className="flex items-center">
        <PlatformIcon className="h-6 w-6" type={platformType} colour={true} />
        <H4 className="px-2 text-normal text-gray-500">{platformTypeName}</H4>
        {hasMultipleSocials && (
          <Select
            dropdownClassName="ml-auto"
            toggleButtonClassName="px-1 py-1 border-0 text-gray-500 text-xs"
            dropdownXAlign="right"
            labelText={`Select platform entity for ${platformTypeName} preview`}
            hideLabel={true}
            value={"" + index}
            options={socials.map(
              ({ platform, platformEntityId }, socialIndex) => {
                return {
                  text: platform.entities[platformEntityId].name,
                  value: "" + socialIndex,
                };
              }
            )}
            onChange={(newSelectedIndex) => {
              setSelectedIndex(parseInt(newSelectedIndex, 10));
            }}
            renderSelectedOption={(selectedOption) => (
              <div className="flex items-center w-full">
                <span className="truncate">
                  {selectedOption ? selectedOption.text : ""}
                </span>

                <ChevronDownIcon className="ml-1 flex-shrink-0 h-4 w-4" />
              </div>
            )}
          />
        )}
      </div>
      {children({ platformEntity })}
    </div>
  );
};

export default FormPostPreviewsContainer;
