import { useCallback, useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { Auth } from "@aws-amplify/auth";

import { purgeAll } from "../localStorage";

import AuthenticatedAppContext from "../../contexts/app/Authenticated";
import WorkspaceAppContext, {
  WorkspaceAppContextState,
} from "../../contexts/app/Workspace";

export const useCurrentUser = () => {
  const context = useContext(AuthenticatedAppContext);
  return context.currentUser;
};

export const useIsEmailVerified = () => {
  const context = useContext(AuthenticatedAppContext);
  return context.isEmailVerified;
};

export const useApiClient = () => {
  const context = useContext(AuthenticatedAppContext);
  return context.apiClient;
};

export const useCurrentUserTimeZone = () => {
  const user = useCurrentUser();
  return user.timeZone;
};

export const useCurrentUserDateTime = () => {
  const timeZone = useCurrentUserTimeZone();
  return DateTime.local().setZone(timeZone);
};

export const useActiveWorkspace = () => {
  const context = useContext(WorkspaceAppContext);
  return context.activeWorkspace;
};

export const useActiveWorkspaceFileAccessToken = () => {
  const context = useContext(WorkspaceAppContext);
  return context.fileAccessToken;
};

export const useActiveWorkspaceTimeZone = () => {
  const context = useContext(WorkspaceAppContext);
  return context.timeZone;
};

export const useActiveWorkspaceDateTime = () => {
  const timeZone = useActiveWorkspaceTimeZone();
  return DateTime.local().setZone(timeZone);
};

export const useNow = () => {
  return useActiveWorkspaceDateTime();
};

export const useDateTimeFactory = () => {
  const timeZone = useActiveWorkspaceTimeZone();

  return (isoString: string) => {
    return DateTime.fromISO(isoString).setZone(timeZone);
  };
};

export const useDateTime = (isoString: string) => {
  const factory = useDateTimeFactory();
  return factory(isoString);
};

export const useModalData = <
  T extends keyof WorkspaceAppContextState["modals"]
>(
  modalName: T
): WorkspaceAppContextState["modals"][T] => {
  const context = useContext(WorkspaceAppContext);
  return context.modals[modalName];
};

export const useDrawerData = <
  T extends keyof WorkspaceAppContextState["drawers"]
>(
  drawerName: T
): WorkspaceAppContextState["drawers"][T] => {
  const context = useContext(WorkspaceAppContext);
  return context.drawers[drawerName];
};

export const useSetModal = () => {
  const context = useContext(WorkspaceAppContext);
  return context.setModal;
};

export const useShowModal = () => {
  const context = useContext(WorkspaceAppContext);
  return context.showModal;
};

export const useHideModal = () => {
  const context = useContext(WorkspaceAppContext);
  return context.hideModal;
};

export const useIsModalVisible = () => {
  const context = useContext(WorkspaceAppContext);
  return useCallback(
    <T extends keyof WorkspaceAppContextState["modals"]>(modalName: T) => {
      return context.modals[modalName] !== undefined
        ? context.modals[modalName]!.isVisible
        : false;
    },
    [context.modals]
  );
};

export const useSetDrawer = () => {
  const context = useContext(WorkspaceAppContext);
  return context.setDrawer;
};

export const useShowDrawer = () => {
  const context = useContext(WorkspaceAppContext);
  return context.showDrawer;
};

export const useHideDrawer = () => {
  const context = useContext(WorkspaceAppContext);
  return context.hideDrawer;
};

export const useIsDrawerVisible = () => {
  const context = useContext(WorkspaceAppContext);
  return useCallback(
    <T extends keyof WorkspaceAppContextState["drawers"]>(drawerName: T) => {
      return context.drawers[drawerName] !== undefined
        ? context.drawers[drawerName]!.isVisible
        : false;
    },
    [context.drawers]
  );
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const context = useContext(AuthenticatedAppContext);
  return useMutation(
    async () => {
      await Auth.signOut();
      purgeAll();
      context.logout();

      return null;
    },
    {
      onSuccess: () => {
        queryClient.clear();
      },
    }
  );
};
