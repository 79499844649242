import React from "react";

import {
  FACEBOOK_REDIRECT_URI,
  getFacebookAuthorisationCode,
} from "../../../libs/platforms/facebook";
import { useActiveWorkspace } from "../../../libs/hooks/app";
import { useCreatePlatform } from "../../../libs/hooks/platforms";

import {
  BaseComponentProps,
  ConnectFacebook,
} from "../../../types/modals/AddPlatform";

import ConnectBase from "./ConnectBase";

interface ConnectFacebookProps extends BaseComponentProps {
  contentId: ConnectFacebook["id"];
}
const AddPlatformModalConnectFacebook: React.FC<ConnectFacebookProps> = ({
  contentId,
  ...rest
}) => {
  const workspace = useActiveWorkspace();
  const { mutateAsync: createPlatform } = useCreatePlatform();

  const handleCreatePlatform = async () => {
    const newAuthorisationCode = await getFacebookAuthorisationCode();

    if (!newAuthorisationCode) {
      throw new Error("Unable to receive user authorisation from Facebook");
    }

    return createPlatform({
      workspaceId: workspace.id,
      type: "FACEBOOK",
      code: newAuthorisationCode,
      redirectUri: FACEBOOK_REDIRECT_URI,
    });
  };

  return (
    <ConnectBase
      contentId={contentId}
      tryAgainContent={{ id: contentId }}
      platformType="FACEBOOK"
      createPlatform={handleCreatePlatform}
      {...rest}
    />
  );
};

export default AddPlatformModalConnectFacebook;
