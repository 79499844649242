import React, { useMemo } from "react";
import { ArrowLeft as ArrowLeftIcon } from "react-feather";

import { Platform } from "../../../types/platforms";
import { Post } from "../../../types/posts";

import { getPlatformTypeName } from "../../../libs/platform";

import H2 from "../../../components/headings/H2";
import H3 from "../../../components/headings/H3";
import PublishedPostActionIcon from "../../../components/icons/PublishedPostAction";
import PlatformIcon from "../../../components/icons/Platform";
import PostPreview from "../../../components/posts/Preview";
import IconButton from "../../../components/buttons/Icon";

import LinkedInForm, {
  LinkedInFormValues,
} from "../../forms/LinkedInPublishedPostActions";
import TwitterForm, {
  TwitterFormValues,
} from "../../forms/TwitterPublishedPostActions";
import FacebookForm, {
  FacebookFormValues,
} from "../../forms/FacebookPublishedPostActions";
import { useContentStack } from "../../../libs/hooks/general";

interface MultiPostPublishedPostActionsEditActionsProps {
  contentId: "editActions";
  post: Post;
  platforms: Platform[];
  defaultValues: LinkedInFormValues | TwitterFormValues | FacebookFormValues;
  onSubmit: (
    post: Post,
    formData: LinkedInFormValues | TwitterFormValues | FacebookFormValues
  ) => void;
}
const MultiPostPublishedPostActionsEditActions: React.FC<
  MultiPostPublishedPostActionsEditActionsProps
> = ({ post, platforms, defaultValues, onSubmit }) => {
  const { pop } = useContentStack();
  const postPlatform = useMemo(() => {
    return platforms.find((candidate) => candidate.id === post.platformId);
  }, [platforms, post.platformId]);
  const postPlatformEntity = useMemo(() => {
    return postPlatform
      ? postPlatform.entities[post.platformEntityId]
      : undefined;
  }, [postPlatform, post.platformEntityId]);

  return (
    <div className="p-2 sm:px-8 sm:pb-8 sm:pt-6">
      <div style={{ width: "80rem" }}></div>
      <div className="flex flex-wrap justify-around gap-8">
        <div
          className="flex-1 flex flex-col"
          style={{ minWidth: 480, maxWidth: 600 }}
        >
          <div className="shrink-0 flex item-center">
            <IconButton onClick={pop}>
              <ArrowLeftIcon />
            </IconButton>
            <H2 className="ml-2 flex items-center justify-center text-2xl text-black">
              <PublishedPostActionIcon className="h-6 w-6" />
              <span className="ml-2">After publish actions</span>
            </H2>
          </div>
          {post.type === "LINKEDIN" && (
            <LinkedInForm
              className="grow"
              post={post}
              platforms={platforms}
              defaultValues={defaultValues as LinkedInFormValues}
              onSubmit={(newValues) => {
                onSubmit(post, newValues);
                pop();
              }}
              isSubmitting={false}
            />
          )}
          {post.type === "TWITTER" && (
            <TwitterForm
              className="grow"
              post={post}
              platforms={platforms}
              defaultValues={defaultValues as TwitterFormValues}
              onSubmit={(newValues) => {
                onSubmit(post, newValues);
                pop();
              }}
              isSubmitting={false}
            />
          )}
          {post.type === "FACEBOOK" && (
            <FacebookForm
              className="grow"
              post={post}
              platforms={platforms}
              defaultValues={defaultValues as FacebookFormValues}
              onSubmit={(newValues) => {
                onSubmit(post, newValues);
                pop();
              }}
              isSubmitting={false}
            />
          )}
        </div>
        <div
          className="flex-1 flex flex-col"
          style={{ minWidth: 480, minHeight: 400, maxWidth: 700 }}
        >
          <div className="h-8 relative shrink-0 flex items-center justify-center">
            <H3 className="flex items-center text-xl text-gray-500">
              <PlatformIcon type={post.type} />
              <span className="ml-2">
                {`${getPlatformTypeName(post.type)} post`}
              </span>
            </H3>
          </div>
          <div className="mt-4 grow w-full relative rounded-lg bg-gray-50 border border-gray-100 shadow">
            <div className="p-2 absolute inset-0 overflow-x-hidden overflow-y-auto">
              <div className="flex justify-center">
                {postPlatformEntity && (
                  <PostPreview
                    post={post}
                    platformEntity={postPlatformEntity}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiPostPublishedPostActionsEditActions;
