import React, { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import { useChangePassword } from "../../libs/hooks/users";
import { useSnackBarFactory } from "../../libs/hooks/general";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import PrimaryButton from "../../components/buttons/Primary";
import PasswordInput, {
  passwordSchema,
} from "../../components/form/input/Password";
import ChangesSavedSnackBarContent from "../../components/snackBarContent/ChangesSaved";

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const formSchema = Joi.object({
  currentPassword: Joi.string().required(),
  newPassword: passwordSchema.required(),
  confirmNewPassword: Joi.ref("newPassword"),
});

interface ChangePasswordModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  close: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isVisible,
  close,
  ...modalProps
}) => {
  const createSnackBar = useSnackBarFactory();
  const {
    isLoading,
    loadingText,
    errorAlert,
    mutateAsync: changePassword,
  } = useChangePassword();
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema),
  });
  const hasErrors = Object.keys(errors).length > 0;

  const onSubmit = async ({ currentPassword, newPassword }: FormValues) => {
    await changePassword({ currentPassword, newPassword });
    createSnackBar({
      content: <ChangesSavedSnackBarContent message="Password changed" />,
    });
    close();
  };

  useEffect(() => {
    if (isVisible) {
      setFocus("currentPassword");
    }
  }, [isVisible, setFocus]);

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="p-8" style={{ width: 500 }}>
        <H3>Change password</H3>
        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            {...register("currentPassword")}
            labelText="Current password"
            labelClassName="mt-4"
            error={!!errors.currentPassword}
          />
          <PasswordInput
            {...register("newPassword")}
            labelText="New password"
            labelClassName="mt-4"
            showRequirements={true}
            error={!!errors.newPassword}
          />
          <PasswordInput
            {...register("confirmNewPassword")}
            labelText="Confirm new password"
            labelClassName="mt-4"
            error={!!errors.confirmNewPassword}
          />
          {errorAlert({ className: "mt-6" })}
          <PrimaryButton
            className="mt-6 w-full text-xl text-white flex items-center"
            type="submit"
            disabled={isLoading || hasErrors || !isDirty}
          >
            {loadingText({
              default: "Change password",
              loading: "Changing password...",
            })}
          </PrimaryButton>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
