import React, { useMemo } from "react";
import { DateTime } from "luxon";
import Joi from "joi";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CardElement, useElements } from "@stripe/react-stripe-js";

import { Subscription } from "../../../types/subscriptions";

import {
  useActiveWorkspaceTimeZone,
  useCurrentUser,
} from "../../../libs/hooks/app";
import {
  useCreatePaymentMethod,
  usePaymentMethods,
} from "../../../libs/hooks/paymentMethods";
import { useUpdateSubscription } from "../../../libs/hooks/subscriptions";
import { useAsyncState, useSnackBarFactory } from "../../../libs/hooks/general";

import PrimaryButton from "../../../components/buttons/Primary";
import PaymentMethodSelector, {
  paymentMethodSelectorValueSchema,
  PaymentMethodSelectorValue,
} from "../../../components/form/PaymentMethodSelector";
import ChangesSavedSnackBarContent from "../../../components/snackBarContent/ChangesSaved";

interface FormValues {
  paymentMethod: PaymentMethodSelectorValue;
}

const formSchema = Joi.object({
  paymentMethod: paymentMethodSelectorValueSchema,
});

interface SubscriptionPaymentMethodSectionProps {
  subscription: Subscription;
  className?: string;
}
const SubscriptionPaymentMethodSection: React.FC<
  SubscriptionPaymentMethodSectionProps
> = ({ subscription, className = "" }) => {
  const user = useCurrentUser();
  const timeZone = useActiveWorkspaceTimeZone();
  const elements = useElements();
  const {
    mutateAsync: createPaymentMethod,
    errorAlert: createPaymentMethodErrorAlert,
    clearErrorAlert: clearCreatePaymentMethodErrorAlert,
  } = useCreatePaymentMethod();
  const {
    mutateAsync: updateSubscription,
    errorAlert: updateSubscriptionErrorAlert,
    clearErrorAlert: clearUpdateSubscriptionErrorAlert,
  } = useUpdateSubscription();
  const createSnackBar = useSnackBarFactory();
  const {
    loading: isSubmitting,
    setLoading: setIsSubmitting,
    loadingText: submitLoadingText,
  } = useAsyncState();
  const {
    control,
    watch,
    reset,
    formState: { errors, isDirty },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onSubmit",
    resolver: joiResolver(formSchema, { convert: false }),
    defaultValues: {
      paymentMethod: subscription.paymentMethod
        ? { type: "EXISTING", value: subscription.paymentMethod.id }
        : { type: "NEW", value: { empty: true, complete: false } },
    },
  });
  const { data, isLoading: isLoadingPaymentMethods } = usePaymentMethods(
    user.id
  );
  const paymentMethods = useMemo(() => data || [], [data]);
  const hasErrors = Object.keys(errors).length > 0;
  const watchPaymentMethod = watch("paymentMethod");
  const hasPaymentMethod = watchPaymentMethod
    ? watchPaymentMethod.type === "NEW"
      ? watchPaymentMethod.value.complete
      : true
    : false;
  const requiresPaymentMethodBeforeTrialEnd =
    subscription.status === "trialing" && !subscription.paymentMethod;
  const displayPeriodEnd = DateTime.fromISO(subscription.periodEnd)
    .setZone(timeZone)
    .toFormat("MMM d");
  const canChangePaymentMethod = subscription.status !== "canceled";

  const onSubmit: SubmitHandler<FormValues> = async ({
    paymentMethod: paymentMethodData,
  }) => {
    if (hasErrors) {
      return;
    }

    if (isSubmitting) {
      return;
    }

    if (paymentMethodData.type === "NEW" && !paymentMethodData.value.complete) {
      return;
    }

    setIsSubmitting(true);
    clearCreatePaymentMethodErrorAlert();
    clearUpdateSubscriptionErrorAlert();

    try {
      let paymentMethodId: string = "";

      if (paymentMethodData.type === "NEW") {
        // The user has entered a new card so create the payment method.
        const cardElement = elements ? elements.getElement(CardElement) : null;

        if (!cardElement) {
          return;
        }

        const newPaymentMethod = await createPaymentMethod({ cardElement });
        paymentMethodId = newPaymentMethod.id;
      } else {
        // The user has selected one of their existing payment methods.
        paymentMethodId = paymentMethodData.value;
      }

      const updateProps: Parameters<
        typeof updateSubscription
      >[0]["updateProps"] = {
        paymentMethodId,
      };

      if (requiresPaymentMethodBeforeTrialEnd) {
        // We're adding a payment method now so we can stop the cancelation
        // at the end of the trial period.
        updateProps.cancelAtPeriodEnd = false;
      }

      await updateSubscription({
        subscription,
        updateProps,
      });
      createSnackBar({
        content: (
          <ChangesSavedSnackBarContent message="Payment method saved!" />
        ),
      });
      reset({
        paymentMethod: {
          type: "EXISTING",
          value: paymentMethodId,
        },
      });
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <div className={className}>
      {requiresPaymentMethodBeforeTrialEnd && (
        <p>
          Add a payment method <strong>before {displayPeriodEnd}</strong> to
          automatically continue your subscription once your trial period
          expires.
        </p>
      )}
      <form
        className={requiresPaymentMethodBeforeTrialEnd ? "mt-4" : ""}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field: { onChange, onBlur, value } }) => (
            <PaymentMethodSelector
              value={value}
              paymentMethods={paymentMethods}
              loading={isLoadingPaymentMethods}
              hideLabel={true}
              error={!!errors.paymentMethod}
              onChange={onChange}
              onBlur={onBlur}
              onError={(message) => {
                if (message) {
                  setError("paymentMethod", { type: "value", message });
                } else {
                  clearErrors("paymentMethod");
                }
              }}
              disabled={!canChangePaymentMethod}
            />
          )}
        />
        {createPaymentMethodErrorAlert({ className: "mt-10" })}
        {updateSubscriptionErrorAlert({ className: "mt-10" })}
        <PrimaryButton
          type="submit"
          className="mt-10 w-full"
          disabled={
            isSubmitting ||
            !isDirty ||
            hasErrors ||
            !hasPaymentMethod ||
            !canChangePaymentMethod
          }
        >
          {submitLoadingText({
            loading: "Saving payment method...",
            default: "Save payment method",
          })}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default SubscriptionPaymentMethodSection;
