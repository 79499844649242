import { ComponentProps, forwardRef } from "react";
import { mergeClassNames } from "../../libs/components";
import Button from "./Button";

interface DangerButtonProps extends ComponentProps<typeof Button> {
  invert?: boolean;
}

const DangerButton = forwardRef<HTMLButtonElement, DangerButtonProps>(
  ({ className = "", invert = false, ...props }, ref) => {
    const localClassName = invert
      ? "text-red-500 hover:bg-red-500 hover:text-white focus:bg-red-500 focus:text-white"
      : "bg-red-500 text-white hover:bg-red-600 focus:bg-red-600";
    return (
      <Button
        ref={ref}
        className={mergeClassNames(localClassName, className)}
        {...props}
      />
    );
  }
);

export default DangerButton;
