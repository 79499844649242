import React from "react";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import ProfilePictureSection from "../containers/profile/sections/Picture";
import ProfileAboutYouSection from "../containers/profile/sections/AboutYou";
import ProfileSecuritySection from "../containers/profile/sections/Security";
import ProfileDangerZoneSection from "../containers/profile/sections/DangerZone";

import H2 from "../components/headings/H2";

const ProfilePage: React.FC = () => {
  return (
    <AppPageWithHeader>
      <div className="py-4 flex justify-center">
        <div className="relative flex flex-col" style={{ width: 600 }}>
          <ProfilePictureSection />
          <H2 className="mt-10">About you</H2>
          <ProfileAboutYouSection />
          <H2 className="mt-20">Security</H2>
          <ProfileSecuritySection className="mt-4" />
          <H2 className="mt-20">Danger zone</H2>
          <ProfileDangerZoneSection className="mt-4" />
        </div>
      </div>
    </AppPageWithHeader>
  );
};

export default ProfilePage;
