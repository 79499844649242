export default {
  AUTH: {
    // When the user is trying to reauthorise an existing platform but they
    // generate an auth code while logged in to a different account.
    PLATFORM_MISMATCH: "AUTH-0001",
  },
  SUBSCRIPTION: {
    // When the user tries to perform a task that would lead to them exceeding
    // the limits on their current subscription, e.g. create too many posts on
    // the free plan.
    LIMIT_EXCEEDED: "SUB-0001",
  },
} as const;
