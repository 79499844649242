import React, { useRef, useEffect, ReactNode } from "react";
import OpenCloseStackContext, {
  OpenCloseStackValue,
} from "../contexts/OpenCloseStack";

interface OpenCloseStackProviderProps {
  children: ReactNode;
}
const OpenCloseStackProvider: React.FC<OpenCloseStackProviderProps> = ({
  children,
}) => {
  const handlersRef = useRef<OpenCloseStackValue>([]);

  useEffect(() => {
    return () => {
      handlersRef.current = [];
    };
  });

  return (
    <OpenCloseStackContext.Provider value={handlersRef.current}>
      {children}
    </OpenCloseStackContext.Provider>
  );
};

export default OpenCloseStackProvider;
