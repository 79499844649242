import React from "react";
import { UploadCloud as UploadCloudIcon, X as XIcon } from "react-feather";

interface UploadFailedSnackBarContentProps {
  fileName: string;
  errorDesc: string;
}

const UploadFailedSnackBarContent: React.FC<
  UploadFailedSnackBarContentProps
> = ({ fileName, errorDesc }) => {
  return (
    <div className="w-full flex items-center overflow-hidden">
      <div className="shrink-0 relative">
        <UploadCloudIcon className="h-6 w-6 opacity-70" />
        <XIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-6 w-6 text-red-300" />
      </div>
      <div className="ml-2 overflow-hidden">
        <div className="truncate">{fileName}</div>
        <div className="text-red-300">{errorDesc}</div>
      </div>
    </div>
  );
};

export default UploadFailedSnackBarContent;
