import Twitter from "twitter-text";
import { DraftDecorator } from "draft-js";
import {
  Build,
  BuildDecorators,
  DraftDecoratorComponentProps,
  ENTITY_TYPES,
} from "../../../../../libs/postTextArea";
import Tooltip from "../../../../../components/Tooltip";

const ENTITY_TYPE = ENTITY_TYPES.LINK;

const strategy: DraftDecorator["strategy"] = (contentBlock, callback) => {
  const urls = Twitter.extractUrlsWithIndices(contentBlock.getText());
  urls.forEach(({ indices }) => {
    callback(...indices);
  });
};

interface BuildSearchComponentProps {
  onClick: (blockKey: string, start: number, end: number, url: string) => void;
}
const buildSearchComponent =
  ({
    onClick,
  }: BuildSearchComponentProps): React.FC<DraftDecoratorComponentProps> =>
  ({ children, blockKey, start, end, decoratedText }) => {
    return (
      <button
        className="text-purple-500"
        type="button"
        onClick={() => {
          onClick(blockKey, start, end, decoratedText);
        }}
      >
        <Tooltip content={`Click to use link as attachment`}>
          {children}
        </Tooltip>
      </button>
    );
  };

const buildDecorators: BuildDecorators<BuildSearchComponentProps> = (props) => {
  return [
    {
      strategy,
      component: buildSearchComponent(props),
    },
  ];
};

const build: Build<BuildSearchComponentProps> = (props) => {
  return {
    type: ENTITY_TYPE,
    decorators: buildDecorators(props),
  };
};

export default build;
