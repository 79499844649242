import React from "react";
import {
  CheckCircle as CheckCircleIcon,
  Database as DatabaseIcon,
  Lock as LockIcon,
  CornerUpRight as CornerUpRightIcon,
  Repeat as RepeatIcon,
} from "react-feather";

import { Platform } from "../../../types/platforms";
import { Post } from "../../../types/posts";

import { getPlatformTypeName } from "../../../libs/platform";
import { useDateTimeFactory } from "../../../libs/hooks/app";

import PlatformEntityProfilePicture from "../../../components/images/profile/PlatformEntity";
import H3 from "../../../components/headings/H3";
import SmartScheduleIcon from "../../../components/icons/SmartSchedule";
import PlatformIcon from "../../../components/icons/Platform";

interface ViewPostDetailsProps {
  post: Post;
  platform: Platform;
  className?: string;
}
const ViewPostDetails: React.FC<ViewPostDetailsProps> = ({
  post,
  platform,
  className = "",
}) => {
  const createDateTime = useDateTimeFactory();
  const platformEntity = platform.entities[post.platformEntityId];

  return (
    <div className={className}>
      <div className="flex items-center">
        <PlatformEntityProfilePicture
          className="h-20 w-20"
          entity={platformEntity}
        />
        <div className="ml-2">
          <div className="text-3xl">{platformEntity.name}</div>
          {platformEntity.username && (
            <div className="text-gray-500">{`@${platformEntity.username}`}</div>
          )}
        </div>
      </div>

      <H3 className="mt-4 text-lg text-gray-500">Details</H3>
      <div className="mt-2 flex items-center">
        <PlatformIcon className="h-6 w-6" type={platform.type} />
        <div className="ml-2">
          {`${getPlatformTypeName(platform.type)} post`}
        </div>
      </div>
      <div className="mt-2 flex items-center">
        <DatabaseIcon className="h-6 w-6" />
        <div className="ml-2">
          {post.createdExternally
            ? `Imported from ${getPlatformTypeName(platform.type)}`
            : `Created on ${createDateTime(post.created).toFormat(
                "DDDD 'at' t ZZZZ"
              )}`}
        </div>
      </div>
      {post.queueId && (
        <div className="mt-2 flex items-center">
          <SmartScheduleIcon className="h-6 w-6" />
          <div className="ml-2">Smart scheduled post</div>
        </div>
      )}
      {!post.queueId && (
        <div className="mt-2 flex items-center">
          <LockIcon className="h-6 w-6" />
          <div className="ml-2">Fixed time post</div>
        </div>
      )}
      {post.queueId && (
        <div className="mt-2 flex items-center">
          {post.shouldRecycle ? (
            <RepeatIcon className="h-6 w-6" />
          ) : (
            <CornerUpRightIcon className="h-6 w-6" />
          )}
          <div className="ml-2">
            {post.shouldRecycle
              ? "Recycled back to the smart schedule drafts"
              : "Published once"}
          </div>
        </div>
      )}
      {post.queueId && post.recycledCount && (
        <div className="mt-2 flex items-center">
          <RepeatIcon className="h-6 w-6" />
          <div className="ml-2">
            {`Recycled post number ${post.recycledCount}`}
          </div>
        </div>
      )}
      {post.posted && (
        <div className="mt-2 flex w-80">
          <CheckCircleIcon className="h-6 w-6 shrink-0" />
          <div className="ml-2">
            {`Published on ${createDateTime(post.posted).toFormat(
              "DDDD 'at' t ZZZZ"
            )}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewPostDetails;
