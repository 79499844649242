import React from "react";

import { mergeClassNames } from "../../libs/components";
import { getPlatformTypeColour } from "../../libs/platform";
import { Platform } from "../../types/platforms";

import { ReactComponent as FacebookIcon } from "../../images/icons/facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../images/icons/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../../images/icons/twitter.svg";

interface PlatformIconProps {
  type: Platform["type"];
  className?: string;
  colour?: boolean;
}

const PlatformIcon: React.FC<PlatformIconProps> = ({
  type,
  className = "",
  colour = true,
}) => {
  switch (type) {
    case "LINKEDIN":
      return (
        <LinkedInIcon
          className={mergeClassNames("w-6 h-6", className)}
          style={
            colour ? { color: getPlatformTypeColour("LINKEDIN") } : undefined
          }
        />
      );
    case "TWITTER":
      return (
        <TwitterIcon
          className={mergeClassNames("w-6 h-6", className)}
          style={
            colour ? { color: getPlatformTypeColour("TWITTER") } : undefined
          }
        />
      );
    case "FACEBOOK":
      return (
        <FacebookIcon
          className={mergeClassNames("w-6 h-6", className)}
          style={
            colour ? { color: getPlatformTypeColour("FACEBOOK") } : undefined
          }
        />
      );
  }
};

export default PlatformIcon;
