import React, { RefObject, useRef, useState } from "react";
import { DateTime } from "luxon";
import { animated, to, useSpring } from "@react-spring/web";
import {
  EyeOffIcon,
  SelectorIcon,
  PauseIcon,
  StatusOnlineIcon,
  InformationCircleIcon,
  ClockIcon,
  BeakerIcon,
} from "@heroicons/react/outline";
import {
  AlertTriangle as AlertTriangleIcon,
  Activity as ActivityIcon,
} from "react-feather";

import { Platform, PlatformEntity } from "../../types/platforms";
import { PostQueue } from "../../types/postQueues";
import { Post } from "../../types/posts";

import { mergeClassNames } from "../../libs/components";
import { getPlatformTypeName } from "../../libs/platform";
import { canPublishQueuedPost } from "../../libs/subscriptions";
import { POST_QUEUE_FREQUENCY_OPTIONS } from "../../libs/postQueues";
import {
  useActiveWorkspace,
  useActiveWorkspaceTimeZone,
  useNow,
  useShowModal,
} from "../../libs/hooks/app";
import { useUpdatePostQueue } from "../../libs/hooks/postQueues";
import { useDragDrop } from "../../libs/hooks/general";

import PlatformEntityProfilePicture from "../../components/images/profile/PlatformEntity";
import H5 from "../../components/headings/H5";
import PlatformIcon from "../../components/icons/Platform";
import TertiaryButton from "../../components/buttons/Tertiary";
import LoaderIcon from "../../components/icons/Loader";
import NeutralBadge from "../../components/badges/Neutral";
import HighlightBadge from "../../components/badges/Highlight";
import DangerBadge from "../../components/badges/Danger";
import DragAutoScroll from "../../components/DragAutoScroll";
import PrimaryLightButton from "../../components/buttons/PrimaryLight";

import QueuedPosts, { QUEUE_POST_DRAG_TYPE } from "./queue/Posts";
import SettingMenu from "./queue/Settings";
import Tooltip from "../../components/Tooltip";

export const QUEUE_DRAG_TYPE = "postQueue";

interface SmartSchedulesQueueProps {
  platform: Platform;
  platformEntity: PlatformEntity;
  postQueue: PostQueue;
  posts: Post[];
  scrollContainerRef: RefObject<HTMLElement>;
  className?: string;
}
const SmartSchedulesQueue: React.FC<SmartSchedulesQueueProps> = ({
  platform,
  platformEntity,
  postQueue,
  posts,
  scrollContainerRef,
  className = "",
}) => {
  const postsScrollContainerRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const timeZone = useActiveWorkspaceTimeZone();
  const workspace = useActiveWorkspace();
  const now = useNow();
  const canPublish = canPublishQueuedPost(workspace, now);
  const showModal = useShowModal();
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const { mutateAsync: updatePostQueue, isLoading: isUpdating } =
    useUpdatePostQueue();
  const { drag } = useDragDrop();
  const [spring, springApi] = useSpring(() => ({
    x: 0,
    zIndex: 0,
    shadow: 1,
    scale: 1,
    immediate: (k) => k === "zIndex",
  }));
  const currentFrequency = postQueue.postFrequency || "NORMAL";

  const handleDragStart: Parameters<typeof drag>[0]["onStart"] = () => {
    springApi.start({
      x: 0,
      zIndex: 100,
      shadow: 15,
      scale: 1.02,
      immediate: (k) => k === "zIndex" || k === "x",
    });
  };

  const handleDragUpdate: Parameters<typeof drag>[0]["onUpdate"] = ({
    state,
    scrollMove,
  }) => {
    const [scrollXDiff] = scrollMove;
    const [moveX] = state.movement;

    springApi.start({
      x: moveX + scrollXDiff,
      immediate: (k) => k === "zIndex" || k === "x",
    });
  };

  const handleDragEnd: Parameters<typeof drag>[0]["onEnd"] = () => {
    springApi.start({
      x: 0,
      zIndex: 0,
      shadow: 1,
      scale: 1,
      immediate: (k) => k === "zIndex",
    });
  };

  return (
    <animated.div
      ref={itemRef}
      className={mergeClassNames(
        `flex flex-col h-full rounded-xl bg-gray-100 ${
          postQueue.hidden
            ? "opacity-60 hover:opacity-100 focus-within:opacity-100"
            : ""
        }`,
        className
      )}
      style={{
        cursor: spring.zIndex.to((z) => (z > 0 ? "grabbing" : "inherit")),
        boxShadow: spring.shadow.to(
          (s) =>
            `0 ${s}px ${3 * s}px 0 rgb(0 0 0 / 0.1), 0 ${s}px ${2 * s}px ${
              -1 * s
            }px rgb(0 0 0 / 0.1)`
        ),
        transform: to(
          [spring.x, spring.scale],
          (x, s) => `translate3d(${x}px, 0, 0) scale(${s})`
        ),
        zIndex: spring.zIndex,
      }}
    >
      <div className="p-2 flex-shrink-0 relative rounded-t-xl bg-white border-b border-gray-100">
        <div className="flex items-start">
          <div className="flex-shrink-0 h-20 w-20 relative">
            <PlatformEntityProfilePicture
              className="h-20 w-20"
              entity={platformEntity}
            />
            {(isUpdating || isPostsLoading) && (
              <div className="absolute inset-0 flex items-center justify-center rounded-full bg-gray-900 bg-opacity-60 text-white">
                <LoaderIcon className="h-10 w-10" />
              </div>
            )}
          </div>
          <div className="ml-2 h-20 flex-grow flex flex-col overflow-hidden">
            <div className="flex items-center">
              <H5 className="truncate">{platformEntity.name}</H5>
              <div className="ml-auto flex items-center">
                {postQueue.hidden && (
                  <NeutralBadge className="ml-1 flex items-center">
                    <EyeOffIcon className="mr-1 flex-shrink-0 h-4 w-4" /> Hidden
                  </NeutralBadge>
                )}
                {postQueue.status === "PAUSED" && (
                  <HighlightBadge className="ml-1 flex items-center">
                    <PauseIcon className="mr-1 flex-shrink-0 h-4 w-4" /> Paused
                  </HighlightBadge>
                )}
                {!canPublish && (
                  <DangerBadge className="ml-1 flex items-center">
                    <AlertTriangleIcon className="mr-1 flex-shrink-0 h-4 w-4" />
                    Limited
                  </DangerBadge>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <PlatformIcon
                className="h-6 w-6"
                type={platform.type}
                colour={true}
              />
              <span className="ml-1 uppercase text-xs font-bold text-gray-400">
                {getPlatformTypeName(platform.type)}
              </span>
            </div>
            <Tooltip
              className="mt-1 flex items-center"
              content={
                <div>
                  <div className="font-bold text-xs">POSTING FREQUENCY</div>
                  <div className="mt-1 text-lg text-purple-300">
                    {POST_QUEUE_FREQUENCY_OPTIONS[currentFrequency].title}
                  </div>
                  <div className="text-xs text-gray-300">
                    {POST_QUEUE_FREQUENCY_OPTIONS[currentFrequency].desc}
                  </div>
                </div>
              }
            >
              <div className="p-1 rounded-full bg-gray-200">
                <ActivityIcon className="h-4 w-4" />
              </div>

              <div className="ml-1 text-sm">
                {POST_QUEUE_FREQUENCY_OPTIONS[currentFrequency].title}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="pt-2 flex items-center justify-between text-gray-500">
          <animated.div
            style={{
              cursor: spring.zIndex.to((z) => (z > 0 ? "grabbing" : "grab")),
            }}
          >
            <TertiaryButton
              ref={drag({
                itemId: postQueue.id,
                type: QUEUE_DRAG_TYPE,
                itemRef,
                scrollContainerRef,
                onStart: handleDragStart,
                onUpdate: handleDragUpdate,
                onEnd: handleDragEnd,
              })}
              className="bg-transparent"
              size="sm"
              disabled={isUpdating}
              style={{ cursor: "inherit" }}
            >
              <SelectorIcon className="h-5 w-5 mr-1 rotate-90" />
              Move
            </TertiaryButton>
          </animated.div>

          <SettingMenu
            postQueue={postQueue}
            updatePostQueue={(updateProps) =>
              updatePostQueue({ postQueue, updateProps })
            }
            isUpdating={isUpdating}
          />
        </div>
      </div>
      {!canPublish && (
        <div className="px-1 py-1 flex items-center justify-center text-center text-red-900 bg-red-100">
          <AlertTriangleIcon className="mr-1 shrink-0 h-6 w-6" />
          Monthly published post limit reached
        </div>
      )}
      {postQueue.state && (
        <PrimaryLightButton
          className="px-1 py-1 group w-full font-normal rounded-none relative"
          onClick={() =>
            showModal("explainSmartSchedulesState", {
              platform,
              platformEntity,
              postQueue: postQueue as any,
            })
          }
        >
          {postQueue.state.type === "MONITORING" ? (
            <StatusOnlineIcon className="mr-1 shrink-0 h-6 w-6" />
          ) : postQueue.state.type === "WAITING" ? (
            <BeakerIcon className="mr-1 shrink-0 h-6 w-6" />
          ) : (
            <ClockIcon className="mr-1 shrink-0 h-6 w-6" />
          )}
          {postQueue.state.type === "MONITORING"
            ? "Monitoring most recent post"
            : postQueue.state.type === "WAITING"
            ? `Waiting for post on ${DateTime.fromISO(
                postQueue.state.occupiedDate
              )
                .setZone(timeZone)
                .toFormat("EEE, d MMM")}`
            : `Projected post for ${DateTime.fromISO(
                postQueue.state.scheduledDate
              )
                .setZone(timeZone)
                .toFormat("EEE, d MMM")}`}
          <div className="absolute right-1 inset-y-0 flex items-center opacity-0 group-hover:opacity-100 group-focus:opacity-100">
            <InformationCircleIcon className="h-6 w-6" />
          </div>
        </PrimaryLightButton>
      )}
      <DragAutoScroll
        ref={postsScrollContainerRef}
        id={`postQueue${postQueue.id}AutoScroll`}
        className="px-4 flex-grow overflow-x-hidden"
        direction="vertical"
        accept={[QUEUE_POST_DRAG_TYPE]}
      >
        <QueuedPosts
          postQueue={postQueue}
          platform={platform}
          platformEntity={platformEntity}
          posts={posts}
          scrollContainerRef={postsScrollContainerRef}
          setIsLoading={setIsPostsLoading}
        />
      </DragAutoScroll>
    </animated.div>
  );
};

export default SmartSchedulesQueue;
