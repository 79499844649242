import React, { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { AxisOptions, Chart } from "react-charts";
import { ChevronDown as ChevronDownIcon } from "react-feather";

import {
  PostAnalytics,
  PostAnalyticsDay,
  RawPostMetrics,
  AnalyticsDatum as PostAnalyticsDatum,
  MetricPropertyNames,
} from "../../types/postAnalytics";

import { mergeClassNames } from "../../libs/components";
import { getUTCOffsetString } from "../../libs/date";
import { useActiveWorkspaceTimeZone } from "../../libs/hooks/app";

import Select from "../form/Select";
import MultiSelectDropdown, {
  MultiSelectDropdownOption,
} from "../form/MultiSelect";
import { MetricPropConfig, METRICS_CONFIG_BY_TYPE } from "../../libs/analytics";

type ScaleOption = "7_DAYS" | "14_DAYS" | "30_DAYS" | "60_DAYS";
type ValueOption = "PER_DAY" | "CUMULATIVE";

function getAvailableScaleOptions(byDayOffset: PostAnalytics["byDayOffset"]) {
  const totalDaysCount = Object.keys(byDayOffset).length;

  const availableScaleOptions: Array<ScaleOption> = ["7_DAYS"];

  if (totalDaysCount > 7) {
    availableScaleOptions.push("14_DAYS");
  }

  if (totalDaysCount > 14) {
    availableScaleOptions.push("30_DAYS");
  }

  if (totalDaysCount > 30) {
    availableScaleOptions.push("60_DAYS");
  }

  return availableScaleOptions;
}

function getDisplayNameForScaleOption(option: ScaleOption) {
  switch (option) {
    case "7_DAYS":
      return "7 days";
    case "14_DAYS":
      return "14 days";
    case "30_DAYS":
      return "30 days";
    case "60_DAYS":
      return "60 days";
  }
}

function getDaysCountForScaleOption(scaleOption: ScaleOption) {
  switch (scaleOption) {
    case "7_DAYS":
      return 7;
    case "14_DAYS":
      return 14;
    case "30_DAYS":
      return 30;
    case "60_DAYS":
      return 60;
  }
}

function getPostAnalyticsDaysForScaleOption(
  scaleOption: ScaleOption,
  timeZone: string,
  utcOffset: number,
  periodStart: string,
  byDayOffset: PostAnalytics["byDayOffset"]
): Array<[string, PostAnalyticsDay<RawPostMetrics> | null]> {
  const nowUTC = DateTime.utc();
  const nowCorrectTimeZone = nowUTC.setZone(timeZone);
  const todayISODate = nowCorrectTimeZone.toISODate();
  const analyticsPeriodStart = DateTime.fromISO(periodStart).setZone(
    getUTCOffsetString(utcOffset)
  );
  const dayOffsets = Object.keys(byDayOffset).map((dayOffset) =>
    parseInt(dayOffset, 10)
  );

  if (dayOffsets.length < 7) {
    let missingCount = 7 - dayOffsets.length;
    let nextDayOffset =
      dayOffsets.length > 0 ? dayOffsets[dayOffsets.length - 1] + 1 : 0;

    while (missingCount > 0) {
      dayOffsets.push(nextDayOffset);
      nextDayOffset++;
      missingCount--;
    }
  }

  const maxDays = getDaysCountForScaleOption(scaleOption);
  const dayOffsetsSubset = dayOffsets.slice(0, maxDays);

  return dayOffsetsSubset.map((dayOffset) => {
    const day = analyticsPeriodStart.plus({ days: dayOffset });
    const dayISODate = day.toISODate();
    const dayAnalytics = byDayOffset[dayOffset];

    return [
      dayISODate === todayISODate ? "Today" : day.toFormat("EEE d MMM"),
      dayAnalytics || null,
    ];
  });
}

type AnalyticsDatum = {
  x: string;
  y: number;
};
type AnalyticsSeries = {
  label: string;
  data: AnalyticsDatum[];
};
function buildData(
  scaleOption: ScaleOption,
  valueOption: ValueOption,
  timeZone: string,
  platformType: PostAnalytics["type"],
  metricPropertyNames: string[],
  utcOffset: number,
  periodStart: string,
  byDayOffset: PostAnalytics["byDayOffset"]
) {
  const dayAnalyticsForScaleOption = getPostAnalyticsDaysForScaleOption(
    scaleOption,
    timeZone,
    utcOffset,
    periodStart,
    byDayOffset
  );

  const analyticsSeriesByMetricName = metricPropertyNames.reduce<{
    [prop: string]: AnalyticsSeries;
  }>((carry, propName) => {
    const typeConfig = METRICS_CONFIG_BY_TYPE[platformType];
    const metricPropConfig = typeConfig[
      propName as keyof typeof typeConfig
    ] as unknown as MetricPropConfig;

    if (metricPropConfig) {
      carry[propName] = {
        label: metricPropConfig.title,
        data: [],
      };
    }

    return carry;
  }, {});

  dayAnalyticsForScaleOption.forEach(([dayString, dayAnalytics]) => {
    metricPropertyNames.forEach((propName) => {
      const analyticsSeries = analyticsSeriesByMetricName[propName];
      const metricsDatum =
        dayAnalytics &&
        dayAnalytics.metrics[propName as keyof typeof dayAnalytics.metrics]
          ? (dayAnalytics.metrics[
              propName as keyof typeof dayAnalytics.metrics
            ] as PostAnalyticsDatum)
          : null;
      let value = 0;

      if (metricsDatum) {
        value =
          valueOption === "CUMULATIVE"
            ? metricsDatum.cumulativeValue
            : metricsDatum.value;
      }

      if (analyticsSeries) {
        analyticsSeries.data.push({
          x: dayString,
          y: value,
        });
      }
    });
  });

  return Object.values(analyticsSeriesByMetricName);
}

interface AnalyticsOverTimeProps {
  metricPropertyNames: MetricPropertyNames;
  platformType: PostAnalytics["type"];
  utcOffset: number;
  periodStart: string;
  byDayOffset: PostAnalytics["byDayOffset"];
  className?: string;
  disableScaleSelect?: boolean;
}
const AnalyticsOverTime: React.FC<AnalyticsOverTimeProps> = ({
  metricPropertyNames,
  platformType,
  utcOffset,
  periodStart,
  byDayOffset,
  className = "",
  disableScaleSelect = false,
}) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const metricConfigByTitle = useMemo(() => {
    return (metricPropertyNames as string[]).reduce<{
      [propName: string]: MetricPropConfig;
    }>((carry, propName) => {
      const typeConfig =
        METRICS_CONFIG_BY_TYPE[
          platformType as keyof typeof METRICS_CONFIG_BY_TYPE
        ];
      const metricPropConfig = typeConfig[
        propName as keyof typeof typeConfig
      ] as unknown as MetricPropConfig;

      carry[metricPropConfig.title] = metricPropConfig;

      return carry;
    }, {});
  }, [metricPropertyNames, platformType]);
  const metricsOptions = useMemo<MultiSelectDropdownOption[]>(() => {
    return metricPropertyNames.map((propName) => {
      const typeConfig = METRICS_CONFIG_BY_TYPE[platformType];
      const metricPropConfig = typeConfig[
        propName as keyof typeof typeConfig
      ] as unknown as MetricPropConfig;

      return {
        key: propName,
        text: metricPropConfig.title,
        data: metricPropConfig,
      };
    });
  }, [metricPropertyNames, platformType]);
  const availableScaleOptions = useMemo(() => {
    return getAvailableScaleOptions(byDayOffset);
  }, [byDayOffset]);
  const [scaleOption, setScaleOption] = useState(
    availableScaleOptions[availableScaleOptions.length - 1]
  );
  const [valueOption, setValueOption] = useState<ValueOption>("CUMULATIVE");
  const [selectedMetrics, setSelectedMetrics] = useState(metricsOptions);
  const [activeSeriesIndex, setActiveSeriesIndex] = useState(-1);
  const [hasRendered, setHasRendered] = useState(false);

  const data = useMemo(() => {
    return buildData(
      scaleOption,
      valueOption,
      timeZone,
      platformType,
      selectedMetrics.map(({ key }) => key),
      utcOffset,
      periodStart,
      byDayOffset
    );
  }, [
    scaleOption,
    valueOption,
    timeZone,
    platformType,
    selectedMetrics,
    utcOffset,
    periodStart,
    byDayOffset,
  ]);

  const primaryAxis = useMemo<AxisOptions<AnalyticsDatum>>(
    () => ({
      getValue: (datum) => datum.x,
      scaleType: "band",
    }),
    []
  );

  const secondaryAxes = useMemo<AxisOptions<AnalyticsDatum>[]>(
    () => [
      {
        getValue: (datum) => datum.y,
        scaleType: "linear",
        elementType: "line",
        min: 0,
        max: 10,
      },
    ],
    []
  );

  useEffect(() => {
    setHasRendered(true);
  }, []);

  useEffect(() => {
    setSelectedMetrics(metricsOptions);
  }, [metricsOptions]);

  return (
    <div
      className={mergeClassNames(
        `transition-opacity ${hasRendered ? "opacity-100" : "opacity-0"}`,
        className
      )}
    >
      <div className="flex items-center">
        {!disableScaleSelect && (
          <Select
            toggleButtonClassName="px-2 py-1"
            labelText="Analytics graph period"
            hideLabel={true}
            value={scaleOption}
            options={availableScaleOptions.map((option) => {
              return {
                value: option,
                text: getDisplayNameForScaleOption(option),
              };
            })}
            onChange={(newOption) => setScaleOption(newOption as ScaleOption)}
          />
        )}
        <Select
          dropdownClassName={disableScaleSelect ? "" : "ml-2"}
          toggleButtonClassName="px-2 py-1"
          labelText="Analytics graph value"
          hideLabel={true}
          value={valueOption}
          options={[
            {
              value: "CUMULATIVE",
              text: "Cumulative total",
            },
            {
              value: "PER_DAY",
              text: "Daily total",
            },
          ]}
          onChange={(newValueOption) =>
            setValueOption(newValueOption as ValueOption)
          }
        />
        <MultiSelectDropdown
          dropdownClassName="ml-auto"
          toggleButtonClassName="px-2 py-1 font-normal"
          dropdownXAlign="right"
          options={metricsOptions}
          selectedOptions={selectedMetrics}
          onChange={(newSelectedMetricsOptions) => {
            if (newSelectedMetricsOptions.length > 0) {
              setSelectedMetrics(newSelectedMetricsOptions);
            }
          }}
          renderSelectedOptions={() => (
            <div className="flex items-center">
              <span>Stats to display</span>
              <ChevronDownIcon className="ml-2 w-6 h-6" />
            </div>
          )}
          renderOption={(option) => {
            return (
              <div className="flex items-center font-normal">
                <span className="h-6 w-6" style={{ color: option.data.bgHex }}>
                  {option.data.icon}
                </span>
                <span className="ml-2">{option.text}</span>
              </div>
            );
          }}
        />
      </div>
      <div className="mt-4">
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
            getSeriesStyle: (series) => {
              const colour = metricConfigByTitle[series.label].bgHex;

              return {
                color: colour,
                opacity:
                  activeSeriesIndex > -1
                    ? series.index === activeSeriesIndex
                      ? 1
                      : 0.3
                    : 1,
              };
            },
            onFocusDatum: (focused) =>
              setActiveSeriesIndex(focused ? focused.seriesIndex : -1),
          }}
        />
        <div className="h-96"></div>
      </div>
    </div>
  );
};

export default AnalyticsOverTime;
