import React, { Fragment } from "react";

import { Workspace } from "../../../types/workspaces";
import { mergeClassNames } from "../../../libs/components";
import {
  useActiveWorkspaceTimeZone,
  useCurrentUser,
} from "../../../libs/hooks/app";
import { useInvoicesForBillingCustomer } from "../../../libs/hooks/invoices";

import SecondaryButton from "../../../components/buttons/Secondary";
import LoaderIcon from "../../../components/icons/Loader";
import InvoiceTableHeader from "../../../components/tables/headers/Invoice";
import InvoiceTableRow from "../../../components/tables/rows/Invoice";

interface BillingInvoicesSectionProps {
  workspaces: Workspace[];
  className?: string;
}

const BillingInvoicesSection: React.FC<BillingInvoicesSectionProps> = ({
  workspaces,
  className = "",
}) => {
  const user = useCurrentUser();
  const timeZone = useActiveWorkspaceTimeZone();
  const billingCustomerId = user.billing ? user.billing.customerId : null;
  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    data: invoicesData,
  } = useInvoicesForBillingCustomer(
    {
      billingCustomerId: billingCustomerId || "",
      limit: 10,
    },
    { enabled: !!billingCustomerId }
  );
  const pages = invoicesData ? invoicesData.pages : [];
  const hasInvoices =
    invoicesData &&
    invoicesData.pages.length > 0 &&
    invoicesData.pages[0].invoices.length > 0;

  return (
    <div
      className={mergeClassNames(
        `relative ${isLoading ? "w-full h-16" : ""}`,
        className
      )}
    >
      {isLoading && (
        <div className="absolute inset-0 rounded-lg flex items-center justify-center text-gray-400">
          <LoaderIcon />
          <span className="ml-2">Loading invoices...</span>
        </div>
      )}
      <div
        className={`default-transition ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
      >
        {!isLoading && !hasInvoices && (
          <p className="p-10 w-full rounded-lg text-center text-gray-400 bg-gray-100">
            No invoices
          </p>
        )}
        {!isLoading && hasInvoices && (
          <table className="w-full table-auto">
            <InvoiceTableHeader includeWorkspace={true} />
            <tbody>
              {pages.map(({ invoices }, index) => {
                return (
                  <Fragment key={index}>
                    {invoices.map((invoice) => {
                      const workspace = workspaces.find(
                        (candidate) => candidate.id === invoice.workspaceId
                      );
                      return (
                        <InvoiceTableRow
                          key={invoice.id}
                          invoice={invoice}
                          timeZone={timeZone}
                          includeWorkspace={true}
                          workspace={workspace}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
        {!isLoading && hasInvoices && (
          <SecondaryButton
            className="mt-2 mx-auto w-96"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? (
              <>
                <LoaderIcon />
                <span className="ml-2">Loading more invoices...</span>
              </>
            ) : hasNextPage ? (
              "Load more invoices"
            ) : (
              "All invoices loaded"
            )}
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default BillingInvoicesSection;
