import { ComponentProps, forwardRef } from "react";
import { mergeClassNames } from "../../libs/components";
import SecondayButton from "./Secondary";

const DropdownMenuButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof SecondayButton>
>(({ className = "", ...props }, ref) => {
  return (
    <SecondayButton
      ref={ref}
      className={mergeClassNames(
        "py-2 px-2 w-full text-left font-normal justify-start",
        className
      )}
      {...props}
    />
  );
});

export default DropdownMenuButton;
