import React from "react";
import { DropzoneOptions } from "react-dropzone";

export interface FileDropStackPushProps {
  id: string;
  onDrop: NonNullable<DropzoneOptions["onDrop"]>;
  onDragEnter?: (event: React.DragEvent<HTMLElement>) => void;
  onDragLeave?: (event: React.DragEvent<HTMLElement>) => void;
  onDragOver?: (event: React.DragEvent<HTMLElement>) => void;
}

export interface FileDropStackValue {
  add: (props: FileDropStackPushProps) => void;
  remove: (id: string) => void;
}

const FileDropContext = React.createContext<FileDropStackValue>({
  add: () => {},
  remove: () => {},
});
export default FileDropContext;
