import React, { ComponentProps } from "react";
import PrimaryButton from "../../components/buttons/Primary";
import TertiaryButton from "../../components/buttons/Tertiary";

import H3 from "../../components/headings/H3";
import Modal from "../../components/Modal";

import { ReactComponent as DiscardImage } from "../../images/throw-away.svg";

interface ConfirmCloseModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  onConfirm: () => void;
}

const ConfirmCloseModal: React.FC<ConfirmCloseModalProps> = ({
  onConfirm,
  close,
  ...modalProps
}) => {
  return (
    <Modal close={close} {...modalProps}>
      <div className="px-8 pb-8 pt-6" style={{ width: 500 }}>
        <H3>Are you sure?</H3>
        <p className="mt-4">
          You seem to have some unsaved changes which will be lost if you close
          this window.
        </p>
        <DiscardImage className="mx-auto w-96 h-64 text-purple-500" />
        <div className="mt-8 flex items-center justify-between">
          <TertiaryButton
            className="mr-1 w-1/2"
            onClick={() => close && close()}
          >
            Whoops, take me back
          </TertiaryButton>
          <PrimaryButton
            className="ml-1 w-1/2"
            onClick={() => {
              onConfirm();
              close && close();
            }}
          >
            Discard the changes
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCloseModal;
