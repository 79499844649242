// src/components/SwitchButtons.tsx
import React, { useState } from "react";
import SectionTitle from "./SsectionTitle";

interface SwitchButtonsProps {
  onHashtagChange: (value: boolean) => void;
  onEmojiChange: (value: boolean) => void;
}

const SwitchButtons: React.FC<SwitchButtonsProps> = ({
  onHashtagChange,
  onEmojiChange,
}) => {
  const [generateHashtag, setGenerateHashtag] = useState(false);
  const [includeEmoji, setIncludeEmoji] = useState(false);
  const handleHashtagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGenerateHashtag(e.target.checked);
    onHashtagChange(e.target.checked);
  };

  const handleEmojiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeEmoji(e.target.checked);
    onEmojiChange(e.target.checked);
  };

  return (
    <div className="flex flex-col gap-2 items-start">
      <div className="flex flex-row items-center gap-4 justify-center cursor-pointer">
        <SectionTitle title={"Generate Hashtags"}></SectionTitle>
        <label
          htmlFor="generate_hashtag"
          className="flex items-center cursor-pointer"
        >
          <div className="relative">
            <input
              id="generate_hashtag"
              type="checkbox"
              className="hidden"
              checked={generateHashtag}
              onChange={handleHashtagChange}
            />
            <div
              className={
                "w-10 h-4 rounded-full shadow-inner " +
                (generateHashtag ? "bg-green-400" : "bg-gray-400")
              }
            ></div>
            <div
              className={
                "dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition " +
                (generateHashtag ? "translate100" : "")
              }
            ></div>
          </div>
        </label>
      </div>
      <div className="flex flex-row items-center gap-4 justify-center cursor-pointer">
        <SectionTitle title={"Include emoji"}></SectionTitle>
        <label
          htmlFor="include_emoji"
          className="flex items-center cursor-pointer"
        >
          <div className="relative">
            <input
              id="include_emoji"
              type="checkbox"
              className="hidden"
              checked={includeEmoji}
              onChange={handleEmojiChange}
            />
            <div
              className={
                "w-10 h-4 rounded-full shadow-inner " +
                (includeEmoji ? "bg-green-400" : "bg-gray-400")
              }
            ></div>
            <div
              className={
                "dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition " +
                (includeEmoji ? "translate100" : "")
              }
            ></div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default SwitchButtons;
