import React, { forwardRef } from "react";

import { ALL_MIME_TYPES } from "../../../libs/files";

interface InputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "className" | "type" | "accept"
  > {}

const MediaFileInput = forwardRef<HTMLInputElement, InputProps>(
  ({ multiple = true, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className="hidden"
        type="file"
        multiple={multiple}
        // Only allow PNG and JPEG images for now.
        accept={ALL_MIME_TYPES.join(",")}
        {...props}
      />
    );
  }
);

export default MediaFileInput;
