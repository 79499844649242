import React, { ReactNode, useMemo } from "react";
import { ContentState } from "draft-js";
import { nanoid } from "nanoid";
import { CornerUpRight as CornerUpRightIcon } from "react-feather";
import { ThumbUpIcon, ChatAltIcon } from "@heroicons/react/outline";

import { Platform } from "../../types/platforms";
import { PostLinkedIn } from "../../types/posts";
import {
  FormPublishedPostActionLinkedInReshare,
  FormPublishedPostActionLinkedInReaction,
  FormPublishedPostActionLinkedInComment,
} from "../../types/publishedPostActions";

import OutlineButton from "../../components/buttons/Outline";

import { linkedinActionSchema } from "./publishedPostActions/schemas";
import FormContainer from "./publishedPostActions/base/FormContainer";
import LinkedInComment from "./publishedPostActions/linkedin/Comment";
import LinkedInReshare from "./publishedPostActions/linkedin/Reshare";
import LinkedInReaction from "./publishedPostActions/linkedin/Reaction";

type FormLinkedInAction =
  | FormPublishedPostActionLinkedInReshare
  | FormPublishedPostActionLinkedInReaction
  | FormPublishedPostActionLinkedInComment;

export type LinkedInFormValues = {
  actions: Array<FormLinkedInAction>;
};

interface LinkedInPublishedPostActionsFormProps {
  post: PostLinkedIn;
  platforms: Platform[];
  defaultValues: LinkedInFormValues;
  onSubmit: (data: LinkedInFormValues) => void;
  isSubmitting: boolean;
  endOfFormElement?: ReactNode;
  onIsDirty?: (newIsDirty: boolean) => void;
  className?: string;
}
const LinkedInPublishedPostActionsForm: React.FC<
  LinkedInPublishedPostActionsFormProps
> = ({
  post,
  platforms,
  defaultValues,
  onSubmit,
  isSubmitting,
  endOfFormElement,
  onIsDirty,
  className = "",
}) => {
  const linkedInPlatforms = useMemo(() => {
    return platforms.filter((candidate) => candidate.type === "LINKEDIN");
  }, [platforms]);

  return (
    <FormContainer
      className={className}
      onSubmit={onSubmit}
      onIsDirty={onIsDirty}
      isSubmitting={isSubmitting}
      actionSchema={linkedinActionSchema}
      defaultValues={defaultValues}
      endOfFormElement={endOfFormElement}
      renderActionButtons={(
        createAction: (newAction: Partial<FormLinkedInAction>) => void
      ) => {
        return (
          <div className="mt-2 shrink-0 w-full flex items-stretch gap-2">
            <OutlineButton
              className="w-full flex items-center"
              onClick={() =>
                createAction({
                  id: nanoid(),
                  postId: post.id,
                  platformType: "LINKEDIN",
                  type: "COMMENT",
                  contentState: ContentState.createFromText(""),
                })
              }
              disabled={isSubmitting}
            >
              <ChatAltIcon
                className="h-6 w-6"
                style={{ transform: "scale(-1,1)" }}
              />
              <span className="ml-2">Add comment</span>
            </OutlineButton>
            <OutlineButton
              className="w-full flex items-center"
              onClick={() =>
                createAction({
                  id: nanoid(),
                  postId: post.id,
                  platformType: "LINKEDIN",
                  type: "REACTION",
                  reactionType: "LIKE",
                })
              }
              disabled={isSubmitting}
            >
              <ThumbUpIcon
                className="h-6 w-6"
                style={{ transform: "scale(-1,1)" }}
              />
              <span className="ml-2">Add reaction</span>
            </OutlineButton>
            <OutlineButton
              className="w-full flex items-center"
              onClick={() =>
                createAction({
                  id: nanoid(),
                  postId: post.id,
                  platformType: "LINKEDIN",
                  type: "RESHARE",
                  contentState: ContentState.createFromText(""),
                })
              }
              disabled={isSubmitting}
            >
              <CornerUpRightIcon className="h-6 w-6" />
              <span className="ml-2">Add reshare</span>
            </OutlineButton>
          </div>
        );
      }}
      renderAction={(
        action: FormLinkedInAction,
        onChange,
        onDelete,
        errors
      ) => {
        switch (action.type) {
          case "COMMENT":
            return (
              <LinkedInComment
                platforms={linkedInPlatforms}
                value={action}
                errors={errors}
                onChange={onChange}
                onDelete={onDelete}
              />
            );
          case "RESHARE":
            return (
              <LinkedInReshare
                platforms={linkedInPlatforms}
                value={action}
                errors={errors}
                onChange={onChange}
                onDelete={onDelete}
              />
            );
          case "REACTION":
            return (
              <LinkedInReaction
                platforms={linkedInPlatforms}
                value={action}
                errors={errors}
                onChange={onChange}
                onDelete={onDelete}
              />
            );
        }
      }}
    />
  );
};

export default LinkedInPublishedPostActionsForm;
