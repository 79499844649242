import React, { ComponentProps, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import { useDeleteUser } from "../../libs/hooks/users";
import { useCurrentUser } from "../../libs/hooks/app";
import { useWorkspaces } from "../../libs/hooks/workspaces";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import H6 from "../../components/headings/H6";
import DangerButton from "../../components/buttons/Danger";
import Input from "../../components/form/Input";
import LoaderIcon from "../../components/icons/Loader";

type FormValues = {
  confirmation: string;
};

const formSchema = Joi.object({
  confirmation: Joi.any().valid("delete").required(),
});

interface DeleteUserAccountModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  close: () => void;
}

const DeleteUserAccountModal: React.FC<DeleteUserAccountModalProps> = ({
  isVisible,
  close,
  ...modalProps
}) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { isLoading: isLoadingWorkspaces, data: workspacesData } =
    useWorkspaces(user.id);
  const {
    isLoading: isDeleting,
    loadingText: deleteLoadingText,
    errorAlert: deleteErrorAlert,
    mutateAsync: deleteUser,
  } = useDeleteUser();
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: joiResolver(formSchema),
    defaultValues: {
      confirmation: "",
    },
  });
  const hasErrors = Object.keys(errors).length > 0;
  const workspaces = workspacesData
    ? workspacesData.workspaces.filter(
        (workspace) => workspace.createdBy === user.id
      )
    : [];

  const onSubmit = async () => {
    await deleteUser(user);
    close();
    navigate("/logout");
  };

  useEffect(() => {
    if (isVisible) {
      setFocus("confirmation");
    }
  }, [isVisible, setFocus]);

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="p-8" style={{ width: 500 }}>
        <H3>Are you sure?</H3>
        <p className="mt-4">
          Deleting your account is a <strong>permanent</strong> action and can
          not be undone.
        </p>
        <p className="mt-2">
          If you proceed all of your data will be deleted including any
          workspaces you own and any posts you may have scheduled for a future
          date.
        </p>
        <p className="mt-2">
          The following workspaces, and all content within them, will be deleted
          as part of this action.
        </p>
        <H6 className="mt-8">Workspaces to be deleted</H6>
        {isLoadingWorkspaces ? (
          <div className="flex items-center justify-center">
            <LoaderIcon height={45} width={45} />
          </div>
        ) : (
          <ul>
            {workspaces.map((workspace) => {
              return (
                <li key={workspace.id} className="ml-8 py-2 list-disc">
                  {workspace.name}
                </li>
              );
            })}
          </ul>
        )}

        <p className="mt-8">
          Please enter the word <strong>delete</strong> into the input below to
          confirm that you wish to delete your account.
        </p>
        <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register("confirmation")}
            labelText="Confirmation"
            labelClassName="mt-4"
            placeholder={'Please type "delete" to confirm'}
            error={!!errors.confirmation}
          />
          {deleteErrorAlert({ className: "mt-6" })}
          <DangerButton
            className="mt-6 w-full text-xl text-white flex items-center"
            type="submit"
            disabled={isDeleting || hasErrors || !isDirty}
          >
            {deleteLoadingText({
              default: "Permanently delete my account",
              loading: "Deleting your account...",
            })}
          </DangerButton>
        </form>
      </div>
    </Modal>
  );
};

export default DeleteUserAccountModal;
