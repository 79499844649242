import React from "react";
import { Share2 as Share2Icon } from "react-feather";

import { mergeClassNames } from "../../libs/components";

interface PublishedPostActionIconProps {
  className?: string;
}
const PublishedPostActionIcon: React.FC<PublishedPostActionIconProps> = ({
  className = "",
}) => {
  return (
    <Share2Icon className={mergeClassNames("h-6 w-6 rotate-90", className)} />
  );
};

export default PublishedPostActionIcon;
