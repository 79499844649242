import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Subscription } from "../../types/subscriptions";
import { ApiResponse } from "../../types/general";

import { useApiClient } from "./app";
import { useDecoratedReactQuery } from "./general";
import { SUBSCRIPTIONS_QUERY_KEYS, WORKSPACES_QUERY_KEYS } from "../queryKeys";
import { createQueryCacheObject, updateQueryCacheObject } from "../queryCache";
import { Workspace } from "../../types/workspaces";

export const useSubscription = (
  subscriptionId: string,
  options = { enabled: true }
) => {
  const apiClient = useApiClient();

  return useQuery(
    SUBSCRIPTIONS_QUERY_KEYS.detail(subscriptionId),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<Subscription>>(
        `/subscriptions/${subscriptionId}`
      );

      return response.data;
    },
    options
  );
};

interface CreateSubscriptionProps {
  workspaceId: string;
  remotePriceId: string;
  remotePaymentMethodId?: string;
  trial?: boolean;
}
export const useCreateSubscription = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useDecoratedReactQuery(
    useMutation(
      async (props: CreateSubscriptionProps) => {
        const { data: response } = await apiClient.post<
          ApiResponse<Subscription>
        >("/subscriptions", props);

        return response.data;
      },
      {
        onSuccess: (subscription, { trial }) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: SUBSCRIPTIONS_QUERY_KEYS.detail(subscription.id),
            },
            subscription.id,
            subscription
          );

          updateQueryCacheObject<Workspace>(
            queryClient,
            {
              detail: {
                dataProp: "workspace",
                queryKey: WORKSPACES_QUERY_KEYS.detail(
                  subscription.workspaceId
                ),
              },
              lists: [
                {
                  dataProp: "workspaces",
                  queryKey: WORKSPACES_QUERY_KEYS.lists(),
                },
              ],
            },
            subscription.workspaceId,
            (oldWorkspace) => {
              return {
                ...oldWorkspace,
                subscription: {
                  id: subscription.id,
                  provider: subscription.provider,
                  status: subscription.status,
                  remoteProductId: subscription.remoteProductId,
                  price: subscription.price,
                },
                hasHadTrial: !!oldWorkspace.hasHadTrial || !!trial,
              };
            }
          );
        },
      }
    ),
    () => ({ title: "Failed to create subscription" })
  );
};

interface CancelSubscriptionProps {
  subscription: Subscription;
}
export const useCancelSubscription = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useDecoratedReactQuery(
    useMutation(
      async ({ subscription }: CancelSubscriptionProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<Subscription>
        >(`/subscriptions/${subscription.id}`, {
          cancelAtPeriodEnd: true,
        });

        return response.data;
      },
      {
        onSuccess: (subscription) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: SUBSCRIPTIONS_QUERY_KEYS.detail(subscription.id),
            },
            subscription.id,
            () => subscription
          );
        },
      }
    ),
    () => ({ title: "Failed to cancel subscription" })
  );
};

interface UpdateSubscriptionProps {
  subscription: Subscription;
  updateProps: {
    cancelAtPeriodEnd?: boolean;
    paymentMethodId?: string;
  };
}
export const useUpdateSubscription = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useDecoratedReactQuery(
    useMutation(
      async ({ subscription, updateProps }: UpdateSubscriptionProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<Subscription>
        >(`/subscriptions/${subscription.id}`, updateProps);

        return response.data;
      },
      {
        onSuccess: (subscription) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: SUBSCRIPTIONS_QUERY_KEYS.detail(subscription.id),
            },
            subscription.id,
            () => subscription
          );
        },
      }
    ),
    () => ({ title: "Failed to update subscription" })
  );
};
