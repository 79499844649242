import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import UnauthenticatedAppContext from "../contexts/app/Unauthenticated";

import { UserReferralAffiliate } from "../types/users";

import Login from "../pages/Login";
import Signup from "../pages/Signup";
import SignupConfirm from "../pages/signup/Confirm";
import ForgotPassword from "../pages/ForgotPassword";
import { analyticsLogEvent } from "../libs/amplitudeAnalytics";

const UnauthenticatedApp: React.FC = () => {
  const location = useLocation();
  const [email, internalSetEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [referralId, setReferralId] = useState<string | null>(null);
  const [referralAffiliate, setReferralAffiliate] =
    useState<UserReferralAffiliate | null>(null);

  const setEmail = useCallback((newEmail: string | null) => {
    internalSetEmail(newEmail ? newEmail.toLowerCase() : newEmail);
  }, []);

  useEffect(() => {
    const url = location.pathname;
    const urlParams = location.search;

    analyticsLogEvent({
      event: "unauthenticatedUrlChange",
      url,
      urlParams,
    });
  }, [location.pathname, location.search]);

  return (
    <UnauthenticatedAppContext.Provider
      value={{
        email,
        password,
        referralId,
        referralAffiliate,
        setEmail,
        setPassword,
        setReferralId,
        setReferralAffiliate,
      }}
    >
      <Routes location={location}>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/signup/confirm"
          element={
            email && password ? (
              <SignupConfirm />
            ) : (
              <Navigate to="/login" state={{ from: location }} replace />
            )
          }
        />

        <Route
          path="/*"
          element={<Navigate to="/login" state={{ from: location }} replace />}
        />
      </Routes>
    </UnauthenticatedAppContext.Provider>
  );
};

export default UnauthenticatedApp;
