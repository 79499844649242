import axios, { AxiosInstance } from "axios";
import { createContext } from "react";
import { User } from "../../types/users";

export interface AuthenticatedAppContextState {
  currentUser: User;
  isEmailVerified: boolean;
  apiClient: AxiosInstance;
  logout: () => void;
}

const AuthenticatedAppContext = createContext<AuthenticatedAppContextState>({
  apiClient: axios.create(),
  currentUser: {
    id: "1",
    email: "default@example.com",
    firstName: "Default",
    timeZone: "Australia/Perth",
    workspaces: {},
    created: "",
    createdBy: "system",
    modified: "",
    modifiedBy: "system",
  },
  isEmailVerified: true,
  logout: () => {},
});

export default AuthenticatedAppContext;
