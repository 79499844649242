import React from "react";
import Joi from "joi";
import { Star as StarIcon, Check as CheckIcon } from "react-feather";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useElements, CardElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";

import { Workspace } from "../../../types/workspaces";
import config from "../../../config";
import {
  useActiveWorkspaceDateTime,
  useCurrentUser,
} from "../../../libs/hooks/app";
import { useAsyncState, useSnackBarFactory } from "../../../libs/hooks/general";
import {
  useCreatePaymentMethod,
  usePaymentMethods,
} from "../../../libs/hooks/paymentMethods";
import { useCreateSubscription } from "../../../libs/hooks/subscriptions";

import H3 from "../../../components/headings/H3";
import PrimaryButton from "../../../components/buttons/Primary";
import PaymentMethodSelector, {
  paymentMethodSelectorValueSchema,
  PaymentMethodSelectorValue,
} from "../../../components/form/PaymentMethodSelector";
import ChangesSavedSnackBarContent from "../../../components/snackBarContent/ChangesSaved";

import { ReactComponent as HappyAnnouncementImage } from "../../../images/happy-announcement.svg";

interface FormValues {
  paymentMethod: PaymentMethodSelectorValue;
}

const formSchema = Joi.object({
  paymentMethod: paymentMethodSelectorValueSchema.required(),
});

interface ChangeSubscriptionModalFreeTrialProps {
  contentId: "freeTrial";
  redirectOnSuccess?: boolean;
  workspace: Workspace;
  close: () => void;
}

const ChangeSubscriptionModalFreeTrial: React.FC<
  ChangeSubscriptionModalFreeTrialProps
> = ({ close, workspace, redirectOnSuccess = false }) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const elements = useElements();
  const now = useActiveWorkspaceDateTime();
  const { data, isLoading: isLoadingPaymentMethods } = usePaymentMethods(
    user.id
  );
  const paymentMethods = data || [];
  const {
    mutateAsync: createPaymentMethod,
    errorAlert: createPaymentMethodErrorAlert,
    clearErrorAlert: clearCreatePaymentMethodErrorAlert,
  } = useCreatePaymentMethod();
  const {
    mutateAsync: createSubscription,
    errorAlert: createSubscriptionErrorAlert,
    clearErrorAlert: clearCreateSubscriptionErrorAlert,
  } = useCreateSubscription();
  const createSnackBar = useSnackBarFactory();
  const {
    loading: isSubmitting,
    setLoading: setIsSubmitting,
    loadingText: submitLoadingText,
  } = useAsyncState();
  const {
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema, { convert: false }),
    defaultValues: {
      paymentMethod: {
        type: "NEW",
        value: {
          empty: true,
          complete: false,
        },
      },
    },
  });
  const watchPaymentMethod = watch("paymentMethod");
  const hasPaymentMethod = watchPaymentMethod
    ? watchPaymentMethod.type === "NEW"
      ? !watchPaymentMethod.value.empty
      : watchPaymentMethod.value !== null
    : false;
  const trialEndDateTime = now.plus({
    days: config.DEFAULT_TRIAL_PERIOD_DAYS,
  });
  const trialEndDateString = trialEndDateTime.toFormat("EEE, d MMM");
  const displayPrice = Math.ceil(
    config.prices.PUBLISH_MONTHLY.unitAmount / 100
  );

  const onSubmit: SubmitHandler<FormValues> = async ({
    paymentMethod: paymentMethodData,
  }) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    clearCreatePaymentMethodErrorAlert();
    clearCreateSubscriptionErrorAlert();

    try {
      let remotePaymentMethodId: string | null = null;

      if (paymentMethodData.type === "NEW" && !paymentMethodData.value.empty) {
        // The user has entered a new card so create the payment method.
        const cardElement = elements ? elements.getElement(CardElement) : null;

        if (!cardElement) {
          return;
        }

        const newPaymentMethod = await createPaymentMethod({ cardElement });
        remotePaymentMethodId = newPaymentMethod.remoteId;
      } else if (paymentMethodData.type === "EXISTING") {
        // The user has selected one of their existing payment methods.
        const paymentMethodId = paymentMethodData.value;
        const paymentMethod = paymentMethods.find(
          (candidate) => candidate.id === paymentMethodId
        );

        if (!paymentMethod) {
          return;
        }

        remotePaymentMethodId = paymentMethod.remoteId;
      }

      const createSubscriptionProps: Parameters<typeof createSubscription>[0] =
        {
          workspaceId: workspace.id,
          remotePriceId: config.prices.PUBLISH_MONTHLY.id,
          trial: true,
        };

      if (remotePaymentMethodId) {
        createSubscriptionProps.remotePaymentMethodId = remotePaymentMethodId;
      }

      const subscription = await createSubscription(createSubscriptionProps);
      createSnackBar({
        content: <ChangesSavedSnackBarContent message="Free trial created" />,
      });

      close();

      if (redirectOnSuccess) {
        navigate(`/subscriptions/${subscription.id}`);
      }
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-8 flex flex-col" style={{ width: 600 }}>
      <H3 className="flex-shrink-0 flex items-center">
        {workspace.name} free trial
      </H3>
      <p className="mt-4">
        Enjoy more access to all of Seenly's great features for{" "}
        <strong>free</strong> for{" "}
        <strong>{config.DEFAULT_TRIAL_PERIOD_DAYS} days!</strong>
      </p>
      <div className="mt-4 flex items-center justify-around">
        <ul className="text-lg">
          {config.prices.PUBLISH_MONTHLY.features.map((feature, index) => {
            return (
              <li key={index} className="flex items-center">
                <CheckIcon className="mr-2 text-purple-500" /> {feature}
              </li>
            );
          })}
        </ul>
        <HappyAnnouncementImage
          className="ml-4 flex-shrink-0 text-purple-500"
          height={150}
          width={150}
        />
      </div>
      <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field: { onChange, onBlur, value } }) => (
            <PaymentMethodSelector
              value={value}
              paymentMethods={paymentMethods}
              loading={isLoadingPaymentMethods}
              labelText="Payment method (optional)"
              error={!!errors.paymentMethod}
              onChange={onChange}
              onBlur={onBlur}
              onError={(message) => {
                if (message) {
                  setError("paymentMethod", { message });
                } else {
                  clearErrors("paymentMethod");
                }
              }}
            />
          )}
        />
        {!hasPaymentMethod && (
          <p className="mt-10">
            You can add a payment method anytime before the end of your trial (
            <strong>{trialEndDateString}</strong>) to automatically continue on
            the <strong>{config.prices.PUBLISH_MONTHLY.displayName}</strong>{" "}
            subscription ({config.prices.PUBLISH_MONTHLY.currency} $
            {displayPrice} monthly).
          </p>
        )}
        {hasPaymentMethod && (
          <p className="mt-10">
            Your free trial period will end on{" "}
            <strong>{trialEndDateString}</strong> after which you will
            automatically continue on the{" "}
            <strong>{config.prices.PUBLISH_MONTHLY.displayName}</strong>{" "}
            subscription ({config.prices.PUBLISH_MONTHLY.currency} $
            {displayPrice} monthly).
          </p>
        )}
        {createPaymentMethodErrorAlert({ className: "mt-10" })}
        {createSubscriptionErrorAlert({ className: "mt-10" })}
        <PrimaryButton
          type="submit"
          className="mt-10 w-full"
          disabled={isSubmitting}
        >
          {submitLoadingText({
            loading: "Creating trial subscription",
            default: (
              <>
                <StarIcon className="mr-2" />
                Start your {config.DEFAULT_TRIAL_PERIOD_DAYS}-day free trial
              </>
            ),
          })}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default ChangeSubscriptionModalFreeTrial;
