import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

import BrandLogoLink from "../links/BrandLogo";
import UnauthenticatedFooter from "../../containers/footers/UnauthenticatedFooter";

interface SignupContainerProps {
  children: ReactNode;
}
const SignupContainer: React.FC<SignupContainerProps> = ({ children }) => {
  return (
    <div className="flex items-stretch relative w-full">
      <Helmet>
        <title>Seenly - sign up</title>
      </Helmet>
      <div className="absolute top-0 left-0 pl-4 pt-4">
        <BrandLogoLink />
      </div>
      <div className="flex-shrink-0 flex flex-col w-full sm:w-3/4 min-h-screen">
        <div className="flex-shrink-0 flex-grow p-5 mt-24 flex justify-center">
          {children}
        </div>
        <UnauthenticatedFooter className="mt-10 z-10" />
      </div>
      <div className="hidden sm:block flex-1 bg-blue-900"></div>
    </div>
  );
};

export default SignupContainer;
