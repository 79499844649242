import React, { ComponentProps, forwardRef, useMemo, useState } from "react";
import {
  Search as SearchIcon,
  X as XIcon,
  Circle as CircleIcon,
  CheckCircle as CheckCircleIcon,
} from "react-feather";
import { escapeRegExp } from "../../libs/utils";

import Button from "../buttons/Button";
import OutlineButton from "../buttons/Outline";
import SecondaryButton from "../buttons/Secondary";
import IconButton from "../buttons/Icon";
import DropdownMenu from "../menus/Dropdown";
import { mergeClassNames } from "../../libs/components";

export interface MultiSelectDropdownOption {
  key: string;
  text: string;
  data: any;
}

interface MultiSelectDropdownProps {
  options: MultiSelectDropdownOption[];
  selectedOptions: MultiSelectDropdownOption[];
  renderSelectedOptions: (
    selectedOptions: MultiSelectDropdownOption[]
  ) => React.ReactNode;
  renderOption: (option: MultiSelectDropdownOption) => React.ReactNode;
  onChange: (newOptions: MultiSelectDropdownOption[]) => void;
  ButtonElement?: typeof Button;
  dropdownClassName?: string;
  dropdownXAlign?: ComponentProps<typeof DropdownMenu>["xAlign"];
  toggleButtonClassName?: string;
  includeSearch?: boolean;
}

const MultiSelectDropdown = forwardRef<
  HTMLButtonElement,
  MultiSelectDropdownProps
>(
  (
    {
      options,
      selectedOptions,
      renderSelectedOptions,
      renderOption,
      onChange,
      ButtonElement = OutlineButton,
      dropdownClassName = "",
      dropdownXAlign = "left",
      toggleButtonClassName = "",
      includeSearch = false,
    },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState("");
    const filteredOptions = useMemo(() => {
      if (includeSearch && searchTerm !== "") {
        const escapedSearchTerm = escapeRegExp(searchTerm);
        const searchRegex = new RegExp(escapedSearchTerm, "ig");
        return options.filter((option) => searchRegex.test(option.text));
      } else {
        return options;
      }
    }, [includeSearch, options, searchTerm]);
    const optionsToIndex = useMemo(() => {
      return options.reduce<{ [optionKey: string]: number }>(
        (carry, option, index) => {
          carry[option.key] = index;
          return carry;
        },
        {}
      );
    }, [options]);

    return (
      <DropdownMenu
        className={dropdownClassName}
        closeOnSelect={false}
        xAlign={dropdownXAlign}
        onMenuToggle={(isOpen) => {
          if (!isOpen && includeSearch) {
            setSearchTerm("");
          }
        }}
      >
        <ButtonElement
          ref={ref}
          className={mergeClassNames("w-full", toggleButtonClassName)}
        >
          {renderSelectedOptions(selectedOptions)}
        </ButtonElement>
        <div>
          {includeSearch && (
            <div className="px-2 w-full flex items-center border-b border-gray-200 focus-within:text-purple-500 focus-within:border-purple-500">
              <div className="px-2 flex-shrink-0">
                <SearchIcon />
              </div>
              <input
                className="flex-grow"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton
                className={`flex-shrink-0 ${
                  searchTerm === "" ? "opacity-0" : "opacity-100"
                }`}
                tabIndex={searchTerm === "" ? -1 : 0}
                onClick={() => setSearchTerm("")}
                title="Clear search"
              >
                <XIcon />
              </IconButton>
            </div>
          )}
          <div
            className="p-2 overflow-x-hidden overflow-y-auto"
            style={{ maxHeight: 800 }}
          >
            {filteredOptions.map((option) => {
              const selectedIndex = selectedOptions.findIndex(
                (candidate) => candidate.key === option.key
              );
              const isSelected = selectedIndex >= 0;
              return (
                <SecondaryButton
                  key={option.key}
                  className="py-2 w-full text-left flex items-center justify-start"
                  onClick={() => {
                    let newOptions = selectedOptions.slice();
                    if (isSelected) {
                      newOptions.splice(selectedIndex, 1);
                    } else {
                      newOptions.push(option);
                      // Keep the selected options in the same order as the
                      // options list.
                      newOptions.sort((a, b) => {
                        const aVal = optionsToIndex[a.key];
                        const bVal = optionsToIndex[b.key];

                        if (aVal < bVal) {
                          return -1;
                        } else if (aVal > bVal) {
                          return 1;
                        } else {
                          return 0;
                        }
                      });
                    }

                    onChange(newOptions);
                  }}
                >
                  {isSelected ? (
                    <CheckCircleIcon className="flex-shrink-0 h-5 w-5 text-purple-500" />
                  ) : (
                    <CircleIcon className="flex-shrink-0 h-5 w-5" />
                  )}
                  <div className="ml-4 flex-grow">{renderOption(option)}</div>
                </SecondaryButton>
              );
            })}
          </div>
        </div>
      </DropdownMenu>
    );
  }
);

export default MultiSelectDropdown;
