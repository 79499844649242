import React from "react";
import { DraftDecorator } from "draft-js";

import { Platform } from "../../../../../types/platforms";
import { MentionEntityData } from "../../../../../types/posts";

import {
  Build,
  BuildDecorators,
  DraftDecoratorComponentProps,
  ENTITY_TYPES,
} from "../../../../../libs/postTextArea";

import Tooltip from "../../../../../components/Tooltip";

const ENTITY_TYPE = ENTITY_TYPES.MENTION;

const searchStrategy: DraftDecorator["strategy"] = (
  contentBlock,
  callback,
  contentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    const entity = entityKey ? contentState.getEntity(entityKey) : null;
    return !!entity && entity.getType() === ENTITY_TYPE;
  }, callback);
};

interface BuildSearchComponentProps {
  singularPlatformType: Platform["type"] | null;
  onClick: (
    blockKey: string,
    entityKey: string,
    start: number,
    end: number
  ) => void;
}
const buildSearchComponent =
  ({
    singularPlatformType,
    onClick,
  }: BuildSearchComponentProps): React.FC<DraftDecoratorComponentProps> =>
  ({
    children,
    contentState,
    entityKey,
    blockKey,
    start,
    end,
    decoratedText,
  }) => {
    let tooltipText = `Click to manage mention for ${decoratedText}`;
    let className =
      "rounded bg-purple-100 text-purple-500 hover:bg-purple-200 hover:text-purple-700";

    if (singularPlatformType) {
      const entity = contentState.getEntity(entityKey!);
      const entityData = entity.getData() as MentionEntityData;

      if (!entityData.mentions[singularPlatformType]) {
        // We don't have a mention selected for this yet so style it like the mention
        // candidate to prompt the user to add one.
        tooltipText = `Click to add mention for ${decoratedText}`;
        className =
          "rounded underline bg-gray-100 hover:bg-purple-100 hover:text-purple-500";
      }
    }

    return (
      <button
        className={className}
        type="button"
        onClick={() => {
          if (entityKey) {
            onClick(blockKey, entityKey, start, end);
          }
        }}
      >
        <Tooltip content={tooltipText}>{children}</Tooltip>
      </button>
    );
  };

const buildDecorators: BuildDecorators<BuildSearchComponentProps> = (props) => {
  return [
    {
      strategy: searchStrategy,
      component: buildSearchComponent(props),
    },
  ];
};

const build: Build<BuildSearchComponentProps> = (props) => {
  return {
    type: ENTITY_TYPE,
    decorators: buildDecorators(props),
  };
};

export default build;
