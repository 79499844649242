import React, { forwardRef } from "react";

import { FILE_TYPE_TO_MIME_TYPE } from "../../../libs/files";

interface InputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "className" | "type" | "accept"
  > {}

const VideoFileInput = forwardRef<HTMLInputElement, InputProps>(
  ({ multiple = true, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className="hidden"
        type="file"
        multiple={multiple}
        accept={FILE_TYPE_TO_MIME_TYPE.VIDEO.join(",")}
        {...props}
      />
    );
  }
);

export default VideoFileInput;
