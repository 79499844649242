import React, { forwardRef, useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import { mergeClassNames, mergeRefs } from "../../libs/components";
import { useDebouncedFunction, useHasChanged } from "../../libs/hooks/general";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  labelText: string;
  labelClassName?: string;
  error?: boolean;
  hideLabel?: boolean;
  className?: string;
  onFinalChange?: (newValue: string) => void;
  debounceOnChange?: (newValue: string) => void;
  debounceTimeout?: number;
  focusAfterRender?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      labelText,
      labelClassName = "",
      className = "",
      id = nanoid(),
      error = false,
      hideLabel = false,
      onFinalChange = undefined,
      debounceOnChange = undefined,
      debounceTimeout = 1000,
      focusAfterRender = false,
      value,
      onChange,
      onBlur,
      onKeyDown,
      ...inputProps
    },
    ref
  ) => {
    const normalisedValue = value === undefined ? "" : "" + value;
    const internalRef = useRef<HTMLInputElement>(null);
    const valueHasChanged = useHasChanged(normalisedValue);
    const [internalValue, setInternalValue] = useState<string>(normalisedValue);
    const useInternalValue =
      onFinalChange !== undefined || debounceOnChange !== undefined;

    const handleDebounceOnChange = useDebouncedFunction((newValue: string) => {
      if (debounceOnChange) {
        debounceOnChange(newValue);
      }
    }, debounceTimeout);

    const internalOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }

      setInternalValue(e.target.value);
      handleDebounceOnChange(e.target.value);
    };

    const internalOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      if (onBlur) {
        onBlur(e);
      }

      if (onFinalChange && internalValue !== value) {
        onFinalChange(internalValue);
      }
    };

    const internalOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
      e
    ) => {
      if (onKeyDown) {
        onKeyDown(e);
      }

      if (!(e.altKey || e.ctrlKey || e.metaKey || e.shiftKey)) {
        if (e.key === "Escape") {
          setInternalValue(normalisedValue);
        } else if (e.key === "Enter") {
          if (onFinalChange && internalValue !== value) {
            onFinalChange(internalValue);
          }
        }
      }
    };

    useEffect(() => {
      if (focusAfterRender && internalRef.current) {
        internalRef.current.focus();
      }
    }, [internalRef, focusAfterRender]);

    useEffect(() => {
      if (valueHasChanged && value !== internalValue) {
        setInternalValue(normalisedValue);
      }
    }, [valueHasChanged, normalisedValue, value, internalValue]);

    return (
      <>
        <label
          className={mergeClassNames(
            `font-bold block mb-2 default-transition ${
              error ? "text-red-500" : ""
            } ${hideLabel ? "sr-only" : ""}`,
            labelClassName
          )}
          htmlFor={id}
        >
          {labelText}
        </label>
        <input
          ref={mergeRefs([ref, internalRef])}
          id={id}
          className={mergeClassNames(
            `py-3 px-4 w-full rounded-lg bg-transparent default-transition border-2 focus:border-purple-500 ${
              error ? "border-red-500" : "border-gray-300"
            }`,
            className
          )}
          value={useInternalValue ? internalValue : value}
          onChange={useInternalValue ? internalOnChange : onChange}
          onBlur={onFinalChange ? internalOnBlur : onBlur}
          onKeyDown={useInternalValue ? internalOnKeyDown : onKeyDown}
          {...inputProps}
        />
      </>
    );
  }
);

export default Input;
