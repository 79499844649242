import React, {
  useRef,
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  ReactNode,
  AnimationEventHandler,
} from "react";
import { useLocation } from "react-router-dom";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { debounce } from "../utils";

import GloablInteractionContext from "../../contexts/GlobalInteraction";
import OpenCloseStackContext from "../../contexts/OpenCloseStack";
import ContentSwitchContext, {
  ContentSwitchContent,
  ContentSwitchContextProps,
} from "../../contexts/ContentSwitch";
import ContentStackContext from "../../contexts/ContentStack";
import DragDropContext from "../../contexts/DragDrop";
import FileDropStackContext, {
  FileDropStackPushProps,
} from "../../contexts/FileDropStack";
import SnackBoxContext from "../../contexts/SnackBox";

import ExpandCollapse from "../../components/animations/ExpandCollapse";
import ErrorAlert from "../../components/alerts/Error";
import LoaderIcon from "../../components/icons/Loader";

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const useIsProduction = () => {
  return process.env.REACT_APP_STAGE === "prod";
};

export const usePrevious = <T extends any>(value: T): T | null => {
  const ref = useRef<T | null>(null);
  const previousValue = ref.current;

  if (value !== undefined && value !== previousValue) {
    ref.current = value;
  }

  return previousValue;
};

export const useHasChanged = <T extends any>(value: T): boolean => {
  const previousValue = usePrevious(value);
  return value !== previousValue;
};

export const useDebouncedFunction = <T extends (args: any) => any>(
  func: T,
  timeout = 500
): T => {
  const funcRef = useRef({ func });

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      funcRef.current.func = (() => {}) as any;
    };
  }, []);

  // Update the function reference each time this is called.
  funcRef.current.func = func;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      ((...args) => {
        return funcRef.current.func(...args);
      }) as T,
      timeout
    ),
    [timeout]
  );
};

export interface GlobalInteractionCallbackProps {
  type: "CLICK" | "KEY";
  external: boolean;
  event: React.KeyboardEvent | React.MouseEvent;
}

interface UseGlobalInteractionProps {
  callback: (props: GlobalInteractionCallbackProps) => void;
  clicks?: boolean;
  keys?: boolean | string[];
}
export const useGlobalInteraction = ({
  clicks = false,
  keys = false,
  callback,
}: UseGlobalInteractionProps) => {
  const listenClick = clicks !== false;
  const listenKeyUp =
    keys === true || (typeof keys === "object" && keys.length > 0);
  const shouldListen = listenClick || listenKeyUp;
  const eventRef = useRef<React.KeyboardEvent | React.MouseEvent | null>(null);
  const registry = useContext(GloablInteractionContext);
  const registryCallback = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      if (e.type === "click" && !listenClick) {
        return;
      } else if (e.type === "keyup") {
        if (keys === false) {
          return;
        } else if (typeof keys === "object" && keys.length > 0) {
          const key = (e as React.KeyboardEvent).key;

          if (!keys.includes(key)) {
            return;
          }
        }
      }

      // If we haven't seen this event bubble up then it must have been triggered
      // outside of the listening element.
      const external = e !== eventRef.current;

      callback({
        type: e.type === "click" ? "CLICK" : "KEY",
        external,
        event: e,
      });

      eventRef.current = null;
    },
    [callback, keys, listenClick]
  );

  const handleClick = useCallback((e: React.MouseEvent) => {
    eventRef.current = e;
  }, []);

  const handleKeyUp = useCallback((e: React.KeyboardEvent) => {
    eventRef.current = e;
  }, []);

  useEffect(() => {
    if (shouldListen) {
      const id = nanoid();
      registry[id] = registryCallback;

      return () => {
        if (registry[id]) {
          delete registry[id];
        }
      };
    }
  }, [shouldListen, registry, registryCallback]);

  return useMemo(() => {
    const handlers: {
      onClick?: (e: React.MouseEvent) => void;
      onKeyUp?: (e: React.KeyboardEvent) => void;
    } = {};

    if (listenClick) {
      handlers.onClick = handleClick;
    }

    if (listenKeyUp) {
      handlers.onKeyUp = handleKeyUp;
    }

    return handlers;
  }, [handleClick, handleKeyUp, listenClick, listenKeyUp]);
};

export const useOpenCloseStack = (
  listen: boolean,
  callback: () => void | false,
  config: { clicks?: boolean; keys?: boolean } = { clicks: true, keys: true }
) => {
  const id = useMemo(() => {
    return nanoid();
  }, []);

  const stack = useContext(OpenCloseStackContext);

  const handleInteraction = useCallback(
    (props: GlobalInteractionCallbackProps) => {
      if (props.type === "CLICK" && !props.external) {
        return;
      }

      if (props.event.isDefaultPrevented()) {
        return;
      }

      const stackSize = stack.length;
      const hasItems = stackSize > 0;
      if (hasItems && stack[stackSize - 1][0] === id) {
        props.event.preventDefault();
        const response: any = stack[stackSize - 1][1]();

        // If the callback explicitly returns false then don't
        // remove it from the stack.
        if (response !== false) {
          stack.pop();
        }
      }
    },
    [id, stack]
  );

  const handlers = useGlobalInteraction({
    clicks: listen && !!config.clicks ? true : false,
    keys: listen && !!config.keys ? ["Escape"] : false,
    callback: handleInteraction,
  });

  useEffect(() => {
    return () => {
      const index = stack.findIndex(([candidateId]) => candidateId === id);
      if (index >= 0) {
        stack.splice(index, 1);
      }
    };
  }, [id, stack]);

  return useMemo(() => {
    const index = stack.findIndex(([candidateId]) => candidateId === id);

    if (listen) {
      if (index >= 0) {
        if (stack[index][1] !== callback) {
          // It's already in the stack so the callback must have changed. Update it.
          stack[index][1] = callback;
        }
      } else {
        // It's not in the stack so add it to the top.
        stack.push([id, callback]);
      }
    } else {
      if (index >= 0) {
        // We're no longer listening to remove it from the stack.
        stack.splice(index, 1);
      }
    }

    return handlers;
  }, [callback, handlers, id, listen, stack]);
};

export const useIsOpenCloseStackEmpty = () => {
  const stack = useContext(OpenCloseStackContext);
  return () => stack.length < 1;
};

export const useContentSwitch = <
  T extends ContentSwitchContent,
  ContentId extends T["id"] = T["id"]
>(
  id: ContentId
) => {
  const context = useContext<ContentSwitchContextProps<T>>(
    ContentSwitchContext as any
  );

  return {
    showContent: context.showContent,
    content:
      context.content.id === id
        ? (context.content as Extract<T, { id: ContentId }>)
        : (context.prevContent as Extract<T, { id: ContentId }>),
  };
};

export const useContentStack = () => {
  return useContext(ContentStackContext);
};

interface ErrorAlertProps {
  className?: string;
  dismissable?: boolean;
}
export const useErrorAlert = () => {
  const [errorData, set] = useState<{
    title: string;
    message: React.ReactNode;
  } | null>(null);

  const clear = useCallback(() => set(null), []);

  const has = useCallback(() => errorData !== null, [errorData]);

  const error = useCallback(
    (props?: ErrorAlertProps) => {
      const { className = "", dismissable = true } = props || {};
      return (
        <ExpandCollapse>
          {errorData ? (
            <ErrorAlert
              className={className}
              title={errorData.title}
              message={errorData.message}
              dismissable={dismissable}
              onDismiss={clear}
            />
          ) : null}
        </ExpandCollapse>
      );
    },
    [clear, errorData]
  );

  return {
    error,
    set,
    clear,
    has,
  };
};

interface UseAsyncStateProps {
  defaultLoading?: boolean;
}
export const useAsyncState = (props?: UseAsyncStateProps) => {
  const { defaultLoading = false } = props || {};
  const [loading, setLoading] = useState(defaultLoading);
  const {
    error,
    set: setError,
    clear: clearError,
    has: hasError,
  } = useErrorAlert();

  const loadingText = (textProps: {
    loading: ReactNode;
    default: ReactNode;
  }) => {
    return loading ? (
      <div className="flex items-center">
        <LoaderIcon className="mr-2" />
        <span>{textProps.loading}</span>
      </div>
    ) : (
      textProps.default
    );
  };

  return {
    loading,
    loadingText,
    setLoading,
    error,
    setError,
    clearError,
    hasError,
  };
};

export const useDecoratedReactQuery = <
  T extends UseMutationResult<any, any, any, any> | UseQueryResult<any, any>
>(
  query: T,
  buildErrorData?: (error: T["error"]) => {
    title?: string;
    message?: React.ReactNode;
  }
) => {
  const {
    error: errorAlert,
    set: setError,
    clear: clearErrorAlert,
  } = useErrorAlert();
  let errorTitle: string | undefined;
  let errorMessage: React.ReactNode | undefined;

  if (query.isError) {
    const error = query.error;
    let { title, message } = buildErrorData
      ? buildErrorData(error)
      : { title: undefined, message: undefined };

    if (title === undefined) {
      title = "Whoops!";
    }

    if (message === undefined) {
      if (error && typeof error === "object") {
        if (error.message) {
          message = error.message;
        } else if (error.hasOwnProperty("toString")) {
          message = error.toString();
        } else {
          message = error;
        }
      } else {
        message = error;
      }
    }

    errorTitle = title;
    errorMessage = message;
  }

  useEffect(() => {
    if (query.isLoading) {
      clearErrorAlert();
    } else if (errorTitle && errorMessage) {
      setError({ title: errorTitle, message: errorMessage });
    }
  }, [clearErrorAlert, errorMessage, errorTitle, query.isLoading, setError]);

  const loadingText = useCallback(
    (textProps: { loading: string; default: string }) => {
      return (
        <div className="flex items-center">
          {query.isLoading ? (
            <>
              <LoaderIcon className="mr-2" />
              <span>{textProps.loading}</span>
            </>
          ) : (
            textProps.default
          )}
        </div>
      );
    },
    [query.isLoading]
  );

  return {
    ...query,
    errorAlert,
    clearErrorAlert,
    loadingText,
  };
};

export const useDragDrop = () => {
  return useContext(DragDropContext);
};

export interface UseFileDropStackProps
  extends Omit<FileDropStackPushProps, "id"> {
  listen?: boolean;
}
export const useFileDropStack = ({
  onDrop,
  onDragEnter,
  onDragLeave,
  onDragOver,
  listen = true,
}: UseFileDropStackProps) => {
  const id = useMemo(() => {
    return nanoid();
  }, []);
  const { add, remove } = useContext(FileDropStackContext);

  useEffect(() => {
    if (listen) {
      add({
        id,
        onDrop,
        onDragEnter,
        onDragLeave,
        onDragOver,
      });

      return () => {
        remove(id);
      };
    } else {
      remove(id);
    }
  }, [add, id, listen, onDragEnter, onDragLeave, onDragOver, onDrop, remove]);
};

export const useSnackBoxItems = () => {
  const context = useContext(SnackBoxContext);
  return context.items;
};

export const useSnackBarFactory = () => {
  const { create } = useContext(SnackBoxContext);
  return create;
};

type AnimationPhase = "mounting" | "mounted" | "unmounting" | "unmounted";
type AnimateHandlers = {
  onAnimationStart?: AnimationEventHandler<HTMLElement>;
  onAnimationEnd?: AnimationEventHandler<HTMLElement>;
};
type AnimateFunction = (props: {
  phase: AnimationPhase;
  animationEventHandlers: AnimateHandlers;
}) => ReactNode;

interface UseAnimatedMountingProps {
  mount: boolean;
  onPhaseChange?: (newPhase: AnimationPhase) => void;
}
export const useAnimatedMounting = ({
  mount,
  onPhaseChange,
}: UseAnimatedMountingProps): ((fn: AnimateFunction) => ReactNode) => {
  const [phase, setPhase] = useState<AnimationPhase>(
    mount ? "mounting" : "unmounted"
  );
  const hasPhaseChange = useHasChanged(phase);

  if (mount) {
    if (phase === "unmounted" || phase === "unmounting") {
      setPhase("mounting");
    }
  } else {
    if (phase === "mounted" || phase === "mounting") {
      setPhase("unmounting");
    }
  }

  const onAnimationStart: AnimationEventHandler<HTMLElement> = () => {
    setPhase((currentPhase) => {
      switch (currentPhase) {
        case "mounted":
          return "unmounting";
        case "unmounted":
          return "unmounting";
        default:
          return currentPhase;
      }
    });
  };

  const onAnimationEnd: AnimationEventHandler<HTMLElement> = () => {
    setPhase((currentPhase) => {
      switch (currentPhase) {
        case "mounting":
          return "mounted";
        case "unmounting":
          return "unmounted";
        default:
          return currentPhase;
      }
    });
  };

  const animate = (fn: AnimateFunction) => {
    return fn({
      phase,
      animationEventHandlers:
        phase === "unmounting" || phase === "mounting"
          ? { onAnimationStart, onAnimationEnd }
          : {},
    });
  };

  useEffect(() => {
    if (onPhaseChange && hasPhaseChange) {
      onPhaseChange(phase);
    }
  }, [phase, hasPhaseChange, onPhaseChange]);

  return animate;
};
