import React, { useRef, useState, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { useSpring, animated } from "@react-spring/web";
import { useScroll, Handler } from "@use-gesture/react";
import useMeasure from "react-use-measure";

import {
  useActiveWorkspace,
  useCurrentUser,
  useCurrentUserTimeZone,
} from "../libs/hooks/app";
import {
  useUpdateWorkspaceUserPreferences,
  useWorkspaceUserPreferences,
} from "../libs/hooks/userPreferences";
import { mergeClassNames, mergeRefs } from "../libs/components";

import { PostWithScheduledProp } from "../types/posts";
import { Platform } from "../types/platforms";

import DragAutoScroll from "../components/DragAutoScroll";
import SecondaryButton from "../components/buttons/Secondary";
import TimeZoneSelect from "../components/form/select/TimeZone";

import CalendarContent from "./calendar/Content";

const MIN_ROW_HEIGHT = 100;
const MAX_ROW_HEIGHT = 150;
const TIME_COL_WIDTH = 65;
const CONTENT_RIGHT_PADDING = 10;
const MIN_COL_WIDTH = 150;

interface CalendarProps {
  today: DateTime;
  startOfWeek: DateTime;
  daysPerWeek: number;
  dayStartsAt: number;
  showHours: number;
  posts: PostWithScheduledProp[];
  platforms: Platform[];
  className?: string;
}

const Calendar: React.FC<CalendarProps> = ({
  today,
  startOfWeek,
  daysPerWeek,
  dayStartsAt,
  showHours,
  posts,
  platforms,
  className = "",
}) => {
  const user = useCurrentUser();
  const workspace = useActiveWorkspace();
  const userTimeZone = useCurrentUserTimeZone();
  const { data: workspacePreferences } = useWorkspaceUserPreferences(
    user.id,
    workspace.id
  );
  const { mutate: updateWorkspaceUserPreferences } =
    useUpdateWorkspaceUserPreferences();
  const workspaceTimeZone =
    workspacePreferences && workspacePreferences.timeZone
      ? workspacePreferences.timeZone
      : "";
  const [resizeDebounce, setResizeDebounce] = useState(0);
  const [resizeRef, resizeBounds] = useMeasure({ debounce: resizeDebounce });
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [renderStages, setRenderStages] = useState({
    calcRowHeight: false,
    scrollDayStart: false,
  });
  const [scrollShadowSpring, scrollShadowSpringApi] = useSpring(() => ({
    shadow: 0,
  }));
  const [visibleSpring, visibleSpringApi] = useSpring(() => ({
    opacity: 0,
  }));
  const [colWidth, setColWidth] = useState(MIN_COL_WIDTH);
  const [rowHeight, setRowHeight] = useState(MIN_ROW_HEIGHT);
  const contentContainerHeight = 24 * rowHeight;
  const hours = useMemo(() => {
    const hours: Array<[string, string]> = [];

    for (let i = 0; i < 24; i++) {
      const suffix = i < 12 ? "AM" : "PM";
      const hour = i % 12;

      hours.push([`${hour || 12}`, suffix]);
    }

    return hours;
  }, []);
  const daysOfWeek = useMemo(() => {
    const days: number[] = [];

    for (let i = 1; i <= daysPerWeek; i++) {
      days.push(i);
    }

    return days.map((dayOfWeek) => {
      return startOfWeek.set({ weekday: dayOfWeek });
    });
  }, [daysPerWeek, startOfWeek]);
  const dragBounds = useMemo(() => {
    const minX = resizeBounds.left + TIME_COL_WIDTH;
    const maxX = resizeBounds.left + resizeBounds.width - colWidth;
    const minY = resizeBounds.top;
    const maxY = minY + resizeBounds.height;
    return { minX, maxX, minY, maxY };
  }, [
    colWidth,
    resizeBounds.height,
    resizeBounds.left,
    resizeBounds.top,
    resizeBounds.width,
  ]);

  useEffect(() => {
    if (renderStages.calcRowHeight && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = rowHeight * dayStartsAt - 5;
      setRenderStages((prev) => ({ ...prev, scrollDayStart: true }));
    }
  }, [dayStartsAt, renderStages.calcRowHeight, rowHeight]);

  useEffect(() => {
    if (resizeBounds.width) {
      setResizeDebounce(150);
      setColWidth(
        Math.max(
          (resizeBounds.width - TIME_COL_WIDTH - CONTENT_RIGHT_PADDING) /
            daysPerWeek,
          MIN_COL_WIDTH
        )
      );
    }

    if (resizeBounds.height) {
      setResizeDebounce(150);
      setRowHeight(
        Math.min(
          Math.max(Math.floor(resizeBounds.height / showHours), MIN_ROW_HEIGHT),
          MAX_ROW_HEIGHT
        )
      );

      if (!renderStages.calcRowHeight) {
        setRenderStages((prev) => ({ ...prev, calcRowHeight: true }));
      }
    }
  }, [
    daysPerWeek,
    showHours,
    resizeBounds.width,
    resizeBounds.height,
    renderStages.calcRowHeight,
  ]);

  useEffect(() => {
    if (renderStages.calcRowHeight && renderStages.scrollDayStart) {
      visibleSpringApi.start({ opacity: 1 });
    }
  }, [
    renderStages.calcRowHeight,
    renderStages.scrollDayStart,
    visibleSpringApi,
  ]);

  const handleScroll: Handler<"scroll"> = ({ memo }) => {
    const scrollY =
      scrollContainerRef && scrollContainerRef.current
        ? scrollContainerRef.current.scrollTop
        : 0;

    if ((memo === 0 || memo === undefined) && scrollY !== 0) {
      scrollShadowSpringApi.start({ shadow: 15 });
    } else if (memo !== 0 && memo !== undefined && scrollY === 0) {
      scrollShadowSpringApi.start({ shadow: 0 });
    }

    return scrollY;
  };

  const scrollBind = useScroll(handleScroll);

  return (
    <animated.div
      className={mergeClassNames(
        "flex flex-col h-full w-full overflow-hidden",
        className
      )}
      style={visibleSpring}
    >
      <animated.div
        className="flex-shrink-0 flex items-center pb-2"
        style={{
          zIndex: 1,
          boxShadow: scrollShadowSpring.shadow.to(
            (s) => `0px ${s}px ${s}px -${s}px rgba(0, 0, 0, 0.15)`
          ),
        }}
      >
        <div className="flex-shrink-0" style={{ width: TIME_COL_WIDTH }}>
          <TimeZoneSelect
            dropdownXAlign="left"
            highlightOptions={[
              { value: "", text: `User profile (${userTimeZone})` },
            ]}
            value={workspaceTimeZone}
            onChange={(newTimeZone) => {
              updateWorkspaceUserPreferences({
                userId: user.id,
                workspaceId: workspace.id,
                updateProps: {
                  timeZone: newTimeZone === "" ? null : newTimeZone,
                },
              });
            }}
            hideLabel={true}
            ButtonElement={SecondaryButton}
            dropdownClassName="px-1"
            toggleButtonClassName="px-0 py-0 w-full bg-none"
            renderSelectedOption={() => {
              return (
                <div>
                  <div
                    className="font-bold text-gray-400"
                    style={{ fontSize: "0.625rem" }}
                  >
                    TIME ZONE
                  </div>
                  <div className="text-sm">{`${today.toFormat("ZZ")}`}</div>
                </div>
              );
            }}
          />
        </div>
        {daysOfWeek.map((day) => {
          const isToday = day.hasSame(today, "day");
          const date = day.toFormat("MMM d");
          const dayOfWeek = day.toFormat("EEEE");
          return (
            <div
              key={date}
              className="flex-shrink-0 overflow-hidden flex flex-col text-center"
              style={{ width: colWidth }}
            >
              <div className="text-xs">
                <div
                  className={`inline-block px-2 py-1 ${
                    isToday ? "rounded-full bg-purple-500 text-white" : ""
                  }`}
                >
                  {date}
                </div>
              </div>
              <div className="text-lg font-bold">{dayOfWeek}</div>
            </div>
          );
        })}
      </animated.div>
      <DragAutoScroll
        ref={mergeRefs([scrollContainerRef, resizeRef])}
        className="relative flex-grow overflow-x-hidden overflow-y-auto"
        {...scrollBind()}
      >
        <div
          className="flex items-stretch"
          style={{ height: contentContainerHeight }}
        >
          <div
            className="flex-shrink-0 relative"
            style={{ width: TIME_COL_WIDTH }}
          >
            {hours.map(([hour, suffix], index) => {
              const displayTime = `${hour} ${suffix}`;

              return (
                <div
                  key={displayTime}
                  className="absolute left-0 w-full text-xs text-gray-500"
                  style={{
                    top: index * rowHeight,
                    height: rowHeight,
                  }}
                >
                  <span
                    className="absolute right-0 pr-3 leading-none"
                    style={{ top: 0 }}
                  >
                    {displayTime}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="flex-grow relative">
            <CalendarContent
              posts={posts}
              platforms={platforms}
              containerHeight={contentContainerHeight}
              rowHeight={rowHeight}
              daysOfWeek={daysOfWeek}
              colWidth={colWidth}
              dragBounds={dragBounds}
            />
          </div>
        </div>
      </DragAutoScroll>
    </animated.div>
  );
};

export default Calendar;
