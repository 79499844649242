import React from "react";
import { getTwitterVerifier } from "../../../libs/platforms/twitter";
import { useActiveWorkspace } from "../../../libs/hooks/app";
import { useCreatePlatform } from "../../../libs/hooks/platforms";
import { useContentSwitch } from "../../../libs/hooks/general";

import {
  AddPlatformContent,
  BaseComponentProps,
  ConnectTwitter,
} from "../../../types/modals/AddPlatform";
import ConnectBase from "./ConnectBase";

interface ConnectTwitterProps extends BaseComponentProps {
  contentId: ConnectTwitter["id"];
}

const AddPlatformModalConnectTwitter: React.FC<ConnectTwitterProps> = ({
  contentId,
  ...rest
}) => {
  const { content } = useContentSwitch<AddPlatformContent, "connectTwitter">(
    "connectTwitter"
  );
  const workspace = useActiveWorkspace();
  const { mutateAsync: createPlatform } = useCreatePlatform();
  const token = content.data.token;

  const handleCreatePlatform = async () => {
    if (!token) {
      throw new Error("Unable to get tokens for twitter");
    }

    const verifier = await getTwitterVerifier(token);

    return createPlatform({
      workspaceId: workspace.id,
      type: "TWITTER",
      token,
      verifier,
    });
  };

  return (
    <ConnectBase
      contentId={contentId}
      tryAgainContent={{ id: contentId, data: { token } }}
      platformType="TWITTER"
      createPlatform={handleCreatePlatform}
      {...rest}
    />
  );
};

export default AddPlatformModalConnectTwitter;
