import React, { ComponentProps } from "react";
import { Link } from "react-router-dom";
import { mergeClassNames } from "../../libs/components";

import { ReactComponent as BrandIconColour } from "../../images/brand/colour/logo.svg";

interface BrandLogoLinkProps
  extends Omit<ComponentProps<typeof Link>, "children" | "to"> {
  to?: string;
  width?: number;
  height?: number;
  hideName?: boolean;
}
const BrandLogoLink: React.FC<BrandLogoLinkProps> = ({
  className = "",
  to = "/",
  width,
  height,
  hideName = false,
  ...props
}) => {
  const defaultWidth =
    width !== undefined ? width : height !== undefined ? undefined : 30;
  return (
    <Link
      className={mergeClassNames("flex items-center", className)}
      to={to}
      {...props}
    >
      <BrandIconColour width={defaultWidth} height={height} />
      {!hideName && <span className="ml-2 font-bold text-3xl">Seenly</span>}
    </Link>
  );
};

export default BrandLogoLink;
