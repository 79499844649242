import React, { CSSProperties, useMemo } from "react";
import {
  ChevronDown as ChevronDownIcon,
  Plus as PlusIcon,
  Check as CheckIcon,
  Edit2 as EditIcon,
} from "react-feather";

import {
  useCurrentUser,
  useActiveWorkspace,
  useShowModal,
} from "../../libs/hooks/app";
import { useWorkspaces } from "../../libs/hooks/workspaces";
import { useSnackBarFactory } from "../../libs/hooks/general";
import { useUpdateUserPreferences } from "../../libs/hooks/userPreferences";

import H4 from "../../components/headings/H4";
import DropdownMenu from "../../components/menus/Dropdown";
import DropdownMenuButton from "../../components/buttons/DropdownMenu";
import FloatButton from "../../components/buttons/Float";
import SwitchedWorkspaceSnackBarContent from "../../components/snackBarContent/SwitchedWorkspace";

interface WorkspaceMenuProps {
  className?: string;
  style?: CSSProperties;
  onSelect?: () => void;
}

const WorkspaceMenu: React.FC<WorkspaceMenuProps> = ({
  className = "",
  style = {},
  onSelect,
}) => {
  const showModal = useShowModal();
  const user = useCurrentUser();
  const activeWorkspace = useActiveWorkspace();
  const { data: workspacesData } = useWorkspaces(user.id);
  const { mutate: updateUserPreferences } = useUpdateUserPreferences();
  const createSnackBar = useSnackBarFactory();
  const sortedWorkspaces = useMemo(() => {
    const workspaces = workspacesData ? workspacesData.workspaces.slice() : [];
    return workspaces.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [workspacesData]);

  return (
    <DropdownMenu
      className={`h-full w-full flex items-center ${className}`}
      style={style}
    >
      <FloatButton className="py-1 w-full group justify-start text-left">
        <div>
          <div className="-mb-1 text-xs leading-1 font-bold text-gray-400">
            WORKSPACE
          </div>
          <H4 className="truncate text-xl leading-6">{activeWorkspace.name}</H4>
        </div>

        <ChevronDownIcon
          className="ml-2 opacity-0 group-hover:opacity-100"
          strokeWidth={1}
        />
      </FloatButton>
      <div
        className="flex flex-col p-2 w-full"
        style={{ minWidth: 250, maxWidth: 400 }}
      >
        {sortedWorkspaces.map((workspace) => {
          const isSelected = workspace.id === activeWorkspace.id;
          return (
            <DropdownMenuButton
              key={workspace.id}
              className={isSelected ? "text-purple-500" : ""}
              onClick={() => {
                if (isSelected) {
                  return;
                }

                updateUserPreferences({
                  userId: user.id,
                  updateProps: { activeWorkspaceId: workspace.id },
                });
                createSnackBar({
                  content: (
                    <SwitchedWorkspaceSnackBarContent
                      newWorkspaceName={workspace.name}
                    />
                  ),
                });
              }}
            >
              {isSelected && <CheckIcon className="w-4 h-4" />}
              <span
                className="ml-2 truncate"
                style={{ paddingLeft: isSelected ? `0px` : `16px` }}
              >
                {workspace.name}
              </span>
              <div
                className="ml-auto hover:bg-gray-300 py-2 px-2 rounded-lg"
                onClick={() => {
                  showModal("updateWorkspace", { workspace });

                  if (onSelect) {
                    onSelect();
                  }
                }}
              >
                <EditIcon size={14} />
              </div>
            </DropdownMenuButton>
          );
        })}
        <div className="w-full border border-gray-100 my-1"></div>
        <DropdownMenuButton
          onClick={() => {
            showModal("addWorkspace");

            if (onSelect) {
              onSelect();
            }
          }}
        >
          <PlusIcon className="mr-2" />
          Add new workspace
        </DropdownMenuButton>
      </div>
    </DropdownMenu>
  );
};

export default WorkspaceMenu;
