import { DateTime } from "luxon";
import { Platform } from "../types/platforms";

const AUTH_REQUIRED_WITHIN_DAYS = 30;

export function getPlatformTypeName(type: Platform["type"]) {
  switch (type) {
    case "LINKEDIN":
      return "LinkedIn";
    case "TWITTER":
      return "Twitter";
    case "FACEBOOK":
      return "Facebook";
  }
}

export function platformRequiresAuth(authExpiry: string) {
  const now = DateTime.local().setZone("utc").startOf("second");
  const expiry = DateTime.fromISO(authExpiry).setZone("utc");
  const diff = expiry.diff(now, "days");
  return diff.days <= AUTH_REQUIRED_WITHIN_DAYS;
}

export function platformIsAuthExpired(authExpiry: string) {
  const now = DateTime.local().setZone("utc").startOf("second");
  const expiry = DateTime.fromISO(authExpiry).setZone("utc");
  return expiry < now;
}

export function getPlatformTypeColour(type: Platform["type"] | "INSTAGRAM") {
  switch (type) {
    case "LINKEDIN":
      return "#0a66c2";
    case "TWITTER":
      return "#1da1f2";
    case "FACEBOOK":
      return "#1877f2";
    case "INSTAGRAM":
      return "#C13584";
  }
}

export function countEnabledEntities(platform: Platform) {
  return Object.values(platform.entities).reduce((carry, entity) => {
    if (entity.disabled) {
      return carry;
    } else {
      return carry + 1;
    }
  }, 0);
}
