const base = {
  urls: {
    TERMS_OF_USE:
      "https://seenly.notion.site/seenly/Seenly-Terms-of-Use-Privacy-Policy-5f7667077ac34470bb6628b28a003d4f",
    PRIVACY_POLICY:
      "https://seenly.notion.site/seenly/Seenly-Terms-of-Use-Privacy-Policy-5f7667077ac34470bb6628b28a003d4f#b47141b8013d48da87592e8d2a17203b",
  },
  feedback_email: {
    TO: "support@seenly.io",
    SUBJECT: "Hi seenly team 👋, it would be great if...",
    BODY: "Please include as much detail as possible including screenshots with notes 📝. Loom videos work great as well 📹. We'd love to know as much as possible.%0D%0A%0D%0AMany thanks from the seenly team.",
    TITLE: "Help us improve seenly or report an issue",
  },
  LOG_ROCKET_ID: "vc0zlz/seenly-app",
  AMPLITUDE_ID: "4f313cc774825847ace4776682533a3c",
  DEFAULT_TRIAL_PERIOD_DAYS: 14,
  GRAMMARLY_CLIENT_ID: "client_RKiXXDNGWLfmWf9A5FjRuK",
  REWARDFUL_API_KEY: "b58e7d",
  AFFILIATE_DISCOUNT_PERCENT: 5,
  prices: {
    FREE: {
      id: null,
      productId: null,
      unitAmount: 0,
      currency: "USD",
      billPeriod: "MONTHLY",
      displayName: "Free",
      description: "Start slow and see how Seenly can work for you",
      features: [
        "Up to 3 social accounts",
        "Up to 5 manually scheduled posts at a time",
        "Up to 3 smart scheduled posts per month",
        "100 MB of media storage",
      ],
    },
    PUBLISH_MONTHLY: {
      id: "price_1NyGk8BWee9QVOB26T2qFlfv",
      productId: "prod_OloMGEEzgRQA80",
      unitAmount: 3000, // In cents.
      currency: "USD",
      billPeriod: "MONTHLY",
      displayName: "Publish",
      description:
        "Unleash your creativity with unlimited publishing to all of your socials",
      features: [
        "Unlimited social accounts",
        "Unlimited manually scheduled posts",
        "Unlimited smart scheduled posts",
        "100 GB of media storage",
        "Post analytics",
      ],
    },
  },
};

const dev = {
  ...base,
  apiGateway: {
    URL: "https://api.dev.seenly.io",
  },
  cdn: {
    URL: "https://cdn.dev.seenly.io",
  },
  cognito: {
    USER_POOL_ID: "us-east-1_XmIAfKloY",
    APP_CLIENT_ID: "63d18dcp67u9u457dk1n0biece",
  },
  linkedIn: {
    CLIENT_ID: "864nhvp2f3blna",
  },
  facebook: {
    APP_ID: "896025544455614",
  },
  stripe: {
    PUBLIC_KEY:
      "pk_test_51NnWOcBWee9QVOB2mKP11R7vskBGF8Sm5huu979feQdEyqpmt1pW1ab7HSNS2Uv9HDJrX2CtB8lpkZpEo9aRAp9l00jXyS3wlQ",
  },
  prices: {
    ...base.prices,
    PUBLISH_MONTHLY: {
      ...base.prices.PUBLISH_MONTHLY,
      id: "price_1NyGk8BWee9QVOB26T2qFlfv",
      productId: "prod_OloMGEEzgRQA80",
    },
  },
};

const staging = {
  ...base,
  apiGateway: {
    URL: "https://app.staging.seenly.io/api",
  },
  cdn: {
    URL: "https://cdn.staging.seenly.io",
  },
  cognito: {
    USER_POOL_ID: "us-east-1_jkQOpomJo",
    APP_CLIENT_ID: "1iseiincha83l7022geua535d5",
  },
  linkedIn: {
    CLIENT_ID: "864nhvp2f3blna",
  },
  facebook: {
    APP_ID: "896025544455614",
  },
  stripe: {
    PUBLIC_KEY:
      "pk_test_51NnWOcBWee9QVOB2mKP11R7vskBGF8Sm5huu979feQdEyqpmt1pW1ab7HSNS2Uv9HDJrX2CtB8lpkZpEo9aRAp9l00jXyS3wlQ",
  },
  prices: {
    ...base.prices,
    PUBLISH_MONTHLY: {
      ...base.prices.PUBLISH_MONTHLY,
      id: "price_1NyGk8BWee9QVOB26T2qFlfv",
      productId: "prod_OloMGEEzgRQA80",
    },
  },
};

const prod = {
  ...base,
  apiGateway: {
    URL: "https://app.seenly.io/api",
  },
  cdn: {
    URL: "https://cdn.seenly.io",
  },
  cognito: {
    USER_POOL_ID: "us-east-1_85vohXB7D",
    APP_CLIENT_ID: "g6o5cr91m1a23d2h3u0vld4l",
  },
  linkedIn: {
    CLIENT_ID: "864nhvp2f3blna",
  },
  facebook: {
    APP_ID: "896025544455614",
  },
  stripe: {
    PUBLIC_KEY:
      "pk_live_51NnWOcBWee9QVOB2zp6TdQsWwiIKZcJXPNukTYxKrmyWcHewZctwH4Ynhge0QAztfsV4956PSDH7957flGoTYSny007umrwDIz",
  },
  prices: {
    ...base.prices,
    PUBLISH_MONTHLY: {
      ...base.prices.PUBLISH_MONTHLY,
      id: "price_1O0nE5BWee9QVOB2AC7Qfv80",
      productId: "prod_OoQ4h9rtijtmgP",
    },
  },
};

let config = dev;

switch (process.env.REACT_APP_STAGE) {
  case "prod":
    config = prod;
    break;
  case "staging":
    config = staging;
    break;
}

export default config;
