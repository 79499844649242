import React, { ReactNode } from "react";
import { ArrowSmUpIcon, ArrowSmDownIcon } from "@heroicons/react/outline";

import { formatCountForDisplay } from "../../../libs/postMetrics";

import Card from "./Card";

interface AnalyticsDailySnapshotMetricCardProps {
  className?: string;
  iconContainerClassName?: string;
  icon: ReactNode;
  title: string;
  value?: number;
  deltaPercent?: number | null;
}
const AnalyticsDailySnapshotMetricCard: React.FC<
  AnalyticsDailySnapshotMetricCardProps
> = ({
  className = "",
  iconContainerClassName = "",
  icon,
  title,
  value,
  deltaPercent,
}) => {
  const hasDelta = deltaPercent !== undefined && deltaPercent !== null;
  const increase = hasDelta && deltaPercent >= 0;

  return (
    <Card
      className={className}
      iconContainerClassName={iconContainerClassName}
      icon={icon}
      title={title}
      subtitle={
        <div className="flex items-baseline">
          <div>{value === undefined ? "-" : formatCountForDisplay(value)}</div>

          {hasDelta && (
            <div
              className={`ml-1 text-xs flex items-center ${
                increase ? "text-green-500" : "text-red-500"
              }`}
            >
              {increase ? (
                <ArrowSmUpIcon className="w-4 h-4" />
              ) : (
                <ArrowSmDownIcon className="w-4 h-4" />
              )}
              <span>{`${deltaPercent.toFixed(1)}%`}</span>
            </div>
          )}
        </div>
      }
    />
  );
};

export default AnalyticsDailySnapshotMetricCard;
