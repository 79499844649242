import React from "react";
import { DateTime } from "luxon";
import {
  Plus as PlusIcon,
  Calendar as CalendarIcon,
  Repeat as RepeatIcon,
  XCircle as XCircleIcon,
} from "react-feather";
import { BanIcon, ReceiptTaxIcon } from "@heroicons/react/outline";

import { Subscription } from "../../../types/subscriptions";
import { useActiveWorkspaceTimeZone } from "../../../libs/hooks/app";
import { useUpdateSubscription } from "../../../libs/hooks/subscriptions";
import { useSnackBarFactory } from "../../../libs/hooks/general";
import { mergeClassNames } from "../../../libs/components";

import PrimaryButton from "../../../components/buttons/Primary";
import ChangesSavedSnackBarContent from "../../../components/snackBarContent/ChangesSaved";

interface SubscriptionDetailsSectionProps {
  subscription: Subscription;
  className?: string;
}
const SubscriptionDetailsSection: React.FC<SubscriptionDetailsSectionProps> = ({
  subscription,
  className = "",
}) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const {
    mutateAsync: updateSubscription,
    isLoading: isUpdatingSubscription,
    loadingText: updateSubscriptionLoadingText,
    errorAlert: updateSubscriptionErrorAlert,
    clearErrorAlert: clearUpdateSubscriptionErrorAlert,
  } = useUpdateSubscription();
  const createSnackBar = useSnackBarFactory();
  const displayPrice = Math.floor(subscription.price.unitAmount / 100);
  const displayBillingPeriod =
    subscription.price.period === "MONTHLY" ? "Monthly" : "Yearly";
  const displayCreated = DateTime.fromISO(subscription.created)
    .setZone(timeZone)
    .toFormat("ff");
  const displayPeriodStart = DateTime.fromISO(subscription.periodStart)
    .setZone(timeZone)
    .toFormat("MMM d");
  const displayPeriodEnd = DateTime.fromISO(subscription.periodEnd)
    .setZone(timeZone)
    .toFormat("MMM d");
  const displayNextInvoice =
    subscription.cancelAtPeriodEnd || subscription.ended
      ? "No further invoice"
      : `$${displayPrice} on ${displayPeriodEnd}`;
  const displayScheduledToCancelOn = subscription.cancelAtPeriodEnd
    ? displayPeriodEnd
    : null;
  const displayEndedAt = subscription.endedAt
    ? DateTime.fromISO(subscription.endedAt).setZone(timeZone).toFormat("MMM d")
    : null;
  const canStopCancel =
    !!subscription.cancelAtPeriodEnd && !!subscription.paymentMethod;

  const handlePreventCancel = async () => {
    clearUpdateSubscriptionErrorAlert();
    await updateSubscription({
      subscription,
      updateProps: { cancelAtPeriodEnd: false },
    });
    createSnackBar({
      content: <ChangesSavedSnackBarContent message="Subscription updated!" />,
    });
  };

  return (
    <>
      <div className={mergeClassNames("grid grid-cols-3 gap-5", className)}>
        <div>
          <div className="flex items-center text-gray-400 text-xs">
            <PlusIcon className="flex-shrink-0 w-4 h-4" />
            <span className="ml-1 font-bold">CREATED</span>
          </div>
          <div className="truncate">{displayCreated}</div>
        </div>

        {!subscription.ended && (
          <div>
            <div className="flex items-center text-gray-400 text-xs">
              <CalendarIcon className="flex-shrink-0 w-4 h-4" />
              <span className="ml-1 font-bold">CURRENT PERIOD</span>
            </div>
            <div className="truncate">
              {`${displayPeriodStart} to ${displayPeriodEnd}`}
            </div>
          </div>
        )}

        {displayEndedAt && (
          <div>
            <div className="flex items-center text-gray-400 text-xs">
              <BanIcon className="flex-shrink-0 w-4 h-4" />
              <span className="ml-1 font-bold">ENDED ON</span>
            </div>
            <div className="truncate">{displayEndedAt}</div>
          </div>
        )}

        <div>
          <div className="flex items-center text-gray-400 text-xs">
            <RepeatIcon className="flex-shrink-0 w-4 h-4" />
            <span className="ml-1 font-bold">BILL PERIOD</span>
          </div>
          <div className="truncate">{displayBillingPeriod}</div>
        </div>

        <div>
          <div className="flex items-center text-gray-400 text-xs">
            <ReceiptTaxIcon className="flex-shrink-0 w-4 h-4" />
            <span className="ml-1 font-bold">NEXT INVOICE</span>
          </div>
          <div className="truncate">{displayNextInvoice}</div>
        </div>

        {displayScheduledToCancelOn && (
          <div>
            <div className="flex items-center text-gray-400 text-xs">
              <XCircleIcon className="flex-shrink-0 w-4 h-4" />
              <span className="ml-1 font-bold">SCHEDULED TO CANCEL ON</span>
            </div>
            <div className="truncate">{displayScheduledToCancelOn}</div>
          </div>
        )}

        {displayScheduledToCancelOn && (
          <div>
            <div className="flex items-center text-gray-400 text-xs">
              <BanIcon className="flex-shrink-0 w-4 h-4" />
              <span className="ml-1 font-bold">ENDS</span>
            </div>
            <div className="truncate">At period end</div>
          </div>
        )}
      </div>
      {canStopCancel && (
        <div className="mt-8">
          {updateSubscriptionErrorAlert({ className: "mb-2" })}
          <p>
            If you no longer want to cancel your subscription you can
            re-activate it by clicking below.
          </p>
          <PrimaryButton
            className="mt-2 w-full"
            onClick={() => handlePreventCancel()}
            disabled={isUpdatingSubscription}
          >
            {updateSubscriptionLoadingText({
              loading: "Updating subscription...",
              default: "Don't cancel my subscription",
            })}
          </PrimaryButton>
        </div>
      )}
    </>
  );
};

export default SubscriptionDetailsSection;
