import { DateTime } from "luxon";
import React, { ReactNode, RefObject, CSSProperties, useRef } from "react";
import { convertFromRaw } from "draft-js";
import { useSpring, animated, to } from "@react-spring/web";
import {
  Clock as ClockIcon,
  Copy as CopyIcon,
  CheckCircle as CheckCircleIcon,
  MoreHorizontal as MoreHorizontalIcon,
  Edit as EditIcon,
  Trash as TrashIcon,
  AlertTriangle as AlertTriangleIcon,
  ExternalLink as ExternalLinkIcon,
  Heart as HeartIcon,
  Repeat as RepeatIcon,
  ThumbsUp as ThumbsUpIcon,
  MessageSquare as MessageSquareIcon,
} from "react-feather";
import {
  FlagIcon,
  PresentationChartLineIcon,
  SelectorIcon,
} from "@heroicons/react/outline";

import { PostWithScheduledProp } from "../../types/posts";
import { Platform } from "../../types/platforms";
import { analyticsLogEvent } from "../../libs/amplitudeAnalytics";
import { useDragDrop } from "../../libs/hooks/general";
import { useActiveWorkspaceTimeZone, useShowModal } from "../../libs/hooks/app";
import { getPlatformTypeName } from "../../libs/platform";

import PlatformIcon from "../../components/icons/Platform";
import DropdownMenu from "../../components/menus/Dropdown";
import IconButton from "../../components/buttons/Icon";
import DropdownMenuButton from "../../components/buttons/DropdownMenu";
import TertiaryButton from "../../components/buttons/Tertiary";
import DropdownMenuExternalLink from "../../components/links/DropdownMenuExternal";
import InternalLink from "../../components/links/Internal";
import PostMetricsEngagementIcon from "../../components/icons/PostMetricsEngagement";
import PublishedPostActionIcon from "../../components/icons/PublishedPostAction";

interface CalendarPostProps {
  post: PostWithScheduledProp;
  platform: Platform;
  height: number;
  width: number;
  itemId: string;
  dragType: string;
  dragBounds: {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  };
  timeZone: string;
  containerRef: RefObject<any>;
  getNewScheduled: (top: number, left: number) => [boolean, number];
}

const BG_WHITE = "rgb(255, 255, 255)";
const BG_RED = "rgb(248, 113, 113)";
const DISPLAY_TIME_FORMAT = "h:mma";
const NON_TEXT_HEIGHT = 60;
const LINE_HEIGHT = 16;

const CalendarPost: React.FC<CalendarPostProps> = ({
  post,
  platform,
  height,
  width,
  itemId,
  dragType,
  dragBounds,
  timeZone,
  containerRef,
  getNewScheduled,
}) => {
  const [valid, scheduled] = getNewScheduled(0, 0);
  const dragItemRef = useRef<HTMLDivElement>(null);
  const { drag } = useDragDrop();
  const workspaceTimeZone = useActiveWorkspaceTimeZone();
  const showModal = useShowModal();
  const [spring, springApi] = useSpring(() => ({
    x: 0,
    y: 0,
    opacity: 0,
    backgroundColor: valid ? BG_WHITE : BG_RED,
    scheduled: scheduled,
    zIndex: 0,
    shadow: 1,
    position: "relative" as CSSProperties["position"],
    immediate: (k) =>
      k !== "shadow" && k !== "opacity" && k !== "backgroundColor",
  }));
  const canReschedule =
    post.status === "ERROR" ||
    post.status === "DRAFT" ||
    post.status === "SCHEDULED";
  const canEditPublishedActions =
    post.type !== "FACEBOOK" &&
    (post.status === "ERROR" ||
      post.status === "DRAFT" ||
      post.status === "SCHEDULED");
  const isPosted = post.status === "POSTED";
  const rawTextAreaHeight = height - NON_TEXT_HEIGHT;
  const lineCount = Math.floor(rawTextAreaHeight / LINE_HEIGHT);
  const clampedTextAreaHeight = lineCount * LINE_HEIGHT;
  const scheduledDateTime = DateTime.fromISO(post.scheduled).setZone(
    workspaceTimeZone
  );
  const postedDateTime = post.posted
    ? DateTime.fromISO(post.posted).setZone(workspaceTimeZone)
    : null;

  const authExpiryDateTime = platform.authExpiry
    ? DateTime.fromISO(platform.authExpiry).setZone(workspaceTimeZone)
    : null;
  const externalUrl = post.externalUrl;
  const entity = platform.entities[post.platformEntityId];
  const canEdit = post.status !== "POSTED" && post.status !== "POSTING";
  const hasEngagementLevel =
    post.status === "POSTED" &&
    !!post.metrics &&
    post.metrics.engagementLevel !== undefined;
  const tokenExpired =
    post &&
    scheduledDateTime &&
    authExpiryDateTime &&
    scheduledDateTime > authExpiryDateTime
      ? true
      : false;

  const handleDragStart: Parameters<typeof drag>[0]["onStart"] = ({
    initial,
  }) => {
    const top = initial.item.relativePosition
      ? initial.item.relativePosition.top
      : 0;
    const left = initial.item.relativePosition
      ? initial.item.relativePosition.left
      : 0;
    const [, scheduled] = getNewScheduled(top, left);
    springApi.start({
      x: initial.item.fixedPosition.left,
      y: initial.item.fixedPosition.top,
      opacity: 1,
      backgroundColor: BG_WHITE,
      scheduled,
      zIndex: 100,
      shadow: 15,
      position: "fixed",
      immediate: (k) => k !== "shadow" && k !== "opacity",
    });

    analyticsLogEvent({ event: "dragStartCalendarPost" });
  };

  const handleDragUpdate: Parameters<typeof drag>[0]["onUpdate"] = ({
    initial,
    current,
    state,
  }) => {
    const [moveX, moveY] = state.movement;
    // Clamp the movement to within the container.
    const newX = initial.item.fixedPosition.left + moveX;
    const newY = initial.item.fixedPosition.top + moveY;
    const clampedX = Math.min(Math.max(dragBounds.minX, newX), dragBounds.maxX);
    const clampedY = Math.min(Math.max(dragBounds.minY, newY), dragBounds.maxY);
    const top = current.item.relativePosition
      ? current.item.relativePosition.top
      : 0;
    const left = current.item.relativePosition
      ? current.item.relativePosition.left
      : 0;

    const [valid, scheduled] = getNewScheduled(top, left);

    springApi.start({
      x: clampedX,
      y: clampedY,
      opacity: 1,
      backgroundColor: valid ? BG_WHITE : BG_RED,
      scheduled,
      immediate: (k) =>
        k !== "shadow" && k !== "opacity" && k !== "backgroundColor",
    });
  };

  const handleDragEnd: Parameters<typeof drag>[0]["onEnd"] = () => {
    springApi.start({
      x: 0,
      y: 0,
      opacity: 0,
      backgroundColor: BG_WHITE,
      zIndex: 0,
      shadow: 1,
      position: "relative",
      immediate: (k) => k !== "shadow",
      delay: 100,
    });

    analyticsLogEvent({ event: "dragEndCalendarPost" });
  };

  // Build the bottom right footer content.
  let bottomRightFooterContent: ReactNode = (
    <div className="flex items-center">
      <ClockIcon className="h-3.5 w-3.5" />
      <span className="ml-1 text-xs">
        {scheduledDateTime.toFormat(DISPLAY_TIME_FORMAT)}
      </span>
    </div>
  );

  if (tokenExpired) {
    bottomRightFooterContent = (
      <InternalLink to="/socials" className="flex items-center text-red-400">
        <AlertTriangleIcon className="h-3.5 w-3.5" />
        <span className="ml-1 text-xs">Access needs refreshing</span>
      </InternalLink>
    );
  } else if (post.status === "ERROR") {
    bottomRightFooterContent = (
      <div className="flex items-center text-red-400">
        <AlertTriangleIcon className="h-3.5 w-3.5" />
        <span className="ml-1 text-xs">Post not sent</span>
      </div>
    );
  } else if (post.status === "WAITINGFORCONFIRMATION") {
    bottomRightFooterContent = (
      <div className="flex items-center text-amber-400">
        <CheckCircleIcon className="h-3.5 w-3.5" />
        <span className="ml-1 text-xs">
          Processing on {getPlatformTypeName(post.type)}
        </span>
      </div>
    );
  } else if (post.status === "POSTED") {
    bottomRightFooterContent = (
      <div className="text-green-500 flex items-center">
        <CheckCircleIcon className="h-3.5 w-3.5" />
        <span className="ml-1">
          {postedDateTime ? postedDateTime.toFormat(DISPLAY_TIME_FORMAT) : ""}
        </span>
      </div>
    );
  }

  // Build the bottom left footer content.
  let bottomLeftFooterContent: ReactNode = null;

  if (post.status === "POSTED") {
    if (post.type === "TWITTER") {
      const retweetCount = post.metrics ? post.metrics.retweetCount : 0;
      const favouriteCount = post.metrics ? post.metrics.likeCount : 0;
      bottomLeftFooterContent = (
        <div className="flex items-center">
          <span
            className={`flex items-center ${
              retweetCount > 0 ? "text-teal-500" : ""
            }`}
          >
            <RepeatIcon className="h-3.5 w-3.5" />
            <span className="ml-1">{retweetCount}</span>
          </span>
          <span
            className={`ml-1 flex items-center ${
              favouriteCount > 0 ? "text-pink-500" : ""
            }`}
          >
            <HeartIcon className="h-3.5 w-3.5" />
            <span className="ml-1">{favouriteCount}</span>
          </span>
        </div>
      );
    } else if (post.type === "FACEBOOK") {
      const likeCount = post.metrics ? post.metrics.reactionsTotal || 0 : 0;
      const commentCount = post.metrics ? post.metrics.commentCount || 0 : 0;
      bottomLeftFooterContent = (
        <div className="flex items-center">
          <span
            className={`flex items-center ${
              commentCount > 0 ? "text-sky-500" : ""
            }`}
          >
            <MessageSquareIcon className="h-3.5 w-3.5" />
            <span className="ml-1">{commentCount}</span>
          </span>
          <span
            className={`ml-1 flex items-center ${
              likeCount > 0 ? "text-pink-500" : ""
            }`}
          >
            <ThumbsUpIcon className="h-3.5 w-3.5" />
            <span className="ml-1">{likeCount}</span>
          </span>
        </div>
      );
    } else if (post.type === "LINKEDIN") {
      const likeCount = post.metrics ? post.metrics.likeCount || 0 : 0;
      const commentCount = post.metrics ? post.metrics.commentCount || 0 : 0;
      bottomLeftFooterContent = (
        <div className="flex items-center">
          <span
            className={`flex items-center ${
              commentCount > 0 ? "text-sky-500" : ""
            }`}
          >
            <MessageSquareIcon className="h-3.5 w-3.5" />
            <span className="ml-1">{commentCount}</span>
          </span>
          <span
            className={`ml-1 flex items-center ${
              likeCount > 0 ? "text-pink-500" : ""
            }`}
          >
            <ThumbsUpIcon className="h-3.5 w-3.5" />
            <span className="ml-1">{likeCount}</span>
          </span>
        </div>
      );
    }
  } else if (post.status === "DRAFT") {
    bottomLeftFooterContent = (
      <div className="flex items-center text-purple-400">
        <FlagIcon className="h-3.5 w-3.5" />
        <span className="ml-1 text-xs">Draft</span>
      </div>
    );
  }

  return (
    <animated.div
      ref={dragItemRef}
      className={`px-2 py-1 top-0 left-0 relative flex flex-col rounded-lg border border-gray-200 bg-white`}
      style={{
        width,
        height,
        zIndex: spring.zIndex,
        position: spring.position,
        boxShadow: spring.shadow.to(
          (s) =>
            `0 ${s}px ${3 * s}px 0 rgb(0 0 0 / 0.1), 0 ${s}px ${2 * s}px ${
              -1 * s
            }px rgb(0 0 0 / 0.1)`
        ),
        transform: to(
          [spring.x, spring.y],
          (x, y) => `translate3d(${x}px, ${y}px, 0)`
        ),
      }}
    >
      <div className="flex-shrink-0 flex items-center">
        <PlatformIcon
          className="flex-shrink-0 h-4 w-4"
          type={platform.type}
          colour={true}
        />
        <span className="ml-1 flex-grow font-bold truncate text-xs text-gray-500">
          {entity.name}
        </span>

        {canReschedule && (
          <TertiaryButton
            ref={drag({
              itemId,
              type: dragType,
              itemRef: dragItemRef,
              containerRef,
              onStart: handleDragStart,
              onUpdate: handleDragUpdate,
              onEnd: handleDragEnd,
            })}
            className="px-1 py-1 bg-transparent cursor-grab"
            size="xs"
          >
            <SelectorIcon className="h-5 w-5 mr-1 rotate-90" />
            Move
          </TertiaryButton>
        )}

        {hasEngagementLevel && (
          <PostMetricsEngagementIcon
            engagementLevel={post.metrics!.engagementLevel!}
            size="sm"
          />
        )}

        <DropdownMenu className="ml-1 flex-shrink-0" xAlign="right">
          <IconButton className="px-1 py-1 text-gray-400">
            <MoreHorizontalIcon className="h-5 w-5" />
          </IconButton>
          <div className="p-1">
            {post.status === "POSTED" ? (
              <DropdownMenuButton
                onClick={() => showModal("viewPost", { post, platform })}
              >
                <PresentationChartLineIcon className="h-5 w-5" />
                <span className="ml-2">Analytics</span>
              </DropdownMenuButton>
            ) : (
              <DropdownMenuButton
                disabled={!canEdit}
                onClick={() => showModal("editPost", { post, platform })}
              >
                <EditIcon className="h-5 w-5" />
                <span className="ml-2">Edit</span>
              </DropdownMenuButton>
            )}
            {canEditPublishedActions ? (
              <DropdownMenuButton
                onClick={() => showModal("editPublishedPostActions", { post })}
              >
                <PublishedPostActionIcon className="h-5 w-5" />
                <span className="ml-2">Edit after publish actions</span>
              </DropdownMenuButton>
            ) : post.hasPublishedPostActions ? (
              <DropdownMenuButton
                onClick={() => showModal("viewPublishedPostActions", { post })}
              >
                <PublishedPostActionIcon className="h-5 w-5" />
                <span className="ml-2">View after publish actions</span>
              </DropdownMenuButton>
            ) : null}
            {post.status === "POSTED" && (
              <DropdownMenuExternalLink
                className="font-normal flex items-center no-underline"
                href={externalUrl}
              >
                <ExternalLinkIcon className="h-5 w-5" />
                <span className="ml-2">
                  View on {getPlatformTypeName(platform.type)}
                </span>
              </DropdownMenuExternalLink>
            )}

            <DropdownMenuButton
              onClick={() =>
                showModal("createPost", {
                  defaultValues: {
                    contentState: convertFromRaw(post.text.contentState),
                    attachment: post.attachment,
                  },
                })
              }
            >
              <CopyIcon className="h-5 w-5" />
              <span className="ml-2">Duplicate</span>
            </DropdownMenuButton>
            <DropdownMenuButton
              onClick={() => showModal("deletePost", { post, platform })}
            >
              <TrashIcon className="h-5 w-5" />
              <span className="ml-2">Delete</span>
            </DropdownMenuButton>
          </div>
        </DropdownMenu>
      </div>
      <div
        className={`relative flex-grow flex flex-col ${
          isPosted ? "cursor-pointer" : ""
        }`}
        onClick={
          isPosted ? () => showModal("viewPost", { post, platform }) : undefined
        }
      >
        <div className="flex-grow">
          <p
            className="text-sm overflow-hidden break-words overflow-ellipsis whitespace-pre-wrap"
            style={{
              height: clampedTextAreaHeight,
              lineHeight: `${LINE_HEIGHT}px`,
              WebkitLineClamp: lineCount,
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
            }}
          >
            {post.text.plain}
          </p>
        </div>
        <div className="flex-shrink-0 flex items-center text-xs">
          <div className="mr-2 flex-shrink-0">{bottomLeftFooterContent}</div>
          <div className="ml-auto flex-shrink-0">
            {bottomRightFooterContent}
          </div>
        </div>
        {isPosted && (
          <div className="absolute inset-0 flex items-center justify-center bg-white default-transition opacity-0 hover:opacity-100">
            <div className="flex items-center">
              <PresentationChartLineIcon className="h-6 w-6" />
              <span className="ml-2">View analytics</span>
            </div>
          </div>
        )}
      </div>
      <animated.div
        className="p-4 absolute inset-0 rounded-lg text-center border border-gray-200 cursor-grabbing"
        style={{
          opacity: spring.opacity,
          display: spring.opacity.to((o) => (o > 0 ? "block" : "none")),
          backgroundColor: spring.backgroundColor,
        }}
      >
        <div>Rescheduling</div>
        <animated.div className="text-2xl">
          {spring.scheduled.to((s) =>
            DateTime.fromSeconds(s, { zone: timeZone }).toFormat(
              DISPLAY_TIME_FORMAT
            )
          )}
        </animated.div>
      </animated.div>
    </animated.div>
  );
};

export default CalendarPost;
