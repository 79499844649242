import React from "react";
import { Platform } from "../../../types/platforms";
import ProfilePicture from "../Profile";
import PlatformIcon from "../../icons/Platform";

interface PlatformProfilePictureProps {
  platform: Platform;
  className?: string;
  includeIcon?: boolean;
}

const PlatformProfilePicture: React.FC<PlatformProfilePictureProps> = ({
  platform,
  className = "",
  includeIcon = false,
}) => {
  const [firstName, lastName] = platform.name.split(" ");
  const pictureElement = (
    <ProfilePicture
      className={className}
      source={platform.pictureUrl}
      firstName={firstName}
      lastName={lastName}
    />
  );

  if (includeIcon) {
    return (
      <span className="flex-shrink-0 relative">
        {pictureElement}
        <span className="absolute -bottom-1.5 -right-1.5">
          <PlatformIcon
            className="h-6 w-6"
            type={platform.type}
            colour={true}
          />
        </span>
      </span>
    );
  } else {
    return pictureElement;
  }
};

export default PlatformProfilePicture;
