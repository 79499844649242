import React from "react";
import { useContentSwitch } from "../../../libs/hooks/general";
import { getPlatformTypeName } from "../../../libs/platform";
import {
  AddPlatformContent,
  BaseComponentProps,
} from "../../../types/modals/AddPlatform";

import H3 from "../../../components/headings/H3";
import PrimaryButton from "../../../components/buttons/Primary";
import PlatformIcon from "../../../components/icons/Platform";
import ExternalLink from "../../../components/links/External";

import { ReactComponent as ReaddedImage } from "../../../images/add-platform/readded.svg";

interface AlreadyExistsProps extends BaseComponentProps {}

const AddPlatformModalAlreadyExists: React.FC<AlreadyExistsProps> = () => {
  const { showContent, content } = useContentSwitch<
    AddPlatformContent,
    "alreadyExists"
  >("alreadyExists");
  const { platform } = content.data;
  const platformTypeName = getPlatformTypeName(platform.type);

  return (
    <div className="p-8" style={{ width: 600 }}>
      <div className="h-full w-full flex flex-col">
        <H3 className="flex-shrink-0 flex items-center">
          <PlatformIcon className="mr-2" type={platform.type} colour={true} />
          Connect {platformTypeName}
        </H3>
        <div className="relative flex-grow flex flex-col">
          <ReaddedImage className="mt-4 flex-shrink-0 w-full h-64 text-purple-500" />
          <div className="mt-4 text-center">
            <H3>
              Already added{" "}
              {platform.externalUrl ? (
                <ExternalLink href={platform.externalUrl}>
                  {platform.name}
                </ExternalLink>
              ) : (
                platform.name
              )}
            </H3>
          </div>
          <p className="mt-4">
            It looks like you are still be signed into an account that you've
            already added to Seenly.
          </p>
          <p className="mt-4">
            If you didn't mean to add <strong>{platform.name}</strong> again
            then please sign out of that account on{" "}
            <strong>{getPlatformTypeName(platform.type)}</strong> before
            retrying.
          </p>

          <PrimaryButton
            className="mt-8 flex-shrink-0 block w-full"
            onClick={() => showContent({ id: "home" })}
          >
            Add a different social
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AddPlatformModalAlreadyExists;
