import React from "react";

import { MetricPropertyNames } from "../../types/postAnalytics";
import { WorkspaceAnalytics } from "../../types/workspaceAnalytics";

import { POST_PREVIEW_WIDTH } from "../../libs/post";

import H3 from "../../components/headings/H3";
import DailySnapshotLoading from "../../components/analytics/DailySnapshotLoading";
import OverTimeLoading from "../../components/analytics/OverTimeLoading";
import InteractionsHeatMapLoading from "../../components/analytics/InteractionsHeatMapLoading";
import CommentsSentimentLoading from "../../components/analytics/CommentsSentimentLoading";

import OverviewLoading from "./body/OverviewLoading";
import DetailsLoading from "./body/DetailsLoading";
import TopPostsLoading from "./body/TopPostsLoading";

interface AnalyticsLoadingProps {
  metricPropertyNames: MetricPropertyNames;
  platformType: WorkspaceAnalytics["type"];
  className?: string;
}
const AnalyticsLoading: React.FC<AnalyticsLoadingProps> = ({
  metricPropertyNames,
  platformType,
  className = "",
}) => {
  return (
    <div className={className} style={{ maxWidth: "90rem" }}>
      <div className="mt-12 flex flex-wrap gap-4 lg:flex-nowrap">
        <div className="grow">
          <H3 className="text-normal uppercase text-gray-400">Overview</H3>
          <OverviewLoading
            className="mt-2"
            metricPropertyNames={metricPropertyNames}
          />
        </div>
        <div className="mt-12 lg:mt-0">
          <H3 className="text-normal uppercase text-gray-400">Details</H3>
          <DetailsLoading className="mt-2" />
        </div>
      </div>
      <H3 className="mt-12 text-normal uppercase text-gray-400">Over time</H3>

      <OverTimeLoading className="mt-2" />

      <div className="mt-12 flex flex-wrap lg:flex-nowrap">
        <div className="grow">
          <H3 className="text-normal uppercase text-gray-400">
            Daily snapshot
          </H3>

          <DailySnapshotLoading
            className="mt-2"
            metricPropertyNames={metricPropertyNames}
          />

          <H3 className="mt-12 text-normal uppercase text-gray-400">
            Interactions heat map
          </H3>

          <div className="mt-2" style={{ width: 0, minWidth: "100%" }}>
            <InteractionsHeatMapLoading />
          </div>

          <H3 className="mt-12 text-normal uppercase text-gray-400">
            Comments sentiment
          </H3>

          <CommentsSentimentLoading className="mt-2" />
        </div>
        <div
          className="mt-12 ml-0 shrink-0 lg:mt-0 lg:ml-12"
          style={{ width: POST_PREVIEW_WIDTH[platformType] }}
        >
          <H3 className="text-normal uppercase text-gray-400">Top posts</H3>
          <TopPostsLoading />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsLoading;
