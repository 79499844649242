import { nanoid } from "nanoid";
import config from "../../config";
import { openPlatformOAuthWindow } from "../auth";

export const FACEBOOK_REDIRECT_URI = `${window.location.origin}/auth/facebook`;
const SCOPES = [
  "pages_read_engagement",
  "pages_read_user_content",
  "pages_manage_posts",
  "pages_show_list",
  "public_profile",
  // "pages_manage_engagement",
  "business_management",
];
const BASE_PARAMS = {
  response_type: "code",
  display: "popup",
  client_id: config.facebook.APP_ID,
  scope: SCOPES.join(","),
  redirect_uri: FACEBOOK_REDIRECT_URI,
};
const BASE_URL = "https://www.facebook.com/v21.0/dialog/oauth";

export async function getFacebookAuthorisationCode(
  authType: "rerequest" | "reauthorize" = "rerequest"
) {
  const stateId = nanoid();
  const queryParams = new URLSearchParams({
    ...BASE_PARAMS,
    state: stateId,
    auth_type: authType,
  });
  const params = await openPlatformOAuthWindow(
    "FACEBOOK",
    `${BASE_URL}?${queryParams.toString()}`
  );

  if (params.has("error")) {
    throw new Error(
      "Unfortunately we connect to your Facebook without you granting us permission."
    );
  }

  if (
    !params.has("state") ||
    params.get("state") !== stateId ||
    !params.has("code")
  ) {
    throw new Error("Looks like something went wrong. Please try again.");
  }

  return params.get("code") as string;
}
