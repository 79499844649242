import React, {
  useEffect,
  useRef,
  useState,
  ReactNode,
  useCallback,
  CSSProperties,
} from "react";
import { createPortal } from "react-dom";
import { mergeClassNames } from "../libs/components";
import { useAnimatedMounting } from "../libs/hooks/general";
import { clamp } from "../libs/utils";

const DEFAULT_HOVER_TIMEOUT = 500;

interface TooltipProps {
  content: ReactNode;
  children: ReactNode;
  className?: string;
  tooltipClassName?: string;
  hoverTimeout?: number;
  disabled?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  className = "",
  tooltipClassName = "",
  hoverTimeout = DEFAULT_HOVER_TIMEOUT,
  disabled = false,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipCoords, setTooltipCoords] = useState<{
    left: CSSProperties["left"];
    top: CSSProperties["top"];
  }>({
    left: -9999999,
    top: -9999999,
  });
  const animate = useAnimatedMounting({
    mount: isOpen,
  });

  const handleOnMouseEnter = useCallback(() => {
    if (timeoutRef.current === null) {
      timeoutRef.current = window.setTimeout(() => {
        setIsOpen(true);
      }, hoverTimeout);
    }
  }, [hoverTimeout]);

  const handleOnMouseLeave = useCallback(() => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen && containerRef.current && tooltipRef.current) {
      const windowWidth = window.innerWidth;
      const containerBoundingBox = containerRef.current.getBoundingClientRect();
      const tooltipBoundingBox = tooltipRef.current.getBoundingClientRect();
      // Divide by 0.95 to account for scaling animation.
      const containerTop = containerBoundingBox.top;
      const containerLeft = containerBoundingBox.left;
      const containerHeight = containerBoundingBox.height;
      const containerWidth = containerBoundingBox.width;
      const tooltipHeight = tooltipBoundingBox.height / 0.95;
      const tooltipWidth = tooltipBoundingBox.width / 0.95;

      let newTop = containerTop - tooltipHeight;

      if (newTop < 0) {
        // There's enough room above the container to render the tooltip so we
        // have to render below it.
        newTop = containerTop + containerHeight;
      }

      const containerXMiddle = containerLeft + containerWidth / 2;
      // Centre it over the container unless it is going to render off screen.
      const newLeft = clamp(
        containerXMiddle - tooltipWidth / 2,
        0,
        windowWidth - tooltipWidth
      );

      setTooltipCoords({ top: newTop, left: newLeft });
    }
  }, [isOpen, content]);

  useEffect(() => {
    if (isOpen && disabled) {
      setIsOpen(false);
    }
  }, [isOpen, disabled]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  });

  return (
    <div
      ref={containerRef}
      className={mergeClassNames("relative", className)}
      onMouseEnter={disabled ? undefined : handleOnMouseEnter}
      onMouseLeave={disabled ? undefined : handleOnMouseLeave}
    >
      {children}
      {createPortal(
        animate(({ phase, animationEventHandlers }) => {
          const showContent = phase !== "unmounted";
          let animationClassName = "";

          switch (phase) {
            case "mounting":
              animationClassName = "animate-fade-up-in";
              break;
            case "unmounting":
              animationClassName = "animate-fade-down-out";
              break;
          }

          return showContent ? (
            <div
              ref={tooltipRef}
              className={mergeClassNames(
                `fixed z-20 p-2 rounded-lg shadow-lg bg-gray-600 text-white text-sm pointer-events-none ${animationClassName}`,
                tooltipClassName
              )}
              role="alert"
              style={tooltipCoords}
              {...animationEventHandlers}
            >
              {content}
            </div>
          ) : null;
        }),
        document.getElementById("portal-container") || document.body
      )}
    </div>
  );
};

export default Tooltip;
