import { DateTime } from "luxon";
import React from "react";
import { Subscription } from "../../types/subscriptions";

import SuccessBadge from "./Success";
import DangerBadge from "./Danger";
import NeutralBadge from "./Neutral";
import HighlightBadge from "./Highlight";

interface SubscriptionStatusBadgeProps {
  status: Subscription["status"];
  className?: string;
  periodEnd?: string;
  trialEnd?: string;
  timeZone?: string;
}
const SubscriptionStatusBadge: React.FC<SubscriptionStatusBadgeProps> = ({
  status,
  className = "",
  periodEnd,
  trialEnd,
  timeZone = "UTC",
}) => {
  const displayTrialEnd = trialEnd
    ? DateTime.fromISO(trialEnd).setZone(timeZone).toFormat("MMM d")
    : null;
  const displayPeriodEnd = periodEnd
    ? DateTime.fromISO(periodEnd).setZone(timeZone).toFormat("MMM d")
    : null;

  switch (status) {
    case "canceled":
      return <DangerBadge className={className}>Cancelled</DangerBadge>;
    case "incomplete":
      return <NeutralBadge className={className}>Incomplete</NeutralBadge>;
    case "incomplete_expired":
      return <NeutralBadge className={className}>Expired</NeutralBadge>;
    case "past_due":
      return <DangerBadge className={className}>Past due</DangerBadge>;
    case "trialing":
      return (
        <HighlightBadge className={className}>
          {displayTrialEnd ? `Trialing until ${displayTrialEnd}` : "Trialing"}
        </HighlightBadge>
      );
    case "unpaid":
      return <DangerBadge className={className}>Unpaid</DangerBadge>;
    default:
      return (
        <SuccessBadge className={className}>
          {displayPeriodEnd ? `Active until ${displayPeriodEnd}` : "Active"}
        </SuccessBadge>
      );
  }
};

export default SubscriptionStatusBadge;
