import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSubscription } from "../libs/hooks/subscriptions";
import { useActiveWorkspace } from "../libs/hooks/app";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import PlanSection from "../containers/subscription/sections/Plan";
import DetailsSection from "../containers/subscription/sections/Details";
import PaymentMethodSection from "../containers/subscription/sections/PaymentMethod";
import InvoicesSection from "../containers/subscription/sections/Invoices";
import DangerZoneSection from "../containers/subscription/sections/DangerZone";

import H2 from "../components/headings/H2";

const SubscriptionPage: React.FC = () => {
  const workspace = useActiveWorkspace();
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const { data: subscription } = useSubscription(subscriptionId || "", {
    enabled: !!subscriptionId,
  });
  const navigate = useNavigate();
  const isSubscriptionFromDifferentWorkspace = subscription
    ? subscription.workspaceId !== workspace.id
    : false;

  const requiresPaymentMethodBeforeTrialEnd =
    !!subscription &&
    subscription.status === "trialing" &&
    !subscription.paymentMethod;

  useEffect(() => {
    if (isSubscriptionFromDifferentWorkspace) {
      navigate("/subscriptions");
    }
  }, [isSubscriptionFromDifferentWorkspace, navigate]);

  return (
    <AppPageWithHeader>
      {!!subscription && (
        <div
          className="pt-4 pb-16 px-4 mx-auto flex flex-col justify-center"
          style={{ width: 800 }}
        >
          <PlanSection subscription={subscription} />

          <H2 className="mt-20">Details</H2>
          <DetailsSection className="mt-4" subscription={subscription} />

          <H2
            className={`mt-20 ${
              requiresPaymentMethodBeforeTrialEnd ? "text-red-500" : ""
            }`}
          >
            Payment method
          </H2>
          <PaymentMethodSection className="mt-4" subscription={subscription} />

          <H2 className="mt-20">Invoices</H2>
          <InvoicesSection className="mt-4" subscription={subscription} />

          <H2 className="mt-20">Danger zone</H2>
          <DangerZoneSection className="mt-4" subscription={subscription} />
        </div>
      )}
      {!subscriptionId && (
        <div
          className="pt-4 pb-16 px-4 mx-auto flex flex-col justify-center"
          style={{ width: 800 }}
        >
          <H2>Unknown subscription</H2>
        </div>
      )}
    </AppPageWithHeader>
  );
};

export default SubscriptionPage;
