import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface AppPageProps {
  children: ReactNode;
}
const AppPage: React.FC<AppPageProps> = ({ children }) => {
  return (
    <main className="flex flex-col h-screen w-screen overflow-hidden">
      <Helmet>
        <title>Seenly: Simple social</title>
      </Helmet>
      {children}
    </main>
  );
};

export default AppPage;
