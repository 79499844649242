import { nanoid } from "nanoid";
import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  ReactNode,
} from "react";
import { useDrag, Handler } from "@use-gesture/react";

import DragDropContext, {
  ItemPosition,
  DragRegisterProps,
  DropRegisterProps,
  HandleDropStartProps,
  OverDropTargets,
} from "../contexts/DragDrop";

interface DragRegistryEntry extends DragRegisterProps {
  dragElement: HTMLElement;
}

interface DragRegistry {
  [id: string]: DragRegistryEntry;
}

interface DropRegistryEntry extends DropRegisterProps {
  elem: HTMLElement;
}

interface DropRegistry {
  [id: string]: DropRegistryEntry;
}

interface MemoProp {
  dragArgs: any;
  dropArgs: {
    [dropId: string]: any;
  };
  initial: ItemPosition;
  itemElement: HTMLElement;
  registryEntry: DragRegistryEntry;
  dropTargets: DropRegistryEntry[];
  startScrollLeft: number;
  startScrollTop: number;
}

function calculateItemPosition(
  item: HTMLElement,
  container?: HTMLElement
): ItemPosition {
  const bounds = item.getBoundingClientRect();
  const containerBounds = container ? container.getBoundingClientRect() : null;

  return {
    item: {
      height: bounds.height,
      width: bounds.width,
      fixedPosition: {
        top: bounds.top,
        left: bounds.left,
        bottom: bounds.bottom,
        right: bounds.right,
      },
      relativePosition: containerBounds
        ? {
            top: bounds.top - containerBounds.top,
            left: bounds.left - containerBounds.left,
            bottom: bounds.bottom - containerBounds.bottom,
            right: bounds.right - containerBounds.right,
          }
        : undefined,
    },
    container: containerBounds
      ? {
          height: containerBounds.height,
          width: containerBounds.width,
          scrollTop: container ? container.scrollTop : 0,
          fixedPosition: {
            top: containerBounds.top,
            left: containerBounds.left,
            bottom: containerBounds.bottom,
            right: containerBounds.right,
          },
        }
      : undefined,
  };
}

function isOverDropTarget(
  [x, y]: [number, number],
  position: ItemPosition,
  target: DOMRect
) {
  const minX = target.left;
  const maxX = minX + target.width;
  const minY = target.top;
  const maxY = minY + target.height;
  const isOver = x >= minX && x <= maxX && y >= minY && y <= maxY;

  if (isOver) {
    const percentX = (x - minX) / target.width;
    const percentY = (y - minY) / target.height;
    return {
      isOver,
      percentXY: [percentX, percentY] as [number, number],
    };
  } else {
    return {
      isOver,
      percentXY: [0, 0] as [number, number],
    };
  }
}

function buildAdditionalDropProps(
  xy: [number, number],
  current: ItemPosition,
  dropTarget: DropRegistryEntry
): Pick<
  HandleDropStartProps,
  "isOver" | "dropContainerPosition" | "overPosition"
> {
  const dropTargetBounds = dropTarget.elem.getBoundingClientRect();
  const { isOver, percentXY } = isOverDropTarget(xy, current, dropTargetBounds);
  const itemOffsetTop = current.item.fixedPosition.top - dropTargetBounds.top;
  const itemOffsetLeft =
    current.item.fixedPosition.left - dropTargetBounds.left;
  const itemOffsetBottom =
    dropTargetBounds.bottom - current.item.fixedPosition.bottom;
  const itemOffsetRight =
    dropTargetBounds.right - current.item.fixedPosition.right;
  const containerOffsetTop = current.container
    ? current.container.fixedPosition.top - dropTargetBounds.top
    : 0;
  const containerOffsetLeft = current.container
    ? current.container.fixedPosition.left - dropTargetBounds.left
    : 0;
  const containerOffsetBottom = current.container
    ? current.container.fixedPosition.bottom - dropTargetBounds.bottom
    : 0;
  const containerOffsetRight = current.container
    ? current.container.fixedPosition.right - dropTargetBounds.right
    : 0;

  const overPosition = {
    item: {
      top: itemOffsetTop,
      left: itemOffsetLeft,
      bottom: itemOffsetBottom,
      right: itemOffsetRight,
    },
    percent: percentXY,
    container: current.container
      ? {
          top: containerOffsetTop,
          left: containerOffsetLeft,
          bottom: containerOffsetBottom,
          right: containerOffsetRight,
        }
      : undefined,
  };

  const dropContainerPosition = {
    height: dropTargetBounds.height,
    width: dropTargetBounds.width,
    fixedPosition: {
      top: dropTargetBounds.top,
      left: dropTargetBounds.left,
      bottom: dropTargetBounds.bottom,
      right: dropTargetBounds.right,
    },
  };

  return {
    isOver,
    overPosition,
    dropContainerPosition,
  };
}

function clearWindowSelection() {
  const selection = window.getSelection();
  if (selection) {
    selection.empty();
  }
}

interface DragDropProviderProps {
  children: ReactNode;
}
const DragDropProvider: React.FC<DragDropProviderProps> = ({ children }) => {
  const dragRegistryRef = useRef<DragRegistry>({});
  const dropRegistryRef = useRef<DropRegistry>({});

  useEffect(() => {
    return () => {
      dragRegistryRef.current = {};
      dropRegistryRef.current = {};
    };
  });

  const searchDragRegistryForElement = useCallback((element: HTMLElement) => {
    const candidates = [element];
    let parent: HTMLElement | null = element.parentElement;
    while (parent) {
      candidates.push(parent);
      parent = parent.parentElement;
    }

    const matchingId = Object.keys(dragRegistryRef.current).find((id) =>
      candidates.includes(dragRegistryRef.current[id].dragElement)
    );

    return matchingId ? dragRegistryRef.current[matchingId] : null;
  }, []);

  const searchDropRegistryForDropTargets = useCallback((type: string) => {
    const matchingIds = Object.keys(dropRegistryRef.current).filter(
      (id) =>
        !!dropRegistryRef.current[id].accept.find(
          (candidate) => candidate === type || candidate === "*"
        )
    );

    return matchingIds.map((id) => dropRegistryRef.current[id]);
  }, []);

  const handleDrag: Handler<"drag"> = useCallback(
    ({ event, down, first, last, memo, ...state }) => {
      if (!down && !first && !last) {
        return;
      }

      if (memo === null || !event) {
        return;
      }

      if (
        !memo &&
        (event.defaultPrevented ||
          event.metaKey ||
          event.ctrlKey ||
          event.altKey ||
          event.shiftKey) // Only allow left click
      ) {
        return null;
      }

      if (first) {
        const registryEntry = searchDragRegistryForElement(
          event.target as HTMLElement
        );

        if (registryEntry) {
          const dropTargets = searchDropRegistryForDropTargets(
            registryEntry.type
          );

          const itemElement =
            registryEntry.itemRef && registryEntry.itemRef.current
              ? registryEntry.itemRef.current
              : registryEntry.dragElement;

          const initial = calculateItemPosition(
            itemElement,
            registryEntry.containerRef && registryEntry.containerRef.current
              ? registryEntry.containerRef.current
              : undefined
          );

          const dropArgs: MemoProp["dropArgs"] = {};
          const overDropTargets: OverDropTargets = {};
          const dropProps = dropTargets.map((dropTarget) => {
            const dropHandlerProps = buildAdditionalDropProps(
              state.xy,
              initial,
              dropTarget
            );

            if (dropHandlerProps.isOver) {
              overDropTargets[dropTarget.dropId] = {
                overPosition: dropHandlerProps.overPosition,
                provided: dropTarget.provide,
              };
            }

            return dropHandlerProps;
          });

          const handlerProps = {
            itemId: registryEntry.itemId,
            type: registryEntry.type,
            itemElement,
            initial,
            current: initial,
            state,
            overDropTargets,
            scrollMove: [0, 0] as [number, number],
          };

          // The drag element handlers must be called before the drop element
          // handlers.
          const dragArgs = registryEntry.onStart
            ? registryEntry.onStart(handlerProps)
            : undefined;

          dropTargets.forEach((dropTarget, index) => {
            if (dropTarget.onStart) {
              const dropHandlerProps = dropProps[index];

              const args = dropTarget.onStart({
                ...handlerProps,
                ...dropHandlerProps,
              });

              dropArgs[dropTarget.dropId] = args;
            }
          });

          const scrollContainerElement =
            registryEntry.scrollContainerRef &&
            registryEntry.scrollContainerRef.current
              ? registryEntry.scrollContainerRef.current
              : null;
          const startScrollLeft = scrollContainerElement
            ? scrollContainerElement.scrollLeft
            : 0;
          const startScrollTop = scrollContainerElement
            ? scrollContainerElement.scrollTop
            : 0;

          const memoProp: MemoProp = {
            dragArgs,
            dropArgs,
            initial,
            itemElement,
            registryEntry,
            dropTargets,
            startScrollLeft,
            startScrollTop,
          };

          clearWindowSelection();
          return memoProp;
        } else {
          return null;
        }
      } else if (memo) {
        clearWindowSelection();
        const {
          dragArgs,
          dropArgs,
          initial,
          itemElement,
          registryEntry,
          dropTargets,
          startScrollLeft,
          startScrollTop,
        } = memo as MemoProp;

        const current = calculateItemPosition(
          itemElement,
          registryEntry.containerRef && registryEntry.containerRef.current
            ? registryEntry.containerRef.current
            : undefined
        );

        const overDropTargets: OverDropTargets = {};
        const dropProps = dropTargets.map((dropTarget) => {
          const dropHandlerProps = buildAdditionalDropProps(
            state.xy,
            current,
            dropTarget
          );

          if (dropHandlerProps.isOver) {
            overDropTargets[dropTarget.dropId] = {
              overPosition: dropHandlerProps.overPosition,
              provided: dropTarget.provide,
            };
          }

          return dropHandlerProps;
        });

        const scrollContainerElement =
          registryEntry.scrollContainerRef &&
          registryEntry.scrollContainerRef.current
            ? registryEntry.scrollContainerRef.current
            : null;
        const currentScrollLeft = scrollContainerElement
          ? scrollContainerElement.scrollLeft
          : 0;
        const currentScrollTop = scrollContainerElement
          ? scrollContainerElement.scrollTop
          : 0;
        const scrollXDiff = currentScrollLeft - startScrollLeft;
        const scrollYDiff = currentScrollTop - startScrollTop;

        const handlerProps = {
          itemId: registryEntry.itemId,
          type: registryEntry.type,
          itemElement,
          initial,
          current,
          state,
          overDropTargets,
          scrollMove: [scrollXDiff, scrollYDiff] as [number, number],
        };

        const dragProps = {
          ...handlerProps,
          args: dragArgs,
        };

        // The drag element handlers must be called before the drop element
        // handlers.
        if (last) {
          if (registryEntry.onEnd) {
            registryEntry.onEnd(dragProps);
          }
        } else {
          if (registryEntry.onUpdate) {
            registryEntry.onUpdate(dragProps);
          }
        }

        for (let index = 0; index < dropTargets.length; index++) {
          const dropTarget = dropTargets[index];
          const dropHandlerProps = dropProps[index];
          const args = dropArgs[dropTarget.dropId];

          if (last) {
            if (dropTarget.onDrop) {
              dropTarget.onDrop({
                ...handlerProps,
                ...dropHandlerProps,
                args,
              });
            }
          } else {
            if (dropTarget.onUpdate) {
              dropTarget.onUpdate({
                ...handlerProps,
                ...dropHandlerProps,
                args,
              });
            }
          }
        }
      }
    },
    [searchDragRegistryForElement, searchDropRegistryForDropTargets]
  );

  const dragBindings = useDrag(handleDrag, {
    delay: true,
    pointer: {
      // Only work on main (left) button click.
      buttons: [1],
      // Disable capturing pointer events because it breaks the
      // Grammarly editor click detection.
      capture: false,
    },
  });

  const drag = useCallback(
    ({
      itemId,
      type,
      itemRef,
      containerRef,
      scrollContainerRef,
      onStart,
      onUpdate,
      onEnd,
    }: DragRegisterProps) => {
      const registryId = nanoid();
      return (elem: HTMLElement | null) => {
        if (elem === null) {
          delete dragRegistryRef.current[registryId];
        } else {
          dragRegistryRef.current[registryId] = {
            dragElement: elem,
            itemId,
            type,
            itemRef,
            containerRef,
            scrollContainerRef,
            onStart,
            onUpdate,
            onEnd,
          };
        }

        return elem;
      };
    },
    []
  );

  const drop = useCallback(
    ({
      dropId,
      accept,
      provide,
      onStart,
      onUpdate,
      onDrop,
    }: DropRegisterProps) => {
      return (elem: HTMLElement | null) => {
        if (elem === null) {
          delete dropRegistryRef.current[dropId];
        } else {
          dropRegistryRef.current[dropId] = {
            dropId,
            elem,
            accept,
            provide,
            onStart,
            onUpdate,
            onDrop,
          };
        }

        return elem;
      };
    },
    []
  );

  const value = useMemo(() => {
    return { drag, drop };
  }, [drag, drop]);

  return (
    <DragDropContext.Provider value={value}>
      <div {...dragBindings()} style={{ touchAction: "none" }}>
        {children}
      </div>
    </DragDropContext.Provider>
  );
};

export default DragDropProvider;
