import React, { CSSProperties } from "react";
import { DateTime } from "luxon";
import {
  RefreshCw as RefreshCwIcon,
  Trash as TrashIcon,
  ExternalLink as ExternalLinkIcon,
  Plus as PlusIcon,
  AlertCircle as AlertCircleIcon,
} from "react-feather";

import { Platform } from "../../types/platforms";
import {
  useActiveWorkspace,
  useActiveWorkspaceDateTime,
  useActiveWorkspaceTimeZone,
  useShowModal,
} from "../../libs/hooks/app";
import {
  useAuthPlatform,
  useSyncPlatform,
  useUpdatePlatform,
} from "../../libs/hooks/platforms";
import {
  getPlatformTypeName,
  platformIsAuthExpired,
  platformRequiresAuth,
} from "../../libs/platform";
import { mergeClassNames } from "../../libs/components";
import { stripUrlProtocol } from "../../libs/utils";
import { canEnablePlatformEntities } from "../../libs/subscriptions";

import H4 from "../../components/headings/H4";
import H6 from "../../components/headings/H6";
import ExternalLink from "../../components/links/External";
import PlatformIcon from "../../components/icons/Platform";
import LoaderIcon from "../../components/icons/Loader";
import SecondaryButton from "../../components/buttons/Secondary";
import DangerButton from "../../components/buttons/Danger";
import PrimaryButton from "../../components/buttons/Primary";
import PlatformProfilePicture from "../../components/images/profile/Platform";
import PlatformEntityProfilePicture from "../../components/images/profile/PlatformEntity";
import Switch from "../../components/form/Switch";
import AlertPing from "../../components/animations/AlertCircle";
import Tooltip from "../../components/Tooltip";

interface PlatformCardProps {
  platform: Platform;
  className?: string;
  style?: CSSProperties;
}

const PlatformCard: React.FC<PlatformCardProps> = ({
  platform,
  className = "",
  style,
}) => {
  const showModal = useShowModal();
  const now = useActiveWorkspaceDateTime();
  const timeZone = useActiveWorkspaceTimeZone();
  const workspace = useActiveWorkspace();
  const { mutate: updatePlatform } = useUpdatePlatform();
  const { mutate: syncPlatform, isLoading: isSyncing } = useSyncPlatform();
  const {
    mutate: authPlatform,
    isLoading: isReauthenticating,
    errorAlert: authErrorAlert,
    loadingText: reauthenticatingLoadingText,
  } = useAuthPlatform();
  const shouldNotifyAboutAuthExpiry =
    !!platform.authExpiry && platformRequiresAuth(platform.authExpiry);
  const requiresAuth =
    !!platform.requiresUserAuth || shouldNotifyAboutAuthExpiry;
  const isAuthExpired =
    requiresAuth &&
    !!platform.authExpiry &&
    platformIsAuthExpired(platform.authExpiry!);
  const platformTypeName = getPlatformTypeName(platform.type);
  const entities = Object.values(platform.entities);
  entities.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });
  const lastSync = DateTime.fromISO(platform.lastSync).setZone(timeZone);
  const thirtyMinutesAgo = now.minus({ minutes: 30 });
  const canSync = lastSync < thirtyMinutesAgo;
  const canEnableDisabledEntity = canEnablePlatformEntities(workspace, 1);

  return (
    <div
      className={mergeClassNames(
        "p-4 shadow rounded-lg flex flex-col bg-white border border-gray-100",
        className
      )}
      style={style}
    >
      {requiresAuth && (
        <div className="pb-4 text-center">
          {authErrorAlert()}
          <div className="mt-2 flex items-center justify-center">
            <AlertPing
              className="bg-red-400 rounded-full"
              containerClassName="mr-2"
            >
              <AlertCircleIcon className="text-red-500 inline " />
            </AlertPing>
            {shouldNotifyAboutAuthExpiry ? (
              <p>
                Authorisation to share content using this account{" "}
                {isAuthExpired ? (
                  <strong>expired</strong>
                ) : (
                  <>
                    is <strong>expiring</strong>
                  </>
                )}{" "}
                on{" "}
                <strong>
                  {DateTime.fromISO(platform.authExpiry!)
                    .setZone(timeZone)
                    .toFormat("ff")}
                </strong>
              </p>
            ) : (
              <p className="text-left">
                Please reauthorise this account to grant Seenly the permissions
                it requires manage your content
              </p>
            )}
          </div>
          <div className="mt-4 flex items-center justify-center">
            <PrimaryButton
              onClick={() => authPlatform(platform)}
              disabled={isReauthenticating}
            >
              {reauthenticatingLoadingText({
                loading: `Reauthorising ${platform.name}`,
                default: `Reauthorise to continue managing content for ${platform.name}`,
              })}
            </PrimaryButton>
          </div>
        </div>
      )}

      <div
        className={`p-4 rounded-lg bg-gray-100 flex flex-col sm:flex-row ${
          requiresAuth ? "mt-4" : ""
        }`}
      >
        <div className="mt-4 sm:mt-0 pl-2 pr-10 flex-grow flex items-center justify-center">
          <PlatformProfilePicture
            className="flex-shrink-0 h-32 w-32"
            platform={platform}
          />
          <div className="ml-4">
            <H4 className="truncate">{platform.name}</H4>
            {platform.username && (
              <H6 className="truncate text-gray-400">{`@${platform.username}`}</H6>
            )}
          </div>
        </div>
        <div className="mt-4 sm:mt-0 p-4 flex-shrink-0 sm:w-64 rounded-lg bg-white">
          <div className="flex justify-center">
            <PlatformIcon
              className="h-6 w-6"
              type={platform.type}
              colour={true}
            />
            <H6 className="ml-2">{platformTypeName}</H6>
          </div>
          <div className="mt-4">
            <div className="flex items-center text-gray-400 text-xs">
              <PlusIcon className="flex-shrink-0 w-3 h-3" />
              <span className="ml-1 font-bold">CONNECTED</span>
            </div>
            <div className="truncate">
              {DateTime.fromISO(platform.created)
                .setZone(timeZone)
                .toFormat("ff")}
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center text-gray-400 text-xs">
              <RefreshCwIcon className="flex-shrink-0 w-3 h-3" />
              <span className="ml-1 font-bold">LAST SYNC</span>
            </div>
            <span className="truncate">
              {DateTime.fromISO(platform.lastSync)
                .setZone(timeZone)
                .toFormat("ff")}
            </span>
          </div>
          {platform.externalUrl && (
            <div className="mt-4">
              <div className="flex items-center text-gray-400 text-xs">
                <ExternalLinkIcon className="flex-shrink-0 w-3 h-3" />
                <span className="ml-1 font-bold">EXTERNAL LINK</span>
              </div>

              <ExternalLink
                className="font-normal"
                href={platform.externalUrl}
                title={platform.externalUrl}
              >
                {stripUrlProtocol(platform.externalUrl)}
              </ExternalLink>
            </div>
          )}
          <div className="mt-8 flex items-center justify-around">
            <Tooltip
              content={
                canSync ? (
                  <p className="w-64">{`Update Seenly with the latest data from your ${platform.name} ${platformTypeName} account`}</p>
                ) : (
                  <p className="w-64">{`Can only update data from your ${platform.name} ${platformTypeName} account once per hour`}</p>
                )
              }
            >
              <SecondaryButton
                size="sm"
                disabled={!canSync || isSyncing}
                onClick={() => syncPlatform(platform.id)}
              >
                {isSyncing ? (
                  <LoaderIcon className="w-4 h-4" />
                ) : (
                  <RefreshCwIcon className="w-4 h-4" />
                )}
                <span className="ml-1">Sync</span>
              </SecondaryButton>
            </Tooltip>
            <Tooltip
              content={
                <p className="w-64">{`Disconnect your ${platform.name} ${platformTypeName} account from Seenly`}</p>
              }
            >
              <DangerButton
                className="ml-2"
                size="sm"
                invert={true}
                onClick={() => showModal("deletePlatform", { platform })}
              >
                <TrashIcon className="w-4 h-4" />
                <span className="ml-1">Disconnect</span>
              </DangerButton>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="mt-6 px-2">
        <div className="flex items-center">
          <H6>Available socials</H6>
          <span className="ml-auto text-sm text-gray-400">
            Enabled in Seenly
          </span>
        </div>
        {entities.map((entity) => {
          const isEntityDisabled = !!entity.disabled;
          const disabledToggle = isEntityDisabled && !canEnableDisabledEntity;
          const tooltipContent = disabledToggle
            ? "Upgrade to enable more socials"
            : isEntityDisabled
            ? "Not available when creating content"
            : "Available when creating content";
          return (
            <div key={entity.id} className="mt-6 flex items-center">
              <PlatformEntityProfilePicture
                className="flex-shrink-0 h-12 w-12"
                entity={entity}
              />
              <div className="ml-4 flex-grow flex flex-col">
                <div className="flex items-center truncate">
                  <span className="truncate">{entity.name}</span>
                  {entity.username && (
                    <span className="ml-2 truncate text-xs text-gray-400">{`@${entity.username}`}</span>
                  )}
                </div>
                {entity.externalUrl && (
                  <ExternalLink
                    className="text-xs text-gray-400 font-normal"
                    href={entity.externalUrl}
                    title={entity.externalUrl}
                    style={{
                      textDecorationThickness: 1,
                    }}
                  >
                    {stripUrlProtocol(entity.externalUrl)}
                  </ExternalLink>
                )}
              </div>
              <div className="flex-shrink-0 w-20">
                <Tooltip className="inline-block" content={tooltipContent}>
                  <Switch
                    checked={!isEntityDisabled}
                    disabled={disabledToggle}
                    onChange={(e) => {
                      updatePlatform({
                        platform,
                        updateProps: {
                          entities: {
                            [entity.id]: {
                              disabled: !e.target.checked,
                            },
                          },
                        },
                        optimistic: true,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PlatformCard;
