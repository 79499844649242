import React, { useState, ReactNode } from "react";
import { useEffect } from "react";
import ContentSwitchContext from "../contexts/ContentSwitch";
import { usePrevious } from "../libs/hooks/general";

interface ContentSwitchContextProviderProps {
  defaultContentId?: string;
  children: ReactNode;
}
const ContentSwitchContextProvider: React.FC<
  ContentSwitchContextProviderProps
> = ({ defaultContentId = "home", children }) => {
  const previousDefaultContentId = usePrevious(defaultContentId);
  const [value, setValue] = useState<
    React.ContextType<typeof ContentSwitchContext>
  >({
    prevContent: null,
    content: { id: defaultContentId, immediate: true },
    showContent: (newContent) => {
      setValue((prevValue) => ({
        ...prevValue,
        prevContent: prevValue.content,
        content: newContent,
      }));
    },
  });

  useEffect(() => {
    if (defaultContentId !== previousDefaultContentId) {
      setValue((prevValue) => {
        if (prevValue.content.id === previousDefaultContentId) {
          return {
            ...prevValue,
            content: { id: defaultContentId, immediate: true },
          };
        } else {
          return prevValue;
        }
      });
    }
  }, [defaultContentId, previousDefaultContentId]);

  return (
    <ContentSwitchContext.Provider value={value}>
      {children}
    </ContentSwitchContext.Provider>
  );
};

export default ContentSwitchContextProvider;
