import { openPlatformOAuthWindow } from "../auth";

export const TWITTER_REDIRECT_URI = `${window.location.origin}/auth/twitter`;

const BASE_URL = "https://api.twitter.com/oauth/authorize";

export async function getTwitterVerifier(token: string) {
  const queryParams = new URLSearchParams({
    oauth_token: token,
  });
  const params = await openPlatformOAuthWindow(
    "TWITTER",
    `${BASE_URL}?${queryParams.toString()}`
  );

  if (!params.has("oauth_token") || !params.has("oauth_verifier")) {
    throw new Error("Looks like something went wrong. Please try again.");
  }

  const returnedToken = params.get("oauth_token") as string;

  if (returnedToken !== token) {
    throw new Error("Unknown reference returned from Twitter");
  }

  return params.get("oauth_verifier") as string;
}
