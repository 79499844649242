import {
  ContentBlock,
  ContentState,
  DraftDecorator,
  EditorState,
  EntityInstance,
  Modifier,
  SelectionState,
} from "draft-js";
import { getWordBoundsAtIndex } from "./utils";
import {
  HashtagDraftEntity,
  LinkDraftEntity,
  MentionDraftEntity,
} from "../types/posts";
import equal from "fast-deep-equal/es6";

export const ENTITY_TYPES = {
  HASHTAG: "HASHTAG",
  LINK: "LINK",
  MENTION: "MENTION",
  PLACEHOLDER: "PLACEHOLDER",
} as const;

export type EntityTypes = typeof ENTITY_TYPES;

export type EntityType = EntityTypes[keyof EntityTypes];

export interface HashtagEntityInstance extends EntityInstance {
  getType: () => HashtagDraftEntity["type"];
  getData: () => HashtagDraftEntity["data"];
}

export interface LinkEntityInstance extends EntityInstance {
  getType: () => LinkDraftEntity["type"];
  getData: () => LinkDraftEntity["data"];
}

export interface MentionEntityInstance extends EntityInstance {
  getType: () => MentionDraftEntity["type"];
  getData: () => MentionDraftEntity["data"];
}

export type EntityInstanceForType<T extends string> =
  T extends EntityTypes["HASHTAG"]
    ? HashtagEntityInstance
    : T extends EntityTypes["LINK"]
    ? LinkEntityInstance
    : T extends EntityTypes["MENTION"]
    ? MentionEntityInstance
    : EntityInstance;

export type EntityInstanceMapValue<T extends EntityType = EntityType> = {
  entityKey: string;
  blockKey: string;
  start: number;
  end: number;
  entity: EntityInstanceForType<T>;
};

export type EntityInstanceMap<T extends EntityType> = {
  [entityKey: string]: EntityInstanceMapValue<T>;
};

export type EntityInstanceByTypeMap = {
  HASHTAG: EntityInstanceMap<EntityTypes["HASHTAG"]>;
  LINK: EntityInstanceMap<EntityTypes["LINK"]>;
  MENTION: EntityInstanceMap<EntityTypes["MENTION"]>;
  PLACEHOLDER: EntityInstanceMap<EntityTypes["PLACEHOLDER"]>;
};

export interface DraftDecoratorComponentProps {
  blockKey: string;
  children?: React.ReactNode;
  contentState: ContentState;
  decoratedText: string;
  end: number;
  entityKey?: string;
  offsetKey: string;
  start: number;
}

export type BuildDecorators<Props> = (props: Props) => DraftDecorator[];

export type OnEditorStateChange = (
  newEditorState: EditorState,
  beforeSelectionState: SelectionState,
  afterSelectionState: SelectionState
) => EditorState;

export type Build<Props> = (props: Props) => {
  type: string;
  decorators: DraftDecorator[];
};

export interface CurrentCursorProps<T extends EntityType> {
  contentBlock: ContentBlock;
  selection: SelectionState;
  existingEntity: EntityInstanceForType<T> | null;
  existingEntityKey: string | null;
}

export type GetEntityRangeFunction<T extends EntityType> = (
  params: CurrentCursorProps<T>
) => [number, number] | [null, null];

export type BuildEntityDataFunction<T extends EntityType> = (
  params: CurrentCursorProps<T> & { entityStart: number; entityEnd: number }
) => ReturnType<EntityInstanceForType<T>["getData"]>;

export function getEntityAtSelection(
  contentState: ContentState,
  selection: SelectionState
): [EntityInstance | null, string | null] {
  if (!selection.isCollapsed()) {
    // If the selection is across multiple characters, i.e. it's not
    // a caret, then just return.
    return [null, null];
  }

  const cursorIndex = selection.getAnchorOffset();
  const blockKey = selection.getAnchorKey();
  const contentBlock = contentState.getBlockForKey(blockKey);
  const blockText = contentBlock.getText();
  const [start] = getWordBoundsAtIndex(blockText, cursorIndex);
  const entityKey = start === null ? null : contentBlock.getEntityAt(start);
  const entity = entityKey ? contentState.getEntity(entityKey) : null;
  return [entity, entityKey];
}

export function getEntityAtCurrentCursor(editorState: EditorState) {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  return getEntityAtSelection(contentState, selection);
}

export function getEntitiesByTypeFromContentBlock(
  contentState: ContentState,
  contentBlock: ContentBlock
) {
  const entitiesByType: EntityInstanceByTypeMap = {
    HASHTAG: {},
    LINK: {},
    MENTION: {},
    PLACEHOLDER: {},
  };

  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();

      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        const entityType = entity.getType() as EntityType;

        if (entity && entityType && entitiesByType[entityType]) {
          return true;
        }
      }

      return false;
    },
    (start, end) => {
      const entityKey = contentBlock.getEntityAt(start);

      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        const entityType = entity.getType() as EntityType;

        entitiesByType[entityType][entityKey] = {
          entityKey,
          blockKey: contentBlock.getKey(),
          start,
          end,
          entity: entity as any,
        };
      }
    }
  );

  return entitiesByType;
}

export function getEntitiesFromContentBlock<T extends EntityType>(
  contentState: ContentState,
  contentBlock: ContentBlock,
  entityType: T
) {
  const entitiesByType = getEntitiesByTypeFromContentBlock(
    contentState,
    contentBlock
  );

  return entitiesByType[entityType] || {};
}

export function getEntitiesByTypeFromContentState(contentState: ContentState) {
  const entitiesByType: EntityInstanceByTypeMap = {
    HASHTAG: {},
    LINK: {},
    MENTION: {},
    PLACEHOLDER: {},
  };

  contentState.getBlockMap().forEach((block) => {
    if (block) {
      const blockEntitiesByType = getEntitiesByTypeFromContentBlock(
        contentState,
        block
      );

      for (const [key, values] of Object.entries(blockEntitiesByType)) {
        const type = key as keyof EntityInstanceByTypeMap;

        if (entitiesByType[type]) {
          entitiesByType[type] = {
            ...entitiesByType[type],
            ...(values as any),
          };
        }
      }
    }
  });

  return entitiesByType;
}

export function findFirstInstanceOfText(
  text: string,
  contentState: ContentState
) {
  for (const block of contentState.getBlockMap().toArray()) {
    const index = block.getText().indexOf(text);

    if (index >= 0) {
      const entityKey = block.getEntityAt(index);
      const entity = entityKey ? contentState.getEntity(entityKey) : null;

      return {
        entityKey: entityKey || null,
        blockKey: block.getKey(),
        start: index,
        end: index + text.length,
        entity: entity,
      };
    }
  }

  return null;
}

export function getEntitySelection(
  contentState: ContentState,
  blockKey: string,
  entityKey: string
): SelectionState | null {
  const block = contentState.getBlockForKey(blockKey);

  if (!block) {
    return null;
  }

  let entitySelection: SelectionState | null = null;

  block.findEntityRanges(
    (character) => {
      const entityKeyAtCharacter = character.getEntity();
      return entityKeyAtCharacter === entityKey;
    },
    (start, end) => {
      if (entitySelection === null) {
        entitySelection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end,
        });
      }
    }
  );

  return entitySelection;
}

export function replaceEntityDisplayText(
  blockKey: string,
  entityKey: string,
  contentState: ContentState,
  text: string
) {
  // Find the entity in the block so that we can make sure we
  // get the correct start and end values. Each time the entity
  // text is replaced with a new value that is a different length
  // it will change the offsets for the other entities in the block.
  // So this allows us to call this function multiple consecutive times
  // while letting Draft.js handle adjusting the other entities for us.
  const entitySelection = getEntitySelection(contentState, blockKey, entityKey);

  if (!entitySelection) {
    return contentState;
  }

  return Modifier.replaceText(
    contentState,
    entitySelection,
    text,
    undefined,
    entityKey
  );
}

export function removeTextFromBlock(
  editorState: EditorState,
  blockKey: string,
  start: number,
  end: number
): EditorState {
  const contentState = editorState.getCurrentContent();
  const contentBlock = contentState.getBlockForKey(blockKey);

  if (!contentBlock) {
    return editorState;
  }

  const rangeSelection = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: start,
    focusOffset: end,
  });

  // Remove any entities in the range.
  let newContentState = Modifier.applyEntity(
    contentState,
    rangeSelection,
    null
  );

  // Delete the placeholder text.
  newContentState = Modifier.replaceText(contentState, rangeSelection, "");

  let newEditorState = EditorState.push(
    editorState,
    newContentState,
    "remove-range"
  );

  // Set the cursor to the start of the deleted text.
  return EditorState.forceSelection(
    newEditorState,
    rangeSelection.merge({
      anchorOffset: start,
      focusOffset: start,
    })
  );
}

export function appendEntityToEndOfContent<T extends EntityType>(
  contentState: ContentState,
  entityType: T,
  entityData: ReturnType<EntityInstanceForType<T>["getData"]>,
  text: string
) {
  let newContentState = contentState.createEntity(
    entityType,
    "MUTABLE",
    entityData
  );
  const entityKey = newContentState.getLastCreatedEntityKey();
  const lastBlock = newContentState.getLastBlock();
  let endOfContentSelection = SelectionState.createEmpty(
    lastBlock.getKey()
  ).merge({
    anchorOffset: lastBlock.getLength(),
    focusOffset: lastBlock.getLength(),
  });

  if (lastBlock.getLength() > 0 && !/\s$/.test(lastBlock.getText())) {
    // If there is text add a space at the end before appending the entity
    // text.
    newContentState = Modifier.insertText(
      newContentState,
      endOfContentSelection,
      " "
    );
    endOfContentSelection = endOfContentSelection.merge({
      anchorOffset: endOfContentSelection.getAnchorOffset() + 1,
      focusOffset: endOfContentSelection.getFocusOffset() + 1,
    });
  }

  // Insert the text at the end and apply the entity to it
  return Modifier.insertText(
    newContentState,
    endOfContentSelection,
    text,
    undefined,
    entityKey
  );
}

export function createEntityInBlockAtLocation<T extends EntityType>(
  contentState: ContentState,
  blockKey: string,
  start: number,
  end: number,
  entityType: T,
  entityData: ReturnType<EntityInstanceForType<T>["getData"]>
) {
  let newContentState = contentState.createEntity(
    entityType,
    "MUTABLE",
    entityData
  );
  const entityKey = newContentState.getLastCreatedEntityKey();
  const entitySelection = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: start,
    focusOffset: end,
  });

  return Modifier.applyEntity(newContentState, entitySelection, entityKey);
}

type BuildEntityData<T extends EntityType> = (
  indicesIndex: number,
  existingEntity: EntityInstanceForType<T> | null
) => ReturnType<EntityInstanceForType<T>["getData"]> | null;
export function setEntitiesInBlock<T extends EntityType>(
  contentState: ContentState,
  contentBlock: ContentBlock,
  entityType: T,
  entityIndices: {
    start: number;
    end: number;
  }[],
  buildDataFn: BuildEntityData<T>
) {
  let newContentState = contentState;

  const existingEntitiesByEntityKey = getEntitiesFromContentBlock(
    contentState,
    contentBlock,
    entityType
  );
  const existingEntities = Object.values(
    existingEntitiesByEntityKey
  ) as EntityInstanceMapValue<T>[];

  const combinedEntities: Array<{
    indices: {
      start: number;
      end: number;
    };
    existing: EntityInstanceMapValue<T> | null;
    data: ReturnType<EntityInstanceForType<T>["getData"]> | null;
  }> = entityIndices.map((indices, index) => {
    const existingIndex = existingEntities.findIndex(
      (candidate) => candidate.start === indices.start
    );

    if (existingIndex >= 0) {
      // Remove it from the array since it's been matched.
      const [existingEntity] = existingEntities.splice(existingIndex, 1);
      return {
        indices,
        existing: existingEntity,
        data: buildDataFn(index, existingEntity.entity),
      };
    } else {
      return {
        indices,
        existing: null,
        data: buildDataFn(index, null),
      };
    }
  });

  // Any leftover existing entities which aren't immutable have
  // now been deleted because they no longer match the criteria
  // for this entity type.
  existingEntities.forEach((existingEntity) => {
    if (existingEntity.entity.getMutability() !== "IMMUTABLE") {
      combinedEntities.push({
        indices: {
          start: existingEntity.start,
          end: existingEntity.end,
        },
        existing: existingEntity,
        data: null,
      });
    }
  });

  combinedEntities.forEach(({ indices, existing, data }) => {
    if (data && !existing) {
      // Create a new entity.
      newContentState = newContentState.createEntity(
        entityType,
        "MUTABLE",
        data
      );
      const entityKey = newContentState.getLastCreatedEntityKey();
      const entitySelection = SelectionState.createEmpty(
        contentBlock.getKey()
      ).merge({
        anchorOffset: indices.start,
        focusOffset: indices.end,
      });

      newContentState = Modifier.applyEntity(
        newContentState,
        entitySelection,
        entityKey
      );
    } else if (data && existing) {
      // Update the existing entity if anything has changed.
      if (indices.end !== existing.end) {
        // The entity text has been changed.
        const entitySelection = SelectionState.createEmpty(
          contentBlock.getKey()
        ).merge({
          anchorOffset: indices.start,
          focusOffset: indices.end,
        });

        newContentState = Modifier.applyEntity(
          newContentState,
          entitySelection,
          existing.entityKey
        );
      }

      if (!equal(data, existing.entity.getData())) {
        // The data has changed, use the newest version.
        newContentState = newContentState.replaceEntityData(
          existing.entityKey,
          data
        );
      }
    } else if (!data && existing) {
      // Delete the existing entity.
      const entitySelection = SelectionState.createEmpty(
        contentBlock.getKey()
      ).merge({
        anchorOffset: indices.start,
        focusOffset: indices.end,
      });

      newContentState = Modifier.applyEntity(
        newContentState,
        entitySelection,
        null
      );
    }
  });

  return newContentState;
}

export function getEntityRangesFromBlock(contentBlock: ContentBlock) {
  const entityRanges: { [key: string]: { start: number; end: number } } = {};

  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return !!entityKey;
    },
    (start, end) => {
      const entityKey = contentBlock.getEntityAt(start);

      if (entityKey) {
        entityRanges[entityKey] = { start, end };
      }
    }
  );

  return entityRanges;
}

export function getEndOfEntitySelection(
  contentState: ContentState,
  blockKey: string,
  entityKey: string
) {
  const contentBlock = contentState.getBlockForKey(blockKey);

  if (!contentBlock) {
    return null;
  }

  const entityRanges = getEntityRangesFromBlock(contentBlock);

  if (!entityRanges[entityKey]) {
    return null;
  }

  const { end } = entityRanges[entityKey];

  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset: end,
    focusOffset: end,
  });
}

export function getBlockPlainTextOffset(
  contentState: ContentState,
  blockKey: string
) {
  let offset = 0;
  let currentBlockKey = blockKey;
  let prevBlock: ContentBlock | undefined = undefined;

  while ((prevBlock = contentState.getBlockBefore(currentBlockKey))) {
    offset += prevBlock.getText().length + 1; // Add +1 for the \n character.
    currentBlockKey = prevBlock.getKey();
  }

  return offset;
}

export function getContentBlocksBetweenSelections(
  contentState: ContentState,
  selections: SelectionState[]
) {
  const contentBlockKeys: string[] = [];
  selections
    .reduce<string[]>((carry, selection) => {
      carry.push(selection.getAnchorKey());
      carry.push(selection.getFocusKey());
      return carry;
    }, [])
    .forEach((blockKey) => {
      if (
        !!contentState.getBlockForKey(blockKey) &&
        !contentBlockKeys.includes(blockKey)
      ) {
        contentBlockKeys.push(blockKey);
      }
    });

  const allContentBlocks = contentState.getBlocksAsArray();
  const selectionContentBlocks: ContentBlock[] = [];

  for (
    let i = 0;
    i < allContentBlocks.length && contentBlockKeys.length > 0;
    i++
  ) {
    const block = allContentBlocks[i];
    const blockKeysIndex = contentBlockKeys.findIndex(
      (candidate) => block.getKey() === candidate
    );

    if (blockKeysIndex >= 0) {
      contentBlockKeys.splice(blockKeysIndex, 1);
    }

    if (selectionContentBlocks.length > 0 || blockKeysIndex >= 0) {
      selectionContentBlocks.push(block);
    }
  }

  return selectionContentBlocks;
}

type ApplyFunction = (
  contentState: ContentState,
  contentBlock: ContentBlock
) => ContentState;
export function applyEntitiesToContentBlocksBetweenSelections(
  editorState: EditorState,
  selections: SelectionState[],
  applyFn: ApplyFunction
) {
  const contentState = editorState.getCurrentContent();
  const contentBlocks = getContentBlocksBetweenSelections(
    contentState,
    selections
  );

  let newContentState = contentState;

  contentBlocks.forEach((contentBlock) => {
    newContentState = applyFn(newContentState, contentBlock);
  });

  if (newContentState !== contentState) {
    return EditorState.set(editorState, {
      currentContent: newContentState,
    });
  } else {
    return editorState;
  }
}
