import Axios from "axios";
import { Auth } from "@aws-amplify/auth";
import { useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { User } from "../../types/users";
import { ApiResponse } from "../../types/general";

import { useDecoratedReactQuery } from "./general";
import config from "../../config";
import { useApiClient } from "./app";
import {
  fetchCurrentUser,
  purgeCurrentUser,
  saveCurrentUser,
} from "../localStorage";
import { USERS_QUERY_KEYS } from "../queryKeys";

export const useLoadCurrentUser = () => {
  const cacheRef = useRef<{ user: User | null; isEmailVerified: boolean }>({
    user: null,
    isEmailVerified: true,
  });

  return useQuery(
    USERS_QUERY_KEYS.detail("currentUser"),
    async () => {
      try {
        const [session, authUser] = await Promise.all([
          Auth.currentSession(),
          Auth.currentAuthenticatedUser(),
        ]);

        if (!session || !authUser) {
          return { user: null, isEmailVerified: false };
        }

        const { attributes } = authUser;
        const isEmailVerified = !!attributes.email_verified;

        try {
          const { data: response } = await Axios.get<ApiResponse<User>>(
            `${config.apiGateway.URL}/users/${attributes.sub}`,
            {
              headers: {
                Authorization: session.getIdToken().getJwtToken(),
              },
            }
          );
          return {
            user: response.data,
            isEmailVerified,
          };
        } catch (e: any) {
          if (
            e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.code === "001"
          ) {
            // The user has a valid session but the backend can't find a user record in
            // the database which means we need to finish up their signup process and
            // create a user.
            const { data: response } = await Axios.post<ApiResponse<User>>(
              `${config.apiGateway.URL}/users/`,
              {
                email: attributes.email,
                firstName: attributes.given_name,
                lastName: attributes.family_name || undefined,
                timeZone: attributes.zoneinfo,
              },
              {
                headers: {
                  Authorization: session.getIdToken().getJwtToken(),
                },
              }
            );

            return {
              user: response.data,
              isEmailVerified,
            };
          }
        }
      } catch (e) {}

      return { user: null, isEmailVerified: false };
    },
    {
      placeholderData: () => {
        const user = fetchCurrentUser();

        if (
          (user &&
            (!cacheRef.current ||
              !cacheRef.current.user ||
              cacheRef.current.user.id !== user.id)) ||
          (!user && cacheRef.current && !!cacheRef.current.user)
        ) {
          cacheRef.current = {
            user,
            isEmailVerified: true,
          };
        }

        return cacheRef.current;
      },
      onSuccess: ({ user, isEmailVerified }) => {
        if (user) {
          saveCurrentUser(user);
          cacheRef.current = {
            user,
            isEmailVerified,
          };
        } else {
          purgeCurrentUser();
        }
      },
    }
  );
};

interface CreateUserProps {
  email: string;
  firstName: string;
  lastName?: string;
  timeZone: string;
}
export const useCreateUser = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (props: CreateUserProps) => {
        const { data: response } = await apiClient.post<ApiResponse<User>>(
          "/users",
          props
        );
        return response.data;
      },
      {
        onSuccess: (user) => {
          saveCurrentUser(user);
          queryClient.setQueryData(USERS_QUERY_KEYS.detail("currentUser"), {
            user,
            isEmailVerified: true,
          });

          // TODO: Modify the users object to add the workspace to those values.
        },
      }
    )
  );
};

export const useDeleteUser = () => {
  const apiClient = useApiClient();
  return useDecoratedReactQuery(
    useMutation(async (user: User) => {
      const { data: response } = await apiClient.delete<ApiResponse<null>>(
        `/users/${user.id}`
      );
      return response.data;
    })
  );
};

interface UpdateUserProps {
  user: User;
  updateProps: {
    email?: string;
    firstName?: string;
    lastName?: string | null;
    profileImageFileId?: string | null;
    timeZone?: string;
  };
}
export const useUpdateUser = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ user, updateProps }: UpdateUserProps) => {
        const { data: response } = await apiClient.patch<ApiResponse<User>>(
          `/users/${user.id}`,
          updateProps
        );

        if (updateProps.email) {
          // Force a re-fetch from cognito.
          await Auth.currentAuthenticatedUser({ bypassCache: true });
        }

        return response.data;
      },
      {
        onSuccess: (user, { updateProps }) => {
          const isEmailVerified = !updateProps.email;
          saveCurrentUser(user);
          queryClient.setQueryData(USERS_QUERY_KEYS.detail("currentUser"), {
            user,
            isEmailVerified,
          });

          // TODO: Modify the users object to add the workspace to those values.
        },
      }
    )
  );
};

export const useVerifyUserEmail = () => {
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (verificationCode: string) => {
        await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode);
        // Force a re-fetch from cognito.
        const { attributes } = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        const isEmailVerified = !!attributes.email_verified;

        return isEmailVerified;
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(USERS_QUERY_KEYS.detail("currentUser"));
        },
      }
    )
  );
};

export const useResendUserEmailVerificationCode = () => {
  return useDecoratedReactQuery(
    useMutation(async () => {
      await Auth.verifyCurrentUserAttribute("email");
      return true;
    })
  );
};

interface ChangePasswordProps {
  currentPassword: string;
  newPassword: string;
}
export const useChangePassword = () => {
  return useDecoratedReactQuery(
    useMutation(
      async ({ currentPassword, newPassword }: ChangePasswordProps) => {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, currentPassword, newPassword);
        return true;
      }
    ),
    (err: any) => {
      if (err.code === "NotAuthorizedException") {
        return {
          title: "Current password incorrect",
          message:
            "That doesn't seem to match your current password. Please try again.",
        };
      }

      return {};
    }
  );
};
