import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ApiResponse } from "../../types/general";
import { PostQueue } from "../../types/postQueues";
import {
  optimisticallyUpdateQueryCacheObject,
  updateQueryCacheObject,
} from "../queryCache";
import { POSTS_QUERY_KEYS, POST_QUEUES_QUERY_KEYS } from "../queryKeys";
import { patchObject } from "../utils";

import { useApiClient } from "./app";
import { useDecoratedReactQuery } from "./general";

export const usePostQueues = (
  workspaceId: string,
  options: { refetchInterval?: number | false } = {}
) => {
  const apiClient = useApiClient();
  return useQuery(
    POST_QUEUES_QUERY_KEYS.list({ workspaceId }),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<PostQueue[]>>(
        "/post_queues",
        {
          params: {
            workspaceId,
          },
        }
      );

      return response.data;
    },
    options
  );
};

export interface UpdatePostQueueProps {
  postQueue: PostQueue;
  updateProps: {
    hidden?: boolean | null;
    postsOrder?: string[] | null;
    status?: "ACTIVE" | "PAUSED";
    postFrequency?: PostQueue["postFrequency"] | null;
  };
  optimistic?: boolean;
}
export const useUpdatePostQueue = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ postQueue, updateProps }: UpdatePostQueueProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<PostQueue>
        >(`/post_queues/${postQueue.id}`, updateProps);

        return response.data;
      },
      {
        onMutate: async ({ postQueue, updateProps, optimistic = false }) => {
          if (!optimistic) {
            return null;
          }

          const newPlatform = patchObject(postQueue, updateProps);
          const revert = await optimisticallyUpdateQueryCacheObject<PostQueue>(
            queryClient,
            {
              detail: POST_QUEUES_QUERY_KEYS.detail(postQueue.id),
              lists: [
                POST_QUEUES_QUERY_KEYS.list({
                  workspaceId: postQueue.workspaceId,
                }),
              ],
            },
            postQueue.id,
            () => newPlatform
          );

          return { revert };
        },
        onError: (error, props, context) => {
          if (!context) {
            return;
          }

          const { revert } = context as {
            revert: () => void;
          };

          revert();
        },
        onSuccess: (postQueue) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: POST_QUEUES_QUERY_KEYS.detail(postQueue.id),
              lists: [
                POST_QUEUES_QUERY_KEYS.list({
                  workspaceId: postQueue.workspaceId,
                }),
              ],
            },
            postQueue.id,
            () => postQueue
          );

          queryClient.invalidateQueries(POSTS_QUERY_KEYS.all);
        },
        onSettled: (successResponse, errorResponse, { postQueue }) => {
          queryClient.invalidateQueries(POST_QUEUES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(
            POST_QUEUES_QUERY_KEYS.detail(postQueue.workspaceId)
          );
        },
      }
    ),
    () => ({ title: "Failed to update smart schedule" })
  );
};
