import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "../../types/general";

import { useApiClient } from "./app";
import { RITE_KIT_QUERY_KEYS } from "../queryKeys";
import { useDecoratedReactQuery } from "./general";
import { PublishedPostAction } from "../../types/publishedPostActions";

interface HashtagSuggestion {
  hashtag: string;
}

interface aiSuggestion {
  aiprompts: string;
}

interface UseSearchTwitterUsersProps {
  text: string;
}

export const useHashtagSuggestions = (
  { text }: UseSearchTwitterUsersProps,
  options = { enabled: true, keepPreviousData: true }
) => {
  const apiClient = useApiClient();

  return useQuery(
    RITE_KIT_QUERY_KEYS.list({ text }),
    async () => {
      const { data: response } = await apiClient.get<
        ApiResponse<HashtagSuggestion[]>
      >("/proxies/rite_kit", {
        params: {
          action: "hashtagSuggestions",
          text,
        },
      });

      return response.data;
    },
    options
  );
};

interface Prompt {
  prompt: string;
  example: string;
}

interface AiSuggestionProps {
  action: "aiSuggestions";
  text: string;
  tone?: string;
  maximumWords?: number;
  generateHashtags?: boolean;
  includeEmoji?: boolean;
}

export const useAiSuggestions = (
  params: AiSuggestionProps,
  options = { enabled: true, keepPreviousData: true }
) => {
  const apiClient = useApiClient();
  return useQuery(
    RITE_KIT_QUERY_KEYS.list(params),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<Prompt[]>>(
        "/proxies/prompts/suggstions",
        {
          params: params,
        }
      );
      return response.data;
    },
    options
  );
};

export const useGetAiGeneratedTextSuggestion = () => {
  const apiClient = useApiClient();

  return useDecoratedReactQuery(
    useMutation(async (props: AiSuggestionProps) => {
      const { data: response } = await apiClient.get<ApiResponse<string>>(
        "/proxies/prompts/suggstions",
        { params: props }
      );

      return response.data;
    }),
    () => ({ title: "Failed to fetch ai suggestions" })
  );
};
