import React from "react";
import { Platform } from "../../types/platforms";
import PlatformIcon from "../icons/Platform";

interface DeletedPostSnackBarContentProps {
  platformType: Platform["type"];
}
const DeletedPostSnackBarContent: React.FC<DeletedPostSnackBarContentProps> = ({
  platformType,
}) => {
  return (
    <div className="flex items-center">
      <PlatformIcon className="flex-shrink-0 w-6 h-6" type={platformType} />
      <span className="ml-2">Post deleted</span>
    </div>
  );
};

export default DeletedPostSnackBarContent;
