import React from "react";
import { CalendarIcon } from "@heroicons/react/outline";
import { ExternalLink as ExternalLinkIcon } from "react-feather";

import { PostQueue, PostQueueStateMonitoring } from "../../../types/postQueues";
import { PlatformEntity } from "../../../types/platforms";

import { getPlatformTypeName } from "../../../libs/platform";
import { useDateTimeFactory, useNow } from "../../../libs/hooks/app";
import { usePost } from "../../../libs/hooks/posts";

import PostPreview from "../../../components/posts/Preview";
import LoaderIcon from "../../../components/icons/Loader";
import InternalLink from "../../../components/links/Internal";
import ExternalLink from "../../../components/links/External";

import ContentSwitchContainer from "../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../components/animations/contentSwitch/Content";
import PostMetricsEngagementIcon from "../../../components/icons/PostMetricsEngagement";
import {
  ENGAGEMENT_LEVELS,
  getEngagementLevelColourName,
  getEngagementLevelDescription,
} from "../../../libs/postMetrics";

interface ExplainSmartSchedulesStateMonitoringProps {
  platformEntity: PlatformEntity;
  postQueue: PostQueue & {
    state: PostQueueStateMonitoring;
  };
  close?: () => void;
  className?: string;
}

const ExplainSmartSchedulesStateMonitoringModal: React.FC<
  ExplainSmartSchedulesStateMonitoringProps
> = ({ platformEntity, postQueue, close, className = "" }) => {
  const { data: post, isLoading, errorAlert } = usePost(postQueue.state.postId);
  const now = useNow();
  const dateFactory = useDateTimeFactory();
  const lastCheck = dateFactory(postQueue.state.lastCheck);
  const currentEngagementLevel =
    post && post.metrics && post.metrics.engagementLevel
      ? post!.metrics.engagementLevel
      : ENGAGEMENT_LEVELS.GREEN;

  return (
    <div className={className}>
      <ContentSwitchContainer
        contentId={isLoading ? "loading" : post ? "post" : "error"}
      >
        <ContentSwitchContent contentId="loading">
          <div className="h-48 flex items-center justify-center">
            <LoaderIcon className="h-10 w-10" />
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="post">
          <div className="mt-4">
            <p style={{ width: 0, minWidth: "100%" }}>
              Seenly is currently monitoring the engagement of your most recent
              post.
            </p>
            <p className="mt-2" style={{ width: 0, minWidth: "100%" }}>
              Once the post engagement moves to{" "}
              <span className="text-red-400">
                {getEngagementLevelColourName(ENGAGEMENT_LEVELS.RED)}
              </span>{" "}
              Seenly will schedule the next up content from your smart schedule
              for publishing.
            </p>
            <div className="mt-8 flex items-center">
              <div className="font-bold">Current engagement:</div>
              <PostMetricsEngagementIcon
                className="ml-2 inline-block"
                engagementLevel={currentEngagementLevel}
              />
              <span
                className={`ml-2 ${
                  currentEngagementLevel === ENGAGEMENT_LEVELS.RED
                    ? "text-red-400"
                    : currentEngagementLevel === ENGAGEMENT_LEVELS.AMBER
                    ? "text-amber-400"
                    : "text-green-400"
                }`}
              >
                {`${getEngagementLevelColourName(
                  currentEngagementLevel
                )} - ${getEngagementLevelDescription(currentEngagementLevel)}`}
              </span>
            </div>
            <div className="mt-2 flex items-center">
              <div className="font-bold">Last checked:</div>
              <div className="ml-2">
                {lastCheck.toRelative({
                  base: now,
                })}
              </div>
            </div>
            {post && (
              <div className="mt-8">
                <div className="flex items-center">
                  <h4 className="font-bold text-sm text-gray-400">
                    MONITORED POST PREVIEW
                  </h4>
                  <div className="ml-auto flex items-center">
                    {post.scheduled && (
                      <InternalLink
                        className="flex items-center"
                        to={`/calendar?day=${dateFactory(
                          post.scheduled
                        ).toISODate()}`}
                        onClick={() => close && close()}
                      >
                        <CalendarIcon className="mr-1 h-4 w-4" /> Calendar
                      </InternalLink>
                    )}
                    {post.externalUrl && (
                      <ExternalLink
                        className="ml-4 flex items-center"
                        href={post.externalUrl}
                      >
                        <ExternalLinkIcon className="mr-1 h-4 w-4" />{" "}
                        {getPlatformTypeName(post.type)}
                      </ExternalLink>
                    )}
                  </div>
                </div>
                <PostPreview
                  className="mt-2"
                  platformEntity={platformEntity}
                  post={post}
                />
              </div>
            )}
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="error">
          {errorAlert()}
        </ContentSwitchContent>
      </ContentSwitchContainer>
    </div>
  );
};

export default ExplainSmartSchedulesStateMonitoringModal;
