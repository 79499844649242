import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Calendar as CalendarIcon,
} from "react-feather";

import { MetricPropertyNames, PostAnalytics } from "../../types/postAnalytics";

import { clamp } from "../../libs/utils";
import { getUTCOffsetString } from "../../libs/date";
import { MetricPropConfig, METRICS_CONFIG_BY_TYPE } from "../../libs/analytics";
import {
  useActiveWorkspaceDateTime,
  useActiveWorkspaceTimeZone,
} from "../../libs/hooks/app";

import MetricCard from "../../components/analytics/dailySnapshot/MetricCard";
import H3 from "../../components/headings/H3";
import PageActionButton from "../../components/buttons/PageAction";
import IconButton from "../../components/buttons/Icon";
import DatePicker from "../../components/menus/DatePicker";

interface AnalyticsDailySnapshotProps {
  metricPropertyNames: MetricPropertyNames;
  platformType: PostAnalytics["type"];
  utcOffset: number;
  periodStart: string;
  byDayOffset: PostAnalytics["byDayOffset"];
  className?: string;
}
const AnalyticsDailySnapshot: React.FC<AnalyticsDailySnapshotProps> = ({
  metricPropertyNames,
  platformType,
  utcOffset,
  periodStart,
  byDayOffset,
  className = "",
}) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const now = useActiveWorkspaceDateTime();
  const todayDate = now.toISODate();
  const yesterdayDate = now.minus({ days: 1 }).toISODate();
  const [selectedDayIndex, setSelectedDayIndex] = useState<number | null>(null);
  const availableDays = useMemo(() => {
    const utcOffsetString = getUTCOffsetString(utcOffset);
    const periodStartDateTime =
      DateTime.fromISO(periodStart).setZone(utcOffsetString);
    const dayOffsets = Object.keys(byDayOffset).map((dayOffset) =>
      parseInt(dayOffset, 10)
    );

    return dayOffsets.map((dayOffset) => {
      return periodStartDateTime.plus({ days: dayOffset }).toISODate();
    });
  }, [byDayOffset, periodStart, utcOffset]);
  const todayIndex = useMemo(() => {
    return availableDays.indexOf(todayDate);
  }, [todayDate, availableDays]);

  const dayIndex =
    selectedDayIndex === null ? availableDays.length - 1 : selectedDayIndex;
  const canPrev = dayIndex > 0;
  const canNext = dayIndex < availableDays.length - 1;
  const dayDate = availableDays[dayIndex];
  const config = METRICS_CONFIG_BY_TYPE[platformType];
  const todayByDay = dayDate ? byDayOffset[dayIndex] : null;
  const isShowingToday = dayDate === todayDate;
  const isShowingYesterday = dayDate === yesterdayDate;

  const dayDateTime = useMemo(() => {
    return dayDate ? DateTime.fromISO(dayDate, { zone: timeZone }) : null;
  }, [dayDate, timeZone]);

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <H3 className="text-2xl">
          {dayDateTime
            ? isShowingToday
              ? "Today"
              : isShowingYesterday
              ? "Yesterday"
              : dayDateTime.toFormat("DDDD")
            : ""}
        </H3>
        <div className="flex items-center">
          <PageActionButton
            disabled={todayIndex < 0 || isShowingToday}
            onClick={() => setSelectedDayIndex(todayIndex)}
          >
            Today
          </PageActionButton>
          <IconButton
            className="ml-2 from-gray-200 to-gray-200 text-gray-600"
            title={`Show previous week from`}
            disabled={!canPrev}
            onClick={() =>
              setSelectedDayIndex(
                clamp(dayIndex - 1, 0, availableDays.length - 1)
              )
            }
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            className="ml-2 from-gray-200 to-gray-200 text-gray-600"
            title={`Show next week from`}
            disabled={!canNext}
            onClick={() =>
              setSelectedDayIndex(
                clamp(dayIndex + 1, 0, availableDays.length - 1)
              )
            }
          >
            <ArrowRightIcon />
          </IconButton>
          <div className="ml-2">
            <DatePicker
              buttonClassName="rounded-full px-2 py-2 bg-gray-200 text-gray-600"
              buttonContent={<CalendarIcon className="text-gray-600" />}
              value={dayDateTime ? dayDateTime.toSeconds() : 0}
              xAlign="right"
              closeOnSelect={true}
              highlightWeek={false}
              timeZone={timeZone}
              allowedDateList={availableDays}
              onChange={(newDate) => {
                if (newDate) {
                  const newDay = DateTime.fromSeconds(newDate, {
                    zone: timeZone,
                  });
                  const newDayDate = newDay.toISODate();
                  const newSelectedDayIndex = availableDays.indexOf(newDayDate);

                  setSelectedDayIndex(
                    newSelectedDayIndex >= 0 ? newSelectedDayIndex : 0
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex items-center flex-wrap gap-4">
        {metricPropertyNames.map((metricPropertyName) => {
          const key = `${platformType}${metricPropertyName}`;

          const delta =
            todayByDay &&
            todayByDay.metrics[
              metricPropertyName as keyof typeof todayByDay.metrics
            ]
              ? ((
                  todayByDay.metrics[
                    metricPropertyName as keyof typeof todayByDay.metrics
                  ] as any
                ).value as number)
              : undefined;
          const comparativeDeltaPercent =
            todayByDay &&
            todayByDay.metrics[
              metricPropertyName as keyof typeof todayByDay.metrics
            ]
              ? ((
                  todayByDay.metrics[
                    metricPropertyName as keyof typeof todayByDay.metrics
                  ] as any
                ).deltaPercent as number)
              : undefined;
          const metricPropertyConfig = config[
            metricPropertyName as keyof typeof config
          ] as MetricPropConfig;

          return (
            <MetricCard
              key={key}
              iconContainerClassName={metricPropertyConfig.bgClassNames}
              icon={metricPropertyConfig.icon}
              title={metricPropertyConfig.title}
              value={delta}
              deltaPercent={comparativeDeltaPercent}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AnalyticsDailySnapshot;
