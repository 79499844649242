import React from "react";

import { InternalFileImage } from "../../types/files";

import { mergeClassNames } from "../../libs/components";
import { useFile } from "../../libs/hooks/files";

import InternalImage from "./Internal";

interface LazyInternalImageProps {
  fileId: string;
  preview?: boolean;
  className?: string;
  containerClassName?: string;
}
const LazyInternalImage: React.FC<LazyInternalImageProps> = ({
  fileId,
  preview = true,
  className = "",
  containerClassName = "",
}) => {
  const { isLoading, data: file } = useFile<InternalFileImage>(fileId);

  if (isLoading || !file) {
    return <div className={mergeClassNames("h-full w-full", className)}></div>;
  } else {
    return (
      <InternalImage
        className={className}
        containerClassName={containerClassName}
        file={file}
        preview={preview}
      />
    );
  }
};

export default LazyInternalImage;
