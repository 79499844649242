import React from "react";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import { mergeClassNames } from "../../../../../../libs/components";

interface PostTextAreaMentionEntityCardProps {
  name: string;
  isVerified?: boolean;
  vanityName?: string;
  pictureUrl?: string;
  className?: string;
}
const PostTextAreaMentionEntityCard: React.FC<
  PostTextAreaMentionEntityCardProps
> = ({ name, vanityName, pictureUrl, isVerified = false, className = "" }) => {
  return (
    <div
      className={mergeClassNames(
        "w-full flex items-center truncate",
        className
      )}
      spellCheck={false}
    >
      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-gray-200">
        {pictureUrl && (
          <img
            className="flex-shrink-0 w-10 h-10 rounded-full"
            src={pictureUrl}
            alt=""
          />
        )}
      </div>
      <div className="ml-2 truncate h-10 w-full">
        <div className="w-full truncate flex items-center">
          <div className="w-full truncate font-bold text-sm">{name}</div>
          {isVerified && (
            <BadgeCheckIcon className="ml-auto flex-shrink-0 h-6 w-6 text-sky-500" />
          )}
        </div>
        {vanityName && (
          <div className="truncate text-gray-500 text-xs leading-3">{`@${vanityName}`}</div>
        )}
      </div>
    </div>
  );
};

export default PostTextAreaMentionEntityCard;
