import React from "react";
import { mergeClassNames } from "../../libs/components";

interface AlertPingProps {
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
}
const AlertPing: React.FC<AlertPingProps> = ({
  children,
  className = "",
  containerClassName = "",
}) => {
  return (
    <span className={mergeClassNames("relative flex", containerClassName)}>
      <span
        className={mergeClassNames(
          "animate-ping absolute inline-flex h-full w-full opacity-75",
          className
        )}
      ></span>
      {children}
    </span>
  );
};

export default AlertPing;
