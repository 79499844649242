import React from "react";
import { Star as StarIcon, Check as CheckIcon } from "react-feather";

import config from "../../../config";
import { mergeClassNames } from "../../../libs/components";
import { useActiveWorkspace, useShowModal } from "../../../libs/hooks/app";

import PrimaryButton from "../../../components/buttons/Primary";
import H2 from "../../../components/headings/H2";

import { ReactComponent as CelebrationImage } from "../../../images/celebration.svg";

interface SubscriptionsOfferTrialSectionProps {
  className?: string;
}
const SubscriptionsOfferTrialSection: React.FC<
  SubscriptionsOfferTrialSectionProps
> = ({ className = "" }) => {
  const showModal = useShowModal();
  const workspace = useActiveWorkspace();

  return (
    <div
      className={mergeClassNames(
        "w-full h-full flex justify-center",
        className
      )}
    >
      <div className="relative flex flex-col items-center">
        <div style={{ width: 600 }}>
          <H2>Why not try before you buy?</H2>
          <p className="mt-6">
            We think you're going to love Seenly so much that we're giving you{" "}
            <strong>{config.DEFAULT_TRIAL_PERIOD_DAYS} days</strong> to take it
            for a spin. No strings attached.
          </p>
          <p className="mt-4">
            If you like what you see then simply add a payment method before the
            end of the trial and your subscription will automatically continue.
          </p>
        </div>
        <ul className="mt-10 text-lg">
          {config.prices.PUBLISH_MONTHLY.features.map((feature, index) => {
            return (
              <li key={index} className="flex items-center">
                <CheckIcon className="mr-2 text-purple-500" /> {feature}
              </li>
            );
          })}
        </ul>
        <PrimaryButton
          className="mt-10"
          onClick={() =>
            showModal("changeSubscription", {
              workspace,
              redirectOnSuccess: true,
            })
          }
        >
          <StarIcon className="mr-2" />
          Start your {config.DEFAULT_TRIAL_PERIOD_DAYS}-day free trial
        </PrimaryButton>
        <p className="mt-2">No credit card required!</p>
        <CelebrationImage
          className="mt-12 flex-shrink-0 text-purple-500"
          style={{ width: 500, height: 500 }}
        />
      </div>
    </div>
  );
};

export default SubscriptionsOfferTrialSection;
