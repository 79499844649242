import { ComponentProps, useState } from "react";

import { FacebookMentionEntityData } from "../../../../../../../types/posts";
import { getPlatformTypeName } from "../../../../../../../libs/platform";
import { useSearchFacebookPages } from "../../../../../../../libs/hooks/facebook";

import DropdownMenuButton from "../../../../../../../components/buttons/DropdownMenu";

import SearchBase from "./Base";
import EntityCard from "../EntityCard";

const PLATFORM_TYPE_DISPLAY_NAME = getPlatformTypeName("FACEBOOK");

interface PostTextAreaMentionSearchFacebookProps
  extends Omit<
    ComponentProps<typeof SearchBase>,
    | "children"
    | "isLoading"
    | "debouncedOnSearchTermChange"
    | "platformDisplayName"
  > {
  platformId: string;
  onClick: (value: FacebookMentionEntityData) => void;
  isVisible?: boolean;
}
const PostTextAreaMentionSearchFacebook = ({
  platformId,
  onClick,
  defaultSearchTerm = "",
  isVisible = true,
  ...rest
}: PostTextAreaMentionSearchFacebookProps) => {
  const [internalSearchTerm, setInternalSearchTerm] =
    useState(defaultSearchTerm);
  const { isFetching, data } = useSearchFacebookPages(
    { platformId, query: internalSearchTerm },
    { enabled: !!internalSearchTerm, keepPreviousData: true }
  );
  const facebookPages = data || [];
  const hasResults = facebookPages.length;

  if (!isVisible) {
    return null;
  }

  return (
    <SearchBase
      platformDisplayName={PLATFORM_TYPE_DISPLAY_NAME}
      isLoading={isFetching}
      defaultSearchTerm={defaultSearchTerm}
      debouncedOnSearchTermChange={setInternalSearchTerm}
      {...rest}
    >
      {facebookPages.map((pageData) => {
        return (
          <DropdownMenuButton
            key={pageData.id}
            title={pageData.name}
            onClick={() =>
              onClick({
                id: pageData.id,
                text: pageData.name,
                type: "BUSINESS",
                platformType: "FACEBOOK",
              })
            }
          >
            <EntityCard
              name={pageData.name}
              vanityName={pageData.username}
              pictureUrl={pageData.pictureUrl}
              isVerified={pageData.isVerified}
            />
          </DropdownMenuButton>
        );
      })}
      {!isFetching && !hasResults && (
        <p className="p-2 w-full text-center text-gray-500">No results</p>
      )}
    </SearchBase>
  );
};

export default PostTextAreaMentionSearchFacebook;
