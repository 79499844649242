import { ReactNode, useState } from "react";
import { Search as SearchIcon } from "react-feather";

import LoaderIcon from "../../../../../../../components/icons/Loader";
import Input from "../../../../../../../components/form/Input";

interface PostTextAreaMentionSearchBaseProps {
  platformDisplayName: string;
  isLoading: boolean;
  children: ReactNode;
  debouncedOnSearchTermChange: (newSearchTerm: string) => void;
  defaultSearchTerm?: string;
}
const PostTextAreaMentionSearchBase = ({
  platformDisplayName,
  isLoading,
  children,
  debouncedOnSearchTermChange,
  defaultSearchTerm = "",
}: PostTextAreaMentionSearchBaseProps) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);

  return (
    <div className="px-2 h-full overflow-hidden flex flex-col">
      <div className="mt-2 shrink-0 flex items-center relative focus-within:text-purple-500">
        <Input
          className="pl-10 pr-2 py-1 text-gray-600 border border-transparent bg-gray-100 focus:bg-white"
          labelText={`Search ${platformDisplayName}`}
          hideLabel={true}
          placeholder={`Search ${platformDisplayName}`}
          value={searchTerm}
          debounceOnChange={(newValue) => {
            // As soon as the user changes the input manually then break the link
            // and use the internal value.
            setSearchTerm(newValue);
            debouncedOnSearchTermChange(newValue);
          }}
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
          {isLoading ? <LoaderIcon /> : <SearchIcon />}
        </div>
      </div>
      <div className="mt-2 grow overflow-y-auto">{children}</div>
    </div>
  );
};

export default PostTextAreaMentionSearchBase;
