import React from "react";
import { CalendarIcon } from "@heroicons/react/outline";

import { PostQueue, PostQueueStateWaiting } from "../../../types/postQueues";
import { PlatformEntity } from "../../../types/platforms";

import { usePostsWithScheduledProp } from "../../../libs/hooks/posts";
import { useDateTimeFactory } from "../../../libs/hooks/app";

import ContentSwitchContainer from "../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../components/animations/contentSwitch/Content";
import LoaderIcon from "../../../components/icons/Loader";
import InternalLink from "../../../components/links/Internal";
import PostPreview from "../../../components/posts/Preview";

interface ExplainSmartSchedulesStateWaitingProps {
  platformEntity: PlatformEntity;
  postQueue: PostQueue & {
    state: PostQueueStateWaiting;
  };
  close?: () => void;
  className?: string;
}

const ExplainSmartSchedulesStateWaitingModal: React.FC<
  ExplainSmartSchedulesStateWaitingProps
> = ({ platformEntity, postQueue, close, className = "" }) => {
  const dateFactory = useDateTimeFactory();
  const occupiedDate = dateFactory(postQueue.state.occupiedDate);
  const candidateDate = dateFactory(postQueue.state.candidateDate);
  const {
    data: response,
    isLoading,
    errorAlert,
  } = usePostsWithScheduledProp(
    postQueue.workspaceId,
    occupiedDate.setZone("utc").startOf("day").toISO(),
    occupiedDate.setZone("utc").endOf("day").toISO()
  );
  const posts = (response || []).filter(
    (post) =>
      post.platformId === postQueue.platformId &&
      post.platformEntityId === platformEntity.id
  );
  const occupiedDateString = occupiedDate.toFormat("EEE, d MMM");
  const candidateDateString = candidateDate.toFormat("EEE, d MMM");

  return (
    <div className={className}>
      <ContentSwitchContainer
        contentId={
          isLoading
            ? "loading"
            : response !== undefined
            ? posts.length < 1
              ? "empty"
              : "content"
            : "error"
        }
      >
        <ContentSwitchContent contentId="loading">
          <div className="h-48 flex items-center justify-center">
            <LoaderIcon className="h-10 w-10" />
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="content">
          <div className="mt-4">
            <p style={{ width: 0, minWidth: "100%" }}>
              Seenly has identified <strong>{candidateDateString}</strong> as
              the best day to schedule the next post for this smart schedule
              however you have existing content scheduled for{" "}
              <strong>{occupiedDateString}</strong>.
            </p>
            <p className="mt-2" style={{ width: 0, minWidth: "100%" }}>
              The smart schedule will wait for the existing content to be
              published and then monitor it for engagement before scheduling the
              next post. This is done to allow for maximum engagement on each of
              your posts.
            </p>

            <div className="mt-8">
              <div className="flex items-center">
                <h4 className="font-bold text-sm text-gray-400">
                  PREVIEW OF POSTS ON {occupiedDateString.toUpperCase()}
                </h4>

                <InternalLink
                  className="ml-auto flex items-center"
                  to={`/calendar?day=${occupiedDate
                    .setZone("utc")
                    .toISODate()}`}
                  onClick={() => close && close()}
                >
                  <CalendarIcon className="mr-1 h-4 w-4" /> Calendar
                </InternalLink>
              </div>
              {posts.map((post) => {
                return (
                  <PostPreview
                    key={post.id}
                    className="mt-2"
                    platformEntity={platformEntity}
                    post={post}
                  />
                );
              })}
            </div>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="empty">
          <div className="mt-4">
            <p style={{ width: 0, minWidth: "100%" }}>
              There no longer appears to be any existing content on{" "}
              <strong>{occupiedDateString}</strong>. This can happen when the
              content is cleared between smart schedule updated.
            </p>
            <p className="mt-2" style={{ width: 0, minWidth: "100%" }}>
              Give it an hour or so for the smart schedule to update again.
            </p>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="error">
          {errorAlert()}
        </ContentSwitchContent>
      </ContentSwitchContainer>
    </div>
  );
};

export default ExplainSmartSchedulesStateWaitingModal;
