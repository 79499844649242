import React, {
  ReactElement,
  ComponentProps,
  Children,
  useRef,
  useLayoutEffect,
  CSSProperties,
} from "react";
import {
  useSpring,
  useTransition,
  animated,
  SpringRef,
} from "@react-spring/web";
import ContentSwitchContent from "./Content";

type ContentProps = ComponentProps<typeof ContentSwitchContent>;
type ChildType = ReactElement<ContentProps>;

interface ContentSwitchContainerProps {
  contentId: string;
  transitionSpeed?: "slow" | "normal" | "fast" | "immediate";
  defaultHeight?: number;
  defaultWidth?: number;
  children: ChildType[];
  animationRef?: SpringRef;
  className?: string;
  contentContainerClassName?: string;
}

const ContentSwitchContainer: React.FC<ContentSwitchContainerProps> = ({
  children,
  contentId,
  transitionSpeed = "normal",
  defaultHeight = 0,
  defaultWidth = 0,
  animationRef,
  className = "",
  contentContainerClassName = "",
}) => {
  let duration = 150;
  switch (transitionSpeed) {
    case "slow":
      duration = 450;
      break;
    case "normal":
      duration = 300;
      break;
    case "fast":
      duration = 150;
      break;
    case "immediate":
      duration = 0;
      break;
  }

  const immediateTransition = duration === 0;
  const enteringContentRef = useRef<HTMLDivElement>(null);
  const leavingContentRef = useRef<HTMLDivElement>(null);
  const [containerSpring, containerSpringApi] = useSpring<{
    width: NonNullable<CSSProperties["width"]>;
    height: NonNullable<CSSProperties["height"]>;
    overflow: NonNullable<CSSProperties["overflow"]>;
  }>(() => ({
    width: defaultWidth || "auto",
    height: defaultHeight || "auto",
    overflow: "visible",
    immediate: true,
  }));
  const routeTransitions = useTransition<
    string,
    {
      buffer: number;
      opacity: CSSProperties["opacity"];
      width: CSSProperties["width"];
      height: CSSProperties["height"];
      position: CSSProperties["position"];
      top: CSSProperties["top"];
      left: CSSProperties["left"];
      transform: CSSProperties["transform"];
    }
  >(contentId, {
    ref: animationRef,

    initial: {
      opacity: 1,
      buffer: 1,
      width: undefined,
      height: undefined,
      position: undefined,
      top: undefined,
      left: undefined,
      transform: undefined,
    },

    from: {
      opacity: 0,
      buffer: 0,
      width: undefined,
      height: undefined,
      position: undefined,
      top: undefined,
      left: undefined,
      transform: undefined,
    },

    enter: [{ buffer: 1 }, { opacity: 1 }],

    leave: () => {
      // Use the enteringContentRef here because this function runs before the render
      // that swaps the refs, so when this function runs the enteringContentRef is
      // poiting to the leaving content.
      const height = enteringContentRef.current
        ? enteringContentRef.current.clientHeight
        : 0;
      const width = enteringContentRef.current
        ? enteringContentRef.current.clientWidth
        : 0;

      // Immediately set the container spring to the size of the content to trigger the
      // overflow lock and prevent the container size from jumping around in the single
      // render frame between when this code executes and the useEffect function
      // executes below.
      containerSpringApi.start({
        width,
        height,
        overflow: "visible",
        immediate: true,
      });

      return {
        height,
        width,
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translate3d(-50%, 0, 0)",
        opacity: 0,
      };
    },

    config: { duration },
    immediate: immediateTransition
      ? true
      : (k: string) => k !== "opacity" && k !== "buffer",
  });

  const getContent = (id: string) => {
    return Children.toArray(children).find(
      (child) => (child as ChildType).props.contentId === id
    );
  };

  useLayoutEffect(() => {
    if (leavingContentRef.current && enteringContentRef.current) {
      const currentHeight = leavingContentRef.current.clientHeight;
      const currentWidth = leavingContentRef.current.clientWidth;
      const nextHeight = enteringContentRef.current.clientHeight;
      const nextWidth = enteringContentRef.current.clientWidth;

      containerSpringApi.start(() => ({
        from: {
          height: currentHeight,
          width: currentWidth,
        },
        to: [
          {
            height: nextHeight,
            width: nextWidth,
            overflow: "hidden",
            immediate: immediateTransition,
          },
          {
            height: "auto",
            width: "auto",
            overflow: "visible",
            immediate: true,
          },
        ],
      }));
    }
  }, [contentId, immediateTransition, containerSpringApi]);

  return (
    <animated.div
      className={`relative ${className}`}
      style={{
        height: containerSpring.height.to((h) =>
          h === "auto" ? (undefined as any) : h
        ),
        width: containerSpring.width.to((w) =>
          w === "auto" ? (undefined as any) : w
        ),
        overflow: containerSpring.width.to((w) =>
          w === "auto" ? (undefined as any) : containerSpring.overflow
        ),
      }}
    >
      {routeTransitions((style, item) => {
        const isLeaving = item !== contentId;
        return (
          <animated.div
            ref={isLeaving ? leavingContentRef : enteringContentRef}
            className={contentContainerClassName}
            style={style}
          >
            {getContent(item)}
          </animated.div>
        );
      })}
    </animated.div>
  );
};

export default ContentSwitchContainer;
