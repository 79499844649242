import React from "react";
import {
  ChevronRight as ChevronRightIcon,
  Activity as ActivityIcon,
} from "react-feather";
import {
  EyeOffIcon,
  PauseIcon,
  EyeIcon,
  PlayIcon,
} from "@heroicons/react/outline";

import { PostQueue } from "../../../../types/postQueues";
import { useContentStack } from "../../../../libs/hooks/general";
import { UpdatePostQueueProps } from "../../../../libs/hooks/postQueues";

import DropdownMenuButton from "../../../../components/buttons/DropdownMenu";

const SmartSchedulesQueueSettingsHome: React.FC<{
  contentId: "home";
  postQueue: PostQueue;
  updatePostQueue: (
    updateProps: UpdatePostQueueProps["updateProps"]
  ) => Promise<any>;
  isUpdating: boolean;
}> = ({ postQueue, updatePostQueue, isUpdating }) => {
  const { push } = useContentStack();

  const handleToggleVisiblity = async () => {
    await updatePostQueue({ hidden: !postQueue.hidden });
  };

  const handleToggleStatus = async () => {
    await updatePostQueue({
      status: postQueue.status === "ACTIVE" ? "PAUSED" : "ACTIVE",
    });
  };

  return (
    <div className="p-2 flex flex-col">
      <DropdownMenuButton
        onClick={(e) => {
          e.preventDefault();
          push("changePostFrequency");
        }}
        disabled={isUpdating}
      >
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          <ActivityIcon className="h-6 w-6" />
        </div>
        Posting frequency
        <ChevronRightIcon className="ml-auto text-gray-500" />
      </DropdownMenuButton>

      <DropdownMenuButton onClick={handleToggleVisiblity} disabled={isUpdating}>
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          {postQueue.hidden ? (
            <EyeIcon className="h-6 w-6" />
          ) : (
            <EyeOffIcon className="h-6 w-6" />
          )}
        </div>

        {postQueue.hidden ? "Show in view" : "Hide from view"}
      </DropdownMenuButton>
      <DropdownMenuButton onClick={handleToggleStatus} disabled={isUpdating}>
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          {postQueue.status === "ACTIVE" ? (
            <PauseIcon className="h-6 w-6" />
          ) : (
            <PlayIcon className="h-6 w-6" />
          )}
        </div>

        {postQueue.status === "ACTIVE" ? "Pause posting" : "Resume posting"}
      </DropdownMenuButton>
    </div>
  );
};

export default SmartSchedulesQueueSettingsHome;
