import { useState, forwardRef, useRef, RefObject, createRef } from "react";
import { nanoid } from "nanoid";
import { mergeClassNames, mergeRefs } from "../../../libs/components";
import { clamp } from "../../../libs/utils";

interface VerificationCodeInputProps {
  value: string;
  onChange: (newCode: string) => void;
  onBlur?: () => void;
  codeLength?: number;
  id?: string;
  labelText?: string;
  className?: string;
  labelClassName?: string;
  error?: boolean;
  hideLabel?: boolean;
}

const VerificationCodeInput = forwardRef<
  HTMLInputElement,
  VerificationCodeInputProps
>(
  (
    {
      id = nanoid(),
      value,
      onChange,
      onBlur,
      codeLength = 6,
      labelText = "Verification code",
      className = "",
      labelClassName = "",
      error,
      hideLabel = false,
    },
    ref
  ) => {
    const [values, setValues] = useState<string[]>(
      new Array(codeLength).fill("").map((currentVal, index) => {
        if (value && value[index]) {
          return value[index];
        } else {
          return currentVal;
        }
      })
    );
    const inputRefs = useRef<RefObject<HTMLInputElement>[]>(
      values.map(() => {
        return createRef<HTMLInputElement>();
      })
    );
    const charWidth = 100 / codeLength;

    const internalOnChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) => {
      const newText = e.target.value;

      if (/[^0-9]/.test(newText)) {
        return;
      }

      const maxIndex = inputRefs.current.length - 1;
      const maxChars = maxIndex - index + 1;
      const newValues = values.slice();
      const newChars = newText.split("").slice(0, maxChars);

      newChars.forEach((char, charIndex) => {
        newValues[index + charIndex] = char;
      });

      setValues(newValues);

      const nextIndex = clamp(index + newText.length, 0, maxIndex);

      if (inputRefs.current[nextIndex].current) {
        inputRefs.current[nextIndex].current!.focus();
      }

      onChange(newValues.join(""));
    };

    const onKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement>,
      index: number
    ) => {
      const prevIndex = clamp(index - 1, 0, inputRefs.current.length - 1);
      const nextIndex = clamp(index + 1, 0, inputRefs.current.length - 1);
      const prevRef = inputRefs.current[prevIndex].current!;
      const nextRef = inputRefs.current[nextIndex].current!;

      switch (e.key) {
        case "Backspace":
          e.preventDefault();
          const newValues = values.slice();
          newValues[index] = "";
          setValues(newValues);
          onChange(newValues.join(""));
          prevRef.focus();
          break;
        case "Left": // IE/Edge specific value
        case "ArrowLeft":
          e.preventDefault();
          prevRef.focus();
          break;
        case "Right": // IE/Edge specific value
        case "ArrowRight":
          e.preventDefault();
          nextRef.focus();
          break;
        case "Up": // IE/Edge specific value
        case "Down": // IE/Edge specific value
        case "ArrowUp":
        case "ArrowDown":
          e.preventDefault();
          break;
      }
    };

    return (
      <>
        <label
          className={mergeClassNames(
            `font-bold block mb-2 default-transition ${
              error ? "text-red-500" : ""
            } ${hideLabel ? "sr-only" : ""}`,
            labelClassName
          )}
          htmlFor={id}
        >
          {labelText}
        </label>
        <div
          className={mergeClassNames(`relative w-full`, className)}
          style={{
            height: "3.25rem",
          }}
        >
          {values.map((val, index) => {
            const charRef = inputRefs.current[index];

            return (
              <input
                key={index}
                ref={index === 0 ? mergeRefs([charRef, ref]) : charRef}
                className={`absolute top-0 h-full text-xl text-center bg-transparent border-2 border-gray-300 focus:border-purple-500 focus:z-10 first-of-type:rounded-l-lg last-of-type:rounded-r-lg ${
                  error ? "border-red-500" : "border-gray-300"
                }`}
                type="string"
                value={val}
                onChange={(e) => internalOnChange(e, index)}
                onKeyDown={(e) => onKeyDown(e, index)}
                onFocus={(e) => {
                  e.target.select();
                }}
                style={{
                  left: `${index * charWidth}%`,
                  width: `calc(${charWidth}% + 2px)`,
                }}
              />
            );
          })}
        </div>
      </>
    );
  }
);

export default VerificationCodeInput;
