import React, { useState, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { analyticsLogEvent } from "../libs/amplitudeAnalytics";
import { useCurrentUser, useIsEmailVerified } from "../libs/hooks/app";
import { useInitialise } from "../libs/hooks/uninitialisedApp";
import WorkspaceAppContext, {
  WorkspaceAppContextState,
} from "../contexts/app/Workspace";

import LoaderIcon from "../components/icons/Loader";
import H2 from "../components/headings/H2";

import CalendarPage from "../pages/Calendar";
import SocialsPage from "../pages/Socials";
import GalleryPage from "../pages/Gallery";
import SubscriptionPage from "../pages/Subscription";
import SubscriptionsPage from "../pages/Subscriptions";
import SettingsPage from "../pages/Settings";
import ProfilePage from "../pages/Profile";
import LogoutPage from "../pages/Logout";
import WelcomePage from "../pages/Welcome";
import NotFoundPage from "../pages/NotFound";
import AuthCallbackPage from "../pages/AuthCallback";
import BillingPage from "../pages/Billing";
import SmartSchedulesPage from "../pages/SmartSchedules";
import AnalyticsPage from "../pages/Analytics";

import AppDrawers from "./AppDrawers";
import AppModals from "./AppModals";
import AppSnackBox from "./AppSnackBox";
import UploadsMonitor from "./UploadsMonitor";

const AuthenticatedApp: React.FC = () => {
  const [appContext, setAppContext] = useState<WorkspaceAppContextState | null>(
    null
  );
  const user = useCurrentUser();
  const isEmailVerified = useIsEmailVerified();
  const location = useLocation();
  const navigate = useNavigate();
  const { isFetched, isError, data } = useInitialise(user);
  const activeWorkspace = data ? data.activeWorkspace : null;
  const fileAccessToken = data ? data.fileAccessToken : null;
  const timeZone = data ? data.timeZone || user.timeZone : user.timeZone;
  const { from } = (location.state as any) || {
    from: { pathname: "/smart_schedules" },
  };
  const redirectTo = { ...from };

  if (redirectTo.pathname === "/logout") {
    redirectTo.pathname = "/smart_schedules";
  }

  let forceRoute: string | null = null;

  if (!isEmailVerified && location.pathname !== "/profile") {
    forceRoute = "/profile";
  }

  useEffect(() => {
    if (activeWorkspace) {
      setAppContext((existingContext) => {
        return {
          activeWorkspace,
          fileAccessToken,
          timeZone,
          modals: existingContext ? existingContext.modals : {},
          drawers: existingContext ? existingContext.drawers : {},
          setModal: (name, values) => {
            setAppContext((prevContext) => {
              if (prevContext) {
                return {
                  ...prevContext,
                  modals: {
                    ...prevContext.modals,
                    [name]: values,
                  },
                };
              } else {
                return prevContext;
              }
            });
          },
          showModal: ((name, values) => {
            analyticsLogEvent({
              event: `showModal${name[0].toUpperCase()}${name.slice(1)}`,
            });

            setAppContext((prevContext) => {
              if (prevContext) {
                return {
                  ...prevContext,
                  modals: {
                    ...prevContext.modals,
                    [name]: values
                      ? {
                          ...values,
                          isVisible: true,
                        }
                      : { isVisible: true },
                  },
                };
              } else {
                return prevContext;
              }
            });
          }) as WorkspaceAppContextState["showModal"],
          hideModal: (name, values) => {
            analyticsLogEvent({
              event: `hideModal${name[0].toUpperCase()}${name.slice(1)}`,
            });

            setAppContext((prevContext) => {
              if (prevContext) {
                return {
                  ...prevContext,
                  modals: {
                    ...prevContext.modals,
                    [name]: values
                      ? {
                          ...values,
                          isVisible: false,
                        }
                      : { ...prevContext.modals[name], isVisible: false },
                  },
                };
              } else {
                return prevContext;
              }
            });
          },
          setDrawer: (name, values) => {
            setAppContext((prevContext) => {
              if (prevContext) {
                return {
                  ...prevContext,
                  drawers: {
                    ...prevContext.drawers,
                    [name]: values,
                  },
                };
              } else {
                return prevContext;
              }
            });
          },
          showDrawer: ((name) => {
            setAppContext((prevContext) => {
              if (prevContext) {
                return {
                  ...prevContext,
                  drawers: {
                    ...prevContext.drawers,
                    [name]: { isVisible: true },
                  },
                };
              } else {
                return prevContext;
              }
            });
          }) as WorkspaceAppContextState["showDrawer"],
          hideDrawer: (name) => {
            setAppContext((prevContext) => {
              if (prevContext) {
                return {
                  ...prevContext,
                  drawers: {
                    ...prevContext.drawers,
                    [name]: { isVisible: false },
                  },
                };
              } else {
                return prevContext;
              }
            });
          },
        };
      });
    }
  }, [activeWorkspace, fileAccessToken, timeZone]);

  useEffect(() => {
    const url = location.pathname;
    const urlParams = location.search;

    analyticsLogEvent({
      event: "authenticatedUrlChange",
      userId: user.id,
      url,
      urlParams,
    });
  }, [location.pathname, location.search, user.id]);

  useEffect(() => {
    if (forceRoute !== null) {
      navigate(forceRoute);
    }
  }, [forceRoute, navigate]);

  if (appContext) {
    // The user has at least one workspace so we can show them the app.
    return (
      <WorkspaceAppContext.Provider value={appContext}>
        <Routes location={location}>
          {forceRoute !== null && (
            <Route path="/*" element={<Navigate to={forceRoute!} />} />
          )}
          <Route path="/" element={<Navigate to="/smart_schedules" />} />
          <Route path="/welcome" element={<Navigate to="/smart_schedules" />} />
          <Route path="/login" element={<Navigate to={redirectTo} />} />
          <Route path="/signup" element={<Navigate to={redirectTo} />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/analytics" element={<AnalyticsPage />} />
          <Route path="/smart_schedules" element={<SmartSchedulesPage />} />
          <Route path="/socials" element={<SocialsPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/subscriptions" element={<SubscriptionsPage />} />
          <Route
            path="/subscriptions/:subscriptionId"
            element={<SubscriptionPage />}
          />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/auth/:provider" element={<AuthCallbackPage />} />,
          <Route path="/billing" element={<BillingPage />} />,
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <AppModals />
        <AppDrawers />
        <AppSnackBox />
        <UploadsMonitor />
      </WorkspaceAppContext.Provider>
    );
  } else if (!appContext && activeWorkspace) {
    // There is a single render frame between when the workspace is loaded and the app
    // context is built (because it happens in a useEffect hook) so just return nothing here
    // otherwise we flash the loading screen which looks bad.
    return null;
  } else if (isFetched && !activeWorkspace) {
    // We've loaded everything but the user still doesn't have a workspace so
    // show them the welcome screen so that they can create their first workspace.
    return (
      <Routes location={location}>
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/*" element={<Navigate to="/welcome" />} />
      </Routes>
    );
  } else {
    // The user doesn't have any workspaces but we're still loading data from the server.
    // Only allow the logout page here. They may have landed here after logging in or signing
    // up so we should redirect them if that's the case.
    return (
      <Routes location={location}>
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/login" element={<Navigate to={redirectTo} />} />
        <Route path="/signup" element={<Navigate to={redirectTo} />} />
        <Route
          path="/*"
          element={
            <div className="h-screen w-screen flex items-center justify-center">
              {isError ? (
                <div className="flex items-center">
                  <H2 className="text-red-500">
                    Failed to load your workspace. Please reload the page to try
                    again.
                  </H2>
                </div>
              ) : (
                <div className="flex items-center">
                  <LoaderIcon className="w-10 h-10" />
                </div>
              )}
            </div>
          }
        />
      </Routes>
    );
  }
};

export default AuthenticatedApp;
