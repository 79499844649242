import React, { useMemo, useRef, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import {
  Cloud as CloudIcon,
  UploadCloud as UploadCloudIcon,
  Search as SearchIcon,
  Filter as FilterIcon,
  ChevronDown as ChevronDownIcon,
} from "react-feather";
import {
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";

import { InternalFile } from "../types/files";
import { useActiveWorkspace } from "../libs/hooks/app";
import { useFiles } from "../libs/hooks/files";
import { useUploadFiles } from "../libs/hooks/uploads";
import {
  formatFileSizeForDisplay,
  sortCreatedAsc,
  sortCreatedDesc,
  sortNameAsc,
  sortNameDesc,
  sortSizeAsc,
  sortSizeDesc,
} from "../libs/files";
import {
  displayUsedWorkspaceStorageAsPercent,
  getAvailableWorkspaceStorage,
  getWorkspaceStorageLimit,
} from "../libs/subscriptions";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import InternalFileCard from "../containers/cards/InternalFile";
import UpgradeSubscriptionButton from "../containers/buttons/UpgradeSubscription";

import DropdownMenu from "../components/menus/Dropdown";
import LoaderIcon from "../components/icons/Loader";
import PrimaryButton from "../components/buttons/Primary";
import DropdownMenuButton from "../components/buttons/DropdownMenu";
import PageActionButton from "../components/buttons/PageAction";
import H2 from "../components/headings/H2";
import Input from "../components/form/Input";
import MediaFileInput from "../components/form/input/MediaFile";
import Select from "../components/form/Select";

import { ReactComponent as NoFilesImage } from "../images/no-files.svg";

type FilterOption = InternalFile["type"] | null;

const FILTER_OPTIONS: FilterOption[] = [null, "IMAGE", "VIDEO"];

const MAX_IMAGE_WIDTH = 500;
const MAX_IMAGE_HEIGHT = 300;

function getFilterDisplayName(option: FilterOption) {
  if (option === null) {
    return "All";
  } else if (option === "IMAGE") {
    return "Images";
  } else {
    return "Videos";
  }
}

const GalleryPage: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState<FilterOption>(null);
  const [searchText, setSearchText] = useState("");
  const [sortFunc, setSortFunc] = useState<
    (a: InternalFile, b: InternalFile) => -1 | 0 | 1
  >(() => sortCreatedDesc);
  const [isFileInputLoading, setIsFileInputLoading] = useState(false);
  const workspace = useActiveWorkspace();
  const { data: files, isLoading: isLoadingFiles } = useFiles(workspace.id);
  const [dragSpring, dragSpringApi] = useSpring(() => ({
    opacity: 0,
    processing: 0,
    immediate: (k) => k === "processing",
  }));
  const { error: uploadError, uploadFiles } = useUploadFiles({
    owner: {
      type: "WORKSPACE",
      workspaceId: workspace.id,
    },
    onDropStart: () => {
      dragSpringApi.start({ processing: 1 });
      return;
    },
    onDropEnd: () => dragSpringApi.start({ processing: 0, opacity: 0 }),
    onDragEnter: () => dragSpringApi.start({ opacity: 1 }),
    onDragLeave: () => dragSpringApi.start({ opacity: 0 }),
  });
  const filteredFiles = useMemo(() => {
    if (!files) {
      return [];
    }

    const loweredSearchText = searchText ? searchText.toLowerCase() : null;
    return files.filter((file) => {
      if (filter !== null) {
        if (file.type !== filter) {
          return false;
        }
      }

      if (loweredSearchText !== null) {
        return file.name.toLowerCase().includes(loweredSearchText);
      }

      return true;
    });
  }, [files, searchText, filter]);
  const sortedFiles = useMemo(() => {
    const copyOfFiles = filteredFiles.slice();
    copyOfFiles.sort(sortFunc);
    return copyOfFiles;
  }, [filteredFiles, sortFunc]);
  const sortOptions = [
    { text: "Newest to oldest", func: sortCreatedDesc, asc: false },
    { text: "Oldest to newest", func: sortCreatedAsc, asc: true },
    { text: "Name A to Z", func: sortNameAsc, asc: true },
    { text: "Name Z to A", func: sortNameDesc, asc: false },
    { text: "Largest to smallest", func: sortSizeDesc, asc: false },
    { text: "Smallest to largest", func: sortSizeAsc, asc: true },
  ];
  const selectedSortOption = sortOptions.find(({ func }) => func === sortFunc);
  const hasFiles = files && files.length > 0;
  const hasAvailableStorage = getAvailableWorkspaceStorage(workspace) > 0;

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputFiles = e.target.files;

    if (inputFiles && inputFiles.length) {
      setIsFileInputLoading(true);
      try {
        await uploadFiles(inputFiles);
      } catch (e) {}
      setIsFileInputLoading(false);
    }
  };

  return (
    <AppPageWithHeader>
      <MediaFileInput ref={fileInputRef} onChange={handleFileInputChange} />
      {hasFiles && (
        <div className="pt-4 px-4 w-full flex items-center flex-wrap">
          <PrimaryButton
            className="shrink-0"
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
            disabled={isFileInputLoading}
          >
            {isFileInputLoading ? (
              <LoaderIcon className="mr-2 shrink-0 h-6 w-6" />
            ) : (
              <UploadCloudIcon className="mr-2 shrink-0 h-6 w-6" />
            )}
            Upload media
          </PrimaryButton>

          <div
            className={`ml-4 flex items-center ${
              hasAvailableStorage ? "text-gray-400" : "text-red-500"
            }`}
          >
            <CloudIcon className="shrink-0 h-6 w-6" />
            <div className="ml-1">
              <div className="text-xs leading-3 font-bold">{`${displayUsedWorkspaceStorageAsPercent(
                workspace
              )} STORAGE USED`}</div>
              <div className="text-sm leading-4">
                {`${formatFileSizeForDisplay(
                  workspace.usedStorage || 0
                )} of ${formatFileSizeForDisplay(
                  getWorkspaceStorageLimit(workspace)
                )}`}
              </div>
            </div>
          </div>

          {!workspace.subscription && (
            <UpgradeSubscriptionButton
              className="ml-2"
              workspace={workspace}
              context="gallery"
            />
          )}

          <div className="ml-auto flex items-center">
            <DropdownMenu className="ml-2 flex-shrink-0" xAlign="right">
              <PageActionButton>
                {selectedSortOption && selectedSortOption.asc ? (
                  <SortAscendingIcon className="h-6 w-6" />
                ) : (
                  <SortDescendingIcon className="h-6 w-6" />
                )}
                {selectedSortOption && (
                  <span className="ml-1">{selectedSortOption.text}</span>
                )}
              </PageActionButton>
              <div className="p-2">
                {sortOptions.map(({ text, func, asc }) => {
                  const isSelected = sortFunc === func;
                  return (
                    <DropdownMenuButton
                      key={text}
                      className={`w-full flex items-center ${
                        isSelected ? "text-purple-500" : ""
                      }`}
                      onClick={() => setSortFunc(() => func)}
                    >
                      <span className="">{text}</span>
                      <span className="ml-auto pl-4">
                        {asc ? (
                          <SortAscendingIcon className="h-6 w-6" />
                        ) : (
                          <SortDescendingIcon className="h-6 w-6" />
                        )}
                      </span>
                    </DropdownMenuButton>
                  );
                })}
              </div>
            </DropdownMenu>
            <Select
              ButtonElement={PageActionButton}
              dropdownClassName="ml-2"
              toggleButtonClassName="font-bold"
              labelText="Filter accounts"
              hideLabel={true}
              hideDropdownIcon={false}
              dropdownXAlign="right"
              value={filter || ""}
              options={FILTER_OPTIONS.map((filterOption) => ({
                text: getFilterDisplayName(filterOption),
                value: filterOption || "",
              }))}
              onChange={(newValue) =>
                setFilter((newValue || null) as FilterOption)
              }
              renderSelectedOption={(selectedOption) => {
                return (
                  <div className="flex items-center w-full">
                    <FilterIcon className="flex-shrink-0 h-6 w-6" />
                    <span className="mx-2 truncate">
                      {selectedOption ? selectedOption.text : ""}
                    </span>
                    <ChevronDownIcon className="flex-shrink-0 h-5 w-5" />
                  </div>
                );
              }}
            />
            <div className="ml-2 w-64 flex-shrink-0 relative text-gray-500 default-transition focus-within:text-purple-500 hover:text-purple-500">
              <Input
                className="pl-9 py-2 border-2 border-transparent placeholder-gray-500 bg-gray-200 hover:bg-white focus:bg-white hover:border-purple-500"
                value={searchText}
                labelText="Search gallery"
                placeholder="Search gallery"
                hideLabel={true}
                debounceOnChange={setSearchText}
              />
              <div className="pl-2 absolute left-0 inset-y-0 flex items-center">
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="w-full flex flex-col items-center justify-center">
        {uploadError()}
      </div>

      {!isLoadingFiles && !hasFiles && (
        <div className="pt-20 w-full h-full flex justify-center">
          <div className="relative flex flex-col items-center">
            <div style={{ width: 600 }}>
              <H2>Got some media to share?</H2>
              <p className="mt-6">
                Upload your images and videos to Seenly and we'll keep them safe
                and secure until you're ready to share them with the world.
              </p>
            </div>
            <PrimaryButton
              className="mt-10"
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            >
              <UploadCloudIcon className="mr-2" />
              Upload your first image or video now
            </PrimaryButton>
            <NoFilesImage
              className="text-sky-200"
              style={{ width: 500, height: 500 }}
            />
          </div>
        </div>
      )}
      {!isLoadingFiles && hasFiles && (
        <div className="p-4 flex flex-wrap items-start justify-center">
          {sortedFiles.map((file) => {
            return (
              <InternalFileCard
                key={file.id}
                className="m-2"
                file={file}
                imageMaxWidth={MAX_IMAGE_WIDTH}
                imageMaxHeight={MAX_IMAGE_HEIGHT}
              />
            );
          })}
        </div>
      )}
      <animated.div
        className="z-10 fixed top-0 left-0 right-0 bottom-0 items-center justify-center"
        style={{
          display: dragSpring.opacity.to((o) => (o > 0 ? "flex" : "none")),
          opacity: dragSpring.opacity,
        }}
      >
        <div
          className="absolute top-0 left-0 w-full h-full flex bg-gray-900 opacity-25"
          style={{ zIndex: -1 }}
        ></div>
        <div className="p-10 w-1/2 h-1/2 flex items-center justify-center text-purple-900 bg-purple-50 rounded-lg border-4 border-dashed border-purple-300">
          <animated.div
            className="w-1/2 h-1/2 flex flex-col items-center justify-center"
            style={{
              display: dragSpring.processing.to((p) =>
                p > 0 ? "flex" : "none"
              ),
            }}
          >
            <LoaderIcon className="h-12 w-12" />
            <p className="mt-1">Processing your media files</p>
          </animated.div>
          <animated.div
            className="w-1/2 h-1/2 flex flex-col items-center justify-center"
            style={{
              display: dragSpring.processing.to((p) =>
                p > 0 ? "none" : "flex"
              ),
            }}
          >
            <UploadCloudIcon className="h-12 w-12" />
            <p className="mt-1">Drop your media files to upload them</p>
          </animated.div>
        </div>
      </animated.div>
    </AppPageWithHeader>
  );
};

export default GalleryPage;
