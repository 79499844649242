import React, { ComponentProps } from "react";
import { mergeClassNames } from "../../libs/components";

import Badge from "./Badge";

interface HighlightBadgeProps extends ComponentProps<typeof Badge> {}

const HighlightBadge: React.FC<HighlightBadgeProps> = ({
  className = "",
  ...props
}) => {
  return (
    <Badge
      className={mergeClassNames("bg-blue-100 text-blue-700", className)}
      {...props}
    />
  );
};

export default HighlightBadge;
