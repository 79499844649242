import Twitter from "twitter-text";
import { DraftDecorator } from "draft-js";
import {
  Build,
  BuildDecorators,
  DraftDecoratorComponentProps,
  ENTITY_TYPES,
} from "../../../../../libs/postTextArea";

const ENTITY_TYPE = ENTITY_TYPES.HASHTAG;

const strategy: DraftDecorator["strategy"] = (contentBlock, callback) => {
  const hashtags = Twitter.extractHashtagsWithIndices(contentBlock.getText());
  hashtags.forEach(({ indices }) => {
    callback(...indices);
  });
};

interface BuildSearchComponentProps {}
const buildSearchComponent =
  (props: BuildSearchComponentProps): React.FC<DraftDecoratorComponentProps> =>
  ({ children }) => {
    return <span className="text-purple-500">{children}</span>;
  };

const buildDecorators: BuildDecorators<BuildSearchComponentProps> = (props) => {
  return [
    {
      strategy,
      component: buildSearchComponent(props),
    },
  ];
};

const build: Build<BuildSearchComponentProps> = (props) => {
  return {
    type: ENTITY_TYPE,
    decorators: buildDecorators(props),
  };
};

export default build;
