import React, { useMemo } from "react";
import Twitter from "twitter-text";
import { DateTime } from "luxon";
import {
  Editor,
  ContentState,
  EditorState,
  CompositeDecorator,
  DraftDecorator,
} from "draft-js";
import {
  MessageCircle as MessageCircleIcon,
  Repeat as RepeatIcon,
  Heart as HeartIcon,
  Upload as UploadIcon,
  BarChart2 as BarChart2Icon,
} from "react-feather";
import { DotsHorizontalIcon } from "@heroicons/react/outline";

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import "draft-js/dist/Draft.css";

import { PlatformEntity } from "../../../types/platforms";
import { MentionEntityData, PostAttachment } from "../../../types/posts";
import { mergeClassNames } from "../../../libs/components";
import {
  buildContentStateForPlatformType,
  POST_PREVIEW_WIDTH,
} from "../../../libs/post";
import {
  DraftDecoratorComponentProps,
  ENTITY_TYPES,
  getBlockPlainTextOffset,
} from "../../../libs/postTextArea";

import TwitterImages from "./twitter/Images";
import TwitterLink from "./twitter/Link";
import TwitterVideo from "./twitter/Video";

import PlatformEntityProfilePicture from "../../images/profile/PlatformEntity";

const COLOURS = {
  BLACK: "rgb(15, 20, 25)",
  BORDER_GRAY: "rgb(239, 243, 244)",
  IMAGE_BORDER_GRAY: "rgb(207, 217, 222)",
  GRAY: "rgb(83, 100, 113)",
  BLUE: "rgb(29, 155, 240)",
};

const WIDTH = POST_PREVIEW_WIDTH.TWITTER;
const MAX_VIDEO_HEIGHT = 506;
const MAX_URL_LENGTH = 25;

const HASHTAG_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback) => {
    const hashtags = Twitter.extractHashtagsWithIndices(contentBlock.getText());
    hashtags.forEach(({ indices }) => {
      callback(...indices);
    });
  },
  component: ({ children }: DraftDecoratorComponentProps) => (
    <span style={{ color: COLOURS.BLUE }}>{children}</span>
  ),
};

const MENTION_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      const entity = entityKey ? contentState.getEntity(entityKey) : null;

      if (!!entity && entity.getType() === ENTITY_TYPES.MENTION) {
        const entityData = entity
          ? (entity.getData() as MentionEntityData)
          : null;

        return !!entityData && !!entityData.mentions["TWITTER"];
      }

      return false;
    }, callback);

    const mentions = Twitter.extractMentionsWithIndices(contentBlock.getText());
    mentions.forEach(({ indices }) => {
      if (!contentBlock.getEntityAt(indices[0])) {
        callback(...indices);
      }
    });
  },
  component: ({
    contentState,
    entityKey,
    decoratedText,
  }: DraftDecoratorComponentProps) => {
    let mentionText = decoratedText;

    if (entityKey) {
      const entity = contentState.getEntity(entityKey!);
      const entityData = entity.getData() as MentionEntityData;
      mentionText = entityData.mentions["TWITTER"]!.text;
    }

    return <span style={{ color: COLOURS.BLUE }}>{mentionText}</span>;
  },
};

const LINK_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback) => {
    const urls = Twitter.extractUrlsWithIndices(contentBlock.getText());
    urls.forEach(({ indices }) => {
      callback(...indices);
    });
  },
  component: ({ children, decoratedText }: DraftDecoratorComponentProps) => {
    const urlParts = decoratedText.match(Twitter.regexen.validateUrlUnencoded);

    if (!urlParts) {
      return children;
    }

    const urlPartsWithoutProtocol = urlParts.slice(2);
    const urlWithoutProtocol = urlPartsWithoutProtocol.join("");
    const displayUrl =
      urlWithoutProtocol.length > MAX_URL_LENGTH
        ? `${urlWithoutProtocol.substring(0, MAX_URL_LENGTH)}…`
        : urlWithoutProtocol;
    return <span style={{ color: COLOURS.BLUE }}>{displayUrl}</span>;
  },
};

function buildMaxLengthDecorator(
  twitterContentState: ContentState
): DraftDecorator {
  const { validRangeEnd } = Twitter.parseTweet(
    twitterContentState.getPlainText()
  );

  return {
    strategy: (contentBlock, callback, contentState) => {
      const blockOffset = getBlockPlainTextOffset(
        contentState,
        contentBlock.getKey()
      );
      const start = Math.max(0, validRangeEnd - blockOffset + 1);

      if (start < contentBlock.getText().length) {
        callback(start, contentBlock.getText().length);
      }
    },
    component: ({ children }: DraftDecoratorComponentProps) => {
      return <span className="text-red-500">{children}</span>;
    },
  };
}

function buildDecorator(contentState: ContentState) {
  return new CompositeDecorator([
    HASHTAG_DECORATOR,
    MENTION_DECORATOR,
    LINK_DECORATOR,
    buildMaxLengthDecorator(contentState),
  ]);
}

interface TwitterPostPreviewProps {
  platformEntity: PlatformEntity;
  scheduled: string;
  contentState: ContentState;
  attachment?: PostAttachment;
  className?: string;
}
const TwitterPostPreview: React.FC<TwitterPostPreviewProps> = ({
  platformEntity,
  scheduled,
  contentState,
  attachment,
  className = "",
}) => {
  const scheduledDateTime = DateTime.fromISO(scheduled);
  const twitterContentState = useMemo(() => {
    return buildContentStateForPlatformType("TWITTER", contentState);
  }, [contentState]);
  const decorator = useMemo(() => {
    return buildDecorator(twitterContentState);
  }, [twitterContentState]);
  const imageAttachments =
    attachment && attachment.type === "IMAGE" ? attachment.images : [];
  const hasImageAttachments = imageAttachments.length > 0;

  return (
    <div
      className={mergeClassNames(
        "px-4 py-3 flex leading-5 bg-white font-twitter",
        className
      )}
      style={{
        border: `1px solid ${COLOURS.BORDER_GRAY}`,
        color: COLOURS.BLACK,
        width: WIDTH,
        fontSize: 15,
      }}
    >
      <div className="mr-3 w-12 shrink-0">
        <PlatformEntityProfilePicture
          className="w-12 h-12"
          entity={platformEntity}
        />
      </div>
      <div className="grow overflow-hidden">
        <div className="mb-0.5 flex items-center justify-between">
          <div className="flex items-baseline">
            <div className="font-bold">{platformEntity.name}</div>
            <div className="ml-1" style={{ color: COLOURS.GRAY }}>
              {`@${platformEntity.username || "handle"}`}
            </div>
            <div className="px-1" style={{ color: COLOURS.GRAY }}>
              ·
            </div>
            <time dateTime={scheduled} style={{ color: COLOURS.GRAY }}>
              {scheduledDateTime.toFormat("MMM d")}
            </time>
          </div>
          <div className="ml-5 h-5">
            <DotsHorizontalIcon
              style={{
                height: 18.75,
                width: 18.75,
                color: COLOURS.GRAY,
              }}
            />
          </div>
        </div>

        <Editor
          editorState={EditorState.createWithContent(
            twitterContentState,
            decorator
          )}
          readOnly={true}
          onChange={() => {}}
        />

        {hasImageAttachments && (
          <TwitterImages className="mt-3" imageAttachments={imageAttachments} />
        )}

        {attachment && attachment.type === "LINK" && (
          <TwitterLink className="mt-3" linkAttachment={attachment.link} />
        )}

        {attachment && attachment.type === "VIDEO" && (
          <TwitterVideo
            className="mt-3"
            videoAttachment={attachment.video}
            maxHeight={MAX_VIDEO_HEIGHT}
          />
        )}

        <div
          className="mt-3 flex items-stretch justify-between"
          style={{ maxWidth: 425, color: COLOURS.GRAY, fontSize: 13 }}
        >
          <div className="h-5 flex items-center">
            <MessageCircleIcon style={{ height: 18.75, width: 18.75 }} />
            <div className="px-3" style={{ minWidth: "calc(1em + 24px)" }}>
              1
            </div>
          </div>
          <div className="h-5 flex items-center">
            <RepeatIcon style={{ height: 18.75, width: 18.75 }} />
            <div className="px-3" style={{ minWidth: "calc(1em + 24px)" }}>
              2
            </div>
          </div>
          <div className="h-5 flex items-center">
            <HeartIcon style={{ height: 18.75, width: 18.75 }} />
            <div className="px-3" style={{ minWidth: "calc(1em + 24px)" }}>
              3
            </div>
          </div>
          <div className="h-5 flex items-center">
            <UploadIcon style={{ height: 18.75, width: 18.75 }} />
            <div style={{ minWidth: "calc(1em + 24px)" }}></div>
          </div>
          <div className="h-5 flex items-center">
            <BarChart2Icon style={{ height: 18.75, width: 18.75 }} />
            <div style={{ minWidth: "calc(1em + 24px)" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitterPostPreview;
