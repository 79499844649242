import React, { forwardRef, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { nanoid } from "nanoid";
import { mergeClassNames } from "../../libs/components";

const COLOURS = {
  THUMB_CHECKED: "#9f7aea",
  THUMB_SHADOW_CHECKED: "rgba(159, 122, 234, 0.2)",
  SLIDER_CHECKED: "#e9d8fd",
  THUMB_UNCHECKED: "#f7fafc",
  THUMB_SHADOW_UNCHECKED: "rgba(160, 174, 192, 0.2)",
  SLIDER_UNCHECKED: "#a0aec0",
};

const CHECKED_STYLE = {
  thumb: COLOURS.THUMB_CHECKED,
  shadow: COLOURS.THUMB_SHADOW_CHECKED,
  slider: COLOURS.SLIDER_CHECKED,
  x: 1.75,
};

const UNCHECKED_STYLE = {
  thumb: COLOURS.THUMB_UNCHECKED,
  shadow: COLOURS.THUMB_SHADOW_UNCHECKED,
  slider: COLOURS.SLIDER_UNCHECKED,
  x: 0,
};

interface SwitchProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type"
  > {}

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      id = nanoid(),
      onChange,
      className = "",
      disabled = false,
      checked = false,
      ...inputProps
    },
    ref
  ) => {
    const [spring, springApi] = useSpring(() =>
      checked ? CHECKED_STYLE : UNCHECKED_STYLE
    );

    useEffect(() => {
      springApi.start(checked ? CHECKED_STYLE : UNCHECKED_STYLE);
    }, [checked, springApi]);

    return (
      <label
        className="group cursor-pointer relative"
        htmlFor={id}
        tabIndex={0}
      >
        <input
          ref={ref}
          id={id}
          className="sr-only"
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          checked={checked}
          {...inputProps}
        />
        <div
          className={mergeClassNames(
            `p-2 relative inline-block ${
              disabled ? "opacity-50 cursor-not-allowed" : ""
            }`,
            className
          )}
        >
          <animated.div
            className="absolute left-0 inset-y-0 flex items-center"
            style={{
              transform: spring.x.to((x) => `translate3d(${x}rem, 0, 0)`),
            }}
          >
            <animated.div
              className="rounded-full h-5 w-5 shadow"
              style={{
                backgroundColor: spring.thumb,
              }}
            ></animated.div>
            {!disabled && (
              <animated.div
                className="rounded-full p-2 opacity-0 group-hover:opacity-100 group-focus:opacity-100"
                style={{
                  zIndex: -1,
                  marginLeft: "-1.75rem",
                  backgroundColor: spring.shadow,
                }}
              >
                <div className="h-5 w-5"></div>
              </animated.div>
            )}
          </animated.div>
          <animated.div
            className="rounded-full w-8 h-3"
            style={{ backgroundColor: spring.slider }}
          ></animated.div>
        </div>
      </label>
    );
  }
);

export default Switch;
