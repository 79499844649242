import React from "react";

// For classes that don't follow the typical tailwind prefix format.
const CLASS_NAME_OVERRIDE_EXCEPTIONS: Record<string, string[]> = {
  "no-underline": ["underline", "no-underline"],
  underline: ["underline", "no-underline"],
};

const MERGE_CLASS_NAMES_CACHE: Record<string, string> = {};
const CLASS_PARTS_CACHE: {
  [className: string]: {
    pseudoPrefix: string | null;
    className: string;
    classNameParts: string[];
    longClassNamePrefix: string;
    isColourClass: boolean;
  };
} = {};

const COLOUR_CLASS_REGEX = /^[a-z]+-([a-z]+-[0-9]+|transparent|black|white)$/;

function parseClassName(rawClassName: string) {
  if (!CLASS_PARTS_CACHE[rawClassName]) {
    const stringParts = rawClassName.split(":");
    const pseudoPrefix =
      stringParts.length > 1 ? stringParts.slice(0, -1).join(":") : null;
    const className =
      stringParts.length > 1
        ? stringParts[stringParts.length - 1]
        : stringParts[0];
    const classNameParts = className.split("-");
    const longClassNamePrefix = classNameParts.slice(0, -1).join("-");
    const isColourClass = COLOUR_CLASS_REGEX.test(className);

    CLASS_PARTS_CACHE[rawClassName] = {
      pseudoPrefix,
      className,
      classNameParts,
      longClassNamePrefix,
      isColourClass,
    };
  }

  return CLASS_PARTS_CACHE[rawClassName];
}

function doClassesMatch(a: string, b: string) {
  const aParsed = parseClassName(a);
  const bParsed = parseClassName(b);

  if (aParsed.pseudoPrefix !== bParsed.pseudoPrefix) {
    return false;
  }

  if (aParsed.classNameParts[0] !== bParsed.classNameParts[0]) {
    return false;
  }

  if (aParsed.isColourClass && !bParsed.isColourClass) {
    return false;
  }

  if (!aParsed.isColourClass && bParsed.isColourClass) {
    return false;
  }

  if (aParsed.isColourClass && bParsed.isColourClass) {
    return true;
  }

  return aParsed.longClassNamePrefix === bParsed.longClassNamePrefix;
}

export const mergeClassNames = (base: string, overrides: string) => {
  const key = base + overrides;

  if (MERGE_CLASS_NAMES_CACHE[key] === undefined) {
    const overrideMatchChecks: Array<(className: string) => boolean> = overrides
      .split(" ")
      .map((override) => {
        if (CLASS_NAME_OVERRIDE_EXCEPTIONS[override]) {
          return (className) =>
            CLASS_NAME_OVERRIDE_EXCEPTIONS[override].includes(className);
        } else {
          return (className) => doClassesMatch(className, override);
        }
      });

    const filteredBaseClassNames = base
      .split(" ")
      .filter(
        (className) =>
          !overrideMatchChecks.some((isMatch) => isMatch(className))
      )
      .join(" ");

    MERGE_CLASS_NAMES_CACHE[key] = `${filteredBaseClassNames} ${overrides}`;
  }

  return MERGE_CLASS_NAMES_CACHE[key];
};

export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref !== null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
