import React, { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";

import { useCurrentUser } from "../../libs/hooks/app";

import Modal from "../../components/Modal";
import H2 from "../../components/headings/H2";
import PrimaryButton from "../../components/buttons/Primary";
import Input from "../../components/form/Input";
import { useUpdateWorkspace } from "../../libs/hooks/workspaces";
import { useUpdateUserPreferences } from "../../libs/hooks/userPreferences";
import { Workspace } from "../../types/workspaces";

interface UpdateWorkspaceModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  close: () => void;
  workspace: Workspace;
}

type FormValues = {
  name: string;
};

const formSchema = Joi.object({
  name: Joi.string().required(),
});

const UpdateWorkspaceModal: React.FC<UpdateWorkspaceModalProps> = ({
  isVisible,
  close,
  workspace,
  ...modalProps
}) => {
  const user = useCurrentUser();
  const {
    mutateAsync: updateWorkspace,
    isLoading: isLoadingUpdateWorkspace,
    loadingText: loadingTextUpdateWorkspace,
    errorAlert,
  } = useUpdateWorkspace();
  const {
    mutateAsync: updateUserPreferences,
    isLoading: isLoadingUpdatePreferences,
    loadingText: loadingTextUpdatePreferences,
  } = useUpdateUserPreferences();
  const isLoading = isLoadingUpdateWorkspace || isLoadingUpdatePreferences;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema),
    defaultValues: {
      name: workspace.name,
    },
  });

  useEffect(() => {
    if (isVisible) {
      setFocus("name");
    }
  }, [isVisible, setFocus, workspace.name]);

  const editWorkspace = async (data: FormValues) => {
    const returnedWorkspace = await updateWorkspace({
      workspace,
      updateProps: data,
    });
    await updateUserPreferences({
      userId: user.id,
      updateProps: { activeWorkspaceId: returnedWorkspace.workspace.id },
    });
    close();
  };

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="p-8 relative" style={{ width: 400 }}>
        <H2 className="mb-4 text-2xl text-black w-full">Update Workspace</H2>

        {errorAlert({ className: "mt-4" })}

        <form onSubmit={handleSubmit(editWorkspace)}>
          <Input
            {...register("name")}
            labelText="Workspace name"
            labelClassName="mt-4"
            type="text"
            error={!!errors.name}
            style={{ resize: "none" }}
            disabled={isLoading}
          />
          <PrimaryButton
            type="submit"
            className="mt-4 w-full"
            disabled={isLoading}
          >
            {isLoadingUpdateWorkspace
              ? loadingTextUpdateWorkspace({
                  loading: "Updating...",
                  default: "Update",
                })
              : isLoadingUpdatePreferences
              ? loadingTextUpdatePreferences({
                  loading: "Updating...",
                  default: "Update",
                })
              : "Update"}
          </PrimaryButton>
        </form>
      </div>
    </Modal>
  );
};

export default UpdateWorkspaceModal;
