import React, { ReactNode } from "react";

import AppHeader from "./AppHeader";
import AppPage from "./AppPage";

interface AppPageWithHeaderProps {
  children: ReactNode;
}
const AppPageWithHeader: React.FC<AppPageWithHeaderProps> = ({ children }) => {
  return (
    <AppPage>
      <AppHeader />
      <div className="w-full h-full overflow-auto">{children}</div>
    </AppPage>
  );
};

export default AppPageWithHeader;
