import React from "react";
import { ChevronDown as ChevronDownIcon } from "react-feather";

import { Platform } from "../../../../types/platforms";
import { FormPublishedPostActionLinkedInReaction } from "../../../../types/publishedPostActions";

import {
  getDisplayNameForLinkedInReactionType,
  getDisplayNameForPublishedPostActionInstance,
} from "../../../../libs/publishedPostActions";

import ActionContainer, {
  ActionContainerErrors,
} from "../base/ActionContainer";
import Select from "../../../../components/form/Select";
import PublishedPostActionLinkedInReactionIcon from "../../../../components/icons/PublishedPostActionLinkedInReaction";
import PublishedPostActionInstanceIcon from "../../../../components/icons/PublishedPostActionInstance";

const REACTION_OPTIONS: Array<{
  text: string;
  value: FormPublishedPostActionLinkedInReaction["reactionType"];
}> = [
  {
    text: getDisplayNameForLinkedInReactionType("LIKE"),
    value: "LIKE",
  },
  {
    text: getDisplayNameForLinkedInReactionType("PRAISE"),
    value: "PRAISE",
  },
  {
    text: getDisplayNameForLinkedInReactionType("ENTERTAINMENT"),
    value: "ENTERTAINMENT",
  },
  {
    text: getDisplayNameForLinkedInReactionType("EMPATHY"),
    value: "EMPATHY",
  },
  {
    text: getDisplayNameForLinkedInReactionType("INTEREST"),
    value: "INTEREST",
  },
  {
    text: getDisplayNameForLinkedInReactionType("APPRECIATION"),
    value: "APPRECIATION",
  },
];

interface LinkedInCommentErrors extends ActionContainerErrors {
  reactionType?: any;
}

interface PublishedPostActionsFormLinkedInReactionProps {
  platforms: Platform[];
  value: FormPublishedPostActionLinkedInReaction;
  onChange: (newValue: FormPublishedPostActionLinkedInReaction) => void;
  onDelete: () => void;
  className?: string;
  errors?: LinkedInCommentErrors;
}
const PublishedPostActionsFormLinkedInReaction: React.FC<
  PublishedPostActionsFormLinkedInReactionProps
> = ({ platforms, value, onChange, onDelete, errors, className = "" }) => {
  return (
    <ActionContainer
      className={className}
      title={
        <span className="flex items-center">
          <PublishedPostActionInstanceIcon
            className="h-6 w-6"
            platformType="LINKEDIN"
            type="REACTION"
          />
          <span className="ml-1">
            {getDisplayNameForPublishedPostActionInstance(
              "LINKEDIN",
              "REACTION"
            )}
          </span>
        </span>
      }
      platforms={platforms}
      value={value}
      errors={errors}
      onChange={(newValue) => {
        onChange({
          ...value,
          ...newValue,
        });
      }}
      onDelete={onDelete}
    >
      <div className="flex items-center">
        <span>React to the post with</span>
        <Select
          toggleButtonClassName="ml-2 px-2 py-1"
          labelText="Reaction type"
          options={REACTION_OPTIONS}
          value={value.reactionType}
          onChange={(newReactionType) => {
            onChange({
              ...value,
              reactionType:
                newReactionType as FormPublishedPostActionLinkedInReaction["reactionType"],
            });
          }}
          error={!!errors && !!errors.reactionType}
          hideLabel={true}
          renderOption={({ text, value }) => {
            return (
              <div className="flex items-center">
                <PublishedPostActionLinkedInReactionIcon
                  reactionType={
                    value as FormPublishedPostActionLinkedInReaction["reactionType"]
                  }
                />

                <span className="ml-2">{text}</span>
              </div>
            );
          }}
          renderSelectedOption={(option) => {
            if (!option) {
              return null;
            }

            return (
              <div className="flex items-center">
                <PublishedPostActionLinkedInReactionIcon
                  reactionType={
                    option.value as FormPublishedPostActionLinkedInReaction["reactionType"]
                  }
                />
                <span className="ml-2">{option.text}</span>
                <ChevronDownIcon className="shrink-0 ml-2" />
              </div>
            );
          }}
        />
      </div>
    </ActionContainer>
  );
};

export default PublishedPostActionsFormLinkedInReaction;
