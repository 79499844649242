import { createContext } from "react";
import { InternalFile } from "../../types/files";
import { AddPlatformContent } from "../../types/modals/AddPlatform";
import { PaymentMethod } from "../../types/paymentMethods";
import { Platform, PlatformEntity } from "../../types/platforms";
import { Post, PostFormValues } from "../../types/posts";
import { PostQueue } from "../../types/postQueues";
import { Subscription } from "../../types/subscriptions";
import { Workspace } from "../../types/workspaces";

export interface BaseModalProps {
  isVisible: boolean;
}

export interface SimpleModals {
  addWorkspace: BaseModalProps;
  verifyEmailAddress: BaseModalProps;
  changePassword: BaseModalProps;
  deleteUserAccount: BaseModalProps;
  explainSmartSchedules: BaseModalProps;
}

export interface ComplexModals {
  addPlatform: BaseModalProps & {
    defaultContentId?: AddPlatformContent["id"];
  };
  createPost: BaseModalProps & {
    forceLoading?: boolean;
    defaultValues?: Partial<PostFormValues>;
  };
  deletePlatform: BaseModalProps & { platform: Platform };
  viewInternalFile: BaseModalProps & { file: InternalFile };
  deletePost: BaseModalProps & {
    post: Post;
    platform: Platform;
  };
  editPost: BaseModalProps & {
    post: Post;
    platform: Platform;
  };
  viewPost: BaseModalProps & {
    post: Post;
    platform: Platform;
  };
  deleteSubscription: BaseModalProps & {
    subscription: Subscription;
  };
  deletePaymentMethod: BaseModalProps & {
    paymentMethod: PaymentMethod;
    workspaces: Workspace[];
  };
  changeSubscription: BaseModalProps & {
    workspace: Workspace;
    redirectOnSuccess?: boolean;
    currentPriceId?: string;
    defaultPriceId?: string;
    limitExceeded?: boolean;
  };
  updateWorkspace: BaseModalProps & {
    workspace: Workspace;
  };
  explainSmartSchedulesState: BaseModalProps & {
    platform: Platform;
    platformEntity: PlatformEntity;
    postQueue:
      | PostQueue & {
          state: NonNullable<PostQueue["state"]>;
        };
  };
  confirmClose: BaseModalProps & {
    onConfirm: () => void;
  };
  multiPostPublishedPostActions: BaseModalProps & {
    posts: Post[];
  };
  editPublishedPostActions: BaseModalProps & {
    post: Post;
  };
  viewPublishedPostActions: BaseModalProps & {
    post: Post;
  };
}

export type WorkspaceAppModals = Partial<SimpleModals & ComplexModals>;

export type WorkspaceAppDrawers = Partial<{
  navigation: {
    isVisible: boolean;
  };
}>;

type SetModal = <T extends keyof WorkspaceAppModals>(
  name: T,
  values: WorkspaceAppModals[T]
) => void;

type ShowModal = <T extends keyof WorkspaceAppModals>(
  name: T,
  ...values: T extends keyof ComplexModals
    ? [Omit<ComplexModals[T], "isVisible">]
    : []
) => void;

type HideModal = <T extends keyof WorkspaceAppModals>(
  name: T,
  values?: T extends keyof ComplexModals
    ? Omit<ComplexModals[T], "isVisible">
    : never
) => void;

type SetDrawer = <T extends keyof WorkspaceAppDrawers>(
  name: T,
  values: WorkspaceAppDrawers[T]
) => void;

type ShowDrawer = <T extends keyof WorkspaceAppDrawers>(name: T) => void;

type HideDrawer = <T extends keyof WorkspaceAppDrawers>(name: T) => void;

export type WorkspaceAppContextState = {
  activeWorkspace: Workspace;
  fileAccessToken: string | null;
  timeZone: string;
  modals: WorkspaceAppModals;
  drawers: WorkspaceAppDrawers;
  setModal: SetModal;
  showModal: ShowModal;
  hideModal: HideModal;
  setDrawer: SetDrawer;
  showDrawer: ShowDrawer;
  hideDrawer: HideDrawer;
};

const FAKE_WORKSPACE = {
  id: "1",
  name: "Default",
  users: {},
  created: "",
  createdBy: "system",
  modified: "",
  modifiedBy: "system",
};

const WorkspaceAppContext = createContext<WorkspaceAppContextState>({
  activeWorkspace: FAKE_WORKSPACE,
  fileAccessToken: null,
  timeZone: "UTC",
  modals: {},
  drawers: {},
  setModal: () => {},
  showModal: () => {},
  hideModal: () => {},
  setDrawer: () => {},
  showDrawer: () => {},
  hideDrawer: () => {},
});

export default WorkspaceAppContext;
