import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ApiResponse } from "../../types/general";
import { Workspace } from "../../types/workspaces";
import { WORKSPACES_QUERY_KEYS } from "../queryKeys";
import { createQueryCacheObject, updateQueryCacheObject } from "../queryCache";

import { useApiClient } from "./app";
import { useDecoratedReactQuery } from "./general";

export const useWorkspaces = (userId: string) => {
  const apiClient = useApiClient();
  return useQuery(WORKSPACES_QUERY_KEYS.list({ userId }), async () => {
    const { data: response } = await apiClient.get<
      ApiResponse<
        Workspace[],
        {
          fileAccessTokens: {
            [workspaceId: string]: string | null;
          };
        }
      >
    >("/workspaces", {
      params: {
        userId,
      },
    });

    return {
      workspaces: response.data,
      fileAccessTokens: response.meta.fileAccessTokens,
    };
  });
};

export const useWorkspace = (workspaceId: string) => {
  const apiClient = useApiClient();
  return useQuery(WORKSPACES_QUERY_KEYS.detail(workspaceId), async () => {
    const { data: response } = await apiClient.get<
      ApiResponse<
        Workspace,
        {
          fileAccessToken: string | null;
        }
      >
    >(`/workspaces/${workspaceId}`);

    return {
      workspace: response.data,
      fileAccessToken: response.meta.fileAccessToken,
    };
  });
};

interface CreateWorkspaceProps {
  name: string;
}
export const useCreateWorkspace = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (props: CreateWorkspaceProps) => {
        const { data: response } = await apiClient.post<
          ApiResponse<
            Workspace,
            {
              fileAccessToken: string | null;
            }
          >
        >("/workspaces", props);

        return {
          workspace: response.data,
          fileAccessToken: response.meta.fileAccessToken,
        };
      },
      {
        onSuccess: ({ workspace }) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: {
                dataProp: "workspace",
                queryKey: WORKSPACES_QUERY_KEYS.detail(workspace.id),
              },
              lists: [
                {
                  dataProp: "workspaces",
                  queryKey: WORKSPACES_QUERY_KEYS.list({
                    userId: workspace.createdBy,
                  }),
                },
              ],
            },
            workspace.id,
            workspace
          );
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.all);

          // TODO: Modify the users object to add the workspace to those values.
        },
      }
    ),
    () => ({ title: "Failed to create workspace" })
  );
};

interface UpdateWorkspaceProps {
  workspace: Workspace;
  updateProps: {
    name: string;
  };
}
export const useUpdateWorkspace = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (props: UpdateWorkspaceProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<
            Workspace,
            {
              fileAccessToken: string | null;
            }
          >
        >(`/workspaces/${props.workspace.id}`, props.updateProps);

        return {
          workspace: response.data,
          fileAccessToken: response.meta.fileAccessToken,
        };
      },
      {
        onSuccess: ({ workspace }) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: {
                dataProp: "workspace",
                queryKey: WORKSPACES_QUERY_KEYS.detail(workspace.id),
              },
              lists: [
                {
                  dataProp: "workspaces",
                  queryKey: WORKSPACES_QUERY_KEYS.list({
                    userId: workspace.createdBy,
                  }),
                },
              ],
            },
            workspace.id,
            () => workspace
          );
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
        },
      }
    ),
    () => ({ title: "Failed to update workspace" })
  );
};
