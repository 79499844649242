import { Platform } from "../types/platforms";
import {
  fetchPlatformOAuthResponse,
  purgePlatformOAuthResponse,
} from "./localStorage";
import { getPlatformTypeName } from "./platform";
import { openPopupWindow } from "./utils";

const WINDOW_NAME = "PLATFORM_OAUTH";

export function openPlatformOAuthWindow(
  platformType: Platform["type"],
  url: string
) {
  return new Promise<URLSearchParams>((resolve, reject) => {
    // Clear any existing stuff from the local storage.
    purgePlatformOAuthResponse();

    const platformTypeName = getPlatformTypeName(platformType);
    const popupWindow = openPopupWindow({ url, windowName: WINDOW_NAME });

    if (popupWindow) {
      const intervalId = window.setInterval(() => {
        // Due to Facebook being a piece of shit and nuking the window object
        // when it's auth dialog opens we have to resort to putting data in the
        // browser local storage and then polling for it because we have no other
        // way to communicate betweek the popup window and this one. Fuck Facebook.
        const responseData = fetchPlatformOAuthResponse();

        if (responseData) {
          window.clearInterval(intervalId);
          purgePlatformOAuthResponse();

          if (responseData.platformType === platformType) {
            // It's a response from the platform we were expecting. They
            // all response with query params so let's parse them and send
            // them back.
            resolve(new URLSearchParams(responseData.response));
          } else {
            // Something is wrong.
            reject(`Unable to get response from ${platformTypeName}`);
          }
        }
      }, 300);
    } else {
      reject(
        `Unable to connect to ${platformTypeName}. Please enable popups for this page in your browser.`
      );
    }
  });
}
