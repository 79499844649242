import React from "react";
import { mergeClassNames } from "../../libs/components";

interface BadgeProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {}
const Badge: React.FC<BadgeProps> = ({ className = "", ...props }) => {
  return (
    <span
      className={mergeClassNames("px-2 py-1 text-sm rounded-lg", className)}
      {...props}
    />
  );
};

export default Badge;
