import React, { ReactNode, useMemo } from "react";
import { ContentState } from "draft-js";
import { nanoid } from "nanoid";
import { MessageSquare as MessageSquareIcon } from "react-feather";

import { Platform } from "../../types/platforms";
import { PostFacebook } from "../../types/posts";
import { FormPublishedPostActionFacebookComment } from "../../types/publishedPostActions";

import OutlineButton from "../../components/buttons/Outline";

import { facebookActionSchema } from "./publishedPostActions/schemas";
import FormContainer from "./publishedPostActions/base/FormContainer";
import FacebookComment from "./publishedPostActions/facebook/Comment";

type FormFacebookAction = FormPublishedPostActionFacebookComment;

export type FacebookFormValues = {
  actions: Array<FormFacebookAction>;
};

interface FacebookPublishedPostActionsFormProps {
  post: PostFacebook;
  platforms: Platform[];
  defaultValues: FacebookFormValues;
  onSubmit: (data: FacebookFormValues) => void;
  isSubmitting: boolean;
  endOfFormElement?: ReactNode;
  onIsDirty?: (newIsDirty: boolean) => void;
  className?: string;
}
const FacebookPublishedPostActionsForm: React.FC<
  FacebookPublishedPostActionsFormProps
> = ({
  post,
  platforms,
  defaultValues,
  onSubmit,
  isSubmitting,
  endOfFormElement,
  onIsDirty,
  className = "",
}) => {
  const facebookPlatforms = useMemo(() => {
    return platforms.filter((candidate) => candidate.type === "FACEBOOK");
  }, [platforms]);

  return (
    <FormContainer
      className={className}
      onSubmit={onSubmit}
      onIsDirty={onIsDirty}
      isSubmitting={isSubmitting}
      actionSchema={facebookActionSchema}
      defaultValues={defaultValues}
      endOfFormElement={endOfFormElement}
      renderActionButtons={(
        createAction: (newAction: Partial<FormFacebookAction>) => void
      ) => {
        return (
          <div className="mt-2 shrink-0 w-full">
            <OutlineButton
              className="flex items-center"
              onClick={() =>
                createAction({
                  id: nanoid(),
                  postId: post.id,
                  platformType: "FACEBOOK",
                  type: "COMMENT",
                  contentState: ContentState.createFromText(""),
                })
              }
              disabled={isSubmitting}
            >
              <MessageSquareIcon className="h-6 w-6" />
              <span className="ml-2">Add comment</span>
            </OutlineButton>
          </div>
        );
      }}
      renderAction={(
        action: FormFacebookAction,
        onChange,
        onDelete,
        errors
      ) => {
        switch (action.type) {
          case "COMMENT":
            return (
              <FacebookComment
                platforms={facebookPlatforms}
                value={action}
                errors={errors}
                onChange={onChange}
                onDelete={onDelete}
              />
            );
        }
      }}
    />
  );
};

export default FacebookPublishedPostActionsForm;
