import { ReactNode } from "react";
import {
  MessageSquare as MessageSquareIcon,
  CornerUpRight as CornerUpRightIcon,
  Link as LinkIcon,
  MessageCircle as MessageCircleIcon,
  Repeat as RepeatIcon,
  Heart as HeartIcon,
  Activity as ActivityIcon,
} from "react-feather";
import {
  SearchIcon,
  CursorClickIcon,
  ChatAlt2Icon,
  UserCircleIcon,
  UserGroupIcon,
  ThumbUpIcon,
  ChatAltIcon,
} from "@heroicons/react/outline";

import {
  RawPostMetricsFacebook,
  RawPostMetricsLinkedIn,
  RawPostMetricsTwitter,
} from "../types/postAnalytics";

export const BG_COLOUR_CLASS_NAMES = {
  RED: "bg-gradient-to-br from-red-500 to-pink-300",
  AMBER: "bg-gradient-to-br from-amber-500 to-yellow-200",
  GREEN: "bg-gradient-to-br from-green-300 to-lime-300",
  PINK: "bg-gradient-to-br from-pink-400 to-fuchsia-400",
  BLUE: "bg-gradient-to-br from-sky-400 to-cyan-300",
  ORANGE: "bg-gradient-to-br from-orange-400 to-yellow-400",
  INDIGO: "bg-gradient-to-br from-indigo-400 to-purple-400",
  PURPLE: "bg-gradient-to-br from-purple-400 to-fuchsia-400",
  CYAN: "bg-gradient-to-br from-cyan-500 to-teal-400",
};

export const BG_COLOUR_HEX = {
  RED: "#ef4444",
  AMBER: "#f59e0b",
  GREEN: "#bef264",
  PINK: "#f472b6",
  BLUE: "#38bdf8",
  ORANGE: "#fb923c",
  INDIGO: "#818cf8",
  PURPLE: "#c084fc",
  CYAN: "#06b6d4",
};

export interface MetricPropConfig {
  bgClassNames: string;
  bgHex: string;
  icon: ReactNode;
  title: string;
}

export const INTERACTIONS_CONFIG: MetricPropConfig = {
  bgClassNames: BG_COLOUR_CLASS_NAMES.CYAN,
  bgHex: BG_COLOUR_HEX.CYAN,
  icon: <ActivityIcon />,
  title: "Interactions",
};

export const LINKEDIN_METRICS_CONFIG: {
  [prop in NonNullable<keyof RawPostMetricsLinkedIn>]: MetricPropConfig;
} = {
  likeCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.PINK,
    bgHex: BG_COLOUR_HEX.PINK,
    icon: <ThumbUpIcon style={{ transform: "scale(-1,1)" }} />,
    title: "Likes",
  },
  commentCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.BLUE,
    bgHex: BG_COLOUR_HEX.BLUE,
    icon: <ChatAltIcon style={{ transform: "scale(-1,1)" }} />,
    title: "Comments",
  },
  clickCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.ORANGE,
    bgHex: BG_COLOUR_HEX.ORANGE,
    icon: <CursorClickIcon />,
    title: "Clicks",
  },
  impressionCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.GREEN,
    bgHex: BG_COLOUR_HEX.GREEN,
    icon: <SearchIcon />,
    title: "Impressions",
  },
  shareCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.INDIGO,
    bgHex: BG_COLOUR_HEX.INDIGO,
    icon: <CornerUpRightIcon />,
    title: "Shares",
  },
};

export const FACEBOOK_METRICS_CONFIG: {
  [prop in NonNullable<
    keyof Pick<
      RawPostMetricsFacebook,
      "reactionsTotal" | "commentCount" | "impressions" | "engagedUsers"
    >
  >]: MetricPropConfig;
} = {
  reactionsTotal: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.PINK,
    bgHex: BG_COLOUR_HEX.PINK,
    icon: <ThumbUpIcon />,
    title: "Reactions",
  },
  commentCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.BLUE,
    bgHex: BG_COLOUR_HEX.BLUE,
    icon: <MessageSquareIcon />,
    title: "Comments",
  },
  impressions: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.GREEN,
    bgHex: BG_COLOUR_HEX.GREEN,
    icon: <SearchIcon />,
    title: "Impressions",
  },
  engagedUsers: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.PURPLE,
    bgHex: BG_COLOUR_HEX.PURPLE,
    icon: <UserGroupIcon />,
    title: "Engaged users",
  },
};

export const TWITTER_METRICS_CONFIG: {
  [prop in NonNullable<keyof RawPostMetricsTwitter>]: MetricPropConfig;
} = {
  likeCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.PINK,
    bgHex: BG_COLOUR_HEX.PINK,
    icon: <HeartIcon />,
    title: "Likes",
  },
  replyCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.BLUE,
    bgHex: BG_COLOUR_HEX.BLUE,
    icon: <MessageCircleIcon />,
    title: "Replies",
  },
  retweetCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.INDIGO,
    bgHex: BG_COLOUR_HEX.INDIGO,
    icon: <RepeatIcon />,
    title: "Retweets",
  },
  quoteCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.PURPLE,
    bgHex: BG_COLOUR_HEX.PURPLE,
    icon: <ChatAlt2Icon />,
    title: "Quotes",
  },
  impressionsCount: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.GREEN,
    bgHex: BG_COLOUR_HEX.GREEN,
    icon: <SearchIcon />,
    title: "Impressions",
  },
  urlLinkClicks: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.ORANGE,
    bgHex: BG_COLOUR_HEX.ORANGE,
    icon: <LinkIcon />,
    title: "Link clicks",
  },
  userProfileClicks: {
    bgClassNames: BG_COLOUR_CLASS_NAMES.AMBER,
    bgHex: BG_COLOUR_HEX.AMBER,
    icon: <UserCircleIcon />,
    title: "Profile clicks",
  },
};

export const METRICS_CONFIG_BY_TYPE = {
  LINKEDIN: LINKEDIN_METRICS_CONFIG,
  FACEBOOK: FACEBOOK_METRICS_CONFIG,
  TWITTER: TWITTER_METRICS_CONFIG,
};
