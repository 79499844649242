import { useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import config from "../../config";
import { useWorkspaces } from "./workspaces";
import { useUserPreferences } from "./userPreferences";
import { Workspace } from "../../types/workspaces";
import { User, UserReferralAffiliate } from "../../types/users";
import {
  fetchActiveWorkspace,
  fetchActiveWorkspaceFileAccessToken,
  fetchActiveWorkspaceTimeZone,
  purgeActiveWorkspace,
  purgeActiveWorkspaceFileAccessToken,
  purgeActiveWorkspaceTimeZone,
  saveActiveWorkspace,
  saveActiveWorkspaceFileAccessToken,
  saveActiveWorkspaceTimeZone,
} from "../localStorage";
import { UNINITIALISED_APP_QUERY_KEYS } from "../queryKeys";

interface InitialisedData {
  activeWorkspace: Workspace | null;
  fileAccessToken: string | null;
  timeZone: string;
}
export const useInitialise = (user: User) => {
  const queryClient = useQueryClient();
  const cacheRef = useRef<InitialisedData>({
    activeWorkspace: fetchActiveWorkspace(),
    fileAccessToken: fetchActiveWorkspaceFileAccessToken(),
    timeZone: fetchActiveWorkspaceTimeZone() || user.timeZone,
  });
  const loadingPromiseRef = useRef<{
    resolve: null | ((value: InitialisedData) => void);
    reject: null | ((reason?: string) => void);
  }>({
    resolve: null,
    reject: null,
  });
  const {
    isFetching: isLoadingWorkspaces,
    isError: isErrorWorkspaces,
    data: workspacesData,
  } = useWorkspaces(user.id);
  const {
    isFetching: isLoadingUserPreferences,
    isError: isErrorUserPreferences,
    data: userPreferences,
  } = useUserPreferences(user.id);
  const isLoading = isLoadingWorkspaces || isLoadingUserPreferences;
  const isError = isErrorWorkspaces || isErrorUserPreferences;

  useEffect(() => {
    queryClient.invalidateQueries(UNINITIALISED_APP_QUERY_KEYS.detail(user.id));
  }, [workspacesData, userPreferences, queryClient, user.id]);

  useEffect(() => {
    if (
      loadingPromiseRef.current.reject &&
      loadingPromiseRef.current.resolve &&
      !isLoading
    ) {
      if (isError) {
        loadingPromiseRef.current.reject();
        return;
      }

      const workspaces = workspacesData ? workspacesData.workspaces : null;
      const activeWorkspaceId =
        userPreferences && userPreferences.activeWorkspaceId
          ? userPreferences.activeWorkspaceId
          : null;
      const activeWorkspace =
        activeWorkspaceId && workspaces && workspaces.length
          ? workspaces.find(
              (candidate) => candidate.id === activeWorkspaceId
            ) || workspaces[0]
          : null;
      const fileAccessToken =
        workspacesData && activeWorkspace
          ? workspacesData.fileAccessTokens[activeWorkspace.id] || null
          : null;
      const workspaceUserPreferences =
        activeWorkspace &&
        userPreferences &&
        userPreferences.workspaces &&
        userPreferences.workspaces[activeWorkspace.id]
          ? userPreferences.workspaces[activeWorkspace.id]
          : null;
      const timeZone = workspaceUserPreferences
        ? workspaceUserPreferences.timeZone || user.timeZone
        : user.timeZone;

      loadingPromiseRef.current.resolve({
        activeWorkspace,
        fileAccessToken,
        timeZone,
      });
    }
  }, [isError, isLoading, user.timeZone, userPreferences, workspacesData]);

  return useQuery(
    UNINITIALISED_APP_QUERY_KEYS.detail(user.id),
    () => {
      return new Promise<InitialisedData>((resolve, reject) => {
        loadingPromiseRef.current.resolve = resolve;
        loadingPromiseRef.current.reject = reject;
      });
    },
    {
      placeholderData: () => {
        const activeWorkspace = fetchActiveWorkspace();
        const fileAccessToken = fetchActiveWorkspaceFileAccessToken();
        const timeZone = fetchActiveWorkspaceTimeZone();

        if (
          (activeWorkspace &&
            (!cacheRef.current.activeWorkspace ||
              cacheRef.current.activeWorkspace.id !== activeWorkspace.id)) ||
          (!activeWorkspace && !!cacheRef.current.activeWorkspace)
        ) {
          cacheRef.current = {
            ...cacheRef.current,
            activeWorkspace,
          };
        }

        if (fileAccessToken !== cacheRef.current.fileAccessToken) {
          cacheRef.current = {
            ...cacheRef.current,
            fileAccessToken,
          };
        }

        if (timeZone && timeZone !== cacheRef.current.timeZone) {
          cacheRef.current = {
            ...cacheRef.current,
            timeZone,
          };
        }

        return cacheRef.current;
      },
      onSuccess: ({ activeWorkspace, fileAccessToken, timeZone }) => {
        if (activeWorkspace) {
          saveActiveWorkspace(activeWorkspace);
        } else {
          purgeActiveWorkspace();
        }

        if (fileAccessToken) {
          saveActiveWorkspaceFileAccessToken(fileAccessToken);
        } else {
          purgeActiveWorkspaceFileAccessToken();
        }

        if (timeZone) {
          saveActiveWorkspaceTimeZone(timeZone);
        } else {
          purgeActiveWorkspaceTimeZone();
        }
      },
      onError: () => {
        purgeActiveWorkspace();
        purgeActiveWorkspaceFileAccessToken();
        purgeActiveWorkspaceTimeZone();
      },
    }
  );
};

type RewardfulAffiliate = {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  token: string;
};

type UseCheckForReferrerProps = {
  onSuccess: (
    props: {
      id: string;
      affiliate?: UserReferralAffiliate;
    } | null
  ) => void;
  onFailedToLoad: (hasReferralParam: boolean) => void;
};

export const useCheckForReferrer = ({
  onSuccess,
  onFailedToLoad,
}: UseCheckForReferrerProps) => {
  const hasInitialisedRef = useRef(false);
  const queryParams = new URLSearchParams(useLocation().search);
  const hasReferralParam = queryParams.has("via");

  useEffect(() => {
    const initialise = async () => {
      const headElements = document.getElementsByTagName("head");
      const headerElement = headElements[0];

      if (!document.getElementById("rewardful-inline-script")) {
        const inlineScript = document.createElement("script");
        inlineScript.id = "rewardful-inline-script";
        inlineScript.type = "text/javascript";
        inlineScript.innerHTML = `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`;

        headerElement.appendChild(inlineScript);
      }

      if (!document.getElementById("rewardful-remote-script")) {
        const remoteScript = document.createElement("script");
        remoteScript.id = "rewardful-remote-script";
        remoteScript.type = "text/javascript";
        remoteScript.async = true;
        remoteScript.dataset.rewardful = config.REWARDFUL_API_KEY;
        remoteScript.src = "https://r.wdfl.co/rw.js";
        remoteScript.onerror = () => {
          onFailedToLoad(hasReferralParam);
        };

        headerElement.appendChild(remoteScript);
      }

      let elapsedTime = 0;

      while (!(window as any).rewardful && elapsedTime < 5000) {
        await new Promise((resolveTimeout) => setTimeout(resolveTimeout, 500));
        elapsedTime += 500;
      }

      if (!(window as any).rewardful) {
        onSuccess(null);
      }

      (window as any).rewardful("ready", () => {
        const { referral, affiliate } = (window as any).Rewardful as {
          referral?: string;
          affiliate?: RewardfulAffiliate | false;
        };

        if (referral) {
          onSuccess({
            id: referral,
            affiliate: affiliate
              ? {
                  id: affiliate.id,
                  token: affiliate.token,
                  name: affiliate.name,
                  firstName: affiliate.first_name,
                  lastName: affiliate.last_name,
                  provider: "REWARDFUL",
                }
              : undefined,
          });
        } else {
          onSuccess(null);
        }
      });
    };

    if (!hasInitialisedRef.current) {
      initialise();
      hasInitialisedRef.current = true;
    }
  }, [hasReferralParam, onFailedToLoad, onSuccess]);
};
