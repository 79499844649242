import React, { useRef, useEffect, ReactNode } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import FileDropStackContext, {
  FileDropStackPushProps,
} from "../contexts/FileDropStack";
import { MIME_TYPE_ACCEPT_LIST } from "../libs/files";

interface FileDropStackProviderProps {
  children: ReactNode;
}
const FileDropStackProvider: React.FC<FileDropStackProviderProps> = ({
  children,
}) => {
  const stackRef = useRef<FileDropStackPushProps[]>([]);

  useEffect(() => {
    return () => {
      stackRef.current = [];
    };
  });

  const getHandler = () => {
    return stackRef.current.length
      ? stackRef.current[stackRef.current.length - 1]
      : null;
  };

  const remove = (id: string) => {
    stackRef.current = stackRef.current.filter(
      (candidate) => candidate.id !== id
    );
  };

  const add = (props: FileDropStackPushProps) => {
    // Make sure it's only in the stack once.
    remove(props.id);
    stackRef.current.push(props);
  };

  const onDrop: DropzoneOptions["onDrop"] = (
    acceptedFiles,
    fileRejections,
    event
  ) => {
    const handler = getHandler();
    if (handler) {
      handler.onDrop(acceptedFiles, fileRejections, event);
    }
  };

  const onDragEnter = (event: React.DragEvent<HTMLElement>) => {
    const handler = getHandler();
    if (handler && handler.onDragEnter) {
      handler.onDragEnter(event);
    }
  };

  const onDragLeave = (event: React.DragEvent<HTMLElement>) => {
    const handler = getHandler();
    if (handler && handler.onDragLeave) {
      handler.onDragLeave(event);
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLElement>) => {
    const handler = getHandler();
    if (handler && handler.onDragOver) {
      handler.onDragOver(event);
    }
  };

  const { getRootProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    onDragOver,
    noClick: true,
    noKeyboard: true,
    multiple: true,
    accept: MIME_TYPE_ACCEPT_LIST,
  });

  // Setting role={undefined} overrides the stupid role="button" they set
  // with the root props (even though this can't be clicked on or used with
  // a keyboard...)
  return (
    <FileDropStackContext.Provider value={{ add, remove }}>
      <div {...getRootProps()} role={undefined}>
        {children}
      </div>
    </FileDropStackContext.Provider>
  );
};

export default FileDropStackProvider;
