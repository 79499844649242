import React from "react";
import ExternalLink from "../../components/links/External";
import { mergeClassNames } from "../../libs/components";

import config from "../../config";

interface UnauthenticatedFooterProps {
  className?: string;
}

const UnauthenticatedFooter: React.FC<UnauthenticatedFooterProps> = ({
  className = "",
}) => {
  return (
    <footer
      className={mergeClassNames("block p-5 text-sm text-gray-500", className)}
    >
      <div>
        {"©2021–Present All Rights Reserved. "}
        <ExternalLink className="font-normal" href={config.urls.PRIVACY_POLICY}>
          Privacy policy
        </ExternalLink>
        {", and "}
        <ExternalLink className="font-normal" href={config.urls.TERMS_OF_USE}>
          terms of use.
        </ExternalLink>
      </div>
      <div className="mt-2">
        <ExternalLink
          href={`mailto:${config.feedback_email.TO}?subject=${config.feedback_email.SUBJECT}&body=${config.feedback_email.BODY}`}
          title={config.feedback_email.TITLE}
        >
          Click here to send feedback or report an issue.
        </ExternalLink>
      </div>
    </footer>
  );
};

export default UnauthenticatedFooter;
