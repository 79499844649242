import React from "react";
import { Star as StarIcon } from "react-feather";

import { Workspace } from "../../../types/workspaces";

import config from "../../../config";
import { useShowModal } from "../../../libs/hooks/app";
import { ReactComponent as PublishedPostActionImage } from "../../../images/published-post-action.svg";

import PrimaryButton from "../../../components/buttons/Primary";
import H2 from "../../../components/headings/H2";

interface MultiPostPublishedPostActionsLoadingProps {
  contentId: "noSubscription";
  workspace: Workspace;
}
const MultiPostPublishedPostActionsNoSubscription: React.FC<
  MultiPostPublishedPostActionsLoadingProps
> = ({ workspace }) => {
  const showModal = useShowModal();
  const hasSubscription = !!workspace.subscription;
  const hasNeverHadSubscription = !workspace.hasHadTrial && !hasSubscription;

  return (
    <div className="p-2 sm:p-8 flex flex-col items-center">
      <div style={{ width: 600 }}>
        <H2>More than simple publishing?</H2>
        <p className="mt-6">
          Social media doesn't stop at simply publishing content. Increase your
          audience engagement by adding <strong>after publish actions</strong>{" "}
          for your posts!
        </p>
        <p className="mt-4">
          Quickly and easily schedule actions such as comments, reactions, and
          reshares to automatically occur after your post has been published.
        </p>
        {hasNeverHadSubscription ? (
          <p className="mt-4">
            Try our{" "}
            <strong>{config.prices.PUBLISH_MONTHLY.displayName} plan</strong>{" "}
            for{" "}
            <strong>free for {config.DEFAULT_TRIAL_PERIOD_DAYS} days</strong>{" "}
            (no credit card required) to create after publish actions for your
            posts.
          </p>
        ) : (
          <p className="mt-4">
            Upgrade to our{" "}
            <strong>{config.prices.PUBLISH_MONTHLY.displayName} plan</strong> to
            create after publish actions for your posts.
          </p>
        )}
      </div>
      <PrimaryButton
        className="mt-10"
        onClick={() =>
          showModal("changeSubscription", {
            workspace,
            redirectOnSuccess: false,
            defaultPriceId: config.prices.PUBLISH_MONTHLY.id,
          })
        }
      >
        <StarIcon className="mr-2" />
        {hasNeverHadSubscription
          ? `Start your ${config.DEFAULT_TRIAL_PERIOD_DAYS}-day free trial`
          : `Upgrade to our ${config.prices.PUBLISH_MONTHLY.displayName} plan to create after publish actions`}
      </PrimaryButton>
      {hasNeverHadSubscription && (
        <p className="mt-2">No credit card required!</p>
      )}
      <PublishedPostActionImage
        className="mt-12 flex-shrink-0 text-purple-500"
        style={{ width: 400, height: 400 }}
      />
    </div>
  );
};

export default MultiPostPublishedPostActionsNoSubscription;
