import React, { ReactNode } from "react";

import { formatCountForDisplay } from "../../../libs/postMetrics";

import Card from "./Card";

interface AnalyticsOverviewMetricCardProps {
  icon: ReactNode;
  title: ReactNode;
  total?: number;
  deltaTotal?: number;
  deltaPeriodDisplayName?: string;
  className?: string;
}
const AnalyticsOverviewMetricCard: React.FC<
  AnalyticsOverviewMetricCardProps
> = ({
  className = "",
  icon,
  title,
  total,
  deltaTotal,
  deltaPeriodDisplayName,
}) => {
  const hasDeltaTotal = deltaTotal !== undefined;
  const increase = hasDeltaTotal && deltaTotal > 0;
  const decrease = hasDeltaTotal && deltaTotal < 0;

  return (
    <Card
      className={className}
      icon={icon}
      title={title}
      subtitle={total === undefined ? "-" : formatCountForDisplay(total)}
      footer={
        hasDeltaTotal && (
          <div className="h-full w-full flex items-center justify-center">
            {increase ? (
              <div className="flex items-center font-bold text-green-500 text-sm">
                {`+${formatCountForDisplay(deltaTotal)}`}
              </div>
            ) : decrease ? (
              <div className="flex items-center font-bold text-red-500 text-sm">
                {`-${formatCountForDisplay(Math.abs(deltaTotal))}`}
              </div>
            ) : (
              <span>No change</span>
            )}
            {deltaPeriodDisplayName && (
              <span className="ml-1">{deltaPeriodDisplayName}</span>
            )}
          </div>
        )
      }
    />
  );
};

export default AnalyticsOverviewMetricCard;
