import { createContext } from "react";
import { UserReferralAffiliate } from "../../types/users";

type UnauthenticatedAppContextState = {
  email: string | null;
  password: string | null;
  referralId: string | null;
  referralAffiliate: UserReferralAffiliate | null;
  setEmail: (value: string | null) => void;
  setPassword: (value: string | null) => void;
  setReferralId: (value: string | null) => void;
  setReferralAffiliate: (value: UserReferralAffiliate | null) => void;
};

const UnauthenticatedAppContext = createContext<UnauthenticatedAppContextState>(
  {
    email: null,
    password: null,
    referralId: null,
    referralAffiliate: null,
    setEmail: () => {},
    setPassword: () => {},
    setReferralId: () => {},
    setReferralAffiliate: () => {},
  }
);

export default UnauthenticatedAppContext;
