import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState, ReactNode } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import {
  Clock as ClockIcon,
  CheckCircle as CheckCircleIcon,
  Maximize2 as MaximizeIcon,
  Repeat as RepeatIcon,
  Heart as HeartIcon,
  ThumbsUp as ThumbsUpIcon,
  MessageSquare as MessageSquareIcon,
  X as XIcon,
} from "react-feather";
import { FlagIcon, FolderIcon, FolderOpenIcon } from "@heroicons/react/outline";

import { PostWithScheduledProp } from "../../types/posts";
import { Platform } from "../../types/platforms";
import { useOpenCloseStack } from "../../libs/hooks/general";
import { useActiveWorkspaceTimeZone } from "../../libs/hooks/app";

import PlatformIcon from "../../components/icons/Platform";
import IconButton from "../../components/buttons/Icon";

interface CalendarPostsContainerProps {
  posts: PostWithScheduledProp[];
  platforms: Platform[];
  postHeight: number;
  postWidth: number;
  forceCollapse: boolean;
  children: ReactNode;
}

const COLLAPSED_PADDING = 8;
const EXPANDED_PADDING = 20;
const DISPLAY_TIME_FORMAT = "h:mma";

const CalendarPostsContainer: React.FC<CalendarPostsContainerProps> = ({
  posts,
  platforms,
  postHeight,
  postWidth,
  forceCollapse,
  children,
}) => {
  const workspaceTimeZone = useActiveWorkspaceTimeZone();
  const [isExpanded, setIsExpanded] = useState(false);
  const platformTypes = Object.keys(
    platforms.reduce<{ [type: string]: true }>((carry, platform) => {
      carry[platform.type] = true;
      return carry;
    }, {})
  ) as Platform["type"][];
  const [postedPosts, scheduledPosts, draftPosts] = posts.reduce<
    [PostWithScheduledProp[], PostWithScheduledProp[], PostWithScheduledProp[]]
  >(
    (carry, post) => {
      if (post.status === "POSTED") {
        carry[0].push(post);
      } else {
        carry[1].push(post);

        if (post.status === "DRAFT") {
          carry[2].push(post);
        }
      }
      return carry;
    },
    [[], [], []]
  );
  const totalPostsCount = posts.length;
  const draftPostCount = draftPosts.length;
  const expandedWidth = postWidth + 2 * EXPANDED_PADDING;
  const expandedHeight =
    totalPostsCount * postHeight +
    (1 + totalPostsCount) * EXPANDED_PADDING +
    EXPANDED_PADDING;
  const heightDiff = expandedHeight - postHeight;
  const firstPostedDateTime =
    postedPosts.length > 0
      ? DateTime.fromISO(postedPosts[0].scheduled)
          .setZone(workspaceTimeZone)
          .startOf("minute")
      : null;
  const lastPostedDateTime =
    postedPosts.length > 1
      ? DateTime.fromISO(postedPosts[postedPosts.length - 1].scheduled)
          .setZone(workspaceTimeZone)
          .startOf("minute")
      : null;
  const firstScheduledDateTime =
    scheduledPosts.length > 0
      ? DateTime.fromISO(scheduledPosts[0].scheduled).setZone(workspaceTimeZone)
      : null;
  const lastScheduledDateTime =
    scheduledPosts.length > 1
      ? DateTime.fromISO(
          scheduledPosts[scheduledPosts.length - 1].scheduled
        ).setZone(workspaceTimeZone)
      : null;
  const hasPublishedPosts = firstPostedDateTime !== null;
  const hasScheduledPosts = firstScheduledDateTime !== null;
  const hasPublishedRange =
    !!firstPostedDateTime &&
    !!lastPostedDateTime &&
    lastPostedDateTime > firstPostedDateTime;
  const hasScheduledRange =
    !!firstScheduledDateTime &&
    !!lastScheduledDateTime &&
    lastScheduledDateTime > firstScheduledDateTime;
  const metrics = postedPosts.reduce(
    (carry, post) => {
      switch (post.type) {
        case "FACEBOOK":
          carry.likes +=
            post.metrics && post.metrics.reactionsTotal
              ? post.metrics.reactionsTotal
              : 0;
          carry.comments +=
            post.metrics && post.metrics.commentCount
              ? post.metrics.commentCount
              : 0;
          break;
        case "LINKEDIN":
          carry.likes +=
            post.metrics && post.metrics.likeCount ? post.metrics.likeCount : 0;
          carry.comments +=
            post.metrics && post.metrics.commentCount
              ? post.metrics.commentCount
              : 0;
          break;
        case "TWITTER":
          carry.retweets +=
            post.metrics && post.metrics.retweetCount
              ? post.metrics.retweetCount
              : 0;
          carry.favourites +=
            post.metrics && post.metrics.likeCount ? post.metrics.likeCount : 0;
          break;
      }
      return carry;
    },
    {
      retweets: 0,
      favourites: 0,
      comments: 0,
      likes: 0,
    }
  );

  let retweetCount = 0;
  postedPosts.forEach((postedPost) => {
    if (postedPost.type === "TWITTER" && postedPost.metrics) {
      retweetCount = retweetCount + postedPost.metrics.retweetCount;
    }
  });

  let favouriteCount = 0;
  postedPosts.forEach((postedPost) => {
    if (postedPost.type === "TWITTER" && postedPost.metrics) {
      favouriteCount = favouriteCount + postedPost.metrics.likeCount;
    }
  });

  let postedDisplay: string | null = null;
  let scheduledDisplay: string | null = null;

  if (hasPublishedPosts) {
    if (hasPublishedRange) {
      if (hasScheduledRange) {
        postedDisplay = `${postedPosts.length}`;
      } else {
        postedDisplay = `${firstPostedDateTime.toFormat(
          DISPLAY_TIME_FORMAT
        )} - ${lastPostedDateTime.toFormat(DISPLAY_TIME_FORMAT)}`;
      }
    } else {
      postedDisplay = firstPostedDateTime.toFormat(DISPLAY_TIME_FORMAT);
    }
  }

  if (hasScheduledPosts) {
    if (hasScheduledRange) {
      if (hasPublishedPosts) {
        scheduledDisplay = `${scheduledPosts.length}`;
      } else {
        scheduledDisplay = `${firstScheduledDateTime.toFormat(
          DISPLAY_TIME_FORMAT
        )} - ${lastScheduledDateTime.toFormat(DISPLAY_TIME_FORMAT)}`;
      }
    } else {
      scheduledDisplay = firstScheduledDateTime.toFormat(DISPLAY_TIME_FORMAT);
    }
  }

  useEffect(() => {
    if (forceCollapse) {
      setIsExpanded(false);
    }
  }, [forceCollapse]);

  const spring = useSpring({
    config: config.stiff,
    from: {
      left: 0,
      top: 0,
      padding: COLLAPSED_PADDING,
      collapseOpacity: 1,
      expandOpacity: 0,
      height: postHeight,
      width: postWidth,
      shadow: 1,
      expanded: isExpanded,
      zIndex: isExpanded ? 1 : 0,
    },
    to: isExpanded
      ? async (next) => {
          await next({ zIndex: 1, immediate: true });
          await next({
            left: EXPANDED_PADDING * -1,
            top: (heightDiff / 2) * -1,
            padding: EXPANDED_PADDING,
            collapseOpacity: 0,
            height: expandedHeight,
            width: expandedWidth,
            shadow: 15,
            expanded: true,
          });
          await next({ expandOpacity: 1 });
        }
      : async (next) => {
          await next({ expandOpacity: 0 });
          await next({
            left: 0,
            top: 0,
            padding: COLLAPSED_PADDING,
            collapseOpacity: 1,
            height: postHeight,
            width: postWidth,
            shadow: 1,
            expanded: false,
          });
          await next({ zIndex: 0, immediate: true });
        },
  });

  const close = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const openCloseHandlers = useOpenCloseStack(
    !forceCollapse && isExpanded,
    close
  );

  return (
    <div className="relative group" {...openCloseHandlers}>
      <animated.div
        className="absolute rounded-lg border border-gray-200 bg-white"
        style={{
          top: spring.top,
          left: spring.left,
          padding: spring.padding,
          height: spring.height,
          width: spring.width,
          boxShadow: spring.shadow.to(
            (s) =>
              `0 ${s}px ${3 * s}px 0 rgb(0 0 0 / 0.1), 0 ${s}px ${2 * s}px ${
                -1 * s
              }px rgb(0 0 0 / 0.1)`
          ),
          zIndex: spring.zIndex,
        }}
      >
        <animated.div
          className="pt-1 absolute top-0 left-0 w-full flex items-start"
          style={{
            height: 2 * EXPANDED_PADDING,
            zIndex: spring.expanded.to((e) => (e ? 1 : 0)),
          }}
        >
          <div className="font-bold text-gray-500 text-sm flex items-center">
            {isExpanded ? (
              <FolderOpenIcon className="mx-1 h-6 w-6" />
            ) : (
              <FolderIcon className="mx-1 h-6 w-6" />
            )}
            {totalPostsCount} posts
          </div>
          {isExpanded ? (
            <div className="ml-auto mr-1">
              <IconButton size="xs" onClick={() => close()}>
                <XIcon className="h-4 w-4" />
              </IconButton>
            </div>
          ) : (
            <div className="ml-auto pt-2 pr-3 text-sm text-gray-200 transition-colors group-hover:text-gray-500">
              <MaximizeIcon className="h-4 w-4" />
            </div>
          )}
        </animated.div>
        <animated.div
          className="absolute top-0 left-0 flex flex-col items-center justify-between overflow-hidden"
          style={{
            paddingTop: spring.expanded.to((e) =>
              e ? 2 * EXPANDED_PADDING : 0
            ),
            paddingLeft: spring.expanded.to((e) => (e ? EXPANDED_PADDING : 0)),
            paddingRight: spring.expanded.to((e) => (e ? EXPANDED_PADDING : 0)),
            paddingBottom: spring.expanded.to((e) =>
              e ? EXPANDED_PADDING : 0
            ),
            height: spring.expanded.to((e) => (e ? expandedHeight : 0)),
            width: spring.expanded.to((e) => (e ? expandedWidth : 0)),
            opacity: forceCollapse ? 1 : spring.expandOpacity,
          }}
        >
          {children}
        </animated.div>
        <animated.div
          className="absolute inset-0"
          style={{
            opacity: spring.collapseOpacity,
            display: spring.collapseOpacity.to((o) =>
              o > 0 ? "block" : "none"
            ),
          }}
        >
          <button
            className="p-2 h-full w-full rounded-lg flex flex-col"
            type="button"
            onClick={() => !forceCollapse && setIsExpanded(!isExpanded)}
          >
            <div className="pt-6 w-full flex items-center justify-between">
              <div className="flex items-center">
                {platformTypes.map((platformType) => (
                  <PlatformIcon
                    key={platformType}
                    className="mr-1 flex-shrink-0 h-4 w-4"
                    type={platformType}
                    colour={true}
                  />
                ))}
              </div>
              {draftPostCount > 0 && (
                <div className="flex-shrink-0 flex items-center text-purple-400">
                  <FlagIcon className="h-3.5 w-3.5" />
                  <span className="ml-1 text-xs">
                    {draftPostCount > 1
                      ? `${draftPostCount} drafts`
                      : "1 draft"}
                  </span>
                </div>
              )}
            </div>
            <div className="mt-auto flex-shrink-0 w-full text-xs">
              {hasPublishedPosts && (
                <div className="flex items-center justify-end">
                  {(Object.keys(metrics) as Array<keyof typeof metrics>).map(
                    (metric) => {
                      const count = metrics[metric];

                      if (count < 1) {
                        return null;
                      }

                      switch (metric) {
                        case "retweets":
                          return (
                            <div
                              key={metric}
                              className="ml-1 flex items-center text-teal-500"
                            >
                              <RepeatIcon className="h-3.5 w-3.5" />
                              <span className="ml-1">{count}</span>
                            </div>
                          );
                        case "favourites":
                          return (
                            <div
                              key={metric}
                              className="ml-1 flex items-center text-pink-500"
                            >
                              <HeartIcon className="h-3.5 w-3.5" />
                              <span className="ml-1">{count}</span>
                            </div>
                          );
                        case "comments":
                          return (
                            <div
                              key={metric}
                              className="ml-1 flex items-center text-sky-500"
                            >
                              <MessageSquareIcon className="h-3.5 w-3.5" />
                              <span className="ml-1">{count}</span>
                            </div>
                          );
                        case "likes":
                          return (
                            <div
                              key={metric}
                              className="ml-1 flex items-center text-pink-500"
                            >
                              <ThumbsUpIcon className="h-3.5 w-3.5" />
                              <span className="ml-1">{count}</span>
                            </div>
                          );
                      }

                      return null;
                    }
                  )}
                </div>
              )}

              <div className="w-full flex items-center justify-between truncate">
                {hasScheduledPosts && (
                  <div className="flex items-center truncate">
                    <ClockIcon className="mr-1 h-3.5 w-3.5" />
                    {scheduledDisplay}
                  </div>
                )}
                {hasPublishedPosts && (
                  <div className="ml-auto shrink-0 flex items-center text-green-500 truncate">
                    <CheckCircleIcon className="mr-1 h-3.5 w-3.5" />
                    {postedDisplay}
                  </div>
                )}
              </div>
            </div>
          </button>
        </animated.div>
      </animated.div>
    </div>
  );
};

export default CalendarPostsContainer;
