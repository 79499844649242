import { DateTime } from "luxon";
import React, { ComponentProps } from "react";

import config from "../../config";
import { Subscription } from "../../types/subscriptions";
import { useActiveWorkspaceTimeZone } from "../../libs/hooks/app";
import { useCancelSubscription } from "../../libs/hooks/subscriptions";
import { useSnackBarFactory } from "../../libs/hooks/general";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import DangerButton from "../../components/buttons/Danger";
import DeletedSubscriptionSnackBarContent from "../../components/snackBarContent/DeletedSubscription";
import TertiaryButton from "../../components/buttons/Tertiary";

interface DeleteSubscriptionModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  subscription: Subscription;
  close: () => void;
}

const DeleteSubscriptionModal: React.FC<DeleteSubscriptionModalProps> = ({
  subscription,
  isVisible,
  close,
  ...modalProps
}) => {
  const createSnackBar = useSnackBarFactory();
  const timeZone = useActiveWorkspaceTimeZone();
  const {
    mutateAsync: cancelSubscription,
    isLoading,
    loadingText,
    errorAlert,
  } = useCancelSubscription();

  const priceConfig = Object.values(config.prices).find((candidate) => {
    return candidate.id === subscription.price.remoteId;
  });
  const subscriptionName = priceConfig ? priceConfig.displayName : "Unknown";
  const displayPeriodEnd = DateTime.fromISO(subscription.periodEnd)
    .setZone(timeZone)
    .toFormat("MMM d");

  const handleDelete = async () => {
    try {
      await cancelSubscription({ subscription });
      createSnackBar({
        content: (
          <DeletedSubscriptionSnackBarContent
            subscriptionDisplayName={subscriptionName}
          />
        ),
      });
      close();
    } catch (e) {}
  };

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="px-8 pb-8 pt-6" style={{ width: 500 }}>
        <H3>Cancel subscription</H3>
        <p className="mt-8">
          Your subscription will continue for the remainder of the current
          period and will then revert to the free plan on{" "}
          <strong>{displayPeriodEnd}</strong>.
        </p>
        <p className="mt-4">
          Are you sure you'd like to cancel your{" "}
          <strong>{subscriptionName}</strong> subscription?
        </p>

        {errorAlert({ className: "mt-8" })}
        <DangerButton
          className="mt-8 w-full"
          onClick={handleDelete}
          disabled={isLoading}
        >
          {loadingText({
            loading: `Cancelling subscription...`,
            default: `Yes, cancel my subscription.`,
          })}
        </DangerButton>
        <TertiaryButton
          className="mt-2 w-full"
          onClick={() => close()}
          disabled={isLoading}
        >
          No, thanks.
        </TertiaryButton>
      </div>
    </Modal>
  );
};

export default DeleteSubscriptionModal;
