import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { UploadCloud as UploadCloudIcon } from "react-feather";
import { mergeClassNames } from "../../libs/components";

interface UploadingImageProps {
  src: string;
  alt: string;
  percent: number;
  className?: string;
  containerClassName?: string;
}
const UploadingImage: React.FC<UploadingImageProps> = ({
  src,
  alt,
  percent,
  className = "",
  containerClassName = "",
}) => {
  const clampedPercent = Math.max(Math.min(percent, 100), 0);
  const spring = useSpring<{ percent: number }>({
    from: { percent: 0 },
    percent: clampedPercent,
    immediate: clampedPercent === 100,
  });

  return (
    <div
      className={mergeClassNames(
        "relative w-full h-full flex-shrink-0",
        containerClassName
      )}
    >
      <img
        className={mergeClassNames(
          "max-w-full max-h-full object-contain",
          className
        )}
        src={src}
        alt={alt}
        loading="lazy"
      />
      <animated.div
        className="absolute bottom-0 left-0 overflow-hidden w-full bg-black opacity-20"
        style={{
          height: spring.percent.to((p) => `${100 - p}%`),
        }}
      ></animated.div>
      <animated.div
        className="p-1 absolute bottom-0 left-0 w-full bg-black text-white opacity-80 items-center justify-center"
        style={{
          display: spring.percent.to((p) => (p < 100 ? "flex" : "none")),
        }}
      >
        <UploadCloudIcon className="mr-2" />
        <animated.span>{spring.percent.to((p) => Math.round(p))}</animated.span>
        %
      </animated.div>
    </div>
  );
};

export default UploadingImage;
