import { useMemo } from "react";
import { DateTime } from "luxon";
import {
  Activity as ActivityIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
} from "react-feather";

import { Post } from "../../../types/posts";
import {
  MetricPropertyNames,
  PostAnalytics,
} from "../../../types/postAnalytics";

import { mergeClassNames } from "../../../libs/components";
import { getUTCOffsetString } from "../../../libs/date";
import {
  ENGAGEMENT_LEVELS,
  getEngagementLevelSingleWordDescription,
} from "../../../libs/postMetrics";
import {
  BG_COLOUR_CLASS_NAMES,
  MetricPropConfig,
  METRICS_CONFIG_BY_TYPE,
} from "../../../libs/analytics";
import { useActiveWorkspaceTimeZone } from "../../../libs/hooks/app";

import OverviewCard from "../../../components/analytics/overview/Card";
import MetricCard from "../../../components/analytics/overview/MetricCard";

interface ViewPostOverviewProps {
  metricPropertyNames: MetricPropertyNames;
  post: Post;
  analytics?: PostAnalytics;
  className?: string;
}
const ViewPostOverview: React.FC<ViewPostOverviewProps> = ({
  metricPropertyNames,
  post,
  analytics,
  className = "",
}) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const weekMetricTotals = useMemo(() => {
    if (!analytics) {
      return {};
    }

    const now = DateTime.local().setZone(timeZone);
    const startOfWeek = now.startOf("week");
    const endOfWeek = now.endOf("week");
    const utcOffset = analytics.utcOffset;
    const utcOffsetString = getUTCOffsetString(utcOffset);
    const periodStart = DateTime.fromISO(analytics.periodStart).setZone(
      utcOffsetString
    );
    const startOfWeekDiff = startOfWeek.diff(periodStart, "days");
    const endOfWeekDiff = endOfWeek.diff(periodStart, "days");
    const earliestDayOffset = Math.floor(startOfWeekDiff.as("days"));
    const latestDayOffset = Math.floor(endOfWeekDiff.as("days"));
    const dayOffsets = Object.keys(analytics.byDayOffset).map((dayOffset) =>
      parseInt(dayOffset, 10)
    );
    const weekDayOffsets = dayOffsets.filter(
      (dayOffset) =>
        dayOffset >= earliestDayOffset && dayOffset <= latestDayOffset
    );

    const newWeekMetricTotals: { [metric: string]: number } = {};

    weekDayOffsets.forEach((dayOffset) => {
      const dayAnalytics = analytics.byDayOffset[dayOffset];

      if (!dayAnalytics) {
        return;
      }

      for (const [metric, data] of Object.entries(dayAnalytics.metrics)) {
        if (newWeekMetricTotals[metric] === undefined) {
          newWeekMetricTotals[metric] = data.value;
        } else {
          newWeekMetricTotals[metric] += data.value;
        }
      }
    });

    return newWeekMetricTotals;
  }, [analytics, timeZone]);

  if (!post.metrics) {
    return null;
  }

  const config = METRICS_CONFIG_BY_TYPE[post.type];
  const postSummaryMetrics = post.metrics;
  const engagementLevel = postSummaryMetrics.engagementLevel;

  return (
    <div
      className={mergeClassNames(
        "flex items-center flex-wrap gap-4",
        className
      )}
    >
      {engagementLevel && (
        <OverviewCard
          className={
            engagementLevel === ENGAGEMENT_LEVELS.RED
              ? BG_COLOUR_CLASS_NAMES.RED
              : engagementLevel === ENGAGEMENT_LEVELS.AMBER
              ? BG_COLOUR_CLASS_NAMES.AMBER
              : BG_COLOUR_CLASS_NAMES.GREEN
          }
          icon={
            engagementLevel === ENGAGEMENT_LEVELS.RED ? (
              <TrendingDownIcon />
            ) : engagementLevel === ENGAGEMENT_LEVELS.AMBER ? (
              <ActivityIcon />
            ) : (
              <TrendingUpIcon />
            )
          }
          title="Engagement"
          subtitle={getEngagementLevelSingleWordDescription(engagementLevel)}
          footer={
            <div className="w-full h-full flex items-center justify-center">
              Current estimated level
            </div>
          }
        />
      )}

      {metricPropertyNames.map((metricPropertyName) => {
        const key = `${post.type}${metricPropertyName}`;
        const total =
          postSummaryMetrics[
            metricPropertyName as keyof typeof postSummaryMetrics
          ];
        const weekTotal = weekMetricTotals[metricPropertyName];
        const metricPropertyConfig = config[
          metricPropertyName as keyof typeof config
        ] as MetricPropConfig;

        return (
          <MetricCard
            key={key}
            className={metricPropertyConfig.bgClassNames}
            icon={metricPropertyConfig.icon}
            title={metricPropertyConfig.title}
            total={total}
            deltaTotal={weekTotal}
            deltaPeriodDisplayName="this week"
          />
        );
      })}
    </div>
  );
};

export default ViewPostOverview;
