import { Platform } from "../types/platforms";
import { User } from "../types/users";
import { Workspace } from "../types/workspaces";

const CURRENT_USER_KEY = "seenly/currentUser";
const ACTIVE_WORKSPACE_KEY = "seenly/activeWorkspace";
const ACTIVE_WORKSPACE_FILE_ACCESS_TOKEN_KEY =
  "seenly/activeWorkspaceFileAccessToken";
const ACTIVE_WORKSPACE_TIME_ZONE = "seenly/activeWorkspaceTimeZone";
const PLATFORM_OAUTH_RESPONSE = "seenly/platformOAuthResponse";

export function fetchCurrentUser() {
  const cacheJson = window.localStorage.getItem(CURRENT_USER_KEY);
  return cacheJson ? (JSON.parse(cacheJson) as User) : null;
}

export function saveCurrentUser(user: User) {
  window.localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));
}

export function purgeCurrentUser() {
  window.localStorage.removeItem(CURRENT_USER_KEY);
}

export function fetchActiveWorkspace() {
  const cacheJson = window.localStorage.getItem(ACTIVE_WORKSPACE_KEY);
  return cacheJson ? (JSON.parse(cacheJson) as Workspace) : null;
}

export function saveActiveWorkspace(workspace: Workspace) {
  window.localStorage.setItem(ACTIVE_WORKSPACE_KEY, JSON.stringify(workspace));
}

export function purgeActiveWorkspace() {
  window.localStorage.removeItem(ACTIVE_WORKSPACE_KEY);
}

export function fetchActiveWorkspaceFileAccessToken() {
  return window.localStorage.getItem(ACTIVE_WORKSPACE_FILE_ACCESS_TOKEN_KEY);
}

export function saveActiveWorkspaceFileAccessToken(token: string) {
  window.localStorage.setItem(ACTIVE_WORKSPACE_FILE_ACCESS_TOKEN_KEY, token);
}

export function purgeActiveWorkspaceFileAccessToken() {
  window.localStorage.removeItem(ACTIVE_WORKSPACE_FILE_ACCESS_TOKEN_KEY);
}

export function fetchActiveWorkspaceTimeZone() {
  return window.localStorage.getItem(ACTIVE_WORKSPACE_TIME_ZONE);
}

export function saveActiveWorkspaceTimeZone(timeZone: string) {
  window.localStorage.setItem(ACTIVE_WORKSPACE_TIME_ZONE, timeZone);
}

export function purgeActiveWorkspaceTimeZone() {
  window.localStorage.removeItem(ACTIVE_WORKSPACE_TIME_ZONE);
}

interface SavedPlatformOAuthResponse {
  platformType: Platform["type"];
  response: string;
}
export function fetchPlatformOAuthResponse() {
  const cacheJson = window.localStorage.getItem(PLATFORM_OAUTH_RESPONSE);
  return cacheJson
    ? (JSON.parse(cacheJson) as SavedPlatformOAuthResponse)
    : null;
}

export function savePlatformOAuthResponse(data: SavedPlatformOAuthResponse) {
  window.localStorage.setItem(PLATFORM_OAUTH_RESPONSE, JSON.stringify(data));
}

export function purgePlatformOAuthResponse() {
  window.localStorage.removeItem(PLATFORM_OAUTH_RESPONSE);
}

export function purgeAll() {
  purgeCurrentUser();
  purgeActiveWorkspace();
  purgeActiveWorkspaceFileAccessToken();
  purgeActiveWorkspaceTimeZone();
  purgePlatformOAuthResponse();
}
