import { ContentState } from "draft-js";
import Joi from "joi";

import {
  baseContentStateSchema,
  linkedinContentStateSchema,
  twitterContentStateSchema,
} from "../post/textArea/schemas";

const MIN_DELAY_MINUTES = 1;
const MAX_DELAY_MINUTES = 15;

const actionFormBaseSchema = Joi.object({
  id: Joi.string().required(),
  postId: Joi.string().required(),
  platformId: Joi.string().required(),
  platformEntityId: Joi.string().required(),
  useRandomDelay: Joi.boolean(),
  delay: Joi.number().min(MIN_DELAY_MINUTES).max(MAX_DELAY_MINUTES),
}).unknown(true);

const linkedinActionFormBaseSchema = actionFormBaseSchema.append({
  platformType: Joi.string().valid("LINKEDIN").required(),
  type: Joi.string().valid("RESHARE", "REACTION", "COMMENT").required(),
});

const linkedInReshareFormSchema = Joi.object({
  contentState: linkedinContentStateSchema,
}).unknown(true);

const linkedInReactionFormSchema = Joi.object({
  reactionType: Joi.string()
    .valid(
      "LIKE",
      "PRAISE",
      "ENTERTAINMENT",
      "EMPATHY",
      "INTEREST",
      "APPRECIATION"
    )
    .required(),
}).unknown(true);

const linkedInCommentSchema = Joi.object({
  contentState: linkedinContentStateSchema
    .custom((value: ContentState) => {
      const text = value.getPlainText() || "";
      if (text.trim() === "") {
        throw new Error(`Comment must contain some text`);
      }

      return value;
    })
    .required(),
}).unknown(true);

export const linkedinActionSchema = linkedinActionFormBaseSchema
  .when(Joi.object({ type: Joi.string().valid("RESHARE") }).unknown(true), {
    then: linkedInReshareFormSchema,
  })
  .when(Joi.object({ type: Joi.string().valid("REACTION") }).unknown(true), {
    then: linkedInReactionFormSchema,
  })
  .when(Joi.object({ type: Joi.string().valid("COMMENT") }).unknown(true), {
    then: linkedInCommentSchema,
  });

const twitterActionFormBaseSchema = actionFormBaseSchema.append({
  platformType: Joi.string().valid("TWITTER").required(),
  type: Joi.string().valid("LIKE", "REPLY", "RETWEET").required(),
});

const twitterReplySchema = Joi.object({
  contentState: twitterContentStateSchema
    .custom((value: ContentState) => {
      const text = value.getPlainText() || "";
      if (text.trim() === "") {
        throw new Error(`Comment must contain some text`);
      }

      return value;
    })
    .required(),
}).unknown(true);

export const twitterActionSchema = twitterActionFormBaseSchema.when(
  Joi.object({ type: Joi.string().valid("REPLY") }).unknown(true),
  {
    then: twitterReplySchema,
  }
);

const facebookActionFormBaseSchema = actionFormBaseSchema.append({
  platformType: Joi.string().valid("FACEBOOK").required(),
  type: Joi.string().valid("COMMENT").required(),
});

const facebookCommentSchema = Joi.object({
  contentState: baseContentStateSchema
    .custom((value: ContentState) => {
      const text = value.getPlainText() || "";
      if (text.trim() === "") {
        throw new Error(`Comment must contain some text`);
      }

      return value;
    })
    .required(),
}).unknown(true);

export const facebookActionSchema = facebookActionFormBaseSchema.when(
  Joi.object({ type: Joi.string().valid("COMMENT") }).unknown(true),
  {
    then: facebookCommentSchema,
  }
);
