import { ComponentProps, forwardRef, useMemo } from "react";
import { ChevronDown as ChevronDownIcon } from "react-feather";
import { CreditCardIcon } from "@heroicons/react/outline";

import { PaymentMethod } from "../../../types/paymentMethods";

import Select, { SelectOption } from "../Select";

function buildRenderOptionFunction(
  paymentMethods: PaymentMethod[],
  selectedOption = false
) {
  return (option: SelectOption | undefined) => {
    if (!option) {
      if (selectedOption) {
        return (
          <div className="w-full flex items-center">
            <span>Select a payment method...</span>
            <ChevronDownIcon className="flex-shrink-0 ml-auto" />
          </div>
        );
      } else {
        return "";
      }
    }

    const paymentMethod = paymentMethods.find(
      (candidate) => candidate.id === option.value
    );

    if (!paymentMethod) {
      return "";
    }

    return (
      <div className="w-full flex items-center">
        <CreditCardIcon className="flex-shrink-0 w-6 h-6" />
        <span className="ml-4">{`${paymentMethod.brand[0].toUpperCase()}${paymentMethod.brand.slice(
          1
        )}`}</span>
        <span className="ml-4">{`•••• ${paymentMethod.last4}`}</span>
        {selectedOption && (
          <ChevronDownIcon className="flex-shrink-0 ml-auto" />
        )}
      </div>
    );
  };
}

interface PaymentMethodSelectProps
  extends Omit<
    ComponentProps<typeof Select>,
    "options" | "renderSelectedOption" | "includeSearch"
  > {
  paymentMethods: PaymentMethod[];
}

const PaymentMethodSelect = forwardRef<
  HTMLInputElement,
  PaymentMethodSelectProps
>(({ paymentMethods, ...otherProps }, ref) => {
  const options = useMemo(() => {
    return paymentMethods.map((paymentMethod) => ({
      text: paymentMethod.last4,
      value: paymentMethod.id,
    }));
  }, [paymentMethods]);

  const renderSelectedOption = useMemo(() => {
    return buildRenderOptionFunction(paymentMethods, true);
  }, [paymentMethods]);

  const renderOption = useMemo(() => {
    return buildRenderOptionFunction(paymentMethods, false);
  }, [paymentMethods]);

  return (
    <Select
      ref={ref}
      options={options}
      includeSearch={false}
      renderSelectedOption={renderSelectedOption}
      renderOption={renderOption}
      {...otherProps}
    />
  );
});

export default PaymentMethodSelect;
