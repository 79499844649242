import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Search as SearchIcon,
  Check as CheckIcon,
  UploadCloud as UploadCloudIcon,
} from "react-feather";
import {
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";

import { InternalImageAttachment } from "../../../../../types/posts";
import { InternalFile } from "../../../../../types/files";
import { useFilesByType } from "../../../../../libs/hooks/files";
import { clampImageHeight } from "../../../../../libs/utils";
import { useActiveWorkspace } from "../../../../../libs/hooks/app";
import {
  sortCreatedAsc,
  sortCreatedDesc,
  sortNameAsc,
  sortNameDesc,
  sortSizeAsc,
  sortSizeDesc,
} from "../../../../../libs/files";

import LoaderIcon from "../../../../../components/icons/Loader";
import Input from "../../../../../components/form/Input";
import InternalImage from "../../../../../components/images/Internal";
import ErrorAlert from "../../../../../components/alerts/Error";
import DropdownMenu from "../../../../../components/menus/Dropdown";
import SecondaryButton from "../../../../../components/buttons/Secondary";
import DropdownMenuButton from "../../../../../components/buttons/DropdownMenu";
import H2 from "../../../../../components/headings/H2";
import TertiaryButton from "../../../../../components/buttons/Tertiary";

import { ReactComponent as NoFilesImage } from "../../../../../images/no-files.svg";

const MAX_PREVIEW_HEIGHT = 250;

interface PostExtensionContentImageAttachmentsGalleryProps {
  imageAttachments: InternalImageAttachment[];
  addImage: (image: InternalImageAttachment) => void;
  removeImage: (imageId: string) => void;
  showUpload: () => void;
}

const PostExtensionContentImageAttachmentsGallery: React.FC<
  PostExtensionContentImageAttachmentsGalleryProps
> = ({ imageAttachments, addImage, removeImage, showUpload }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const workspace = useActiveWorkspace();
  const {
    isLoading,
    isError,
    data: filesData,
  } = useFilesByType(workspace.id, "IMAGE");
  const [searchValue, setSearchValue] = useState("");
  const [sortFunc, setSortFunc] = useState<
    (a: InternalFile, b: InternalFile) => -1 | 0 | 1
  >(() => sortCreatedDesc);
  const filteredImageFiles = useMemo(() => {
    const imageFiles = filesData || [];
    const loweredSearchValue = searchValue.toLowerCase();
    return imageFiles.filter((file) =>
      file.name.toLowerCase().includes(loweredSearchValue)
    );
  }, [filesData, searchValue]);
  const sortedImageFiles = useMemo(() => {
    const copyOfFiles = filteredImageFiles.slice();
    copyOfFiles.sort(sortFunc);
    return copyOfFiles;
  }, [filteredImageFiles, sortFunc]);
  const sortOptions = [
    { text: "Newest to oldest", func: sortCreatedDesc, asc: false },
    { text: "Oldest to newest", func: sortCreatedAsc, asc: true },
    { text: "Name A to Z", func: sortNameAsc, asc: true },
    { text: "Name Z to A", func: sortNameDesc, asc: false },
    { text: "Largest to smallest", func: sortSizeDesc, asc: false },
    { text: "Smallest to largest", func: sortSizeAsc, asc: true },
  ];
  const selectedSortOption = sortOptions.find(({ func }) => func === sortFunc);
  const hasImages = !!filesData && filesData.length > 0;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="relative w-full h-full overflow-hidden flex flex-col">
      {!isLoading && hasImages && (
        <div className="p-2 shrink-0 flex items-center">
          <div className="grow relative text-gray-500 focus-within:text-purple-500">
            <Input
              ref={inputRef}
              className="pl-10 py-2 pr-4 placeholder-gray-500 border-gray-200 bg-gray-200 hover:bg-white focus:bg-white"
              labelText="Search your image gallery"
              hideLabel={true}
              placeholder="Search your image gallery"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-2">
              <SearchIcon />
            </div>
          </div>
          <DropdownMenu className="ml-2 shrink-0 h-full" xAlign="right">
            <SecondaryButton
              className="py-2 h-full text-gray-500 bg-gray-200"
              size="sm"
            >
              {selectedSortOption && selectedSortOption.asc ? (
                <SortAscendingIcon className="h-6 w-6" />
              ) : (
                <SortDescendingIcon className="h-6 w-6" />
              )}
              {selectedSortOption && (
                <span className="ml-1">{selectedSortOption.text}</span>
              )}
            </SecondaryButton>
            <div className="p-2">
              {sortOptions.map(({ text, func, asc }) => {
                const isSelected = sortFunc === func;
                return (
                  <DropdownMenuButton
                    key={text}
                    className={`w-full flex items-center ${
                      isSelected ? "text-purple-500" : ""
                    }`}
                    onClick={() => setSortFunc(() => func)}
                  >
                    <span className="">{text}</span>
                    <span className="ml-auto pl-4">
                      {asc ? (
                        <SortAscendingIcon className="h-6 w-6" />
                      ) : (
                        <SortDescendingIcon className="h-6 w-6" />
                      )}
                    </span>
                  </DropdownMenuButton>
                );
              })}
            </div>
          </DropdownMenu>
        </div>
      )}

      {isError && (
        <ErrorAlert
          className="mt-2 shrink-0"
          title="Awkward!"
          message="We weren't able to load your image gallery"
        />
      )}
      {isLoading && (
        <div className="h-full w-full flex items-center justify-center">
          <LoaderIcon className="w-12 h-12" />
        </div>
      )}
      {!isLoading && hasImages && (
        <div className="grow overflow-x-hidden overflow-y-auto">
          <div className="p-2 flex flex-wrap justify-center items-start">
            {sortedImageFiles.map((image) => {
              const isSelected = !!imageAttachments.find(
                (candidate) => candidate.fileId === image.id
              );
              const imageHeight = image.preview
                ? image.preview.height
                : image.height || 0;
              const imageWidth = image.preview
                ? image.preview.width
                : image.width || 0;
              const [clampedHeight, clampedWidth] = clampImageHeight(
                imageHeight,
                imageWidth,
                MAX_PREVIEW_HEIGHT
              );

              return (
                <button
                  key={image.id}
                  type="button"
                  className={`m-1 shrink-0 relative rounded shadow bg-white border-4 border-transparent default-transition hover:scale-105 hover:shadow-lg hover:z-10 ${
                    isSelected ? "border-sky-200" : ""
                  }`}
                  onClick={() => {
                    if (isSelected) {
                      removeImage(image.id);
                    } else {
                      addImage({
                        fileId: image.id,
                        title: image.name,
                        width: image.width || 0,
                        height: image.height || 0,
                      });
                    }
                  }}
                >
                  <div style={{ height: clampedHeight, width: clampedWidth }}>
                    <InternalImage file={image} preview={true} />
                  </div>
                  <p
                    className="font-bold break-words"
                    style={{
                      width: 0,
                      minWidth: "100%",
                    }}
                  >
                    {image.name}
                  </p>
                  {isSelected && (
                    <div className="absolute top-1 left-1 h-6 w-6 rounded-full bg-sky-200 flex items-center justify-center">
                      <CheckIcon className="h-5 w-5 text-sky-700" />
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      )}
      {!isLoading && !hasImages && (
        <div className="h-full w-full flex items-center justify-center">
          <div className="relative flex flex-col items-center">
            <div style={{ width: 600 }}>
              <H2>Got some images to share?</H2>
              <p className="mt-6">
                Upload your images to Seenly and we'll keep them safe and secure
                until you're ready to share them with the world.
              </p>
            </div>
            <TertiaryButton className="mt-10" onClick={showUpload}>
              <UploadCloudIcon className="mr-2" />
              Upload your first image now
            </TertiaryButton>
            <NoFilesImage
              className="text-sky-200"
              style={{ width: 500, height: 500 }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PostExtensionContentImageAttachmentsGallery;
