import React, { useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { UploadCloud as UploadCloudIcon } from "react-feather";

import { VideoFileUpload } from "../../../../../types/uploads";

import { useActiveWorkspace } from "../../../../../libs/hooks/app";
import { useUploadFiles } from "../../../../../libs/hooks/uploads";

import { VideoAttachment } from "../../../../../types/posts";

import TertiaryButton from "../../../../../components/buttons/Tertiary";
import LoaderIcon from "../../../../../components/icons/Loader";
import VideoFileInput from "../../../../../components/form/input/VideoFile";

interface PostExtensionContentImageAttachmentsUploadProps {
  setVideoAttachment: (newVideoAttachment: VideoAttachment) => void;
  close: () => void;
}
const PostExtensionContentImageAttachmentsUpload: React.FC<
  PostExtensionContentImageAttachmentsUploadProps
> = ({ setVideoAttachment, close }) => {
  const workspace = useActiveWorkspace();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragSpring, dragSpringApi] = useSpring(() => ({
    opacity: 0,
  }));
  const {
    loading,
    error: uploadError,
    uploadFiles,
  } = useUploadFiles({
    owner: { type: "WORKSPACE", workspaceId: workspace.id },
    accept: ["VIDEO"],
    onDragEnter: () => dragSpringApi.start({ opacity: 1 }),
    onDragLeave: () => dragSpringApi.start({ opacity: 0 }),
    onDropEnd: (fileUploads) => {
      dragSpringApi.start({ opacity: 0 });

      if (fileUploads.length) {
        const fileUpload = fileUploads[0];
        setVideoAttachment({
          fileId: fileUpload.internalFile.id,
          title: "",
          height: (fileUpload as VideoFileUpload).videoHeight,
          width: (fileUpload as VideoFileUpload).videoWidth,
          duration: (fileUpload as VideoFileUpload).videoDuration,
          size: (fileUpload as VideoFileUpload).videoSize,
        });
        close();
      }
    },
  });

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length) {
      const { fileUploads } = await uploadFiles(files);

      if (fileUploads.length) {
        const fileUpload = fileUploads[0];
        setVideoAttachment({
          fileId: fileUpload.internalFile.id,
          title: fileUpload.internalFile.name,
          height: (fileUpload as VideoFileUpload).videoHeight,
          width: (fileUpload as VideoFileUpload).videoWidth,
          duration: (fileUpload as VideoFileUpload).videoDuration,
          size: (fileUpload as VideoFileUpload).videoSize,
        });
        close();
      }
    }
  };

  return (
    <div className="p-4 w-full h-full flex flex-col">
      {uploadError({ className: "mb-4 shrink-0" })}
      <div className="grow relative">
        <div className="w-full h-full border-2 border-dashed border-gray-300 bg-gray-50 rounded-lg flex items-center justify-center">
          {loading ? (
            <div className="flex flex-col items-center">
              <LoaderIcon className="h-12 w-12" />
              <p className="mt-1">Processing files</p>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <UploadCloudIcon className="h-12 w-12" />
              <p className="mt-1">Drag & drop your videos</p>
              <p className="mt-3">OR</p>
              <VideoFileInput ref={fileInputRef} onChange={onChange} />
              <TertiaryButton
                className="mt-3"
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                Browse
              </TertiaryButton>
            </div>
          )}
        </div>
        <animated.div
          className="absolute inset-4 border-2 border-dashed border-purple-300 bg-purple-50 rounded-lg items-center justify-center"
          style={{
            display: dragSpring.opacity.to((o) => (o > 0 ? "flex" : "none")),
            opacity: dragSpring.opacity,
          }}
        >
          <div className="flex flex-col items-center text-purple-900">
            <UploadCloudIcon className="h-12 w-12" />
            <p className="mt-1">Drop your videos to upload them</p>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default PostExtensionContentImageAttachmentsUpload;
