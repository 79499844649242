import React, { ComponentProps } from "react";
import { mergeClassNames } from "../../libs/components";
import ExternalLink from "./External";

const IconExternalLink: React.FC<ComponentProps<typeof ExternalLink>> = ({
  className = "",
  ...props
}) => {
  return (
    <ExternalLink
      className={mergeClassNames(
        "rounded-full px-2 py-2 bg-gray-100 bg-gradient-to-r hover:text-black hover:from-emerald-200 hover:to-sky-200 focus:text-black focus:from-emerald-200 focus:to-sky-200",
        className
      )}
      {...props}
    />
  );
};

export default IconExternalLink;
