import React, { useMemo } from "react";

import { Platform, SelectedEntities } from "../../../../types/platforms";
import { FormPublishedPostActionFacebookComment } from "../../../../types/publishedPostActions";

import { getDisplayNameForPublishedPostActionInstance } from "../../../../libs/publishedPostActions";
import PostTextArea from "../../post/TextArea";
import ActionContainer, {
  ActionContainerErrors,
} from "../base/ActionContainer";

import PublishedPostActionInstanceIcon from "../../../../components/icons/PublishedPostActionInstance";

interface FacebookCommentErrors extends ActionContainerErrors {
  contentState?: any;
}

interface PublishedPostActionsFormFacebookCommentProps {
  platforms: Platform[];
  value: FormPublishedPostActionFacebookComment;
  onChange: (newValue: FormPublishedPostActionFacebookComment) => void;
  onDelete: () => void;
  className?: string;
  errors?: FacebookCommentErrors;
}
const PublishedPostActionsFormFacebookComment: React.FC<
  PublishedPostActionsFormFacebookCommentProps
> = ({ platforms, value, onChange, onDelete, errors, className = "" }) => {
  const selectedEntities: SelectedEntities[] = useMemo(() => {
    return platforms.map((platform) => ({
      platform,
      entities: Object.values(platform.entities).filter(
        (entity) => !entity.disabled
      ),
    }));
  }, [platforms]);

  return (
    <ActionContainer
      className={className}
      title={
        <span className="flex items-center">
          <PublishedPostActionInstanceIcon
            className="h-6 w-6"
            platformType="FACEBOOK"
            type="COMMENT"
          />
          <span className="ml-1">
            {getDisplayNameForPublishedPostActionInstance(
              "FACEBOOK",
              "COMMENT"
            )}
          </span>
        </span>
      }
      platforms={platforms}
      value={value}
      errors={errors}
      onChange={(newValue) => {
        onChange({
          ...value,
          ...newValue,
        });
      }}
      onDelete={onDelete}
    >
      <PostTextArea
        textAreaContainerClassName="h-32"
        selectedEntities={selectedEntities}
        value={value.contentState}
        onChange={(newContentState) =>
          onChange({ ...value, contentState: newContentState })
        }
        onBlur={() => {}}
        error={!!errors && !!errors.contentState}
        enableHashtagSuggestions={false}
      />
    </ActionContainer>
  );
};

export default PublishedPostActionsFormFacebookComment;
