import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../../../../components/buttons/Primary";
import { Circle as CircleIcon, Upload } from "react-feather";
import { LinkedinBulkUploadCsvRow } from "../../../../types/linkedinBulkUploadCsvRow";
import { useSnackBarFactory } from "../../../../libs/hooks/general";
import CommonToast from "../../../../components/snackBarContent/CommonToast";
import { PostFormValues } from "../../../../types/posts";
import { PlatformType } from "../../../../types/enums/platformType";
import { buildCreateActionPropsForFormAction } from "../../../../libs/publishedPostActions";
import { ContentState } from "draft-js";
import {
  CreatePublishedPostActionProps,
  CreatePublishedPostActionPropsBase,
} from "../../../../types/publishedPostActions";
import { PostType } from "../../../../types/enums/postType";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useCurrentUser } from "../../../../libs/hooks/app";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

let displayErrorMessage = "";
let validFileUploaded = true;
let uploadedFile: File | undefined;
let uploadedCSVRows: LinkedinBulkUploadCsvRow[] = [];
let currentWorkspaceLinkedInHandles: string[] = [];
let currentAllowedSocials: PostFormValues["socials"];
let firstValueRowOfUploadedCSV: LinkedinBulkUploadCsvRow | undefined;
let publishedPostActionProps: CreatePublishedPostActionProps[] = [];

const onChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  onShowError: Function,
  onReturnPostText?: Function
) => {
  const files = e.target.files;
  if (files && files.length > 0) {
    clearInitialValues();
    // Validate CSV data
    readAndValidateCSV(files[0], onShowError, onReturnPostText);
  }
};

function readAndValidateCSV(
  file: File,
  onShowError: Function,
  onReturnPostText?: Function
): void {
  const fileTypeCSV = file.type.endsWith("csv");
  const fileTypeExcel =
    file.type.endsWith("sheet") || file.type.endsWith("excel");
  if (fileTypeCSV || fileTypeExcel) {
    uploadedFile = file;
    const reader = new FileReader();
    reader.onload = function (e) {
      const rawData: any = e.target?.result;
      if (rawData) {
        let csvData: string;
        if (fileTypeCSV) {
          // csvData = rawData as string;

          // Parse the CSV content using XLSX
          const workbook = XLSX.read(rawData, {
            type: "string",
            raw: false,
            FS: ",",
          });

          // Convert the first sheet to JSON
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          csvData = XLSX.utils.sheet_to_csv(worksheet);
        } else {
          const wb = XLSX.read(rawData, { type: "array" });
          const workSheet = wb.SheetNames[0];
          const ws = wb.Sheets[workSheet];
          csvData = XLSX.utils.sheet_to_csv(ws);
        }
        let rows: string[] = csvData.split(";eol;");
        rows = rows.filter((item) => item.trim().length > 0);
        rows = rows.map((item: any) => {
          return item.replace(/\n/, ""); // Removes the first occurrence of '\n'
        });
        const csvRows: LinkedinBulkUploadCsvRow[] = rows.map((row) => {
          // Split the row by the first 6 commas
          const columns = row.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);

          const postId = columns[0]?.trim();
          const user = columns[1]?.trim();
          const category = columns[2]?.trim();
          const postType = columns[3]?.trim();
          const engagementType = columns[4]?.trim();
          const engagedPrimaryPost = columns[5]?.trim();

          // Join the rest of the columns to form the postComment
          let postComment = columns.slice(6).join(",").trim();
          postComment = postComment.replace(/",$/, "");

          return {
            "Internal Post ID": postId,
            User: user,
            Category: category,
            "Post Type": postType,
            "Engagement Type": engagementType,
            "Engaged Primary Post": engagedPrimaryPost,
            "Post / Comment": postComment.replace(/(^"|"$)/g, ""), // Remove surrounding quotes if present
          };
        });

        // csv-parse package
        // parse(
        //   csvData,
        //   {
        //     columns: true,
        //     trim: true,
        //     skip_empty_lines: true,
        //   },
        //   (err, records) => {
        //     if (err) {
        //       console.error("Error parsing CSV:", err);
        //       return;
        //     }
        //
        //     // Process the records
        //     uploadedCSVRows = records.map((record: any) => ({
        //       "Internal Post ID": record["Internal Post ID"]?.trim(),
        //       User: record["User"]?.trim(),
        //       Category: record["Category"]?.trim(),
        //       "Post Type": record["Post Type"]?.trim(),
        //       "Engagement Type": record["Engagement Type"]?.trim(),
        //       "Engaged Primary Post": record["Engaged Primary Post"]?.trim(),
        //       "Post / Comment": record["Post / Comment"]?.trim(),
        //     }));
        //   }
        // );

        uploadedCSVRows = csvRows;

        // Validate CSV data
        const { isValid, errorMessage } = validateCsvAsPerRules(csvRows);
        if (isValid) {
          validFileUploaded = true;
          displayErrorMessage = errorMessage ?? "";
          if (onReturnPostText) {
            onReturnPostText(
              firstValueRowOfUploadedCSV &&
                firstValueRowOfUploadedCSV["Post / Comment"],
              publishedPostActionProps
            );
          }
        } else {
          validFileUploaded = false;
          displayErrorMessage = errorMessage + " A complete log can be found ";
          onShowError(displayErrorMessage);
        }
      } else {
        onShowError("Error reading file");
      }
    };
    if (fileTypeExcel) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsBinaryString(file);
    }
  } else {
    onShowError("Only allowed file types are 'csv', 'xlsx' and 'xls'");
  }
}

const validateCsvAsPerRules = (rows: LinkedinBulkUploadCsvRow[]) => {
  let isValid = true;
  let errorMessage = "";
  if (rows.length < 2) {
    isValid = false;
    errorMessage = "Minimum 2 rows required to validate the file.";
  } else {
    let originalPostTypeFound = false;
    for (let i = 0; i < rows.length; i++) {
      const { isValid, errorMessage, originalPostFound } =
        validateASingleRowWithValidationRules(
          rows[i],
          i,
          originalPostTypeFound
        );
      originalPostTypeFound = originalPostFound;
      if (!isValid) {
        return { isValid, errorMessage };
      }
      prepareAfterPostActionsFromLinkedinBulkUploadCsvRow(rows[i], i);
    }
    firstValueRowOfUploadedCSV = rows[1];
  }
  return { isValid, errorMessage };
};

const validateASingleRowWithValidationRules = (
  row: LinkedinBulkUploadCsvRow,
  i: number,
  originalPostTypeFound: boolean
) => {
  let errorMessage: string | null = null;
  let isValid = true;
  let originalPostFound = originalPostTypeFound;
  const {
    "Internal Post ID": postId,
    User: user,
    Category: category,
    "Post Type": postType,
    "Engagement Type": engagementType,
    "Engaged Primary Post": engagedPrimaryPost,
    "Post / Comment": postComment,
  } = row;
  if (!category) {
    errorMessage = `Error in row ${i + 1}: "Category" column cannot be empty.`;
    isValid = false;
  } else if (!postType) {
    errorMessage = `Error in row ${i + 1}: "Post Type" column cannot be empty.`;
    isValid = false;
  } else if (!user) {
    errorMessage = `Error in row ${i + 1}: "User" column cannot be empty.`;
    isValid = false;
  } else if (i >= 1 && !currentWorkspaceLinkedInHandles.includes(user)) {
    errorMessage = `Error in row ${
      i + 1
    }: Could not found "User" in this workspace.`;
    isValid = false;
  } else if (i >= 2 && !engagedPrimaryPost) {
    errorMessage = `Error in row ${
      i + 1
    }: "Engaged Primary Post" column cannot be empty from the 3rd row.`;
    isValid = false;
  } else if (
    (postType === "Engagement" || postType === "Comment on Post") &&
    category !== "Follow On"
  ) {
    errorMessage = `Error in row ${
      i + 1
    }: When Post Type is ${postType} "Category" must be 'Follow On'.`;
    isValid = false;
  } else if (
    (postType === "Share" || postType === "Share & Comment") &&
    category !== "Primary"
  ) {
    errorMessage = `Error in row ${
      i + 1
    }: When Post Type is ${postType} "Category" must be 'Primary'.`;
    isValid = false;
  } else if (i === 1 && postType !== "Original Post") {
    errorMessage = 'Error: First row must have Post Type as "Original Post".';
    isValid = false;
  } else if (postType === "Engagement" && !engagementType) {
    errorMessage = `Error in row ${
      i + 1
    }: When Post Type is "Engagement" the "Engagement Type" cannot be empty.`;
    isValid = false;
  } else if (i > 0 && postType !== "Original Post" && !engagedPrimaryPost) {
    errorMessage = `Error in row ${
      i + 1
    }: When Post Type is not "Original Post" the "Engaged Primary Post" cannot be empty.`;
    isValid = false;
  } else if (
    (postType === "Original Post" ||
      postType === "Share & Comment" ||
      postType === "Comment on Post") &&
    !postComment
  ) {
    errorMessage = `Error in row ${
      i + 1
    }: When "Post Type" is ${postType} the "Post / Comment" cannot be empty.`;
    isValid = false;
  } else if (postType === "Original Post") {
    if (originalPostFound) {
      errorMessage = `Error in row ${
        i + 1
      }: Multiple rows with "Post Type" as "Original Post" found.`;
      isValid = false;
    }
    originalPostFound = true;
  }
  return { isValid, errorMessage, originalPostFound };
};

const prepareAndDownloadCSVErrorLog = async (
  rows: LinkedinBulkUploadCsvRow[]
) => {
  const csvWithErrorLog = await generateCSVWithErrors(rows);
  saveCSVFile(csvWithErrorLog);
};

async function generateCSVWithErrors(
  rows: LinkedinBulkUploadCsvRow[]
): Promise<string> {
  const csvRowsWithErrorLog: string[] = [];
  csvRowsWithErrorLog.push(`${Object.keys(rows[0]).join(",")},Error`); // Header row
  let originalPostTypeFound = false;
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const { isValid, errorMessage, originalPostFound } =
      validateASingleRowWithValidationRules(row, i, originalPostTypeFound);
    originalPostTypeFound = originalPostFound;
    if (!isValid) {
      const errorLogRow = `${Object.values(row).join(",")},${errorMessage}`;
      csvRowsWithErrorLog.push(errorLogRow);
    }
  }

  return csvRowsWithErrorLog.join("\n");
}

function saveCSVFile(csvData: string): void {
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
  saveAs(blob, "error_log.csv");
}

const populateCurrentWorkspaceLinkedinHandles = (
  allowedSocials: PostFormValues["socials"]
) => {
  currentAllowedSocials = allowedSocials;
  currentWorkspaceLinkedInHandles = [];
  for (const allowedSocial of allowedSocials ?? []) {
    if (
      allowedSocial?.platform?.type === PlatformType.LINKEDIN &&
      allowedSocial.platform.username &&
      !currentWorkspaceLinkedInHandles.includes(allowedSocial.platform.username)
    ) {
      currentWorkspaceLinkedInHandles.push(allowedSocial.platform.username);
    }
  }
};

const getLinkedInReactionTypeFromEngagementTypeOnCSV = (
  engagementType: string
) => {
  const engagement = engagementType.trim().toUpperCase();
  if (engagement === "CELEBRATE") {
    return "PRAISE";
  } else if (engagement === "FUNNY") {
    return "ENTERTAINMENT";
  } else if (engagement === "LOVE") {
    return "EMPATHY";
  } else if (engagement === "INSIGHTFUL") {
    return "INTEREST";
  } else if (engagement === "SUPPORT") {
    return "APPRECIATION";
  } else {
    return "LIKE";
  }
};

const prepareAfterPostActionsFromLinkedinBulkUploadCsvRow = (
  linkedinBulkUploadCsvRow: LinkedinBulkUploadCsvRow,
  index: number
) => {
  const MAX_DELAY_LIMIT = 15;
  const commentText = linkedinBulkUploadCsvRow["Post / Comment"] ?? "";
  const commentContentState = ContentState.createFromText(commentText);
  const username = linkedinBulkUploadCsvRow.User ?? "";
  const postTypeMentionedOnCSV = linkedinBulkUploadCsvRow["Post Type"] ?? "";
  const engagementTypeMentionedOnCSV =
    getLinkedInReactionTypeFromEngagementTypeOnCSV(
      linkedinBulkUploadCsvRow["Engagement Type"] ?? ""
    );
  const allowedSocial = getAllowedSocialBySocialHandle(
    username,
    currentAllowedSocials
  );
  const priorityUploadGroup = [
    PostType.ShareAndComment.toString(),
    PostType.CommentOnPost.toString(),
  ].includes(postTypeMentionedOnCSV);
  if (username && allowedSocial) {
    const delayForThisAction = priorityUploadGroup ? 1 : 5;
    const base: CreatePublishedPostActionPropsBase = {
      postId: "",
      platformId: allowedSocial.platform.id,
      platformEntityId: allowedSocial.platformEntityId,
      delay: delayForThisAction,
      useRandomDelay: false,
    };
    if (postTypeMentionedOnCSV === PostType.Engagement) {
      const publishedPostActionProp: CreatePublishedPostActionProps =
        buildCreateActionPropsForFormAction({
          ...base,
          id: "",
          platformType: PlatformType.LINKEDIN,
          type: "REACTION",
          reactionType: engagementTypeMentionedOnCSV,
        });
      publishedPostActionProps.push(publishedPostActionProp);
    } else if (postTypeMentionedOnCSV === PostType.CommentOnPost) {
      const publishedPostActionProp: CreatePublishedPostActionProps =
        buildCreateActionPropsForFormAction({
          ...base,
          id: "",
          platformType: PlatformType.LINKEDIN,
          type: "COMMENT",
          contentState: commentContentState,
        });
      publishedPostActionProps.push(publishedPostActionProp);
    } else if (postTypeMentionedOnCSV === PostType.ShareAndComment) {
      const publishedPostActionProp: CreatePublishedPostActionProps =
        buildCreateActionPropsForFormAction({
          ...base,
          id: "",
          platformType: PlatformType.LINKEDIN,
          type: "RESHARE",
          contentState: commentContentState,
        });
      publishedPostActionProps.push(publishedPostActionProp);
    }
  }
};

const getAllowedSocialBySocialHandle = (
  username: string,
  allowedSocials: PostFormValues["socials"]
) => {
  return allowedSocials.find((item) => item.platform?.username === username);
};

const clearInitialValues = () => {
  displayErrorMessage = "";
  validFileUploaded = true;
  uploadedFile = undefined;
  uploadedCSVRows = [];
  firstValueRowOfUploadedCSV = undefined;
  publishedPostActionProps = [];
};

interface BulkUploadProps {
  allowedSocials: PostFormValues["socials"];
  onValidCSVUpload?: Function;
}

const BulkUpload: React.FC<BulkUploadProps> = ({
  allowedSocials,
  onValidCSVUpload,
}) => {
  const user = useCurrentUser();
  const [bulkUploadAvailable, setBulkUploadAvailable] = useState(false);
  const [bulkUploadChosen, setBulkUploadChosen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const createSnackBar = useSnackBarFactory();

  const showErrorToast = (message: string) => {
    createSnackBar({
      content: <CommonToast message={message} />,
    });
  };

  populateCurrentWorkspaceLinkedinHandles(allowedSocials);

  const onBulkUploadCheckChange = () => {
    setBulkUploadChosen((prevState) => !prevState);
    clearInitialValues();
    onValidCSVUpload && onValidCSVUpload("", []);
  };

  //On Mount
  useEffect(() => {
    clearInitialValues();
  }, []);

  useEffect(() => {
    const allowedUserEmails = [
      "hasan.munsi@seenly.io",
      "socials@seenly.io",
      "rmkamalesh@yahoo.com",
      "kamal@seenly.io",
      "vinay.kumar@seenly.io",
      "rahim@seenly.io",
      "social@chameleon.co",
      "rs@tenderhut.com",
      "shahrukh.islam@seenly.io",
    ];
    if (allowedUserEmails.includes(user?.email)) {
      setBulkUploadAvailable((prevState) => true);
    }
  }, [user.email]);

  return (
    <>
      {bulkUploadAvailable && (
        <div className={"w-full group mt-1 flex flex-col gap-2"}>
          <div
            onClick={onBulkUploadCheckChange}
            className={"flex flex-row w-fit gap-2 items-center cursor-pointer"}
          >
            {bulkUploadChosen ? (
              <CheckCircleIcon className="flex-shrink-0 h-5 w-5 text-purple-500" />
            ) : (
              <CircleIcon className="flex-shrink-0 h-5 w-5" />
            )}
            <span>Select to Bulk load from excel</span>
          </div>

          {bulkUploadChosen && (
            <PrimaryButton
              id="bulk-upload"
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
              className={"w-full"}
            >
              <input
                ref={fileInputRef}
                className="hidden"
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={(e) => {
                  onChange(e, showErrorToast, onValidCSVUpload);
                }}
              />
              <Upload className="shrink-0 h-6 w-6 mr-2" />
              Bulk Upload {uploadedFile ? `(${uploadedFile.name})` : ""}
            </PrimaryButton>
          )}

          {!validFileUploaded && (
            <span className={"mt-2 text-red-500"}>
              {displayErrorMessage}
              <button
                type={"button"}
                onClick={() => {
                  prepareAndDownloadCSVErrorLog(uploadedCSVRows).then();
                }}
                className={"font-semibold underline"}
              >
                here
              </button>
            </span>
          )}
        </div>
      )}
    </>
  );
};
export default BulkUpload;
