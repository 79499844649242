import React, { ReactElement, ReactNode, useEffect } from "react";
import Joi from "joi";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Save as SaveIcon } from "react-feather";
import { joiResolver } from "@hookform/resolvers/joi";

import { ReactComponent as BlankCanvasImage } from "../../../../images/blank-canvas.svg";
import { mergeClassNames } from "../../../../libs/components";

import H3 from "../../../../components/headings/H3";
import PrimaryButton from "../../../../components/buttons/Primary";
import LoaderIcon from "../../../../components/icons/Loader";

type FormValues = {
  actions: any[];
};

interface PublishedPostActionsFormContainerProps {
  onSubmit: (formData: any) => void;
  isSubmitting: boolean;
  actionSchema: Joi.Schema;
  defaultValues: FormValues;
  renderActionButtons: (createAction: (newAction: any) => void) => ReactElement;
  renderAction: (
    action: any,
    onChange: (newAction: any) => void,
    onDelete: () => void,
    errors: any
  ) => ReactElement;
  endOfFormElement?: ReactNode;
  onIsDirty?: (newIsDirty: boolean) => void;
  className?: string;
}
const PublishedPostActionsFormContainer: React.FC<
  PublishedPostActionsFormContainerProps
> = ({
  onSubmit,
  onIsDirty,
  isSubmitting,
  actionSchema,
  defaultValues,
  renderActionButtons,
  renderAction,
  endOfFormElement,
  className = "",
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    mode: "onSubmit",
    resolver: joiResolver(
      Joi.object({
        actions: Joi.array().items(actionSchema),
      }),
      { convert: false }
    ),
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    name: "actions",
    control,
  });

  useEffect(() => {
    if (onIsDirty) {
      onIsDirty(isDirty);
    }
  }, [isDirty, onIsDirty]);

  return (
    <form
      className={mergeClassNames("flex flex-col", className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grow">
        {fields.length < 1 && (
          <div className="mt-8 shrink-0">
            <BlankCanvasImage className="h-60 text-purple-500" />
            <p className="mt-4 px-16">
              Create powerful automations that get executed after your posts are
              published.
            </p>
          </div>
        )}
        {fields.map((field, index) => {
          return (
            <div key={field.id} className="mt-10">
              {index > 0 && (
                <div className="mx-10 mb-8 relative h-px bg-gray-300 flex items-center justify-center">
                  <div className="p-2 bg-white text-sm text-gray-300">AND</div>
                </div>
              )}
              <Controller
                control={control}
                name={`actions.${index}`}
                render={({ field: { onChange, value } }) => {
                  return renderAction(
                    value,
                    onChange,
                    () => remove(index),
                    errors && errors.actions && errors.actions[index]
                      ? errors.actions[index]
                      : undefined
                  );
                }}
              />
            </div>
          );
        })}
      </div>

      <div className="mt-14">
        <H3 className="text-sm text-gray-300">NEW ACTION</H3>
        {renderActionButtons((newAction) => append(newAction))}
      </div>

      <hr className="m-8 h-px bg-gray-300" />

      {endOfFormElement}

      <PrimaryButton
        className="shrink-0 w-full flex items-center"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <LoaderIcon className="h-6 w-6" />
        ) : (
          <SaveIcon className="h-6 w-6" />
        )}
        <span className="ml-2">
          {isSubmitting ? "Saving actions..." : "Save actions"}
        </span>
      </PrimaryButton>
    </form>
  );
};

export default PublishedPostActionsFormContainer;
