import React, { useMemo } from "react";
import {
  ArrowLeft as ArrowLeftIcon,
  Check as CheckIcon,
  Edit2 as EditIcon,
  Plus,
} from "react-feather";

import {
  useContentStack,
  useSnackBarFactory,
} from "../../../libs/hooks/general";
import {
  useActiveWorkspace,
  useCurrentUser,
  useShowModal,
} from "../../../libs/hooks/app";
import { useWorkspaces } from "../../../libs/hooks/workspaces";
import { useUpdateUserPreferences } from "../../../libs/hooks/userPreferences";

import DropdownMenuButton from "../../../components/buttons/DropdownMenu";
import IconButton from "../../../components/buttons/Icon";
import SwitchedWorkspaceSnackBarContent from "../../../components/snackBarContent/SwitchedWorkspace";

const UserMenuSwitchWorkspace: React.FC<{
  contentId: "switchWorkspace";
}> = () => {
  const { pop } = useContentStack();
  const showModal = useShowModal();
  const user = useCurrentUser();
  const activeWorkspace = useActiveWorkspace();
  const { data: workspacesData } = useWorkspaces(user.id);
  const { mutate: updateUserPreferences } = useUpdateUserPreferences();
  const createSnackBar = useSnackBarFactory();
  const sortedWorkspaces = useMemo(() => {
    const workspaces = workspacesData ? workspacesData.workspaces.slice() : [];
    return workspaces.sort((a, b) => {
      if (a.created < b.created) {
        return -1;
      } else if (a.created > b.created) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [workspacesData]);

  return (
    <div className="p-2">
      <div className="flex items-center">
        <IconButton
          className="flex-shrink-0"
          onClick={(e) => {
            e.preventDefault();
            pop();
          }}
        >
          <ArrowLeftIcon />
        </IconButton>
        <h2 className="ml-2 font-bold text-2xl">Switch workspaces</h2>
      </div>
      <p className="mt-4 px-2 text-sm text-gray-500">
        Switch workspaces to manage multiple sets of social accounts.
      </p>
      <div className="mt-2 w-full flex flex-col">
        {sortedWorkspaces.map((workspace) => {
          const isSelected = workspace.id === activeWorkspace.id;
          return (
            <DropdownMenuButton
              key={workspace.id}
              className={isSelected ? "text-purple-500" : ""}
              onClick={() => {
                if (isSelected) {
                  return;
                }

                updateUserPreferences({
                  userId: user.id,
                  updateProps: { activeWorkspaceId: workspace.id },
                });
                createSnackBar({
                  content: (
                    <SwitchedWorkspaceSnackBarContent
                      newWorkspaceName={workspace.name}
                    />
                  ),
                });
              }}
            >
              {isSelected && <CheckIcon className="w-6 h-6" />}
              <span className={`ml-2 truncate ${!isSelected ? "pl-6" : ""}`}>
                {workspace.name}
              </span>
              <div
                className="ml-auto hover:bg-gray-300 py-2 px-2 rounded-lg"
                onClick={() => {
                  showModal("updateWorkspace", { workspace });
                }}
              >
                <EditIcon size={14} />
              </div>
            </DropdownMenuButton>
          );
        })}
        <hr className="m-2" />
        <DropdownMenuButton
          onClick={() => {
            showModal("addWorkspace");
          }}
        >
          <div className="mr-2 p-2 rounded-full bg-gray-200">
            <Plus className="h-4 w-4" />
          </div>
          Add new workspace
        </DropdownMenuButton>
      </div>
    </div>
  );
};

export default UserMenuSwitchWorkspace;
