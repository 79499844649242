import React from "react";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";

interface SwitchedWorkspaceSnackBarContentProps {
  newWorkspaceName: string;
}
const SwitchedWorkspaceSnackBarContent: React.FC<
  SwitchedWorkspaceSnackBarContentProps
> = ({ newWorkspaceName }) => {
  return (
    <div className="flex items-center">
      <SwitchHorizontalIcon className="flex-shrink-0 w-5 h-5" />
      <div className="ml-2">
        <div className="leading-3 text-xs">SWITCHED WORKSPACE</div>
        <div className="font-bold text-lg">{newWorkspaceName}</div>
      </div>
    </div>
  );
};

export default SwitchedWorkspaceSnackBarContent;
