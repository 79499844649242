import React from "react";
import {
  CheckCircle as CheckCircleIcon,
  RefreshCw as RefreshCwIcon,
} from "react-feather";
import { PresentationChartLineIcon } from "@heroicons/react/outline";

import { WorkspaceAnalytics } from "../../../types/workspaceAnalytics";

import { mergeClassNames } from "../../../libs/components";
import { formatCountForDisplay } from "../../../libs/postMetrics";
import { useDateTime } from "../../../libs/hooks/app";

import Card from "../../../components/analytics/dailySnapshot/Card";

interface AnalyticsBodyDetailsProps {
  postsCount: WorkspaceAnalytics["postsCount"];
  lastUpdated: string;
  className?: string;
}
const AnalyticsBodyDetails: React.FC<AnalyticsBodyDetailsProps> = ({
  postsCount,
  lastUpdated,
  className = "",
}) => {
  const lastUpdatedDate = useDateTime(lastUpdated);

  return (
    <div
      className={mergeClassNames(
        "flex items-center flex-wrap gap-4",
        className
      )}
    >
      <Card
        icon={<RefreshCwIcon />}
        iconContainerClassName="bg-gradient-to-br from-gray-500 to-stone-300"
        subtitleClassName="text-2xl"
        title="Last updated"
        subtitle={lastUpdatedDate.toRelative({ round: true, style: "narrow" })}
      />
      <Card
        icon={<CheckCircleIcon />}
        iconContainerClassName="bg-gradient-to-br from-gray-500 to-stone-300"
        title="Posts published"
        subtitle={
          <div className="flex items-baseline">
            <div>{formatCountForDisplay(postsCount.published.value)}</div>

            <div
              className={`ml-4 text-base flex items-center ${
                postsCount.published.delta >= 0
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {`${postsCount.published.delta >= 0 ? "+" : ""}${
                postsCount.published.delta
              }`}
            </div>
          </div>
        }
      />
      <Card
        icon={<PresentationChartLineIcon />}
        iconContainerClassName="bg-gradient-to-br from-gray-500 to-stone-300"
        title="Posts analysed"
        subtitle={
          <div className="flex items-baseline">
            <div>{formatCountForDisplay(postsCount.measured.value)}</div>

            <div
              className={`ml-4 text-base flex items-center ${
                postsCount.measured.delta >= 0
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {`${postsCount.measured.delta >= 0 ? "+" : ""}${
                postsCount.measured.delta
              }`}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AnalyticsBodyDetails;
