import React, {
  CSSProperties,
  ReactNode,
  RefObject,
  useMemo,
  useRef,
} from "react";
import { DateTime } from "luxon";
import { animated, to, useSprings } from "@react-spring/web";
import {
  Plus as PlusIcon,
  Copy as CopyIcon,
  CheckCircle as CheckCircleIcon,
  MoreHorizontal as MoreHorizontalIcon,
  Edit as EditIcon,
  Trash as TrashIcon,
  List as ListIcon,
} from "react-feather";
import {
  SelectorIcon,
  FlagIcon,
  PauseIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import { convertFromRaw } from "draft-js";

import { Post } from "../../../types/posts";

import { mergeClassNames } from "../../../libs/components";
import { clamp } from "../../../libs/utils";
import { useDragDrop, useHasChanged } from "../../../libs/hooks/general";

import TertiaryButton from "../../../components/buttons/Tertiary";
import OutlineButton from "../../../components/buttons/Outline";
import { PostQueue } from "../../../types/postQueues";
import DropdownMenu from "../../../components/menus/Dropdown";
import IconButton from "../../../components/buttons/Icon";
import DropdownMenuButton from "../../../components/buttons/DropdownMenu";
import {
  useActiveWorkspaceTimeZone,
  useShowModal,
} from "../../../libs/hooks/app";
import { Platform, PlatformEntity } from "../../../types/platforms";
import { useUpdatePost } from "../../../libs/hooks/posts";
import { useUpdatePostQueue } from "../../../libs/hooks/postQueues";
import PublishedPostActionIcon from "../../../components/icons/PublishedPostAction";

interface ListItemBase {
  id: string;
  height: number;
}

interface ListItemPost extends ListItemBase {
  type: "POST";
  post: Post;
}

interface ListItemHeader extends ListItemBase {
  type: "HEADER";
  text: (postQueue: PostQueue, timeZone: string) => ReactNode;
  draft: boolean;
  count?: number;
}

interface ListItemButton extends ListItemBase {
  type: "BUTTON";
  text: string;
  draft: boolean;
}

type ListItem = ListItemPost | ListItemHeader | ListItemButton;

interface DragArgs {
  initial: {
    index: number;
    y: number;
    draft: boolean;
  };
  current: {
    index: number;
    y: number;
    draft: boolean;
  };
  minY: number;
  maxY: number;
  newListItems: ListItem[];
}

export const QUEUE_POST_DRAG_TYPE = "postQueuePost";

const HEADER_HEIGHT = 40;
const POST_HEIGHT = 120;
const BUTTON_HEIGHT = 80;
const GUTTER_HEIGHT = 8;
const UP_NEXT_HEADER_LIST_ITEM: ListItemHeader = {
  id: "upNextHeader", // Same as the up paused header.
  height: HEADER_HEIGHT,
  type: "HEADER",
  text: (postQueue, timeZone) => {
    if (postQueue.status === "PAUSED") {
      return (
        <>
          <PauseIcon className="mr-2 w-5 h-5" />
          PAUSED
        </>
      );
    } else if (postQueue.state && postQueue.state.type === "SCHEDULED") {
      const scheduledDate = DateTime.fromISO(
        postQueue.state.scheduledDate
      ).setZone(timeZone);

      return (
        <>
          <ClockIcon className="mr-1 w-5 h-5" />
          {`PROJECTED FOR ${scheduledDate
            .toFormat("h:mm a EEE, d MMM")
            .toUpperCase()}`}
        </>
      );
    } else {
      return (
        <>
          <CheckCircleIcon className="mr-2 w-4 h-4" />
          UP NEXT
        </>
      );
    }
  },
  draft: false,
};
const QUEUED_HEADER_LIST_ITEM: ListItemHeader = {
  id: "queuedHeader",
  height: HEADER_HEIGHT,
  type: "HEADER",
  text: () => (
    <>
      <ListIcon className="mr-2 w-4 h-4" />
      QUEUED
    </>
  ),
  draft: false,
};
const DRAFTS_HEADER_LIST_ITEM: ListItemHeader = {
  id: "draftsHeader",
  height: HEADER_HEIGHT,
  type: "HEADER",
  text: () => (
    <>
      <FlagIcon className="mr-2 w-4 h-4" />
      DRAFTS
    </>
  ),
  draft: true,
};
const QUEUED_BUTTON_LIST_ITEM: ListItemButton = {
  id: "queuedButton",
  height: BUTTON_HEIGHT,
  type: "BUTTON",
  text: "Create queued content",
  draft: false,
};
const DRAFTS_BUTTON_LIST_ITEM: ListItemButton = {
  id: "draftsButton",
  height: BUTTON_HEIGHT,
  type: "BUTTON",
  text: "Create draft content",
  draft: true,
};

function buildPostsSortFunction(postsOrder: string[]) {
  return (a: Post, b: Post) => {
    if (a.status === "QUEUED" && b.status === "QUEUEDDRAFT") {
      return -1;
    } else if (a.status === "QUEUEDDRAFT" && b.status === "QUEUED") {
      return 1;
    }

    const aIndex = postsOrder.indexOf(a.id);
    const bIndex = postsOrder.indexOf(b.id);

    if (aIndex >= 0 && bIndex < 0) {
      return -1;
    } else if (aIndex < 0 && bIndex >= 0) {
      return 1;
    } else if (aIndex >= 0 && bIndex >= 0) {
      return aIndex - bIndex;
    }

    if (a.created < b.created) {
      return -1;
    } else if (a.created > b.created) {
      return 1;
    }

    return 0;
  };
}

function calculateListItemsHeight(listItems: ListItem[]) {
  return listItems.reduce<number>((carry, listItem) => {
    return carry + listItem.height;
  }, 0);
}

function buildSpringsFunction(
  listItems: ListItem[],
  springIndexToListItemId: { [index: number]: string },
  draggedListItemId?: string,
  draggedListItemYPos?: number,
  dragging?: boolean,
  immediate?: boolean
): any {
  return (springIndex: number) => {
    const listItemId = springIndexToListItemId[springIndex];
    const listItemIndex = listItems.findIndex(
      (listItem) => listItem.id === listItemId
    );
    const listItem = listItems[listItemIndex];
    const beforeListItems = listItems.slice(0, listItemIndex);

    if (listItemId === draggedListItemId) {
      if (dragging) {
        return {
          y: draggedListItemYPos || 0,
          zIndex: 2,
          shadow: 15,
          scale: 1.02,
          cursor: "grabbing",
          immediate: (k: string) =>
            k === "zIndex" || k === "y" || k === "cursor",
        };
      } else {
        return {
          to: [
            {
              y: calculateListItemsHeight(beforeListItems),
              shadow: 1,
              scale: 1,
              cursor: "grab",
              immediate: (k: string) => k === "cursor",
            },
            {
              zIndex: 1,
              immediate: true,
            },
          ],
        };
      }
    } else {
      return {
        y: calculateListItemsHeight(beforeListItems),
        shadow: 1,
        scale: 1,
        zIndex: listItem.type === "POST" ? 1 : 0,
        cursor: "grab",
        immediate: immediate
          ? true
          : (k: string) => k === "zIndex" || k === "cursor",
      };
    }
  };
}

interface SmartSchedulesQueuePostsProps {
  postQueue: PostQueue;
  platform: Platform;
  platformEntity: PlatformEntity;
  posts: Post[];
  scrollContainerRef: RefObject<HTMLElement>;
  setIsLoading: (isLoading: boolean) => void;
  className?: string;
}
const SmartSchedulesQueuePosts: React.FC<SmartSchedulesQueuePostsProps> = ({
  postQueue,
  platform,
  platformEntity,
  posts,
  scrollContainerRef,
  setIsLoading,
  className = "",
}) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const springIndexToListItemIdRef = useRef<{ [index: number]: string }>({});
  const listItemIdToSpringIndexRef = useRef<{ [index: string]: number }>({});
  const timeZone = useActiveWorkspaceTimeZone();
  const { drag } = useDragDrop();
  const showModal = useShowModal();
  const { mutateAsync: updatePost } = useUpdatePost();
  const { mutateAsync: updatePostQueue } = useUpdatePostQueue();
  const { listItems } = useMemo(() => {
    const sortedPosts = posts.slice();
    sortedPosts.sort(buildPostsSortFunction(postQueue.postsOrder || []));

    const { queuedCount, draftCount } = sortedPosts.reduce<{
      queuedCount: number;
      draftCount: number;
    }>(
      (carry, post) => {
        if (post.status === "QUEUED") {
          carry.queuedCount++;
        } else {
          carry.draftCount++;
        }
        return carry;
      },
      { queuedCount: 0, draftCount: 0 }
    );

    const listItems: ListItem[] = [];

    const queuedPosts = sortedPosts.slice(0, queuedCount);
    const draftPosts = sortedPosts.slice(queuedCount);
    const queuedWithoutUpNextCount = Math.max(0, queuedCount - 1);

    queuedPosts.forEach((post, index) => {
      if (index === 0) {
        listItems.push(UP_NEXT_HEADER_LIST_ITEM);
      } else if (index === 1) {
        listItems.push({
          ...QUEUED_HEADER_LIST_ITEM,
          count: queuedWithoutUpNextCount,
        });
      }

      listItems.push({
        id: post.id,
        height: POST_HEIGHT + GUTTER_HEIGHT,
        type: "POST",
        post,
      });
    });

    if (listItems.length === 0 || listItems.length === 2) {
      // We didn't add any queued posts so the queued header.
      listItems.push({
        ...QUEUED_HEADER_LIST_ITEM,
        count: queuedWithoutUpNextCount,
      });
    }

    listItems.push(QUEUED_BUTTON_LIST_ITEM);
    listItems.push({ ...DRAFTS_HEADER_LIST_ITEM, count: draftCount });

    draftPosts.forEach((post) => {
      listItems.push({
        id: post.id,
        height: POST_HEIGHT + GUTTER_HEIGHT,
        type: "POST",
        post,
      });
    });

    listItems.push(DRAFTS_BUTTON_LIST_ITEM);

    if (
      Object.keys(springIndexToListItemIdRef.current).length !==
      listItems.length
    ) {
      springIndexToListItemIdRef.current = {};
      listItemIdToSpringIndexRef.current = {};

      listItems.forEach(({ id }, index) => {
        springIndexToListItemIdRef.current[index] = id;
        listItemIdToSpringIndexRef.current[id] = index;
      });
    }

    return { listItems, queuedCount, draftCount };
  }, [posts, postQueue.postsOrder]);
  const hasItemListLengthChanged = useHasChanged(listItems.length);

  const [springs, springsApi] = useSprings<{
    y: number;
    zIndex: number;
    shadow: number;
    scale: number;
    cursor: CSSProperties["cursor"];
  }>(
    listItems.length,
    buildSpringsFunction(
      listItems,
      springIndexToListItemIdRef.current,
      undefined,
      undefined,
      undefined,
      hasItemListLengthChanged
    ),
    [listItems]
  );

  const handleDragStart: Parameters<typeof drag>[0]["onStart"] = ({
    itemId,
  }): DragArgs => {
    const draggedItemStartIndex = listItems.findIndex(
      (listItem) => listItem.id === itemId
    );
    const draggedItem = listItems[draggedItemStartIndex];
    const beforeListItems = listItems.slice(0, draggedItemStartIndex);
    const draggedListItemStartingYPos =
      calculateListItemsHeight(beforeListItems);
    const draft =
      draggedItem.type === "POST"
        ? draggedItem.post.status === "QUEUEDDRAFT"
        : false;

    springsApi.start(
      buildSpringsFunction(
        listItems,
        springIndexToListItemIdRef.current,
        itemId,
        draggedListItemStartingYPos,
        true
      )
    );

    return {
      initial: {
        index: draggedItemStartIndex,
        y: draggedListItemStartingYPos,
        draft,
      },
      current: {
        index: draggedItemStartIndex,
        y: draggedListItemStartingYPos,
        draft,
      },
      minY: calculateListItemsHeight(listItems.slice(0, 1)),
      maxY: calculateListItemsHeight(listItems.slice(0, listItems.length - 2)),
      newListItems: listItems.slice(),
    };
  };

  const handleDragUpdate: Parameters<typeof drag>[0]["onUpdate"] = ({
    itemId,
    args,
    state,
    scrollMove,
  }) => {
    const { initial, current, newListItems, minY, maxY } = args as DragArgs;
    const [, moveScrollY] = scrollMove;
    const [, moveY] = state.movement;

    // Total amount of pixels moved since drag start.
    const totalMoveY = moveY + moveScrollY;
    // The new absolute position.
    const newYPos = initial.y + totalMoveY;
    // How many pixels moved since we last updated the index in the list.
    const moveYDiff = newYPos - current.y;
    const absMoveYDiff = Math.abs(moveYDiff);
    const moveUp = moveYDiff < 0;
    const nextIndexes = [];
    let nextDraft = current.draft;

    if (current.index > 0 && current.index < newListItems.length - 1) {
      // Find the next post (if any) and ignore the first item and last item
      // in the list because it's always a header and button.
      let nextIndex = clamp(
        moveUp ? current.index - 1 : current.index + 1,
        1,
        newListItems.length - 2
      );
      let keepSearching = newListItems[nextIndex].type !== "POST";

      nextIndexes.push(nextIndex);

      while (
        nextIndex > 1 &&
        nextIndex < newListItems.length - 2 &&
        keepSearching
      ) {
        nextIndex = moveUp ? nextIndex - 1 : nextIndex + 1;
        const nextItem = newListItems[nextIndex];

        if (nextItem.type === "POST") {
          keepSearching = false;

          if ((nextIndex === 1 || current.index === 1) && !current.draft) {
            // Special handling for the "Up next" section which should
            // swap the posts rather than add new posts to the section.
            nextIndexes.push(nextIndex);
          }
        } else {
          // The queue and draft sections are bounded by header and button list items
          // which contain the draft status. If we cross one of those bounds then we
          // take that status.
          if (nextDraft !== nextItem.draft) {
            nextDraft = nextItem.draft;

            if (nextDraft || newListItems[1].type === "POST") {
              keepSearching = false;
            }
          }

          nextIndexes.push(nextIndex);
        }
      }
    }

    if (nextIndexes.length > 0) {
      const totalMoveListItemsHeight = nextIndexes.reduce(
        (carry, listItemIndex) => carry + newListItems[listItemIndex].height,
        0
      );
      const requiredMoveY = Math.round(totalMoveListItemsHeight / 2);
      const shouldUpdateListPosition = absMoveYDiff > requiredMoveY;

      if (shouldUpdateListPosition) {
        const nextIndex = nextIndexes[nextIndexes.length - 1];

        if (
          (nextIndex === 1 || current.index === 1) &&
          newListItems[nextIndex].type === "POST"
        ) {
          // Moving into the "Up next" position so we should swap positions
          // rather than move the post.
          const nextListItem = newListItems[nextIndex];
          newListItems[nextIndex] = newListItems[current.index];
          newListItems[current.index] = nextListItem;
        } else {
          const [moved] = newListItems.splice(current.index, 1);
          newListItems.splice(nextIndex, 0, moved);
        }

        current.index = nextIndex;
        current.draft = nextDraft;
        current.y = clamp(
          moveUp
            ? current.y - totalMoveListItemsHeight
            : current.y + totalMoveListItemsHeight,
          minY,
          maxY
        );
      }
    }

    springsApi.start(
      buildSpringsFunction(
        newListItems,
        springIndexToListItemIdRef.current,
        itemId,
        newYPos,
        true
      )
    );
  };

  const handleDragEnd: Parameters<typeof drag>[0]["onEnd"] = async ({
    itemId,
    args,
    state,
    scrollMove,
  }) => {
    const { initial, current, newListItems } = args as DragArgs;
    const [, moveScrollY] = scrollMove;
    const [, moveY] = state.movement;

    const totalMoveY = moveY + moveScrollY;
    const newYPos = initial.y + totalMoveY;

    springsApi.start(
      buildSpringsFunction(
        newListItems,
        springIndexToListItemIdRef.current,
        itemId,
        newYPos,
        false
      )
    );

    const postsOrder = newListItems.reduce<string[]>((carry, listItem) => {
      if (listItem.type === "POST") {
        carry.push(listItem.post.id);
      }
      return carry;
    }, []);

    setIsLoading(true);

    const promises: Promise<any>[] = [
      updatePostQueue({
        postQueue,
        updateProps: {
          postsOrder,
        },
        optimistic: true,
      }),
    ];

    if (current.draft !== initial.draft) {
      const draggedItem = newListItems[current.index];

      if (draggedItem.type === "POST") {
        promises.push(
          updatePost({
            post: draggedItem.post,
            updateProps: {
              status: current.draft ? "QUEUEDDRAFT" : "QUEUED",
            },
            optimistic: true,
          })
        );
      }
    }

    try {
      await Promise.all(promises);
    } catch (e) {}

    setIsLoading(false);
  };

  const movePostToQueued = async (post: Post) => {
    if (post.status === "QUEUED") {
      return;
    }

    const newListItems = listItems.slice();
    const currentIndex = newListItems.findIndex(
      (candidate) => candidate.id === post.id
    );
    const queuedButtonIndex = newListItems.findIndex(
      (candidate) => candidate.type === "BUTTON" && !candidate.draft
    );

    setIsLoading(true);

    const promises: Promise<any>[] = [];

    if (queuedButtonIndex !== currentIndex) {
      const [moved] = newListItems.splice(currentIndex, 1);
      newListItems.splice(queuedButtonIndex, 0, moved);

      const postsOrder = newListItems.reduce<string[]>((carry, listItem) => {
        if (listItem.type === "POST") {
          carry.push(listItem.post.id);
        }
        return carry;
      }, []);

      promises.push(
        updatePostQueue({
          postQueue,
          updateProps: {
            postsOrder,
          },
          optimistic: true,
        })
      );
    }

    promises.push(
      updatePost({
        post,
        updateProps: {
          status: "QUEUED",
        },
        optimistic: true,
      })
    );

    await Promise.all(promises);
    setIsLoading(false);
  };

  const movePostToDrafts = async (post: Post) => {
    if (post.status === "QUEUEDDRAFT") {
      return;
    }

    const newListItems = listItems.slice();
    const currentIndex = newListItems.findIndex(
      (candidate) => candidate.id === post.id
    );
    const draftsHeaderIndex = newListItems.findIndex(
      (candidate) => candidate.type === "HEADER" && candidate.draft
    );

    setIsLoading(true);

    const promises: Promise<any>[] = [];

    if (currentIndex !== draftsHeaderIndex) {
      const [moved] = newListItems.splice(currentIndex, 1);
      newListItems.splice(draftsHeaderIndex, 0, moved);

      const postsOrder = newListItems.reduce<string[]>((carry, listItem) => {
        if (listItem.type === "POST") {
          carry.push(listItem.post.id);
        }
        return carry;
      }, []);

      promises.push(
        updatePostQueue({
          postQueue,
          updateProps: {
            postsOrder,
          },
          optimistic: true,
        })
      );
    }

    promises.push(
      updatePost({
        post,
        updateProps: {
          status: "QUEUEDDRAFT",
        },
        optimistic: true,
      })
    );

    await Promise.all(promises);
    setIsLoading(false);
  };

  return (
    <div
      className={mergeClassNames("w-full relative", className)}
      style={{ height: calculateListItemsHeight(listItems) }}
    >
      {listItems.map((listItem) => {
        const springIndex = listItemIdToSpringIndexRef.current[listItem.id];
        const spring = springs[springIndex];

        return (
          <animated.div
            key={listItem.id}
            ref={itemRef}
            className="w-full absolute top-0 left-0"
            style={{
              height: listItem.height,
              transform: to(
                [spring.y, spring.scale],
                (y, s) => `translate3d(0, ${y}px, 0) scale(${s})`
              ),
              zIndex: spring.zIndex,
            }}
          >
            {listItem.type === "HEADER" && (
              <div className="w-full h-full flex items-center">
                <h3 className="w-full flex items-center font-bold text-sm text-gray-400">
                  {listItem.text(postQueue, timeZone)}
                  {!!listItem.count && (
                    <span className="ml-auto font-normal">
                      {listItem.count === 1
                        ? "1 item"
                        : `${listItem.count} items`}
                    </span>
                  )}
                </h3>
              </div>
            )}

            {listItem.type === "BUTTON" && (
              <div className="w-full h-full">
                <OutlineButton
                  className="w-full border-2 border-dashed border-gray-300 text-gray-300"
                  onClick={() =>
                    showModal("createPost", {
                      defaultValues: {
                        socials: [
                          {
                            platform,
                            platformEntityId: platformEntity.id,
                          },
                        ],
                        status: "QUEUED",
                        draft: listItem.draft,
                      },
                    })
                  }
                >
                  <div className="mr-2 p-1 rounded-full bg-gray-50">
                    <PlusIcon className="w-4 h-4" />
                  </div>
                  {listItem.text}
                </OutlineButton>
              </div>
            )}

            {listItem.type === "POST" && (
              <animated.div
                className="p-1 w-full rounded-lg bg-white flex flex-col"
                style={{
                  height: POST_HEIGHT,
                  boxShadow: spring.shadow.to(
                    (s) =>
                      `0 ${s}px ${3 * s}px 0 rgb(0 0 0 / 0.1), 0 ${s}px ${
                        2 * s
                      }px ${-1 * s}px rgb(0 0 0 / 0.1)`
                  ),
                }}
              >
                <div className="flex-shrink-0 flex items-center">
                  <animated.div
                    style={{
                      cursor: spring.cursor,
                    }}
                  >
                    <TertiaryButton
                      ref={drag({
                        itemId: listItem.id,
                        type: QUEUE_POST_DRAG_TYPE,
                        itemRef,
                        scrollContainerRef,
                        onStart: handleDragStart,
                        onUpdate: handleDragUpdate,
                        onEnd: handleDragEnd,
                      })}
                      className="bg-transparent text-gray-400"
                      size="sm"
                      style={{ cursor: "inherit" }}
                    >
                      <SelectorIcon className="h-5 w-5 mr-1" />
                      Move
                    </TertiaryButton>
                  </animated.div>
                  <DropdownMenu className="ml-auto" xAlign="right">
                    <IconButton className="px-1 py-1 text-gray-400">
                      <MoreHorizontalIcon className="h-5 w-5" />
                    </IconButton>
                    <div className="p-1">
                      <DropdownMenuButton
                        onClick={() =>
                          showModal("editPost", {
                            post: listItem.post,
                            platform,
                          })
                        }
                      >
                        <EditIcon className="h-4 w-4" />
                        <span className="ml-2">Edit</span>
                      </DropdownMenuButton>

                      <DropdownMenuButton
                        onClick={() =>
                          showModal("editPublishedPostActions", {
                            post: listItem.post,
                          })
                        }
                      >
                        <PublishedPostActionIcon className="h-4 w-4" />
                        <span className="ml-2">Edit after publish actions</span>
                      </DropdownMenuButton>

                      {listItem.post.status === "QUEUED" && (
                        <DropdownMenuButton
                          onClick={() => movePostToDrafts(listItem.post)}
                        >
                          <FlagIcon className="h-4 w-4" />
                          <span className="ml-2">Move to drafts</span>
                        </DropdownMenuButton>
                      )}
                      {listItem.post.status === "QUEUEDDRAFT" && (
                        <DropdownMenuButton
                          onClick={() => movePostToQueued(listItem.post)}
                        >
                          <CheckCircleIcon className="h-4 w-4" />
                          <span className="ml-2">Move to queued</span>
                        </DropdownMenuButton>
                      )}
                      <DropdownMenuButton
                        onClick={() =>
                          showModal("createPost", {
                            defaultValues: {
                              contentState: convertFromRaw(
                                listItem.post.text.contentState
                              ),
                              attachment: listItem.post.attachment,
                            },
                          })
                        }
                      >
                        <CopyIcon className="h-4 w-4" />
                        <span className="ml-2">Duplicate</span>
                      </DropdownMenuButton>
                      <DropdownMenuButton
                        onClick={() =>
                          showModal("deletePost", {
                            post: listItem.post,
                            platform,
                          })
                        }
                      >
                        <TrashIcon className="h-4 w-4" />
                        <span className="ml-2">Delete</span>
                      </DropdownMenuButton>
                    </div>
                  </DropdownMenu>
                </div>
                <div className="p-2 flex-grow flex items-center">
                  <p
                    className="leading-5 overflow-hidden break-words overflow-ellipsis whitespace-pre-wrap"
                    style={{
                      height: "3.75rem",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      display: "-webkit-box",
                    }}
                  >
                    {listItem.post.text.plain}
                  </p>
                </div>
              </animated.div>
            )}
          </animated.div>
        );
      })}
    </div>
  );
};

export default SmartSchedulesQueuePosts;
