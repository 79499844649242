import React from "react";
import {
  Activity as ActivityIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
} from "react-feather";
import { mergeClassNames } from "../../libs/components";
import {
  ENGAGEMENT_LEVELS,
  getEngagementLevelDescription,
} from "../../libs/postMetrics";
import { Post } from "../../types/posts";
import Tooltip from "../Tooltip";

interface PostMetricsEngagementIconProps {
  engagementLevel: NonNullable<NonNullable<Post["metrics"]>["engagementLevel"]>;
  className?: string;
  size?: "sm" | "md" | "lg";
}

const PostMetricsEngagementIcon: React.FC<PostMetricsEngagementIconProps> = ({
  engagementLevel,
  className = "",
  size = "md",
}) => {
  let iconClassName = "";
  let containerPaddingClassName = "";

  switch (size) {
    case "sm":
      iconClassName = "h-3.5 w-3.5";
      containerPaddingClassName = "p-1";
      break;
    case "md":
      iconClassName = "h-4 w-4";
      containerPaddingClassName = "p-1.5";
      break;
    case "lg":
      iconClassName = "h-5 w-5";
      containerPaddingClassName = "p-1.5";
      break;
  }

  return (
    <Tooltip
      className={mergeClassNames(
        `rounded-full ${containerPaddingClassName} ${
          engagementLevel === ENGAGEMENT_LEVELS.RED
            ? "bg-red-100 text-red-400"
            : engagementLevel === ENGAGEMENT_LEVELS.AMBER
            ? "bg-amber-100 text-amber-400"
            : "bg-green-100 text-green-400"
        }`,
        className
      )}
      content={getEngagementLevelDescription(engagementLevel)}
    >
      {engagementLevel === ENGAGEMENT_LEVELS.RED ? (
        <TrendingDownIcon className={iconClassName} />
      ) : engagementLevel === ENGAGEMENT_LEVELS.AMBER ? (
        <ActivityIcon className={iconClassName} />
      ) : (
        <TrendingUpIcon className={iconClassName} />
      )}
    </Tooltip>
  );
};

export default PostMetricsEngagementIcon;
