import React from "react";
import { mergeClassNames } from "../../../libs/components";
import { Platform, PlatformEntity } from "../../../types/platforms";
import PlatformIcon from "../../icons/Platform";
import ProfilePicture from "../Profile";

interface PlatformEntityProfilePictureProps {
  entity: PlatformEntity;
  className?: string;
  platformType?: Platform["type"];
  circle?: boolean;
}

const PlatformEntityProfilePicture: React.FC<
  PlatformEntityProfilePictureProps
> = ({ entity, className = "", platformType = null, circle = true }) => {
  const [firstName, lastName] = entity.name.split(" ");
  const pictureElement = (
    <ProfilePicture
      className={mergeClassNames("w-11 h-11", className)}
      source={entity.pictureUrl}
      firstName={firstName}
      lastName={lastName}
      circle={circle}
    />
  );

  if (platformType !== null) {
    return (
      <div className="inline-block flex-shrink-0 relative">
        {pictureElement}
        <span className="absolute -bottom-1.5 -right-1.5">
          <PlatformIcon className="h-6 w-6" type={platformType} colour={true} />
        </span>
      </div>
    );
  } else {
    return pictureElement;
  }
};

export default PlatformEntityProfilePicture;
