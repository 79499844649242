import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "../../types/general";

import { useApiClient } from "./app";
import { FACEBOOK_QUERY_KEYS } from "../queryKeys";

interface PageData {
  id: string;
  name: string;
  isVerified?: boolean;
  username?: string;
  url?: string;
  pictureUrl?: string;
}

interface UseSearchFacebookPagesProps {
  platformId: string;
  query: string;
}
export const useSearchFacebookPages = (
  { platformId, query }: UseSearchFacebookPagesProps,
  options = { enabled: true, keepPreviousData: true }
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useQuery(
    FACEBOOK_QUERY_KEYS.list({ platformId, query }),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<PageData[]>>(
        "/proxies/facebook",
        {
          params: {
            action: "searchPages",
            platformId,
            query,
          },
        }
      );

      return response.data;
    },
    {
      ...options,
      onSuccess: (facebookPages) => {
        facebookPages.forEach((facebookPage) => {
          queryClient.setQueryData(
            FACEBOOK_QUERY_KEYS.detail(facebookPage.id),
            facebookPage
          );
        });
      },
    }
  );
};

export const useFacebookPage = (platformId: string, pageId: string) => {
  const apiClient = useApiClient();

  return useQuery(FACEBOOK_QUERY_KEYS.detail(pageId), async () => {
    const { data: response } = await apiClient.get<ApiResponse<PageData>>(
      "/proxies/facebook",
      {
        params: {
          action: "getPage",
          platformId,
          pageId,
        },
      }
    );

    return response.data;
  });
};
