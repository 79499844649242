import React, { useState, forwardRef } from "react";
import Joi from "joi";
import { nanoid } from "nanoid";
import { useTransition, animated } from "@react-spring/web";
import { Eye, EyeOff, Circle, CheckCircle } from "react-feather";

import LinkButton from "../../buttons/Link";
import { mergeClassNames } from "../../../libs/components";

const lowerRegex = /[a-z]/;
const upperRegex = /[A-Z]/;
const numberRegex = /[0-9]/;
const specialRegex = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/;

export const passwordSchema = Joi.string()
  .pattern(lowerRegex, "lower")
  .pattern(upperRegex, "upper")
  .pattern(numberRegex, "number")
  .pattern(specialRegex, "special")
  .min(8);

interface PasswordInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type"
  > {
  error?: boolean;
  labelClassName?: string;
  showRequirements?: boolean;
  labelText?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      className = "",
      labelClassName = "",
      id = nanoid(),
      error = false,
      showRequirements = false,
      labelText = "Password",
      value,
      onChange,
      ...inputProps
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState((value as string) || "");
    const [showPassword, setShowPassword] = useState(false);
    const showPasswordTransitions = useTransition(showPassword, {
      initial: { opacity: 1 },
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    });
    const checkPasswordMatch = (
      key: "lower" | "upper" | "number" | "special"
    ) => {
      if (!internalValue) {
        return false;
      }

      switch (key) {
        case "lower":
          return lowerRegex.test(internalValue);
        case "upper":
          return upperRegex.test(internalValue);
        case "number":
          return numberRegex.test(internalValue);
        case "special":
          return specialRegex.test(internalValue);
      }
    };
    const passwordRequirements = [
      {
        pass: checkPasswordMatch("lower"),
        text: "One lowercase letter",
      },
      {
        pass: checkPasswordMatch("upper"),
        text: "One uppercase letter",
      },
      {
        pass: checkPasswordMatch("number"),
        text: "One number",
      },
      {
        pass: checkPasswordMatch("special"),
        text: "One special character",
      },
      {
        pass: internalValue.length >= 8,
        text: "Minimum 8 characters",
      },
    ];

    return (
      <>
        <div
          className={mergeClassNames("flex items-center mb-2", labelClassName)}
        >
          <label
            className={`font-bold block default-transition ${
              error ? "text-red-500" : ""
            }`}
            htmlFor={id}
          >
            {labelText}
          </label>
          <LinkButton
            className="ml-auto flex items-center relative pl-6"
            onClick={() => setShowPassword(!showPassword)}
            tabIndex={1}
          >
            {showPasswordTransitions((style, item) => {
              return (
                <animated.div
                  className="absolute inset-y-0 left-0 flex items-center"
                  style={style}
                >
                  {item ? <EyeOff size={20} /> : <Eye size={20} />}
                </animated.div>
              );
            })}
            {showPassword ? "Hide" : "Show"}
          </LinkButton>
        </div>
        <input
          ref={ref}
          id={id}
          className={mergeClassNames(
            `py-3 px-4 w-full rounded-lg bg-transparent default-transition border-2 focus:border-purple-500 ${
              error ? "border-red-500" : "border-gray-300"
            }`,
            className
          )}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={(e) => {
            setInternalValue(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          {...inputProps}
        />
        {showRequirements && (
          <div className="mt-2 flex flex-wrap">
            {passwordRequirements.map((requirement) => {
              return (
                <div
                  key={requirement.text}
                  className={`flex items-center w-1/2 text-sm transition ease-in-out duration-300 ${
                    requirement.pass ? "text-gray-300" : ""
                  }`}
                >
                  <div
                    className={`mr-2 transition ease-in-out duration-300 ${
                      requirement.pass ? "text-gray-300" : "text-purple-500"
                    }`}
                  >
                    {requirement.pass ? (
                      <CheckCircle size="15" />
                    ) : (
                      <Circle size="15" />
                    )}
                  </div>
                  {requirement.text}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }
);

export default PasswordInput;
