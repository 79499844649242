import React, { useEffect, useRef, useState } from "react";

import { ImageAttachment } from "../../../../types/posts";

import { mergeClassNames } from "../../../../libs/components";

import PostImageAttachmentImage from "../../../images/PostAttachment";

const COLOURS = {
  BORDER_GRAY: "rgb(207, 217, 222)",
};
const MAX_SINGLE_IMAGE_HEIGHT = 510;
const MAX_MULTI_IMAGE_HEIGHT = 56.25;

interface TwitterPostPreviewImagesProps {
  imageAttachments: ImageAttachment[];
  className?: string;
}
const TwitterPostPreviewImages: React.FC<TwitterPostPreviewImagesProps> = ({
  imageAttachments,
  className = "",
}) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const [imageContainerWidth, setImageContainerWidth] = useState(0);

  const slot1 = imageAttachments.length > 0 ? imageAttachments[0] : null;
  const slot2 = imageAttachments.length > 3 ? imageAttachments[1] : null;
  const slot3 =
    imageAttachments.length > 3
      ? imageAttachments[2]
      : imageAttachments.length > 1
      ? imageAttachments[1]
      : null;
  const slot4 =
    imageAttachments.length > 3
      ? imageAttachments[3]
      : imageAttachments.length > 2
      ? imageAttachments[2]
      : null;
  const canShowImages = imageContainerWidth > 0 && !!slot1;

  useEffect(() => {
    if (imageContainerRef.current) {
      setImageContainerWidth(imageContainerRef.current.clientWidth);
    }
  }, []);

  return (
    <div
      ref={imageContainerRef}
      className={mergeClassNames(
        "rounded-2xl overflow-hidden relative",
        className
      )}
      style={{
        border: `1px solid ${COLOURS.BORDER_GRAY}`,
        paddingBottom: !!slot3 ? `${MAX_MULTI_IMAGE_HEIGHT}%` : undefined,
      }}
    >
      {canShowImages && (
        <div className={`flex ${!!slot3 ? "absolute inset-0" : ""}`}>
          <div className={`h-full ${slot3 ? "w-1/2 mr-px" : ""}`}>
            <div
              className={`w-full relative ${slot2 ? `h-1/2 mb-px` : "h-full"}`}
              style={{
                maxHeight: !slot3 ? MAX_SINGLE_IMAGE_HEIGHT : undefined,
              }}
            >
              <PostImageAttachmentImage
                className={`${
                  slot3 ? "w-full h-full object-center object-cover" : ""
                }`}
                containerClassName="absolute inset-0"
                imageAttachment={slot1!}
              />
            </div>
            {slot2 && (
              <div className="pt-px w-full h-1/2 relative">
                <PostImageAttachmentImage
                  className="w-full h-full object-center object-cover"
                  containerClassName="absolute inset-0"
                  imageAttachment={slot2}
                />
              </div>
            )}
          </div>
          {slot3 && (
            <div className="ml-px h-full w-1/2">
              <div
                className={`w-full relative ${
                  slot4 ? "h-1/2 mb-px" : "h-full"
                }`}
              >
                <PostImageAttachmentImage
                  className="w-full h-full object-center object-cover"
                  containerClassName="absolute inset-0"
                  imageAttachment={slot3}
                />
              </div>
              {slot4 && (
                <div className="mt-px w-full h-1/2 relative">
                  <PostImageAttachmentImage
                    className="w-full h-full object-center object-cover"
                    containerClassName="absolute inset-0"
                    imageAttachment={slot4}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TwitterPostPreviewImages;
