import { ContentState, convertToRaw, convertFromRaw, Modifier } from "draft-js";
import { DateTime } from "luxon";
import { Platform } from "../types/platforms";
import {
  CreatePostProps,
  CreatePostPropsBase,
  CreateQueuedPostProps,
  ImageAttachment,
  InternalImageAttachment,
  MentionEntityData,
  Post,
  PostFormValues,
  PostFormValuesByPlatformType,
  PostWithScheduledProp,
  UnsplashImageAttachment,
} from "../types/posts";
import { PostTextContentState } from "../types/posts";
import {
  EntityInstanceMapValue,
  EntityTypes,
  ENTITY_TYPES,
  getEntitiesByTypeFromContentState,
  getEntitySelection,
  replaceEntityDisplayText,
} from "./postTextArea";
import { deepCopy, randomIntBetween } from "./utils";

export const POST_PREVIEW_WIDTH = {
  FACEBOOK: 680,
  LINKEDIN: 540,
  TWITTER: 600,
};

export function isInternalImageAttachment(
  imageAttachment: ImageAttachment
): imageAttachment is InternalImageAttachment {
  return !!(imageAttachment as InternalImageAttachment).fileId;
}

export function isUnsplashImageAttachment(
  imageAttachment: ImageAttachment
): imageAttachment is UnsplashImageAttachment {
  return (imageAttachment as UnsplashImageAttachment).type === "UNSPLASH";
}

function findTimeWeightKeys(
  timeWeights: { [time: string]: number },
  dateTime: DateTime
) {
  const searchISODateTime = dateTime.toISO();
  const times = Object.keys(timeWeights);

  // Add an extra time to the end of the list to catch any times in
  // that last 15 minute bracket.
  const timeCap = DateTime.fromISO(times[times.length - 1]).plus({
    minutes: 15,
  });
  times.push(timeCap.toISO());

  const afterTimeIndex = times.findIndex(
    (isoDateTime) => isoDateTime > searchISODateTime
  );

  if (afterTimeIndex <= 0) {
    return [null, null, null];
  } else {
    const timeIndex = afterTimeIndex - 1;
    const beforeTimeIndex = timeIndex - 1;

    return [
      beforeTimeIndex >= 0 ? times[beforeTimeIndex] : null,
      times[timeIndex],
      afterTimeIndex < times.length - 1 ? times[afterTimeIndex] : null,
    ];
  }
}

function buildMorningTimes(day: DateTime) {
  const base = day.set({ second: 0, millisecond: 0 });
  return [
    // 8am - 10am.
    base.set({ hour: 8, minute: 0 }),
    base.set({ hour: 8, minute: 15 }),
    base.set({ hour: 8, minute: 30 }),
    base.set({ hour: 8, minute: 45 }),
    base.set({ hour: 9, minute: 0 }),
    base.set({ hour: 9, minute: 15 }),
    base.set({ hour: 9, minute: 30 }),
    base.set({ hour: 9, minute: 45 }),
    base.set({ hour: 10, minute: 0 }),
  ];
}

function buildNoonTimes(day: DateTime) {
  const base = day.set({ second: 0, millisecond: 0 });
  return [
    // 11:30am - 2pm.
    base.set({ hour: 11, minute: 30 }),
    base.set({ hour: 11, minute: 45 }),
    base.set({ hour: 12, minute: 0 }),
    base.set({ hour: 12, minute: 15 }),
    base.set({ hour: 12, minute: 30 }),
    base.set({ hour: 12, minute: 45 }),
    base.set({ hour: 13, minute: 0 }),
    base.set({ hour: 13, minute: 15 }),
    base.set({ hour: 13, minute: 30 }),
    base.set({ hour: 13, minute: 45 }),
    base.set({ hour: 14, minute: 0 }),
    base.set({ hour: 14, minute: 15 }),
    base.set({ hour: 14, minute: 30 }),
  ];
}

function buildEveningTimes(day: DateTime) {
  const base = day.set({ second: 0, millisecond: 0 });
  return [
    // 5pm - 8pm.
    base.set({ hour: 17, minute: 0 }),
    base.set({ hour: 17, minute: 15 }),
    base.set({ hour: 17, minute: 30 }),
    base.set({ hour: 17, minute: 45 }),
    base.set({ hour: 18, minute: 0 }),
    base.set({ hour: 18, minute: 15 }),
    base.set({ hour: 18, minute: 35 }),
    base.set({ hour: 18, minute: 45 }),
    base.set({ hour: 19, minute: 0 }),
    base.set({ hour: 19, minute: 15 }),
    base.set({ hour: 19, minute: 30 }),
    base.set({ hour: 19, minute: 45 }),
    base.set({ hour: 20, minute: 0 }),
  ];
}

function buildTimeWeights(
  day: DateTime,
  baseWeight: number,
  fromTime: DateTime | null = null,
  ignoreTimes: DateTime[] = [],
  includeMorning = true,
  includeNoon = true,
  includeEvening = true
) {
  const fromISODateTimeString = fromTime ? fromTime.toISO() : null;
  const ignoreHours = ignoreTimes.map((ignoreDateTime) => ignoreDateTime.hour);
  let timeWeights: { [isoDateTime: string]: number } = {};

  if (includeMorning) {
    const morning = buildMorningTimes(day);

    morning
      .filter((morningDateTime) => {
        return (
          !ignoreHours.includes(morningDateTime.hour) &&
          (!fromISODateTimeString ||
            morningDateTime.toISO() > fromISODateTimeString)
        );
      })
      .forEach((morningDateTime) => {
        timeWeights[morningDateTime.toISO()] = baseWeight;
      });
  }

  if (includeNoon) {
    const noon = buildNoonTimes(day);

    noon
      .filter((noonDateTime) => {
        return (
          !ignoreHours.includes(noonDateTime.hour) &&
          (!fromISODateTimeString ||
            noonDateTime.toISO() > fromISODateTimeString)
        );
      })
      .forEach((noonDateTime) => {
        timeWeights[noonDateTime.toISO()] = baseWeight;
      });
  }

  if (includeEvening) {
    const evening = buildEveningTimes(day);

    evening
      .filter((eveningDateTime) => {
        return (
          !ignoreHours.includes(eveningDateTime.hour) &&
          (!fromISODateTimeString ||
            eveningDateTime.toISO() > fromISODateTimeString)
        );
      })
      .forEach((eveningDateTime) => {
        timeWeights[eveningDateTime.toISO()] = baseWeight;
      });
  }

  return timeWeights;
}

export function canGenerateSuggestedMorningTime(
  fromTime: DateTime | null = null,
  ignoreTimes: DateTime[] = []
) {
  if (!fromTime) {
    return true;
  }

  const now = DateTime.local().setZone(fromTime.zone);
  const timeWeights = buildTimeWeights(
    fromTime,
    1,
    now,
    ignoreTimes,
    true,
    false,
    false
  );

  return Object.keys(timeWeights).length > 0;
}

export function canGenerateSuggestedNoonTime(
  fromTime: DateTime | null = null,
  ignoreTimes: DateTime[] = []
) {
  if (!fromTime) {
    return true;
  }

  const now = DateTime.local().setZone(fromTime.zone);
  const timeWeights = buildTimeWeights(
    fromTime,
    1,
    now,
    ignoreTimes,
    false,
    true,
    false
  );

  return Object.keys(timeWeights).length > 0;
}

export function canGenerateSuggestedEveningTime(
  fromTime: DateTime | null = null,
  ignoreTimes: DateTime[] = []
) {
  if (!fromTime) {
    return true;
  }

  const now = DateTime.local().setZone(fromTime.zone);
  const timeWeights = buildTimeWeights(
    fromTime,
    1,
    now,
    ignoreTimes,
    false,
    false,
    true
  );

  return Object.keys(timeWeights).length > 0;
}

export function generateSuggestedPostDateTime(
  postsByISODate: { [isoDate: string]: PostWithScheduledProp[] },
  timeZone: string,
  platformTypes: Platform["type"][],
  fromTime: DateTime | null = null,
  ignoreTimes: DateTime[] = [],
  includeMorning = true,
  includeNoon = true,
  includeEvening = true
) {
  const candidates = Object.keys(postsByISODate).reduce<string[]>(
    (carry, isoDate) => {
      const posts = postsByISODate[isoDate] || [];
      const postsOfSelectedType = posts.filter((post) =>
        platformTypes.includes(post.type)
      );

      const anyPostCost = posts.length > 0 ? 2 : 0;
      const selectedTypeCost = postsOfSelectedType.length * 2;
      const baseDayWeight = Math.max(1, 10 - anyPostCost - selectedTypeCost);
      const dayDateTime = DateTime.fromISO(isoDate, { zone: timeZone });
      const timeWeights = buildTimeWeights(
        dayDateTime,
        baseDayWeight,
        fromTime,
        ignoreTimes,
        includeMorning,
        includeNoon,
        includeEvening
      );

      postsOfSelectedType.forEach((post) => {
        const scheduledDateTime = DateTime.fromISO(post.scheduled).setZone(
          timeZone
        );
        const [beforeKey, key, afterKey] = findTimeWeightKeys(
          timeWeights,
          scheduledDateTime
        );

        if (beforeKey) {
          // Slightly reduce chance of picking a date right before another post.
          timeWeights[beforeKey] = Math.max(1, timeWeights[beforeKey] - 2);
        }

        if (key) {
          // Massively reduce chance of picking a date right before another post.
          timeWeights[key] = Math.max(1, timeWeights[key] - 5);
        }

        if (afterKey) {
          // Slightly reduce chance of picking a date right after another post.
          timeWeights[afterKey] = Math.max(1, timeWeights[afterKey] - 2);
        }
      });

      for (const [isoDateTime, weight] of Object.entries(timeWeights)) {
        for (let i = 0; i < weight; i++) {
          carry.push(isoDateTime);
        }
      }

      return carry;
    },
    []
  );

  if (candidates.length < 1) {
    return null;
  } else {
    const index = randomIntBetween(0, candidates.length - 1);
    const isoDate = candidates[index];
    return DateTime.fromISO(isoDate, { zone: timeZone });
  }
}

export function contentStateToPostText(
  contentState: ContentState
): Post["text"] {
  return {
    plain: contentState.getPlainText(),
    contentState: convertToRaw(contentState) as PostTextContentState,
  };
}

export function postFormDataToCreatePostProps({
  socials,
  scheduled,
  contentState,
  attachment,
  status,
  draft,
  recycle,
}: PostFormValues): CreatePostProps[] {
  return socials.map(({ platform, platformEntityId }) => {
    const propsBase: CreatePostPropsBase = {
      platformId: platform.id,
      platformEntityId,
      text: contentStateToPostText(contentState),
    };

    if (attachment) {
      propsBase.attachment = attachment;
    }

    let postStatus: CreatePostProps["status"] = "SCHEDULED";

    if (status === "SCHEDULED") {
      postStatus = draft ? "DRAFT" : "SCHEDULED";

      return {
        ...propsBase,
        status: postStatus,
        scheduled: scheduled,
      };
    } else {
      postStatus = draft ? "QUEUEDDRAFT" : "QUEUED";

      const createQueuedPostProps: CreateQueuedPostProps = {
        ...propsBase,
        status: postStatus,
      };

      if (recycle) {
        createQueuedPostProps.shouldRecycle = recycle;
      }

      return createQueuedPostProps;
    }
  });
}

export function postToDefaultPostFormData(
  platform: Platform,
  post: Post
): Partial<PostFormValues> {
  let status: PostFormValues["status"] = "SCHEDULED";

  switch (post.status) {
    case "SCHEDULED":
      status = "SCHEDULED";
      break;
    case "QUEUED":
      status = "QUEUED";
      break;
    case "DRAFT":
      status = "SCHEDULED";
      break;
    case "QUEUEDDRAFT":
      status = "QUEUED";
      break;
  }

  const defaultValues: Partial<PostFormValues> = {
    socials: [{ platform, platformEntityId: post.platformEntityId }],
    contentState: convertFromRaw(post.text.contentState),
    attachment: post.attachment || null,
    status,
    draft: post.status === "DRAFT" || post.status === "QUEUEDDRAFT",
  };

  if (post.scheduled) {
    defaultValues.scheduled = post.scheduled;
  }

  if (post.shouldRecycle) {
    defaultValues.recycle = post.shouldRecycle;
  }

  return defaultValues;
}

export function updateMentionEntityForPlatformTypeInContentState(
  contentState: ContentState,
  platformType: string,
  mentionInstance: EntityInstanceMapValue<EntityTypes["MENTION"]>
): ContentState {
  if (mentionInstance.entity.getMutability() !== "IMMUTABLE") {
    // The user never selected a mention so there's nothing to do.
    return contentState;
  }

  const entityData = mentionInstance.entity.getData();
  const platformMention = entityData.mentions[platformType] || null;
  const entitySelection = getEntitySelection(
    contentState,
    mentionInstance.blockKey,
    mentionInstance.entityKey
  );

  if (!entitySelection) {
    // Weirdly we couldn't find the entity in the block...
    return contentState;
  }

  // Delete the current entity because we're going to create a new one.
  let newContentState = Modifier.applyEntity(
    contentState,
    entitySelection,
    null
  );

  const newEntityData: MentionEntityData = {
    mentions: {},
  };

  if (platformMention) {
    newEntityData.displayAs = platformType as MentionEntityData["displayAs"];
    newEntityData.mentions[platformType] = platformMention;
  }

  newContentState = newContentState.createEntity(
    ENTITY_TYPES.MENTION,
    platformMention ? "IMMUTABLE" : "MUTABLE",
    newEntityData
  );
  const newEntityKey = newContentState.getLastCreatedEntityKey();

  newContentState = Modifier.applyEntity(
    newContentState,
    entitySelection,
    newEntityKey
  );

  const newEntityText = platformMention ? platformMention.text : null;

  if (newEntityText) {
    newContentState = replaceEntityDisplayText(
      mentionInstance.blockKey,
      newEntityKey,
      newContentState,
      newEntityText
    );
  }

  return newContentState;
}

export function buildContentStateForPlatformType(
  platformType: string,
  contentState: ContentState
): ContentState {
  const entitiesByType = getEntitiesByTypeFromContentState(contentState);
  const mentionEntities = entitiesByType[ENTITY_TYPES.MENTION];

  return Object.values(mentionEntities).reduce((carry, mentionInstance) => {
    return updateMentionEntityForPlatformTypeInContentState(
      carry,
      platformType,
      mentionInstance
    );
  }, contentState);
}

export function buildPostFormValuesByPlatformType(
  data: PostFormValues
): PostFormValuesByPlatformType {
  const socialsByPlatformType = data.socials.reduce<{
    [platformType: string]: PostFormValues["socials"];
  }>((carry, social) => {
    const type = social.platform.type;

    if (!carry[type]) {
      carry[type] = [];
    }

    carry[type].push(social);

    return carry;
  }, {});

  const platformTypes = Object.keys(socialsByPlatformType);

  if (platformTypes.length === 1) {
    return {
      [platformTypes[0]]: data,
    };
  } else {
    return platformTypes.reduce<PostFormValuesByPlatformType>(
      (carry, platformType) => {
        carry[platformType] = {
          socials: socialsByPlatformType[platformType],
          scheduled: data.scheduled,
          contentState: buildContentStateForPlatformType(
            platformType,
            data.contentState
          ),
          attachment: data.attachment ? deepCopy(data.attachment) : null,
          status: data.status,
          draft: data.draft,
          recycle: data.recycle,
        };
        return carry;
      },
      {}
    );
  }
}
