import React, {
  useState,
  useEffect,
  useRef,
  HTMLAttributes,
  ReactElement,
  cloneElement,
  CSSProperties,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import {
  useAnimatedMounting,
  useOpenCloseStack,
} from "../../libs/hooks/general";

interface DropdownMenuBaseProps {
  className?: string;
  yAlign?: "below" | "over";
  xAlign?: "left" | "right";
  animate?: boolean;
  menuClassName?: string;
  menuStyle?: CSSProperties;
  closeOnSelect?: boolean;
  onMenuToggle?: (isOpen: boolean) => void;
  controlled?: boolean;
  style?: CSSProperties;
}

export interface DropdownMenuUncontrolledProps extends DropdownMenuBaseProps {
  children: [
    ReactElement<HTMLAttributes<HTMLButtonElement>>,
    ReactElement | ((props: { close: () => void }) => ReactElement)
  ];
}

export interface DropdownMenuControlledProps extends DropdownMenuBaseProps {
  children: [ReactElement, ReactElement];
  isOpen: boolean;
  controlled: true;
}

const DropdownMenu = <
  TProps extends DropdownMenuUncontrolledProps | DropdownMenuControlledProps
>({
  className = "",
  menuClassName = "bg-white rounded-lg shadow-lg border border-gray-100",
  yAlign = "below",
  xAlign = "left",
  animate = true,
  children,
  style = {},
  menuStyle = {},
  controlled = false,
  onMenuToggle,
  ...rest
}: TProps extends { controlled: true }
  ? DropdownMenuControlledProps
  : DropdownMenuUncontrolledProps) => {
  const [internalIsOpen, setIsOpen] = useState(
    controlled ? (rest as any).isOpen : false
  );
  const isOpen = controlled ? (rest as any).isOpen : internalIsOpen;
  const animateFn = useAnimatedMounting({
    mount: isOpen,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [trigger, menuContent] = children;
  const [menuCoords, setMenuCoords] = useState({
    left: -9999999,
    top: -9999999,
    right: "auto",
    bottom: "auto",
  });
  const [menuMinWidth, setMenuMinWidth] = useState(0);
  const closeOnSelect = controlled
    ? false
    : (rest as any).closeOnSelect !== false;
  const close = useCallback(() => {
    setIsOpen(false);
    onMenuToggle && onMenuToggle(false);
  }, [onMenuToggle]);
  const openCloseHandlers = useOpenCloseStack(isOpen, close);

  useEffect(() => {
    if (isOpen && containerRef.current && menuRef.current) {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const containerBoundingBox = containerRef.current.getBoundingClientRect();
      const menuBoundingBox = menuRef.current.getBoundingClientRect();
      // Divide by 0.9 to account for scaling animation.
      const menuHeight = animate
        ? menuBoundingBox.height / 0.95
        : menuBoundingBox.height;
      const menuWidth = animate
        ? menuBoundingBox.width / 0.95
        : menuBoundingBox.width;
      const newMenuCoords: any = {};

      switch (yAlign) {
        case "over":
          newMenuCoords.bottom = "auto";

          if (containerBoundingBox.top + menuHeight + 5 > windowHeight) {
            newMenuCoords.top = windowHeight - menuHeight - 5;
          } else {
            newMenuCoords.top = containerBoundingBox.top;
          }
          break;
        default:
          newMenuCoords.bottom = "auto";

          if (containerBoundingBox.bottom + 5 + menuHeight > windowHeight) {
            newMenuCoords.top = windowHeight - menuHeight - 5;
          } else {
            newMenuCoords.top = containerBoundingBox.bottom + 5;
          }
      }

      switch (xAlign) {
        case "left":
          newMenuCoords.right = "auto";

          if (containerBoundingBox.left + menuWidth > windowWidth - 5) {
            newMenuCoords.left = windowWidth - menuWidth - 5;
          } else if (containerBoundingBox.left < 5) {
            newMenuCoords.left = 5;
          } else {
            newMenuCoords.left = containerBoundingBox.left;
          }
          break;
        default:
          newMenuCoords.left = "auto";

          if (containerBoundingBox.right - menuWidth < 5) {
            newMenuCoords.right = windowWidth - menuWidth - 5;
          } else {
            newMenuCoords.right = windowWidth - containerBoundingBox.right;
          }
      }

      setMenuMinWidth(containerBoundingBox.width);
      setMenuCoords(newMenuCoords);
    }
  }, [animate, isOpen, xAlign, yAlign]);

  return (
    <div
      ref={containerRef}
      className={`relative ${className}`}
      style={style}
      {...openCloseHandlers}
    >
      {controlled
        ? trigger
        : cloneElement(trigger, {
            onClick: (e: React.MouseEvent) => {
              // Don't trigger drag if the menu is in a draggable element.
              e.preventDefault();
              const newState = !isOpen;
              setIsOpen(newState);
              onMenuToggle && onMenuToggle(newState);
            },
            onPointerDown: (e: React.PointerEvent) => {
              // Don't trigger drag if the menu is in a draggable element.
              e.preventDefault();
            },
            onMouseDown: (e: React.MouseEvent) => {
              // Don't trigger drag if the menu is in a draggable element.
              e.preventDefault();
            },
          })}
      {ReactDOM.createPortal(
        animateFn(({ phase, animationEventHandlers }) => {
          const showMenu = animate ? phase !== "unmounted" : isOpen;
          const eventHandlers = animate ? animationEventHandlers : {};
          let animationClassName = "";

          if (animate) {
            switch (phase) {
              case "mounting":
                animationClassName = "animate-fade-up-in";
                break;
              case "unmounting":
                animationClassName = "animate-fade-down-out";
                break;
            }
          }

          return showMenu ? (
            <div
              ref={menuRef}
              className={`fixed z-20 ${menuClassName} ${animationClassName} ${
                isOpen ? "" : "pointer-events-none"
              }`}
              style={{
                ...menuStyle,
                ...menuCoords,
                minWidth: `${menuMinWidth}px`,
              }}
              onClick={(e) => {
                if (closeOnSelect && !e.isDefaultPrevented()) {
                  setIsOpen(false);
                  onMenuToggle && onMenuToggle(false);
                }
              }}
              {...eventHandlers}
            >
              {typeof menuContent === "function"
                ? menuContent({ close })
                : menuContent}
            </div>
          ) : null;
        }),
        document.getElementById("portal-container") || document.body
      )}
    </div>
  );
};

export default DropdownMenu;
