import React from "react";

import { Platform } from "../../../../types/platforms";
import { FormPublishedPostActionTwitterLike } from "../../../../types/publishedPostActions";

import { getDisplayNameForPublishedPostActionInstance } from "../../../../libs/publishedPostActions";

import ActionContainer, {
  ActionContainerErrors,
} from "../base/ActionContainer";

import PublishedPostActionInstanceIcon from "../../../../components/icons/PublishedPostActionInstance";

interface PublishedPostActionsFormTwitterLikeProps {
  platforms: Platform[];
  value: FormPublishedPostActionTwitterLike;
  onChange: (newValue: FormPublishedPostActionTwitterLike) => void;
  onDelete: () => void;
  className?: string;
  errors?: ActionContainerErrors;
}
const PublishedPostActionsFormTwitterLike: React.FC<
  PublishedPostActionsFormTwitterLikeProps
> = ({ platforms, value, onChange, onDelete, errors, className = "" }) => {
  return (
    <ActionContainer
      className={className}
      title={
        <span className="flex items-center">
          <PublishedPostActionInstanceIcon
            className="h-6 w-6"
            platformType="TWITTER"
            type="LIKE"
          />
          <span className="ml-1">
            {getDisplayNameForPublishedPostActionInstance("TWITTER", "LIKE")}
          </span>
        </span>
      }
      platforms={platforms}
      value={value}
      errors={errors}
      onChange={(newValue) => {
        onChange({
          ...value,
          ...newValue,
        });
      }}
      onDelete={onDelete}
    >
      <p>
        The post will be <strong>liked</strong> by the selected profile
      </p>
    </ActionContainer>
  );
};

export default PublishedPostActionsFormTwitterLike;
