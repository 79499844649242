import React from "react";

interface CommonSnackBarContentProps {
  message: string;
}
const CommonSnackBarContent: React.FC<CommonSnackBarContentProps> = ({
  message,
}) => {
  return (
    <div className="flex items-center">
      <span className="ml-2">{message}</span>
    </div>
  );
};

export default CommonSnackBarContent;
