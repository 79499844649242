import React from "react";
import { Star as StarIcon } from "react-feather";
import { Workspace } from "../../types/workspaces";
import { useShowModal } from "../../libs/hooks/app";
import PrimaryTextButton from "../../components/buttons/PrimaryText";
import config from "../../config";
import { mergeClassNames } from "../../libs/components";

interface UpgradeSubscriptionButtonProps {
  workspace: Workspace;
  className?: string;
  context?: "calendar" | "socials" | "smartSchedules" | "gallery";
}
const UpgradeSubscriptionButton: React.FC<UpgradeSubscriptionButtonProps> = ({
  workspace,
  className = "",
  context,
}) => {
  const showModal = useShowModal();
  let upgradeText = "Upgrade";
  let freeTrialText = `${config.DEFAULT_TRIAL_PERIOD_DAYS}-day free trial`;

  switch (context) {
    case "calendar":
      upgradeText = `Upgrade for unlimited posts`;
      freeTrialText = `Try unlimited posts for free`;
      break;
    case "socials":
      upgradeText = `Upgrade for unlimited socials`;
      freeTrialText = `Try unlimited socials for free`;
      break;
    case "smartSchedules":
      upgradeText = `Upgrade for unlimited posts`;
      freeTrialText = `Try unlimited posts for free`;
      break;
    case "gallery":
      upgradeText = `Upgrade for 100 GB of storage`;
      freeTrialText = `Try 100 GB of storage for free`;
      break;
  }

  return (
    <PrimaryTextButton
      className={mergeClassNames("py-1", className)}
      onClick={() =>
        showModal("changeSubscription", {
          workspace,
          redirectOnSuccess: false,
          defaultPriceId: config.prices.PUBLISH_MONTHLY.id,
        })
      }
    >
      <StarIcon className="mr-2 shrink-0" />
      {workspace.hasHadTrial ? (
        upgradeText
      ) : (
        <div className="text-center">
          <div className="leading-4">{freeTrialText}</div>
          <div className="text-xs font-normal opacity-75">
            No credit card required
          </div>
        </div>
      )}
    </PrimaryTextButton>
  );
};

export default UpgradeSubscriptionButton;
