import { ReactNode } from "react";

import { Platform } from "../../../../../../types/platforms";
import { PlatformMentionEntityData } from "../../../../../../types/posts";

import FacebookSearch from "./search/Facebook";
import TwitterSearch from "./search/Twitter";
import LinkedInSearch from "./search/LinkedIn";
import FacebookSelected from "./selected/Facebook";
import TwitterSelected from "./selected/Twitter";
import LinkedInSelected from "./selected/LinkedIn";

interface PostTextAreaPlatformMentionsProps {
  platform: Platform;
  value: PlatformMentionEntityData | null;
  onChange: (newValue: PlatformMentionEntityData | null) => void;
  className?: string;
  defaultSearchTerm?: string;
  isVisible?: boolean;
}
const PostTextAreaPlatformMentions = ({
  platform,
  defaultSearchTerm,
  value,
  onChange,
  className,
  isVisible,
}: PostTextAreaPlatformMentionsProps) => {
  let searchElement: ReactNode = null;

  switch (platform.type) {
    case "TWITTER":
      searchElement = (
        <TwitterSearch
          platformId={platform.id}
          defaultSearchTerm={defaultSearchTerm}
          onClick={onChange}
          isVisible={isVisible}
        />
      );
      break;
    case "FACEBOOK":
      searchElement = (
        <FacebookSearch
          platformId={platform.id}
          defaultSearchTerm={defaultSearchTerm}
          onClick={onChange}
          isVisible={isVisible}
        />
      );
      break;
    case "LINKEDIN":
      searchElement = (
        <LinkedInSearch
          platformId={platform.id}
          defaultSearchTerm={defaultSearchTerm}
          onClick={onChange}
          isVisible={isVisible}
        />
      );
      break;
  }

  let selectedElement: ReactNode = null;

  if (value) {
    switch (platform.type) {
      case "TWITTER":
        selectedElement = (
          <TwitterSelected
            platformId={platform.id}
            twitterUserId={value.id}
            onDelete={() => onChange(null)}
            isVisible={isVisible}
          />
        );
        break;
      case "FACEBOOK":
        selectedElement = (
          <FacebookSelected
            platformId={platform.id}
            entityType={value.type}
            entityId={value.id}
            onDelete={() => onChange(null)}
            isVisible={isVisible}
          />
        );
        break;
      case "LINKEDIN":
        selectedElement = (
          <LinkedInSelected
            platformId={platform.id}
            entityType={value.type}
            entityId={value.id}
            onDelete={() => onChange(null)}
            isVisible={isVisible}
          />
        );
        break;
    }
  }

  return (
    <div className={isVisible ? className : ""}>
      {!value && searchElement}
      {value && selectedElement}
    </div>
  );
};

export default PostTextAreaPlatformMentions;
