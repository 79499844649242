import React, { useMemo, useState } from "react";
import { ContentState } from "draft-js";
import { AnnotationIcon } from "@heroicons/react/outline";

import { Platform, SelectedEntities } from "../../../../types/platforms";
import { FormPublishedPostActionLinkedInReshare } from "../../../../types/publishedPostActions";

import { getDisplayNameForPublishedPostActionInstance } from "../../../../libs/publishedPostActions";
import { CHARACTER_LIMIT } from "../../post/textArea/schemas";
import PostTextArea from "../../post/TextArea";
import ActionContainer, {
  ActionContainerErrors,
} from "../base/ActionContainer";
import TertiaryButton from "../../../../components/buttons/Tertiary";
import DangerButton from "../../../../components/buttons/Danger";
import PublishedPostActionInstanceIcon from "../../../../components/icons/PublishedPostActionInstance";

interface LinkedInReshareErrors extends ActionContainerErrors {
  contentState?: any;
}

interface PublishedPostActionsFormLinkedInReshareProps {
  platforms: Platform[];
  value: FormPublishedPostActionLinkedInReshare;
  onChange: (newValue: FormPublishedPostActionLinkedInReshare) => void;
  onDelete: () => void;
  className?: string;
  errors?: LinkedInReshareErrors;
}
const PublishedPostActionsFormLinkedInReshare: React.FC<
  PublishedPostActionsFormLinkedInReshareProps
> = ({ platforms, value, onChange, onDelete, errors, className = "" }) => {
  const [showComment, setShowComment] = useState(
    value.contentState.getPlainText() !== ""
  );
  const selectedEntities: SelectedEntities[] = useMemo(() => {
    return platforms.map((platform) => ({
      platform,
      entities: Object.values(platform.entities).filter(
        (entity) => !entity.disabled
      ),
    }));
  }, [platforms]);

  return (
    <ActionContainer
      className={className}
      title={
        <span className="flex items-center">
          <PublishedPostActionInstanceIcon
            className="h-6 w-6"
            platformType="LINKEDIN"
            type="RESHARE"
          />
          <span className="ml-1">
            {getDisplayNameForPublishedPostActionInstance(
              "LINKEDIN",
              "RESHARE"
            )}
          </span>
        </span>
      }
      platforms={platforms}
      value={value}
      errors={errors}
      onChange={(newValue) => {
        onChange({
          ...value,
          ...newValue,
        });
      }}
      onDelete={onDelete}
    >
      <p>The post will be reshared by the selected profile to their feed</p>
      {!showComment && (
        <TertiaryButton
          className="mt-2 w-full flex items-center"
          onClick={() => setShowComment(true)}
        >
          <AnnotationIcon className="h-6 w-6" />
          <span className="ml-2">Add text to the reshare</span>
        </TertiaryButton>
      )}
      {showComment && (
        <div className="mt-2 px-4 flex items-center">
          <h5 className="font-bold">Text</h5>
          <DangerButton
            className="ml-auto text-inherit text-sm"
            size="xs"
            invert={true}
            onClick={() => {
              onChange({
                ...value,
                contentState: ContentState.createFromText(""),
              });
              setShowComment(false);
            }}
          >
            Remove text
          </DangerButton>
        </div>
      )}
      {showComment && (
        <PostTextArea
          selectedEntities={selectedEntities}
          value={value.contentState}
          onChange={(newContentState) =>
            onChange({ ...value, contentState: newContentState })
          }
          onBlur={() => {}}
          error={!!errors && !!errors.contentState}
          characterLimit={CHARACTER_LIMIT.LINKEDIN}
        />
      )}
    </ActionContainer>
  );
};

export default PublishedPostActionsFormLinkedInReshare;
