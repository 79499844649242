import React from "react";
import { mergeClassNames } from "../../libs/components";
import { InternalFileImage } from "../../types/files";
import InternalImage from "./Internal";

type Source = string | InternalFileImage;

interface ProfilePictureProps {
  source?: Source;
  firstName?: string;
  lastName?: string;
  className?: string;
  preview?: boolean;
  circle?: boolean;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  source,
  firstName,
  lastName,
  className = "",
  preview = true,
  circle = true,
}) => {
  if (!source) {
    const first = firstName ? firstName.slice(0, 1) : "";
    const last = lastName ? lastName.slice(0, 1) : "";
    const initials = `${first}${last}`.toUpperCase();
    return (
      <div
        className={mergeClassNames(
          `flex-shrink-0 w-11 h-11 bg-gray-200 text-gray-700 flex items-center justify-center font-bold ${
            circle ? "rounded-full" : ""
          }`,
          className
        )}
      >
        {initials}
      </div>
    );
  } else if (typeof source === "string") {
    return (
      <img
        className={mergeClassNames(
          `flex-shrink-0 w-11 h-11 object-contain overflow-hidden  ${
            circle ? "rounded-full" : ""
          }`,
          className
        )}
        src={source}
        alt={`${firstName || ""} ${lastName || ""}`}
      />
    );
  } else {
    return (
      <div
        className={mergeClassNames(
          `flex-shrink-0 w-11 h-11 bg-gray-200 flex items-center justify-center overflow-hidden  ${
            circle ? "rounded-full" : ""
          }`,
          className
        )}
      >
        <InternalImage
          file={source}
          preview={preview}
          className="max-w-none max-h-none w-full h-full object-cover"
        />
      </div>
    );
  }
};

export default ProfilePicture;
