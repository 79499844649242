import React, { ReactNode, useState } from "react";

import { useHideDrawer, useIsDrawerVisible } from "../libs/hooks/app";
import { WorkspaceAppDrawers } from "../contexts/app/Workspace";

import { DrawerProps } from "../components/Drawer";

import NavigationDrawer from "./drawers/Navigation";

function useDrawerRenderer(
  modalName: keyof WorkspaceAppDrawers
): (
  fn: (modalProps: {
    isVisible: DrawerProps["isVisible"];
    close: NonNullable<DrawerProps["close"]>;
    onStatusChange: DrawerProps["onStatusChange"];
  }) => ReactNode
) => ReactNode {
  const hideModal = useHideDrawer();
  const isModalVisible = useIsDrawerVisible();
  const [status, setStatus] =
    useState<Parameters<NonNullable<DrawerProps["onStatusChange"]>>[0]>(
      "closed"
    );
  const isVisible = isModalVisible(modalName);

  return (buildModalFn) => {
    return isVisible || status !== "closed"
      ? buildModalFn({
          isVisible,
          close: () => hideModal(modalName),
          onStatusChange: setStatus,
        })
      : null;
  };
}

function useNavigationDrawer() {
  const render = useDrawerRenderer("navigation");
  return render((drawerProps) => <NavigationDrawer {...drawerProps} />);
}

const AppDrawers: React.FC = () => {
  const navigationDrawer = useNavigationDrawer();

  return <>{navigationDrawer}</>;
};

export default AppDrawers;
