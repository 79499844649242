import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "../../types/general";

import { useApiClient } from "./app";
import { LINKEDIN_QUERY_KEYS } from "../queryKeys";
import { PlatformEntity } from "../../types/platforms";

interface EntityData {
  id: string;
  type: PlatformEntity["type"];
  name: string;
  vanityName: string;
  pictureUrl?: string;
}

interface UseSearchLinkedInOrganisationsProps {
  platformId: string;
  query: string;
}
export const useSearchLinkedInEntities = (
  { platformId, query }: UseSearchLinkedInOrganisationsProps,
  options = { enabled: true, keepPreviousData: true }
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useQuery(
    LINKEDIN_QUERY_KEYS.list({ platformId, query }),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<EntityData[]>>(
        "/proxies/linkedin",
        {
          params: {
            action: "searchEntities",
            platformId,
            query,
          },
        }
      );

      return response.data;
    },
    {
      ...options,
      onSuccess: (linkedInEntities) => {
        linkedInEntities.forEach((entity) => {
          queryClient.setQueryData(
            LINKEDIN_QUERY_KEYS.detail(`${entity.type}${entity.id}`),
            entity
          );
        });
      },
    }
  );
};

interface UseLinkedInEntityProps {
  platformId: string;
  entityType: PlatformEntity["type"];
  entityId: string;
}
export const useLinkedInEntity = ({
  platformId,
  entityType,
  entityId,
}: UseLinkedInEntityProps) => {
  const apiClient = useApiClient();
  return useQuery(
    LINKEDIN_QUERY_KEYS.detail(`${entityType}${entityId}`),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<EntityData>>(
        "/proxies/linkedin",
        {
          params:
            entityType === "BUSINESS"
              ? {
                  action: "getOrganisation",
                  platformId,
                  organisationId: entityId,
                }
              : { action: "getOtherUser", platformId, userId: entityId },
        }
      );

      return response.data;
    }
  );
};
