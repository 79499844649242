import React, { useMemo, useState } from "react";
import {
  Plus as PlusIcon,
  Filter as FilterIcon,
  ChevronDown as ChevronDownIcon,
} from "react-feather";

import { Platform } from "../types/platforms";
import { AddPlatformContent } from "../types/modals/AddPlatform";
import { useActiveWorkspace, useShowModal } from "../libs/hooks/app";
import { usePlatforms } from "../libs/hooks/platforms";
import { getPlatformTypeName } from "../libs/platform";
import {
  hasEnabledPlatformEntitiesLimit,
  getAvailableEnablePlatformEntities,
} from "../libs/subscriptions";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import PlatformCard from "../containers/cards/Platform";
import UpgradeSubscriptionButton from "../containers/buttons/UpgradeSubscription";

import H2 from "../components/headings/H2";
import H3 from "../components/headings/H3";
import PrimaryButton from "../components/buttons/Primary";
import PlatformIcon from "../components/icons/Platform";
import OutlineButton from "../components/buttons/Outline";
import PageActionButton from "../components/buttons/PageAction";
import Select from "../components/form/Select";

import { ReactComponent as NoPlatformsImage } from "../images/no-platforms.svg";

interface PlatformsByType {
  FACEBOOK?: Platform[];
  TWITTER?: Platform[];
  LINKEDIN?: Platform[];
}

type FilterOption = keyof PlatformsByType | null;

const FILTER_OPTIONS: FilterOption[] = [
  null,
  "FACEBOOK",
  "TWITTER",
  "LINKEDIN",
];

const SocialsPage: React.FC = () => {
  const [filter, setFilter] = useState<FilterOption>(null);
  const workspace = useActiveWorkspace();
  const { data: platforms, isLoading: isLoadingPlatforms } = usePlatforms(
    workspace.id
  );
  const showModal = useShowModal();
  const hasPlatformEntitiesLimit = hasEnabledPlatformEntitiesLimit(workspace);
  const availableEntitiesCount = getAvailableEnablePlatformEntities(workspace);
  const hasPlatforms = !!platforms && platforms.length > 0;
  const groupedPlatforms = useMemo(() => {
    const groups = Object.values(platforms || []).reduce<PlatformsByType>(
      (carry, platform) => {
        carry[platform.type]!.push(platform);
        return carry;
      },
      { FACEBOOK: [], TWITTER: [], LINKEDIN: [] }
    );

    (Object.keys(groups) as Array<keyof PlatformsByType>).forEach((type) => {
      groups[type]!.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }

        if (a.created < b.created) {
          return -1;
        } else if (a.created > b.created) {
          return 1;
        } else {
          return 0;
        }
      });
    });

    return groups;
  }, [platforms]);
  const filteredPlatforms = useMemo(() => {
    return (
      Object.keys(groupedPlatforms) as Array<keyof PlatformsByType>
    ).reduce<PlatformsByType>((carry, type) => {
      if (filter === null || filter === type) {
        carry[type] = groupedPlatforms[type];
      }
      return carry;
    }, {});
  }, [filter, groupedPlatforms]);

  return (
    <AppPageWithHeader>
      {!isLoadingPlatforms && !hasPlatforms && (
        <div className="pt-20 w-full h-full flex justify-center">
          <div className="relative flex flex-col items-center">
            <div style={{ width: 600 }}>
              <H2>How about an introduction?</H2>
              <p className="mt-6">
                Add the social media platforms you wish to manage with Seenly.
                All we need is a little introduction from you and then we'll
                take care of the rest.
              </p>
            </div>
            <PrimaryButton
              className="mt-10"
              onClick={() => showModal("addPlatform", {})}
            >
              <PlusIcon className="mr-2" />
              Add your first social account now
            </PrimaryButton>
            <NoPlatformsImage
              className="mt-12 flex-shrink-0"
              style={{ width: 500, height: 500 }}
            />
          </div>
        </div>
      )}
      {!isLoadingPlatforms && hasPlatforms && (
        <div className="mx-auto px-4 pt-4 pb-12">
          <div className="w-full h-full flex items-center">
            <PrimaryButton onClick={() => showModal("addPlatform", {})}>
              <PlusIcon className="mr-2" />
              Connect a social account
            </PrimaryButton>

            {!isLoadingPlatforms && hasPlatformEntitiesLimit && (
              <span
                className={`ml-4 ${
                  availableEntitiesCount !== null && availableEntitiesCount > 0
                    ? "text-gray-500"
                    : "text-red-500"
                }`}
              >{`${availableEntitiesCount} ${
                availableEntitiesCount === 1 ? "social" : "socials"
              } remaining`}</span>
            )}
            {!isLoadingPlatforms && hasPlatformEntitiesLimit && (
              <UpgradeSubscriptionButton
                className="ml-2"
                workspace={workspace}
                context="socials"
              />
            )}
            <div className="ml-auto flex items-center justify-end ">
              <Select
                ButtonElement={PageActionButton}
                dropdownClassName="ml-2"
                toggleButtonClassName="font-bold"
                labelText="Filter accounts"
                hideLabel={true}
                hideDropdownIcon={false}
                dropdownXAlign="right"
                value={filter || ""}
                options={FILTER_OPTIONS.map((filterOption) => ({
                  text:
                    filterOption === null
                      ? "All"
                      : getPlatformTypeName(filterOption),
                  value: filterOption || "",
                }))}
                onChange={(newValue) =>
                  setFilter((newValue || null) as FilterOption)
                }
                renderSelectedOption={(selectedOption) => {
                  return (
                    <div className="flex items-center w-full">
                      <FilterIcon className="flex-shrink-0 h-6 w-6" />
                      <span className="mx-2 truncate">
                        {selectedOption ? selectedOption.text : ""}
                      </span>
                      <ChevronDownIcon className="flex-shrink-0 h-5 w-5" />
                    </div>
                  );
                }}
              />
            </div>
          </div>

          {(Object.keys(filteredPlatforms) as Array<keyof PlatformsByType>).map(
            (type, index) => {
              const platforms = filteredPlatforms[type] || [];
              const platformTypeName = getPlatformTypeName(type);
              let defaultContentId: AddPlatformContent["id"] = "home";

              switch (type) {
                case "FACEBOOK":
                  defaultContentId = "consentFacebook";
                  break;
                case "TWITTER":
                  defaultContentId = "consentTwitter";
                  break;
                case "LINKEDIN":
                  defaultContentId = "consentLinkedIn";
                  break;
              }

              return (
                <div
                  key={type}
                  className={`mx-auto ${index > 0 ? "mt-12" : "mt-8"}`}
                  style={{ maxWidth: 800 }}
                >
                  <H3 className="flex items-center">
                    <PlatformIcon
                      className="h-7 w-7"
                      type={type}
                      colour={true}
                    />

                    <span className="ml-2">{`${platformTypeName} accounts`}</span>
                  </H3>
                  {platforms.map((platform) => (
                    <PlatformCard
                      key={platform.id}
                      className="mt-4"
                      platform={platform}
                    />
                  ))}
                  <OutlineButton
                    className="mt-4 w-full border-2 border-dashed border-gray-300 text-gray-300"
                    onClick={() =>
                      showModal("addPlatform", {
                        defaultContentId,
                      })
                    }
                  >
                    <div className="mr-2 p-1 rounded-full bg-gray-100">
                      <PlusIcon className="w-4 h-4" />
                    </div>
                    {`Connect a new ${platformTypeName} account`}
                  </OutlineButton>
                </div>
              );
            }
          )}
        </div>
      )}
    </AppPageWithHeader>
  );
};

export default SocialsPage;
