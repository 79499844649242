import React, { useContext, useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { DateTime } from "luxon";

import config from "../config";
import UnauthenticatedAppContext from "../contexts/app/Unauthenticated";
import { useAsyncState } from "../libs/hooks/general";

import H1 from "../components/headings/H1";
import InternalLink from "../components/links/Internal";
import PrimaryButton from "../components/buttons/Primary";
import SignupContainer from "../components/signup/Container";
import FormInput from "../components/form/Input";
import FormPasswordInput, {
  passwordSchema,
} from "../components/form/input/Password";
import TimeZoneSelect from "../components/form/select/TimeZone";
import ExternalLink from "../components/links/External";

import Splash from "../images/signup-splash.svg";
import ExpandCollapse from "../components/animations/ExpandCollapse";
import { useCheckForReferrer } from "../libs/hooks/uninitialisedApp";

type FormValues = {
  email: string;
  firstName: string;
  lastName?: string;
  timeZone: string;
  password: string;
  confirmPassword: string;
};

const formSchema = Joi.object({
  email: Joi.string().required().email({ tlds: false }),
  firstName: Joi.string().required(),
  lastName: Joi.string().allow(""),
  timeZone: Joi.string()
    .required()
    .custom((value) => {
      const dateTime = DateTime.local().setZone(value);

      if (!dateTime.isValid) {
        throw new Error(`invalid time zone ${value}`);
      }

      return value;
    }),
  password: passwordSchema.required(),
  confirmPassword: Joi.ref("password"),
});

const SignupPage: React.FC = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const defaultEmail = queryParams.has("email")
    ? (queryParams.get("email") as string)
    : "";
  const defaultFirstName = queryParams.has("name")
    ? (queryParams.get("name") as string)
    : "";
  const referrerParam = queryParams.has("via")
    ? (queryParams.get("via") as string)
    : null;
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setFocus,
    control,
  } = useForm<FormValues>({
    mode: "onTouched",
    criteriaMode: "all",
    resolver: joiResolver(formSchema),
    defaultValues: {
      email: defaultEmail.toLowerCase(),
      firstName: defaultFirstName,
      lastName: "",
      timeZone: DateTime.local().zoneName,
      password: "",
      confirmPassword: "",
    },
  });
  const {
    referralAffiliate,
    setEmail,
    setPassword,
    setReferralAffiliate,
    setReferralId,
  } = useContext(UnauthenticatedAppContext);
  const { loading, loadingText, setLoading, error, setError, clearError } =
    useAsyncState();
  const [askToDisableAdBlock, setAskToDisableAdBlock] = useState(false);
  useCheckForReferrer({
    onSuccess: (referrerData) => {
      if (referrerData) {
        setReferralId(referrerData.id);

        if (referrerData.affiliate) {
          setReferralAffiliate(referrerData.affiliate);
        }
      }
    },
    onFailedToLoad: (hasReferralParam) => {
      if (hasReferralParam) {
        setAskToDisableAdBlock(true);
      }
    },
  });

  const onSubmit = async ({
    email: rawEmail,
    password,
    firstName,
    lastName,
    timeZone,
  }: FormValues) => {
    const email = rawEmail.toLowerCase();
    setLoading(true);
    setEmail(email);
    setPassword(password);
    clearError();

    try {
      const attributes: any = {
        given_name: firstName,
        zoneinfo: timeZone,
      };

      if (lastName) {
        attributes.family_name = lastName;
      }

      const signupResult = await Auth.signUp({
        username: email,
        password,
        attributes,
      });

      if (signupResult.userConfirmed) {
        await Auth.signIn(email, password);
        navigate("/");
      } else {
        navigate(
          `/signup/confirm${referrerParam ? `?via=${referrerParam}` : ""}`
        );
      }
    } catch (e: any) {
      setLoading(false);

      if (e.code === "UsernameExistsException") {
        setError({
          title: "We thought you looked familiar!",
          message: (
            <>
              It seems that email is already in use. You can{" "}
              <InternalLink to="/forgot-password">
                reset your password here
              </InternalLink>{" "}
              just in case you've forgotten it.
            </>
          ),
        });
      } else {
        setError({
          title: "Something went wrong",
          message: (
            <>
              This never usually happens. Honest. Please try again.
              <br />
              {e.message}
            </>
          ),
        });
      }
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <SignupContainer>
      <div
        className="overscroll-auto overflow-y-auto"
        style={{ zIndex: 1, maxWidth: 600 }}
      >
        <H1 className="mt-4">Welcome to seenly</H1>
        <p className="mt-4">
          Connect your social. Schedule your content. It's that simple.
          <br />
          Already have an account?{" "}
          <InternalLink to="/login" id="sign-up-login">
            Log in
          </InternalLink>
        </p>
        <ExpandCollapse>
          {referralAffiliate ? (
            <div className="pt-6">
              <div className="p-4 rounded-lg bg-gradient-to-r from-purple-100 to-blue-100">
                <p>
                  Hi there
                  {referralAffiliate.firstName
                    ? ` friend of ${referralAffiliate.firstName}`
                    : ""}
                  ! 👋
                </p>
                {referralAffiliate.firstName ? (
                  <p className="mt-2">
                    Any friend of {referralAffiliate.firstName} is a friend of
                    ours so we'd like to give you a{" "}
                    <strong>
                      {config.AFFILIATE_DISCOUNT_PERCENT}% discount
                    </strong>{" "}
                    on our paid plans! 🎉
                  </p>
                ) : (
                  <p className="mt-2">
                    We'd like to give you a{" "}
                    <strong>
                      {config.AFFILIATE_DISCOUNT_PERCENT}% discount
                    </strong>{" "}
                    on our paid plans! 🎉
                  </p>
                )}
                <p className="mt-2">
                  There's nothing you need to do. We'll automatically apply the
                  discount if (or when 😉) you choose to purchase our paid plan.
                </p>
                <p className="mt-2">Have a great day! 👍</p>
              </div>
            </div>
          ) : askToDisableAdBlock ? (
            <div className="pt-6">
              <div className="p-4 rounded-lg bg-gradient-to-r from-red-100 to-pink-100">
                <p>Hi there! 👋</p>
                <p className="mt-2">
                  It looks like you've arrived here using a link from one of our
                  friends so we'd like to give you a{" "}
                  <strong>{config.AFFILIATE_DISCOUNT_PERCENT}% discount</strong>{" "}
                  on our paid plans but first we need to determine which friend
                  sent you!
                </p>
                <p className="mt-2">
                  Sometimes ad blockers can prevent us from loading the
                  information we need. Can you please{" "}
                  <strong>
                    temporarily disable your ad blocker and then reload the page
                  </strong>
                  .
                </p>
                <p className="mt-2">
                  If you no longer see this message after you've reloaded the
                  page then please complete the signup process before you turn
                  your ad blocker back on.
                </p>
              </div>
            </div>
          ) : null}
        </ExpandCollapse>
        {error()}
        <form
          className="mt-6 p-1 overscroll-auto overflow-y-auto"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            WebkitOverflowScrolling: "touch",
          }}
        >
          <FormInput
            {...register("email")}
            className="bg-white"
            labelText="Email"
            labelClassName="mt-4"
            type="text"
            error={!!errors.email}
          />
          <div className="flex -mx-3">
            <div className="w-1/2 px-3">
              <FormInput
                {...register("firstName")}
                className="bg-white"
                labelText="First name"
                labelClassName="mt-4"
                type="text"
                error={!!errors.firstName}
              />
            </div>
            <div className="w-1/2 px-3">
              <FormInput
                {...register("lastName")}
                className="bg-white"
                labelText="Last name"
                labelClassName="mt-4"
                type="text"
              />
            </div>
          </div>
          <Controller
            control={control}
            name="timeZone"
            render={({ field: { onChange, onBlur, value } }) => (
              <TimeZoneSelect
                dropdownClassName="bg-white"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.timeZone}
                labelClassName="mt-4"
                toggleButtonClassName="w-full"
              />
            )}
          />
          <FormPasswordInput
            {...register("password")}
            className="bg-white"
            labelClassName="mt-4"
            showRequirements={true}
            error={!!errors.password}
          />
          <FormPasswordInput
            {...register("confirmPassword")}
            className="bg-white"
            labelText="Confirm password"
            labelClassName="mt-4"
            showRequirements={false}
            error={!!errors.confirmPassword}
          />
          <PrimaryButton
            className="mt-8 w-full text-xl text-white"
            type="submit"
            disabled={loading}
            id="sign-up"
          >
            {loadingText({
              loading: "Creating your account...",
              default: "Get started!",
            })}
          </PrimaryButton>
        </form>

        <small className="block mt-10">
          By clicking the "Get started!" button, you are creating a Seenly
          account, and you agree to Seenly's{" "}
          <ExternalLink className="font-normal" href={config.urls.TERMS_OF_USE}>
            Terms of Use
          </ExternalLink>{" "}
          and{" "}
          <ExternalLink
            className="font-normal"
            href={config.urls.PRIVACY_POLICY}
          >
            Privacy Policy
          </ExternalLink>
          .
        </small>
      </div>
      <div
        className="hidden md:block w-1/3 h-1/3 lg:w-3/5 lg:h-3/5 absolute bottom-40 lg:bottom-10 right-0"
        style={{ zIndex: 0 }}
      >
        <img className="w-full h-full" src={Splash} alt="" />
      </div>
    </SignupContainer>
  );
};

export default SignupPage;
