import React from "react";
import { ArrowLeft as ArrowLeftIcon, Check as CheckIcon } from "react-feather";

import { PostQueue } from "../../../../types/postQueues";

import { useContentStack } from "../../../../libs/hooks/general";
import { UpdatePostQueueProps } from "../../../../libs/hooks/postQueues";
import { POST_QUEUE_FREQUENCY_OPTIONS } from "../../../../libs/postQueues";

import DropdownMenuButton from "../../../../components/buttons/DropdownMenu";
import IconButton from "../../../../components/buttons/Icon";

const SmartSchedulesQueueSettingsChangePostFrequency: React.FC<{
  contentId: "changePostFrequency";
  postQueue: PostQueue;
  updatePostQueue: (
    updateProps: UpdatePostQueueProps["updateProps"]
  ) => Promise<any>;
  isUpdating: boolean;
}> = ({ postQueue, updatePostQueue, isUpdating }) => {
  const { pop } = useContentStack();
  const currentFrequency = postQueue.postFrequency || "NORMAL";

  return (
    <div className="p-2">
      <div className="flex items-center">
        <IconButton
          className="flex-shrink-0"
          onClick={(e) => {
            e.preventDefault();
            pop();
          }}
        >
          <ArrowLeftIcon />
        </IconButton>
        <h2 className="ml-2 shrink-0 font-bold text-2xl">Posting frequency</h2>
      </div>
      <p className="shrink-0 mt-4 px-2 text-sm text-gray-500">
        How often would you like Seenly to publish content
      </p>
      <div className="mt-4 w-full flex flex-col">
        {Object.values(POST_QUEUE_FREQUENCY_OPTIONS).map(
          ({ value, title, desc }) => {
            const isSelected = value === currentFrequency;

            return (
              <DropdownMenuButton
                key={value}
                className={isSelected ? "text-purple-500" : ""}
                onClick={() => updatePostQueue({ postFrequency: value })}
              >
                {isSelected && <CheckIcon className="w-6 h-6" />}
                <div
                  className={`ml-2 grow flex items-center justify-between ${
                    !isSelected ? "pl-6" : ""
                  }`}
                >
                  <div className="leading-4">{title}</div>
                  <div
                    className={`text-sm ${
                      isSelected ? "text-purple-500" : "text-gray-400"
                    }`}
                  >
                    {desc}
                  </div>
                </div>
              </DropdownMenuButton>
            );
          }
        )}
      </div>
    </div>
  );
};

export default SmartSchedulesQueueSettingsChangePostFrequency;
