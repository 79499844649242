import { DateTime } from "luxon";
import React, { ComponentProps } from "react";
import { Clock as ClockIcon } from "react-feather";

import { Post } from "../../types/posts";
import { Platform } from "../../types/platforms";
import { getPlatformTypeName } from "../../libs/platform";
import { useActiveWorkspaceTimeZone } from "../../libs/hooks/app";
import { useDeletePost } from "../../libs/hooks/posts";
import { useSnackBarFactory } from "../../libs/hooks/general";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import DangerButton from "../../components/buttons/Danger";
import PlatformEntityProfilePicture from "../../components/images/profile/PlatformEntity";
import ExternalLink from "../../components/links/External";
import PlatformIcon from "../../components/icons/Platform";
import DeletedPostSnackBarContent from "../../components/snackBarContent/DeletedPost";
import PostPreview from "../../components/posts/Preview";

interface DeletePlatformModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  post: Post;
  platform: Platform;
  close: () => void;
}

const DeletePostModal: React.FC<DeletePlatformModalProps> = ({
  post,
  platform,
  isVisible,
  close,
  ...modalProps
}) => {
  const createSnackBar = useSnackBarFactory();
  const timeZone = useActiveWorkspaceTimeZone();
  const {
    mutateAsync: deletePost,
    isLoading,
    loadingText,
    errorAlert,
  } = useDeletePost();
  const platformTypeName = getPlatformTypeName(platform.type);
  const entity = platform.entities[post.platformEntityId];
  const externalPostUrl = post.externalUrl;

  const handleDelete = async () => {
    try {
      await deletePost(post);
      createSnackBar({
        content: <DeletedPostSnackBarContent platformType={platform.type} />,
      });
      close();
    } catch (e) {}
  };

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="p-8">
        <H3>Delete post</H3>
        <p className="mt-4" style={{ width: 0, minWidth: "100%" }}>
          Are you sure you'd like to delete this post?
        </p>
        {post.status === "POSTED" && (
          <p className="mt-2" style={{ width: 0, minWidth: "100%" }}>
            The post has already been shared on {platformTypeName}. Deleting it
            now will <strong>only remove it from Seenly.</strong>
          </p>
        )}
        {externalPostUrl && (
          <p className="mt-2" style={{ width: 0, minWidth: "100%" }}>
            You can manually remove your post from {platformTypeName}{" "}
            <ExternalLink href={externalPostUrl}>here</ExternalLink>.
          </p>
        )}

        {entity && (
          <div className="mt-8 flex items-center">
            <PlatformIcon
              className="h-6 w-6"
              type={platform.type}
              colour={true}
            />
            <div className="ml-4 flex items-center">
              <PlatformEntityProfilePicture className="ml-2" entity={entity} />
              <span className="ml-2 font-bold">{entity.name}</span>
            </div>
          </div>
        )}

        {post.scheduled && (
          <div className="mt-8 flex">
            <ClockIcon className="w-6 h-6" />
            <div className="ml-4">
              {DateTime.fromISO(post.scheduled)
                .setZone(timeZone)
                .toFormat("EEE, ff")}
            </div>
          </div>
        )}

        <div className="mt-8 flex">
          <PostPreview post={post} platformEntity={entity} />
        </div>

        {errorAlert({ className: "mt-8" })}
        <DangerButton
          className="mt-8 w-full"
          onClick={handleDelete}
          disabled={isLoading}
        >
          {loadingText({
            loading: `Deleting post...`,
            default: `Yes, delete post from Seenly`,
          })}
        </DangerButton>
      </div>
    </Modal>
  );
};

export default DeletePostModal;
