import React from "react";
import { User } from "../../../types/users";
import ProfilePicture from "../Profile";

interface UserProfilePictureProps {
  user: User;
  className?: string;
  preview?: boolean;
}

const UserProfilePicture: React.FC<UserProfilePictureProps> = ({
  user,
  className = "",
  preview = true,
}) => {
  return (
    <ProfilePicture
      className={className}
      source={user.profileImage}
      preview={preview}
      firstName={user.firstName}
      lastName={user.lastName}
    />
  );
};

export default UserProfilePicture;
