import React, { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { mergeClassNames } from "../../../libs/components";
import { usePrevious } from "../../../libs/hooks/general";
import { isEqualOrAncestorElement } from "../../../libs/utils";

import ClampedNumberInput from "./ClampedNumber";
import MonthInput from "./Month";

interface DateInputProps {
  value: { year: number; month: number; day: number };
  onChange: (newDate: { year: number; month: number; day: number }) => void;
  timeZone: string;
  allowPast?: boolean;
  className?: string;
}

const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  timeZone,
  allowPast = true,
  className = "",
}) => {
  const now = DateTime.local().setZone(timeZone);
  const valueDateTime = DateTime.fromObject(value, { zone: timeZone });
  const valueISODate = valueDateTime.toISODate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevValue = usePrevious(valueISODate);
  const [day, setDay] = useState<number>(value.day);
  const [month, setMonth] = useState(value.month);
  const [year, setYear] = useState<number>(value.year);
  const newDate = DateTime.fromObject(
    {
      year,
      month,
      day: 1,
    },
    { zone: timeZone }
  );
  const newValueISODate = DateTime.fromObject(
    {
      year,
      month,
      day,
    },
    { zone: timeZone }
  ).toISODate();
  const minDay = allowPast
    ? 1
    : month === now.month && year === now.year
    ? now.day
    : 1;
  const maxDay = newDate.daysInMonth;
  const minYear = allowPast ? 1 : month < now.month ? now.year + 1 : now.year;
  const maxYear = 2100;

  useEffect(() => {
    if (valueISODate !== prevValue && valueISODate !== newValueISODate) {
      setDay(value.day);
      setMonth(value.month);
      setYear(value.year);
    }
  }, [
    newValueISODate,
    prevValue,
    value.day,
    value.month,
    value.year,
    valueISODate,
  ]);

  return (
    <div
      ref={containerRef}
      className={mergeClassNames("flex items-center", className)}
      onBlur={() => {
        window.requestAnimationFrame(() => {
          if (
            !containerRef.current ||
            !document.activeElement ||
            !isEqualOrAncestorElement(
              containerRef.current,
              document.activeElement
            )
          ) {
            if (
              value.day !== day ||
              value.month !== month ||
              value.year !== year
            ) {
              onChange({ day, month, year });
            }
          }
        });
      }}
    >
      <ClampedNumberInput
        className="w-6 text-lg text-right focus:text-purple-500 focus:underline"
        value={day}
        onChange={(newDay) => {
          setDay(newDay);
          onChange({ day: newDay, month, year });
        }}
        min={minDay}
        max={maxDay}
      />
      <MonthInput
        className="w-12 text-lg text-center"
        value={month}
        onChange={(newMonth) => {
          setMonth(newMonth);

          let newYear = year;

          if (!allowPast && year === now.year && newMonth < now.month) {
            newYear = now.year + 1;
            setYear(now.year + 1);
          }

          onChange({ day, month: newMonth, year: newYear });
        }}
      />
      <ClampedNumberInput
        className="w-14 text-lg text-left focus:text-purple-500 focus:underline"
        value={year}
        onChange={(newYear) => {
          setYear(newYear);
          onChange({ day, month, year: newYear });
        }}
        min={minYear}
        max={maxYear}
      />
    </div>
  );
};

export default DateInput;
