import React, { ComponentProps } from "react";

import {
  PostQueue,
  PostQueueState,
  PostQueueStateMonitoring,
  PostQueueStateScheduled,
  PostQueueStateWaiting,
} from "../../types/postQueues";
import { Platform, PlatformEntity } from "../../types/platforms";

import { getPlatformTypeName } from "../../libs/platform";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import PlatformEntityProfilePicture from "../../components/images/profile/PlatformEntity";
import PlatformIcon from "../../components/icons/Platform";

import Monitoring from "./explainSmartSchedulesState/Monitoring";
import Scheduled from "./explainSmartSchedulesState/Scheduled";
import Waiting from "./explainSmartSchedulesState/Waiting";

interface ExplainSmartSchedulesStateProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  platform: Platform;
  platformEntity: PlatformEntity;
  postQueue: PostQueue & {
    state: PostQueueState;
  };
}

const ExplainSmartSchedulesStateModal: React.FC<
  ExplainSmartSchedulesStateProps
> = ({ platform, platformEntity, postQueue, close, ...modalProps }) => {
  return (
    <Modal close={close} {...modalProps}>
      <div className="px-8 pb-8 pt-4 relative" style={{ minWidth: 500 }}>
        <div className="flex items-start">
          <PlatformEntityProfilePicture
            className="flex-shrink-0 h-20 w-20"
            entity={platformEntity}
          />

          <div className="ml-2 h-20 flex-grow flex flex-col">
            <H3 className="truncate">{platformEntity.name}</H3>
            <div className="flex items-center">
              <PlatformIcon
                className="h-4 w-4"
                type={platform.type}
                colour={true}
              />
              <span className="ml-1 uppercase text-xs font-bold text-gray-400">
                {getPlatformTypeName(platform.type)}
              </span>
            </div>
          </div>
        </div>
        {postQueue.state.type === "MONITORING" && (
          <Monitoring
            platformEntity={platformEntity}
            postQueue={
              postQueue as PostQueue & {
                state: PostQueueStateMonitoring;
              }
            }
            close={close}
          />
        )}
        {postQueue.state.type === "SCHEDULED" && (
          <Scheduled
            platformEntity={platformEntity}
            postQueue={
              postQueue as PostQueue & {
                state: PostQueueStateScheduled;
              }
            }
            close={close}
          />
        )}
        {postQueue.state.type === "WAITING" && (
          <Waiting
            platformEntity={platformEntity}
            postQueue={
              postQueue as PostQueue & {
                state: PostQueueStateWaiting;
              }
            }
            close={close}
          />
        )}
      </div>
    </Modal>
  );
};

export default ExplainSmartSchedulesStateModal;
