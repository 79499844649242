import React from "react";
import { FileUpload, FileUploadByType, UploadProps } from "../types/uploads";

export interface UploadContextState {
  upload: <T extends UploadProps>(
    props: T
  ) => Promise<FileUploadByType<T["internalFile"]["type"]>>;
  get: (fileId: string) => FileUpload | null;
  getAll: () => { [fileId: string]: FileUpload };
  remove: (fileId: string) => void;
}

const UploadContext = React.createContext<UploadContextState>({
  upload: () => {
    return new Promise(() => {});
  },
  get: () => null,
  getAll: () => ({}),
  remove: () => {},
});
export default UploadContext;
