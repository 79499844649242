import React from "react";
import { Menu } from "react-feather";

import { mergeClassNames } from "../../libs/components";
import { useDrawerData, useSetDrawer } from "../../libs/hooks/app";
import { useShowPlatformRequiresAuthAlert } from "../../libs/hooks/alerts";

import IconButton from "../../components/buttons/Icon";
import AlertPing from "../../components/animations/AlertCircle";

interface ToggleNavigationDrawerButtonProps {
  className?: string;
}
const ToggleNavigationDrawerButton: React.FC<
  ToggleNavigationDrawerButtonProps
> = ({ className = "" }) => {
  const setDrawer = useSetDrawer();
  const workspaceDrawerData = useDrawerData("navigation");
  const showAlert = useShowPlatformRequiresAuthAlert();

  return (
    <IconButton
      className={mergeClassNames("relative", className)}
      title="Toggle navigation drawer"
      onClick={() => {
        setDrawer("navigation", {
          isVisible: workspaceDrawerData
            ? !workspaceDrawerData.isVisible
            : true,
        });
      }}
    >
      <Menu />
      {showAlert && (
        <div className="absolute top-0 right-0">
          <AlertPing className="rounded-full bg-red-400">
            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
          </AlertPing>
        </div>
      )}
    </IconButton>
  );
};

export default ToggleNavigationDrawerButton;
