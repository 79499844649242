import React from "react";

import H2 from "../../../components/headings/H2";

interface CreatePostModalNoEnabledPlatformEntitiesProps {
  contentId: "noplatformentities";
  close: () => void;
}

const CreatePostModalNoEnabledPlatformEntities: React.FC<
  CreatePostModalNoEnabledPlatformEntitiesProps
> = () => {
  return (
    <div className="p-8" style={{ width: 500 }}>
      <H2 className="text-2xl text-black w-full">No enabled socials?</H2>
      <p className="mt-2">
        Use the socials menu to enable at least one social to create content.
      </p>
    </div>
  );
};

export default CreatePostModalNoEnabledPlatformEntities;
