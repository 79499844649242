import React, { useEffect, useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";
import Twitter from "twitter-text";
import {
  Editor,
  ContentState,
  EditorState,
  CompositeDecorator,
  DraftDecorator,
} from "draft-js";
import {
  CornerUpRight as CornerUpRightIcon,
  MessageSquare as MessageSquareIcon,
} from "react-feather";
import { DotsHorizontalIcon, GlobeIcon } from "@heroicons/react/solid";
import { ThumbUpIcon } from "@heroicons/react/outline";

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import "draft-js/dist/Draft.css";

import { PlatformEntity } from "../../../types/platforms";
import { MentionEntityData, PostAttachment } from "../../../types/posts";
import { mergeClassNames } from "../../../libs/components";
import {
  buildContentStateForPlatformType,
  POST_PREVIEW_WIDTH,
} from "../../../libs/post";
import {
  DraftDecoratorComponentProps,
  ENTITY_TYPES,
} from "../../../libs/postTextArea";

import PlatformEntityProfilePicture from "../../images/profile/PlatformEntity";

import FacebookImages from "./facebook/Images";
import FacebookVideo from "./facebook/Video";
import FacebookLink from "./facebook/Link";

const COLOURS = {
  BLACK: "rgb(28, 30, 33)",
  TEXT_BLACK: "rgb(5, 5, 5)",
  GRAY: "rgb(101, 103, 107)",
  BLUE: "rgb(24, 118, 242)",
};

const WIDTH = POST_PREVIEW_WIDTH.FACEBOOK;
const MAX_URL_LENGTH = 50;
const MIN_CHAR_COUNT = 85;
const MAX_CHAR_COUNT = 480;
const MAX_PARAGRAPH_COUNT = 3;
const EDITOR_LINE_HEIGHT = 20;

const HASHTAG_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback) => {
    const hashtags = Twitter.extractHashtagsWithIndices(contentBlock.getText());
    hashtags.forEach(({ indices }) => {
      callback(...indices);
    });
  },
  component: ({ children }: DraftDecoratorComponentProps) => (
    <span style={{ color: COLOURS.BLUE }}>{children}</span>
  ),
};

const MENTION_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      const entity = entityKey ? contentState.getEntity(entityKey) : null;

      if (!!entity && entity.getType() === ENTITY_TYPES.MENTION) {
        const entityData = entity
          ? (entity.getData() as MentionEntityData)
          : null;

        return !!entityData && !!entityData.mentions["FACEBOOK"];
      }

      return false;
    }, callback);
  },
  component: ({ contentState, entityKey }: DraftDecoratorComponentProps) => {
    const entity = contentState.getEntity(entityKey!);
    const entityData = entity.getData() as MentionEntityData;

    return (
      <span style={{ color: COLOURS.BLUE }}>
        {entityData.mentions["FACEBOOK"]!.text}
      </span>
    );
  },
};

const LINK_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback) => {
    const urls = Twitter.extractUrlsWithIndices(contentBlock.getText(), {
      extractUrlsWithoutProtocol: false,
    });
    urls.forEach(({ indices }) => {
      callback(...indices);
    });
  },
  component: ({ children, decoratedText }: DraftDecoratorComponentProps) => {
    const rawUrl = decoratedText;

    if (rawUrl.length < MAX_URL_LENGTH) {
      return <span style={{ color: COLOURS.BLUE }}>{children}</span>;
    }

    const urlParts = rawUrl.match(
      // Typescript types are wrong here. The twitter lib does export regexen.
      (Twitter as any).regexen.validateUrlUnencoded
    );

    if (!urlParts) {
      return <span style={{ color: COLOURS.BLUE }}>{children}</span>;
    }

    const protocol = urlParts[1];
    const domain = urlParts[2];
    const path = urlParts[3];
    const query = urlParts[4];
    const fragment = urlParts[5];

    let shortenedUrl = `${protocol}://${domain}`;
    let remainingChars = Math.max(0, MAX_URL_LENGTH - shortenedUrl.length);

    if (remainingChars > 0 && path) {
      if (path.length < remainingChars) {
        shortenedUrl += path;
        remainingChars -= path.length;
      } else {
        const pathParts = path.split("/");
        const lastPath = pathParts[pathParts.length - 1];
        const lastPathParts = lastPath.split("-");

        let shortenedPath = pathParts.length > 2 ? "/.../" : "/";
        let first = true;

        while (
          lastPathParts.length > 0 &&
          shortenedPath.length < remainingChars
        ) {
          const word = lastPathParts.shift();
          shortenedPath += first ? word : `-${word}`;
          first = false;
        }

        if (lastPathParts.length > 0) {
          shortenedPath += "...";
        }

        shortenedUrl += shortenedPath;
      }

      remainingChars = Math.max(0, MAX_URL_LENGTH - shortenedUrl.length);
    }

    if (remainingChars > 0 && query) {
      if (query.length > remainingChars) {
        shortenedUrl += `?${query.substring(0, remainingChars)}...`;
      } else {
        shortenedUrl += `?${query}`;
      }

      remainingChars = Math.max(0, MAX_URL_LENGTH - shortenedUrl.length);
    }

    if (remainingChars > 0 && fragment) {
      if (fragment.length > remainingChars) {
        shortenedUrl += `#${fragment.substring(0, remainingChars)}...`;
      } else {
        shortenedUrl += `#${fragment}`;
      }
    }

    return <span style={{ color: COLOURS.BLUE }}>{shortenedUrl}</span>;
  },
};

const DECORATOR = new CompositeDecorator([
  HASHTAG_DECORATOR,
  MENTION_DECORATOR,
  LINK_DECORATOR,
]);

function getPreFoldText(text: string) {
  if (text.length < MIN_CHAR_COUNT) {
    return "";
  }

  const largestSubstring = text.substring(0, MAX_CHAR_COUNT);
  const paragraphs = largestSubstring.split("\n");
  let preFoldText = "";
  let paragraphCount = 0;
  let remainingCharacters = MAX_CHAR_COUNT;

  while (
    paragraphCount < MAX_PARAGRAPH_COUNT &&
    remainingCharacters > 0 &&
    paragraphs.length > 0
  ) {
    const paragraph = paragraphs.shift();

    if (!paragraph || paragraph.length < 1) {
      preFoldText += "\n";
    } else {
      paragraphCount++;
      preFoldText += `${paragraph.substring(0, remainingCharacters)}\n`;
    }

    remainingCharacters = MAX_CHAR_COUNT - preFoldText.length;
  }

  if (paragraphCount === MAX_PARAGRAPH_COUNT) {
    return preFoldText.trim();
  } else {
    return "";
  }
}

interface FacebookPostPreviewProps {
  platformEntity: PlatformEntity;
  scheduled: string;
  contentState: ContentState;
  attachment?: PostAttachment;
  className?: string;
}
const FacebookPostPreview: React.FC<FacebookPostPreviewProps> = ({
  platformEntity,
  scheduled,
  contentState,
  attachment,
  className = "",
}) => {
  const scheduledDateTime = DateTime.fromISO(scheduled);
  const foldHeightContainerRef = useRef<HTMLDivElement | null>(null);
  const [canExpand, setCanExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [maxEditorHeight, setMaxEditorHeight] = useState(0);
  const facebookContentState = useMemo(() => {
    return buildContentStateForPlatformType("FACEBOOK", contentState);
  }, [contentState]);
  const plainText = facebookContentState.getPlainText();
  const editorState = EditorState.createWithContent(
    facebookContentState,
    DECORATOR
  );
  const imageAttachments =
    attachment && attachment.type === "IMAGE" ? attachment.images : [];
  const hasImageAttachments = imageAttachments.length > 0;

  useEffect(() => {
    if (foldHeightContainerRef.current) {
      const newPreFoldText = getPreFoldText(plainText);

      if (newPreFoldText) {
        setCanExpand(true);
      } else {
        setCanExpand(false);
        setExpanded(false);
      }

      foldHeightContainerRef.current.innerText = newPreFoldText;
      setMaxEditorHeight(foldHeightContainerRef.current.clientHeight);
    }
  }, [plainText]);

  return (
    <div
      className={mergeClassNames(
        "group bg-white rounded-lg leading-5 font-facebook",
        className
      )}
      style={{
        width: WIDTH,
        borderRadius: "0.8rem",
        boxShadow: `0 1px 2px rgba(0, 0, 0, 0.2)`,
        color: COLOURS.BLACK,
        fontSize: "15px",
      }}
    >
      <div className="mb-3 pt-3 px-4 h-12 relative flex items-start">
        <PlatformEntityProfilePicture
          className="mr-2 w-10 h-10 shrink-0"
          entity={platformEntity}
        />

        <div className="grow">
          <div className="flex flex-col justify-between h-10">
            <div className="font-semibold break-words text-">
              {platformEntity.name}
            </div>
            <div className="text-xs" style={{ color: COLOURS.GRAY }}>
              <span>{`${scheduledDateTime.toFormat("d LLLL 'at' T")} · `}</span>
              <span>
                <GlobeIcon className="shrink-0 inline-block h-4 w-4" />
              </span>
            </div>
          </div>
        </div>

        <div className="p-2 shrink-0">
          <DotsHorizontalIcon className="w-5 h-5" />
        </div>
      </div>

      <div
        className="mb-3 px-4 overflow-hidden relative"
        style={{
          color: COLOURS.TEXT_BLACK,
          lineHeight: `${EDITOR_LINE_HEIGHT}px`,
          maxHeight: expanded ? undefined : maxEditorHeight || undefined,
        }}
      >
        <div
          ref={foldHeightContainerRef}
          className="px-4 absolute top-0 left-0 w-full invisible opacity-0 pointer-events-none whitespace-pre-wrap"
          style={{ zIndex: -999 }}
          aria-hidden={true}
          tabIndex={-1}
        ></div>

        <Editor editorState={editorState} readOnly={true} onChange={() => {}} />

        {canExpand && (
          <div
            className="absolute left-2 right-2 bottom-0 rounded-lg pointer-events-none opacity-0 default-transition group-hover:opacity-100"
            style={{ top: maxEditorHeight, zIndex: 1 }}
          >
            <div className="px-1 absolute top-0 right-0 text-gray-500 rounded bg-gray-200">
              Below the fold
            </div>
            <div className="h-full w-full rounded-lg pointer-events-none bg-gray-200 bg-opacity-40"></div>
          </div>
        )}
        {canExpand && (
          <div
            className={`mr-2 px-2 absolute bottom-0 right-0 text-sm bg-white default-transition ${
              expanded ? "rounded group-hover:bg-gray-200" : ""
            } `}
            style={{ color: COLOURS.GRAY, zIndex: 1 }}
          >
            <button
              className="hover:underline hover:text-blue-600 focus:underline focus:text-blue-600"
              type="button"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "…see less" : "…see more"}
            </button>
          </div>
        )}
      </div>

      {hasImageAttachments && (
        <FacebookImages imageAttachments={imageAttachments} />
      )}

      {attachment && attachment.type === "LINK" && (
        <FacebookLink linkAttachment={attachment.link} />
      )}
      {attachment && attachment.type === "VIDEO" && (
        <FacebookVideo videoAttachment={attachment.video} maxHeight={WIDTH} />
      )}

      <div
        className="px-4 h-10 flex font-semibold"
        style={{ color: COLOURS.GRAY }}
      >
        <div className="h-full w-full flex items-center justify-center">
          <ThumbUpIcon className="mr-2" style={{ width: 20, height: 20 }} />
          <div>Like</div>
        </div>
        <div className="h-full w-full flex items-center justify-center">
          <MessageSquareIcon
            className="mr-2"
            style={{ width: 18, height: 18 }}
          />
          <div>Comment</div>
        </div>
        <div className="h-full w-full flex items-center justify-center">
          <CornerUpRightIcon
            className="mr-2"
            style={{ width: 18, height: 18 }}
          />
          <div>Share</div>
        </div>
      </div>
    </div>
  );
};

export default FacebookPostPreview;
