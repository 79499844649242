import React from "react";
import { Invoice } from "../../types/invoices";

import SuccessBadge from "./Success";
import DangerBadge from "./Danger";
import NeutralBadge from "./Neutral";
import HighlightBadge from "./Highlight";

interface InvoiceStatusBadgeProps {
  status: Invoice["status"];
  className?: string;
}
const InvoiceStatusBadge: React.FC<InvoiceStatusBadgeProps> = ({
  status,
  className = "",
}) => {
  switch (status) {
    case "deleted":
      return <NeutralBadge className={className}>Deleted</NeutralBadge>;
    case "draft":
      return <NeutralBadge className={className}>Scheduled</NeutralBadge>;
    case "open":
      return <HighlightBadge className={className}>Open</HighlightBadge>;
    case "uncollectible":
      return <DangerBadge className={className}>Uncollectible</DangerBadge>;
    case "void":
      return <HighlightBadge className={className}>Void</HighlightBadge>;

    default:
      return <SuccessBadge className={className}>Paid</SuccessBadge>;
  }
};

export default InvoiceStatusBadge;
