import React from "react";

import PostLoading from "./topPosts/PostLoading";

interface AnalyticsBodyTopPostsLoadingProps {
  className?: string;
}
const AnalyticsBodyTopPostsLoading: React.FC<
  AnalyticsBodyTopPostsLoadingProps
> = ({ className = "" }) => {
  return (
    <div className={className}>
      <div className="flex justify-end">
        <div className="h-9 w-40 rounded-lg animate-pulse bg-slate-200"></div>
      </div>

      <div className="mt-4">
        <div className="flex items-center justify-between">
          <div className="h-6 w-40 rounded-lg animate-pulse bg-slate-200"></div>
        </div>
        <PostLoading className="mt-2" />
      </div>

      <div className="mt-4">
        <div className="flex items-center justify-between">
          <div className="h-6 w-40 rounded-lg animate-pulse bg-slate-200"></div>
        </div>
        <PostLoading className="mt-2" />
      </div>

      <div className="mt-4">
        <div className="flex items-center justify-between">
          <div className="h-6 w-40 rounded-lg animate-pulse bg-slate-200"></div>
        </div>
        <PostLoading className="mt-2" />
      </div>
    </div>
  );
};

export default AnalyticsBodyTopPostsLoading;
