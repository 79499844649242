import React, { useRef, useState, useEffect } from "react";
import {
  Trash as TrashIcon,
  Download as DownloadIcon,
  CloudOff as CloudOffIcon,
} from "react-feather";
import {
  ArrowsExpandIcon,
  PhotographIcon,
  FilmIcon,
} from "@heroicons/react/outline";

import { InternalFile } from "../../types/files";
import { FileUpload } from "../../types/uploads";

import { mergeClassNames } from "../../libs/components";
import { clampImageHeight, clampImageSize } from "../../libs/utils";
import {
  buildDownloadFileUrl,
  formatFileSizeForDisplay,
} from "../../libs/files";
import { useDeleteFile, useUpdateFile } from "../../libs/hooks/files";
import {
  useActiveWorkspaceFileAccessToken,
  useShowModal,
} from "../../libs/hooks/app";
import { useSnackBarFactory } from "../../libs/hooks/general";
import { isImageFileUpload, useUpload } from "../../libs/hooks/uploads";

import IconExternalLink from "../../components/links/IconExternal";
import IconDangerButton from "../../components/buttons/IconDanger";
import DangerButton from "../../components/buttons/Danger";
import TertiaryButton from "../../components/buttons/Tertiary";
import LoaderIcon from "../../components/icons/Loader";
import InternalImage from "../../components/images/Internal";
import InternalVideoPreviewImage from "../../components/images/InternalVideoPreview";
import Input from "../../components/form/Input";
import DeletedFileSnackBarContent from "../../components/snackBarContent/DeletedFile";

const MIN_WIDTH = 250;

function getClampedWidth(
  file: InternalFile,
  maxHeight: number,
  maxWidth?: number,
  upload?: FileUpload | null
) {
  let height = 0;
  let width = 0;

  if (upload) {
    height = isImageFileUpload(upload)
      ? upload.imageHeight
      : upload.videoHeight;
    width = isImageFileUpload(upload) ? upload.imageWidth : upload.videoWidth;
  } else if (file.preview) {
    height = file.preview.height;
    width = file.preview.width;
  } else if (file.height && file.width) {
    height = file.height;
    width = file.width;
  }

  if (maxWidth) {
    const [, clampedWidth] = clampImageSize(height, width, maxHeight, maxWidth);

    return clampedWidth;
  } else {
    const [, clampedWidth] = clampImageHeight(height, width, maxHeight);

    return clampedWidth;
  }
}

interface InternalFileCardProps {
  file: InternalFile;
  imageMaxWidth?: number;
  imageMaxHeight?: number;
  className?: string;
}

const InternalFileCard: React.FC<InternalFileCardProps> = ({
  file,
  imageMaxWidth,
  imageMaxHeight,
  className = "",
}) => {
  const upload = useUpload(file.id);
  const fileAccessToken = useActiveWorkspaceFileAccessToken();
  const hasBeenUploadingRef = useRef(!!upload);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [clampedWidth, setClampedWidth] = useState<number | undefined>(
    imageMaxHeight
      ? getClampedWidth(file, imageMaxHeight, imageMaxWidth, upload)
      : undefined
  );
  const cancelDeleteButtonRef = useRef<HTMLButtonElement | null>(null);
  const { mutateAsync: deleteFile, isLoading: isDeletingFile } =
    useDeleteFile();
  const { mutate: updateFile, isLoading: isUpdatingFile } = useUpdateFile();
  const createSnackBar = useSnackBarFactory();
  const showModal = useShowModal();
  const isLoading = isDeletingFile || isUpdatingFile;
  const isComplete = file.status === "COMPLETE";
  const isUploading =
    !!upload &&
    (upload.status === "PENDING" || upload.status === "IN_PROGRESS");
  const imageWidth =
    file.type === "VIDEO"
      ? Math.max(MIN_WIDTH, clampedWidth || 0)
      : clampedWidth;
  const isFailedUpload = !isComplete && !hasBeenUploadingRef.current;

  const handleFileNameChange = (newFileName: string) => {
    updateFile({ file, updateProps: { name: newFileName } });
  };

  useEffect(() => {
    if (showConfirmDelete && cancelDeleteButtonRef.current) {
      cancelDeleteButtonRef.current.focus();
    }
  }, [showConfirmDelete]);

  useEffect(() => {
    const newClampedWidth = imageMaxHeight
      ? getClampedWidth(file, imageMaxHeight, imageMaxWidth, upload)
      : undefined;

    if (newClampedWidth) {
      setClampedWidth(newClampedWidth);
    }
  }, [file, imageMaxHeight, imageMaxWidth, upload]);

  useEffect(() => {
    if (isUploading && !hasBeenUploadingRef.current) {
      hasBeenUploadingRef.current = true;
    }
  });

  return (
    <div
      className={mergeClassNames(
        `shadow rounded-lg flex flex-col items-center overflow-hidden bg-white border border-gray-100 ${
          isDeletingFile ? "opacity-25" : ""
        }`,
        className
      )}
      style={{ minWidth: MIN_WIDTH }}
    >
      <div
        className="flex items-start justify-center overflow-hidden"
        style={{ height: imageMaxHeight, width: imageWidth || "auto" }}
      >
        {isFailedUpload && (
          <div className="px-4 w-full h-full flex items-center justify-center">
            <div className="flex items-center text-red-500">
              <CloudOffIcon className="shrink-0 w-6 h-6" />
              <div className="ml-2">Upload failed</div>
            </div>
          </div>
        )}
        {!isFailedUpload && (
          <button
            type="button"
            className="relative group w-full h-full"
            disabled={isLoading || !isComplete}
            onClick={() => {
              showModal("viewInternalFile", { file });
            }}
          >
            {file.type === "IMAGE" && (
              <InternalImage file={file} preview={true} />
            )}
            {file.type === "VIDEO" && <InternalVideoPreviewImage file={file} />}
            {isComplete && (
              <div className="p-2 absolute top-0 right-0 rounded-full bg-white opacity-0 default-transition group-hover:opacity-100 group-focus:opacity-100">
                <ArrowsExpandIcon className="w-5 h-5" />
              </div>
            )}
          </button>
        )}
      </div>
      <div className="px-1 w-full relative">
        <div className="relative">
          <Input
            className={`truncate px-2 py-1 font-bold border-2 border-transparent ${
              isUpdatingFile
                ? "bg-gray-100 pr-10"
                : isFailedUpload
                ? ""
                : "hover:bg-gray-100"
            }`}
            value={file.name}
            hideLabel={true}
            labelText="File name"
            onFinalChange={handleFileNameChange}
            disabled={isLoading || !isComplete || isFailedUpload}
          />
          {isUpdatingFile && (
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
              <LoaderIcon />
            </div>
          )}
        </div>
        <div className="py-1 pl-2 flex items-center">
          {file.type === "IMAGE" && (
            <PhotographIcon className="shrink-0 h-6 w-6 text-gray-500" />
          )}
          {file.type === "VIDEO" && (
            <FilmIcon className="shrink-0 h-6 w-6 text-gray-500" />
          )}
          <span className="ml-2 text-sm text-gray-500">
            {file.size !== undefined && formatFileSizeForDisplay(file.size)}
          </span>
          <div className="ml-auto shrink-0 flex items-center">
            {isComplete && !isFailedUpload && (
              <IconExternalLink
                href={buildDownloadFileUrl(
                  file.path,
                  fileAccessToken || "",
                  file.name
                )}
                title={`Download ${file.name}`}
              >
                <DownloadIcon className="w-5 h-5" />
              </IconExternalLink>
            )}
            <IconDangerButton
              className="ml-1"
              title={`Delete ${file.name}`}
              disabled={isLoading || isUploading}
              onClick={() => setShowConfirmDelete(true)}
            >
              {isDeletingFile ? (
                <LoaderIcon className="w-5 h-5" />
              ) : (
                <TrashIcon className="w-5 h-5" />
              )}
            </IconDangerButton>
          </div>
        </div>
        {showConfirmDelete && (
          <div className="p-2 absolute bottom-0 left-0 w-full bg-white">
            <div className="font-bold text-center">
              Are you sure you want to delete this file?
            </div>
            <div className="mt-2 flex items-center justify-center">
              <TertiaryButton
                ref={cancelDeleteButtonRef}
                onClick={() => setShowConfirmDelete(false)}
              >
                Cancel
              </TertiaryButton>
              <DangerButton
                className="ml-4"
                onClick={async () => {
                  setShowConfirmDelete(false);
                  await deleteFile(file.id);
                  createSnackBar({
                    content: (
                      <DeletedFileSnackBarContent fileName={file.name} />
                    ),
                  });
                }}
              >
                Delete
              </DangerButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InternalFileCard;
