import { useEffect, useState, forwardRef, useRef } from "react";
import { mergeClassNames } from "../../../libs/components";
import { useHasChanged, usePrevious } from "../../../libs/hooks/general";
import {
  clamp,
  isEqualOrAncestorElement,
  isModifierKeyPressed,
} from "../../../libs/utils";

import DropdownMenuButton from "../../buttons/DropdownMenu";

const MIN = 1;
const MAX = 12;
const MONTHS = [
  { number: 1, display: "January", lower: "january", short: "Jan" },
  { number: 2, display: "February", lower: "february", short: "Feb" },
  { number: 3, display: "March", lower: "march", short: "Mar" },
  { number: 4, display: "April", lower: "april", short: "Apr" },
  { number: 5, display: "May", lower: "may", short: "May" },
  { number: 6, display: "June", lower: "june", short: "Jun" },
  { number: 7, display: "July", lower: "july", short: "Jul" },
  { number: 8, display: "August", lower: "august", short: "Aug" },
  { number: 9, display: "September", lower: "september", short: "Sep" },
  { number: 10, display: "October", lower: "october", short: "Oct" },
  { number: 11, display: "November", lower: "november", short: "Nov" },
  { number: 12, display: "December", lower: "december", short: "Dec" },
] as const;

function buildSuggestions(
  searchValue: string | number,
  minIndex: number,
  maxIndex: number
) {
  return MONTHS.filter((month, index) => {
    if (index < minIndex || index > maxIndex) {
      return false;
    } else if (typeof searchValue === "number") {
      return index === searchValue;
    } else {
      return month.lower.startsWith(searchValue);
    }
  });
}

interface MonthInputProps {
  value: number;
  onChange: (newNumber: number) => void;
  min?: number;
  max?: number;
  className?: string;
}

const MonthInput = forwardRef<HTMLInputElement, MonthInputProps>(
  ({ value, onChange, min = MIN, max = MAX, className = "" }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const prevValue = usePrevious(value);
    const hasMinChanged = useHasChanged(min);
    const hasMaxChanged = useHasChanged(max);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [newValue, setNewValue] = useState<number>(value);
    const [inputValue, setInputValue] = useState<string>(
      MONTHS[value - 1].short
    );
    const hasValueChanged = value !== prevValue && value !== newValue;
    const inputValueLowerCase = inputValue.toLocaleLowerCase();
    const inputValueAsNumber = parseInt(inputValue, 10);
    const isInputValueANumber = !isNaN(inputValueAsNumber);
    const clampedInputValueAsNumber = isInputValueANumber
      ? clamp(inputValueAsNumber, min, max)
      : min;
    const clampedInputValueAsIndex = clampedInputValueAsNumber - 1;
    const minAsIndex = min - 1;
    const maxAsIndex = max - 1;
    const suggestions = buildSuggestions(
      isInputValueANumber ? clampedInputValueAsIndex : inputValueLowerCase,
      minAsIndex,
      maxAsIndex
    );

    useEffect(() => {
      if (!hasValueChanged && (hasMinChanged || hasMaxChanged)) {
        const clampedNewValue = clamp(newValue, min, max);
        if (clampedNewValue !== newValue) {
          setNewValue(clampedNewValue);
          onChange(clampedNewValue);
          setInputValue(MONTHS[clampedNewValue - 1].short);
        }
      }
    }, [
      hasMaxChanged,
      hasMinChanged,
      hasValueChanged,
      max,
      min,
      newValue,
      onChange,
    ]);

    useEffect(() => {
      if (hasValueChanged) {
        setNewValue(value);
        setInputValue(MONTHS[value - 1].short);
      }
    }, [hasValueChanged, newValue, prevValue, value]);

    return (
      <div
        ref={containerRef}
        className="relative"
        onBlur={() => {
          window.requestAnimationFrame(() => {
            if (
              !containerRef.current ||
              !document.activeElement ||
              !isEqualOrAncestorElement(
                containerRef.current,
                document.activeElement
              )
            ) {
              setShowSuggestions(false);
            }
          });
        }}
      >
        <input
          ref={ref}
          className={mergeClassNames("bg-transparent", className)}
          type="text"
          value={inputValue}
          onKeyDown={(e) => {
            if (!isModifierKeyPressed(e) && e.key === "Enter") {
              e.preventDefault();

              let newMonth =
                suggestions.length > 0 ? suggestions[0] : MONTHS[minAsIndex];

              if (newMonth.number < MIN || newMonth.number > MAX) {
                newMonth = MONTHS[minAsIndex];
              }

              setInputValue(newMonth.short);
              setNewValue(newMonth.number);

              if (value !== newMonth.number) {
                onChange(newMonth.number);
              }
            }
          }}
          onChange={(e) => {
            const newInputValue = e.target.value.trim();
            const parsedNewInputValue = parseInt(newInputValue, 10);
            const isNewInputValueANumber = !isNaN(parsedNewInputValue);

            if (isNewInputValueANumber) {
              const clampedNewNumber = clamp(parsedNewInputValue, min, max);

              if (
                clampedNewNumber > 9 &&
                clampedNewNumber >= min &&
                clampedNewNumber <= max
              ) {
                const newSuggestions = buildSuggestions(
                  clampedNewNumber - 1,
                  minAsIndex,
                  maxAsIndex
                );
                setInputValue(newSuggestions[0].short);
                setNewValue(clampedNewNumber);

                if (value !== clampedNewNumber) {
                  onChange(clampedNewNumber);
                }
              } else {
                setInputValue("" + clampedNewNumber);
              }
            } else {
              const newSuggestions = buildSuggestions(
                newInputValue.toLowerCase(),
                minAsIndex,
                maxAsIndex
              );
              if (newSuggestions.length === 1) {
                const month = newSuggestions[0];
                setInputValue(month.short);
                setNewValue(month.number);

                if (value !== month.number) {
                  onChange(month.number);
                }
              } else {
                setInputValue(newInputValue);
              }
            }
          }}
          onFocus={(e) => {
            e.target.select();
            setShowSuggestions(true);
          }}
          onBlur={(e) => {
            const newInputValue = e.target.value.trim();
            const parsedNewInputValue = parseInt(newInputValue, 10);
            const isNewInputValueANumber = !isNaN(parsedNewInputValue);
            const newSuggestions = buildSuggestions(
              isNewInputValueANumber
                ? parsedNewInputValue
                : newInputValue.toLowerCase(),
              minAsIndex,
              maxAsIndex
            );

            window.requestAnimationFrame(() => {
              if (
                !containerRef.current ||
                !document.activeElement ||
                !isEqualOrAncestorElement(
                  containerRef.current,
                  document.activeElement
                )
              ) {
                let newMonth =
                  newSuggestions.length > 0
                    ? newSuggestions[0]
                    : MONTHS[minAsIndex];

                if (newMonth.number < MIN || newMonth.number > MAX) {
                  newMonth = MONTHS[minAsIndex];
                }

                setNewValue(newMonth.number);
                setInputValue(newMonth.short);

                if (value !== newMonth.number) {
                  onChange(newMonth.number);
                }
              }
            });
          }}
        />
        {showSuggestions && (
          <div className="p-2 max-h-56 overflow-auto absolute top-6 left-0 z-10 bg-white rounded-lg shadow-lg border border-gray-100">
            {suggestions.map((month) => {
              return (
                <DropdownMenuButton
                  key={month.number}
                  onClick={() => {
                    setNewValue(month.number);
                    setInputValue(month.short);
                    setShowSuggestions(false);

                    if (value !== month.number) {
                      onChange(month.number);
                    }
                  }}
                >
                  {month.display}
                </DropdownMenuButton>
              );
            })}
            {suggestions.length < 1 && (
              <p className="whitespace-nowrap">No matches</p>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default MonthInput;
