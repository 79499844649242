import React from "react";

import { ReactComponent as ErrorImage } from "../../../images/access-denied.svg";

interface MultiPostPublishedPostActionsLoadingProps {
  contentId: "error";
}
const MultiPostPublishedPostActionsError: React.FC<
  MultiPostPublishedPostActionsLoadingProps
> = () => {
  return (
    <div
      className="p-2 sm:p-8 flex items-center justify-center"
      style={{ width: "60rem", height: "34rem" }}
    >
      <div className="flex flex-col items-center">
        <ErrorImage className="h-80 text-red-500" />
        <span className="mt-4 text-lg text-red-500">
          Unable to load socials. Please reload the page and try again.
        </span>
      </div>
    </div>
  );
};

export default MultiPostPublishedPostActionsError;
