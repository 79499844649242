import { useMatch } from "react-router-dom";
import { platformRequiresAuth } from "../platform";
import { useActiveWorkspace } from "./app";
import { usePlatforms } from "./platforms";

export const useShowPlatformRequiresAuthAlert = () => {
  const workspace = useActiveWorkspace();
  const { data: platforms } = usePlatforms(workspace.id);
  const isOnSocialsPage = !!useMatch("/socials");
  const anyPlatformRequiresAuth =
    !!platforms &&
    platforms.some(
      (platform) =>
        !!platform.requiresUserAuth ||
        (!!platform.authExpiry && platformRequiresAuth(platform.authExpiry))
    );
  return anyPlatformRequiresAuth && !isOnSocialsPage;
};
