import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Check as CheckIcon } from "react-feather";

import { mergeClassNames, mergeRefs } from "../../libs/components";

interface CheckboxProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type"
  > {}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ onChange, className = "", ...inputProps }, ref) => {
    const localRef = useRef<HTMLInputElement | null>(null);
    const [isChecked, setIsChecked] = useState(
      inputProps.checked || inputProps.defaultChecked || false
    );

    const localOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);

        if (onChange) {
          onChange(e);
        }
      },
      [onChange]
    );

    useEffect(() => {
      if (localRef.current) {
        setIsChecked(localRef.current.checked);
      }
    }, []);

    return (
      <span
        className={mergeClassNames(
          `w-6 h-6 flex-shrink-0 inline-flex items-center justify-center cursor-pointer rounded border-2 default-transition hover:ring-2 hover:ring-sky-200 ${
            isChecked ? "border-purple-500 bg-purple-500" : "border-gray-200"
          } `,
          className
        )}
      >
        <input
          ref={mergeRefs([ref, localRef])}
          className="sr-only"
          type="checkbox"
          onChange={localOnChange}
          {...inputProps}
        />
        <CheckIcon
          className={`w-4 h-4 text-white default-transition ${
            isChecked ? "opacity-100" : "opacity-0"
          }`}
          strokeWidth={3}
        />
      </span>
    );
  }
);

export default Checkbox;
