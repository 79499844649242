import Joi from "joi";

import { VIDEO_FILE_RESTRICTIONS } from "../../../../libs/files";

export const PLATFORM_MAX_IMAGE_COUNT = {
  TWITTER: 4,
  LINKEDIN: 9,
  FACEBOOK: 9,
} as const;
export const DEFAULT_MAX_IMAGE_COUNT = Math.max(
  ...Object.values(PLATFORM_MAX_IMAGE_COUNT)
);

export const linkAttachmentSchema = Joi.object({
  url: Joi.string()
    .uri({
      scheme: ["http", "https"],
      allowRelative: false,
    })
    .required(),
  thumbnail: Joi.object({
    url: Joi.string()
      .uri({
        scheme: ["http", "https"],
        allowRelative: false,
      })
      .required(),
    height: Joi.number(),
    width: Joi.number(),
  }).unknown(false),
  title: Joi.string(),
  description: Joi.string(),
  domainName: Joi.string(),
}).unknown(false);

export const postAttachmentLinkSchema = Joi.object({
  type: Joi.string().valid("LINK").required(),
  link: linkAttachmentSchema.required(),
}).unknown(false);

export const internalImageAttachmentSchema = Joi.object({
  fileId: Joi.string().required(),
  title: Joi.string().required(),
  height: Joi.number().min(1).required(),
  width: Joi.number().min(1).required(),
}).unknown(false);

export const unsplashImageAttachmentSchema = Joi.object({
  id: Joi.string().required(),
  type: Joi.string().valid("UNSPLASH").required(),
  title: Joi.string().required(),
  height: Joi.number().min(1).required(),
  width: Joi.number().min(1).required(),
  blurHash: Joi.string().allow(null).required(),
  urls: Joi.object({
    full: Joi.string()
      .uri({
        scheme: ["http", "https"],
        allowRelative: false,
      })
      .required(),
    raw: Joi.string()
      .uri({
        scheme: ["http", "https"],
        allowRelative: false,
      })
      .required(),
    regular: Joi.string()
      .uri({
        scheme: ["http", "https"],
        allowRelative: false,
      })
      .required(),
    small: Joi.string()
      .uri({
        scheme: ["http", "https"],
        allowRelative: false,
      })
      .required(),
    thumb: Joi.string()
      .uri({
        scheme: ["http", "https"],
        allowRelative: false,
      })
      .required(),
  }).unknown(true),
  author: Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
    url: Joi.string()
      .uri({
        scheme: ["http", "https"],
        allowRelative: false,
      })
      .required(),
  }),
}).unknown(false);

export const imageAttachmentSchema = Joi.alternatives().try(
  internalImageAttachmentSchema,
  unsplashImageAttachmentSchema
);

export const postAttachmentImageSchemaBase = Joi.object({
  type: Joi.string().valid("IMAGE").required(),
});

export const twitterPostAttachmentImageSchema = postAttachmentImageSchemaBase
  .append({
    images: Joi.array()
      .items(imageAttachmentSchema)
      .min(1)
      .max(PLATFORM_MAX_IMAGE_COUNT.TWITTER)
      .required(),
  })
  .unknown(false);

export const facebookPostAttachmentImageSchema = postAttachmentImageSchemaBase
  .append({
    images: Joi.array()
      .items(imageAttachmentSchema)
      .min(1)
      .max(PLATFORM_MAX_IMAGE_COUNT.FACEBOOK)
      .required(),
  })
  .unknown(false);

export const linkedinPostAttachmentImageSchema = postAttachmentImageSchemaBase
  .append({
    images: Joi.array()
      .items(imageAttachmentSchema)
      .min(1)
      .max(PLATFORM_MAX_IMAGE_COUNT.LINKEDIN)
      .required(),
  })
  .unknown(false);

export const postAttachmentImageSchema = Joi.alternatives().try(
  twitterPostAttachmentImageSchema,
  facebookPostAttachmentImageSchema,
  linkedinPostAttachmentImageSchema
);

export const videoAttachmentSchemaBase = Joi.object({
  fileId: Joi.string().required(),
  title: Joi.string().allow("").required(),
}).unknown(false);

export const twitterVideoAttachmentSchema = videoAttachmentSchemaBase
  .append({
    height: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.TWITTER.HEIGHT_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.TWITTER.HEIGHT_MAX)
      .required(),
    width: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.TWITTER.WIDTH_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.TWITTER.WIDTH_MAX)
      .required(),
    duration: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.TWITTER.DURATION_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.TWITTER.DURATION_MAX)
      .required(),
    size: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.TWITTER.SIZE_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.TWITTER.SIZE_MAX)
      .required(),
  })
  .unknown(false);

export const facebookVideoAttachmentSchema = videoAttachmentSchemaBase
  .append({
    height: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.FACEBOOK.HEIGHT_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.FACEBOOK.HEIGHT_MAX)
      .required(),
    width: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.FACEBOOK.WIDTH_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.FACEBOOK.WIDTH_MAX)
      .required(),
    duration: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.FACEBOOK.DURATION_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.FACEBOOK.DURATION_MAX)
      .required(),
    size: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.FACEBOOK.SIZE_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.FACEBOOK.SIZE_MAX)
      .required(),
  })
  .unknown(false);

export const linkedinVideoAttachmentSchema = videoAttachmentSchemaBase
  .append({
    height: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.LINKEDIN.HEIGHT_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.LINKEDIN.HEIGHT_MAX)
      .required(),
    width: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.LINKEDIN.WIDTH_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.LINKEDIN.WIDTH_MAX)
      .required(),
    duration: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.LINKEDIN.DURATION_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.LINKEDIN.DURATION_MAX)
      .required(),
    size: Joi.number()
      .min(VIDEO_FILE_RESTRICTIONS.LINKEDIN.SIZE_MIN)
      .max(VIDEO_FILE_RESTRICTIONS.LINKEDIN.SIZE_MAX)
      .required(),
  })
  .unknown(false);

export const postAttachmentVideoSchemaBase = Joi.object({
  type: Joi.string().valid("VIDEO").required(),
}).unknown(false);

export const twitterPostAttachmentVideoSchema = postAttachmentVideoSchemaBase
  .append({
    video: twitterVideoAttachmentSchema.required(),
  })
  .unknown(false);

export const facebookPostAttachmentVideoSchema = postAttachmentVideoSchemaBase
  .append({
    video: facebookVideoAttachmentSchema.required(),
  })
  .unknown(false);

export const linkedinPostAttachmentVideoSchema = postAttachmentVideoSchemaBase
  .append({
    video: linkedinVideoAttachmentSchema.required(),
  })
  .unknown(false);

export const postAttachmentVideoSchema = Joi.alternatives().try(
  twitterPostAttachmentVideoSchema,
  facebookPostAttachmentVideoSchema,
  linkedinPostAttachmentVideoSchema
);

export const twitterPostAttachmentSchema = Joi.alternatives()
  .try(
    postAttachmentLinkSchema,
    twitterPostAttachmentImageSchema,
    twitterPostAttachmentVideoSchema
  )
  .allow(null)
  .required();

export const facebookPostAttachmentSchema = Joi.alternatives()
  .try(
    postAttachmentLinkSchema,
    facebookPostAttachmentImageSchema,
    facebookPostAttachmentVideoSchema
  )
  .allow(null)
  .required();

export const linkedinPostAttachmentSchema = Joi.alternatives()
  .try(
    postAttachmentLinkSchema,
    linkedinPostAttachmentImageSchema,
    linkedinPostAttachmentVideoSchema
  )
  .allow(null)
  .required();

export const postAttachmentSchema = Joi.alternatives()
  .try(
    twitterPostAttachmentSchema,
    facebookPostAttachmentSchema,
    linkedinPostAttachmentSchema
  )
  .allow(null)
  .required();

export const postStatusSchema = Joi.string()
  .valid("SCHEDULED", "QUEUED")
  .required();
