import React, { useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAsyncState } from "../libs/hooks/general";
import { USERS_QUERY_KEYS } from "../libs/queryKeys";

import H2 from "../components/headings/H2";
import FormInput from "../components/form/Input";
import PrimaryButton from "../components/buttons/Primary";

import FormPasswordInput, {
  passwordSchema,
} from "../components/form/input/Password";

interface NewPasswordProps {
  email: string;
}

type FormValues = {
  newPasswordCode: string;
  password: string;
  confirmPassword: string;
};

const formSchema = Joi.object({
  newPasswordCode: Joi.string().required(),
  password: passwordSchema.required(),
  confirmPassword: Joi.ref("password"),
});

const errorMessages: Record<
  string,
  { title: string; message: React.ReactNode }
> = {
  CodeMismatchException: {
    title: "That's not the code we're looking for...",
    message: (
      <>
        It looks like the code you've entered isn't the right one.
        <br />
        Double-check you're using the most recent code and try again.
      </>
    ),
  },
  LimitExceededException: {
    title: "Looks like you've tried to too many times...",
    message: (
      <>
        You've hit the limit of forgotten password attempts.
        <br />
        Try again after a break.
      </>
    ),
  },
};

const NewPassword: React.FC<NewPasswordProps> = ({ email: rawEmail }) => {
  const email = rawEmail.toLowerCase();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    mode: "onSubmit",
    criteriaMode: "all",
    resolver: joiResolver(formSchema),
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { loading, loadingText, setLoading, error, setError, clearError } =
    useAsyncState();

  useEffect(() => {
    setFocus("newPasswordCode");
  }, [setFocus]);

  const onSubmit = async ({ newPasswordCode, password }: FormValues) => {
    setLoading(true);
    clearError();

    try {
      const forgotPasswordSubmitResult = await Auth.forgotPasswordSubmit(
        email,
        newPasswordCode,
        password
      );
      if (forgotPasswordSubmitResult === "SUCCESS") {
        await Auth.signIn(email, password);
        await Auth.signOut({ global: true });
        queryClient.resetQueries(USERS_QUERY_KEYS.all);
        navigate("/");
      } else {
        setLoading(false);
        //   wasn't successful but no error
        setError({
          title: "Something went wrong...",
          message: (
            <>
              This never usually happens. Honest. Please try again.
              <br />
              {forgotPasswordSubmitResult}
            </>
          ),
        });
      }
    } catch (e: any) {
      setLoading(false);
      if (e.code && errorMessages[e.code]) {
        setError(errorMessages[e.code]);
      } else {
        setLoading(false);
        setError({
          title: "Something went wrong",
          message: (
            <>
              This never usually happens. Honest. Please try again.
              <br />
              {e.message}
            </>
          ),
        });
      }
    }
  };

  return (
    <>
      <H2 className="my-4">Choose a new password</H2>
      <p className="mb-6">
        Enter in the details below to create your new password.
      </p>
      {error()}
      <form className="pt-1" onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          {...register("newPasswordCode")}
          labelText="Confirmation code"
          labelClassName="mt-4"
          name="newPasswordCode"
          error={!!errors.newPasswordCode}
        />
        <FormPasswordInput
          {...register("password")}
          labelClassName="mt-4"
          showRequirements={true}
          error={!!errors.password}
        />
        <FormPasswordInput
          {...register("confirmPassword")}
          labelText="Confirm password"
          labelClassName="mt-4"
          showRequirements={false}
          error={!!errors.confirmPassword}
        />
        <PrimaryButton
          className="mt-8 w-full text-xl text-white"
          type="submit"
          disabled={loading}
          id="reset-password"
        >
          {loadingText({
            loading: "Resetting password...",
            default: "Reset my password!",
          })}
        </PrimaryButton>
      </form>
    </>
  );
};

export default NewPassword;
