import { DateTime } from "luxon";
import { Workspace } from "../types/workspaces";

const FREE_LIMITS = {
  ENABLED_PLATFORM_ENTITIES: 3,
  FUTURE_POSTS: 5,
  QUEUED_POSTS: 3,
  STORAGE: 104857600, // 100MB in bytes.
};

const SUBSCRIPTION_LIMITS = {
  STORAGE: 107374182400, // 100GB in bytes.
};

export function hasActiveSubscription(workspace: Workspace) {
  return (
    !!workspace.subscription &&
    (workspace.subscription.status === "active" ||
      workspace.subscription.status === "trialing")
  );
}

export function hasEnabledPlatformEntitiesLimit(workspace: Workspace) {
  return !workspace.subscription;
}

export function canEnablePlatformEntities(
  workspace: Workspace,
  amountToEnable: number
) {
  if (!hasEnabledPlatformEntitiesLimit(workspace)) {
    return true;
  }

  const currentNumberOfEnabledPlatformEntities =
    workspace.enabledPlatformEntitiesCount || 0;
  const newNumberOfEnabledPlatformEntities =
    currentNumberOfEnabledPlatformEntities + amountToEnable;

  if (
    newNumberOfEnabledPlatformEntities < currentNumberOfEnabledPlatformEntities
  ) {
    // Always allow the disabling of entities.
    return true;
  } else {
    return (
      newNumberOfEnabledPlatformEntities <=
      FREE_LIMITS.ENABLED_PLATFORM_ENTITIES
    );
  }
}

export function getAvailableEnablePlatformEntities(workspace: Workspace) {
  if (!hasEnabledPlatformEntitiesLimit(workspace)) {
    return null;
  }

  const currentNumberOfEnabledPlatformEntities =
    workspace.enabledPlatformEntitiesCount || 0;

  return Math.max(
    FREE_LIMITS.ENABLED_PLATFORM_ENTITIES -
      currentNumberOfEnabledPlatformEntities,
    0
  );
}

export function hasScheduleFuturePostsLimit(workspace: Workspace) {
  return !workspace.subscription;
}

export function canScheduleFuturePosts(
  workspace: Workspace,
  amountToAdd: number
) {
  if (!hasScheduleFuturePostsLimit(workspace)) {
    return true;
  }

  const currentNumberOfFuturePosts = workspace.futurePostsCount || 0;
  const newNumberOfFuturePosts = currentNumberOfFuturePosts + amountToAdd;

  if (newNumberOfFuturePosts < currentNumberOfFuturePosts) {
    return true;
  } else {
    return newNumberOfFuturePosts <= FREE_LIMITS.FUTURE_POSTS;
  }
}

export function getAvailableScheduleFuturePosts(workspace: Workspace) {
  if (!hasScheduleFuturePostsLimit(workspace)) {
    return null;
  }

  const currentNumberOfFuturePosts = workspace.futurePostsCount || 0;

  return Math.max(FREE_LIMITS.FUTURE_POSTS - currentNumberOfFuturePosts, 0);
}

export function hasPublishQeueuedPostLimit(workspace: Workspace) {
  return !workspace.subscription;
}

export function canPublishQueuedPost(workspace: Workspace, date: DateTime) {
  if (workspace.subscription) {
    return true;
  }

  if (!workspace.postedQueuedPostsCount) {
    return true;
  }

  const dateISO = date.setZone("utc").toISO();
  const isInPeriod =
    workspace.postedQueuedPostsCount.periodStart <= dateISO &&
    workspace.postedQueuedPostsCount.periodEnd >= dateISO;
  const isBelowLimit =
    workspace.postedQueuedPostsCount.count < FREE_LIMITS.QUEUED_POSTS;

  return !isInPeriod || isBelowLimit;
}

export function getAvailablePublishQueuedPosts(
  workspace: Workspace,
  date: DateTime
) {
  if (!canPublishQueuedPost(workspace, date)) {
    return null;
  }

  const currentNumberOfPublishedQueuedPosts = workspace.postedQueuedPostsCount
    ? workspace.postedQueuedPostsCount.count
    : 0;
  return Math.max(
    0,
    FREE_LIMITS.QUEUED_POSTS - currentNumberOfPublishedQueuedPosts
  );
}

export function getWorkspaceStorageLimit(workspace: Workspace) {
  return workspace.subscription
    ? SUBSCRIPTION_LIMITS.STORAGE
    : FREE_LIMITS.STORAGE;
}

export function getAvailableWorkspaceStorage(workspace: Workspace) {
  const usedStorage = workspace.usedStorage || 0;
  const maxStorage = getWorkspaceStorageLimit(workspace);

  return Math.max(0, maxStorage - usedStorage);
}

export function displayUsedWorkspaceStorageAsPercent(workspace: Workspace) {
  const usedStorage = workspace.usedStorage || 0;
  const maxStorage = getWorkspaceStorageLimit(workspace);

  return `${((usedStorage / maxStorage) * 100)
    .toFixed(2)
    .replace(/(0+|\.00)$/, "")}%`;
}
