import React, { ReactNode, useEffect, useRef } from "react";
import GloablInteractionContext, {
  CallbackRegistry,
} from "../contexts/GlobalInteraction";

interface GlobalInteractionProviderProps {
  children: ReactNode;
}
const GlobalInteractionProvider: React.FC<GlobalInteractionProviderProps> = ({
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const handlersRef = useRef<CallbackRegistry>({});

  useEffect(() => {
    // TODO: Find a better solution to trapping focus inside the react app.
    const intervalId = window.setInterval(() => {
      if (
        document.activeElement === document.body &&
        containerRef &&
        containerRef.current
      ) {
        // Don't let focus escape this container or the keyup handler won't work.
        containerRef.current.focus();
      }
    }, 100);

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  const triggerCallbacks = (e: React.MouseEvent | React.KeyboardEvent) => {
    Object.keys(handlersRef.current).forEach((key) => {
      handlersRef.current[key](e);
    });
  };

  const handleClick = (e: React.MouseEvent) => {
    triggerCallbacks(e);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    triggerCallbacks(e);
  };

  return (
    <GloablInteractionContext.Provider value={handlersRef.current}>
      <div
        ref={containerRef}
        className="h-full w-full min-h-screen"
        tabIndex={-1}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
      >
        {children}
      </div>
    </GloablInteractionContext.Provider>
  );
};

export default GlobalInteractionProvider;
