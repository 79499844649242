import React from "react";
import { Star as StarIcon, Check as CheckIcon } from "react-feather";

import config from "../../../config";
import { mergeClassNames } from "../../../libs/components";
import { useActiveWorkspace, useShowModal } from "../../../libs/hooks/app";

import PrimaryButton from "../../../components/buttons/Primary";
import H2 from "../../../components/headings/H2";

import { ReactComponent as UpgradeImage } from "../../../images/upgrade.svg";

interface SubscriptionsOfferSubscriptionSectionProps {
  className?: string;
}
const SubscriptionsOfferSubscriptionSection: React.FC<
  SubscriptionsOfferSubscriptionSectionProps
> = ({ className = "" }) => {
  const showModal = useShowModal();
  const workspace = useActiveWorkspace();
  const displayPrice = `$${Math.ceil(
    config.prices.PUBLISH_MONTHLY.unitAmount / 100
  )} per month`;

  return (
    <div
      className={mergeClassNames(
        "w-full h-full flex justify-center",
        className
      )}
    >
      <div className="relative flex flex-col items-center">
        <div style={{ width: 600 }}>
          <H2>Need more from Seenly?</H2>
          <p className="mt-4">
            If you've outgrown the free tier and want to take your social to the
            next level then we've got you covered.
          </p>
          <p className="mt-4">
            Upgrade to our{" "}
            <strong>{config.prices.PUBLISH_MONTHLY.displayName}</strong>{" "}
            subscription and you'll get:
          </p>
        </div>
        <ul className="mt-4 text-lg">
          {config.prices.PUBLISH_MONTHLY.features.map((feature, index) => {
            return (
              <li key={index} className="flex items-center">
                <CheckIcon className="mr-2 text-purple-500" /> {feature}
              </li>
            );
          })}
        </ul>
        <PrimaryButton
          className="mt-10"
          onClick={() =>
            showModal("changeSubscription", {
              workspace,
              redirectOnSuccess: true,
              defaultPriceId: config.prices.PUBLISH_MONTHLY.id,
            })
          }
        >
          <StarIcon className="mr-2" />
          {`Upgrade for only ${displayPrice}!`}
        </PrimaryButton>

        <UpgradeImage
          className="mt-12 flex-shrink-0 text-purple-500"
          style={{ width: 500, height: 500 }}
        />
      </div>
    </div>
  );
};

export default SubscriptionsOfferSubscriptionSection;
