import React, {
  ReactElement,
  Children,
  CSSProperties,
  useRef,
  useMemo,
  useState,
} from "react";
import { useSpring, useTransition, animated } from "@react-spring/web";

import { mergeClassNames } from "../../libs/components";
import ContentStackContext from "../../contexts/ContentStack";
import { usePrevious } from "../../libs/hooks/general";

type ContentElement = ReactElement<{ contentId: string }>;

type ChildType = ContentElement | ContentElement[] | null;

interface ContentStackProps {
  defaultContentId: string;
  children: ChildType | ChildType[];
  className?: string;
}

const ContentStack: React.FC<ContentStackProps> = ({
  children,
  defaultContentId,
  className = "",
}) => {
  const [stack, setStack] = useState<string[]>([]);
  const previousStackLength = usePrevious(stack.length);
  const slideLeft = stack.length > (previousStackLength || 0);
  const visibleContentId = stack.length
    ? stack[stack.length - 1]
    : defaultContentId;
  const isUsingDefaultContentId =
    stack.length < 1 &&
    (!previousStackLength || previousStackLength < 1) &&
    visibleContentId === defaultContentId;
  const enteringContentRef = useRef<HTMLDivElement>(null);
  const leavingContentRef = useRef<HTMLDivElement>(null);
  const [containerSpring, containerSpringApi] = useSpring<{
    width: NonNullable<CSSProperties["width"]>;
    height: NonNullable<CSSProperties["height"]>;
    overflow: number;
  }>(() => ({
    width: "auto",
    height: "auto",
    overflow: 1,
    immediate: false,
  }));
  const routeTransitions = useTransition(visibleContentId, {
    initial: { x: 0, opacity: 1 },
    from: () => {
      const enteringContentWidth = enteringContentRef.current
        ? enteringContentRef.current.clientWidth
        : 0;

      return {
        x: slideLeft ? enteringContentWidth : enteringContentWidth * -1,
        opacity: 0,
      };
    },
    enter: { x: 0, opacity: 1 },
    leave: () => async (next) => {
      const enteringContentWidth = enteringContentRef.current
        ? enteringContentRef.current.clientWidth
        : 0;
      const enteringContentHeight = enteringContentRef.current
        ? enteringContentRef.current.clientHeight
        : 0;
      const leavingContentWidth = leavingContentRef.current
        ? leavingContentRef.current.clientWidth
        : 0;
      const leavingContentHeight = leavingContentRef.current
        ? leavingContentRef.current.clientHeight
        : 0;

      containerSpringApi.start(() => ({
        from: {
          height: leavingContentHeight,
          width: leavingContentWidth,
          overflow: 0,
        },
        to: [
          {
            height: enteringContentHeight,
            width: enteringContentWidth,
            overflow: 1,
            immediate: false,
          },
          {
            height: "auto",
            width: "auto",
            immediate: true,
          },
        ],
      }));

      await next({
        x: slideLeft ? leavingContentWidth * -1 : enteringContentWidth,
        opacity: 0,
      });
    },
    immediate: false,
  });

  const contextValue = useMemo(() => {
    return {
      push: (contentId: string) => {
        setStack((prevStack) => {
          const newStack = prevStack.slice();
          newStack.push(contentId);
          return newStack;
        });
      },
      pop: () => {
        setStack((prevStack) => {
          const newStack = prevStack.slice();
          newStack.pop();
          return newStack;
        });
      },
      clear: () => {
        setStack([]);
      },
    };
  }, []);

  const childrenByContentId = useMemo(() => {
    return (Children.toArray(children) as ContentElement[]).reduce<{
      [contentId: string]: ChildType;
    }>((carry, child) => {
      if (child) {
        const contentId = child.props.contentId;
        carry[contentId] = child;
      }
      return carry;
    }, {});
  }, [children]);

  const getContent = (id: string) => {
    return childrenByContentId[id] || childrenByContentId[defaultContentId];
  };

  return (
    <ContentStackContext.Provider value={contextValue}>
      <animated.div
        className={mergeClassNames(className, "relative")}
        style={{
          height: containerSpring.height,
          width: containerSpring.width,
          overflow: containerSpring.overflow.to((o) =>
            o === 1 ? "visible" : "hidden"
          ),
        }}
      >
        {routeTransitions((style, item) => {
          const isLeaving = item !== visibleContentId;

          return (
            <animated.div
              ref={isLeaving ? leavingContentRef : enteringContentRef}
              style={{
                x: isUsingDefaultContentId ? undefined : style.x,
                opacity: style.opacity,
                position: isLeaving ? "absolute" : undefined,
                top: isLeaving ? 0 : undefined,
                left: isLeaving ? 0 : undefined,
              }}
            >
              {getContent(item)}
            </animated.div>
          );
        })}
      </animated.div>
    </ContentStackContext.Provider>
  );
};

export default ContentStack;
