import React from "react";

import { mergeClassNames } from "../../../libs/components";

import CardLoading from "../../../components/analytics/dailySnapshot/CardLoading";

interface AnalyticsBodyDetailsLoadingProps {
  className?: string;
}
const AnalyticsBodyDetailsLoading: React.FC<
  AnalyticsBodyDetailsLoadingProps
> = ({ className = "" }) => {
  return (
    <div
      className={mergeClassNames(
        "flex items-center flex-wrap gap-4",
        className
      )}
    >
      <CardLoading />
      <CardLoading />
      <CardLoading />
    </div>
  );
};

export default AnalyticsBodyDetailsLoading;
