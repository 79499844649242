import React, { useEffect } from "react";
import Joi from "joi";

import { VideoAttachment } from "../../../../types/posts";
import { InternalFileVideo } from "../../../../types/files";
import { Platform } from "../../../../types/platforms";

import {
  formatFileSizeForDisplay,
  VIDEO_FILE_RESTRICTIONS,
} from "../../../../libs/files";
import { clampImageHeight } from "../../../../libs/utils";
import { getPlatformTypeName } from "../../../../libs/platform";
import { useFile } from "../../../../libs/hooks/files";
import { useErrorAlert } from "../../../../libs/hooks/general";

import TertiaryButton from "../../../../components/buttons/Tertiary";
import Input from "../../../../components/form/Input";
import InternalVideoPreviewImage from "../../../../components/images/InternalVideoPreview";

import { ReactComponent as NoVideosImage } from "../../../../images/video-files.svg";

import {
  twitterVideoAttachmentSchema,
  facebookVideoAttachmentSchema,
  linkedinVideoAttachmentSchema,
} from "./schemas";

const MAX_PREVIEW_HEIGHT = 250;

interface PostAttachmentVideoProps {
  value: VideoAttachment | null;
  onChange: (newValue: VideoAttachment | null) => void;
  selectedPlatformTypes: Platform["type"][];
  showVideoAttachment: () => void;
  isVideoAttachmentVisible: boolean;
  error?: boolean;
}
const PostAttachmentImage: React.FC<PostAttachmentVideoProps> = ({
  value,
  onChange,
  selectedPlatformTypes,
  showVideoAttachment,
  isVideoAttachmentVisible,
  error,
}) => {
  const {
    error: errorAlert,
    set: setError,
    clear: clearError,
    has: hasError,
  } = useErrorAlert();
  const hasErrorAlert = hasError();

  const { data: videoFile } = useFile<InternalFileVideo>(
    value ? value.fileId : "",
    {
      enabled: !!value,
    }
  );
  const [clampedImageHeight, clampedImageWidth] = clampImageHeight(
    value ? value.height : 0,
    value ? value.width : 0,
    MAX_PREVIEW_HEIGHT
  );

  const handleTitleInputChange = (newTitle: string) => {
    if (!value) {
      return;
    }

    const newVideo = {
      ...value,
      title: newTitle,
    };
    onChange(newVideo);
  };

  useEffect(() => {
    if (error && hasErrorAlert) {
      return;
    }

    if (error && value && selectedPlatformTypes.length > 0) {
      const platformType = selectedPlatformTypes[0];
      let title = "Invalid video";
      let messages: string[] = [
        "This video can't be posted to your selected socials",
      ];
      let validationError: Joi.ValidationError | undefined = undefined;

      if (platformType === "FACEBOOK") {
        const { error: facebookValidationError } =
          facebookVideoAttachmentSchema.validate(value);

        validationError = facebookValidationError;
      } else if (platformType === "LINKEDIN") {
        const { error: linkedinValidationError } =
          linkedinVideoAttachmentSchema.validate(value);

        validationError = linkedinValidationError;
      } else if (platformType === "TWITTER") {
        const { error: twitterValidationError } =
          twitterVideoAttachmentSchema.validate(value);

        validationError = twitterValidationError;
      }

      if (validationError && validationError.details) {
        title = `Invalid video for ${getPlatformTypeName(platformType)}`;
        messages = [];
        validationError.details.forEach((detail) => {
          if (detail.path && detail.path.length) {
            const propName = detail.path[0];
            if (propName === "height" || propName === "width") {
              messages.push(
                `Video resolution ${value.width}x${value.height} is not between ${VIDEO_FILE_RESTRICTIONS[platformType].WIDTH_MIN}x${VIDEO_FILE_RESTRICTIONS[platformType].HEIGHT_MIN} and ${VIDEO_FILE_RESTRICTIONS[platformType].WIDTH_MAX}x${VIDEO_FILE_RESTRICTIONS[platformType].HEIGHT_MAX}`
              );
            } else if (propName === "duration") {
              messages.push(
                `Video duration of ${value.duration} seconds is not between ${VIDEO_FILE_RESTRICTIONS[platformType].DURATION_MIN} and ${VIDEO_FILE_RESTRICTIONS[platformType].DURATION_MAX} seconds`
              );
            } else if (propName === "size") {
              messages.push(
                `Video size of ${formatFileSizeForDisplay(
                  value.size
                )} is not between ${formatFileSizeForDisplay(
                  VIDEO_FILE_RESTRICTIONS[platformType].SIZE_MIN
                )} and ${formatFileSizeForDisplay(
                  VIDEO_FILE_RESTRICTIONS[platformType].SIZE_MAX
                )}`
              );
            }
          }
        });
      }

      setError({
        title,
        message: (
          <ul>
            {messages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        ),
      });
    } else {
      clearError();
    }
  }, [
    clearError,
    error,
    hasErrorAlert,
    selectedPlatformTypes,
    setError,
    value,
  ]);

  return (
    <div className="p-1 w-full">
      {!!value && (
        <div className="shrink-0 p-2 flex flex-col items-center rounded shadow">
          <div className="group w-full flex flex-col">
            <div className="flex items-start pl-1 text-xs font-bold text-gray-400 group-focus-within:text-purple-500">
              TITLE
            </div>

            <Input
              className="py-0 px-1 text-sm text-gray-600 border-2 border-transparent hover:bg-gray-100 focus:bg-white"
              labelText="Video title (optional)"
              hideLabel={true}
              placeholder="Video title (optional)"
              value={value.title}
              debounceOnChange={handleTitleInputChange}
            />
          </div>

          <div
            className="mt-2"
            style={{
              height: clampedImageHeight,
              width: clampedImageWidth,
            }}
          >
            {videoFile && <InternalVideoPreviewImage file={videoFile} />}
          </div>
        </div>
      )}
      {!value && (
        <div className="flex items-center justify-center">
          <NoVideosImage className="h-32 w-32 text-purple-500" />
          <div>
            <p className="ml-8 w-80 text-gray-500">
              Add a video to your post by selecting it in the video picker.
            </p>
            {!isVideoAttachmentVisible && (
              <TertiaryButton
                className="mx-auto mt-4"
                size="sm"
                onClick={() => showVideoAttachment()}
              >
                Show video picker
              </TertiaryButton>
            )}
          </div>
        </div>
      )}
      {errorAlert({ className: "mt-2", dismissable: false })}
    </div>
  );
};

export default PostAttachmentImage;
