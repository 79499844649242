import React, { ComponentProps } from "react";

import { Platform } from "../../types/platforms";
import { getPlatformTypeName } from "../../libs/platform";
import { useDeletePlatform } from "../../libs/hooks/platforms";
import { useSnackBarFactory } from "../../libs/hooks/general";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import H6 from "../../components/headings/H6";
import DangerButton from "../../components/buttons/Danger";
import PlatformProfilePicture from "../../components/images/profile/Platform";
import PlatformEntityProfilePicture from "../../components/images/profile/PlatformEntity";
import ExternalLink from "../../components/links/External";
import DeletedPlatformSnackBarContent from "../../components/snackBarContent/DeletedPlatform";

interface DeletePlatformModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  platform: Platform;
  close: () => void;
}

const DeletePlatformModal: React.FC<DeletePlatformModalProps> = ({
  platform,
  isVisible,
  close,
  ...modalProps
}) => {
  const createSnackBar = useSnackBarFactory();
  const {
    mutateAsync: deletePlatform,
    isLoading,
    loadingText,
    errorAlert,
  } = useDeletePlatform();
  const platformTypeName = getPlatformTypeName(platform.type);
  const entities = Object.values(platform.entities);

  const handleDelete = async () => {
    try {
      await deletePlatform(platform.id);
      createSnackBar({
        content: (
          <DeletedPlatformSnackBarContent
            platformType={platform.type}
            platformName={platform.name}
          />
        ),
      });
      close();
    } catch (e) {}
  };

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <div className="p-8" style={{ width: 500 }}>
        <H3>Are you sure?</H3>
        <p className="mt-4">
          Seenly will no longer be able to manage content for{" "}
          <strong>{platform.name}</strong> or any of it's brands on{" "}
          {platformTypeName}.
        </p>
        <p className="mt-2">
          Any existing scheduled content for {platform.name}, and any of it's
          brands, will also be <strong>deleted</strong>.
        </p>
        <H6 className="mt-8">Account to be disconnected</H6>
        <ExternalLink
          className="p-2 no-underline flex items-center rounded-lg default-transition hover:bg-gray-100 focus:bg-gray-100"
          href={platform.externalUrl}
          title={platform.externalUrl}
        >
          <PlatformProfilePicture className="h-11 w-11" platform={platform} />
          <span className="ml-2 truncate font-bold">{platform.name}</span>
        </ExternalLink>

        <H6 className="mt-2">Managed brands to be removed</H6>
        {entities.map((entity) => {
          return (
            <a
              key={entity.id}
              className="p-2 flex items-center rounded-lg default-transition hover:bg-gray-100 focus:bg-gray-100"
              href={entity.externalUrl}
              title={entity.externalUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <PlatformEntityProfilePicture
                className="h-11 w-11"
                entity={entity}
              />
              <span className="ml-2 truncate font-bold">{entity.name}</span>
            </a>
          );
        })}
        {errorAlert({ className: "mt-8" })}
        <DangerButton
          className="mt-8 w-full"
          onClick={handleDelete}
          disabled={isLoading}
        >
          {loadingText({
            loading: `Disconnecting ${platform.name}...`,
            default: `Yes, disconnect ${platform.name}.`,
          })}
        </DangerButton>
      </div>
    </Modal>
  );
};

export default DeletePlatformModal;
