import React, { forwardRef, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { mergeRefs } from "../libs/components";
import { useDragDrop } from "../libs/hooks/general";

interface DragAutoScrollProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  id?: string;
  threshold?: number;
  velocityIncrement?: number;
  direction?: "vertical" | "horizontal" | "both";
  accept?: string[];
}

const SCROLL_THRESHOLD = 100;
const SCROLL_VELOCITY_INCREMENT = 100;
const SCROLL_DIRECTION = "vertical";

const DragAutoScroll = forwardRef<HTMLDivElement, DragAutoScrollProps>(
  (
    {
      id = "DRAG_AUTO_SCROLL",
      threshold = SCROLL_THRESHOLD,
      velocityIncrement = SCROLL_VELOCITY_INCREMENT,
      direction = SCROLL_DIRECTION,
      accept = ["*"],
      className = "",
      children,
      ...rest
    },
    forwardedRef
  ) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const scrollTimeoutRef = useRef<number | null>(null);
    const verticalScrollVelocityRef = useRef(0);
    const horizontalScrollVelocityRef = useRef(0);
    const [scrollVerticalSpring, scrollVerticalSpringApi] = useSpring(() => ({
      scroll: 0,
    }));
    const [scrollHorizontalSpring, scrollHorizontalSpringApi] = useSpring(
      () => ({
        scroll: 0,
      })
    );
    const { drop } = useDragDrop();
    const isScrollVertical = direction === "vertical" || direction === "both";
    const isScrollHorizontal =
      direction === "horizontal" || direction === "both";

    const handleDragStart: Parameters<typeof drop>[0]["onStart"] = () => {
      if (scrollContainerRef && scrollContainerRef.current) {
        if (scrollVerticalSpring) {
          scrollVerticalSpringApi.start({
            scroll: scrollContainerRef.current.scrollTop,
            immediate: true,
          });
        }

        if (isScrollHorizontal) {
          scrollHorizontalSpringApi.start({
            scroll: scrollContainerRef.current.scrollLeft,
            immediate: true,
          });
        }
      }
    };

    const handleDragUpdate: Parameters<typeof drop>[0]["onUpdate"] = ({
      isOver,
      state,
      dropContainerPosition,
    }) => {
      if (scrollContainerRef && scrollContainerRef.current && isOver) {
        const [x, y] = state.xy;

        let verticalVelocity = 0;
        let horizontalVelocity = 0;

        if (isScrollVertical) {
          const topDiff = y - dropContainerPosition.fixedPosition.top;
          const bottomDiff = dropContainerPosition.fixedPosition.bottom - y;

          if (topDiff <= SCROLL_THRESHOLD && topDiff > 0) {
            verticalVelocity =
              Math.min(
                SCROLL_THRESHOLD,
                Math.round(
                  (SCROLL_THRESHOLD - topDiff) / SCROLL_VELOCITY_INCREMENT
                ) * SCROLL_VELOCITY_INCREMENT
              ) * -1;
          } else if (bottomDiff <= SCROLL_THRESHOLD && bottomDiff > 0) {
            verticalVelocity = Math.min(
              SCROLL_THRESHOLD,
              Math.round(
                (SCROLL_THRESHOLD - bottomDiff) / SCROLL_VELOCITY_INCREMENT
              ) * SCROLL_VELOCITY_INCREMENT
            );
          }
        }

        if (isScrollHorizontal) {
          const leftDiff = x - dropContainerPosition.fixedPosition.left;
          const rightDiff = dropContainerPosition.fixedPosition.right - x;

          if (leftDiff <= SCROLL_THRESHOLD) {
            horizontalVelocity =
              Math.min(
                SCROLL_THRESHOLD,
                Math.round(
                  (SCROLL_THRESHOLD - leftDiff) / SCROLL_VELOCITY_INCREMENT
                ) * SCROLL_VELOCITY_INCREMENT
              ) * -1;
          } else if (rightDiff <= SCROLL_THRESHOLD) {
            horizontalVelocity = Math.min(
              SCROLL_THRESHOLD,
              Math.round(
                (SCROLL_THRESHOLD - rightDiff) / SCROLL_VELOCITY_INCREMENT
              ) * SCROLL_VELOCITY_INCREMENT
            );
          }
        }

        if (
          verticalScrollVelocityRef.current !== verticalVelocity ||
          horizontalScrollVelocityRef.current !== horizontalVelocity
        ) {
          verticalScrollVelocityRef.current = verticalVelocity;
          horizontalScrollVelocityRef.current = horizontalVelocity;

          if (scrollTimeoutRef.current !== null) {
            window.clearTimeout(scrollTimeoutRef.current);
            scrollTimeoutRef.current = null;
          }

          const scrollContainer = () => {
            let shouldKeepScrolling = false;

            if (isScrollVertical) {
              const currentVerticalScroll =
                scrollContainerRef.current!.scrollTop;
              const newVerticalScroll = Math.min(
                Math.max(0, currentVerticalScroll + verticalVelocity),
                scrollContainerRef.current!.scrollHeight
              );

              if (newVerticalScroll !== currentVerticalScroll) {
                scrollVerticalSpringApi.start({
                  scroll: newVerticalScroll,
                  immediate: false,
                });
              }

              if (
                newVerticalScroll > 0 &&
                newVerticalScroll < scrollContainerRef.current!.scrollHeight &&
                verticalVelocity !== 0
              ) {
                shouldKeepScrolling = true;
              }
            }

            if (isScrollHorizontal) {
              const currentHorizontalScroll =
                scrollContainerRef.current!.scrollLeft;
              const newHorizontalScroll = Math.min(
                Math.max(0, currentHorizontalScroll + horizontalVelocity),
                scrollContainerRef.current!.scrollWidth
              );

              if (newHorizontalScroll !== currentHorizontalScroll) {
                scrollHorizontalSpringApi.start({
                  scroll: newHorizontalScroll,
                  immediate: false,
                });
              }

              if (
                newHorizontalScroll > 0 &&
                newHorizontalScroll < scrollContainerRef.current!.scrollWidth &&
                horizontalVelocity !== 0
              ) {
                shouldKeepScrolling = true;
              }
            }

            if (shouldKeepScrolling) {
              scrollTimeoutRef.current = window.setTimeout(
                scrollContainer,
                100
              );
            }
          };

          if (verticalVelocity !== 0 || horizontalVelocity !== 0) {
            scrollContainer();
          }
        }
      }
    };

    const handleDrop: Parameters<typeof drop>[0]["onDrop"] = () => {
      if (scrollTimeoutRef.current !== null) {
        window.clearTimeout(scrollTimeoutRef.current);
        scrollTimeoutRef.current = null;
      }
    };

    return (
      <animated.div
        ref={
          mergeRefs([
            forwardedRef,
            scrollContainerRef,
            drop({
              dropId: id,
              accept,
              onStart: handleDragStart,
              onUpdate: handleDragUpdate,
              onDrop: handleDrop,
            }),
          ]) as any
        }
        className={`${isScrollVertical ? "overflow-y-auto" : ""} ${
          isScrollHorizontal ? "overflow-x-auto" : ""
        } ${className}`}
        {...rest}
        {...{
          scrollTop: scrollVerticalSpring.scroll,
          scrollLeft: scrollHorizontalSpring.scroll,
        }}
      >
        {children}
      </animated.div>
    );
  }
);

export default DragAutoScroll;
