import React from "react";
import { CreditCardIcon } from "@heroicons/react/outline";

interface DeletedPaymentMethodSnackBarContentProps {
  last4: string;
}
const DeletedPaymentMethodSnackBarContent: React.FC<
  DeletedPaymentMethodSnackBarContentProps
> = ({ last4 }) => {
  return (
    <div className="flex items-center">
      <CreditCardIcon className="flex-shrink-0 w-4 h-4" />
      <span className="ml-2">
        <strong>{`•••• ${last4}`}</strong> deleted
      </span>
    </div>
  );
};

export default DeletedPaymentMethodSnackBarContent;
