import React, { useMemo } from "react";
import {
  Sunrise as SunriseIcon,
  Sunset as SunsetIcon,
  Sun as SunIcon,
} from "react-feather";
import { DateTime } from "luxon";

import { Platform } from "../../../../types/platforms";
import { mergeClassNames } from "../../../../libs/components";
import { useActiveWorkspace } from "../../../../libs/hooks/app";
import { usePostsWithScheduledProp } from "../../../../libs/hooks/posts";

import LoaderIcon from "../../../../components/icons/Loader";
import TimeInput from "../../../../components/form/input/Time";
import OutlineButton from "../../../../components/buttons/Outline";
import {
  canGenerateSuggestedEveningTime,
  canGenerateSuggestedMorningTime,
  canGenerateSuggestedNoonTime,
  generateSuggestedPostDateTime,
} from "../../../../libs/post";
import Tooltip from "../../../../components/Tooltip";

interface TimeInputProps {
  value: string;
  onChange: (newScheduled: string) => void;
  timeZone: string;
  selectedPlatformTypes: Platform["type"][];
  allowPast?: boolean;
  className?: string;
  error?: boolean;
}

const PostFormTimeInput: React.FC<TimeInputProps> = ({
  value,
  onChange,
  timeZone,
  selectedPlatformTypes,
  allowPast = true,
  className = "",
  error = false,
}) => {
  const workspace = useActiveWorkspace();
  const scheduledDateTime = DateTime.fromISO(value).setZone(timeZone);
  const scheduledISODate = scheduledDateTime.toISODate();
  const startOfScheduledDayISO = scheduledDateTime.startOf("day").toISO();
  const endOfScheduledDayISO = scheduledDateTime.endOf("day").toISO();
  const { isLoading: isLoadingScheduledDayPosts, data: scheduledDayPosts } =
    usePostsWithScheduledProp(
      workspace.id,
      startOfScheduledDayISO,
      endOfScheduledDayISO
    );
  const disableSuggestMorning =
    !canGenerateSuggestedMorningTime(scheduledDateTime, [scheduledDateTime]) ||
    isLoadingScheduledDayPosts;
  const disabledSuggestNoon =
    !canGenerateSuggestedNoonTime(scheduledDateTime, [scheduledDateTime]) ||
    isLoadingScheduledDayPosts;
  const disableSuggestEvening =
    !canGenerateSuggestedEveningTime(scheduledDateTime, [scheduledDateTime]) ||
    isLoadingScheduledDayPosts;

  const scheduledDayPostsByISODate = useMemo(() => {
    return { [scheduledISODate]: scheduledDayPosts || [] };
  }, [scheduledDayPosts, scheduledISODate]);

  return (
    <div
      className={mergeClassNames(
        `p-2 flex items-center rounded-lg default-transition border-2 hover:bg-gray-100 focus-within:bg-white focus-within:border-purple-500 ${
          error ? "border-red-500" : "border-transparent"
        }`,
        className
      )}
    >
      <TimeInput
        value={{
          hour: scheduledDateTime.hour,
          minute: scheduledDateTime.minute,
        }}
        onChange={(newValues) => {
          const newScheduledDateTime = scheduledDateTime.set(newValues);
          onChange(newScheduledDateTime.toISO());
        }}
        timeZone={timeZone}
        allowPast={allowPast}
      />
      <Tooltip
        className="ml-auto"
        content={
          <p className="w-64">
            Seenly will intelligently suggest a time in the morning for your
            post
          </p>
        }
        disabled={disableSuggestMorning}
      >
        <OutlineButton
          className="px-2 py-2 border-gray-200 flex items-center text-xs font-normal"
          disabled={disableSuggestMorning}
          onClick={() => {
            const newScheduledDateTime = generateSuggestedPostDateTime(
              scheduledDayPostsByISODate,
              timeZone,
              selectedPlatformTypes,
              DateTime.local().setZone(timeZone),
              [scheduledDateTime],
              true,
              false,
              false
            );

            if (newScheduledDateTime) {
              onChange(newScheduledDateTime.toISO());
            }
          }}
        >
          {isLoadingScheduledDayPosts ? (
            <LoaderIcon className="mr-1 h-4 w-4" />
          ) : (
            <SunriseIcon className="mr-1 h-4 w-4" />
          )}
          Morning
        </OutlineButton>
      </Tooltip>
      <Tooltip
        content={
          <p className="w-64">
            Seenly will intelligently suggest a time around noon for your post
          </p>
        }
        disabled={disabledSuggestNoon}
      >
        <OutlineButton
          className="ml-1 px-2 py-2 border-gray-200 flex items-center text-xs font-normal"
          disabled={disabledSuggestNoon}
          onClick={() => {
            const newScheduledDateTime = generateSuggestedPostDateTime(
              scheduledDayPostsByISODate,
              timeZone,
              selectedPlatformTypes,
              DateTime.local().setZone(timeZone),
              [scheduledDateTime],
              false,
              true,
              false
            );

            if (newScheduledDateTime) {
              onChange(newScheduledDateTime.toISO());
            }
          }}
        >
          {isLoadingScheduledDayPosts ? (
            <LoaderIcon className="mr-1 h-4 w-4" />
          ) : (
            <SunIcon className="mr-1 h-4 w-4" />
          )}
          Noon
        </OutlineButton>
      </Tooltip>
      <Tooltip
        content={
          <p className="w-64">
            Seenly will intelligently suggest a time in the evening for your
            post
          </p>
        }
        disabled={disableSuggestEvening}
      >
        <OutlineButton
          className="ml-1 px-2 py-2 border-gray-200 flex items-center text-xs font-normal"
          disabled={disableSuggestEvening}
          onClick={() => {
            const newScheduledDateTime = generateSuggestedPostDateTime(
              scheduledDayPostsByISODate,
              timeZone,
              selectedPlatformTypes,
              DateTime.local().setZone(timeZone),
              [scheduledDateTime],
              false,
              false,
              true
            );

            if (newScheduledDateTime) {
              onChange(newScheduledDateTime.toISO());
            }
          }}
        >
          {isLoadingScheduledDayPosts ? (
            <LoaderIcon className="mr-1 h-4 w-4" />
          ) : (
            <SunsetIcon className="mr-1 h-4 w-4" />
          )}
          Evening
        </OutlineButton>
      </Tooltip>
    </div>
  );
};

export default PostFormTimeInput;
