import React from "react";
import { mergeClassNames } from "../../../libs/components";
import { useShowModal } from "../../../libs/hooks/app";

import DangerButton from "../../../components/buttons/Danger";

interface ProfileDangerZoneSectionProps {
  className?: string;
}

const ProfileDangerZoneSection: React.FC<ProfileDangerZoneSectionProps> = ({
  className = "",
}) => {
  const showModal = useShowModal();

  return (
    <DangerButton
      className={mergeClassNames("w-full", className)}
      onClick={() => showModal("deleteUserAccount")}
    >
      Delete account
    </DangerButton>
  );
};

export default ProfileDangerZoneSection;
