import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ApiResponse } from "../../types/general";
import {
  CreatePublishedPostActionProps,
  PublishedPostAction,
  UpdatePropsPublishedPostActionForType,
} from "../../types/publishedPostActions";

import { PUBLISHED_POST_ACTIONS_QUERY_KEYS } from "../queryKeys";
import {
  createQueryCacheObject,
  updateQueryCacheObject,
  removeQueryCacheObject,
} from "../queryCache";
import { useApiClient } from "./app";
import { useDecoratedReactQuery } from "./general";

export const useCreatePublishedPostActions = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useDecoratedReactQuery(
    useMutation(
      async (props: CreatePublishedPostActionProps[]) => {
        const { data: response } = await apiClient.post<
          ApiResponse<PublishedPostAction[]>
        >("/published_post_actions", props);

        return response.data;
      },
      {
        onSuccess: (actions) => {
          actions.forEach((action) => {
            createQueryCacheObject(
              queryClient,
              {
                detail: PUBLISHED_POST_ACTIONS_QUERY_KEYS.detail(action.id),
                lists: [
                  PUBLISHED_POST_ACTIONS_QUERY_KEYS.list({
                    postId: action.postId,
                  }),
                ],
              },
              action.id,
              action
            );
          });
        },
      }
    ),
    () => ({ title: "Failed to create published post actions" })
  );
};

interface UpdatePublishedPostActionProps<
  T extends PublishedPostAction = PublishedPostAction
> {
  action: T;
  updateProps: UpdatePropsPublishedPostActionForType<T>;
}
export const useUpdatePublishedPostAction = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ action, updateProps }: UpdatePublishedPostActionProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<typeof action>
        >(`/published_post_actions/${action.id}`, updateProps);

        return response.data;
      },
      {
        onSuccess: (updateAction) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: PUBLISHED_POST_ACTIONS_QUERY_KEYS.detail(updateAction.id),
              lists: [
                PUBLISHED_POST_ACTIONS_QUERY_KEYS.list({
                  postId: updateAction.postId,
                }),
              ],
            },
            updateAction.id,
            () => updateAction
          );
        },
      }
    ),
    () => ({ title: "Failed to update pbulsihed post action" })
  );
};

export const useDeletePublishedPostAction = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (actionId: string) => {
        const { data: response } = await apiClient.delete<ApiResponse<null>>(
          `/published_post_actions/${actionId}`
        );

        return response.data;
      },
      {
        onSuccess: (response, actionId) => {
          removeQueryCacheObject(
            queryClient,
            {
              detail: PUBLISHED_POST_ACTIONS_QUERY_KEYS.detail(actionId),
              lists: [PUBLISHED_POST_ACTIONS_QUERY_KEYS.lists()],
            },
            actionId
          );
        },
      }
    ),
    () => ({ title: "Failed to delete publish post action" })
  );
};

export const usePublishedPostActionsForPost = (postId: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useQuery(
    PUBLISHED_POST_ACTIONS_QUERY_KEYS.list({
      postId,
    }),
    async () => {
      const { data: response } = await apiClient.get<
        ApiResponse<PublishedPostAction[]>
      >("/published_post_actions", {
        params: {
          postId,
        },
      });

      return response.data;
    },
    {
      onSuccess: (actions) => {
        actions.forEach((action) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: PUBLISHED_POST_ACTIONS_QUERY_KEYS.detail(action.id),
            },
            action.id,
            action
          );
        });
      },
    }
  );
};
