import React from "react";
import { ChevronDown as ChevronDownIcon } from "react-feather";
import { ClockIcon } from "@heroicons/react/outline";

import { WorkspaceAnalytics } from "../../types/workspaceAnalytics";

import { mergeClassNames } from "../../libs/components";
import { getPlatformTypeName } from "../../libs/platform";
import { getPeriodDisplayName } from "../../libs/workspaceAnalytics";

import PageActionButton from "../../components/buttons/PageAction";
import Select from "../../components/form/Select";
import PlatformIcon from "../../components/icons/Platform";

const PLATFORM_TYPE_OPTIONS: WorkspaceAnalytics["type"][] = [
  "LINKEDIN",
  "FACEBOOK",
  "TWITTER",
];

const PERIOD_OPTIONS: WorkspaceAnalytics["period"][] = [
  "LAST_7_DAYS",
  "LAST_14_DAYS",
  "LAST_30_DAYS",
  "LAST_60_DAYS",
];

interface AnalyticsHeaderProps {
  platformType: WorkspaceAnalytics["type"];
  period: WorkspaceAnalytics["period"];
  onPlatformTypeChange: (newPlatformType: WorkspaceAnalytics["type"]) => void;
  onPeriodChange: (newPeriod: WorkspaceAnalytics["period"]) => void;
  className?: string;
}
const AnalyticsHeader: React.FC<AnalyticsHeaderProps> = ({
  platformType,
  period,
  onPlatformTypeChange,
  onPeriodChange,
  className = "",
}) => {
  return (
    <div className={mergeClassNames("flex items-center flex-wrap", className)}>
      <div className="ml-auto flex items-center">
        <Select
          ButtonElement={PageActionButton}
          dropdownClassName="ml-2"
          toggleButtonClassName="font-bold"
          labelText="Select analytics period"
          hideLabel={true}
          hideDropdownIcon={false}
          dropdownXAlign="right"
          value={period}
          options={PERIOD_OPTIONS.map((option) => ({
            text: getPeriodDisplayName(option),
            value: option,
          }))}
          onChange={(newValue) =>
            onPeriodChange(newValue as WorkspaceAnalytics["period"])
          }
          renderSelectedOption={(selectedOption) => {
            if (!selectedOption) {
              return null;
            }

            return (
              <div className="flex items-center w-full">
                <ClockIcon className="flex-shrink-0 h-6 w-6" />
                <span className="mx-2 truncate">{selectedOption.text}</span>
                <ChevronDownIcon className="flex-shrink-0 h-5 w-5" />
              </div>
            );
          }}
        />
        <Select
          ButtonElement={PageActionButton}
          dropdownClassName="ml-2"
          toggleButtonClassName="font-bold"
          labelText="Select social platform"
          hideLabel={true}
          hideDropdownIcon={false}
          dropdownXAlign="right"
          value={platformType}
          options={PLATFORM_TYPE_OPTIONS.map((option) => ({
            text: getPlatformTypeName(option),
            value: option,
          }))}
          onChange={(newValue) =>
            onPlatformTypeChange(newValue as WorkspaceAnalytics["type"])
          }
          renderSelectedOption={(selectedOption) => {
            if (!selectedOption) {
              return null;
            }

            return (
              <div className="flex items-center w-full">
                <PlatformIcon
                  className="flex-shrink-0 h-6 w-6"
                  type={selectedOption.value as WorkspaceAnalytics["type"]}
                />
                <span className="mx-2 truncate">{selectedOption.text}</span>
                <ChevronDownIcon className="flex-shrink-0 h-5 w-5" />
              </div>
            );
          }}
          renderOption={(option) => {
            return (
              <div className="flex items-center w-full">
                <PlatformIcon
                  className="flex-shrink-0 h-6 w-6"
                  type={option.value as WorkspaceAnalytics["type"]}
                />
                <span className="ml-2 truncate">{option.text}</span>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AnalyticsHeader;
