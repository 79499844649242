import { PlatformEntity } from "../types/platforms";
import {
  RawPostMetricsFacebook,
  RawPostMetricsLinkedIn,
  RawPostMetricsTwitter,
} from "../types/postAnalytics";
import { Post, PostFacebook, PostLinkedIn, PostTwitter } from "../types/posts";

export function getLinkedInMetricsForEntityType(
  post: PostLinkedIn,
  entityType: PlatformEntity["type"]
) {
  const baseMetrics: Array<keyof RawPostMetricsLinkedIn> = [
    "likeCount",
    "commentCount",
  ];

  if (entityType === "BUSINESS") {
    return baseMetrics.concat(["clickCount", "impressionCount", "shareCount"]);
  } else {
    return baseMetrics;
  }
}

export function getFacebookMetricsForEntityType(
  post: PostFacebook,
  entityType: PlatformEntity["type"]
) {
  const baseMetrics: Array<keyof RawPostMetricsFacebook> = [
    "reactionsTotal",
    "commentCount",
    "impressions",
    "engagedUsers",
  ];

  return baseMetrics;
}

export function getTwitterMetricsForEntityType(
  post: PostTwitter,
  entityType: PlatformEntity["type"]
) {
  const metrics: Array<keyof RawPostMetricsTwitter> = [
    "likeCount",
    "replyCount",
    "retweetCount",
    "quoteCount",
    "impressionsCount",
    "userProfileClicks",
  ];

  if (post.attachment && post.attachment.type === "LINK") {
    metrics.push("urlLinkClicks");
  }

  return metrics;
}

export function getMetricsForPost<T extends Post>(
  post: T,
  entityType: PlatformEntity["type"]
) {
  if (post.type === "LINKEDIN") {
    return getLinkedInMetricsForEntityType(post, entityType);
  } else if (post.type === "FACEBOOK") {
    return getFacebookMetricsForEntityType(post, entityType);
  } else {
    return getTwitterMetricsForEntityType(post, entityType);
  }
}
