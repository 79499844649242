import { useContext } from "react";
import { DropEvent, FileRejection } from "react-dropzone";

import UploadContext from "../../contexts/Upload";
import {
  InternalFile,
  InternalFileImage,
  InternalFileVideo,
} from "../../types/files";
import {
  FileUpload,
  ImageFileUpload,
  VideoFileUpload,
  UploadImageProps,
  UploadVideoProps,
} from "../../types/uploads";
import { useCreateFile } from "./files";
import {
  useAsyncState,
  useFileDropStack,
  UseFileDropStackProps,
  useSnackBarFactory,
} from "./general";
import {
  ALLOWED_FILE_SIZES,
  MIME_TYPE_TO_FILE_TYPE,
  FILE_TYPE_TO_MIME_TYPE,
} from "../files";

import UploadFailedSnackBarContent from "../../components/snackBarContent/UploadFailed";

const UPLOAD_FILE_ERROR_CODES = {
  "0001": `Image file must be between ${ALLOWED_FILE_SIZES.IMAGE.MIN_DESC} and ${ALLOWED_FILE_SIZES.IMAGE.MAX_DESC}`,
  "0002": `Video file must be between ${ALLOWED_FILE_SIZES.VIDEO.MIN_DESC} and ${ALLOWED_FILE_SIZES.VIDEO.MAX_DESC}`,
  "0003": `File type not allowed`,
} as const;

export function isImageFileUpload(
  upload: FileUpload
): upload is ImageFileUpload {
  return upload.internalFile.type === "IMAGE";
}

export function isVideoFileUpload(
  upload: FileUpload
): upload is VideoFileUpload {
  return upload.internalFile.type === "VIDEO";
}

export const useUpload = (fileId: string) => {
  const { get } = useContext(UploadContext);
  return get(fileId);
};

export const useUploads = () => {
  const { getAll } = useContext(UploadContext);
  return getAll();
};

export const useCreateUpload = () => {
  const { upload } = useContext(UploadContext);
  return upload;
};

export const useRemoveUpload = () => {
  const { remove } = useContext(UploadContext);
  return remove;
};

interface InvalidFiles {
  file: File;
  code: keyof typeof UPLOAD_FILE_ERROR_CODES;
  desc: string;
}

interface UploadFilesProps extends Omit<UseFileDropStackProps, "onDrop"> {
  owner: InternalFile["owner"];
  accept?: InternalFile["type"][];
  onDropStart?: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void | File[];
  onDropEnd?: (fileUploads: FileUpload[], event: DropEvent) => void;
}

export const useUploadFiles = ({
  owner,
  accept = ["IMAGE", "VIDEO"],
  onDropStart,
  onDropEnd,
  ...fileDropStackProps
}: UploadFilesProps) => {
  const { mutateAsync: createFile } = useCreateFile<InternalFile>();
  const createUpload = useCreateUpload();
  const createSnackBar = useSnackBarFactory();
  const { setLoading, setError, ...rest } = useAsyncState();

  const uploadFiles = async (
    files: File[] | FileList
  ): Promise<{ invalidFiles: InvalidFiles[]; fileUploads: FileUpload[] }> => {
    const invalidFiles: InvalidFiles[] = [];
    const validFiles: Array<{
      file: File;
      type: InternalFile["type"];
    }> = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileContentType = file.type;
      const fileType =
        MIME_TYPE_TO_FILE_TYPE[
          fileContentType as keyof typeof MIME_TYPE_TO_FILE_TYPE
        ];

      if (!fileType || !accept.includes(fileType)) {
        const allowedContentTypes = accept.reduce<string[]>(
          (carry, acceptType) => {
            return carry.concat(FILE_TYPE_TO_MIME_TYPE[acceptType]);
          },
          []
        );
        invalidFiles.push({
          file,
          code: "0003",
          desc: `${
            UPLOAD_FILE_ERROR_CODES["0003"]
          } - must be of type ${allowedContentTypes.join(", ")}`,
        });
        continue;
      }

      const sizeLimit = ALLOWED_FILE_SIZES[fileType];
      if (file.size < sizeLimit.MIN || file.size > sizeLimit.MAX) {
        invalidFiles.push({
          file,
          code: fileType === "IMAGE" ? "0001" : "0002",
          desc: UPLOAD_FILE_ERROR_CODES[fileType === "IMAGE" ? "0001" : "0002"],
        });
        continue;
      }

      validFiles.push({
        file,
        type: fileType,
      });
    }

    if (invalidFiles.length) {
      setError({
        title: "Invalid files",
        message: (
          <>
            Unable to upload the following files:
            <ul>
              {invalidFiles.map(({ file, desc }, index) => (
                <li key={index}>
                  {file.name} - {desc}
                </li>
              ))}
            </ul>
          </>
        ),
      });

      invalidFiles.forEach(({ file, desc }) => {
        createSnackBar({
          content: (
            <UploadFailedSnackBarContent
              fileName={file.name}
              errorDesc={desc}
            />
          ),
        });
      });
    }

    if (!validFiles.length) {
      return {
        invalidFiles,
        fileUploads: [],
      };
    }

    setLoading(true);
    const fileUploadPromises: Promise<FileUpload>[] = [];

    for (let i = 0; i < validFiles.length; i++) {
      const { file: rawFile, type: fileType } = validFiles[i];

      fileUploadPromises.push(
        new Promise(async (resolve, reject) => {
          try {
            const reader = new FileReader();
            reader.addEventListener("load", (e) => {
              if (e.target && reader.result) {
                const fileReaderUrl = reader.result as string;

                if (fileType === "IMAGE") {
                  const image = new Image();

                  image.onload = async () => {
                    try {
                      const createFileResponse = await createFile({
                        owner,
                        contentType: rawFile.type,
                        name: rawFile.name,
                      });

                      if (!createFileResponse) {
                        reject(`Unable to create file ${rawFile.name}`);
                        return;
                      }

                      const uploadProps: UploadImageProps = {
                        internalFile:
                          createFileResponse.file as InternalFileImage,
                        rawFile,
                        postUrl: createFileResponse.postUrl,
                        postFields: createFileResponse.postFields,
                        previewUrl: fileReaderUrl,
                        imageHeight: image.naturalHeight,
                        imageWidth: image.naturalWidth,
                      };

                      resolve(createUpload(uploadProps));
                    } catch (e) {
                      reject(`Unable to create file ${rawFile.name}`);
                    }
                  };

                  image.src = fileReaderUrl;
                } else {
                  const video = document.createElement("video");

                  video.addEventListener("loadedmetadata", async () => {
                    try {
                      const createFileResponse = await createFile({
                        owner,
                        contentType: rawFile.type,
                        name: rawFile.name,
                      });

                      if (!createFileResponse) {
                        reject(`Unable to create file ${rawFile.name}`);
                        return;
                      }

                      const uploadProps: UploadVideoProps = {
                        internalFile:
                          createFileResponse.file as InternalFileVideo,
                        rawFile,
                        postUrl: createFileResponse.postUrl,
                        postFields: createFileResponse.postFields,
                        videoHeight: video.videoHeight,
                        videoWidth: video.videoWidth,
                        videoDuration: video.duration,
                        videoSize: rawFile.size,
                      };

                      resolve(createUpload(uploadProps));
                    } catch (e) {
                      reject(`Unable to create file ${rawFile.name}`);
                    }
                  });

                  video.src = fileReaderUrl;
                }
              } else {
                reject(`Unable to load file ${rawFile.name}`);
              }
            });

            reader.readAsDataURL(rawFile);
          } catch (e) {
            reject(e);
          }
        })
      );
    }

    try {
      const fileUploads = await Promise.all(fileUploadPromises);
      setLoading(false);
      return { invalidFiles, fileUploads };
    } catch (e: any) {
      setLoading(false);
      setError({
        title: "Upload failed",
        message: e.message || e,
      });

      return { invalidFiles, fileUploads: [] };
    }
  };

  const handleFileDrop: Parameters<
    typeof useFileDropStack
  >[0]["onDrop"] = async (acceptedFiles: File[], fileRejections, event) => {
    let filesToUpload = acceptedFiles;

    if (onDropStart) {
      const filteredFilesToUpload = onDropStart(
        acceptedFiles,
        fileRejections,
        event
      );

      if (filteredFilesToUpload) {
        filesToUpload = filteredFilesToUpload;
      }
    }

    if (fileRejections.length) {
      setError({
        title: "Unsupported file types",
        message: (
          <>
            These files are not supported:
            <ul>
              {fileRejections.map(({ file }, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </>
        ),
      });

      fileRejections.forEach(({ file }) => {
        createSnackBar({
          content: (
            <UploadFailedSnackBarContent
              fileName={file.name}
              errorDesc="Unsupported file type"
            />
          ),
        });
      });
    }

    let fileUploads: FileUpload[] = [];

    if (filesToUpload.length) {
      try {
        const { fileUploads: successfulFileUploads } = await uploadFiles(
          filesToUpload
        );
        fileUploads = successfulFileUploads;
      } catch (e) {}
    }

    if (onDropEnd) {
      onDropEnd(fileUploads, event);
    }
  };

  useFileDropStack({
    onDrop: handleFileDrop,
    ...fileDropStackProps,
  });

  return { uploadFiles, ...rest };
};
