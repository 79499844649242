import React from "react";
import { mergeClassNames } from "../../../libs/components";

interface InvoiceTableHeaderProps {
  includeWorkspace?: boolean;
  className?: string;
}
const InvoiceTableHeader: React.FC<InvoiceTableHeaderProps> = ({
  includeWorkspace = false,
  className = "",
}) => {
  return (
    <thead
      className={mergeClassNames("text-left text-xs text-gray-400", className)}
    >
      <tr>
        {includeWorkspace && <th className="p-2">WORKSPACE</th>}
        <th className="p-2 w-1">AMOUNT</th>
        <th className="p-2"></th>
        <th className="p-2"></th>
        <th className="p-2">PERIOD</th>
        <th className="p-2 w-1">DUE</th>
        <th className="p-2">CREATED</th>
        <th className="p-2"></th>
      </tr>
    </thead>
  );
};

export default InvoiceTableHeader;
