import React, { useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import { mergeRefs } from "../libs/components";
import { useCreateWorkspace } from "../libs/hooks/workspaces";
import { useUpdateUserPreferences } from "../libs/hooks/userPreferences";
import { useCurrentUser } from "../libs/hooks/app";

import H1 from "../components/headings/H1";
import FormInput from "../components/form/Input";
import PrimaryButton from "../components/buttons/Primary";
import BrandLogoLink from "../components/links/BrandLogo";

type FormValues = {
  name: string;
};

const formSchema = Joi.object({
  name: Joi.string().required(),
});

const WelcomePage: React.FC = () => {
  const user = useCurrentUser();
  const {
    mutateAsync: createWorkspace,
    isLoading: isLoadingCreateWorkspace,
    loadingText: loadingTextCreateWorkspace,
    errorAlert,
  } = useCreateWorkspace();
  const {
    mutateAsync: updateUserPreferences,
    isLoading: isLoadingUpdatePreferences,
    loadingText: loadingTextUpdatePreferences,
  } = useUpdateUserPreferences();
  const nameRef = useRef<HTMLInputElement | null>(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema),
  });

  useEffect(() => {
    if (nameRef && nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  const onSubmit = async (data: FormValues) => {
    const { workspace } = await createWorkspace(data);
    await updateUserPreferences({
      userId: user.id,
      updateProps: { activeWorkspaceId: workspace.id },
    });
  };

  const { ref: nameInputRef, ...nameRegisterProps } = register("name");

  return (
    <div className="relative w-screen h-screen overflow-hidden flex items-center justify-center bg-white">
      <div className="p-4 absolute top-0 left-0 z-10">
        <BrandLogoLink />
      </div>

      <div
        className="p-2 relative flex flex-col items-center justify-center"
        style={{ maxWidth: 800 }}
      >
        <div
          className="px-8 py-4 flex flex-col shadow-xl bg-white rounded-lg border border-gray-100 z-10"
          style={{ maxWidth: 600 }}
        >
          <H1 className="flex-shrink-0">Welcome!</H1>
          <p className="pt-2">
            Let's get you started with a workspace. A workspace allows you to
            manage all of your related socials in a single spot.
          </p>
          <p className="pt-2">
            Managing more than one set of socials? No problem, we've got you
            covered! You can create as many workspaces as you need to stay
            organised but for now let's just start with one.
          </p>
          {errorAlert({ className: "mt-2" })}
          <form className="mt-auto text-left" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              {...nameRegisterProps}
              ref={mergeRefs([nameInputRef, nameRef])}
              name="name"
              labelText="Workspace name"
              labelClassName="mt-4"
              type="text"
              error={!!errors.name}
            />
            <PrimaryButton
              className="mt-6 w-full text-xl text-white flex items-center"
              type="submit"
              disabled={isLoadingCreateWorkspace || isLoadingUpdatePreferences}
              id="create-first-workspace"
            >
              {isLoadingCreateWorkspace
                ? loadingTextCreateWorkspace({
                    loading: "Creating workspace...",
                    default: "Create workspace",
                  })
                : isLoadingUpdatePreferences
                ? loadingTextUpdatePreferences({
                    loading: "Loading workspace...",
                    default: "Create workspace",
                  })
                : "Create workspace"}
            </PrimaryButton>
          </form>
        </div>
        <div
          className="absolute bottom-0 right-0 rounded-full bg-teal-300 opacity-75 -mb-12 -mx-10"
          style={{
            width: "400px",
            height: "400px",
          }}
        ></div>
        <div
          className="absolute top-0 right-0 rounded-full bg-indigo-300 opacity-75 -mt-20 mr-4"
          style={{
            width: "500px",
            height: "500px",
          }}
        ></div>
        <div
          className="absolute bottom-0 left-0 rounded-full bg-purple-300 opacity-75 -ml-16 -mb-16"
          style={{
            width: "400px",
            height: "400px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default WelcomePage;
