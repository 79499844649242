import React, { ReactNode, useRef } from "react";
import { useTransition, animated } from "@react-spring/web";

import { useContentSwitch } from "../../../libs/hooks/general";

import H2 from "../../../components/headings/H2";
import OutlineButton from "../../../components/buttons/Outline";
import PlatformIcon from "../../../components/icons/Platform";

import {
  AddPlatformContent,
  BaseComponentProps,
} from "../../../types/modals/AddPlatform";

const buttons: Array<{
  icon: ReactNode;
  name: string;
  id: AddPlatformContent["id"];
}> = [
  {
    icon: <PlatformIcon type="LINKEDIN" colour={true} />,
    name: "LinkedIn",
    id: "consentLinkedIn",
  },
  {
    icon: <PlatformIcon type="FACEBOOK" colour={true} />,
    name: "Facebook",
    id: "consentFacebook",
  },
  {
    icon: <PlatformIcon type="TWITTER" colour={true} />,
    name: "Twitter",
    id: "consentTwitter",
  },
];

const AddPlatformModalHome: React.FC<BaseComponentProps> = ({
  isVisible,
  contentId,
}) => {
  const { showContent, content } = useContentSwitch<AddPlatformContent, "home">(
    "home"
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const showButtons = isVisible && content.id === contentId;
  const buttonTransitions = useTransition(showButtons ? buttons : [], {
    keys: (button) => button.name,
    trail: 400 / buttons.length,
    from: { opacity: 0, transform: "scale(0)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0)" },
  });

  return (
    <div
      ref={containerRef}
      className="p-8 relative flex flex-col"
      style={{ width: 500 }}
    >
      <H2 className="flex-shrink-0 text-2xl text-black w-full">
        Connect a social platform
      </H2>
      <p className="mt-2">
        Connect your social platforms to begin managing your content with
        Seenly.
      </p>
      <p className="mt-2">
        Start by selecting one of the platforms below, then follow the prompts
        to authorise Seenly to manage content on your behalf.
      </p>
      <div className="mt-4 flex-grow flex items-center justify-around flex-wrap">
        {buttonTransitions((style, item) => (
          <animated.div className="m-2 w-64" style={style}>
            <OutlineButton
              className="w-full"
              onClick={() => showContent({ id: item.id } as AddPlatformContent)}
            >
              <span className="mr-2">{item.icon}</span>
              {`Connect ${item.name}`}
            </OutlineButton>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default AddPlatformModalHome;
