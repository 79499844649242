import React, { ComponentProps } from "react";
import { Link } from "react-router-dom";
import { mergeClassNames } from "../../libs/components";

const DropdownMenuLink: React.FC<ComponentProps<typeof Link>> = ({
  className = "",
  ...props
}) => {
  return (
    <Link
      className={mergeClassNames(
        "flex items-center justify-start rounded-lg default-transition py-2 px-2 w-full text-left font-normal bg-gradient-to-r hover:text-black hover:from-emerald-200 hover:to-sky-200 focus:text-black focus:from-emerald-200 focus:to-sky-200",
        className
      )}
      {...props}
    />
  );
};

export default DropdownMenuLink;
