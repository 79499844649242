import React, { ComponentProps, useState } from "react";
import equal from "fast-deep-equal/es6";
import { SubmitHandler } from "react-hook-form";
import { AlertTriangle as AlertTriangleIcon } from "react-feather";

import { Post, PostFormValues } from "../../types/posts";
import { Platform } from "../../types/platforms";

import { getPlatformTypeName } from "../../libs/platform";
import {
  postToDefaultPostFormData,
  postFormDataToCreatePostProps,
} from "../../libs/post";
import { useActiveWorkspaceTimeZone, useShowModal } from "../../libs/hooks/app";
import { useUpdatePost } from "../../libs/hooks/posts";
import { useSnackBarFactory } from "../../libs/hooks/general";

import PostForm from "../forms/Post";

import Modal from "../../components/Modal";
import H2 from "../../components/headings/H2";
import PrimaryButton from "../../components/buttons/Primary";
import UpdatedPostSnackBarContent from "../../components/snackBarContent/UpdatedPost";

interface EditPostModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  post: Post;
  platform: Platform;
  close: () => void;
}

const EditPostModal: React.FC<EditPostModalProps> = ({
  post,
  platform,
  isVisible,
  close,
  ...modalProps
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const showModal = useShowModal();
  const timeZone = useActiveWorkspaceTimeZone();
  const createSnackBar = useSnackBarFactory();
  const {
    mutateAsync: updatePost,
    isLoading: isUpdatingPost,
    errorAlert: updatePostErrorAlert,
    loadingText: updatePostLoadingText,
  } = useUpdatePost();

  const onSubmit: SubmitHandler<PostFormValues> = async (data) => {
    const [createProps] = postFormDataToCreatePostProps(data as PostFormValues);
    const updateProps: Parameters<typeof updatePost>[0]["updateProps"] = {};

    if (post.status !== createProps.status) {
      updateProps.status = createProps.status;
    }

    if (!equal(post.text, createProps.text)) {
      updateProps.text = createProps.text;
    }

    if (!equal(post.attachment, createProps.attachment)) {
      updateProps.attachment = createProps.attachment || null;
    }

    if (createProps.status === "DRAFT" || createProps.status === "SCHEDULED") {
      if (post.scheduled !== createProps.scheduled) {
        updateProps.scheduled = createProps.scheduled || null;
      }
    } else if (
      createProps.status === "QUEUED" ||
      createProps.status === "QUEUEDDRAFT"
    ) {
      if (post.shouldRecycle !== createProps.shouldRecycle) {
        updateProps.shouldRecycle = createProps.shouldRecycle || null;
      }
    }

    if (Object.keys(updateProps).length > 0) {
      await updatePost({ post, updateProps });
      createSnackBar({
        content: <UpdatedPostSnackBarContent platformType={platform.type} />,
      });
    }

    close();
  };

  return (
    <Modal
      {...modalProps}
      isVisible={isVisible}
      close={() => {
        if (isDirty) {
          showModal("confirmClose", {
            onConfirm: close,
          });
          // Return false to prevent this modal from being
          // removed from the open/close stack.
          return false;
        } else {
          close();
        }
      }}
    >
      <div className="px-2 pb-2 pt-1 sm:px-8 sm:pb-8 sm:pt-6">
        <PostForm
          className="mt-2"
          allowedSocials={[]}
          timeZone={timeZone}
          defaultValues={postToDefaultPostFormData(platform, post)}
          onSubmit={onSubmit}
          onDirtyChange={setIsDirty}
          header={
            <>
              <H2 className="w-full text-2xl text-black text-center">
                Edit post
              </H2>
              {post.status === "DRAFT" && (
                <p className="mt-2 p-2 flex items-center text-sm rounded-lg bg-gray-100">
                  <AlertTriangleIcon className="mr-2 h-6 w-6 flex-shrink-0" />
                  This post won't be published on{" "}
                  {getPlatformTypeName(post.type)} until it is set to "Ready for
                  publishing"
                </p>
              )}
            </>
          }
          footer={() => {
            return (
              <>
                {updatePostErrorAlert({ className: "mt-8" })}
                <PrimaryButton
                  type="submit"
                  className="mt-8 w-full"
                  disabled={isUpdatingPost}
                >
                  {updatePostLoadingText({
                    default: "Save",
                    loading: "Saving...",
                  })}
                </PrimaryButton>
              </>
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default EditPostModal;
