import React from "react";

import { useCurrentUser } from "../../libs/hooks/app";

import DropdownMenu from "../../components/menus/Dropdown";
import FloatButton from "../../components/buttons/Float";
import UserProfilePicture from "../../components/images/profile/User";

import Home from "./user/Home";
import SwitchWorkspace from "./user/SwitchWorkspace";
import ContentStack from "../../components/animations/ContentStack";

interface UserMenuProps {
  className?: string;
}

const UserMenu: React.FC<UserMenuProps> = ({ className = "" }) => {
  const user = useCurrentUser();

  return (
    <DropdownMenu className={className} xAlign="right">
      <FloatButton className="px-2 py-1">
        <UserProfilePicture className="w-9 h-9" user={user} />
      </FloatButton>
      <div className="w-80">
        <ContentStack defaultContentId="home">
          <Home contentId="home" />
          <SwitchWorkspace contentId="switchWorkspace" />
        </ContentStack>
      </div>
    </DropdownMenu>
  );
};

export default UserMenu;
