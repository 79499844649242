import React from "react";

import { MetricPropertyNames } from "../../../types/postAnalytics";

import { mergeClassNames } from "../../../libs/components";

import CardLoading from "../../../components/analytics/overview/CardLoading";

interface AnalyticsBodyOverviewLoadingProps {
  metricPropertyNames: MetricPropertyNames;
  className?: string;
}
const AnalyticsBodyOverviewLoading: React.FC<
  AnalyticsBodyOverviewLoadingProps
> = ({ metricPropertyNames, className = "" }) => {
  return (
    <div
      className={mergeClassNames(
        "flex items-center flex-wrap gap-4",
        className
      )}
    >
      {metricPropertyNames.map((metricPropertyName) => {
        return <CardLoading key={metricPropertyName} />;
      })}
    </div>
  );
};

export default AnalyticsBodyOverviewLoading;
