import React from "react";
import { Settings } from "react-feather";

import {
  useCurrentUser,
  useCurrentUserTimeZone,
  useActiveWorkspace,
} from "../../libs/hooks/app";
import {
  useUpdateWorkspaceUserPreferences,
  useWorkspaceUserPreferences,
} from "../../libs/hooks/userPreferences";

import DropdownMenu from "../../components/menus/Dropdown";
import H4 from "../../components/headings/H4";
import IconButton from "../../components/buttons/Icon";
import TimeZoneSelect from "../../components/form/select/TimeZone";
import Select from "../../components/form/Select";

const LABEL_WIDTH = 120;
const INPUT_WIDTH = 280;
const DAYS_PER_WEEK_OPTIONS = [
  { value: "5", text: "5" },
  { value: "7", text: "7" },
];
const DAY_STARTS_AT_OPTIONS = [
  { value: "0", text: "12:00 am" },
  { value: "1", text: "1:00 am" },
  { value: "2", text: "2:00 am" },
  { value: "3", text: "3:00 am" },
  { value: "4", text: "4:00 am" },
  { value: "5", text: "5:00 am" },
  { value: "6", text: "6:00 am" },
  { value: "7", text: "7:00 am" },
  { value: "8", text: "8:00 am" },
  { value: "9", text: "9:00 am" },
  { value: "10", text: "10:00 am" },
  { value: "11", text: "11:00 am" },
  { value: "12", text: "12:00 pm" },
];
const SHOW_HOURS_OPTIONS = [
  { value: "6", text: "6" },
  { value: "7", text: "7" },
  { value: "8", text: "8" },
  { value: "9", text: "9" },
  { value: "10", text: "10" },
  { value: "11", text: "11" },
  { value: "12", text: "12" },
  { value: "13", text: "13" },
  { value: "14", text: "14" },
  { value: "15", text: "15" },
  { value: "16", text: "16" },
  { value: "17", text: "17" },
  { value: "18", text: "18" },
  { value: "19", text: "19" },
  { value: "20", text: "20" },
  { value: "21", text: "21" },
  { value: "22", text: "22" },
  { value: "23", text: "23" },
  { value: "25", text: "24" },
];

interface CalendarSettingsMenuProps {
  className?: string;
}

const CalendarSettingsMenu: React.FC<CalendarSettingsMenuProps> = ({
  className = "",
}) => {
  const user = useCurrentUser();
  const activeWorkspace = useActiveWorkspace();
  const userTimeZone = useCurrentUserTimeZone();
  const { data: workspacePreferences } = useWorkspaceUserPreferences(
    user.id,
    activeWorkspace.id
  );
  const { mutate: updateWorkspaceUserPreferences } =
    useUpdateWorkspaceUserPreferences();
  const workspaceTimeZone =
    workspacePreferences && workspacePreferences.timeZone
      ? workspacePreferences.timeZone
      : "";
  const daysPerWeek =
    workspacePreferences && workspacePreferences.calendarDaysPerWeek
      ? "" + workspacePreferences.calendarDaysPerWeek
      : undefined;
  const dayStartsAt =
    workspacePreferences && workspacePreferences.calendarDayStartsAt
      ? "" + workspacePreferences.calendarDayStartsAt
      : undefined;
  const showHours =
    workspacePreferences && workspacePreferences.calendarShowHours
      ? "" + workspacePreferences.calendarShowHours
      : undefined;

  return (
    <DropdownMenu
      className={`h-full flex items-center ${className}`}
      xAlign="right"
      closeOnSelect={false}
    >
      <IconButton className="from-gray-200 to-gray-200 text-gray-600">
        <Settings />
      </IconButton>
      <div className="flex flex-col p-4">
        <H4>Calendar settings</H4>
        <div className="mt-4 flex items-center">
          <div
            className="flex-shrink-0 text-right"
            style={{ width: LABEL_WIDTH }}
          >
            Time zone:
          </div>
          <div style={{ maxWidth: INPUT_WIDTH }}>
            <TimeZoneSelect
              dropdownClassName="ml-2"
              dropdownXAlign="right"
              highlightOptions={[
                { value: "", text: `User profile (${userTimeZone})` },
              ]}
              value={workspaceTimeZone}
              onChange={(newTimeZone) => {
                updateWorkspaceUserPreferences({
                  userId: user.id,
                  workspaceId: activeWorkspace.id,
                  updateProps: {
                    timeZone: newTimeZone === "" ? null : newTimeZone,
                  },
                });
              }}
              hideLabel={true}
            />
          </div>
        </div>
        <div className="mt-2 flex items-center">
          <div className="text-right" style={{ width: LABEL_WIDTH }}>
            Days per week:
          </div>
          <div style={{ maxWidth: INPUT_WIDTH }}>
            <Select
              dropdownClassName="ml-2"
              dropdownXAlign="right"
              value={daysPerWeek}
              options={DAYS_PER_WEEK_OPTIONS}
              onChange={(newVal) => {
                if (newVal !== daysPerWeek) {
                  const newDaysPerWeek = parseInt(newVal, 10);
                  updateWorkspaceUserPreferences({
                    userId: user.id,
                    workspaceId: activeWorkspace.id,
                    updateProps: {
                      calendarDaysPerWeek: newDaysPerWeek,
                    },
                  });
                }
              }}
              labelText="Days per week"
              hideLabel={true}
            />
          </div>
        </div>
        <div className="mt-2 flex items-center">
          <div className="text-right" style={{ width: LABEL_WIDTH }}>
            Day starts at:
          </div>
          <div style={{ maxWidth: INPUT_WIDTH }}>
            <Select
              dropdownClassName="ml-2"
              dropdownXAlign="right"
              value={dayStartsAt}
              options={DAY_STARTS_AT_OPTIONS}
              onChange={(newVal) => {
                if (newVal !== dayStartsAt) {
                  const newDayStartsAt = parseInt(newVal, 10);
                  updateWorkspaceUserPreferences({
                    userId: user.id,
                    workspaceId: activeWorkspace.id,
                    updateProps: {
                      calendarDayStartsAt: newDayStartsAt,
                    },
                  });
                }
              }}
              labelText="Day starts at"
              hideLabel={true}
            />
          </div>
        </div>
        <div className="mt-2 flex items-center">
          <div className="text-right" style={{ width: LABEL_WIDTH }}>
            Show:
          </div>
          <div className="flex items-center" style={{ maxWidth: INPUT_WIDTH }}>
            <Select
              dropdownClassName="ml-2"
              dropdownXAlign="right"
              value={showHours}
              options={SHOW_HOURS_OPTIONS}
              onChange={(newVal) => {
                if (newVal !== showHours) {
                  const newShowHours = parseInt(newVal, 10);
                  updateWorkspaceUserPreferences({
                    userId: user.id,
                    workspaceId: activeWorkspace.id,
                    updateProps: {
                      calendarShowHours: newShowHours,
                    },
                  });
                }
              }}
              labelText="Show hours"
              hideLabel={true}
            />
            <div className="ml-2">hours at a time</div>
          </div>
        </div>
      </div>
    </DropdownMenu>
  );
};

export default CalendarSettingsMenu;
