import React from "react";
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Linkedin as LinkedInIcon,
} from "react-feather";

import { mergeClassNames } from "../../libs/components";

interface SocialsIconIconProps {
  fill?: boolean;
  fillColour?: string;
  className?: string;
}
const SocialsIcon: React.FC<SocialsIconIconProps> = ({
  fill = false,
  className = "",
}) => {
  return (
    <div className={mergeClassNames("relative h-6 w-6", className)}>
      <LinkedInIcon
        className="absolute top-0 left-1/2 -translate-x-1/2 w-1/2 h-1/2"
        fill={fill ? "currentColor" : "none"}
        fillOpacity={0.4}
      />
      <FacebookIcon
        className="absolute bottom-0 right-0 w-1/2 h-1/2"
        fill={fill ? "currentColor" : "none"}
        fillOpacity={0.4}
      />
      <TwitterIcon
        className="absolute bottom-0 left-0 w-1/2 h-1/2"
        fill={fill ? "currentColor" : "none"}
        fillOpacity={0.4}
      />
    </div>
  );
};

export default SocialsIcon;
