import React from "react";
import { Play as PlayIcon } from "react-feather";

import { VideoAttachment } from "../../../../types/posts";
import { InternalFileVideo } from "../../../../types/files";

import { mergeClassNames } from "../../../../libs/components";
import { useFile } from "../../../../libs/hooks/files";

import InternalVideoPreviewImage from "../../../images/InternalVideoPreview";
import { clamp } from "../../../../libs/utils";

interface FacebookPostPreviewVideoProps {
  videoAttachment: VideoAttachment;
  maxHeight: number;
  className?: string;
}
const FacebookPostPreviewVideo: React.FC<FacebookPostPreviewVideoProps> = ({
  videoAttachment,
  maxHeight,
  className = "",
}) => {
  const { data: videoFile } = useFile<InternalFileVideo>(
    videoAttachment.fileId
  );

  if (!videoFile || !videoFile.preview) {
    return null;
  }

  const videoHeight = videoFile.height || 0;
  const videoWidth = videoFile.width || 0;
  const isVertical = videoHeight > videoWidth;
  const clampedHeight = isVertical
    ? clamp(videoHeight, 0, maxHeight)
    : undefined;

  return (
    <div
      className={mergeClassNames("overflow-hidden relative w-full", className)}
      style={{ maxHeight: maxHeight, height: clampedHeight }}
    >
      <InternalVideoPreviewImage
        className="w-full"
        file={videoFile}
        showInvalidPlatforms={false}
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="h-20 w-20 rounded-full border-4 border-white text-white bg-gray-900 bg-opacity-40 flex items-center justify-center">
          <PlayIcon className="ml-2 h-10 w-10" fill="currentColor" />
        </div>
      </div>
    </div>
  );
};

export default FacebookPostPreviewVideo;
