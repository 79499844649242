import { DateTime } from "luxon";
import React from "react";
import { Clock as ClockIcon } from "react-feather";

import { PostWithScheduledProp } from "../../types/posts";
import { Platform } from "../../types/platforms";

import { useActiveWorkspaceTimeZone } from "../../libs/hooks/app";
import { getPlatformTypeName } from "../../libs/platform";

import PlatformIcon from "../../components/icons/Platform";
import SmartScheduleIcon from "../../components/icons/SmartSchedule";
import PlatformEntityProfilePicture from "../../components/images/profile/PlatformEntity";

interface CalendarQueuedPostProps {
  post: PostWithScheduledProp;
  platform: Platform;
  height: number;
  width: number;
}

const DISPLAY_TIME_FORMAT = "EEE, h:mma";

const CalendarQueuedPost: React.FC<CalendarQueuedPostProps> = ({
  post,
  platform,
  height,
  width,
}) => {
  const workspaceTimeZone = useActiveWorkspaceTimeZone();
  const scheduledDateTime = DateTime.fromISO(post.scheduled).setZone(
    workspaceTimeZone
  );
  const platformEntity = platform.entities[post.platformEntityId];

  return (
    <div
      className="px-2 py-1 relative flex flex-col rounded-lg border border-gray-200 bg-gradient-to-r from-purple-100 to-blue-100"
      style={{ height, width }}
    >
      <div className="flex-shrink-0 flex items-center text-purple-500">
        <SmartScheduleIcon className="h-4 w-4" />
        <span className="ml-1 text-xs">Smart schedule</span>
        <PlatformIcon
          className="ml-auto flex-shrink-0 h-4 w-4"
          type={platform.type}
          colour={true}
        />
      </div>
      <div className="flex-grow flex items-center">
        <PlatformEntityProfilePicture
          className="flex-shrink-0 h-10 w-10"
          entity={platformEntity}
        />
        <div className="ml-1 font-bold truncate">
          <div className="truncate leading-4">{platformEntity.name}</div>
          <div className="flex items-center text-xs text-gray-500 leading-4">
            {getPlatformTypeName(platform.type).toUpperCase()}
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 flex items-center text-xs">
        <div className="ml-auto flex-shrink-0 flex items-center">
          <ClockIcon className="h-3.5 w-3.5" />
          <span className="ml-1 text-xs">
            {`Projected for ${scheduledDateTime.toFormat(DISPLAY_TIME_FORMAT)}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CalendarQueuedPost;
