import React, { useState } from "react";
import {
  Circle as CircleIcon,
  CheckCircle as CheckCircleIcon,
  Check as CheckIcon,
} from "react-feather";

import config from "../../../config";
import { useContentStack } from "../../../libs/hooks/general";

import H2 from "../../../components/headings/H2";
import PrimaryButton from "../../../components/buttons/Primary";

const PLANS = [config.prices.FREE, config.prices.PUBLISH_MONTHLY];

interface ChangeSubscriptionModalPlansProps {
  contentId: "plans";
  currentPriceId?: string;
  defaultPriceId?: string;
  onChange: (newPriceId: string) => void;
}
const ChangeSubscriptionModalPlans: React.FC<
  ChangeSubscriptionModalPlansProps
> = ({ currentPriceId, defaultPriceId, onChange }) => {
  const normalisedCurrentPriceId = currentPriceId || null;
  const normalisedDefaultPriceId = defaultPriceId || null;
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(
    normalisedDefaultPriceId || normalisedCurrentPriceId
  );
  const { push } = useContentStack();
  const hasChanged = selectedPriceId !== normalisedCurrentPriceId;
  const currentIndex = PLANS.findIndex(
    (plan) => plan.id === normalisedCurrentPriceId
  );
  const newIndex = PLANS.findIndex((plan) => plan.id === selectedPriceId);
  const displayHeader = hasChanged
    ? currentIndex < newIndex
      ? `Upgrade to ${PLANS[newIndex].displayName}`
      : `Downgrade to ${PLANS[newIndex].displayName}`
    : `Stay on ${PLANS[currentIndex].displayName}`;

  return (
    <div className="p-8">
      <H2 className="flex-shrink-0 text-2xl text-black w-full">
        {displayHeader}
      </H2>
      <div className="mt-8 flex">
        {PLANS.map((plan, index) => {
          const isCurrent = plan.id === normalisedCurrentPriceId;
          const isSelected = plan.id === selectedPriceId;

          return (
            <button
              key={index}
              className={`p-4 w-96 flex flex-col rounded-lg default-transition  ${
                index > 0 ? "ml-4" : ""
              } ${
                isSelected
                  ? "border-2 border-purple-500"
                  : "border-2 border-gray-200"
              } ${
                isCurrent
                  ? "cursor-not-allowed"
                  : "hover:bg-purple-50 hover:border-purple-500 focus:border-purple-500"
              }`}
              type="button"
              onClick={() => setSelectedPriceId(plan.id)}
              disabled={isCurrent}
            >
              <div className="w-full flex items-center">
                <h3 className="font-bold text-lg">{plan.displayName}</h3>
                {isSelected ? (
                  <CheckCircleIcon className="ml-auto w-6 h-6 text-purple-500" />
                ) : (
                  <CircleIcon className="ml-auto w-6 h-6 text-gray-200" />
                )}
              </div>
              <p className="mt-4 h-16 text-left text-sm">{plan.description}</p>
              <p className="mt-2 h-10 flex items-center">
                <span className="font-bold text-2xl">$</span>
                <span className="font-bold text-4xl">
                  {Math.ceil(plan.unitAmount / 100)}
                </span>
              </p>
              <p className="text-sm text-gray-500">{`${plan.currency.toUpperCase()} per month`}</p>
              <h4 className="mt-8 font-bold">Features</h4>
              <ul className="mt-2 w-full text-left">
                {plan.features.map((feature, index) => {
                  return (
                    <li key={index} className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4 text-purple-500" />{" "}
                      {feature}
                    </li>
                  );
                })}
              </ul>
              <div className="mt-auto pt-2 w-full h-7">
                {isCurrent && (
                  <span className="mx-auto px-2 py-1 rounded bg-purple-100 text-purple-700">
                    Current subscription
                  </span>
                )}
              </div>
            </button>
          );
        })}
      </div>
      <div className="mt-8 flex justify-end">
        <PrimaryButton
          onClick={() => {
            if (selectedPriceId) {
              onChange(selectedPriceId);
              push("payment");
            } else {
              // TODO: Handle plan downgrades to free here?
            }
          }}
          disabled={!hasChanged}
        >
          Continue to payment method
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ChangeSubscriptionModalPlans;
