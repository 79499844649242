import React, { useMemo, useState } from "react";

import { WorkspaceAnalytics } from "../types/workspaceAnalytics";

import { hasActiveSubscription } from "../libs/subscriptions";
import { getMetricsForPlatformType } from "../libs/workspaceAnalytics";
import { useActiveWorkspace } from "../libs/hooks/app";
import { useWorkspaceAnalytics } from "../libs/hooks/workspaceAnalytics";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import AnalyticsHeader from "../containers/analytics/Header";
import AnalyticsBody from "../containers/analytics/Body";
import AnalyticsLoading from "../containers/analytics/Loading";
import AnalyticsEmpty from "../containers/analytics/Empty";
import AnalyticsNoSubscription from "../containers/analytics/NoSubscription";

const AnalyticsPage: React.FC = () => {
  const [period, setPeriod] =
    useState<WorkspaceAnalytics["period"]>("LAST_7_DAYS");
  const [platformType, setPlatformType] =
    useState<WorkspaceAnalytics["type"]>("LINKEDIN");
  const workspace = useActiveWorkspace();
  const activeSubscription = hasActiveSubscription(workspace);
  const { data: workspaceAnalytics, isLoading } = useWorkspaceAnalytics(
    workspace.id,
    period,
    platformType,
    { enabled: activeSubscription }
  );
  const metricPropertyNames = useMemo(() => {
    return getMetricsForPlatformType(platformType);
  }, [platformType]);

  return (
    <AppPageWithHeader>
      {activeSubscription && (
        <div className="p-4">
          <AnalyticsHeader
            period={period}
            platformType={platformType}
            onPeriodChange={setPeriod}
            onPlatformTypeChange={setPlatformType}
          />
          {isLoading && (
            <AnalyticsLoading
              className="mt-4 mx-auto"
              metricPropertyNames={metricPropertyNames}
              platformType={platformType}
            />
          )}
          {!isLoading && !!workspaceAnalytics && (
            <AnalyticsBody
              className="mt-4 mx-auto"
              metricPropertyNames={metricPropertyNames}
              analytics={workspaceAnalytics}
            />
          )}
          {!isLoading && !workspaceAnalytics && (
            <AnalyticsEmpty className="mt-4 mx-auto" />
          )}
        </div>
      )}
      {!activeSubscription && <AnalyticsNoSubscription className="mt-10" />}
    </AppPageWithHeader>
  );
};

export default AnalyticsPage;
