import React from "react";

export interface ContentSwitchContent {
  id: string;
  data?: any;
  immediate?: boolean;
}

export interface ContentSwitchContextProps<
  T extends ContentSwitchContent = ContentSwitchContent
> {
  prevContent: T | null;
  content: T;
  showContent: (newContent: T) => void;
}

const ContentSwitchContext = React.createContext<ContentSwitchContextProps>({
  prevContent: null,
  content: { id: "home", immediate: true },
  showContent: () => {},
});
export default ContentSwitchContext;
