import React, { useEffect, useState } from "react";
import { Image as ImageIcon } from "react-feather";

import { LinkAttachment } from "../../../../types/posts";
import { mergeClassNames } from "../../../../libs/components";
import { getImageDimensions } from "../../../../libs/images";
import { clamp } from "../../../../libs/utils";

// Images less than 200px wide will be shown as small thumbnails on
// the article:
// https://www.linkedin.com/help/linkedin/answer/a566445#:~:text=Use%20a%201.91%3A1%20ratio,left%20side%20of%20the%20post.
const SMALL_THUMBNAIL_WIDTH_CUTOFF = 200;
const SMALL_THUMBNAIL_WIDTH = 138;
const SMALL_THUMBNAIL_HEIGHT = 72;
const COLOURS = {
  BLACK: "rgba(0, 0, 0, 0.9)",
  BACKGROUND_GRAY: "rgb(238, 243, 248)",
  GRAY: "rgba(0, 0, 0, 0.6)",
};

interface LinkedInPostPreviewLinkProps {
  linkAttachment: LinkAttachment;
  className?: string;
}
const LinkedInPostPreviewLink: React.FC<LinkedInPostPreviewLinkProps> = ({
  linkAttachment,
  className = "",
}) => {
  const thumbnail = linkAttachment.thumbnail;
  const thumbnailUrl = thumbnail ? thumbnail.url : null;
  const showContents =
    !!thumbnail || !!linkAttachment.title || !!linkAttachment.domainName;
  const [isLoading, setIsLoading] = useState(false);
  const [paddingBottom, setPaddingBottom] = useState(50);
  const [isSmallThumbnail, setIsSmallThumbnail] = useState(
    thumbnail &&
      thumbnail.width &&
      thumbnail.width <= SMALL_THUMBNAIL_WIDTH_CUTOFF
  );

  useEffect(() => {
    if (!!thumbnail && !isLoading) {
      const calculatePaddingBottom = async () => {
        let height = thumbnail.height || 0;
        let width = thumbnail.width || 0;

        if (!height || !width) {
          [height, width] = await getImageDimensions(thumbnail.url);
        }

        setPaddingBottom(clamp((height / width) * 100, 50, 100));
        setIsSmallThumbnail(width <= SMALL_THUMBNAIL_WIDTH_CUTOFF);
        setIsLoading(false);
      };

      setIsLoading(true);
      calculatePaddingBottom();
    }
  }, [isLoading, thumbnail, thumbnailUrl]);

  if (!showContents) {
    return null;
  }

  return (
    <div
      className={mergeClassNames(
        `overflow-hidden relative w-full font-linkedIn ${
          isSmallThumbnail ? "flex items-center" : ""
        }`,
        className
      )}
      style={{ backgroundColor: COLOURS.BACKGROUND_GRAY }}
    >
      <div
        className={`relative bg-white ${isSmallThumbnail ? "ml-3 my-2" : ""}`}
        style={
          isSmallThumbnail
            ? { width: SMALL_THUMBNAIL_WIDTH, height: SMALL_THUMBNAIL_HEIGHT }
            : { paddingBottom: `${paddingBottom}%` }
        }
      >
        {thumbnail ? (
          <img
            className="absolute w-full h-full object-cover"
            src={thumbnail.url}
            alt=""
          />
        ) : (
          <div className="absolute w-full h-full flex items-center justify-center">
            <ImageIcon className="w-12 h-12" />
          </div>
        )}
      </div>

      <div className="px-3 py-2 overflow-hidden">
        <div
          className="text-sm text-ellipsis font-semibold overflow-hidden max-h-10 break-words"
          style={{
            color: COLOURS.BLACK,
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
          }}
        >
          {linkAttachment.title}
        </div>
        <div
          className="mt-2 text-xs truncate text-ellipsis"
          style={{
            color: COLOURS.GRAY,
          }}
        >
          {`${linkAttachment.domainName} • 5 min read`}
        </div>
      </div>
    </div>
  );
};

export default LinkedInPostPreviewLink;
