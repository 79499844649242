import React from "react";
import { Star as StarIcon } from "react-feather";

import config from "../../../config";
import { Workspace } from "../../../types/workspaces";

import { useShowModal } from "../../../libs/hooks/app";

import SubscriptionStatusBadge from "../../../components/badges/SubscriptionStatus";
import PrimaryTextButton from "../../../components/buttons/PrimaryText";

interface BillingWorkspacesSectionProps {
  workspaces: Workspace[];
  className?: string;
}

const BillingWorkspacesSection: React.FC<BillingWorkspacesSectionProps> = ({
  workspaces,
  className = "",
}) => {
  const showModal = useShowModal();
  const priceConfigs = Object.values(config.prices);

  return (
    <div className={className}>
      {workspaces.map((workspace) => {
        const subscription = workspace.subscription;
        const offerFreeTrial = !subscription && !workspace.hasHadTrial;
        const remotePriceId = subscription ? subscription.price.remoteId : null;
        const priceConfig = priceConfigs.find((candidate) => {
          return candidate.id === remotePriceId;
        });
        const subscriptionName = priceConfig
          ? priceConfig.displayName
          : "Unknown";
        const displayPrice = subscription
          ? Math.floor(subscription.price.unitAmount / 100)
          : 0;
        const displayCurrency = subscription
          ? subscription.price.currency.toUpperCase()
          : "USD";
        const displayPerPeriod = subscription
          ? subscription.price.period === "MONTHLY"
            ? "month"
            : "year"
          : "month";

        return (
          <div key={workspace.id} className="py-2 flex items-center">
            <div className="flex-grow flex items-center truncate">
              <div className="text-xl font-bold truncate">{workspace.name}</div>
              {offerFreeTrial && (
                <PrimaryTextButton
                  className="m-2 flex-shrink-0"
                  size="sm"
                  onClick={() => {
                    showModal("changeSubscription", {
                      workspace,
                      redirectOnSuccess: false,
                    });
                  }}
                >
                  <StarIcon className="w-5 h-5" />
                  <span className="ml-2">Start free trial</span>
                </PrimaryTextButton>
              )}
            </div>
            <div className="flex-shrink-0 w-24 text-center">
              <SubscriptionStatusBadge
                status={subscription ? subscription.status : "active"}
              />
            </div>
            <div className="flex-shrink-0 w-20 leading-4">
              <div className="text-xs font-bold text-gray-400">PLAN</div>
              <div>{subscriptionName}</div>
            </div>
            <div className="flex-shrink-0 w-36 text-right">
              <span className="font-bold text-3xl text-purple-500">
                ${displayPrice}
              </span>
              <span className="ml-2 text-xs text-gray-500">{`${displayCurrency} / ${displayPerPeriod}`}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BillingWorkspacesSection;
