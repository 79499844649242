import React, { ReactNode, useMemo } from "react";
import { ContentState } from "draft-js";
import { nanoid } from "nanoid";
import {
  MessageCircle as MessageCircleIcon,
  Heart as HeartIcon,
  Repeat as RepeatIcon,
} from "react-feather";

import { Platform } from "../../types/platforms";
import { PostTwitter } from "../../types/posts";
import {
  FormPublishedPostActionTwitterLike,
  FormPublishedPostActionTwitterReply,
  FormPublishedPostActionTwitterRetweet,
} from "../../types/publishedPostActions";

import OutlineButton from "../../components/buttons/Outline";

import { twitterActionSchema } from "./publishedPostActions/schemas";
import FormContainer from "./publishedPostActions/base/FormContainer";
import TwitterLike from "./publishedPostActions/twitter/Like";
import TwitterReply from "./publishedPostActions/twitter/Reply";
import TwitterRetweet from "./publishedPostActions/twitter/Retweet";

type FormTwitterAction =
  | FormPublishedPostActionTwitterLike
  | FormPublishedPostActionTwitterReply
  | FormPublishedPostActionTwitterRetweet;

export type TwitterFormValues = {
  actions: Array<FormTwitterAction>;
};

interface TwitterPublishedPostActionsFormProps {
  post: PostTwitter;
  platforms: Platform[];
  defaultValues: TwitterFormValues;
  onSubmit: (data: TwitterFormValues) => void;
  isSubmitting: boolean;
  endOfFormElement?: ReactNode;
  onIsDirty?: (newIsDirty: boolean) => void;
  className?: string;
}
const TwitterPublishedPostActionsForm: React.FC<
  TwitterPublishedPostActionsFormProps
> = ({
  post,
  platforms,
  defaultValues,
  onSubmit,
  isSubmitting,
  endOfFormElement,
  onIsDirty,
  className = "",
}) => {
  const twitterPlatforms = useMemo(() => {
    return platforms.filter((candidate) => candidate.type === "TWITTER");
  }, [platforms]);

  return (
    <FormContainer
      className={className}
      onSubmit={onSubmit}
      onIsDirty={onIsDirty}
      isSubmitting={isSubmitting}
      actionSchema={twitterActionSchema}
      defaultValues={defaultValues}
      endOfFormElement={endOfFormElement}
      renderActionButtons={(
        createAction: (newAction: Partial<FormTwitterAction>) => void
      ) => {
        return (
          <div className="mt-2 shrink-0 w-full flex items-stretch gap-2">
            <OutlineButton
              className="w-full flex items-center"
              onClick={() =>
                createAction({
                  id: nanoid(),
                  postId: post.id,
                  platformType: "TWITTER",
                  type: "REPLY",
                  contentState: ContentState.createFromText(""),
                })
              }
              disabled={isSubmitting}
            >
              <MessageCircleIcon className="h-6 w-6" />
              <span className="ml-2">Add reply</span>
            </OutlineButton>
            <OutlineButton
              className="w-full flex items-center"
              onClick={() =>
                createAction({
                  id: nanoid(),
                  postId: post.id,
                  platformType: "TWITTER",
                  type: "LIKE",
                })
              }
              disabled={isSubmitting}
            >
              <HeartIcon className="h-6 w-6" />
              <span className="ml-2">Add like</span>
            </OutlineButton>
            <OutlineButton
              className="w-full flex items-center"
              onClick={() =>
                createAction({
                  id: nanoid(),
                  postId: post.id,
                  platformType: "TWITTER",
                  type: "RETWEET",
                })
              }
              disabled={isSubmitting}
            >
              <RepeatIcon className="h-6 w-6" />
              <span className="ml-2">Add reshare</span>
            </OutlineButton>
          </div>
        );
      }}
      renderAction={(action: FormTwitterAction, onChange, onDelete, errors) => {
        switch (action.type) {
          case "LIKE":
            return (
              <TwitterLike
                platforms={twitterPlatforms}
                value={action}
                errors={errors}
                onChange={onChange}
                onDelete={onDelete}
              />
            );
          case "REPLY":
            return (
              <TwitterReply
                platforms={twitterPlatforms}
                value={action}
                errors={errors}
                onChange={onChange}
                onDelete={onDelete}
              />
            );
          case "RETWEET":
            return (
              <TwitterRetweet
                platforms={twitterPlatforms}
                value={action}
                errors={errors}
                onChange={onChange}
                onDelete={onDelete}
              />
            );
        }
      }}
    />
  );
};

export default TwitterPublishedPostActionsForm;
