import { DateTime } from "luxon";
import {
  Query,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreatePostProps,
  Post,
  PostWithScheduledProp,
} from "../../types/posts";
import { ApiResponse } from "../../types/general";

import { useDecoratedReactQuery } from "./general";
import { useApiClient } from "./app";
import {
  POSTS_QUERY_KEYS,
  POST_QUEUES_QUERY_KEYS,
  WORKSPACES_QUERY_KEYS,
} from "../queryKeys";
import {
  createQueryCacheObject,
  ObjectQueryKeys,
  optimisticallyUpdateQueryCacheObject,
  removeQueryCacheObject,
} from "../queryCache";
import { patchObject } from "../utils";

function sortByScheduled(a: Post, b: Post) {
  const aDateTime = a.scheduled ? DateTime.fromISO(a.scheduled).toUTC() : null;
  const bDateTime = b.scheduled ? DateTime.fromISO(b.scheduled).toUTC() : null;

  if (aDateTime === null && bDateTime !== null) {
    return 1;
  } else if (aDateTime !== null && bDateTime === null) {
    return -1;
  } else if (aDateTime === null && bDateTime === null) {
    return 0;
  } else if (aDateTime! < bDateTime!) {
    return -1;
  } else if (aDateTime! > bDateTime!) {
    return 1;
  } else {
    return 0;
  }
}

function buildPostWithScheduledPropQueryFilter(post: PostWithScheduledProp) {
  const postScheduled = DateTime.fromISO(post.scheduled).toUTC();
  return {
    queryKey: POSTS_QUERY_KEYS.list({
      type: "SCHEDULED",
      workspaceId: post.workspaceId,
    }),
    predicate: (query: Query) => {
      const [, , { from, to }] = query.queryKey as [
        string,
        string,
        { from: string; to: string }
      ];
      const toDateTime = DateTime.fromISO(to).toUTC();
      const fromDateTime = DateTime.fromISO(from).toUTC();
      return postScheduled > fromDateTime && postScheduled < toDateTime;
    },
  };
}

export const usePost = (postId: string) => {
  const apiClient = useApiClient();
  return useDecoratedReactQuery(
    useQuery(POSTS_QUERY_KEYS.detail(postId), async () => {
      const { data: response } = await apiClient.get<ApiResponse<Post>>(
        `/posts/${postId}`
      );

      return response.data;
    })
  );
};

export const usePostsWithScheduledProp = (
  workspaceId: string,
  from: string,
  to: string
) => {
  const apiClient = useApiClient();
  return useDecoratedReactQuery(
    useQuery(
      POSTS_QUERY_KEYS.list({ type: "SCHEDULED", workspaceId, from, to }),
      async () => {
        const { data: response } = await apiClient.get<
          ApiResponse<PostWithScheduledProp[]>
        >("/posts", {
          params: {
            type: "SCHEDULED",
            workspaceId,
            from,
            to,
          },
        });

        return response.data;
      }
    )
  );
};

export const useQueuedPosts = (workspaceId: string) => {
  const apiClient = useApiClient();
  return useDecoratedReactQuery(
    useQuery(
      POSTS_QUERY_KEYS.list({ type: "QUEUED", workspaceId }),
      async () => {
        const { data: response } = await apiClient.get<ApiResponse<Post[]>>(
          "/posts",
          {
            params: {
              type: "QUEUED",
              workspaceId,
            },
          }
        );

        return response.data;
      }
    )
  );
};

export const useCreatePost = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (props: CreatePostProps) => {
        const { data: response } = await apiClient.post<ApiResponse<Post>>(
          "/posts",
          props
        );

        return response.data;
      },
      {
        onSuccess: (post) => {
          const objectQueryKeys: ObjectQueryKeys = {
            detail: POSTS_QUERY_KEYS.detail(post.id),
            lists: [],
          };

          if (post.status === "QUEUED" || post.status === "QUEUEDDRAFT") {
            objectQueryKeys.lists!.push(
              POSTS_QUERY_KEYS.list({
                type: "QUEUED",
                workspaceId: post.workspaceId,
              })
            );
          }

          if (post.scheduled) {
            objectQueryKeys.lists!.push(
              buildPostWithScheduledPropQueryFilter(
                post as PostWithScheduledProp
              )
            );

            createQueryCacheObject<Post>(
              queryClient,
              objectQueryKeys,
              post.id,
              () => (oldPosts) => {
                const newPosts = oldPosts.concat(post);
                newPosts.sort(sortByScheduled);
                return newPosts;
              }
            );
          } else {
            createQueryCacheObject<Post>(
              queryClient,
              objectQueryKeys,
              post.id,
              post
            );
          }

          queryClient.invalidateQueries(POSTS_QUERY_KEYS.lists());
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(
            WORKSPACES_QUERY_KEYS.detail(post.workspaceId)
          );

          if (post.queueId) {
            queryClient.invalidateQueries(POST_QUEUES_QUERY_KEYS.lists());
          }
        },
      }
    ),
    () => ({ title: "Failed to create post" })
  );
};

export const useCreatePosts = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (props: CreatePostProps[]) => {
        const { data: response } = await apiClient.post<ApiResponse<Post[]>>(
          "/posts",
          props
        );

        return response.data;
      },
      {
        onSuccess: (posts) => {
          let shouldInvalidatePostQueues = false;

          queryClient.invalidateQueries(POSTS_QUERY_KEYS.all);
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          posts.forEach((post) => {
            const objectQueryKeys: ObjectQueryKeys = {
              detail: POSTS_QUERY_KEYS.detail(post.id),
              lists: [],
            };

            if (post.status === "QUEUED" || post.status === "QUEUEDDRAFT") {
              shouldInvalidatePostQueues = true;

              objectQueryKeys.lists!.push(
                POSTS_QUERY_KEYS.list({
                  type: "QUEUED",
                  workspaceId: post.workspaceId,
                })
              );
            }

            if (post.scheduled) {
              objectQueryKeys.lists!.push(
                buildPostWithScheduledPropQueryFilter(
                  post as PostWithScheduledProp
                )
              );

              createQueryCacheObject<Post>(
                queryClient,
                objectQueryKeys,
                post.id,
                () => (oldPosts) => {
                  const newPosts = oldPosts.concat(post);
                  newPosts.sort(sortByScheduled);
                  return newPosts;
                }
              );
            } else {
              createQueryCacheObject<Post>(
                queryClient,
                objectQueryKeys,
                post.id,
                post
              );
            }

            queryClient.invalidateQueries(
              WORKSPACES_QUERY_KEYS.detail(post.workspaceId)
            );
          });

          if (shouldInvalidatePostQueues) {
            queryClient.invalidateQueries(POST_QUEUES_QUERY_KEYS.lists());
          }
        },
      }
    ),
    () => ({ title: "Failed to create posts" })
  );
};

interface UpdatePostProps {
  post: Post;
  updateProps: {
    text?: Post["text"];
    scheduled?: Post["scheduled"] | null;
    attachment?: Post["attachment"] | null;
    status?: "DRAFT" | "SCHEDULED" | "QUEUED" | "QUEUEDDRAFT";
    shouldRecycle?: Post["shouldRecycle"] | null;
  };
  optimistic?: boolean;
}
export const useUpdatePost = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ post, updateProps }: UpdatePostProps) => {
        const { data: response } = await apiClient.patch<ApiResponse<Post>>(
          `/posts/${post.id}`,
          updateProps
        );

        return response.data;
      },
      {
        onMutate: async ({ post, updateProps, optimistic = false }) => {
          if (!optimistic) {
            return;
          }

          const newPost = patchObject(post, updateProps);
          const objectQueryKeys: ObjectQueryKeys = {
            detail: POSTS_QUERY_KEYS.detail(post.id),
            lists: [],
          };

          if (newPost.status === "QUEUED" || newPost.status === "QUEUEDDRAFT") {
            objectQueryKeys.lists!.push(
              POSTS_QUERY_KEYS.list({
                type: "QUEUED",
                workspaceId: newPost.workspaceId,
              })
            );
          }

          if (newPost.scheduled) {
            objectQueryKeys.lists!.push(
              buildPostWithScheduledPropQueryFilter(
                newPost as PostWithScheduledProp
              )
            );
          }

          const revert = await optimisticallyUpdateQueryCacheObject<Post>(
            queryClient,
            objectQueryKeys,
            post.id,
            () => (oldPosts, postIndex) => {
              let newPosts = oldPosts;

              if (postIndex >= 0) {
                newPosts = oldPosts.slice();
                newPosts[postIndex] = newPost;
              } else {
                newPosts = oldPosts.concat(newPost);
              }

              if (newPost.scheduled) {
                newPosts.sort(sortByScheduled);
              }

              return newPosts;
            }
          );

          return { revert };
        },
        onError: (error, props, context) => {
          if (!context) {
            return;
          }

          const { revert } = context as {
            revert: () => void;
          };

          revert();
        },
        onSettled: (success, error, { post }) => {
          queryClient.invalidateQueries(POSTS_QUERY_KEYS.detail(post.id));
          queryClient.invalidateQueries(POSTS_QUERY_KEYS.lists());
          queryClient.invalidateQueries(POST_QUEUES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(
            WORKSPACES_QUERY_KEYS.detail(post.workspaceId)
          );
        },
      }
    ),
    () => ({ title: "Failed to update post" })
  );
};

export const useDeletePost = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useDecoratedReactQuery(
    useMutation(
      async (post: Post) => {
        const { data: response } = await apiClient.delete<ApiResponse<null>>(
          `/posts/${post.id}`
        );

        return response.data;
      },
      {
        onSuccess: (data, post) => {
          removeQueryCacheObject(
            queryClient,
            {
              detail: POSTS_QUERY_KEYS.detail(post.id),
              lists: [POSTS_QUERY_KEYS.lists()],
            },
            post.id
          );

          queryClient.invalidateQueries(POSTS_QUERY_KEYS.detail(post.id));
          queryClient.invalidateQueries(POSTS_QUERY_KEYS.lists());
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(
            WORKSPACES_QUERY_KEYS.detail(post.workspaceId)
          );

          if (post.queueId) {
            queryClient.invalidateQueries(POST_QUEUES_QUERY_KEYS.lists());
          }
        },
      }
    ),
    () => ({ title: "Failed to create post" })
  );
};
