import React from "react";
import { DateTime } from "luxon";
import { CheckCircleIcon } from "@heroicons/react/outline";

import { useActiveWorkspaceTimeZone } from "../../libs/hooks/app";
import InternalTertiaryButtonLink from "../links/InternalTertiaryButton";

interface CreatedPostsSnackBarContentProps {
  count: number;
  scheduled?: string;
}
const CreatedPostsSnackBarContent: React.FC<
  CreatedPostsSnackBarContentProps
> = ({ count, scheduled }) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const scheduledDateTime = scheduled
    ? DateTime.fromISO(scheduled).setZone(timeZone)
    : null;

  return (
    <div className="flex items-center">
      <CheckCircleIcon className="flex-shrink-0 w-5 h-5" />
      <span className="ml-2">
        {`${count > 1 ? `${count} posts` : "Post"} created`}
      </span>
      {scheduledDateTime && (
        <InternalTertiaryButtonLink
          className="ml-auto py-1 px-2 text-black"
          to={`/calendar?day=${scheduledDateTime.toISODate()}`}
        >
          View
        </InternalTertiaryButtonLink>
      )}
    </div>
  );
};

export default CreatedPostsSnackBarContent;
