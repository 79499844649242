import React, { useMemo } from "react";

import { useCurrentUser } from "../libs/hooks/app";
import { useWorkspaces } from "../libs/hooks/workspaces";
import { usePaymentMethods } from "../libs/hooks/paymentMethods";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import InvoicesSection from "../containers/billing/sections/Invoices";
import PaymentMethodsSection from "../containers/billing/sections/PaymentMethods";
import WorkspacesSection from "../containers/billing/sections/Workspaces";

import LoaderIcon from "../components/icons/Loader";
import H2 from "../components/headings/H2";

const BillingPage: React.FC = () => {
  const user = useCurrentUser();
  const { isLoading: isLoadingPaymentMethods, data: paymentMethodsData } =
    usePaymentMethods(user.id);
  const paymentMethods = paymentMethodsData || [];
  const { isLoading: isLoadingWorkspaces, data: workspacesData } =
    useWorkspaces(user.id);
  const sortedWorkspaces = useMemo(() => {
    const workspaces = workspacesData ? workspacesData.workspaces.slice() : [];
    return workspaces.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [workspacesData]);
  const isLoading = isLoadingWorkspaces || isLoadingPaymentMethods;

  return (
    <AppPageWithHeader>
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          {isLoading && <LoaderIcon />}
        </div>
      )}
      {!isLoading && (
        <div
          className="pt-4 pb-16 px-4 mx-auto flex flex-col justify-center"
          style={{ width: 800 }}
        >
          <H2>Payment methods</H2>
          <PaymentMethodsSection
            className="mt-4"
            workspaces={sortedWorkspaces}
            paymentMethods={paymentMethods}
          />

          <H2 className="mt-20">Workspaces</H2>
          <WorkspacesSection className="mt-4" workspaces={sortedWorkspaces} />

          <H2 className="mt-20">Invoices</H2>
          <InvoicesSection className="mt-4" workspaces={sortedWorkspaces} />
        </div>
      )}
    </AppPageWithHeader>
  );
};

export default BillingPage;
