import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { InternalFile, InternalFileByType } from "../../types/files";
import { ApiResponse } from "../../types/general";
import { FILES_QUERY_KEYS, WORKSPACES_QUERY_KEYS } from "../queryKeys";
import {
  createQueryCacheObject,
  updateQueryCacheObject,
  removeQueryCacheObject,
} from "../queryCache";
import { useApiClient } from "./app";
import { useDecoratedReactQuery } from "./general";

interface CreateFileProps {
  owner: InternalFile["owner"];
  contentType: string;
  name: string;
}
export const useCreateFile = <T extends InternalFile = InternalFile>() => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ owner, contentType, name }: CreateFileProps) => {
        const bodyData: Record<string, unknown> = {
          type: owner.type,
          contentType,
          name,
        };

        if (owner.type === "WORKSPACE") {
          bodyData.workspaceId = owner.workspaceId;
        } else {
          bodyData.userId = owner.userId;
        }

        const { data: response } = await apiClient.post<
          ApiResponse<
            T,
            { postUrl: string; postFields: Record<string, string> }
          >
        >("/files", bodyData);

        return {
          file: response.data,
          postUrl: response.meta.postUrl,
          postFields: response.meta.postFields,
        };
      },
      {
        onSuccess: ({ file }) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: FILES_QUERY_KEYS.detail(file.id),
              lists:
                file.owner.type === "WORKSPACE"
                  ? [
                      FILES_QUERY_KEYS.list({
                        workspaceId: file.owner.workspaceId,
                      }),
                      FILES_QUERY_KEYS.list({
                        workspaceId: file.owner.workspaceId,
                        fileType: file.type,
                      }),
                    ]
                  : undefined,
            },
            file.id,
            file
          );

          // Refresh workspaces to make sure we get the updated storage values.
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.all);
        },
      }
    ),
    () => ({ title: "Failed to create file" })
  );
};

interface UpdateFileProps {
  file: InternalFile;
  updateProps: {
    name?: string;
  };
}
export const useUpdateFile = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ file, updateProps }: UpdateFileProps) => {
        const { data: response } = await apiClient.patch<
          ApiResponse<InternalFile>
        >(`/files/${file.id}`, updateProps);

        return {
          file: response.data,
        };
      },
      {
        onSuccess: ({ file }) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: FILES_QUERY_KEYS.detail(file.id),
              lists:
                file.owner.type === "WORKSPACE"
                  ? [
                      FILES_QUERY_KEYS.list({
                        workspaceId: file.owner.workspaceId,
                      }),
                      FILES_QUERY_KEYS.list({
                        workspaceId: file.owner.workspaceId,
                        fileType: file.type,
                      }),
                    ]
                  : undefined,
            },
            file.id,
            () => file
          );
        },
      }
    ),
    () => ({ title: "Failed to update file" })
  );
};

export const useDeleteFile = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (fileId: string) => {
        const { data: response } = await apiClient.delete<ApiResponse<null>>(
          `/files/${fileId}`
        );

        return response.data;
      },
      {
        onSuccess: (response, fileId) => {
          removeQueryCacheObject(
            queryClient,
            {
              detail: FILES_QUERY_KEYS.detail(fileId),
              lists: [FILES_QUERY_KEYS.lists()],
            },
            fileId
          );

          // Refresh workspaces to make sure we get the updated storage values.
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.all);
        },
      }
    ),
    () => ({ title: "Failed to delete file" })
  );
};

export const useFiles = (workspaceId: string) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useQuery(
    FILES_QUERY_KEYS.list({
      workspaceId,
    }),
    async () => {
      const { data: response } = await apiClient.get<
        ApiResponse<InternalFile[]>
      >("/files", {
        params: {
          workspaceId,
        },
      });

      return response.data;
    },
    {
      onSuccess: (files) => {
        files.forEach((file) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: FILES_QUERY_KEYS.detail(file.id),
            },
            file.id,
            file
          );
        });
      },
    }
  );
};

export const useFilesByType = <T extends InternalFile["type"]>(
  workspaceId: string,
  fileType: T
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useQuery(
    FILES_QUERY_KEYS.list({
      workspaceId,
      fileType,
    }),
    async () => {
      const { data: response } = await apiClient.get<
        ApiResponse<InternalFileByType<T>[]>
      >("/files", {
        params: {
          workspaceId,
          type: fileType,
        },
      });

      return response.data;
    },
    {
      onSuccess: (files) => {
        files.forEach((file) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: FILES_QUERY_KEYS.detail(file.id),
            },
            file.id,
            file
          );
        });
      },
    }
  );
};

export const useFile = <T extends InternalFile = InternalFile>(
  fileId: string,
  options: { enabled?: boolean; refetchInterval?: number | false } = {}
) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useQuery(
    FILES_QUERY_KEYS.detail(fileId),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<T>>(
        `/files/${fileId}`
      );

      return response.data;
    },
    {
      ...options,
      onSuccess: (file) => {
        updateQueryCacheObject(
          queryClient,
          {
            lists:
              file.owner.type === "WORKSPACE"
                ? [
                    FILES_QUERY_KEYS.list({
                      workspaceId: file.owner.workspaceId,
                    }),
                    FILES_QUERY_KEYS.list({
                      workspaceId: file.owner.workspaceId,
                      fileType: file.type,
                    }),
                  ]
                : undefined,
          },
          file.id,
          () => file
        );
      },
    }
  );
};

export const useGetFile = () => {
  const apiClient = useApiClient();
  return async (fileId: string) => {
    const { data: response } = await apiClient.get<ApiResponse<InternalFile>>(
      `/files/${fileId}`
    );

    return response.data;
  };
};

export const useInvalidateFilesQueries = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(FILES_QUERY_KEYS.all);
  };
};
