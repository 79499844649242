import React from "react";
import LoaderIcon from "../../../components/icons/Loader";

interface MultiPostPublishedPostActionsLoadingProps {
  contentId: "loading";
}
const MultiPostPublishedPostActionsLoading: React.FC<
  MultiPostPublishedPostActionsLoadingProps
> = () => {
  return (
    <div
      className="p-2 sm:p-8 flex items-center justify-center"
      style={{ width: "60rem", height: "34rem" }}
    >
      <div className="flex items-center">
        <LoaderIcon className="h-10 w-10" />
        <span className="ml-2 text-lg">Loading...</span>
      </div>
    </div>
  );
};

export default MultiPostPublishedPostActionsLoading;
