import React from "react";
import { Image as ImageIcon } from "react-feather";

import { LinkAttachment } from "../../../../types/posts";
import { mergeClassNames } from "../../../../libs/components";

const PADDING_BOTTOM = "52.2%";
const COLOURS = {
  BLACK: "rgb(5, 5, 5)",
  BACKGROUND_GRAY: "rgb(240, 242, 245)",
  BORDER_GRAY: "rgb(206, 208, 212)",
  BORDER_GRAY_2: "rgba(0, 0, 0, 0.1)",
  GRAY: "rgb(101, 103, 107)",
};

interface FacebookPostPreviewLinkProps {
  linkAttachment: LinkAttachment;
  className?: string;
}
const FacebookPostPreviewLink: React.FC<FacebookPostPreviewLinkProps> = ({
  linkAttachment,
  className = "",
}) => {
  const thumbnail = linkAttachment.thumbnail;
  const showContents =
    !!thumbnail || !!linkAttachment.title || !!linkAttachment.domainName;

  if (!showContents) {
    return null;
  }

  return (
    <div
      className={mergeClassNames(
        "overflow-hidden relative w-full font-facebook",
        className
      )}
      style={{ backgroundColor: COLOURS.BACKGROUND_GRAY }}
    >
      <div
        className="w-full relative"
        style={{
          paddingBottom: PADDING_BOTTOM,
          borderTop: `1px solid ${COLOURS.BORDER_GRAY_2}`,
          borderBottom: `1px solid ${COLOURS.BORDER_GRAY_2}`,
        }}
      >
        {thumbnail ? (
          <img
            className="absolute w-full h-full object-cover"
            src={thumbnail.url}
            alt=""
          />
        ) : (
          <div className="absolute w-full h-full flex items-center justify-center">
            <ImageIcon className="w-12 h-12" />
          </div>
        )}
        <div
          className="absolute rounded-full bg-white flex items-center justify-center"
          style={{
            height: 30,
            width: 30,
            right: 14,
            bottom: -14,
            border: `1px solid ${COLOURS.BORDER_GRAY_2}`,
          }}
        >
          <span className="font-bold italic text-lg">i</span>
        </div>
      </div>

      <div
        className="py-2 px-4 overflow-hidden"
        style={{
          borderBottom: `1px solid ${COLOURS.BORDER_GRAY}`,
        }}
      >
        <div
          className="text-xs truncate uppercase"
          style={{
            color: COLOURS.GRAY,
          }}
        >
          {linkAttachment.domainName}
        </div>
        <div
          className="mt-1 text-ellipsis font-semibold overflow-hidden break-words leading-5"
          style={{
            fontSize: "17px",
            color: COLOURS.BLACK,
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
          }}
        >
          {linkAttachment.title}
        </div>
        {linkAttachment.description && (
          <div
            className="mt-1 truncate"
            style={{
              color: COLOURS.GRAY,
            }}
          >
            {linkAttachment.description}
          </div>
        )}
      </div>
    </div>
  );
};

export default FacebookPostPreviewLink;
