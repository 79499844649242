import { PostMetricsPropertyBase } from "../types/posts";

export const ENGAGEMENT_LEVELS = {
  GREEN: 1,
  AMBER: 2,
  RED: 3,
} as const;

export const MANDATORY_METRIC_STATS = {
  LINKEDIN: ["commentCount", "likeCount"],
  FACEBOOK: [],
  TWITTER: ["retweetCount", "replyCount", "likeCount", "quoteCount"],
} as const;

export const OPTIONAL_METRIC_STATS = {
  LINKEDIN: ["clickCount", "impressionCount", "shareCount"],
  FACEBOOK: [
    "engagedUsers",
    "negativeFeedbackTotal",
    "negativeFeedbackHideClicks",
    "negativeFeedbackHideAllClicks",
    "negativeFeedbackReportSpamClicks",
    "negativeFeedbackUnlikePageClicks",
    "engagedFans",
    "reactionsTotal",
    "reactionsLike",
    "reactionsLove",
    "reactionsWow",
    "reactionsHaha",
    "reactionsSorry",
    "reactionsAnger",
    "impressions",
    "impressionsFans",
    "impressionsOrganic",
    "impressionsViral",
    "commentCount",
  ],
  TWITTER: ["impressionsCount", "urlLinkClicks", "userProfileClicks"],
} as const;

export const ALL_METRIC_STATS = {
  LINKEDIN: [
    ...MANDATORY_METRIC_STATS.LINKEDIN,
    ...OPTIONAL_METRIC_STATS.LINKEDIN,
  ],
  FACEBOOK: [
    ...MANDATORY_METRIC_STATS.FACEBOOK,
    ...OPTIONAL_METRIC_STATS.FACEBOOK,
  ],
  TWITTER: [
    ...MANDATORY_METRIC_STATS.TWITTER,
    ...OPTIONAL_METRIC_STATS.TWITTER,
  ],
};

export function getEngagementLevelSingleWordDescription(
  engagementLevel: NonNullable<PostMetricsPropertyBase["engagementLevel"]>
) {
  return engagementLevel === ENGAGEMENT_LEVELS.RED
    ? "Low"
    : engagementLevel === ENGAGEMENT_LEVELS.AMBER
    ? "Slowing"
    : "Growing";
}

export function getEngagementLevelShortDescription(
  engagementLevel: NonNullable<PostMetricsPropertyBase["engagementLevel"]>
) {
  return engagementLevel === ENGAGEMENT_LEVELS.RED
    ? "Low"
    : engagementLevel === ENGAGEMENT_LEVELS.AMBER
    ? "Stable or slowing"
    : "Good or growing";
}

export function getEngagementLevelDescription(
  engagementLevel: NonNullable<PostMetricsPropertyBase["engagementLevel"]>
) {
  const shortDesc = getEngagementLevelShortDescription(engagementLevel);
  const formattedShortDesc = `${shortDesc[0].toLowerCase()}${shortDesc.substring(
    1
  )}`;
  return `Engagement is ${formattedShortDesc}`;
}

export function getEngagementLevelColourName(
  engagementLevel: NonNullable<PostMetricsPropertyBase["engagementLevel"]>
) {
  return engagementLevel === ENGAGEMENT_LEVELS.RED
    ? "Red"
    : engagementLevel === ENGAGEMENT_LEVELS.AMBER
    ? "Amber"
    : "Green";
}

export function formatCountForDisplay(count: number) {
  if (count >= 1000) {
    const thousands = Math.floor(count / 1000);
    const remainder = count % 1000;
    const hundreds = remainder > 0 ? Math.floor(remainder / 100) : 0;

    return `${thousands}${hundreds > 0 ? `.${hundreds}` : ""}k`;
  } else {
    return "" + count;
  }
}
