import React, { useEffect } from "react";
import Joi from "joi";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  useCurrentUser,
  useIsEmailVerified,
  useShowModal,
} from "../../../libs/hooks/app";
import { useUpdateUser } from "../../../libs/hooks/users";
import { useSnackBarFactory } from "../../../libs/hooks/general";

import Input from "../../../components/form/Input";
import TimeZoneSelect from "../../../components/form/select/TimeZone";
import PrimaryButton from "../../../components/buttons/Primary";
import ChangesSavedSnackBarContent from "../../../components/snackBarContent/ChangesSaved";

interface FormValues {
  email: string;
  firstName: string;
  lastName?: string;
  timeZone: string;
}

const formSchema = Joi.object({
  email: Joi.string().email({ tlds: false }).required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().allow(""),
  timeZone: Joi.string()
    .required()
    .custom((value) => {
      const dateTime = DateTime.local().setZone(value);

      if (!dateTime.isValid) {
        throw new Error(`invalid time zone ${value}`);
      }

      return value;
    }),
});

interface ProfileAboutYouSectionProps {
  className?: string;
}

const ProfileAboutYouSection: React.FC<ProfileAboutYouSectionProps> = ({
  className = "",
}) => {
  const createSnackBar = useSnackBarFactory();
  const user = useCurrentUser();
  const isEmailVerified = useIsEmailVerified();
  const showModal = useShowModal();
  const {
    isLoading,
    loadingText,
    errorAlert,
    mutateAsync: updateUser,
  } = useUpdateUser();
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
  } = useForm<FormValues>({
    mode: "onSubmit",
    criteriaMode: "all",
    resolver: joiResolver(formSchema),
    defaultValues: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName || "",
      timeZone: user.timeZone,
    },
  });
  const hasErrors = Object.keys(errors).length > 0;

  const onSubmit = async (data: FormValues) => {
    const updateProps: Parameters<typeof updateUser>[0]["updateProps"] = {};

    const newEmail = data.email.toLowerCase();
    if (newEmail !== user.email) {
      updateProps.email = newEmail;
    }

    if (data.firstName !== user.firstName) {
      updateProps.firstName = data.firstName;
    }

    if (data.lastName !== user.lastName) {
      updateProps.lastName = data.lastName || null;
    }

    if (data.timeZone !== user.timeZone) {
      updateProps.timeZone = data.timeZone;
    }

    const hasChanged = Object.keys(updateProps).length > 0;

    if (hasChanged) {
      await updateUser({ user, updateProps });
      createSnackBar({ content: <ChangesSavedSnackBarContent /> });
      reset(data);
    }
  };

  useEffect(() => {
    if (!isEmailVerified) {
      showModal("verifyEmailAddress");
    }
  }, [isEmailVerified, showModal]);

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register("email")}
        labelText="Email"
        labelClassName="mt-4"
        type="text"
        error={!!errors.email}
      />
      <div className="flex -mx-3">
        <div className="w-1/2 px-3">
          <Input
            {...register("firstName")}
            labelText="First name"
            labelClassName="mt-4"
            type="text"
            error={!!errors.firstName}
          />
        </div>
        <div className="w-1/2 px-3">
          <Input
            {...register("lastName")}
            labelText="Last name"
            labelClassName="mt-4"
            type="text"
            error={!!errors.lastName}
          />
        </div>
      </div>
      <Controller
        control={control}
        name="timeZone"
        render={({ field: { onChange, onBlur, value } }) => (
          <TimeZoneSelect
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors.timeZone}
            labelClassName="mt-4"
            toggleButtonClassName="w-full"
          />
        )}
      />
      {errorAlert({ className: "mt-8 w-full" })}
      <PrimaryButton
        className="mt-8 w-full text-white"
        type="submit"
        disabled={isLoading || !isDirty || hasErrors}
      >
        {loadingText({
          loading: "Saving...",
          default: "Save changes",
        })}
      </PrimaryButton>
    </form>
  );
};

export default ProfileAboutYouSection;
