import React, { ComponentProps, useCallback, useState } from "react";

import {
  PostFormValues,
  PostFormValuesByPlatformType,
} from "../../types/posts";
import { Platform } from "../../types/platforms";
import { buildPostFormValuesByPlatformType } from "../../libs/post";
import {
  useActiveWorkspace,
  useActiveWorkspaceTimeZone,
  useShowModal,
} from "../../libs/hooks/app";
import { usePlatforms } from "../../libs/hooks/platforms";

import ModalContentStack from "../../components/ModalContentStack";

import Loading from "./createPost/Loading";
import Home from "./createPost/Home";
import Refine from "./createPost/Refine";
import Submit from "./createPost/Submit";
import NoPlatforms from "./createPost/NoPlatforms";
import NoEnabledPlatformEntities from "./createPost/NoEnabledPlatformEntities";

interface CreatePostModalProps
  extends Omit<ComponentProps<typeof ModalContentStack>, "children"> {
  forceLoading?: boolean;
  defaultValues?: Partial<PostFormValues>;
  close: () => void;
}

const CreatePostModal: React.FC<CreatePostModalProps> = ({
  isVisible,
  close,
  forceLoading = false,
  defaultValues,
  ...modalProps
}) => {
  const showModal = useShowModal();
  const [isDirty, setIsDirty] = useState(false);
  const [preventModalClose, setPreventModalClose] = useState(false);
  const [rawPostFormData, setRawPostFormData] = useState<PostFormValues | null>(
    null
  );
  const [postFormDataByPlatformType, setPostFormDataByPlatformType] =
    useState<PostFormValuesByPlatformType>({});
  const workspace = useActiveWorkspace();
  const timeZone = useActiveWorkspaceTimeZone();
  const {
    isLoading: isLoadingPlatforms,
    isError: isErrorPlatforms,
    data: platformsData,
  } = usePlatforms(workspace.id);

  const isLoading = isLoadingPlatforms;
  const isError = isErrorPlatforms;
  const platforms = platformsData || [];
  const hasPlatforms = platforms.length > 0;
  const hasPlatformEntities =
    hasPlatforms &&
    !!platforms.find(
      (platform) =>
        !!Object.values(platform.entities).find((entitiy) => !entitiy.disabled)
    );
  const totalPostCount = Object.values(postFormDataByPlatformType).reduce(
    (carry, formData) => {
      return carry + formData.socials.length;
    },
    0
  );

  const resetState = useCallback(() => {
    setIsDirty(false);
    setPreventModalClose(false);
    setRawPostFormData(null);
    setPostFormDataByPlatformType({});
  }, []);

  return (
    <ModalContentStack
      {...modalProps}
      isVisible={isVisible}
      close={
        preventModalClose
          ? undefined
          : () => {
              if (totalPostCount > 0 || isDirty) {
                showModal("confirmClose", {
                  onConfirm: close,
                });
                // Return false to prevent this modal from being
                // removed from the open/close stack.
                return false;
              } else {
                close();
              }
            }
      }
      defaultContentId={
        isLoading || isError || forceLoading
          ? "loading"
          : hasPlatforms
          ? hasPlatformEntities
            ? "home"
            : "noplatformentities"
          : "noplatforms"
      }
    >
      <Loading contentId="loading" isError={isError} />

      <Home
        contentId="home"
        platforms={platforms}
        timeZone={timeZone}
        defaultValues={rawPostFormData ? rawPostFormData : defaultValues}
        getNextContentId={(formData) => {
          const formDataByPlatformType =
            buildPostFormValuesByPlatformType(formData);
          const platformTypes = Object.keys(formDataByPlatformType);

          setRawPostFormData(formData);
          setPostFormDataByPlatformType(formDataByPlatformType);

          if (platformTypes.length > 1) {
            return `refine${platformTypes[0]}`;
          } else {
            return "submit";
          }
        }}
        onDirtyChange={setIsDirty}
      />

      {Object.entries(postFormDataByPlatformType).map(
        ([platformType, formData], index, entriesArr) => {
          const isLastRefine = index === entriesArr.length - 1;
          const contentId = `refine${platformType}`;
          const nextContentId = isLastRefine
            ? "submit"
            : `refine${entriesArr[index + 1][0]}`;

          return (
            <Refine
              key={contentId}
              contentId={contentId}
              nextContentId={nextContentId}
              isLastRefine={isLastRefine}
              platformType={platformType as Platform["type"]}
              platforms={platforms}
              formData={formData}
              totalPostCount={totalPostCount}
              timeZone={timeZone}
              updateFormData={(newFormData) => {
                setPostFormDataByPlatformType({
                  ...postFormDataByPlatformType,
                  [platformType]: newFormData,
                });
              }}
            />
          );
        }
      )}

      {postFormDataByPlatformType && (
        <Submit
          contentId="submit"
          close={close}
          resetState={resetState}
          setPreventModalClose={setPreventModalClose}
          postFormDataByPlatformType={postFormDataByPlatformType}
        />
      )}

      <NoPlatforms contentId="noplatforms" close={close} />

      <NoEnabledPlatformEntities contentId="noplatformentities" close={close} />
    </ModalContentStack>
  );
};

export default CreatePostModal;
