import React from "react";

import { mergeClassNames } from "../../../../libs/components";

interface AnalyticsBodyPostLoadingProps {
  className?: string;
}

const AnalyticsBodyPostLoading: React.FC<AnalyticsBodyPostLoadingProps> = ({
  className = "",
}) => {
  return (
    <div
      className={mergeClassNames(
        "w-full rounded-xl bg-white border border-gray-200",
        className
      )}
    >
      <div className="pt-3 pl-4 pr-10">
        <div className="mr-3 flex items-start">
          <div className="w-12 h-12 shrink-0 rounded-xl animate-pulse bg-slate-200"></div>

          <div className="ml-2 w-full">
            <div className="h-4 w-24 rounded-lg animate-pulse bg-slate-200"></div>
            <div className="mt-1 h-3 w-16 rounded-lg animate-pulse bg-slate-200"></div>
            <div className="mt-1 h-3 w-16 rounded-lg animate-pulse bg-slate-200"></div>
          </div>
        </div>
      </div>

      <div className="mt-4 px-4 overflow-hidden relative">
        <div className="h-4 leading-5 w-1/4 rounded-lg animate-pulse bg-slate-200"></div>
        <div className="h-4 leading-5 w-full"></div>
        <div className="h-4 leading-5 w-3/4 rounded-lg animate-pulse bg-slate-200"></div>
      </div>

      <div className="px-3 py-1 grid grid-flow-col gap-1 font-semibold">
        <div className="h-12 flex items-center justify-center">
          <div className="mr-1 h-6 w-6 rounded-lg animate-pulse bg-slate-200"></div>
          <div className="h-4 w-12 rounded-lg animate-pulse bg-slate-200"></div>
        </div>
        <div className="h-12 flex items-center justify-center">
          <div className="mr-1 h-6 w-6 rounded-lg animate-pulse bg-slate-200"></div>
          <div className="h-4 w-12 rounded-lg animate-pulse bg-slate-200"></div>
        </div>
        <div className="h-12 flex items-center justify-center">
          <div className="mr-1 h-6 w-6 rounded-lg animate-pulse bg-slate-200"></div>
          <div className="h-4 w-12 rounded-lg animate-pulse bg-slate-200"></div>
        </div>
        <div className="h-12 flex items-center justify-center">
          <div className="mr-1 h-6 w-6 rounded-lg animate-pulse bg-slate-200"></div>
          <div className="h-4 w-12 rounded-lg animate-pulse bg-slate-200"></div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsBodyPostLoading;
