import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  AlertCircle as AlertCircleIcon,
  ArrowLeft as ArrowLeftIcon,
} from "react-feather";

import {
  CreatePublishedPostActionProps,
  FormPublishedPostAction,
} from "../../../types/publishedPostActions";

import { buildCreateActionPropsForFormAction } from "../../../libs/publishedPostActions";
import {
  useContentStack,
  useSnackBarFactory,
} from "../../../libs/hooks/general";
import { useCreatePublishedPostActions } from "../../../libs/hooks/publishedPostActions";

import { ReactComponent as ErrorImage } from "../../../images/create-post/error.svg";
import { ReactComponent as PublishedPostActionImage } from "../../../images/published-post-action.svg";

import H2 from "../../../components/headings/H2";
import LoaderIcon from "../../../components/icons/Loader";
import TertiaryButton from "../../../components/buttons/Tertiary";
import PrimaryButton from "../../../components/buttons/Primary";
import ContentSwitchContainer from "../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../components/animations/contentSwitch/Content";
import CreatedPublishedPostActionsSnackBarContent from "../../../components/snackBarContent/CreatedPublishedPostActions";

interface MultiPostPublishedPostActionsSubmitProps {
  contentId: "submit";
  setPreventModalClose: (canClose: boolean) => void;
  actionFormValues: {
    [postId: string]: FormPublishedPostAction[];
  };
  close: () => void;
}
const MultiPostPublishedPostActionsSubmit: React.FC<
  MultiPostPublishedPostActionsSubmitProps
> = ({ setPreventModalClose, actionFormValues, close }) => {
  const firstRef = useRef(true);
  const { pop } = useContentStack();
  const {
    isError,
    isLoading,
    errorAlert,
    mutateAsync: createActions,
  } = useCreatePublishedPostActions();
  const createSnackBar = useSnackBarFactory();
  const createActionProps = useMemo(() => {
    return Object.values(actionFormValues).reduce<
      CreatePublishedPostActionProps[]
    >((carry, formValues) => {
      return carry.concat(formValues.map(buildCreateActionPropsForFormAction));
    }, []);
  }, [actionFormValues]);
  const isCreatingActions = firstRef.current || isLoading;
  const failedCreatingActions = !isCreatingActions && isError;

  const handleCreateActions = useCallback(async () => {
    setPreventModalClose(true);

    try {
      await createActions(createActionProps);

      createSnackBar({
        content: (
          <CreatedPublishedPostActionsSnackBarContent
            count={createActionProps.length}
          />
        ),
      });
      close();
    } catch (e) {}
    setPreventModalClose(false);
  }, [
    close,
    createActionProps,
    createActions,
    createSnackBar,
    setPreventModalClose,
  ]);

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
      handleCreateActions();
    }
  }, [handleCreateActions]);

  return (
    <ContentSwitchContainer
      contentId={failedCreatingActions ? "error" : "creating"}
    >
      <ContentSwitchContent contentId="creating">
        <div
          className="p-8 w-full flex flex-col items-center justify-center"
          style={{ width: "42rem" }}
        >
          <div className="w-full flex items-bottom justify-around">
            <PublishedPostActionImage className="h-56 w-56 text-purple-500" />
          </div>
          <H2 className="mt-12 w-full flex items-center justify-center text-3xl text-black">
            <LoaderIcon className="mr-2 flex-shrink-0 h-8 w-8" />
            {`Creating ${createActionProps.length} after publish action${
              createActionProps.length > 1 ? "s" : ""
            }...`}
          </H2>
        </div>
      </ContentSwitchContent>
      <ContentSwitchContent contentId="error">
        <div
          className="p-8 flex flex-col items-center justify-center"
          style={{ width: "42rem" }}
        >
          <ErrorImage className="w-96 h-full text-red-500" />
          <H2 className="mt-8 w-full flex items-center text-xl text-red-500">
            <AlertCircleIcon className="flex-shrink-0 h-8 w-8" />
            <span className="ml-2">Error creating after publish actions</span>
          </H2>
          {errorAlert({ className: "mt-2" })}
          <div className="mt-8 w-full flex items-center">
            <TertiaryButton className="mr-2 w-full" onClick={() => pop()}>
              <ArrowLeftIcon className="flex-shrink-0 h-6 w-6" />
              <span className="ml-2">Back</span>
            </TertiaryButton>
            <PrimaryButton
              className="ml-2 w-full"
              onClick={() => handleCreateActions()}
            >
              Try again
            </PrimaryButton>
          </div>
        </div>
      </ContentSwitchContent>
    </ContentSwitchContainer>
  );
};

export default MultiPostPublishedPostActionsSubmit;
