import React, { ComponentProps } from "react";
import { X as XIcon } from "react-feather";
import {
  CalendarIcon,
  PhotographIcon,
  StarIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/outline";

import { useActiveWorkspace, useSetDrawer } from "../../libs/hooks/app";
import { useShowPlatformRequiresAuthAlert } from "../../libs/hooks/alerts";

import Drawer from "../../components/Drawer";
import IconButton from "../../components/buttons/Icon";
import NavigationLink from "../../components/links/Navigation";
import AlertPing from "../../components/animations/AlertCircle";
import SmartScheduleIcon from "../../components/icons/SmartSchedule";
import SocialsIcon from "../../components/icons/Socials";

import WorkspaceMenu from "../menus/Workspace";

interface NavigationDrawerProps
  extends Omit<ComponentProps<typeof Drawer>, "children"> {}

const NavigationDrawer: React.FC<NavigationDrawerProps> = (props) => {
  const workspace = useActiveWorkspace();
  const showPlatformRequiresAuthAlert = useShowPlatformRequiresAuthAlert();
  const setDrawer = useSetDrawer();

  const handleClick = () => setDrawer("navigation", { isVisible: false });

  return (
    <Drawer {...props}>
      <div className="flex flex-col p-2 w-full h-full bg-white">
        <div className="flex-shrink-0 flex items-center">
          <WorkspaceMenu
            onSelect={handleClick}
            style={{ maxWidth: "calc(100% - 40px)" }}
          />
          <div className="ml-auto flex-shrink-0 flex items-center">
            <IconButton title="Close navigation drawer" onClick={handleClick}>
              <XIcon />
            </IconButton>
          </div>
        </div>
        <div className="mt-4 flex-grow overflow-x-hidden overflow-y-auto">
          <NavigationLink to="/smart_schedules" onClick={handleClick}>
            {({ active }) => (
              <>
                <div
                  className={`p-2 rounded-full ${
                    active ? "bg-purple-500 text-white" : "bg-gray-200"
                  }`}
                >
                  <SmartScheduleIcon className="h-6 w-6" />
                </div>
                <span className="ml-4">Smart schedules</span>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="/calendar" onClick={handleClick}>
            {({ active }) => (
              <>
                <div
                  className={`p-2 rounded-full ${
                    active ? "bg-purple-500 text-white" : "bg-gray-200"
                  }`}
                >
                  <CalendarIcon className="h-6 w-6" />
                </div>
                <span className="ml-4">Calendar</span>
              </>
            )}
          </NavigationLink>
          <NavigationLink to="/analytics" onClick={handleClick}>
            {({ active }) => (
              <>
                <div
                  className={`p-2 rounded-full ${
                    active ? "bg-purple-500 text-white" : "bg-gray-200"
                  }`}
                >
                  <PresentationChartLineIcon className="h-6 w-6" />
                </div>
                <span className="ml-4">Analytics</span>
              </>
            )}
          </NavigationLink>
          <NavigationLink
            className="mt-2 relative"
            to="/socials"
            onClick={handleClick}
          >
            {({ active }) => (
              <>
                <div
                  className={`p-2 rounded-full ${
                    active ? "bg-purple-500 text-white" : "bg-gray-200"
                  }`}
                >
                  <div className="relative w-6 h-6">
                    <SocialsIcon className="w-7 h-7 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </div>
                </div>
                <span className="ml-4">Socials</span>
                {showPlatformRequiresAuthAlert && (
                  <div className="pl-1 pt-1 absolute top-0 left-0">
                    <AlertPing className="rounded-full bg-red-400">
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                    </AlertPing>
                  </div>
                )}
              </>
            )}
          </NavigationLink>
          <NavigationLink className="mt-2" to="/gallery" onClick={handleClick}>
            {({ active }) => (
              <>
                <div
                  className={`p-2 rounded-full ${
                    active ? "bg-purple-500 text-white" : "bg-gray-200"
                  }`}
                >
                  <PhotographIcon className="h-6 w-6" />
                </div>
                <span className="ml-4">Gallery</span>
              </>
            )}
          </NavigationLink>
          <NavigationLink
            className="mt-2"
            to={
              workspace.subscription
                ? `/subscriptions/${workspace.subscription.id}`
                : "/subscriptions"
            }
            onClick={handleClick}
          >
            {({ active }) => (
              <>
                <div
                  className={`p-2 rounded-full ${
                    active ? "bg-purple-500 text-white" : "bg-gray-200"
                  }`}
                >
                  <StarIcon className="h-6 w-6" />
                </div>
                <span className="ml-4">Subscription</span>
              </>
            )}
          </NavigationLink>

          {/*
          <NavigationLink className="mt-2" to="/settings" onClick={handleClick}>
            <Settings />
            <span className="ml-4">Settings</span>
          </NavigationLink>
          */}
        </div>
      </div>
    </Drawer>
  );
};

export default NavigationDrawer;
