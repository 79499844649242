import axios, { CancelTokenSource } from "axios";
import React, { ReactNode, useCallback, useState } from "react";

import { FileUpload, FileUploadByType, UploadProps } from "../types/uploads";

import UploadContext from "../contexts/Upload";
import { useGetFile, useInvalidateFilesQueries } from "../libs/hooks/files";

interface UploadProviderProps {
  children: ReactNode;
}
const UploadProvider: React.FC<UploadProviderProps> = ({ children }) => {
  const getFile = useGetFile();
  const invalidateFilesQueries = useInvalidateFilesQueries();
  const [uploads, setUploads] = useState<{ [fileId: string]: FileUpload }>({});
  const [, setCancelTokens] = useState<{
    [fileId: string]: CancelTokenSource;
  }>({});

  const setUpload = useCallback(
    (
      fileId: string,
      fileUpload: FileUpload | null | ((prevUpload: FileUpload) => FileUpload)
    ) => {
      setUploads((prevValues) => {
        if (fileUpload) {
          const prevUpload = prevValues[fileId];

          return {
            ...prevValues,
            [fileId]:
              typeof fileUpload === "function"
                ? fileUpload(prevUpload)
                : fileUpload,
          };
        } else {
          if (prevValues[fileId]) {
            const newValues = { ...prevValues };
            delete newValues[fileId];
            return newValues;
          }
        }

        return prevValues;
      });
    },
    []
  );

  const setCancelToken = useCallback(
    (fileId: string, source: CancelTokenSource | null) => {
      setCancelTokens((prevValues) => {
        if (source) {
          return {
            ...prevValues,
            [fileId]: source,
          };
        } else {
          if (prevValues[fileId]) {
            // Trigger a cancel on the token.
            prevValues[fileId].cancel();
            const newValues = { ...prevValues };
            delete newValues[fileId];
            return newValues;
          }
        }

        return prevValues;
      });
    },
    []
  );

  const remove = useCallback(
    (fileId: string) => {
      setCancelToken(fileId, null);
      setUpload(fileId, null);
    },
    [setCancelToken, setUpload]
  );

  const upload = useCallback(
    async <T extends UploadProps>({
      rawFile,
      internalFile,
      postUrl,
      postFields,
      ...rest
    }: T) => {
      const formData = new FormData();
      Object.keys(postFields).forEach((key) => {
        formData.append(key, postFields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", rawFile);

      const fileId = internalFile.id;
      const source = axios.CancelToken.source();
      const promise = axios({
        method: "post",
        url: postUrl,
        data: formData,
        onUploadProgress: (e: ProgressEvent) => {
          const percent = Math.min(
            100,
            Math.max(0, (e.loaded / e.total) * 100)
          );
          setUpload(fileId, (prevFileUpload) => ({
            ...prevFileUpload,
            status: "IN_PROGRESS",
            percent,
          }));
        },
        cancelToken: source.token,
      });
      const fileUpload = {
        internalFile,
        percent: 0,
        status: "PENDING",
        promise,
        ...rest,
      } as unknown as FileUploadByType<T["internalFile"]["type"]>;
      setUpload(fileId, fileUpload);
      setCancelToken(fileId, source);

      promise
        .then(async () => {
          setUpload(fileId, {
            ...fileUpload,
            status: "COMPLETE",
            percent: 100,
          });
          setCancelToken(fileId, null);

          // Ping the server for the file every second (up to 10 times) to check
          // for the status to change to COMPLETE. Once it has we can delete the
          // upload.
          await new Promise((resolve, reject) => {
            let attempts = 0;

            const checkFileCompletion = () => {
              window.setTimeout(async () => {
                attempts++;

                if (attempts > 10) {
                  reject("Unable to confirm file completed");
                  return;
                }

                const updatedFile = await getFile(fileId);

                if (!updatedFile) {
                  reject(`Unable to find file ${fileId}`);
                  return;
                }

                if (updatedFile.status !== "COMPLETE") {
                  checkFileCompletion();
                  return;
                }

                invalidateFilesQueries();
                remove(fileId);
                resolve(true);
              }, 1000);
            };

            checkFileCompletion();
          });
        })
        .catch((e) => {
          setUpload(fileId, {
            ...fileUpload,
            status: "FAILED",
            error: e.message || e,
          });
          setCancelToken(fileId, null);
        });

      return fileUpload;
    },
    [getFile, invalidateFilesQueries, remove, setCancelToken, setUpload]
  );

  const get = useCallback(
    (fileId: string) => {
      return uploads[fileId] || null;
    },
    [uploads]
  );

  const getAll = useCallback(() => uploads, [uploads]);

  return (
    <UploadContext.Provider value={{ upload, get, getAll, remove }}>
      {children}
    </UploadContext.Provider>
  );
};

export default UploadProvider;
