import React from "react";

interface AnalyticsOverTimeLoadingProps {
  className?: string;
}
const AnalyticsOverTimeLoading: React.FC<AnalyticsOverTimeLoadingProps> = ({
  className = "",
}) => {
  return (
    <div className={className}>
      <div className="flex items-center">
        <div className="ml-2 w-20 h-9 rounded-lg animate-pulse bg-slate-200"></div>
        <div className="ml-2 w-20 h-9 rounded-lg animate-pulse bg-slate-200"></div>
        <div className="ml-auto w-40 h-9 rounded-lg animate-pulse bg-slate-200"></div>
      </div>
      <div className="mt-4 h-96 rounded-lg animate-pulse bg-slate-200"></div>
    </div>
  );
};

export default AnalyticsOverTimeLoading;
