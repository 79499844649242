import { DateTime } from "luxon";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  DragEvent,
} from "react";
import { useSprings, animated } from "@react-spring/web";

import { PostWithScheduledProp } from "../../types/posts";
import { Platform } from "../../types/platforms";
import { ImageFileUpload } from "../../types/uploads";

import { mergeRefs } from "../../libs/components";
import { getAvailableScheduleFuturePosts } from "../../libs/subscriptions";
import {
  useDragDrop,
  useIsOpenCloseStackEmpty,
} from "../../libs/hooks/general";
import {
  useActiveWorkspace,
  useActiveWorkspaceTimeZone,
  useHideModal,
  useShowModal,
} from "../../libs/hooks/app";
import { useUpdatePost } from "../../libs/hooks/posts";
import { useUploadFiles } from "../../libs/hooks/uploads";

import CalendarPost from "./Post";
import CalendarQueuedPost from "./QueuedPost";
import CalendarPostsContainer from "./PostsContainer";

interface CalendarContentProps {
  posts: PostWithScheduledProp[];
  platforms: Platform[];
  containerHeight: number;
  rowHeight: number;
  daysOfWeek: DateTime[];
  colWidth: number;
  dragBounds: {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  };
}

interface OrganisedPostConfig {
  top: number;
  left: number;
  post: PostWithScheduledProp;
  platform: Platform;
}

interface OrganisedPost {
  postConfigs: OrganisedPostConfig[];
}

type OrganisedPosts = OrganisedPost[];

const DROP_TARGET_ID = "CALENDAR_CONTENT";
const DRAGGABLE_POST_TYPE = "CALENDAR_POST_DRAGGABLE";
const POST_MARGIN = 3;
const ROW_INCREMENTS = 4;

function buildDayColumnSprings(
  daysOfWeek: DateTime[],
  containerHeight: number,
  timeZone: string,
  rowHeight: number
) {
  return (index: number) => {
    let height = 0;
    const dayDateTime = daysOfWeek[index];
    const nowDateTime = DateTime.local().setZone(timeZone);
    const isToday = dayDateTime.hasSame(nowDateTime, "day");

    if (isToday) {
      const hour = nowDateTime.hour;
      const minute = nowDateTime.minute;
      const percentThroughHour = minute / 60;
      height = Math.floor(hour * rowHeight + rowHeight * percentThroughHour);
    } else if (dayDateTime < nowDateTime) {
      height = containerHeight;
    }

    return {
      height,
      immediate: true,
    };
  };
}

const CalendarContent: React.FC<CalendarContentProps> = ({
  posts,
  platforms,
  containerHeight,
  rowHeight,
  daysOfWeek,
  colWidth,
  dragBounds,
}) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const workspace = useActiveWorkspace();
  const showModal = useShowModal();
  const hideModal = useHideModal();
  const isOpenCloseStackEmpty = useIsOpenCloseStackEmpty();
  const { mutate: updatePost } = useUpdatePost();
  const { drop } = useDragDrop();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const incrementHeight = parseFloat(
    `${(rowHeight / ROW_INCREMENTS).toPrecision(2)}`
  );
  const minutesPerIncrement = parseFloat(
    `${(60 / ROW_INCREMENTS).toPrecision(2)}`
  );
  const postWidth = colWidth - 2 * POST_MARGIN;
  const postHeight = rowHeight - 2 * POST_MARGIN;
  const availablePostsCount = getAvailableScheduleFuturePosts(workspace);
  const isAtPostLimit = availablePostsCount !== null && availablePostsCount < 1;
  const [dayColumnSprings, dayColumnSpringsApi] = useSprings(
    daysOfWeek.length,
    buildDayColumnSprings(daysOfWeek, containerHeight, timeZone, rowHeight)
  );
  const hourMarkers: number[] = [];
  for (let i = 0; i < 24; i++) {
    hourMarkers.push(i * rowHeight);
  }

  const calculatePosition = useCallback(
    (scheduled: string) => {
      const scheduledDateTime = DateTime.fromISO(scheduled).setZone(timeZone);
      const hour = scheduledDateTime.hour;
      const day = scheduledDateTime.weekday - 1; // Make it zero indexed.
      const top = hour * rowHeight;
      // Add margins for spacing.
      const left = POST_MARGIN + day * colWidth;

      return { top, left };
    },
    [colWidth, rowHeight, timeZone]
  );

  const calculateScheduled = useCallback(
    (top: number, left: number, roundDay = true) => {
      const day = roundDay
        ? Math.round(left / colWidth)
        : Math.floor(left / colWidth);
      const hour = Math.floor(top / rowHeight);
      const minute =
        Math.round((top % rowHeight) / incrementHeight) * minutesPerIncrement;
      const dayDateTime = daysOfWeek[day];
      return dayDateTime.set({ hour, minute });
    },
    [colWidth, daysOfWeek, incrementHeight, minutesPerIncrement, rowHeight]
  );

  const organisedPosts = useMemo(() => {
    const copyPosts = posts.slice();
    const result: OrganisedPosts = [];

    while (copyPosts.length > 0) {
      const organisedPost: OrganisedPost = {
        postConfigs: [],
      };
      const post = copyPosts.shift()!;
      const platform = platforms.find(
        (platform) => platform.id === post.platformId
      );

      if (!platform) {
        continue;
      }

      const { top, left } = calculatePosition(post.scheduled);
      let matching = true;
      let prevTop = top;

      while (copyPosts.length > 0 && matching) {
        const nextPost = copyPosts.shift()!;
        const nextPlatform = platforms.find(
          (platform) => platform.id === nextPost.platformId
        );

        if (!nextPlatform) {
          continue;
        }

        const { top: nextTop, left: nextLeft } = calculatePosition(
          nextPost.scheduled
        );

        if (nextLeft === left && nextTop - prevTop < rowHeight) {
          // These posts will overlap so let's stick them in a container.
          organisedPost.postConfigs.push({
            top: nextTop,
            left: nextLeft,
            post: nextPost,
            platform: nextPlatform,
          });

          // Remember the last top value we saw to compare with the next post.
          prevTop = nextTop;
        } else {
          // No match so put it back.
          copyPosts.unshift(nextPost);
          matching = false;
        }
      }

      // Add the original post to the front of the list.
      organisedPost.postConfigs.unshift({ top, left, post, platform });

      result.push(organisedPost);
    }

    return result;
  }, [calculatePosition, platforms, posts, rowHeight]);

  const handleDragStart = useCallback<
    NonNullable<Parameters<typeof drop>[0]["onStart"]>
  >(
    ({ itemId }) => {
      const draggedPost = posts.find((post) => post.id === itemId);

      setIsDragging(true);
      return { draggedPost };
    },
    [posts]
  );

  const handleDragEnd = useCallback<
    NonNullable<Parameters<typeof drop>[0]["onDrop"]>
  >(
    ({ type, overPosition, args }) => {
      if (type === DRAGGABLE_POST_TYPE) {
        const { draggedPost } = args as { draggedPost: PostWithScheduledProp };
        const newScheduled = calculateScheduled(
          overPosition.item.top,
          overPosition.item.left,
          true
        );
        const oldScheduled = DateTime.fromISO(draggedPost.scheduled);
        const now = DateTime.local().setZone(timeZone);
        const isValid = newScheduled >= now;

        if (
          isValid &&
          newScheduled.toUTC().toISO() !== oldScheduled.toUTC().toISO()
        ) {
          updatePost({
            post: draggedPost,
            updateProps: { scheduled: newScheduled.toISO() },
            optimistic: true,
          });
        }
      }

      setIsDragging(false);
    },
    [calculateScheduled, timeZone, updatePost]
  );

  const handleCalendarClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (
      e.isDefaultPrevented() ||
      !isOpenCloseStackEmpty() ||
      e.altKey ||
      e.shiftKey ||
      e.ctrlKey ||
      e.metaKey
    ) {
      return;
    }

    const nowISO = DateTime.local().setZone(timeZone).toISO();
    const bounds = e.currentTarget.getBoundingClientRect();
    const relativeX = e.clientX - bounds.x;
    const relativeY = e.clientY - bounds.y;

    let scheduledISO = calculateScheduled(relativeY, relativeX, false).toISO();

    if (scheduledISO < nowISO) {
      // Schedule is in the past but the user might be clicking close to the
      // now/past cutoff so add an increment and try again.
      scheduledISO = calculateScheduled(
        relativeY + incrementHeight,
        relativeX,
        false
      ).toISO();

      if (scheduledISO < nowISO) {
        // Nope, it's still in the past so we should ignore the click.
        return;
      }
    }

    if (isAtPostLimit) {
      showModal("changeSubscription", {
        workspace,
        limitExceeded: true,
        redirectOnSuccess: false,
      });
    } else {
      showModal("createPost", {
        defaultValues: { scheduled: scheduledISO },
      });
    }
  };

  const renderPost = useCallback(
    (post: PostWithScheduledProp, platform: Platform) => {
      if (post.status === "QUEUED") {
        return (
          <CalendarQueuedPost
            key={post.id}
            post={post}
            platform={platform}
            height={postHeight}
            width={postWidth}
          />
        );
      } else {
        return (
          <CalendarPost
            key={post.id}
            post={post}
            platform={platform}
            height={postHeight}
            width={postWidth}
            itemId={post.id}
            dragType={DRAGGABLE_POST_TYPE}
            dragBounds={dragBounds}
            timeZone={timeZone}
            containerRef={containerRef}
            getNewScheduled={(newTop, newLeft) => {
              const newScheduled = calculateScheduled(newTop, newLeft);
              const now = DateTime.local().setZone(timeZone);
              const isValid = newScheduled >= now;
              return [isValid, newScheduled.toSeconds()];
            }}
          />
        );
      }
    },
    [calculateScheduled, dragBounds, postHeight, postWidth, timeZone]
  );

  useUploadFiles({
    owner: {
      type: "WORKSPACE",
      workspaceId: workspace.id,
    },
    onDropStart: (acceptedFiles, fileRejections, dropEvent) => {
      if (!acceptedFiles.length || !containerRef.current) {
        return;
      }

      const bounds = containerRef.current.getBoundingClientRect();
      const relativeX =
        (dropEvent as DragEvent<HTMLElement>).clientX - bounds.x;
      const relativeY =
        (dropEvent as DragEvent<HTMLElement>).clientY - bounds.y;

      const scheduledISO = calculateScheduled(
        relativeY,
        relativeX,
        false
      ).toISO();
      const nowISO = DateTime.local().setZone(timeZone).toISO();

      if (scheduledISO > nowISO) {
        if (isAtPostLimit) {
          showModal("changeSubscription", {
            workspace,
            limitExceeded: true,
            redirectOnSuccess: false,
          });
        } else {
          showModal("createPost", {
            forceLoading: true,
          });
        }
      }
    },
    onDropEnd: (fileUploads, dropEvent) => {
      if (!containerRef.current) {
        return;
      }

      const imageFileUploads = fileUploads.filter(
        (fileUpload) => fileUpload.internalFile.type === "IMAGE"
      ) as ImageFileUpload[];

      if (imageFileUploads.length) {
        const bounds = containerRef.current.getBoundingClientRect();
        const relativeX =
          (dropEvent as DragEvent<HTMLElement>).clientX - bounds.x;
        const relativeY =
          (dropEvent as DragEvent<HTMLElement>).clientY - bounds.y;

        const scheduledISO = calculateScheduled(
          relativeY,
          relativeX,
          false
        ).toISO();

        showModal("createPost", {
          defaultValues: {
            scheduled: scheduledISO,
            attachment: {
              type: "IMAGE",
              images: imageFileUploads.map((imageFileUpload) => ({
                fileId: imageFileUpload.internalFile.id,
                title: imageFileUpload.internalFile.name,
                height: (imageFileUpload as ImageFileUpload).imageHeight,
                width: (imageFileUpload as ImageFileUpload).imageWidth,
              })),
            },
          },
        });
      } else {
        hideModal("createPost");
      }
    },
  });

  useEffect(() => {
    // Any time these values change update the columns.
    dayColumnSpringsApi.start(
      buildDayColumnSprings(daysOfWeek, containerHeight, timeZone, rowHeight)
    );

    const nowDateTime = DateTime.local().setZone(timeZone);
    const endOfMinute = nowDateTime.endOf("minute");
    const { milliseconds } = endOfMinute.diff(nowDateTime, "milliseconds");
    // Start the interval at the end of the minute.
    let intervalId: number | null = null;
    const timeoutId = window.setTimeout(() => {
      // Redraw column sizes.
      dayColumnSpringsApi.start(
        buildDayColumnSprings(daysOfWeek, containerHeight, timeZone, rowHeight)
      );

      // Redraw column sizes every minute from now (until the user leaves the page).
      intervalId = window.setInterval(() => {
        dayColumnSpringsApi.start(
          buildDayColumnSprings(
            daysOfWeek,
            containerHeight,
            timeZone,
            rowHeight
          )
        );
      }, 60000);
    }, milliseconds);

    return () => {
      // Clear timers when this component unmounts.
      window.clearTimeout(timeoutId);

      if (intervalId !== null) {
        window.clearInterval(intervalId);
      }
    };
  }, [containerHeight, dayColumnSpringsApi, daysOfWeek, rowHeight, timeZone]);

  return (
    <div
      ref={mergeRefs([
        drop({
          dropId: DROP_TARGET_ID,
          accept: [DRAGGABLE_POST_TYPE],
          onStart: handleDragStart,
          onDrop: handleDragEnd,
        }),
        containerRef,
      ])}
      className="relative w-full h-full"
    >
      {dayColumnSprings.map((spring, index) => {
        return (
          <animated.div
            key={index}
            className="absolute top-0 bg-gray-100"
            style={{
              left: colWidth * index,
              width: colWidth,
              height: spring.height,
            }}
          ></animated.div>
        );
      })}
      {hourMarkers.map((top, index) => {
        return (
          <div
            key={index}
            className="w-full h-px absolute left-0 bg-gray-200"
            style={{ top }}
          ></div>
        );
      })}
      <div
        className="absolute top-0 left-0 h-full w-full"
        onClick={handleCalendarClick}
        role={undefined}
      ></div>
      {organisedPosts.map(({ postConfigs }, index) => {
        if (postConfigs.length > 1) {
          const { top, left } = postConfigs[0];
          const [containerPosts, containerPlatforms] = postConfigs.reduce<
            [PostWithScheduledProp[], Platform[]]
          >(
            (carry, postConfig) => {
              carry[0].push(postConfig.post);
              carry[1].push(postConfig.platform);
              return carry;
            },
            [[], []]
          );

          return (
            <div
              key={`post-container-${index}`}
              className="absolute"
              style={{ top, left }}
            >
              <CalendarPostsContainer
                posts={containerPosts}
                platforms={containerPlatforms}
                postHeight={postHeight}
                postWidth={postWidth}
                forceCollapse={isDragging}
              >
                {postConfigs.map((postConfig) => {
                  const { post, platform } = postConfig;
                  return renderPost(post, platform);
                })}
              </CalendarPostsContainer>
            </div>
          );
        } else {
          const { post, platform, top, left } = postConfigs[0];
          return (
            <div key={post.id} className="absolute" style={{ top, left }}>
              {renderPost(post, platform)}
            </div>
          );
        }
      })}
    </div>
  );
};

export default CalendarContent;
