import { useInfiniteQuery } from "@tanstack/react-query";

import { ApiResponse } from "../../types/general";
import { Invoice } from "../../types/invoices";
import { INVOICES_QUERY_KEYS } from "../queryKeys";

import { useApiClient } from "./app";

interface UseInvoicesForSubscriptionProps {
  subscriptionId: string;
  limit?: number;
  offsetKey?: string;
  asc?: boolean;
}
export const useInvoicesForSubscription = ({
  subscriptionId,
  limit = 10,
  offsetKey,
  asc = false,
}: UseInvoicesForSubscriptionProps) => {
  const apiClient = useApiClient();

  return useInfiniteQuery(
    INVOICES_QUERY_KEYS.infiniteList({ subscriptionId, limit, offsetKey, asc }),
    async ({ pageParam = offsetKey }) => {
      const { data: response } = await apiClient.get<
        ApiResponse<
          Invoice[],
          {
            queryData: UseInvoicesForSubscriptionProps;
            offsetKey?: string;
            isLast: boolean;
          }
        >
      >("/invoices", {
        params: {
          subscriptionId,
          limit,
          offsetKey: pageParam,
          asc,
        },
      });

      return {
        invoices: response.data,
        meta: response.meta,
      };
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.meta.isLast ? undefined : lastPage.meta.offsetKey,
    }
  );
};

interface UseInvoicesForBillingCustomerProps {
  billingCustomerId: string;
  limit?: number;
  offsetKey?: string;
  asc?: boolean;
}
export const useInvoicesForBillingCustomer = (
  {
    billingCustomerId,
    limit = 10,
    offsetKey,
    asc = false,
  }: UseInvoicesForBillingCustomerProps,
  options = { enabled: true }
) => {
  const apiClient = useApiClient();

  return useInfiniteQuery(
    INVOICES_QUERY_KEYS.infiniteList({
      billingCustomerId,
      limit,
      offsetKey,
      asc,
    }),
    async ({ pageParam = offsetKey }) => {
      const { data: response } = await apiClient.get<
        ApiResponse<
          Invoice[],
          {
            queryData: UseInvoicesForBillingCustomerProps;
            offsetKey?: string;
            isLast: boolean;
          }
        >
      >("/invoices", {
        params: {
          billingCustomerId,
          limit,
          offsetKey: pageParam,
          asc,
        },
      });

      return {
        invoices: response.data,
        meta: response.meta,
      };
    },
    {
      ...options,
      getNextPageParam: (lastPage) =>
        lastPage.meta.isLast ? undefined : lastPage.meta.offsetKey,
    }
  );
};
