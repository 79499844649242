import React, { useMemo } from "react";
import { DateTime } from "luxon";
import {
  Calendar as CalendarIcon,
  ChevronDown as ChevronDownIcon,
} from "react-feather";
import { SparklesIcon } from "@heroicons/react/outline";

import { PostWithScheduledProp } from "../../../../types/posts";
import { Platform } from "../../../../types/platforms";
import { mergeClassNames } from "../../../../libs/components";
import { generateSuggestedPostDateTime } from "../../../../libs/post";
import { useActiveWorkspace } from "../../../../libs/hooks/app";
import { usePostsWithScheduledProp } from "../../../../libs/hooks/posts";

import LoaderIcon from "../../../../components/icons/Loader";
import DatePickerMenu from "../../../../components/menus/DatePicker";
import OutlineButton from "../../../../components/buttons/Outline";
import DateInput from "../../../../components/form/input/Date";
import Tooltip from "../../../../components/Tooltip";

interface DateInputProps {
  value: string;
  onChange: (newScheduled: string) => void;
  timeZone: string;
  selectedPlatformTypes: Platform["type"][];
  className?: string;
  error?: boolean;
}

const PorstFormDateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  timeZone,
  selectedPlatformTypes,
  className = "",
  error = false,
}) => {
  const workspace = useActiveWorkspace();
  const scheduledDateTime = DateTime.fromISO(value).setZone(timeZone);
  const now = DateTime.local().setZone(timeZone);
  const todayISO = now.startOf("day").toISO();
  const nextWeekISO = now.plus({ weeks: 1 }).endOf("day").toISO();
  const { isLoading: isLoadingWeekPosts, data: weekPosts } =
    usePostsWithScheduledProp(workspace.id, todayISO, nextWeekISO);

  const weekdayPostsByISODate = useMemo(() => {
    const postsByISODate = (weekPosts || []).reduce<{
      [isoDate: string]: PostWithScheduledProp[];
    }>((carry, post) => {
      const isoDate = DateTime.fromISO(post.scheduled)
        .setZone(timeZone)
        .toISODate();

      if (carry[isoDate]) {
        carry[isoDate].push(post);
      } else {
        carry[isoDate] = [post];
      }

      return carry;
    }, {});

    const now = DateTime.local().setZone(timeZone);
    const weekdayISODates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const candidateDate = now.plus({ days: i });

      if (candidateDate.weekday < 6) {
        // If the dates is Mon - Fri.
        weekdayISODates.push(candidateDate.toISODate());
      }
    }

    return weekdayISODates.reduce<typeof postsByISODate>((carry, isoDate) => {
      carry[isoDate] = postsByISODate[isoDate] || [];
      return carry;
    }, {});
  }, [weekPosts, timeZone]);

  return (
    <div
      className={mergeClassNames(
        `p-2 flex items-center rounded-lg default-transition border-2 hover:bg-gray-100 focus-within:bg-white focus-within:border-purple-500 ${
          error ? "border-red-500" : "border-transparent"
        }`,
        className
      )}
    >
      <DateInput
        value={{
          day: scheduledDateTime.day,
          month: scheduledDateTime.month,
          year: scheduledDateTime.year,
        }}
        onChange={(newValues) => {
          const newScheduledDateTime = scheduledDateTime.set(newValues);

          onChange(newScheduledDateTime.toISO());
        }}
        timeZone={timeZone}
        allowPast={false}
      />
      <Tooltip
        className="ml-auto"
        content={
          <p className="w-64">
            Seenly will intelligently suggest a date and time for your post
          </p>
        }
      >
        <OutlineButton
          className="px-2 py-2 border-gray-200 flex items-center text-xs font-normal"
          disabled={isLoadingWeekPosts}
          onClick={() => {
            const newScheduledDateTime = generateSuggestedPostDateTime(
              weekdayPostsByISODate,
              timeZone,
              selectedPlatformTypes,
              DateTime.local().setZone(timeZone),
              [scheduledDateTime]
            );

            if (newScheduledDateTime) {
              onChange(newScheduledDateTime.toISO());
            }
          }}
        >
          {isLoadingWeekPosts ? (
            <LoaderIcon className="mr-1 h-5 w-5" />
          ) : (
            <SparklesIcon className="mr-1 h-5 w-5" />
          )}
          Smart suggest
        </OutlineButton>
      </Tooltip>
      <DatePickerMenu
        value={scheduledDateTime.toSeconds()}
        onChange={(newValue) => {
          if (newValue !== null) {
            const newScheduledDateTime = DateTime.fromSeconds(newValue, {
              zone: timeZone,
            });

            onChange(newScheduledDateTime.toISO());
          }
        }}
        xAlign="right"
        className="ml-1"
        disablePast={true}
        ButtonComponent={OutlineButton}
        buttonClassName="px-2 py-2 border-gray-200"
        buttonContent={
          <span className="flex items-center">
            <CalendarIcon className="mr-1 h-5 w-5" />
            <ChevronDownIcon className="h-5 w-5" />
          </span>
        }
        closeOnSelect={true}
        timeZone={timeZone}
      />
    </div>
  );
};

export default PorstFormDateInput;
