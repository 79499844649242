import React from "react";
import { convertFromRaw } from "draft-js";

import { PlatformEntity } from "../../types/platforms";
import { Post } from "../../types/posts";

import { useNow } from "../../libs/hooks/app";

import FacebookPreview from "./previews/Facebook";
import LinkedInPreview from "./previews/LinkedIn";
import TwitterPreview from "./previews/Twitter";

interface PostPreviewProps {
  platformEntity: PlatformEntity;
  post: Post;
  className?: string;
}
const PostPreview: React.FC<PostPreviewProps> = ({
  platformEntity,
  post,
  className,
}) => {
  const now = useNow();

  switch (post.type) {
    case "FACEBOOK":
      return (
        <FacebookPreview
          className={className}
          platformEntity={platformEntity}
          scheduled={post.scheduled || now.toISO()}
          contentState={convertFromRaw(post.text.contentState)}
          attachment={post.attachment}
        />
      );
    case "LINKEDIN":
      return (
        <LinkedInPreview
          className={className}
          platformEntity={platformEntity}
          contentState={convertFromRaw(post.text.contentState)}
          attachment={post.attachment}
        />
      );
    case "TWITTER":
      return (
        <TwitterPreview
          className={className}
          platformEntity={platformEntity}
          scheduled={post.scheduled || now.toISO()}
          contentState={convertFromRaw(post.text.contentState)}
          attachment={post.attachment}
        />
      );
  }
};

export default PostPreview;
