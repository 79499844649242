import React, { useState } from "react";
import H4 from "../headings/H4";
import SectionTitle from "./SsectionTitle";
import button from "../buttons/Button";

interface ToneSelectorProps {
  onChange: (tone: string) => void;
  selectedTone: string;
}

const ToneSelector: React.FC<ToneSelectorProps> = ({
  onChange,
  selectedTone,
}) => {
  const [activeTone, setActiveTone] = useState(selectedTone);

  const handleToneClick = (tone: string) => {
    setActiveTone(tone);
    onChange(tone);
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <SectionTitle title={"Tone of voice"}></SectionTitle>
      <div className="flex flex-wrap gap-2">
        <button
          type={"button"}
          className={`rounded-lg border border-gray-300 shadow-sm focus:outline-none appearance-none h-10 pl-4 pr-4 ${
            selectedTone === "polite" ? "bg-gray-500 text-white" : "bg-white"
          }`}
          onClick={() => onChange("polite")}
        >
          Polite
        </button>
        <button
          type={"button"}
          className={`rounded-lg border border-gray-300 shadow-sm focus:outline-none appearance-none h-10 pl-4 pr-4 ${
            selectedTone === "witty" ? "bg-gray-500 text-white" : "bg-white"
          }`}
          onClick={() => onChange("witty")}
        >
          Witty
        </button>
        <button
          type={"button"}
          className={`rounded-lg border border-gray-300 shadow-sm focus:outline-none appearance-none h-10 pl-4 pr-4 ${
            selectedTone === "enthusiastic"
              ? "bg-gray-500 text-white"
              : "bg-white"
          }`}
          onClick={() => onChange("enthusiastic")}
        >
          Enthusiastic
        </button>
        <button
          type={"button"}
          className={`rounded-lg border border-gray-300 shadow-sm focus:outline-none appearance-none h-10 pl-4 pr-4 ${
            selectedTone === "friendly" ? "bg-gray-500 text-white" : "bg-white"
          }`}
          onClick={() => onChange("friendly")}
        >
          Friendly
        </button>
        <button
          type={"button"}
          className={`rounded-lg border border-gray-300 shadow-sm focus:outline-none appearance-none h-10 pl-4 pr-4 ${
            selectedTone === "informational"
              ? "bg-gray-500 text-white"
              : "bg-white"
          }`}
          onClick={() => onChange("informational")}
        >
          Informational
        </button>
        <button
          type={"button"}
          className={`rounded-lg border border-gray-300 shadow-sm focus:outline-none appearance-none h-10 pl-4 pr-4 ${
            selectedTone === "funny" ? "bg-gray-500 text-white" : "bg-white"
          }`}
          onClick={() => onChange("funny")}
        >
          Funny
        </button>
        <br />
        <button
          type={"button"}
          className={`rounded-lg border border-gray-300 shadow-sm focus:outline-none appearance-none h-10 pl-4 pr-4 ${
            selectedTone === "inspirational"
              ? "bg-gray-500 text-white"
              : "bg-white"
          }`}
          onClick={() => onChange("inspirational")}
        >
          Inspirational
        </button>
      </div>
    </div>
  );
};

export default ToneSelector;
