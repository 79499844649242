import { nanoid } from "nanoid";
import config from "../../config";
import { openPlatformOAuthWindow } from "../auth";

export const LINKEDIN_REDIRECT_URI = `${window.location.origin}/auth/linkedin`;
const SCOPES = [
  "r_organization_followers",
  "r_basicprofile",
  "w_member_social",
  "w_member_social_feed",
  "rw_organization_admin",
  "r_organization_social",
  "r_organization_social_feed",
  "w_organization_social",
  "w_organization_social_feed",
  "r_1st_connections_size",
];
const BASE_PARAMS = {
  response_type: "code",
  client_id: config.linkedIn.CLIENT_ID,
  scope: SCOPES.join(" "),
  redirect_uri: LINKEDIN_REDIRECT_URI,
};
const BASE_URL = "https://www.linkedin.com/oauth/v2/authorization";

export async function getLinkedInAuthorisationCode() {
  const stateId = nanoid();
  const queryParams = new URLSearchParams({
    ...BASE_PARAMS,
    state: stateId,
  });
  const params = await openPlatformOAuthWindow(
    "LINKEDIN",
    `${BASE_URL}?${queryParams.toString()}`
  );

  if (params.has("error")) {
    throw new Error(
      "Unfortunately we can't post to your LinkedIn without you granting us permission."
    );
  }

  if (
    !params.has("state") ||
    params.get("state") !== stateId ||
    !params.has("code")
  ) {
    throw new Error("Looks like something went wrong. Please try again.");
  }

  return params.get("code") as string;
}
