import React, { ComponentProps, useMemo, useState } from "react";
import { Star as StarIcon } from "react-feather";

import { Post } from "../../types/posts";

import config from "../../config";
import { useActiveWorkspace, useShowModal } from "../../libs/hooks/app";
import { useAsyncState, useSnackBarFactory } from "../../libs/hooks/general";
import { usePlatforms } from "../../libs/hooks/platforms";
import {
  useCreatePublishedPostActions,
  useDeletePublishedPostAction,
  usePublishedPostActionsForPost,
  useUpdatePublishedPostAction,
} from "../../libs/hooks/publishedPostActions";
import {
  buildChangePatchForActionFormData,
  buildFormDataForPublishedPostActions,
} from "../../libs/publishedPostActions";
import { getPlatformTypeName } from "../../libs/platform";

import Modal from "../../components/Modal";
import ContentSwitchContainer from "../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../components/animations/contentSwitch/Content";
import LoaderIcon from "../../components/icons/Loader";
import H2 from "../../components/headings/H2";
import H3 from "../../components/headings/H3";
import InternalPrimaryButtonLink from "../../components/links/InternalPrimaryButton";
import PrimaryButton from "../../components/buttons/Primary";
import ChangesSavedSnackBarContent from "../../components/snackBarContent/ChangesSaved";
import PublishedPostActionIcon from "../../components/icons/PublishedPostAction";
import PlatformIcon from "../../components/icons/Platform";
import PostPreview from "../../components/posts/Preview";

import { ReactComponent as ErrorImage } from "../../images/access-denied.svg";
import { ReactComponent as NoPlatformsImage } from "../../images/no-platforms.svg";
import { ReactComponent as PublishedPostActionImage } from "../../images/published-post-action.svg";

import LinkedInForm, {
  LinkedInFormValues,
} from "../forms/LinkedInPublishedPostActions";
import TwitterForm, {
  TwitterFormValues,
} from "../forms/TwitterPublishedPostActions";
import FacebookForm, {
  FacebookFormValues,
} from "../forms/FacebookPublishedPostActions";

interface EditPublishedPostActionsModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  post: Post;
  close: () => void;
}

const EditPublishedPostActionsModal: React.FC<
  EditPublishedPostActionsModalProps
> = ({ isVisible, close, post, ...modalProps }) => {
  const showModal = useShowModal();
  const createSnackBar = useSnackBarFactory();
  const [isDirty, setIsDirty] = useState(false);
  const {
    loading: isSubmitting,
    setLoading: setIsSubmitting,
    error: errorAlert,
    setError,
    clearError,
  } = useAsyncState();
  const workspace = useActiveWorkspace();
  const {
    isLoading: isLoadingPlatforms,
    isError: isErrorPlatforms,
    data: platformsData,
  } = usePlatforms(workspace.id);
  const {
    isLoading: isLoadingActions,
    isError: isErrorActions,
    data: actionsData,
  } = usePublishedPostActionsForPost(post.id);
  const { mutateAsync: createPublishedPostActions } =
    useCreatePublishedPostActions();
  const { mutateAsync: updatePublishedPostAction } =
    useUpdatePublishedPostAction();
  const { mutateAsync: deletePublishedPostAction } =
    useDeletePublishedPostAction();

  const isLoading = isLoadingPlatforms || isLoadingActions;
  const isError = isErrorPlatforms || isErrorActions;
  const actions = actionsData || [];
  const platforms = useMemo(() => platformsData || [], [platformsData]);
  const hasActions = actions.length > 0;
  const hasSubscription = !!workspace.subscription;
  const hasNeverHadSubscription = !workspace.hasHadTrial && !hasSubscription;
  const hasAccess = hasSubscription || hasActions;
  const hasPlatforms = platforms.length > 0;
  const hasPlatformEntities =
    hasPlatforms &&
    !!platforms.find(
      (platform) =>
        !!Object.values(platform.entities).find((entitiy) => !entitiy.disabled)
    );
  const postPlatform = useMemo(() => {
    return platforms.find((candidate) => candidate.id === post.platformId);
  }, [platforms, post.platformId]);
  const postPlatformEntity = useMemo(() => {
    return postPlatform
      ? postPlatform.entities[post.platformEntityId]
      : undefined;
  }, [postPlatform, post.platformEntityId]);

  const handleSubmit = async (
    data: LinkedInFormValues | TwitterFormValues | FacebookFormValues
  ) => {
    setIsSubmitting(true);
    clearError();

    const changePatch = buildChangePatchForActionFormData(
      actions,
      data.actions
    );

    const promises: Promise<any>[] = [];

    if (changePatch.create.length > 0) {
      promises.push(createPublishedPostActions(changePatch.create));
    }

    if (changePatch.update.length > 0) {
      changePatch.update.forEach(({ action, updateProps }) => {
        promises.push(updatePublishedPostAction({ action, updateProps }));
      });
    }

    if (changePatch.delete.length > 0) {
      changePatch.delete.forEach((actionId) => {
        promises.push(deletePublishedPostAction(actionId));
      });
    }

    try {
      await Promise.all(promises);
      createSnackBar({
        content: (
          <ChangesSavedSnackBarContent
            icon={<PublishedPostActionIcon className="h-5 w-5" />}
            message="After publish actions saved!"
          />
        ),
      });
      close();
    } catch (e: any) {
      setError({
        title: "Error saving actions",
        message: (
          <div>
            <p>Unable to save actions. Please try again.</p>
            <p>{e.message ? e.message : e}</p>
          </div>
        ),
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Modal
      {...modalProps}
      isVisible={isVisible}
      close={() => {
        if (isDirty) {
          showModal("confirmClose", {
            onConfirm: close,
          });
          // Return false to prevent this modal from being
          // removed from the open/close stack.
          return false;
        } else {
          close();
        }
      }}
    >
      <ContentSwitchContainer
        contentId={
          isLoading
            ? "loading"
            : isError
            ? "error"
            : hasAccess
            ? hasPlatformEntities
              ? "form"
              : "noplatformentities"
            : "nosubscription"
        }
      >
        <ContentSwitchContent contentId="loading">
          <div
            className="p-2 sm:p-8 flex items-center justify-center"
            style={{ width: "80rem", height: "34rem" }}
          >
            <div className="flex items-center">
              <LoaderIcon className="h-10 w-10" />
              <span className="ml-2 text-lg">Loading actions...</span>
            </div>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="error">
          <div
            className="p-2 sm:p-8 flex items-center justify-center"
            style={{ width: "80rem", height: "34rem" }}
          >
            <div className="flex flex-col items-center">
              <ErrorImage className="h-80 text-red-500" />
              <span className="mt-4 text-lg text-red-500">
                Unable to load actions. Please reload the page and try again.
              </span>
            </div>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="nosubscription">
          <div className="p-2 sm:p-8 flex flex-col items-center">
            <div style={{ width: 600 }}>
              <H2>More than simple publishing?</H2>
              <p className="mt-6">
                Social media doesn't stop at simply publishing content. Increase
                your audience engagement by adding{" "}
                <strong>after publish actions</strong> for your posts!
              </p>
              <p className="mt-4">
                Quickly and easily schedule actions such as comments, reactions,
                and reshares to automatically occur after your post has been
                published.
              </p>
              {hasNeverHadSubscription ? (
                <p className="mt-4">
                  Try our{" "}
                  <strong>
                    {config.prices.PUBLISH_MONTHLY.displayName} plan
                  </strong>{" "}
                  for{" "}
                  <strong>
                    free for {config.DEFAULT_TRIAL_PERIOD_DAYS} days
                  </strong>{" "}
                  (no credit card required) to create after publish actions for
                  your posts.
                </p>
              ) : (
                <p className="mt-4">
                  Upgrade to our{" "}
                  <strong>
                    {config.prices.PUBLISH_MONTHLY.displayName} plan
                  </strong>{" "}
                  to create after publish actions for your posts.
                </p>
              )}
            </div>
            <PrimaryButton
              className="mt-10"
              onClick={() =>
                showModal("changeSubscription", {
                  workspace,
                  redirectOnSuccess: false,
                  defaultPriceId: config.prices.PUBLISH_MONTHLY.id,
                })
              }
            >
              <StarIcon className="mr-2" />
              {hasNeverHadSubscription
                ? `Start your ${config.DEFAULT_TRIAL_PERIOD_DAYS}-day free trial`
                : `Upgrade to our ${config.prices.PUBLISH_MONTHLY.displayName} plan to create after publish actions`}
            </PrimaryButton>
            {hasNeverHadSubscription && (
              <p className="mt-2">No credit card required!</p>
            )}
            <PublishedPostActionImage
              className="mt-12 flex-shrink-0 text-purple-500"
              style={{ width: 400, height: 400 }}
            />
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="noplatformentities">
          <div className="p-2 sm:p-8 flex items-center justify-center">
            <div className="flex flex-col items-center w-96">
              <H2 className="text-2xl text-black w-full">
                No enabled socials?
              </H2>
              <p className="mt-2">
                Get started by enabling a social account before adding any after
                publish actions.
              </p>
              <NoPlatformsImage
                className="mt-8 w-full text-purple-500"
                height={200}
                width={200}
              />
              <InternalPrimaryButtonLink
                className="mt-8 w-full"
                to="/socials"
                onClick={() => {
                  close();
                }}
              >
                Enable a social account
              </InternalPrimaryButtonLink>
            </div>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="form">
          <div className="p-2 sm:p-8">
            <div style={{ width: "80rem" }}></div>
            <div className="flex flex-wrap justify-around gap-8">
              <div
                className="flex-1 flex flex-col"
                style={{ minWidth: 480, maxWidth: 600 }}
              >
                <H2 className="shrink-0 w-full flex items-center justify-center text-2xl text-black">
                  <PublishedPostActionIcon className="h-6 w-6" />
                  <span className="ml-2">After publish actions</span>
                </H2>
                {post.type === "LINKEDIN" && (
                  <LinkedInForm
                    className="grow"
                    post={post}
                    platforms={platforms}
                    defaultValues={
                      {
                        actions: buildFormDataForPublishedPostActions(actions),
                      } as LinkedInFormValues
                    }
                    onSubmit={handleSubmit}
                    onIsDirty={setIsDirty}
                    isSubmitting={isSubmitting}
                    endOfFormElement={errorAlert({ className: "mt-8" })}
                  />
                )}
                {post.type === "TWITTER" && (
                  <TwitterForm
                    className="grow"
                    post={post}
                    platforms={platforms}
                    defaultValues={
                      {
                        actions: buildFormDataForPublishedPostActions(actions),
                      } as TwitterFormValues
                    }
                    onSubmit={handleSubmit}
                    onIsDirty={setIsDirty}
                    isSubmitting={isSubmitting}
                    endOfFormElement={errorAlert({ className: "mt-8" })}
                  />
                )}
                {post.type === "FACEBOOK" && (
                  <FacebookForm
                    className="grow"
                    post={post}
                    platforms={platforms}
                    defaultValues={
                      {
                        actions: buildFormDataForPublishedPostActions(actions),
                      } as FacebookFormValues
                    }
                    onSubmit={handleSubmit}
                    onIsDirty={setIsDirty}
                    isSubmitting={isSubmitting}
                    endOfFormElement={errorAlert({ className: "mt-8" })}
                  />
                )}
              </div>
              <div
                className="flex-1 flex flex-col"
                style={{ minWidth: 480, minHeight: 400, maxWidth: 700 }}
              >
                <div className="h-8 relative shrink-0 flex items-center justify-center">
                  <H3 className="flex items-center text-xl text-gray-500">
                    <PlatformIcon type={post.type} />
                    <span className="ml-2">
                      {`${getPlatformTypeName(post.type)} post`}
                    </span>
                  </H3>
                </div>
                <div className="mt-4 grow w-full relative rounded-lg bg-gray-50 border border-gray-100 shadow">
                  <div className="p-2 absolute inset-0 overflow-x-hidden overflow-y-auto">
                    <div className="flex justify-center">
                      {postPlatformEntity && (
                        <PostPreview
                          post={post}
                          platformEntity={postPlatformEntity}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentSwitchContent>
      </ContentSwitchContainer>
    </Modal>
  );
};

export default EditPublishedPostActionsModal;
