import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "../../types/general";
import { PostAnalytics } from "../../types/postAnalytics";
import { POST_ANALYTICS_QUERY_KEYS } from "../queryKeys";

import { useApiClient } from "./app";

export const usePostAnalytics = <T extends PostAnalytics = PostAnalytics>(
  postId: string,
  options: { enabled?: boolean } = {}
) => {
  const apiClient = useApiClient();
  return useQuery(
    POST_ANALYTICS_QUERY_KEYS.detail(postId),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<T>>(
        `/post_analytics/${postId}`
      );

      return response.data;
    },
    options
  );
};
