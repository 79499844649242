import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StripeCardElement } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";

import { PaymentMethod } from "../../types/paymentMethods";
import { ApiResponse } from "../../types/general";

import { useApiClient } from "./app";
import { useDecoratedReactQuery } from "./general";
import {
  PAYMENT_METHODS_QUERY_KEYS,
  SUBSCRIPTIONS_QUERY_KEYS,
  USERS_QUERY_KEYS,
  WORKSPACES_QUERY_KEYS,
} from "../queryKeys";
import { createQueryCacheObject, removeQueryCacheObject } from "../queryCache";

export const usePaymentMethods = (userId: string) => {
  const apiClient = useApiClient();

  return useQuery(PAYMENT_METHODS_QUERY_KEYS.list({ userId }), async () => {
    const { data: response } = await apiClient.get<
      ApiResponse<PaymentMethod[]>
    >("/payment_methods", {
      params: {
        userId,
      },
    });

    return response.data;
  });
};

interface CreatePaymentMethodProps {
  cardElement: StripeCardElement;
}
export const useCreatePaymentMethod = () => {
  const stripe = useStripe();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useDecoratedReactQuery(
    useMutation(
      async ({ cardElement }: CreatePaymentMethodProps) => {
        if (!stripe) {
          throw new Error(`Unable to load stripe`);
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          throw new Error(error.message);
        } else if (!paymentMethod) {
          throw new Error("Unable to create payment method");
        }

        const { data: response } = await apiClient.post<
          ApiResponse<PaymentMethod>
        >("/payment_methods", {
          remotePaymentMethodId: paymentMethod.id,
        });

        return response.data;
      },
      {
        onSuccess: (paymentMethod) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: PAYMENT_METHODS_QUERY_KEYS.detail(paymentMethod.id),
              lists: [PAYMENT_METHODS_QUERY_KEYS.lists()],
            },
            paymentMethod.id,
            paymentMethod
          );

          queryClient.invalidateQueries(USERS_QUERY_KEYS.all);
        },
      }
    ),
    () => ({ title: "Failed to create payment method" })
  );
};

interface DeletePaymentMethodProps {
  paymentMethod: PaymentMethod;
  replacementPaymentMethodId?: string;
}
export const useDeletePaymentMethod = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useDecoratedReactQuery(
    useMutation(
      async ({
        paymentMethod,
        replacementPaymentMethodId,
      }: DeletePaymentMethodProps) => {
        let queryString = "";

        if (replacementPaymentMethodId) {
          const params = new URLSearchParams({ replacementPaymentMethodId });
          queryString = `?${params.toString()}`;
        }

        const { data: response } = await apiClient.delete<ApiResponse<null>>(
          `/payment_methods/${paymentMethod.id}${queryString}`
        );

        return response.data;
      },
      {
        onSuccess: (response, { paymentMethod }) => {
          removeQueryCacheObject(
            queryClient,
            {
              detail: PAYMENT_METHODS_QUERY_KEYS.detail(paymentMethod.id),
              lists: [PAYMENT_METHODS_QUERY_KEYS.lists()],
            },
            paymentMethod.id
          );

          queryClient.invalidateQueries(SUBSCRIPTIONS_QUERY_KEYS.all);
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.all);
        },
      }
    ),
    () => ({ title: "Failed to delete payment method" })
  );
};
