import { ComponentProps, forwardRef } from "react";
import { mergeClassNames } from "../../libs/components";
import Button from "./Button";

const PrimaryTextButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ className = "", ...props }, ref) => {
  return (
    <Button
      ref={ref}
      className={mergeClassNames(
        "text-purple-500 bg-gradient-to-r hover:from-purple-100 hover:to-blue-100 focus:from-purple-100 focus:to-blue-100",
        className
      )}
      {...props}
    />
  );
});

export default PrimaryTextButton;
