import React from "react";

import { MetricPropertyNames } from "../../../types/postAnalytics";
import {
  WorkspaceAnalytics,
  WorkspaceAnalyticsValueWithDelta,
} from "../../../types/workspaceAnalytics";

import { mergeClassNames } from "../../../libs/components";
import {
  MetricPropConfig,
  METRICS_CONFIG_BY_TYPE,
} from "../../../libs/analytics";
import MetricCard from "../../../components/analytics/overview/MetricCard";

function getDisplayNameForPeriod(period: WorkspaceAnalytics["period"]) {
  switch (period) {
    case "LAST_7_DAYS":
      return "7 days";
    case "LAST_14_DAYS":
      return "14 days";
    case "LAST_30_DAYS":
      return "30 days";
    case "LAST_60_DAYS":
      return "60 days";
  }
}

interface AnalyticsBodyOverviewProps {
  metricPropertyNames: MetricPropertyNames;
  periodMetricsTotals: WorkspaceAnalytics["periodMetricsTotals"];
  platformType: WorkspaceAnalytics["type"];
  period: WorkspaceAnalytics["period"];
  className?: string;
}
const AnalyticsBodyOverview: React.FC<AnalyticsBodyOverviewProps> = ({
  metricPropertyNames,
  periodMetricsTotals,
  platformType,
  period,
  className = "",
}) => {
  const config = METRICS_CONFIG_BY_TYPE[platformType];
  const periodDisplayName = `from prev ${getDisplayNameForPeriod(period)}`;

  return (
    <div
      className={mergeClassNames(
        "flex items-center flex-wrap gap-4",
        className
      )}
    >
      {metricPropertyNames.map((metricPropertyName) => {
        const metricTotals = periodMetricsTotals[
          metricPropertyName as keyof typeof periodMetricsTotals
        ] as WorkspaceAnalyticsValueWithDelta;

        if (!metricTotals) {
          return null;
        }

        const total = metricTotals.value;
        const deltaTotal = metricTotals.delta;
        const metricPropertyConfig = config[
          metricPropertyName as keyof typeof config
        ] as MetricPropConfig;

        return (
          <MetricCard
            key={metricPropertyName}
            className={metricPropertyConfig.bgClassNames}
            icon={metricPropertyConfig.icon}
            title={metricPropertyConfig.title}
            total={total}
            deltaTotal={deltaTotal}
            deltaPeriodDisplayName={periodDisplayName}
          />
        );
      })}
    </div>
  );
};

export default AnalyticsBodyOverview;
