import React from "react";
import { Star as StarIcon } from "react-feather";

interface DeletedSubscriptionSnackBarContentProps {
  subscriptionDisplayName: string;
}
const DeletedSubscriptionSnackBarContent: React.FC<
  DeletedSubscriptionSnackBarContentProps
> = ({ subscriptionDisplayName }) => {
  return (
    <div className="flex items-center">
      <StarIcon className="flex-shrink-0 w-4 h-4" />
      <span className="ml-2">
        <strong>{subscriptionDisplayName}</strong> subscription cancelled
      </span>
    </div>
  );
};

export default DeletedSubscriptionSnackBarContent;
