import React, { useMemo, useState, useEffect, useRef } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { ContentState, Modifier, SelectionState } from "draft-js";

import { mergeClassNames } from "../../../../libs/components";
import { shuffleArray } from "../../../../libs/utils";
import { ENTITY_TYPES } from "../../../../libs/postTextArea";

import rawPromptsOrg from "./prompts.json";
import { Controller, useFormContext } from "react-hook-form";
import { PostFormValues } from "../../../../types/posts";
import { CHARACTER_LIMIT } from "../textArea/schemas";
import TwitterText from "twitter-text";
import PostTextArea from "../TextArea";
import { Platform, SelectedEntities } from "../../../../types/platforms";
import PostFormRow from "../Row";
import ToneSelector from "../../../../components/aiPrompt/ToneSelector";
import WordCountSelector from "../../../../components/aiPrompt/WordCountSelector";
import SwitchButtons from "../../../../components/aiPrompt/SwitchButtonSelector";
import PrimaryButton from "../../../../components/buttons/Primary";
import SectionTitle from "../../../../components/aiPrompt/SsectionTitle";
import { useGetAiGeneratedTextSuggestion } from "../../../../libs/hooks/riteKit";

const PLACEHOLDER_REGEX = /(\[.*?\])/g;

interface PostTextAreaWritingPromptProps {
  close: () => void;
  setContentState: (newContentState: ContentState) => void;
  className?: string;
  promptsData: Prompt[] | undefined;
}

interface Prompt {
  prompt: string;
  example: string;
}

interface MentionsDrawerBase {
  isVisible: boolean;
}

interface TextAreaEmptyDrawer extends MentionsDrawerBase {
  type: "none";
}

interface TextAreaMentionsDrawer extends MentionsDrawerBase {
  type: "mentions";
  data: {
    blockKey: string;
    start: number;
    end: number;
    entityKey?: string;
    searchText?: string;
  };
}

const PostTextAreaWritingPrompt: React.FC<PostTextAreaWritingPromptProps> = ({
  close,
  setContentState,
  className = "",
  promptsData,
}) => {
  let rawPrompts = rawPromptsOrg;
  if (promptsData) {
    rawPrompts = promptsData;
  }

  const [promptIndex, setPromptIndex] = useState(0);

  const shuffledPrompts = useMemo(() => {
    return shuffleArray(rawPrompts);
  }, []);

  const showNewPrompt = async () => {
    if (promptIndex < rawPrompts.length - 1) {
      setPromptIndex(promptIndex + 1);
    } else {
      setPromptIndex(0);
    }
  };

  const useExample = () => {
    const contentState = ContentState.createFromText(
      rawPrompts[promptIndex].example
    );

    let contentStateWithPlaceholders = contentState;

    contentState.getBlockMap().forEach((block) => {
      if (block) {
        const blockKey = block.getKey();
        const blockText = block.getText();

        let match: RegExpExecArray | null = null;

        while ((match = PLACEHOLDER_REGEX.exec(blockText))) {
          const start = match.index;
          const end = match.index + match[0].length;

          contentStateWithPlaceholders =
            contentStateWithPlaceholders.createEntity(
              ENTITY_TYPES.PLACEHOLDER,
              "IMMUTABLE"
            );
          const entityKey =
            contentStateWithPlaceholders.getLastCreatedEntityKey();
          const entitySelection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start,
            focusOffset: end,
          });

          contentStateWithPlaceholders = Modifier.applyEntity(
            contentStateWithPlaceholders,
            entitySelection,
            entityKey
          );
        }
      }
    });

    setContentState(contentStateWithPlaceholders);
    close();
  };

  const platforms: Platform[] = [];
  const selectedEntities: SelectedEntities[] = useMemo(() => {
    return platforms.map((platform) => ({
      platform,
      entities: Object.values(platform.entities).filter(
        (entity) => !entity.disabled
      ),
    }));
  }, [platforms]);

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext<PostFormValues>();
  const { mutateAsync: getAiSuggestion } = useGetAiGeneratedTextSuggestion();
  const [writtenPrompt, setWrittenPrompt] = useState("");
  const [tone, setTone] = useState("polite");
  const [wordCount, setWordCount] = useState(50);
  const [hashtagsEnabled, setHashtagsEnabled] = useState(false);
  const [emojisEnabled, setEmojisEnabled] = useState(false);
  const [loadingAiSuggestions, setLoadingAiSuggestions] = useState(false);

  const handleHashtagChange = (value: boolean) => {
    setHashtagsEnabled(value);
  };

  const handleEmojiChange = (value: boolean) => {
    setEmojisEnabled(value);
  };
  const handleToneChange = (tone: string) => {
    setTone(tone);
  };

  const handleWordCountChange = (value: number) => {
    setWordCount(value);
  };

  const delayedWritingPrompt = (generatedPrompt: string) => {
    let index = 0;
    let printedPrompt = "";

    const timerId = setInterval(() => {
      // Get the next character from the generatedPrompt string
      const character = generatedPrompt.charAt(index);

      // If there are no more characters left, clear the interval
      if (!character) {
        clearInterval(timerId);
        return;
      }

      printedPrompt += character;

      // Create content state from the current character
      setContentState(ContentState.createFromText(printedPrompt));

      // Increment the index to get the next character in the next iteration
      index++;
    }, 20);
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    setLoadingAiSuggestions(true);
    try {
      if (writtenPrompt.trim().length > 0) {
        const response = await getAiSuggestion({
          action: "aiSuggestions",
          text: writtenPrompt,
          tone: tone,
          maximumWords: wordCount,
          generateHashtags: hashtagsEnabled,
          includeEmoji: emojisEnabled,
        });
        if ((response?.length ?? 0) > 0) {
          delayedWritingPrompt(response);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingAiSuggestions(false);
  }

  return (
    <form
      className={mergeClassNames("h-full p-4 flex flex-col gap-6", className)}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-row items-center gap-2">
        <SectionTitle title={"Your prompt"}></SectionTitle>
        <InformationCircleIcon className="mr-2 h-6 w-6" />
      </div>
      <div className="row relative w-full overflow-hidden rounded-lg transition-colors duration-300">
        <PostFormRow
          contentContainerClassName="overflow-hidden"
          icon={<div className="mt-3 flex flex-col items-center"></div>}
          content={
            <Controller
              control={control}
              name="contentState"
              render={({ field: { onChange, onBlur, value } }) => (
                <PostTextArea
                  bgColor={"bg-gray-50"}
                  placeHolder={
                    "Write me an essay about the use of AI in software industry"
                  }
                  textAreaContainerClassName="h-32"
                  selectedEntities={selectedEntities}
                  value={value}
                  onChange={(newContentState) =>
                    setWrittenPrompt(newContentState.getPlainText())
                  }
                  onBlur={() => {}}
                  characterLimit={CHARACTER_LIMIT.TWITTER}
                  countCharacters={(text: string) => {
                    const { weightedLength } = TwitterText.parseTweet(text);
                    return weightedLength;
                  }}
                  enableHashtagSuggestions={false}
                />
              )}
            />
          }
        />
      </div>
      <ToneSelector onChange={handleToneChange} selectedTone={tone} />
      <WordCountSelector onChange={handleWordCountChange} />
      <SwitchButtons
        onHashtagChange={handleHashtagChange}
        onEmojiChange={handleEmojiChange}
      />
      <PrimaryButton type={"submit"} disabled={loadingAiSuggestions}>
        {loadingAiSuggestions ? "Generating Suggestion" : "Generate"}
      </PrimaryButton>
    </form>
  );
};

export default PostTextAreaWritingPrompt;
