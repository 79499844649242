import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AlertCircle as AlertCircleIcon,
  ArrowLeft as ArrowLeftIcon,
  CheckCircle as CheckCircleIcon,
  Plus as PlusIcon,
} from "react-feather";

import { CreatePostProps, Post, PostFormValues } from "../../../types/posts";

import { postFormDataToCreatePostProps } from "../../../libs/post";
import { useCreatePosts } from "../../../libs/hooks/posts";
import { useCreatePublishedPostActions } from "../../../libs/hooks/publishedPostActions";
import {
  useContentStack,
  useSnackBarFactory,
} from "../../../libs/hooks/general";
import { useShowModal } from "../../../libs/hooks/app";

import { ReactComponent as CreatePostImage1 } from "../../../images/create-post/create-post-1.svg";
import { ReactComponent as CreatePostImage2 } from "../../../images/create-post/create-post-2.svg";
import { ReactComponent as ErrorImage } from "../../../images/create-post/error.svg";
import { ReactComponent as SuccessImage } from "../../../images/create-post/success.svg";

import H2 from "../../../components/headings/H2";
import LoaderIcon from "../../../components/icons/Loader";
import CreatedPostsSnackBarContent from "../../../components/snackBarContent/CreatedPosts";
import TertiaryButton from "../../../components/buttons/Tertiary";
import PrimaryButton from "../../../components/buttons/Primary";
import PublishedPostActionIcon from "../../../components/icons/PublishedPostAction";
import ContentSwitchContainer from "../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../components/animations/contentSwitch/Content";
import { PlatformType } from "../../../types/enums/platformType";
import bulkUpload from "../../forms/post/input/BulkUpload";
import { PublishedPostAction } from "../../../types/publishedPostActions";

interface CreatePostModalSubmitProps {
  contentId: "submit";
  close: () => void;
  resetState: () => void;
  setPreventModalClose: (value: boolean) => void;
  postFormDataByPlatformType: {
    [platformType: string]: PostFormValues;
  };
}

const CreatePostModalSubmit: React.FC<CreatePostModalSubmitProps> = ({
  close,
  resetState,
  setPreventModalClose,
  postFormDataByPlatformType,
}) => {
  const firstRef = useRef(true);
  const [createdPosts, setCreatedPosts] = useState<Post[]>([]);
  const { push, pop } = useContentStack();
  const {
    isError,
    isLoading,
    errorAlert,
    mutateAsync: createPosts,
  } = useCreatePosts();
  const { mutateAsync: createPublishedPostActions } =
    useCreatePublishedPostActions();
  const createSnackBar = useSnackBarFactory();
  const showModal = useShowModal();
  const createPostProps = Object.values(postFormDataByPlatformType).reduce<
    CreatePostProps[]
  >((carry, postFormData) => {
    return carry.concat(postFormDataToCreatePostProps(postFormData));
  }, []);
  const linkedInPostFormValues =
    postFormDataByPlatformType[PlatformType.LINKEDIN];
  const fromBulkUpload = linkedInPostFormValues?.bulkUpload;
  const afterPostActionsWithoutPostIds =
    linkedInPostFormValues?.afterPostActions;
  const assignPostIdIntoAllAfterPostAction = (
    afterPostActions: PublishedPostAction[],
    postId: string
  ) => {
    afterPostActions.map((item) => (item.postId = postId));
    return afterPostActions;
  };
  const isCreatingPosts =
    (firstRef.current || isLoading) && createdPosts.length < 1;
  const failedCreatingPosts =
    !isCreatingPosts && isError && createdPosts.length < 1;
  const [isMultiPost] = useState(createPostProps.length > 1);
  const [canAddPublishedPostActions] = useState(
    Object.values(postFormDataByPlatformType).some((formValues) => {
      return formValues.socials.some(({ platform }) => {
        return platform.type !== "FACEBOOK";
      });
    })
  );
  const [createPostCount] = useState(createPostProps.length);

  const handleCreatePosts = useCallback(async () => {
    setPreventModalClose(true);

    try {
      const posts = await createPosts(createPostProps);

      const count = createPostProps.length;
      let scheduled: string | undefined = undefined;

      if (count > 0 && fromBulkUpload) {
        try {
          const postId = posts[0].id;
          const publishedPostActions = assignPostIdIntoAllAfterPostAction(
            afterPostActionsWithoutPostIds ?? [],
            postId
          );
          await createPublishedPostActions(publishedPostActions);
        } catch (e) {}
      }

      for (const createProps of createPostProps) {
        if (
          createProps.status === "SCHEDULED" ||
          createProps.status === "DRAFT"
        ) {
          scheduled = createProps.scheduled;
          break;
        }
      }

      createSnackBar({
        content: (
          <CreatedPostsSnackBarContent count={count} scheduled={scheduled} />
        ),
      });

      setCreatedPosts(posts);
      resetState();
    } catch (e) {}
    setPreventModalClose(false);
  }, [
    createPostProps,
    createPosts,
    createSnackBar,
    resetState,
    setPreventModalClose,
  ]);

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
      handleCreatePosts();
    }
  }, [handleCreatePosts]);

  return (
    <ContentSwitchContainer
      contentId={
        isCreatingPosts ? "creating" : failedCreatingPosts ? "error" : "success"
      }
    >
      <ContentSwitchContent contentId="creating">
        <div
          className="p-8 w-full flex flex-col items-center justify-center"
          style={{ width: "42rem" }}
        >
          <div className="w-full flex items-bottom justify-around">
            <CreatePostImage1 className="h-56 w-56 text-purple-500" />
            <CreatePostImage2 className="h-56 w-56 text-purple-500" />
          </div>
          <H2 className="mt-12 w-full flex items-center justify-center text-3xl text-black">
            <LoaderIcon className="mr-2 flex-shrink-0 h-8 w-8" />
            {`Creating ${createPostCount} post${
              createPostCount > 1 ? "s" : ""
            }...`}
          </H2>
        </div>
      </ContentSwitchContent>
      <ContentSwitchContent contentId="error">
        <div
          className="p-8 flex flex-col items-center justify-center"
          style={{ width: "42rem" }}
        >
          <ErrorImage className="w-96 h-full text-red-500" />
          <H2 className="mt-8 w-full flex items-center text-xl text-red-500">
            <AlertCircleIcon className="flex-shrink-0 h-8 w-8" />
            <span className="ml-2">Error creating posts</span>
          </H2>
          {errorAlert({ className: "mt-2" })}
          <div className="mt-8 w-full flex items-center">
            <TertiaryButton className="mr-2 w-full" onClick={() => pop()}>
              <ArrowLeftIcon className="flex-shrink-0 h-6 w-6" />
              <span className="ml-2">Back</span>
            </TertiaryButton>
            <PrimaryButton
              className="ml-2 w-full"
              onClick={() => handleCreatePosts()}
            >
              Try again
            </PrimaryButton>
          </div>
        </div>
      </ContentSwitchContent>
      <ContentSwitchContent contentId="success">
        <div className="w-full h-full flex">
          <div className="p-4">
            <H2 className="text-2xl">Anything else?</H2>

            {canAddPublishedPostActions && (
              <div className="mt-8 w-96">
                <p>
                  Add one or more actions to be automatically executed once your
                  post is published.
                </p>
                <p className="mt-2">
                  For example, create a comment on your post or reshare it will
                  a different account.
                </p>
                <PrimaryButton
                  className="mt-4 w-full"
                  onClick={() => {
                    if (isMultiPost) {
                      showModal("multiPostPublishedPostActions", {
                        posts: createdPosts,
                      });
                    } else {
                      showModal("editPublishedPostActions", {
                        post: createdPosts[0],
                      });
                    }

                    close();
                  }}
                >
                  <PublishedPostActionIcon className="h-6 w-6" />
                  <span className="ml-2">Add after publish actions</span>
                </PrimaryButton>
              </div>
            )}
            {canAddPublishedPostActions && (
              <div className="m-8 relative h-px bg-gray-300 flex items-center justify-center">
                <div className="p-2 bg-white text-sm">OR</div>
              </div>
            )}
            <div className={`w-96 ${canAddPublishedPostActions ? "" : "mt-8"}`}>
              <p>
                Got more to say? Jump back to the start and create some more
                posts!
              </p>
              <TertiaryButton
                className="mt-4 w-full"
                onClick={() => {
                  push("home");
                }}
              >
                <PlusIcon className="h-6 w-6" />
                <span className="ml-2">Create more content</span>
              </TertiaryButton>
            </div>
            <div className="m-8 relative h-px bg-gray-300 flex items-center justify-center">
              <div className="p-2 bg-white text-sm">OR</div>
            </div>
            <TertiaryButton
              className="w-full"
              onClick={() => {
                close();
              }}
            >
              <span className="ml-2">No, thanks. I'm finished.</span>
            </TertiaryButton>
          </div>
          <div className="p-8 w-80 flex-1 flex flex-col items-center justify-center bg-gray-100">
            <SuccessImage className="h-56 w-56 text-green-500" />
            <H2 className="w-full flex items-center justify-center text-3xl text-green-500">
              <CheckCircleIcon className="mr-2 flex-shrink-0 h-8 w-8" />
              {`Post${createPostProps.length > 1 ? "s" : ""} created!`}
            </H2>
          </div>
        </div>
      </ContentSwitchContent>
    </ContentSwitchContainer>
  );
};

export default CreatePostModalSubmit;
