import React, { ComponentProps } from "react";

import ContentSwitchContext from "../../contexts/ContentSwitch";
import ContextProvider from "../../providers/ContentSwitch";
import Container from "./contentSwitch/Container";

interface ContentSwitchProps
  extends Omit<ComponentProps<typeof Container>, "contentId"> {
  defaultContentId?: string;
}

const ContentSwitch: React.FC<ContentSwitchProps> = ({
  defaultContentId = "home",
  ...containerProps
}) => {
  return (
    <ContextProvider defaultContentId={defaultContentId}>
      <ContentSwitchContext.Consumer>
        {(context) => (
          <Container
            contentId={context.content.id}
            transitionSpeed={context.content.immediate ? "immediate" : "normal"}
            {...containerProps}
          />
        )}
      </ContentSwitchContext.Consumer>
    </ContextProvider>
  );
};

export default ContentSwitch;
