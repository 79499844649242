import React, { Fragment } from "react";

interface ContentSwitchContentProps {
  contentId: string;
  children: React.ReactElement;
}

const ContentSwitchContent: React.FC<ContentSwitchContentProps> = ({
  contentId,
  children,
}) => {
  return <Fragment key={contentId}>{children}</Fragment>;
};

export default ContentSwitchContent;
