import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Editor,
  EditorState,
  CompositeDecorator,
  ContentState,
  convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import equal from "fast-deep-equal";
import { X as XIcon } from "react-feather";

import { Platform, SelectedEntities } from "../../../types/platforms";

import { mergeClassNames } from "../../../libs/components";
import { ensureUrlHasProtocol } from "../../../libs/utils";
import {
  getEntitiesByTypeFromContentState,
  appendEntityToEndOfContent,
  ENTITY_TYPES,
  removeTextFromBlock,
  createEntityInBlockAtLocation,
} from "../../../libs/postTextArea";
import {
  useAnimatedMounting,
  useOpenCloseStack,
  usePrevious,
} from "../../../libs/hooks/general";

import TertiaryButton from "../../../components/buttons/Tertiary";
import H3 from "../../../components/headings/H3";

import buildHashtag from "./textArea/decorators/Hashtag";
import buildLink from "./textArea/decorators/Link";
import buildMention from "./textArea/decorators/Mention";
import buildMentionCandidate from "./textArea/decorators/MentionCandidate";
import buildPlaceholder from "./textArea/decorators/Placeholder";
import HashtagSuggestions from "./textArea/HashtagSuggestions";
import MentionsDrawer from "./textArea/drawers/Mentions";

interface MentionsDrawerBase {
  isVisible: boolean;
}

interface TextAreaEmptyDrawer extends MentionsDrawerBase {
  type: "none";
}

interface TextAreaMentionsDrawer extends MentionsDrawerBase {
  type: "mentions";
  data: {
    blockKey: string;
    start: number;
    end: number;
    entityKey?: string;
    searchText?: string;
  };
}

type TextAreaDrawers = TextAreaEmptyDrawer | TextAreaMentionsDrawer;

function defaultCountCharacters(text: string) {
  return text.length;
}

const contentChangeCache = new WeakMap<
  ContentState,
  WeakMap<ContentState, boolean>
>();

function hasContentStateChanged(
  a: ContentState | null,
  b: ContentState | null
): boolean {
  if (a === b) {
    return false;
  }

  if (a === null) {
    return true;
  }

  if (b === null) {
    return true;
  }

  // Since ContentState is an immutable object we can use a weak map to cachea
  // the result of the comparisons between the two values so that we don't need
  // to compute the convert to raw and deep compare on each call to this function.
  // We keep two caches so that the objects can be provided to this function in
  // any order, e.g. hasContentStateChanged(a, b) === hasContentStateChanged(b, a)
  const aCache = contentChangeCache.get(a);
  const bCache = contentChangeCache.get(b);
  const hasChanged =
    aCache && aCache.has(b)
      ? aCache.get(b)!
      : bCache && bCache.has(a)
      ? bCache.get(a)!
      : !equal(convertToRaw(a), convertToRaw(b));

  if (aCache) {
    if (!aCache.has(b)) {
      aCache.set(b, hasChanged);
    }
  } else {
    const cache = new WeakMap<ContentState, boolean>();
    cache.set(b, hasChanged);
    contentChangeCache.set(a, cache);
  }

  if (bCache) {
    if (!bCache.has(a)) {
      bCache.set(a, hasChanged);
    }
  } else {
    const cache = new WeakMap<ContentState, boolean>();
    cache.set(a, hasChanged);
    contentChangeCache.set(b, cache);
  }

  return hasChanged;
}

function getHashtagsFromEditorState(editorState: EditorState) {
  const entitiesByType = getEntitiesByTypeFromContentState(
    editorState.getCurrentContent()
  );

  return Object.values(entitiesByType.HASHTAG).map(({ entity }) => {
    return entity.getData().hashtag;
  });
}

interface PostTextAreaProps {
  selectedEntities: SelectedEntities[];
  value: ContentState;
  placeHolder?: string;
  onChange: (newContent: ContentState) => void;
  onBlur: () => void;
  className?: string;
  textAreaContainerClassName?: string;
  error?: boolean;
  characterLimit?: number;
  countCharacters?: (text: string) => number;
  onClickUrl?: (url: string) => void;
  enableHashtagSuggestions?: boolean;
  bgColor?: string;
}

const PostTextArea: React.FC<PostTextAreaProps> = ({
  selectedEntities,
  value,
  placeHolder = "",
  onChange,
  onBlur,
  className = "",
  textAreaContainerClassName = "",
  error = false,
  characterLimit,
  countCharacters = defaultCountCharacters,
  onClickUrl,
  enableHashtagSuggestions = true,
  bgColor,
}) => {
  const editorRef = useRef<Editor | null>(null);
  const [drawer, setDrawer] = useState<TextAreaDrawers>({
    type: "none",
    isVisible: false,
  });
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(value)
  );
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const animateDrawer = useAnimatedMounting({
    mount: drawer.isVisible,
  });
  const selectedEntitiesByPlatformType = useMemo(() => {
    return selectedEntities.reduce<{
      [platformType: string]: SelectedEntities[];
    }>((carry, selected) => {
      const platformType = selected.platform.type;
      if (carry[platformType]) {
        carry[platformType].push(selected);
      } else {
        carry[platformType] = [selected];
      }

      return carry;
    }, {});
  }, [selectedEntities]);
  const platformTypes = useMemo(
    () => Object.keys(selectedEntitiesByPlatformType) as Platform["type"][],
    [selectedEntitiesByPlatformType]
  );
  const singularPlatformType =
    platformTypes.length === 1 ? platformTypes[0] : null;
  const hasCharacterLimit = !!characterLimit;
  const currentContentState = editorState.getCurrentContent();
  const previousValue = usePrevious(value);
  const characterCount = countCharacters(currentContentState.getPlainText());

  const openDrawer = useCallback((drawer: TextAreaDrawers) => {
    setDrawer(drawer);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawer((prevDrawer) => ({ ...prevDrawer, isVisible: false }));

    window.requestAnimationFrame(() => {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    });
  }, []);

  const handleLinkClick = useCallback(
    (blockKey: string, start: number, end: number, url: string) => {
      setEditorState((prevEditorState) => {
        const contentState = prevEditorState.getCurrentContent();
        const block = contentState.getBlockForKey(blockKey);

        if (!block) {
          return prevEditorState;
        }

        const entitiesByType = getEntitiesByTypeFromContentState(contentState);
        const linkEntities = entitiesByType.LINK;
        let newContentState = contentState;
        let hasChanged = false;
        let entityExists = false;

        // Iterate over the link entities and ensure that only the one that was
        // just clicked is flagged to be used as an attachment.
        for (const linkEntity of Object.values(linkEntities)) {
          let newUseAsAttachment = false;

          if (
            !entityExists &&
            linkEntity.blockKey === blockKey &&
            linkEntity.start === start
          ) {
            entityExists = true;
            newUseAsAttachment = true;
          }

          const linkEntityData = linkEntity.entity.getData();

          if (linkEntityData.useAsAttachment !== newUseAsAttachment) {
            hasChanged = true;
            newContentState = newContentState.mergeEntityData(
              linkEntity.entityKey,
              {
                useAsAttachment: newUseAsAttachment,
              }
            );
          }
        }

        if (!hasChanged && entityExists) {
          // The URL was already set as an attachment. Nothing to do.
          return prevEditorState;
        }

        if (!entityExists) {
          newContentState = createEntityInBlockAtLocation(
            contentState,
            blockKey,
            start,
            end,
            "LINK",
            {
              text: url,
              url: ensureUrlHasProtocol(url),
              useAsAttachment: true,
            }
          );
        }

        return EditorState.push(
          prevEditorState,
          newContentState,
          "apply-entity"
        );
      });
    },
    []
  );

  const { decorators } = useMemo(() => {
    const { decorators: hashtagDecorators } = buildHashtag({});
    const { decorators: linkDecorators } = buildLink({
      onClick: (blockKey, start, end, url) => {
        // We need to update the editor state in an animation frame because the
        // act of a click causes the editor component to re-render and update its
        // state which ends up overwriting this change. So instead we will let it do
        // its update and then apply the changes from the click.
        window.requestAnimationFrame(() => {
          handleLinkClick(blockKey, start, end, url);

          if (onClickUrl) {
            onClickUrl(ensureUrlHasProtocol(url));
          }
        });
      },
    });
    const { decorators: mentionDecorators } = buildMention({
      singularPlatformType,
      onClick: (blockKey, entityKey, start, end) => {
        openDrawer({
          isVisible: true,
          type: "mentions",
          data: {
            blockKey,
            start,
            end,
            entityKey,
          },
        });
      },
    });
    const { decorators: mentionCandidateDecorators } = buildMentionCandidate({
      onClick: (blockKey, start, end, searchText) => {
        openDrawer({
          isVisible: true,
          type: "mentions",
          data: {
            blockKey,
            start,
            end,
            searchText,
          },
        });
      },
    });
    const { decorators: placeholderDecorators } = buildPlaceholder({
      onClick: (blockKey, start, end) => {
        // We need to update the editor state in an animation frame because the
        // act of a click causes the editor component to re-render and update its
        // state which ends up overwriting this change. So instead we will let it do
        // its update and then apply the changes from the click.
        window.requestAnimationFrame(() => {
          setEditorState((prevEditorState) => {
            const newEditorState = removeTextFromBlock(
              prevEditorState,
              blockKey,
              start,
              end
            );

            return newEditorState;
          });
        });
      },
    });

    return {
      decorators: [
        ...hashtagDecorators,
        ...linkDecorators,
        ...mentionDecorators,
        ...mentionCandidateDecorators,
        ...placeholderDecorators,
      ],
    };
  }, [handleLinkClick, onClickUrl, openDrawer, singularPlatformType]);

  const handleEditorOnFocus = useCallback(() => {
    setEditorHasFocus(true);
  }, []);

  const handleEditorOnBlur = useCallback(() => {
    setEditorHasFocus(false);
    onBlur();
  }, [onBlur]);

  const drawerGlobalInteractionHandlers = useOpenCloseStack(
    drawer.isVisible,
    () => closeDrawer()
  );

  useEffect(() => {
    setEditorState((prevEditorState) => {
      return EditorState.set(prevEditorState, {
        decorator: new CompositeDecorator(decorators),
      });
    });
  }, [decorators]);

  useEffect(() => {
    if (hasContentStateChanged(value, currentContentState)) {
      if (hasContentStateChanged(previousValue, value)) {
        setEditorState((prevEditorState) =>
          EditorState.push(prevEditorState, value, "insert-characters")
        );
      } else {
        onChange(currentContentState);
      }
    }
  }, [currentContentState, value, previousValue, onChange]);

  return (
    <div>
      <div
        className={`relative w-full overflow-hidden rounded-lg border-2 transition-colors duration-300 ${
          error
            ? "border-red-500"
            : drawer.isVisible
            ? "border-gray-100 focus-within:border-purple-500"
            : "border-transparent hover:bg-gray-100 focus-within:border-purple-500 hover:focus-within:bg-white"
        } ${bgColor ? bgColor : "bg-white"}`}
      >
        <div
          className={mergeClassNames(
            "py-2 px-4 w-full relative cursor-text overflow-x-hidden overflow-y-auto",
            className
          )}
          onClick={() => {
            if (editorRef.current) {
              editorRef.current.focus();
            }
          }}
        >
          <div className={mergeClassNames("h-64", textAreaContainerClassName)}>
            <Editor
              ref={editorRef}
              editorState={editorState}
              placeholder={placeHolder}
              spellCheck={true}
              stripPastedStyles={true}
              onChange={setEditorState}
              onFocus={handleEditorOnFocus}
              onBlur={handleEditorOnBlur}
            />
          </div>
        </div>
        <div className="px-1 pb-1 h-8 flex items-end">
          <span
            className={`px-1 ml-auto text-xs default-transition ${
              error ? "text-red-500" : "text-gray-400"
            } ${
              hasCharacterLimit && editorHasFocus ? "opacity-100" : "opacity-0"
            }`}
          >
            {hasCharacterLimit
              ? `${characterCount}/${characterLimit}`
              : characterCount}
          </span>
        </div>
        {animateDrawer(({ phase, animationEventHandlers }) => {
          let containerAnimationClassName = "";
          let drawerAnimationClassName = "";

          switch (phase) {
            case "mounting":
              containerAnimationClassName = "animate-fade-in";
              drawerAnimationClassName = "animate-slide-in-from-right";
              break;
            case "unmounting":
              containerAnimationClassName = "animate-fade-out";
              drawerAnimationClassName = "animate-slide-out-to-right";
              break;
          }

          return phase === "unmounted" ? null : (
            <div
              className={`absolute inset-0 z-10 bg-gray-900 bg-opacity-20 transition-opacity ${containerAnimationClassName}`}
              {...animationEventHandlers}
            >
              <div
                className={`absolute top-0 right-0 flex flex-col h-full w-72 rounded-l-lg shadow-lg bg-white overflow-hidden ${drawerAnimationClassName}`}
                {...drawerGlobalInteractionHandlers}
              >
                <div className="pl-2 pr-1 shrink-0 flex items-center">
                  <H3 className="text-xl">
                    {drawer.type === "mentions" ? "Mentions" : ""}
                  </H3>
                  <TertiaryButton
                    className="ml-auto bg-gray-100"
                    size="xs"
                    onClick={() => closeDrawer()}
                  >
                    <XIcon className="mr-1 h-4 w-4" />
                    Close
                  </TertiaryButton>
                </div>

                <div className="grow overflow-hidden">
                  {drawer.type === "mentions" && (
                    <MentionsDrawer
                      close={closeDrawer}
                      selectedEntities={selectedEntities}
                      editorState={editorState}
                      setEditorState={setEditorState}
                      blockKey={drawer.data.blockKey}
                      start={drawer.data.start}
                      end={drawer.data.end}
                      entityKey={drawer.data.entityKey}
                      searchText={drawer.data.searchText}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {enableHashtagSuggestions && (
        <HashtagSuggestions
          text={currentContentState.getPlainText().trim()}
          existing={getHashtagsFromEditorState(editorState)}
          onClick={(newHashTag) => {
            let newContentState = appendEntityToEndOfContent(
              currentContentState,
              ENTITY_TYPES.HASHTAG,
              { hashtag: newHashTag },
              `#${newHashTag}`
            );

            setEditorState(
              EditorState.push(
                editorState,
                newContentState,
                "insert-characters"
              )
            );
          }}
        />
      )}
    </div>
  );
};

export default PostTextArea;
