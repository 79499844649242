import React, { ReactNode } from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";

interface ChangesSavedSnackBarContentProps {
  message?: string;
  icon?: ReactNode;
}
const ChangesSavedSnackBarContent: React.FC<
  ChangesSavedSnackBarContentProps
> = ({
  message = "Changes saved",
  icon = <CheckCircleIcon className="flex-shrink-0 w-5 h-5" />,
}) => {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 w-5 h-5">{icon}</div>
      <span className="ml-2">{message}</span>
    </div>
  );
};

export default ChangesSavedSnackBarContent;
