import React from "react";

import { WorkspaceAnalytics } from "../../types/workspaceAnalytics";
import { MetricPropertyNames } from "../../types/postAnalytics";

import { POST_PREVIEW_WIDTH } from "../../libs/post";

import H3 from "../../components/headings/H3";
import DailySnapshot from "../../components/analytics/DailySnapshot";
import OverTime from "../../components/analytics/OverTime";
import InteractionsHeatMap from "../../components/analytics/InteractionsHeatMap";
import CommentsSentiment from "../../components/analytics/CommentsSentiment";

import Overview from "./body/Overview";
import Details from "./body/Details";
import TopPosts from "./body/TopPosts";

interface AnalyticsBodyProps {
  metricPropertyNames: MetricPropertyNames;
  analytics: WorkspaceAnalytics;
  className?: string;
}
const AnalyticsBody: React.FC<AnalyticsBodyProps> = ({
  metricPropertyNames,
  analytics,
  className = "",
}) => {
  return (
    <div className={className} style={{ maxWidth: "90rem" }}>
      <div className="mt-12 flex flex-wrap gap-4 lg:flex-nowrap">
        <div className="grow">
          <H3 className="text-normal uppercase text-gray-400">Overview</H3>
          <Overview
            className="mt-2"
            metricPropertyNames={metricPropertyNames}
            periodMetricsTotals={analytics.periodMetricsTotals}
            platformType={analytics.type}
            period={analytics.period}
          />
        </div>
        <div className="mt-12 lg:mt-0">
          <H3 className="text-normal uppercase text-gray-400">Details</H3>
          <Details
            className="mt-2"
            postsCount={analytics.postsCount}
            lastUpdated={analytics.lastUpdated}
          />
        </div>
      </div>

      <H3 className="mt-12 text-normal uppercase text-gray-400">Over time</H3>
      <OverTime
        className="mt-2"
        metricPropertyNames={metricPropertyNames}
        platformType={analytics.type}
        utcOffset={analytics.utcOffset}
        periodStart={analytics.periodStart}
        byDayOffset={analytics.byDayOffset}
        disableScaleSelect={true}
      />

      <div className="mt-12 flex flex-wrap lg:flex-nowrap">
        <div className="grow">
          <H3 className="text-normal uppercase text-gray-400">
            Daily snapshot
          </H3>
          <DailySnapshot
            className="mt-2"
            metricPropertyNames={metricPropertyNames}
            platformType={analytics.type}
            utcOffset={analytics.utcOffset}
            periodStart={analytics.periodStart}
            byDayOffset={analytics.byDayOffset}
          />

          <H3 className="mt-12 text-normal uppercase text-gray-400">
            Interactions heat map
          </H3>
          <div className="mt-2" style={{ width: 0, minWidth: "100%" }}>
            <InteractionsHeatMap interactions={analytics.interactions} />
          </div>

          <H3 className="mt-12 text-normal uppercase text-gray-400">
            Comments sentiment
          </H3>
          <CommentsSentiment className="mt-2" comments={analytics.comments} />
        </div>
        <div
          className="mt-12 ml-0 shrink-0 lg:mt-0 lg:ml-12"
          style={{ width: POST_PREVIEW_WIDTH[analytics.type] }}
        >
          <H3 className="text-normal uppercase text-gray-400">Top posts</H3>
          <TopPosts
            metricPropertyNames={metricPropertyNames}
            topPosts={analytics.topPosts}
            platformType={analytics.type}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsBody;
