import React, { ComponentProps } from "react";
import { mergeClassNames } from "../../libs/components";

import Badge from "./Badge";

interface SuccessBadgeProps extends ComponentProps<typeof Badge> {}

const SuccessBadge: React.FC<SuccessBadgeProps> = ({
  className = "",
  ...props
}) => {
  return (
    <Badge
      className={mergeClassNames("bg-green-100 text-green-700", className)}
      {...props}
    />
  );
};

export default SuccessBadge;
