import React, { ComponentProps, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { ChevronRight as ChevronRightIcon } from "react-feather";
import { DocumentIcon, DocumentDuplicateIcon } from "@heroicons/react/outline";

import { Platform } from "../../../types/platforms";
import { PostFormValues } from "../../../types/posts";
import { useContentStack } from "../../../libs/hooks/general";

import H2 from "../../../components/headings/H2";
import PrimaryButton from "../../../components/buttons/Primary";

import PostForm from "../../forms/Post";

interface CreatePostModalHomeProps {
  contentId: "home";
  platforms: Platform[];
  timeZone: string;
  defaultValues?: Partial<PostFormValues>;
  getNextContentId: (formData: PostFormValues) => string;
  onDirtyChange: (newIsDirty: boolean) => void;
}

const CreatePostModalHome: React.FC<CreatePostModalHomeProps> = ({
  platforms,
  timeZone,
  defaultValues,
  getNextContentId,
  onDirtyChange,
}) => {
  const { push } = useContentStack();
  const socials = useMemo(() => {
    return platforms.reduce<ComponentProps<typeof PostForm>["allowedSocials"]>(
      (carry, platform) => {
        Object.values(platform.entities).forEach((entity) => {
          if (!entity.disabled && !entity.externalMissing) {
            carry.push({ platform, platformEntityId: entity.id });
          }
        });
        return carry;
      },
      []
    );
  }, [platforms]);

  const onSubmit: SubmitHandler<PostFormValues> = (data) => {
    push(getNextContentId(data as PostFormValues));
  };

  return (
    <div className="px-2 pb-2 pt-1 sm:px-8 sm:pb-6">
      <PostForm
        className="mt-2"
        allowedSocials={socials}
        defaultValues={defaultValues}
        timeZone={timeZone}
        onSubmit={onSubmit}
        onDirtyChange={onDirtyChange}
        header={
          <H2 className="w-full text-2xl text-black text-center">
            Create your post
          </H2>
        }
        footer={({ isDraft, platformTypes, postsCount }) => {
          const needRefining = platformTypes.length > 1;

          return (
            <PrimaryButton
              type="submit"
              className="mt-8 w-full relative"
              id={needRefining ? "refine-posts" : "create-post"}
            >
              {!needRefining &&
                (postsCount > 1 ? (
                  <DocumentDuplicateIcon className="mr-2 h-6 w-6" />
                ) : (
                  <DocumentIcon className="mr-2 h-6 w-6" />
                ))}
              {needRefining
                ? "Customise for each social"
                : postsCount > 1
                ? `Create ${postsCount} ${isDraft ? "drafts" : "posts"}`
                : `Create ${isDraft ? "draft" : "post"}`}

              {needRefining && (
                <div className="pr-4 absolute inset-y-0 right-0 flex items-center">
                  <ChevronRightIcon className="h-6 w6" />
                </div>
              )}
            </PrimaryButton>
          );
        }}
      />
    </div>
  );
};

export default CreatePostModalHome;
