import React, { useMemo, useState } from "react";
import { ChevronDown as ChevronDownIcon } from "react-feather";

import { WorkspaceAnalytics } from "../../../types/workspaceAnalytics";
import { MetricPropertyNames } from "../../../types/postAnalytics";

import { mergeClassNames } from "../../../libs/components";
import {
  INTERACTIONS_CONFIG,
  MetricPropConfig,
  METRICS_CONFIG_BY_TYPE,
} from "../../../libs/analytics";
import { formatCountForDisplay } from "../../../libs/postMetrics";

import { ReactComponent as NoPostsImage } from "../../../images/no-files.svg";

import Select, { SelectOption } from "../../../components/form/Select";

import TopPost from "./topPosts/TopPost";

interface AnalyticsBodyTopPostsProps {
  metricPropertyNames: MetricPropertyNames;
  topPosts: WorkspaceAnalytics["topPosts"];
  platformType: WorkspaceAnalytics["type"];
  className?: string;
}
const AnalyticsBodyTopPosts: React.FC<AnalyticsBodyTopPostsProps> = ({
  metricPropertyNames,
  topPosts,
  platformType,
  className = "",
}) => {
  const typeConfig = METRICS_CONFIG_BY_TYPE[platformType];
  const metricsOptions = useMemo<SelectOption[]>(() => {
    const newOptions: SelectOption[] = [
      {
        text: "Interactions",
        value: "interactions",
      },
    ];

    for (const propName of metricPropertyNames) {
      const metricPropConfig = typeConfig[
        propName as keyof typeof typeConfig
      ] as unknown as MetricPropConfig;

      if (metricPropConfig) {
        newOptions.push({
          text: metricPropConfig.title,
          value: propName,
        });
      }
    }

    return newOptions;
  }, [metricPropertyNames, typeConfig]);
  const [selectedOption, setSelectedOption] = useState(metricsOptions[0]);
  const selectedTopPosts = topPosts
    ? selectedOption.value === "interactions"
      ? topPosts.interactions
      : topPosts.metrics[
          selectedOption.value as keyof typeof topPosts.metrics
        ] || []
    : [];
  const hasSelectedTopPosts = selectedTopPosts.length > 0;

  return (
    <div className={mergeClassNames("w-full", className)}>
      <div className="flex justify-end relative">
        <Select
          toggleButtonClassName="px-2 py-1"
          labelText="Top posts metric"
          hideLabel={true}
          value={selectedOption.value}
          options={metricsOptions}
          onChange={(newValue) => {
            const newOption = metricsOptions.find(
              (candidate) => candidate.value === newValue
            );

            if (!newOption) {
              return;
            }

            setSelectedOption(newOption);
          }}
          renderSelectedOption={(option) => {
            if (!option) {
              return null;
            }

            const config =
              option.value === "interactions"
                ? INTERACTIONS_CONFIG
                : typeConfig[option.value as keyof typeof typeConfig];

            return (
              <div className="flex items-center">
                <span className="h-6 w-6" style={{ color: config.bgHex }}>
                  {config.icon}
                </span>
                <span className="ml-2">{option.text}</span>
                <ChevronDownIcon className="ml-2 w-6 h-6" />
              </div>
            );
          }}
          renderOption={(option) => {
            const config =
              option.value === "interactions"
                ? INTERACTIONS_CONFIG
                : typeConfig[option.value as keyof typeof typeConfig];

            return (
              <div className="flex items-center font-normal">
                <span className="h-6 w-6" style={{ color: config.bgHex }}>
                  {config.icon}
                </span>
                <span className="ml-2">{option.text}</span>
              </div>
            );
          }}
        />
      </div>
      {hasSelectedTopPosts && (
        <div>
          {selectedTopPosts.map(({ value, postId }, index) => {
            const key = `${value}${postId}`;
            return (
              <TopPost
                key={key}
                className="mt-4"
                title={`${formatCountForDisplay(
                  value
                )} ${selectedOption.text.toLowerCase()}`}
                postId={postId}
              />
            );
          })}
        </div>
      )}
      {!hasSelectedTopPosts && (
        <div className="mt-4 flex flex-col items-center">
          <NoPostsImage className="w-3/4 h-3/4 text-purple-500" />
          <p className="mt-4">{`No posts found with ${selectedOption.text.toLowerCase()}`}</p>
        </div>
      )}
    </div>
  );
};

export default AnalyticsBodyTopPosts;
