import React from "react";
import { DraftDecorator } from "draft-js";
import Twitter from "twitter-text";

import {
  Build,
  BuildDecorators,
  DraftDecoratorComponentProps,
} from "../../../../../libs/postTextArea";
import Tooltip from "../../../../../components/Tooltip";

const searchStrategy: DraftDecorator["strategy"] = (contentBlock, callback) => {
  const mentions = Twitter.extractMentionsWithIndices(contentBlock.getText());
  mentions.forEach(({ indices }) => {
    if (!contentBlock.getEntityAt(indices[0])) {
      callback(...indices);
    }
  });
};

interface BuildSearchComponentProps {
  onClick: (blockKey: string, start: number, end: number, text: string) => void;
}
const buildSearchComponent =
  ({
    onClick,
  }: BuildSearchComponentProps): React.FC<DraftDecoratorComponentProps> =>
  ({ children, decoratedText, blockKey, start, end }) => {
    const searchText = decoratedText.replace(Twitter.regexen.atSigns, "");

    return (
      <button
        className="rounded underline bg-gray-100 hover:bg-purple-100 hover:text-purple-500"
        type="button"
        onClick={() => onClick(blockKey, start, end, searchText)}
      >
        <Tooltip content={`Click to add mention for ${searchText}`}>
          {children}
        </Tooltip>
      </button>
    );
  };

const buildDecorators: BuildDecorators<BuildSearchComponentProps> = (props) => {
  return [
    {
      strategy: searchStrategy,
      component: buildSearchComponent(props),
    },
  ];
};

const build: Build<BuildSearchComponentProps> = (props) => {
  return {
    type: "MENTION_CANDIDATE",
    decorators: buildDecorators(props),
  };
};

export default build;
