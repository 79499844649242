import React from "react";

import { mergeClassNames } from "../../libs/components";

import { ReactComponent as AnalyticsImage } from "../../images/analytics.svg";

import H3 from "../../components/headings/H3";

interface AnalyticsBodyProps {
  className?: string;
}
const AnalyticsEmpty: React.FC<AnalyticsBodyProps> = ({ className = "" }) => {
  return (
    <div className={mergeClassNames("flex flex-col items-center", className)}>
      <H3>We're busy crunching the numbers</H3>
      <AnalyticsImage
        className="mt-4 flex-shrink-0 text-purple-500"
        style={{ width: 500, height: 500 }}
      />
      <p className="mt-4">
        It can take a little while to build the analytics. Please check back in
        about an hour or so.
      </p>
    </div>
  );
};

export default AnalyticsEmpty;
