import React from "react";
import {
  LogOut as LogOutIcon,
  User as UserIcon,
  ChevronRight as ChevronRightIcon,
  DollarSign as DollarSignIcon,
  HelpCircle as HelpCircleIcon,
} from "react-feather";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";

import config from "../../../config";
import { useActiveWorkspace, useCurrentUser } from "../../../libs/hooks/app";
import { useContentStack } from "../../../libs/hooks/general";
import { getUserDisplayName } from "../../../libs/user";

import DropdownMenuLink from "../../../components/links/DropdownMenu";
import DropdownMenuButton from "../../../components/buttons/DropdownMenu";
import UserProfilePicture from "../../../components/images/profile/User";
import ExternalLink from "../../../components/links/External";
import DropdownMenuExternalLink from "../../../components/links/DropdownMenuExternal";

const UserMenuHome: React.FC<{ contentId: "home" }> = () => {
  const user = useCurrentUser();
  const workspace = useActiveWorkspace();
  const displayName = getUserDisplayName(user);
  const { push } = useContentStack();

  return (
    <div className="p-2 flex flex-col">
      <div className="p-2 flex items-center font-bold text-black">
        <div className="flex-shrink-0 mr-3">
          <UserProfilePicture className="h-16 w-16" user={user} />
        </div>
        <h2 className="break-words text-xl">{displayName}</h2>
      </div>
      <hr className="m-2" />
      <h3 className="pl-2 text-xs font-bold text-gray-500">WORKSPACE</h3>
      <p className="pl-2 break-words text-2xl font-bold">{workspace.name}</p>
      <DropdownMenuButton
        onClick={(e) => {
          e.preventDefault();
          push("switchWorkspace");
        }}
      >
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          <SwitchHorizontalIcon className="h-6 w-6" />
        </div>
        Switch workspaces
        <ChevronRightIcon className="ml-auto text-gray-500" />
      </DropdownMenuButton>
      <hr className="m-2" />
      <DropdownMenuLink to="/profile">
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          <UserIcon className="h-6 w-6" />
        </div>
        Profile
      </DropdownMenuLink>
      <DropdownMenuLink to="/billing">
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          <DollarSignIcon className="h-6 w-6" />
        </div>
        Billing
      </DropdownMenuLink>
      <DropdownMenuExternalLink
        href={`mailto:${config.feedback_email.TO}?subject=${config.feedback_email.SUBJECT}&body=${config.feedback_email.BODY}`}
        title={config.feedback_email.TITLE}
      >
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          <HelpCircleIcon className="h-6 w-6" />
        </div>
        Feedback
      </DropdownMenuExternalLink>
      <DropdownMenuLink to="/logout">
        <div className="mr-2 p-2 bg-gray-200 rounded-full">
          <LogOutIcon className="h-6 w-6" />
        </div>
        Sign out
      </DropdownMenuLink>
      <div className="mt-6 px-2 flex items-center justify-around text-xs text-gray-400">
        <ExternalLink
          className="font-normal no-underline text-gray-400"
          href={config.urls.TERMS_OF_USE}
        >
          Terms of use
        </ExternalLink>
        <span className="mx-2">·</span>
        <ExternalLink
          className="font-normal no-underline text-gray-400"
          href={config.urls.PRIVACY_POLICY}
        >
          Privacy policy
        </ExternalLink>
        <span className="mx-2">·</span>
        <span>Seenly © 2022</span>
      </div>
    </div>
  );
};

export default UserMenuHome;
