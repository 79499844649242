import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "../../types/general";
import { WorkspaceAnalytics } from "../../types/workspaceAnalytics";
import { WORKSPACE_ANALYTICS_QUERY_KEYS } from "../queryKeys";

import { useApiClient } from "./app";

export const useWorkspaceAnalytics = <
  T extends WorkspaceAnalytics = WorkspaceAnalytics
>(
  workspaceId: string,
  period: WorkspaceAnalytics["period"],
  type: WorkspaceAnalytics["type"],
  options: { enabled?: boolean } = {}
) => {
  const apiClient = useApiClient();
  return useQuery(
    WORKSPACE_ANALYTICS_QUERY_KEYS.detail(`${workspaceId}${period}${type}`),
    async () => {
      const { data: response } = await apiClient.get<ApiResponse<T>>(
        `/workspace_analytics/${workspaceId}`,
        {
          params: {
            period,
            type,
          },
        }
      );

      return response.data;
    },
    options
  );
};
