import React, { Fragment, useMemo } from "react";

import { PostAnalytics } from "../../types/postAnalytics";

import { mergeClassNames } from "../../libs/components";
import { formatCountForDisplay } from "../../libs/postMetrics";

type HeatMapDatum = {
  value: number;
  percent: number;
};

type HeatMapDataRow = {
  label: string;
  data: HeatMapDatum[];
};

type HeatMapLabelRow = {
  labels: string[];
};

type HeatMap = {
  dataRows: HeatMapDataRow[];
  labelRow: HeatMapLabelRow;
};

function getDisplayNameForWeekDay(weekDay: number) {
  switch (weekDay) {
    case 1:
      return "Mon";
    case 2:
      return "Tues";
    case 3:
      return "Wed";
    case 4:
      return "Thurs";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    case 7:
      return "Sun";
    default:
      return "Mon";
  }
}

function getTileClassNameForPercent(percent: number) {
  if (percent < 1) {
    return "bg-gray-100";
  } else if (percent < 10) {
    return "bg-indigo-50";
  } else if (percent < 20) {
    return "bg-indigo-100";
  } else if (percent < 30) {
    return "bg-indigo-200";
  } else if (percent < 40) {
    return "bg-indigo-300";
  } else if (percent < 50) {
    return "bg-indigo-400";
  } else if (percent < 60) {
    return "bg-indigo-500";
  } else if (percent < 70) {
    return "bg-indigo-600";
  } else if (percent < 80) {
    return "bg-indigo-700";
  } else if (percent < 90) {
    return "bg-indigo-800";
  } else {
    return "bg-indigo-900";
  }
}

function getTextClassNameForPercent(percent: number) {
  if (percent < 40) {
    return "text-indigo-900";
  } else {
    return "text-indigo-50";
  }
}

interface AnalyticsInteractionsHeatMapProps {
  interactions: PostAnalytics["interactions"];
  className?: string;
}
const AnalyticsInteractionsHeatMap: React.FC<
  AnalyticsInteractionsHeatMapProps
> = ({ interactions, className = "" }) => {
  const data = useMemo<HeatMap>(() => {
    const dataRows: HeatMapDataRow[] = [7, 6, 5, 4, 3, 2, 1].map((weekDay) => ({
      label: getDisplayNameForWeekDay(weekDay),
      data: [...new Array(12)].map(() => ({
        value: 0,
        percent: 0,
      })),
    }));
    const labelRow: HeatMapLabelRow = {
      labels: [
        "12am",
        "2am",
        "4am",
        "6am",
        "8am",
        "10am",
        "12pm",
        "2pm",
        "4pm",
        "6pm",
        "8pm",
        "10pm",
      ],
    };

    if (!interactions) {
      return {
        dataRows,
        labelRow,
      };
    }

    const denominator =
      interactions.max > 0 ? interactions.max * 2 : interactions.count;

    for (const [weekDayStr, byHour] of Object.entries(interactions.heatMap)) {
      const weekDay = parseInt(weekDayStr, 10);
      const dataRowIndex = 7 - weekDay;
      const dataRow = dataRows[dataRowIndex];
      const hourCounts = Object.entries(byHour);

      while (hourCounts.length > 0) {
        const [hourStr, count] = hourCounts.shift()!;
        const hour = parseInt(hourStr, 10);
        const dataIndex = Math.floor(hour / 2);
        const datum = dataRow.data[dataIndex];

        datum.value += count;
        datum.percent =
          datum.value > 0 ? Math.round((datum.value / denominator) * 100) : 0;
      }
    }

    return {
      dataRows,
      labelRow,
    };
  }, [interactions]);

  return (
    <div
      className={mergeClassNames(
        "w-full grid grid-rows-8 grid-cols-13 gap-2",
        className
      )}
    >
      {data.dataRows.map((dataRow) => {
        return (
          <Fragment key={dataRow.label}>
            <div className="h-6 flex items-center justify-end text-xs text-gray-500">
              {dataRow.label}
            </div>
            {dataRow.data.map((datum, index) => {
              const key = `${dataRow.label}${index}`;
              const hasInteractions = datum.value > 0;

              return (
                <div
                  key={key}
                  className={`rounded group flex items-center justify-center ${
                    hasInteractions
                      ? "transition-all hover:scale-125 hover:shadow"
                      : ""
                  } ${getTileClassNameForPercent(
                    datum.percent
                  )} ${getTextClassNameForPercent(datum.percent)}`}
                >
                  {hasInteractions && (
                    <span className="transition-opacity opacity-0 group-hover:opacity-100">
                      {formatCountForDisplay(datum.value)}
                    </span>
                  )}
                </div>
              );
            })}
          </Fragment>
        );
      })}
      <div></div>
      {data.labelRow.labels.map((label) => {
        return (
          <div key={label} className="text-center text-xs text-gray-500">
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default AnalyticsInteractionsHeatMap;
