import React, { useEffect, useMemo, useState } from "react";
import { ContentState } from "draft-js";
import { ChevronDown as ChevronDownIcon } from "react-feather";

import { PostAttachment, PostFormValues } from "../../../../types/posts";
import { Platform } from "../../../../types/platforms";

import { getPlatformTypeName } from "../../../../libs/platform";
import { useHasChanged } from "../../../../libs/hooks/general";

import MultiSelect, {
  MultiSelectDropdownOption,
} from "../../../../components/form/MultiSelect";
import PlatformIcon from "../../../../components/icons/Platform";
import TwitterPostPreview from "../../../../components/posts/previews/Twitter";
import LinkedInPostPreview from "../../../../components/posts/previews/LinkedIn";
import FacebookPostPreview from "../../../../components/posts/previews/Facebook";

import PreviewContainer from "./previews/Container";

import { ReactComponent as EmptyContentImage } from "../../../../images/typewriter.svg";

interface PostTextAreaWritingPromptProps {
  socials: PostFormValues["socials"];
  scheduled: string;
  contentState: ContentState;
  attachment?: PostAttachment;
}
const PostTextAreaWritingPrompt: React.FC<PostTextAreaWritingPromptProps> = ({
  socials,
  scheduled,
  contentState,
  attachment,
}) => {
  const socialsByPlatformType = useMemo(() => {
    return socials.reduce<{
      [platformType: string]: PostFormValues["socials"];
    }>((carry, social) => {
      if (carry[social.platform.type]) {
        carry[social.platform.type].push(social);
      } else {
        carry[social.platform.type] = [social];
      }

      return carry;
    }, {});
  }, [socials]);
  const platformTypes = useMemo(() => {
    const types = Object.keys(socialsByPlatformType) as Platform["type"][];
    types.sort();
    return types;
  }, [socialsByPlatformType]);
  const hasPlatformTypesChanged = useHasChanged(platformTypes.join(""));
  const [platformsOptions, setPlatformsOptions] = useState<
    MultiSelectDropdownOption[]
  >(
    platformTypes.map((platformType) => ({
      key: platformType,
      text: getPlatformTypeName(platformType),
      data: platformType,
    }))
  );
  const [selectedPlatformsOptions, setSelectedPlatformsOptions] = useState<
    MultiSelectDropdownOption[]
  >(
    platformTypes.map((platformType) => ({
      key: platformType,
      text: getPlatformTypeName(platformType),
      data: platformType,
    }))
  );
  const isMultiPlatform = platformTypes.length > 1;
  const hasPlatformOptions = platformsOptions.length > 0;
  const hasSelectedPlatformOptions = selectedPlatformsOptions.length > 0;
  const showPlaceholderImage =
    !hasPlatformOptions || !hasSelectedPlatformOptions;

  useEffect(() => {
    if (hasPlatformTypesChanged) {
      const newTypes = platformTypes.filter(
        (platformType) =>
          !platformsOptions.find((option) => option.data === platformType)
      );
      const removedOptions = platformsOptions.filter(
        (option) => !platformTypes.includes(option.data)
      );

      setPlatformsOptions(
        platformTypes.map((platformType) => ({
          key: platformType,
          text: getPlatformTypeName(platformType),
          data: platformType,
        }))
      );

      setSelectedPlatformsOptions((prevSelectedOptions) => {
        let newSelectedOptions = prevSelectedOptions.filter(
          (option) =>
            !removedOptions.find((candidate) => candidate.key === option.key)
        );

        newSelectedOptions = newSelectedOptions.concat(
          newTypes.map((platformType) => ({
            key: platformType,
            text: getPlatformTypeName(platformType),
            data: platformType,
          }))
        );

        newSelectedOptions.sort((a, b) => {
          if (a.data < b.data) {
            return -1;
          } else if (a.data > b.data) {
            return 1;
          } else {
            return 0;
          }
        });

        return newSelectedOptions;
      });
    }
  }, [hasPlatformTypesChanged, platformTypes, platformsOptions]);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col">
      {hasPlatformOptions && isMultiPlatform && (
        <div className="p-2 w-full flex justify-end">
          <MultiSelect
            dropdownClassName="ml-auto"
            toggleButtonClassName="px-0 py-0 border-0 text-gray-500"
            dropdownXAlign="right"
            options={platformsOptions}
            selectedOptions={selectedPlatformsOptions}
            renderOption={(option) => (
              <div className="pl-2 flex items-center text-sm">
                <PlatformIcon className="mr-2" type={option.data} />
                {option.text}
              </div>
            )}
            renderSelectedOptions={(options) => (
              <div className="flex items-center">
                {options.map((option) => (
                  <PlatformIcon
                    key={option.data}
                    className="mr-2"
                    type={option.data}
                  />
                ))}
                {options.length < 1 && (
                  <span className="mr-2 font-normal text-sm">Select type</span>
                )}
                <ChevronDownIcon className="h-6 w-6" />
              </div>
            )}
            onChange={setSelectedPlatformsOptions}
          />
        </div>
      )}

      {showPlaceholderImage && (
        <div className="p-12 w-full">
          <EmptyContentImage className="max-w-full h-96 text-purple-500" />
        </div>
      )}
      <div className="p-2 h-full w-full overflow-auto">
        {selectedPlatformsOptions.map((option, index) => {
          const platformType = option.data as Platform["type"];
          const socials = socialsByPlatformType[platformType];

          if (!socials || socials.length < 1) {
            return null;
          }

          switch (platformType) {
            case "TWITTER":
              return (
                <PreviewContainer
                  className={index > 0 ? "mt-8" : ""}
                  key={platformType}
                  platformType={platformType}
                  socials={socials}
                >
                  {({ platformEntity }) => {
                    return (
                      <TwitterPostPreview
                        className="mt-2 mx-auto"
                        platformEntity={platformEntity}
                        scheduled={scheduled}
                        contentState={contentState}
                        attachment={attachment}
                      />
                    );
                  }}
                </PreviewContainer>
              );
            case "LINKEDIN":
              return (
                <PreviewContainer
                  className={index > 0 ? "mt-8" : ""}
                  key={platformType}
                  platformType={platformType}
                  socials={socials}
                >
                  {({ platformEntity }) => {
                    return (
                      <LinkedInPostPreview
                        className="mt-2 mx-auto"
                        platformEntity={platformEntity}
                        contentState={contentState}
                        attachment={attachment}
                      />
                    );
                  }}
                </PreviewContainer>
              );
            case "FACEBOOK":
              return (
                <PreviewContainer
                  className={index > 0 ? "mt-8" : ""}
                  key={platformType}
                  platformType={platformType}
                  socials={socials}
                >
                  {({ platformEntity }) => {
                    return (
                      <FacebookPostPreview
                        className="mt-2 mx-auto"
                        platformEntity={platformEntity}
                        scheduled={scheduled}
                        contentState={contentState}
                        attachment={attachment}
                      />
                    );
                  }}
                </PreviewContainer>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default PostTextAreaWritingPrompt;
