import React, {
  useEffect,
  FunctionComponent,
  useMemo,
  useState,
  ReactNode,
} from "react";
import Joi from "joi";
import { DateTime } from "luxon";
import {
  useForm,
  SubmitHandler,
  FieldErrors,
  FormProvider,
} from "react-hook-form";
import { ArrowLeft as ArrowLeftIcon } from "react-feather";
import { joiResolver } from "@hookform/resolvers/joi";
import { ContentState } from "draft-js";
import Twitter from "twitter-text";

import { Platform } from "../../types/platforms";
import { PostFormExtentionContent, PostFormValues } from "../../types/posts";

import { mergeClassNames } from "../../libs/components";
import { createEntityInBlockAtLocation } from "../../libs/postTextArea";
import { getAvailableScheduleFuturePosts } from "../../libs/subscriptions";
import { ensureUrlHasProtocol } from "../../libs/utils";
import { useActiveWorkspace } from "../../libs/hooks/app";

import {
  postAttachmentSchema,
  postStatusSchema,
  twitterPostAttachmentSchema,
  linkedinPostAttachmentSchema,
  facebookPostAttachmentSchema,
} from "./post/attachment/schemas";
import {
  baseContentStateSchema,
  linkedinContentStateSchema,
  twitterContentStateSchema,
} from "./post/textArea/schemas";
import PreviewsExtension from "./post/extensionContent/Previews";
import WritingPromptExtension from "./post/extensionContent/WritingPrompt";
import ImageAttachmentsExtension from "./post/extensionContent/ImageAttachments";
import VideoAttachmentExtension from "./post/extensionContent/VideoAttachment";
import WhereToPostSection from "./post/sections/WhereToPost";
import WhenToPostSection from "./post/sections/WhenToPost";
import WhatToPostSection from "./post/sections/WhatToPost";

import Tooltip from "../../components/Tooltip";
import IconButton from "../../components/buttons/Icon";
import H3 from "../../components/headings/H3";
import { PublishedPostAction } from "../../types/publishedPostActions";

const singleTwitterSocialSchema = Joi.array()
  .items(
    Joi.object({
      platform: Joi.object({
        type: "TWITTER",
      })
        .unknown(true)
        .required(),
      platformEntityId: Joi.string().required(),
    })
  )
  .length(1)
  .required();

const singleFacebookSocialSchema = Joi.array()
  .items(
    Joi.object({
      platform: Joi.object({
        type: "FACEBOOK",
      })
        .unknown(true)
        .required(),
      platformEntityId: Joi.string().required(),
    })
  )
  .length(1)
  .required();

const singleLinkedInSocialSchema = Joi.array()
  .items(
    Joi.object({
      platform: Joi.object({
        type: "LINKEDIN",
      })
        .unknown(true)
        .required(),
      platformEntityId: Joi.string().required(),
    })
  )
  .length(1)
  .required();

interface PostFormProps {
  allowedSocials: PostFormValues["socials"];
  timeZone: string;
  onSubmit: SubmitHandler<PostFormValues>;
  header: ReactNode;
  footer: FunctionComponent<{
    errors: FieldErrors<PostFormValues>;
    isDirty: boolean;
    isDraft: boolean;
    platformTypes: Platform["type"][];
    postsCount: number;
  }>;
  defaultValues?: Partial<PostFormValues>;
  className?: string;
  validateImmediately?: boolean;
  focusFirstInput?: boolean;
  onDirtyChange?: (newIsDirty: boolean) => void;
}

const PostForm: React.FC<PostFormProps> = ({
  allowedSocials,
  defaultValues,
  timeZone,
  onSubmit,
  header,
  footer,
  className = "",
  validateImmediately = false,
  focusFirstInput = true,
  onDirtyChange,
}) => {
  const workspace = useActiveWorkspace();
  const availablePostsCount = getAvailableScheduleFuturePosts(workspace);
  const [extensionContent, setExtensionContent] =
    useState<PostFormExtentionContent>(null);
  const [afterPostActions, setAfterPostActions] = useState<
    PublishedPostAction[]
  >([]);

  let socialsSchema = Joi.array()
    .items(
      Joi.object({
        platform: Joi.object().required(),
        platformEntityId: Joi.string().required(),
      })
    )
    .min(1)
    .required();
  let socialsDefault: PostFormValues["socials"] = [];

  if (allowedSocials.length === 1) {
    socialsDefault = allowedSocials;
  }

  if (availablePostsCount !== null) {
    socialsSchema = socialsSchema.max(availablePostsCount);
  }

  const formSchema = Joi.object({
    socials: socialsSchema,
    scheduled: Joi.when("status", {
      is: Joi.string().valid("SCHEDULED").required(),
      then: Joi.string()
        .isoDate()
        .custom((value) => {
          const scheduled = DateTime.fromISO(value).setZone(timeZone);
          const localNow = DateTime.local().setZone(timeZone);

          if (scheduled < localNow) {
            throw new Error(`Unable to schedule a post in the past`);
          }

          return value;
        })
        .required(),
    }),
    contentState: Joi.when("socials", {
      switch: [
        { is: singleTwitterSocialSchema, then: twitterContentStateSchema },
        { is: singleLinkedInSocialSchema, then: linkedinContentStateSchema },
      ],
      otherwise: baseContentStateSchema,
    }).when("attachment", {
      is: Joi.equal(null),
      then: Joi.custom((value: ContentState) => {
        if (!value.hasText()) {
          throw new Error("Post must contain text");
        }

        return value;
      }),
    }),
    attachment: Joi.when("socials", {
      switch: [
        { is: singleTwitterSocialSchema, then: twitterPostAttachmentSchema },
        { is: singleFacebookSocialSchema, then: facebookPostAttachmentSchema },
        { is: singleLinkedInSocialSchema, then: linkedinPostAttachmentSchema },
      ],
      otherwise: postAttachmentSchema,
    }),
    status: postStatusSchema,
    draft: Joi.boolean(),
    recycle: Joi.boolean(),
  });

  const formContext = useForm<PostFormValues>({
    mode: validateImmediately ? "onChange" : "onSubmit",
    resolver: joiResolver(formSchema, { convert: false }) as any,
    defaultValues: {
      socials:
        defaultValues && defaultValues.socials
          ? defaultValues.socials
          : socialsDefault,
      scheduled:
        defaultValues && defaultValues.scheduled
          ? defaultValues.scheduled
          : DateTime.local()
              .setZone(timeZone)
              .startOf("hour")
              .startOf("minute")
              .startOf("second")
              .plus({ hours: 1 })
              .toISO(),
      contentState:
        defaultValues && defaultValues.contentState
          ? defaultValues.contentState
          : ContentState.createFromText(""),
      attachment:
        defaultValues && defaultValues.attachment
          ? defaultValues.attachment
          : null,
      status:
        defaultValues && defaultValues.status
          ? defaultValues.status
          : "SCHEDULED",
      draft:
        defaultValues && defaultValues.draft !== undefined
          ? defaultValues.draft
          : false,
      recycle:
        defaultValues && defaultValues.recycle !== undefined
          ? defaultValues.recycle
          : false,
    },
  });
  const formContextForWrittingPrompt = useForm<PostFormValues>({
    mode: validateImmediately ? "onChange" : "onSubmit",
    resolver: joiResolver(formSchema, { convert: false }) as any,
    defaultValues: {
      socials: socialsDefault,
      scheduled: DateTime.local()
        .setZone(timeZone)
        .startOf("hour")
        .startOf("minute")
        .startOf("second")
        .plus({ hours: 1 })
        .toISO(),
      contentState: ContentState.createFromText(""),
      attachment: null,
      status: "SCHEDULED",
      draft: false,
      recycle: false,
    },
  });
  const {
    formState: { errors, isDirty },
    watch,
    setFocus,
    setValue,
    clearErrors,
    handleSubmit,
    trigger,
  } = formContext;
  const watchSelectedSocials = watch("socials");
  const watchScheduled = watch("scheduled");
  const watchContentState = watch("contentState");
  const watchAttachment = watch("attachment");
  const watchDraft = watch("draft");

  const selectedPlatformTypes = useMemo(() => {
    return watchSelectedSocials.reduce<Platform["type"][]>(
      (carry, { platform }) => {
        if (!carry.includes(platform.type)) {
          carry.push(platform.type);
        }

        return carry;
      },
      []
    );
  }, [watchSelectedSocials]);

  const closeExtensionContent = () => setExtensionContent(null);

  // const response = useAiSuggestions(
  //   { text: "placeholderForSuggestions" },
  //   { enabled: true, keepPreviousData: true }
  // );
  // const suggestions = response.data;
  const aiPrompts = undefined;

  const internalOnSubmit: SubmitHandler<PostFormValues> = ({
    contentState,
    ...rest
  }) => {
    let newContentState = contentState;

    for (const contentBlock of contentState.getBlockMap().toArray()) {
      const contentBlockText = contentBlock.getText();

      const urls = Twitter.extractUrlsWithIndices(contentBlockText);
      newContentState = urls.reduce((carry, { indices, url }) => {
        const entityKey = contentBlock.getEntityAt(indices[0]);

        if (entityKey) {
          return carry;
        }

        return createEntityInBlockAtLocation(
          carry,
          contentBlock.getKey(),
          indices[0],
          indices[1],
          "LINK",
          {
            text: url,
            url: ensureUrlHasProtocol(url),
            useAsAttachment: false,
          }
        );
      }, newContentState);

      const hashtags = Twitter.extractHashtagsWithIndices(contentBlockText);
      newContentState = hashtags.reduce((carry, { indices, hashtag }) => {
        const entityKey = contentBlock.getEntityAt(indices[0]);

        if (entityKey) {
          return carry;
        }

        return createEntityInBlockAtLocation(
          carry,
          contentBlock.getKey(),
          indices[0],
          indices[1],
          "HASHTAG",
          {
            hashtag,
          }
        );
      }, newContentState);
    }

    onSubmit({
      contentState: newContentState,
      bulkUpload: afterPostActions.length > 0,
      afterPostActions: afterPostActions,
      ...rest,
    });
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors, selectedPlatformTypes]);

  useEffect(() => {
    if (validateImmediately) {
      trigger();
    }
  }, [trigger, validateImmediately]);

  useEffect(() => {
    if (focusFirstInput) {
      setFocus("socials");
    }
  }, [focusFirstInput, setFocus]);

  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(isDirty);
    }
  }, [isDirty, onDirtyChange]);

  const onValidCsvUploadAndPostTextReceived = (
    text: string,
    afterPublishActions: PublishedPostAction[]
  ) => {
    setAfterPostActions(afterPublishActions);
    setValue("contentState", ContentState.createFromText(text), {
      shouldDirty: true,
    });
  };

  return (
    <div>
      <div style={{ width: "90rem" }}></div>
      <div
        className={mergeClassNames(
          "flex flex-wrap justify-around gap-8",
          className
        )}
      >
        <div className="flex-1" style={{ minWidth: 480, maxWidth: 700 }}>
          <div className="relative">{header}</div>
          <FormProvider {...formContext}>
            <form
              className="mt-2 pb-2 h-full w-full"
              onSubmit={handleSubmit(internalOnSubmit)}
            >
              <WhereToPostSection allowedSocials={allowedSocials} />

              <WhenToPostSection className="mt-8" timeZone={timeZone} />

              <WhatToPostSection
                className="mt-12"
                allowedSocials={allowedSocials}
                selectedPlatformTypes={selectedPlatformTypes}
                extensionContent={extensionContent}
                workspaceSubscription={workspace.subscription}
                setExtensionContent={setExtensionContent}
                onValidCSVUpload={onValidCsvUploadAndPostTextReceived}
              />
              {footer({
                errors,
                isDirty,
                isDraft: watchDraft,
                platformTypes: selectedPlatformTypes,
                postsCount: watchSelectedSocials.length,
              })}
            </form>
          </FormProvider>
        </div>

        <div
          className="flex-1 flex flex-col"
          style={{ minWidth: 480, minHeight: 600, maxWidth: 700 }}
        >
          <div className="h-8 relative shrink-0 flex items-center justify-center">
            {extensionContent === null && (
              <H3 className="text-xl text-gray-500">Preview</H3>
            )}
            {extensionContent === "writing-prompt" && (
              <>
                <div className="absolute left-0 top-0 flex items-center">
                  <Tooltip content="Hide writing prompts">
                    <IconButton onClick={closeExtensionContent}>
                      <ArrowLeftIcon className="w-6 h-6" />
                    </IconButton>
                  </Tooltip>
                </div>

                <H3 className="text-xl text-gray-500">Writing prompts</H3>
              </>
            )}
            {extensionContent === "image-attachments" && (
              <>
                <div className="absolute left-0 top-0 flex items-center">
                  <Tooltip content="Hide image picker">
                    <IconButton onClick={closeExtensionContent}>
                      <ArrowLeftIcon className="w-6 h-6" />
                    </IconButton>
                  </Tooltip>
                </div>

                <H3 className="text-xl text-gray-500">Image picker</H3>
              </>
            )}
            {extensionContent === "video-attachment" && (
              <>
                <div className="absolute left-0 top-0 flex items-center">
                  <Tooltip content="Hide video picker">
                    <IconButton onClick={closeExtensionContent}>
                      <ArrowLeftIcon className="w-6 h-6" />
                    </IconButton>
                  </Tooltip>
                </div>

                <H3 className="text-xl text-gray-500">Video picker</H3>
              </>
            )}
          </div>
          <div className="mt-4 grow w-full relative rounded-lg bg-gray-50 border border-gray-100 shadow">
            <div className="absolute inset-0 overflow-hidden">
              {extensionContent === null && (
                <PreviewsExtension
                  socials={watchSelectedSocials}
                  scheduled={watchScheduled}
                  contentState={watchContentState}
                  attachment={watchAttachment || undefined}
                />
              )}
              {extensionContent === "writing-prompt" && (
                <FormProvider {...formContextForWrittingPrompt}>
                  <WritingPromptExtension
                    close={closeExtensionContent}
                    setContentState={(newContentState) => {
                      setValue("contentState", newContentState, {
                        shouldDirty: true,
                      });
                    }}
                    promptsData={aiPrompts}
                  />
                </FormProvider>
              )}
              {extensionContent === "image-attachments" && (
                <ImageAttachmentsExtension
                  close={closeExtensionContent}
                  imageAttachments={
                    watchAttachment && watchAttachment.type === "IMAGE"
                      ? watchAttachment.images
                      : []
                  }
                  setImageAttachments={(newImageAttachments) => {
                    if (newImageAttachments.length) {
                      setValue(
                        "attachment",
                        {
                          type: "IMAGE",
                          images: newImageAttachments,
                        },
                        { shouldDirty: true }
                      );
                    } else {
                      setValue("attachment", null, { shouldDirty: true });
                    }
                  }}
                />
              )}
              {extensionContent === "video-attachment" && (
                <VideoAttachmentExtension
                  close={closeExtensionContent}
                  videoAttachment={
                    watchAttachment && watchAttachment.type === "VIDEO"
                      ? watchAttachment.video
                      : null
                  }
                  setVideoAttachment={(newVideoAttachment) => {
                    if (newVideoAttachment) {
                      setValue(
                        "attachment",
                        {
                          type: "VIDEO",
                          video: newVideoAttachment,
                        },
                        { shouldDirty: true }
                      );
                    } else {
                      setValue("attachment", null, { shouldDirty: true });
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostForm;
