import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExternalLink as ExternalLinkIcon } from "react-feather";

import { Platform } from "../../../../types/platforms";
import { PostFormValues } from "../../../../types/posts";
import { useActiveWorkspace } from "../../../../libs/hooks/app";
import { getAvailableScheduleFuturePosts } from "../../../../libs/subscriptions";
import { mergeClassNames } from "../../../../libs/components";

import ExternalLink from "../../../../components/links/External";
import PlatformEntityProfilePicture from "../../../../components/images/profile/PlatformEntity";
import PlatformIcon from "../../../../components/icons/Platform";

import PostFormRow from "../Row";
import SocialsMultiSelect from "../multiSelect/Socials";
import SocialsIcon from "../../../../components/icons/Socials";

interface PostFormWhereToPostSectionProps {
  allowedSocials: PostFormValues["socials"];
  className?: string;
}
const PostFormWhereToPostSection: React.FC<PostFormWhereToPostSectionProps> = ({
  allowedSocials,
  className = "",
}) => {
  const workspace = useActiveWorkspace();
  const availablePostsCount = getAvailableScheduleFuturePosts(workspace);
  const {
    control,
    formState: { errors },
  } = useFormContext<PostFormValues>();
  const availablePlatformTypes = useMemo(() => {
    return allowedSocials.reduce<Platform["type"][]>((carry, social) => {
      if (!carry.includes(social.platform.type)) {
        carry.push(social.platform.type);
      }
      return carry;
    }, []);
  }, [allowedSocials]);

  return (
    <div className={mergeClassNames("group", className)}>
      <h3 className="font-bold text-sm text-gray-300 default-transition group-focus-within:text-purple-500">
        WHERE TO POST
      </h3>
      <Controller
        control={control}
        name="socials"
        render={({ field: { onChange, value, ref } }) => {
          const hasSingleOption =
            allowedSocials.length < 2 && value.length === 1;
          const singlePlatformType = hasSingleOption
            ? value[0].platform.type
            : availablePlatformTypes.length === 1
            ? availablePlatformTypes[0]
            : null;
          const hasExceededPostLimit =
            errors.socials && (errors.socials as any).type === "array.max";

          return (
            <PostFormRow
              className="mt-1"
              icon={
                <div className="h-full flex items-center">
                  {singlePlatformType ? (
                    <PlatformIcon
                      className="h-6 w-6"
                      type={singlePlatformType}
                      colour={true}
                    />
                  ) : (
                    <div className="relative w-6 h-6">
                      <SocialsIcon
                        className={`w-7 h-7 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 default-transition ${
                          !!errors.socials ? "text-red-500" : ""
                        }`}
                      />
                    </div>
                  )}
                </div>
              }
              content={
                <div className="ml-4">
                  {hasSingleOption ? (
                    <ExternalLink
                      className="p-2 group flex items-center rounded-lg hover:bg-gray-100 focus:bg-gray-100"
                      href={
                        value[0].platform.entities[value[0].platformEntityId]
                          .externalUrl
                      }
                    >
                      <PlatformEntityProfilePicture
                        className="flex-shrink-0 h-9 w-9"
                        entity={
                          value[0].platform.entities[value[0].platformEntityId]
                        }
                      />
                      <span ref={ref} className="ml-2 flex-shrink-0 truncate">
                        {
                          value[0].platform.entities[value[0].platformEntityId]
                            .name
                        }
                      </span>
                      <ExternalLinkIcon className="ml-auto h-4 w-4 text-gray-200 default-transition group-hover:text-black group-focus:text-black" />
                    </ExternalLink>
                  ) : (
                    <>
                      <SocialsMultiSelect
                        ref={ref}
                        dropdownClassName="text-gray-600"
                        socials={allowedSocials}
                        selected={value}
                        onChange={onChange}
                        error={!!errors.socials}
                        showPlatformType={!singlePlatformType}
                      />
                      {hasExceededPostLimit && (
                        <p className="mt-1 text-red-500 text-xs">{`You only have ${availablePostsCount} ${
                          availablePostsCount === 1 ? "post" : "posts"
                        } remaining. You may upgrade to create more posts.`}</p>
                      )}
                    </>
                  )}
                </div>
              }
            />
          );
        }}
      />
    </div>
  );
};

export default PostFormWhereToPostSection;
