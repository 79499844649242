import React, { ComponentProps } from "react";

import {
  ENGAGEMENT_LEVELS,
  getEngagementLevelColourName,
  getEngagementLevelDescription,
} from "../../libs/postMetrics";

import Modal from "../../components/Modal";
import H3 from "../../components/headings/H3";
import H4 from "../../components/headings/H4";
import PostMetricsEngagementIcon from "../../components/icons/PostMetricsEngagement";
import SmartScheduleIcon from "../../components/icons/SmartSchedule";

interface ExplainSmartSchedulesProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {}

const ExplainSmartSchedulesModal: React.FC<ExplainSmartSchedulesProps> = ({
  ...modalProps
}) => {
  return (
    <Modal {...modalProps}>
      <div className="px-8 pb-8 pt-4 relative" style={{ width: 600 }}>
        <H3 className="flex items-center">
          <SmartScheduleIcon className="mr-2 h-7 w-7" />
          Smart schedules
        </H3>
        <p className="mt-4">
          Seenly automatically monitors each piece of content published to your
          socials (whether it was created with Seenly or not) and assigns it a
          status to indicate its level of engagement with your audience.
        </p>

        <p className="mt-4">The levels are:</p>
        <ul className="pl-4">
          <li className="flex items-center">
            <span className="inline-flex items-center w-24 text-green-400 font-bold">
              <PostMetricsEngagementIcon
                className="mr-2"
                engagementLevel={ENGAGEMENT_LEVELS.GREEN}
                size="md"
              />
              {getEngagementLevelColourName(ENGAGEMENT_LEVELS.GREEN)}
            </span>{" "}
            {getEngagementLevelDescription(ENGAGEMENT_LEVELS.GREEN)}
          </li>
          <li className="mt-1 flex items-center">
            <span className="inline-flex items-center w-24 text-amber-400 font-bold">
              <PostMetricsEngagementIcon
                className="mr-2"
                engagementLevel={ENGAGEMENT_LEVELS.AMBER}
                size="md"
              />
              {getEngagementLevelColourName(ENGAGEMENT_LEVELS.AMBER)}
            </span>{" "}
            {getEngagementLevelDescription(ENGAGEMENT_LEVELS.AMBER)}
          </li>
          <li className="mt-1 flex items-center">
            <span className="inline-flex items-center w-24 text-red-400 font-bold">
              <PostMetricsEngagementIcon
                className="mr-2"
                engagementLevel={ENGAGEMENT_LEVELS.RED}
                size="md"
              />
              {getEngagementLevelColourName(ENGAGEMENT_LEVELS.RED)}
            </span>{" "}
            {getEngagementLevelDescription(ENGAGEMENT_LEVELS.RED)}
          </li>
        </ul>

        <p className="mt-4">
          Once the engagement reaches{" "}
          <span className="text-red-400 font-bold lowercase">
            {getEngagementLevelColourName(ENGAGEMENT_LEVELS.RED)}
          </span>{" "}
          for a social's <strong>most recently published</strong> content Seenly
          will automatically publish the "UP NEXT" content, if any, from that
          social's smart schedule.
        </p>

        <H4 className="mt-12 text-lg">UP NEXT</H4>
        <p>
          The next piece of content that should be published for that social.
        </p>

        <H4 className="mt-4 text-lg">QUEUED</H4>
        <p>
          The list of content, in the order that it should be published, for
          that social. The top content will move into the "UP NEXT" position
          when it is available.
        </p>

        <H4 className="mt-4 text-lg">DRAFTS</H4>
        <p>
          The list of content that is not ready to be published. The order of
          this list does not matter. Content must manually be moved from the
          "DRAFTS" list to the "QUEUED" list to be eligible for publishing.
        </p>
      </div>
    </Modal>
  );
};

export default ExplainSmartSchedulesModal;
