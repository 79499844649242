import React, { useEffect, useMemo, useRef, useState } from "react";
import Twitter from "twitter-text";
import {
  Editor,
  ContentState,
  EditorState,
  CompositeDecorator,
  DraftDecorator,
} from "draft-js";
import { CornerUpRight as CornerUpRightIcon } from "react-feather";
import {
  DotsHorizontalIcon,
  GlobeIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/solid";
import { ThumbUpIcon, ChatAltIcon } from "@heroicons/react/outline";

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import "draft-js/dist/Draft.css";

import { PlatformEntity } from "../../../types/platforms";
import { MentionEntityData, PostAttachment } from "../../../types/posts";
import { mergeClassNames } from "../../../libs/components";
import {
  buildContentStateForPlatformType,
  POST_PREVIEW_WIDTH,
} from "../../../libs/post";
import { ensureUrlHasProtocol } from "../../../libs/utils";
import {
  DraftDecoratorComponentProps,
  ENTITY_TYPES,
  getBlockPlainTextOffset,
} from "../../../libs/postTextArea";

import { CHARACTER_LIMIT } from "../../../containers/forms/post/textArea/schemas";
import PlatformEntityProfilePicture from "../../images/profile/PlatformEntity";

import LinkedInImages from "./linkedIn/Images";
import LinkedInLink from "./linkedIn/Link";
import LinkedInVideo from "./linkedIn/Video";

const COLOURS = {
  BLACK: "rgba(0, 0, 0, 0.9)",
  BORDER_GRAY: "rgba(0, 0, 0, 0.08)",
  IMAGE_BORDER_GRAY: "rgb(207, 217, 222)",
  GRAY: "rgba(0, 0, 0, 0.6)",
  BLUE: "rgb(10, 102, 194)",
};

const WIDTH = POST_PREVIEW_WIDTH.LINKEDIN;
const EDITOR_LINE_HEIGHT = 20;
const MAX_LINE_COUNT = 5;
const MAX_UNSHORTENED_URL_LENGTH = 26;
const REGULAR_COLLAPSED_TEXT_HEIGHT = EDITOR_LINE_HEIGHT * 3;
const FIVE_LINE_COLLAPSED_TEXT_HEIGHT = EDITOR_LINE_HEIGHT * 5;

const HASHTAG_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback) => {
    const hashtags = Twitter.extractHashtagsWithIndices(contentBlock.getText());
    hashtags.forEach(({ indices }) => {
      callback(...indices);
    });
  },
  component: ({ children }: DraftDecoratorComponentProps) => (
    <span className="font-semibold" style={{ color: COLOURS.BLUE }}>
      {children}
    </span>
  ),
};

const MENTION_DECORATOR: DraftDecorator = {
  strategy: (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      const entity = entityKey ? contentState.getEntity(entityKey) : null;

      if (!!entity && entity.getType() === ENTITY_TYPES.MENTION) {
        const entityData = entity
          ? (entity.getData() as MentionEntityData)
          : null;

        return !!entityData && !!entityData.mentions["LINKEDIN"];
      }

      return false;
    }, callback);
  },
  component: ({ contentState, entityKey }: DraftDecoratorComponentProps) => {
    const entity = contentState.getEntity(entityKey!);
    const entityData = entity.getData() as MentionEntityData;

    return (
      <span className="font-semibold" style={{ color: COLOURS.BLUE }}>
        {entityData.mentions["LINKEDIN"]!.text}
      </span>
    );
  },
};

function buildLinkDecorator(attachedUrl: string | null): DraftDecorator {
  return {
    strategy: (contentBlock, callback) => {
      const urls = Twitter.extractUrlsWithIndices(contentBlock.getText());
      urls.forEach(({ indices }) => {
        callback(...indices);
      });
    },
    component: ({
      contentState,
      decoratedText,
    }: DraftDecoratorComponentProps) => {
      const url = decoratedText;
      let displayText = url;

      if (attachedUrl) {
        const normalisedAttachedUrl = ensureUrlHasProtocol(attachedUrl);
        const normalisedUrl = ensureUrlHasProtocol(url);
        if (normalisedAttachedUrl === normalisedUrl) {
          const contentStateText = contentState.getPlainText().trim();

          if (contentStateText.endsWith(url)) {
            // LinkedIn removes the link from the text if it's the last thing
            // in the post text and it's attached to the post.
            displayText = "";
          }
        }
      }

      if (displayText.length > MAX_UNSHORTENED_URL_LENGTH) {
        const idChars = ["d", "e", "x", "9", "m", "w", "W"];
        const urlLettersAndNumbers = url
          .replace(/^https?:\/\//, "")
          .replaceAll(/[^a-zA-Z0-9]/g, "");

        for (
          let i = 0;
          i < idChars.length && i * 2 < urlLettersAndNumbers.length;
          i++
        ) {
          const urlChar = urlLettersAndNumbers[i * 2];

          if (urlChar) {
            idChars[i] = urlChar;
          }
        }

        displayText = `https://lnkd.in/${idChars.join("")}`;
      }

      return (
        <span className="font-semibold" style={{ color: COLOURS.BLUE }}>
          {displayText}
        </span>
      );
    },
  };
}

const MAX_LENGTH_DECORTATOR: DraftDecorator = {
  strategy: (contentBlock, callback, contentState) => {
    const blockOffset = getBlockPlainTextOffset(
      contentState,
      contentBlock.getKey()
    );
    const start = Math.max(0, CHARACTER_LIMIT.LINKEDIN - blockOffset + 1);

    if (start < contentBlock.getText().length) {
      callback(start, contentBlock.getText().length);
    }
  },
  component: ({ children }: DraftDecoratorComponentProps) => {
    return <span className="text-red-500">{children}</span>;
  },
};

function buildDecorator(attachedUrl: string | null) {
  return new CompositeDecorator([
    HASHTAG_DECORATOR,
    MENTION_DECORATOR,
    buildLinkDecorator(attachedUrl),
    MAX_LENGTH_DECORTATOR,
  ]);
}

interface LinkedInPostPreviewProps {
  platformEntity: PlatformEntity;
  contentState: ContentState;
  attachment?: PostAttachment;
  className?: string;
}
const LinkedInPostPreview: React.FC<LinkedInPostPreviewProps> = ({
  platformEntity,
  contentState,
  attachment,
  className = "",
}) => {
  const preFoldTextRef = useRef("");
  const foldHeightContainerRef = useRef<HTMLDivElement | null>(null);
  const editorContainerRef = useRef<HTMLDivElement | null>(null);
  const [canExpand, setCanExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [maxEditorHeight, setMaxEditorHeight] = useState(
    REGULAR_COLLAPSED_TEXT_HEIGHT
  );
  const linkedInContentState = useMemo(() => {
    return buildContentStateForPlatformType("LINKEDIN", contentState);
  }, [contentState]);
  const plainText = linkedInContentState.getPlainText();
  const imageAttachments =
    attachment && attachment.type === "IMAGE" ? attachment.images : [];
  const hasImageAttachments = imageAttachments.length > 0;

  useEffect(() => {
    if (foldHeightContainerRef.current) {
      const paragraphs = plainText.split("\n");
      const candidateParagraphs = paragraphs.slice(0, MAX_LINE_COUNT);
      const hasMoreParagraphs = candidateParagraphs.length < paragraphs.length;
      const newPreFoldTextKey = candidateParagraphs.join("");

      if (preFoldTextRef.current === newPreFoldTextKey) {
        // The pre-fold text hasn't changed so no need to recalculate it.
        return;
      } else {
        preFoldTextRef.current = newPreFoldTextKey;
      }

      let newPreFoldText = "";
      let newCanExpand = false;
      let newMaxEditorHeight = 0;

      for (let i = 0; i < candidateParagraphs.length; i++) {
        const paragraph = candidateParagraphs[i];
        const isLastCandidate = i === candidateParagraphs.length - 1;
        newPreFoldText += `${paragraph}\n`;
        foldHeightContainerRef.current.innerText = newPreFoldText;
        const preFoldTextHeight = foldHeightContainerRef.current.clientHeight;

        newCanExpand = preFoldTextHeight > REGULAR_COLLAPSED_TEXT_HEIGHT;

        if (newCanExpand) {
          const trimmedParagraph = paragraph.trim();

          if (
            preFoldTextHeight === FIVE_LINE_COLLAPSED_TEXT_HEIGHT &&
            (hasMoreParagraphs || !isLastCandidate) &&
            trimmedParagraph !== ""
          ) {
            newMaxEditorHeight = FIVE_LINE_COLLAPSED_TEXT_HEIGHT;
            break;
          } else if (preFoldTextHeight > FIVE_LINE_COLLAPSED_TEXT_HEIGHT) {
            newMaxEditorHeight = REGULAR_COLLAPSED_TEXT_HEIGHT;
            break;
          } else {
            newMaxEditorHeight = REGULAR_COLLAPSED_TEXT_HEIGHT;
          }
        }
      }

      if (newCanExpand) {
        setCanExpand(true);
      } else {
        setCanExpand(false);
        setExpanded(false);
      }

      setMaxEditorHeight(newMaxEditorHeight);
    }
  }, [plainText]);

  return (
    <div
      className={mergeClassNames(
        "group bg-white text-sm font-linkedIn",
        className
      )}
      style={{
        width: WIDTH,
        borderRadius: "0.8rem",
        boxShadow: `0px 0px 0px 1px ${COLOURS.BORDER_GRAY}`,
        color: COLOURS.BLACK,
      }}
    >
      <div className="mb-2 pt-3 pl-4 pr-10  relative">
        <div className="mr-3 flex items-start">
          <PlatformEntityProfilePicture
            className="w-12 h-12 shrink-0"
            entity={platformEntity}
            circle={platformEntity.type === "USER"}
          />

          <div className="ml-2 w-full">
            <div className="font-bold truncate text-ellipsis">
              {platformEntity.name}
            </div>
            <div
              className="text-xs truncate text-ellipsis"
              style={{ color: COLOURS.GRAY }}
            >
              {platformEntity.type === "USER"
                ? "A short user bio will be shown here"
                : "100 followers"}
            </div>
            <div
              className="text-xs flex items-center"
              style={{ color: COLOURS.GRAY }}
            >
              <span>{`1h • `}</span>
              <GlobeIcon className="ml-1 h-4 w-4" />
            </div>
          </div>
        </div>
        <div className="absolute top-1 right-3 w-8 h-8 flex items-center justify-center">
          <DotsHorizontalIcon
            className="w-6 h-6"
            style={{
              color: COLOURS.GRAY,
            }}
          />
        </div>
      </div>

      <div
        className="px-4 overflow-hidden relative"
        style={{
          maxHeight: expanded ? undefined : maxEditorHeight || undefined,
          lineHeight: `${EDITOR_LINE_HEIGHT}px`,
        }}
      >
        <div
          ref={foldHeightContainerRef}
          className="px-4 absolute top-0 left-0 w-full invisible opacity-0 pointer-events-none whitespace-pre-wrap"
          style={{ zIndex: -999 }}
          aria-hidden={true}
          tabIndex={-1}
        ></div>

        <div ref={editorContainerRef}>
          <Editor
            editorState={EditorState.createWithContent(
              linkedInContentState,
              buildDecorator(
                attachment && attachment.type === "LINK"
                  ? attachment.link.url
                  : null
              )
            )}
            readOnly={true}
            onChange={() => {}}
          />
        </div>
        {canExpand && (
          <div
            className="absolute left-2 right-2 bottom-0 rounded-lg pointer-events-none opacity-0 default-transition group-hover:opacity-100"
            style={{ top: maxEditorHeight, zIndex: 1 }}
          >
            <div className="px-1 absolute top-0 right-0 text-gray-500 rounded bg-gray-200">
              Below the fold
            </div>
            <div className="h-full w-full rounded-lg pointer-events-none bg-gray-200 bg-opacity-40"></div>
          </div>
        )}
        {canExpand && (
          <div
            className={`mr-2 px-2 absolute bottom-0 right-0 text-sm bg-white default-transition ${
              expanded ? "rounded group-hover:bg-gray-200" : ""
            } `}
            style={{ color: COLOURS.GRAY, zIndex: 1 }}
          >
            <button
              className="hover:underline hover:text-blue-600 focus:underline focus:text-blue-600"
              type="button"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "…see less" : "…see more"}
            </button>
          </div>
        )}
      </div>

      {hasImageAttachments && (
        <LinkedInImages className="mt-2" imageAttachments={imageAttachments} />
      )}

      {attachment && attachment.type === "LINK" && (
        <LinkedInLink className="mt-2" linkAttachment={attachment.link} />
      )}
      {attachment && attachment.type === "VIDEO" && (
        <LinkedInVideo
          className="mt-2"
          videoAttachment={attachment.video}
          maxHeight={WIDTH}
        />
      )}

      <div
        className="px-3 py-1 grid grid-flow-col gap-1 font-semibold"
        style={{ color: COLOURS.GRAY }}
      >
        <div className="h-12 flex items-center justify-center">
          <ThumbUpIcon
            className="mr-1 h-6 w-6"
            style={{ transform: "scale(-1,1)" }}
          />
          <div>Like</div>
        </div>
        <div className="h-12 flex items-center justify-center">
          <ChatAltIcon
            className="mr-1 h-6 w-6"
            style={{ transform: "scale(-1,1)" }}
          />
          <div>Comment</div>
        </div>
        <div className="h-12 flex items-center justify-center">
          <CornerUpRightIcon className="mr-1 h-6 w-6" />
          <div>Share</div>
        </div>
        <div className="h-12 flex items-center justify-center">
          <PaperAirplaneIcon className="mr-1 h-6 w-6 rotate-45" />
          <div>Send</div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInPostPreview;
