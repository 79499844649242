import React, { forwardRef } from "react";
import ReactDOM from "react-dom";
import { X } from "react-feather";
import { useAnimatedMounting, useOpenCloseStack } from "../libs/hooks/general";
import { mergeClassNames } from "../libs/components";

import IconButton from "./buttons/Icon";

export interface ModalProps {
  isVisible: boolean;
  close?: () => void;
  children: React.ReactNode;
  className?: string;
  closeButtonClassName?: string;
  onAnimationComplete?: () => void;
  onStatusChange?: (
    newStatus: "opening" | "open" | "closing" | "closed"
  ) => void;
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      isVisible,
      close,
      onAnimationComplete,
      onStatusChange,
      className = "",
      closeButtonClassName = "",
    },
    ref
  ) => {
    const animate = useAnimatedMounting({
      mount: isVisible,
      onPhaseChange: (newPhase) => {
        switch (newPhase) {
          case "mounting":
            onStatusChange && onStatusChange("opening");
            break;
          case "mounted":
            onStatusChange && onStatusChange("open");
            onAnimationComplete && onAnimationComplete();
            break;
          case "unmounting":
            onStatusChange && onStatusChange("closing");
            break;
          case "unmounted":
            onStatusChange && onStatusChange("closed");
            onAnimationComplete && onAnimationComplete();
            break;
        }
      },
    });

    const openCloseHandlers = useOpenCloseStack(
      isVisible && !!close,
      close ? close : () => {}
    );

    return ReactDOM.createPortal(
      animate(({ phase, animationEventHandlers }) => {
        let animationClassName = "";

        switch (phase) {
          case "mounting":
            animationClassName = "animate-scale-up-in";
            break;
          case "unmounting":
            animationClassName = "animate-scale-down-out";
            break;
        }

        return phase === "unmounted" ? null : (
          <div
            className={`fixed z-10 top-0 left-0 right-0 bottom-0 min-h-screen min-w-screen overscroll-contain flex items-start justify-center overflow-x-hidden overflow-y-auto bg-black/50 ${
              isVisible
                ? "animate-fade-in backdrop-blur-sm"
                : "animate-fade-out backdrop-blur-none pointer-events-none"
            }`}
          >
            <div
              ref={ref}
              role="dialog"
              className={mergeClassNames(
                `sm:mx-4 sm:my-4 md:mx-8 md:my-8 max-w-full relative rounded-lg shadow-lg bg-white overflow-hidden ${animationClassName}`,
                className
              )}
              {...openCloseHandlers}
              {...animationEventHandlers}
            >
              {children}
              {!!close && (
                <IconButton
                  className={mergeClassNames(
                    "mt-2 mr-2 absolute top-0 right-0",
                    closeButtonClassName
                  )}
                  title="Close"
                  onClick={() => close()}
                >
                  <X />
                </IconButton>
              )}
            </div>
          </div>
        );
      }),
      document.getElementById("portal-container") || document.body
    );
  }
);

export default Modal;
