import React, { ComponentProps } from "react";

import { useTwitterRequestToken } from "../../libs/hooks/platforms";
import { TWITTER_REDIRECT_URI } from "../../libs/platforms/twitter";

import ModalContentSwitch from "../../components/ModalContentSwitch";
import ContentSwitchContent from "../../components/animations/contentSwitch/Content";

import { AddPlatformContent } from "../../types/modals/AddPlatform";
import Home from "./addPlatform/Home";
import ErrorContent from "./addPlatform/Error";
import AlreadyExists from "./addPlatform/AlreadyExists";
import Consent from "./addPlatform/Consent";
import ConnectLinkedIn from "./addPlatform/ConnectLinkedIn";
import ConnectTwitter from "./addPlatform/ConnectTwitter";
import ConnectFacebook from "./addPlatform/ConnectFacebook";

interface AddPlatformModalProps
  extends Omit<ComponentProps<typeof ModalContentSwitch>, "children"> {
  close: () => void;
  defaultContentId?: AddPlatformContent["id"];
}

const AddPlatformModal: React.FC<AddPlatformModalProps> = ({
  isVisible,
  close,
  ...modalProps
}) => {
  const { mutateAsync: requestToken } = useTwitterRequestToken();

  return (
    <ModalContentSwitch {...modalProps} isVisible={isVisible} close={close}>
      <ContentSwitchContent contentId="home">
        <Home contentId="home" isVisible={isVisible} close={close} />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="error">
        <ErrorContent contentId="error" isVisible={isVisible} close={close} />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="alreadyExists">
        <AlreadyExists
          contentId="alreadyExists"
          isVisible={isVisible}
          close={close}
        />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="consentLinkedIn">
        <Consent
          contentId="consentLinkedIn"
          platformType="LINKEDIN"
          isVisible={isVisible}
          close={close}
          fetchConnectContent={async () => ({ id: "connectLinkedIn" })}
        />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="consentTwitter">
        <Consent
          contentId="consentTwitter"
          platformType="TWITTER"
          isVisible={isVisible}
          close={close}
          fetchConnectContent={async () => {
            const { token } = await requestToken(TWITTER_REDIRECT_URI);

            if (!token) {
              throw new Error("Unable to get token for twitter");
            }

            return { id: "connectTwitter", data: { token } };
          }}
        />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="consentFacebook">
        <Consent
          contentId="consentFacebook"
          platformType="FACEBOOK"
          isVisible={isVisible}
          close={close}
          fetchConnectContent={async () => ({ id: "connectFacebook" })}
        />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="connectLinkedIn">
        <ConnectLinkedIn
          contentId="connectLinkedIn"
          isVisible={isVisible}
          close={close}
        />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="connectTwitter">
        <ConnectTwitter
          contentId="connectTwitter"
          isVisible={isVisible}
          close={close}
        />
      </ContentSwitchContent>
      <ContentSwitchContent contentId="connectFacebook">
        <ConnectFacebook
          contentId="connectFacebook"
          isVisible={isVisible}
          close={close}
        />
      </ContentSwitchContent>
    </ModalContentSwitch>
  );
};

export default AddPlatformModal;
