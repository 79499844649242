import { DateTime } from "luxon";
import React from "react";
import {
  ExternalLink as ExternalLinkIcon,
  Download as DownloadIcon,
} from "react-feather";

import { Invoice } from "../../../types/invoices";
import { Workspace } from "../../../types/workspaces";

import InvoiceStatusBadge from "../../badges/InvoiceStatus";
import ExternalLink from "../../links/External";

interface InvoiceTableRowProps {
  invoice: Invoice;
  timeZone: string;
  includeWorkspace?: boolean;
  workspace?: Workspace;
}
const InvoiceTableRow: React.FC<InvoiceTableRowProps> = ({
  invoice,
  timeZone,
  includeWorkspace = false,
  workspace,
}) => {
  const displayTotal = `$${(invoice.total / 100).toFixed(2)}`;
  const displayAmountDue = invoice.amountDue
    ? `$${(invoice.amountDue / 100).toFixed(2)}`
    : "-";
  const displayPeriodStart = DateTime.fromISO(invoice.periodStart)
    .setZone(timeZone)
    .toFormat("MMM d");
  const displayPeriodEnd = DateTime.fromISO(invoice.periodEnd)
    .setZone(timeZone)
    .toFormat("MMM d");
  const displayCreated = DateTime.fromISO(invoice.created)
    .setZone(timeZone)
    .toFormat("DD");

  return (
    <tr className="default-transition hover:bg-gray-200">
      {includeWorkspace && (
        <td className="p-2 rounded-l-lg">{workspace ? workspace.name : ""}</td>
      )}
      <td
        className={`p-2 text-right ${includeWorkspace ? "" : "rounded-l-lg"}`}
      >
        {displayTotal}
      </td>
      <td className="p-2 text-gray-400">{invoice.currency.toUpperCase()}</td>
      <td className="p-2">
        <InvoiceStatusBadge status={invoice.status} />
      </td>
      <td className="p-2 text-sm">{`${displayPeriodStart} to ${displayPeriodEnd}`}</td>
      <td className="p-2 font-bold text-right">{displayAmountDue}</td>
      <td className="p-2 ">{displayCreated}</td>
      <td className="p-2 rounded-r-lg">
        <div className="h-full w-full flex items-center justify-end">
          <ExternalLink
            className={
              !invoice.hostedInvoiceUrl
                ? "opacity-50 cursor-not-allowed hover:text-normal focus:text-normal"
                : ""
            }
            href={invoice.hostedInvoiceUrl}
            title="View hosted invoice"
          >
            <ExternalLinkIcon className="w-4 h-4" />
          </ExternalLink>
          <ExternalLink
            className={`ml-2 ${
              !invoice.invoicePdfUrl
                ? "opacity-50 cursor-not-allowed hover:text-normal focus:text-normal"
                : ""
            }`}
            href={invoice.invoicePdfUrl}
            title="Download invoice"
          >
            <DownloadIcon className="w-4 h-4" />
          </ExternalLink>
        </div>
      </td>
    </tr>
  );
};

export default InvoiceTableRow;
