import React from "react";
import { mergeClassNames } from "../../../libs/components";

import Card from "./Card";

interface AnalyticsOverviewCardLoadingProps {
  className?: string;
}
const AnalyticsOverviewCardLoading: React.FC<
  AnalyticsOverviewCardLoadingProps
> = ({ className = "" }) => {
  return (
    <Card
      className={mergeClassNames("animate-pulse bg-slate-200", className)}
      footerClassName="bg-neutral-900 bg-opacity-10"
      icon={null}
      title={
        <div className="my-1 bg-neutral-900 bg-opacity-10 h-4 w-24 rounded-full"></div>
      }
      subtitle={
        <div className="mt-2 mb-1 bg-neutral-900 bg-opacity-10 h-6 w-12 rounded-full"></div>
      }
      footer={<div className="h-full w-full"></div>}
    />
  );
};

export default AnalyticsOverviewCardLoading;
