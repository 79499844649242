import React, { ComponentProps } from "react";
import { mergeClassNames } from "../../libs/components";

import Badge from "./Badge";

interface PrimaryBadgeProps extends ComponentProps<typeof Badge> {}

const PrimaryBadge: React.FC<PrimaryBadgeProps> = ({
  className = "",
  ...props
}) => {
  return (
    <Badge
      className={mergeClassNames("bg-purple-100 text-purple-500", className)}
      {...props}
    />
  );
};

export default PrimaryBadge;
