import React, { useMemo, useState } from "react";
import { Check as CheckIcon } from "react-feather";
import { EditorState, Modifier, SelectionState } from "draft-js";
import Twitter from "twitter-text";

import { Platform, SelectedEntities } from "../../../../../types/platforms";
import {
  MentionEntityData,
  PlatformMentionEntityData,
} from "../../../../../types/posts";

import {
  ENTITY_TYPES,
  getEndOfEntitySelection,
  getEntitySelection,
  MentionEntityInstance,
  replaceEntityDisplayText,
} from "../../../../../libs/postTextArea";
import { isSpaceCharacter } from "../../../../../libs/utils";

import IconButton from "../../../../../components/buttons/Icon";
import PlatformIcon from "../../../../../components/icons/Platform";

import { ReactComponent as MentionImage } from "../../../../../images/mention.svg";

import PlatformMentions from "./mentions/PlatformMentions";

const ENTITY_TYPE = ENTITY_TYPES.MENTION;

function createNewMentionEntity(
  editorState: EditorState,
  blockKey: string,
  textStart: number,
  textEnd: number,
  newData: MentionEntityData,
  newMentionText: string
): [string, EditorState] {
  let newEditorState = editorState;
  let newContentState = newEditorState.getCurrentContent();
  const entitySelection = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: textStart,
    focusOffset: textEnd,
  });

  // Create the new mention entity.
  newContentState = newContentState.createEntity(
    ENTITY_TYPE,
    "IMMUTABLE",
    newData
  );
  const newEntityKey = newContentState.getLastCreatedEntityKey();

  newContentState = Modifier.applyEntity(
    newContentState,
    entitySelection,
    newEntityKey
  );

  // Change the text in the text area to be the mentioned entity's
  // name or username.
  newContentState = replaceEntityDisplayText(
    blockKey,
    newEntityKey,
    newContentState,
    newMentionText
  );

  const newSelection = getEndOfEntitySelection(
    newContentState,
    blockKey,
    newEntityKey
  );

  if (!newSelection) {
    // This shouldn't happen, because it means the entity failed to create.
    // If it does then just bail.
    return [newEntityKey, editorState];
  }

  newEditorState = EditorState.push(
    newEditorState,
    newContentState,
    "apply-entity"
  );

  // Force selection to make the editor re-render and show the new state.
  return [
    newEntityKey,
    EditorState.forceSelection(newEditorState, newSelection),
  ];
}

function removeMentionEntity(
  editorState: EditorState,
  blockKey: string,
  entityKey: string
) {
  let newEditorState = editorState;
  let newContentState = newEditorState.getCurrentContent();
  const block = newContentState.getBlockForKey(blockKey);
  const entitySelection = getEntitySelection(
    newContentState,
    blockKey,
    entityKey
  );

  if (!entitySelection || !block) {
    return editorState;
  }

  // Delete the current entity.
  newContentState = Modifier.applyEntity(
    newContentState,
    entitySelection,
    null
  );

  const blockText = block.getText();
  const mentionText = blockText.substring(
    entitySelection.getAnchorOffset(),
    entitySelection.getFocusOffset()
  );

  if (mentionText.length > 0 && Twitter.regexen.atSigns.test(mentionText[0])) {
    // If the mention text has a leading @ (aka Twitter mentions) then we should
    // strip the @ from the text when the user removes the mention so that it isn't
    // flagged as a mention by Twitter.
    newContentState = Modifier.replaceText(
      newContentState,
      entitySelection,
      mentionText.substring(1)
    );
  }

  newEditorState = EditorState.push(
    newEditorState,
    newContentState,
    "apply-entity"
  );

  // Move the cursor to the end of the entity text. Force selection
  // to make the editor re-render and show the new state.
  return EditorState.forceSelection(
    newEditorState,
    entitySelection.merge({
      anchorOffset: entitySelection.getEndOffset(),
      focusOffset: entitySelection.getEndOffset(),
    })
  );
}

interface PostTextAreaDrawersMentionsProps {
  close: () => void;
  selectedEntities: SelectedEntities[];
  editorState: EditorState;
  setEditorState: (newEditorState: EditorState) => void;
  blockKey: string;
  start: number;
  end: number;
  entityKey?: string;
  searchText?: string;
}
const PostTextAreaDrawersMentions: React.FC<
  PostTextAreaDrawersMentionsProps
> = ({
  close,
  selectedEntities,
  editorState,
  setEditorState,
  blockKey,
  start,
  end,
  entityKey,
  searchText,
}) => {
  const selectedEntitiesByPlatformType = useMemo(() => {
    return selectedEntities.reduce<{
      [platformType: string]: SelectedEntities[];
    }>((carry, selected) => {
      const platformType = selected.platform.type;
      if (carry[platformType]) {
        carry[platformType].push(selected);
      } else {
        carry[platformType] = [selected];
      }

      return carry;
    }, {});
  }, [selectedEntities]);
  const platformTypes = useMemo(
    () => Object.keys(selectedEntitiesByPlatformType) as Platform["type"][],
    [selectedEntitiesByPlatformType]
  );
  const [visiblePlatformType, setVisiblePlatformType] = useState<
    Platform["type"]
  >(platformTypes[0]);
  const [internalEntityKey, setInternalEntityKey] = useState(entityKey);

  const hasSelectedEntities = selectedEntities.length > 0;
  const isMultiPlatform = platformTypes.length > 1;
  const entity = internalEntityKey
    ? (editorState
        .getCurrentContent()
        .getEntity(internalEntityKey) as MentionEntityInstance)
    : null;
  const entityData = entity ? entity.getData() : null;

  const hasSelectedEntityForPlatform = (platformType: Platform["type"]) => {
    return !!entityData && !!entityData.mentions[platformType];
  };

  const handleMentionChange = (
    platformType: Platform["type"],
    newMentionData: PlatformMentionEntityData | null
  ) => {
    if (newMentionData === null) {
      if (!hasSelectedEntityForPlatform(platformType)) {
        // We're trying to delete an already removed entity so
        // there is nothing to do.
        return;
      }
    }

    const newEntityData: MentionEntityData = entityData
      ? {
          displayAs: entityData.displayAs || platformType,
          mentions: {
            ...entityData.mentions,
            [platformType]: newMentionData,
          },
        }
      : {
          displayAs: platformType,
          mentions: {
            [platformType]: newMentionData,
          },
        };
    const newMentions = newEntityData.mentions;
    // Has the user selected a mention for every platform?
    const allPlatformsSelected = platformTypes.every(
      (platformType) => !!newMentions[platformType]
    );
    const noMentions =
      Object.keys(newMentions).length < 1 ||
      Object.keys(newMentions).every(
        (platformType) => !newMentions[platformType]
      );
    const isFinished = allPlatformsSelected || noMentions;

    const mentionsPlatformTypes = (
      Object.keys(newMentions) as Platform["type"][]
    ).filter((platformType) => !!newMentions[platformType]);
    const isRemovingFinalMention = mentionsPlatformTypes.length === 0;

    if (!internalEntityKey || !entityData) {
      // We haven't yet created an entity so let's do that now.
      const [newEntityKey, newEditorState] = createNewMentionEntity(
        editorState,
        blockKey,
        start,
        end,
        newEntityData,
        newMentionData!.text
      );

      setEditorState(newEditorState);
      setInternalEntityKey(newEntityKey);
    } else if (isRemovingFinalMention) {
      setEditorState(
        removeMentionEntity(editorState, blockKey, internalEntityKey)
      );
      setInternalEntityKey(undefined);
    } else {
      let newEditorState = editorState;
      let newContentState = newEditorState.getCurrentContent();
      const oldDisplayAs = entityData ? entityData.displayAs : null;
      const newDisplayAs =
        newMentionData === null
          ? mentionsPlatformTypes[0]
          : newEntityData.displayAs;

      if (newDisplayAs !== oldDisplayAs && newDisplayAs) {
        const newDisplayAsEntityData = newEntityData.mentions[newDisplayAs]!;
        newContentState = replaceEntityDisplayText(
          blockKey,
          internalEntityKey,
          newContentState,
          newDisplayAsEntityData.text
        );
      }

      // This fucking thing isn't an immutable change! It modifies the
      // object in place which is different to the way that everything else
      // behaves and introduces an insane amount of tiny bugs and weird
      // behaviour.
      newContentState.mergeEntityData(internalEntityKey, newEntityData);

      let endOfEntitySelection = getEndOfEntitySelection(
        newContentState,
        blockKey,
        internalEntityKey
      );

      if (isFinished && endOfEntitySelection) {
        // If it's the last entity being selected then set the editor cursor
        // position to be at the end of the entity.
        const entityBlock = newContentState.getBlockForKey(
          endOfEntitySelection.getFocusKey()
        );

        if (entityBlock) {
          const blockText = entityBlock.getText();

          if (blockText.length > endOfEntitySelection.getFocusOffset()) {
            const charAfterEntity =
              blockText[endOfEntitySelection.getFocusOffset() + 1];

            if (
              charAfterEntity === undefined ||
              isSpaceCharacter(charAfterEntity)
            ) {
              // If the entity text has a space after it then move the cursor
              // to the space.
              endOfEntitySelection = endOfEntitySelection.merge({
                anchorOffset: endOfEntitySelection.getAnchorOffset() + 1,
                focusOffset: endOfEntitySelection.getFocusOffset() + 1,
              });
            }
          }
        }
      }

      newEditorState = EditorState.push(
        newEditorState,
        newContentState,
        "apply-entity"
      );

      if (endOfEntitySelection) {
        // Force selection to make the editor re-render and show the new state.
        newEditorState = EditorState.forceSelection(
          newEditorState,
          endOfEntitySelection
        );
      }

      setEditorState(newEditorState);
    }

    if (isFinished) {
      close();
    }
  };

  if (!hasSelectedEntities) {
    return (
      <div className="pt-4 h-full flex flex-col items-center">
        <p className="mx-4">
          Please select some socials before adding mentions
        </p>
        <div className="grow flex items-center justify-center">
          <MentionImage className="mt-4 h-28 w-32 text-purple-500" />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col overflow-hidden">
      {isMultiPlatform && (
        <div className="px-2 pt-1 flex items-center">
          {platformTypes.map((platformType) => {
            const isSelected = platformType === visiblePlatformType;
            const platformEntityData = entityData
              ? entityData.mentions[platformType] || null
              : null;
            const isComplete = !!platformEntityData;

            return (
              <IconButton
                key={platformType}
                className={`mr-1 relative ${
                  isSelected
                    ? "text-black from-emerald-200 to-sky-200"
                    : isComplete
                    ? "opacity-50 text-green-500 hover:opacity-100 focus:opacity-100"
                    : ""
                }`}
                onClick={() => setVisiblePlatformType(platformType)}
              >
                <PlatformIcon type={platformType} />
                {isComplete && (
                  <div className="absolute top-0 right-0 w-3 h-3 rounded-full bg-green-500 text-white flex items-center justify-center">
                    <CheckIcon className="w-3 h-3" />
                  </div>
                )}
              </IconButton>
            );
          })}
        </div>
      )}

      {platformTypes.map((platformType) => {
        const isVisible = visiblePlatformType === platformType;
        const platform =
          selectedEntitiesByPlatformType[platformType][0].platform;
        const platformEntityData = entityData
          ? entityData.mentions[platformType] || null
          : null;

        return (
          <PlatformMentions
            key={platformType}
            className="grow overflow-hidden"
            platform={platform}
            value={platformEntityData}
            onChange={(newMentionData) => {
              handleMentionChange(platformType, newMentionData);

              const nextVisiblePlatformType = platformTypes.find(
                (candidate) =>
                  candidate !== platformType &&
                  !hasSelectedEntityForPlatform(candidate)
              );

              if (nextVisiblePlatformType) {
                setVisiblePlatformType(nextVisiblePlatformType);
              }
            }}
            defaultSearchTerm={searchText}
            isVisible={isVisible}
          />
        );
      })}
    </div>
  );
};

export default PostTextAreaDrawersMentions;
