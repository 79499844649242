import React, { useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather";

import { LinkAttachment } from "../../../../types/posts";
import { getImageDimensions } from "../../../../libs/images";
import {
  useAsyncState,
  useDebouncedFunction,
  usePrevious,
} from "../../../../libs/hooks/general";
import { useApiClient } from "../../../../libs/hooks/app";

import LoaderIcon from "../../../../components/icons/Loader";
import LinkPreviewCard from "../../../../components/links/PreviewCard";
import IconButton from "../../../../components/buttons/Icon";
import Input from "../../../../components/form/Input";
import TertiaryButton from "../../../../components/buttons/Tertiary";

interface LinkPreviewResponse {
  uri: string;
  title: string | null;
  description: string | null;
  domainName: string;
  imageUrls: string[];
}

interface PostAttachmentLinkProps {
  value: LinkAttachment | null;
  onChange: (newValue: LinkAttachment) => void;
  className?: string;
  error?: boolean;
  focusOnRender?: boolean;
  canEdit?: boolean;
  candidateLinkAttachments?: string[];
}
const PostAttachmentLink: React.FC<PostAttachmentLinkProps> = ({
  value,
  onChange,
  className = "",
  error,
  focusOnRender = true,
  canEdit = true,
  candidateLinkAttachments = [],
}) => {
  const apiClient = useApiClient();
  const previousValue = usePrevious(value);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const currentLoadUriInstance = useRef("");
  const [changingImage, setChangingImage] = useState<"prev" | "next" | null>(
    null
  );
  const [selectedThumbnailUrlIndex, setSelectedThumbnailUrlIndex] =
    useState(-1);
  const [thumbnails, setThumbnails] = useState<
    NonNullable<LinkAttachment["thumbnail"]>[]
  >([]);
  const {
    loading,
    setLoading,
    error: renderError,
    setError,
    clearError,
  } = useAsyncState();
  const showPreview =
    !!value && (!!value.title || !!value.description || !!value.thumbnail);
  const showSuggestions = !showPreview && candidateLinkAttachments.length > 0;
  const canSelectPreviousImage =
    canEdit && thumbnails.length > 1 && selectedThumbnailUrlIndex > 0;
  const canSelectNextImage =
    canEdit &&
    thumbnails.length > 1 &&
    selectedThumbnailUrlIndex < thumbnails.length - 1;

  const nextImage = async () => {
    if (value && canSelectNextImage) {
      setChangingImage("next");
      const nextIndex = selectedThumbnailUrlIndex + 1;
      let nextThumbnail = thumbnails[nextIndex];

      if (!nextThumbnail.width || !nextThumbnail.height) {
        const [width, height] = await getImageDimensions(nextThumbnail.url);
        nextThumbnail = {
          url: nextThumbnail.url,
          width,
          height,
        };

        const newThumbnails = thumbnails.slice();
        thumbnails[nextIndex] = nextThumbnail;

        setThumbnails(newThumbnails);
      }

      setSelectedThumbnailUrlIndex(nextIndex);
      onChange({
        ...value,
        thumbnail: nextThumbnail,
      });
      setChangingImage(null);
    }
  };

  const previousImage = async () => {
    if (value && canSelectPreviousImage) {
      setChangingImage("prev");
      const nextIndex = selectedThumbnailUrlIndex - 1;
      let prevThumbnail = thumbnails[nextIndex];

      if (!prevThumbnail.width || !prevThumbnail.height) {
        const [width, height] = await getImageDimensions(prevThumbnail.url);
        prevThumbnail = {
          url: prevThumbnail.url,
          width,
          height,
        };

        const newThumbnails = thumbnails.slice();
        thumbnails[nextIndex] = prevThumbnail;

        setThumbnails(newThumbnails);
      }

      setSelectedThumbnailUrlIndex(nextIndex);
      onChange({
        ...value,
        thumbnail: prevThumbnail,
      });
      setChangingImage(null);
    }
  };

  const debouncedLoadUriData = useDebouncedFunction(async (newUri: string) => {
    let uri = newUri.trim();

    if (uri === "") {
      return;
    }

    clearError();
    setLoading(true);

    if (!/^http/.test(uri)) {
      uri = `https://${uri}`;
      onChange({
        ...value,
        url: uri,
      });
    }

    const instanceId = nanoid();
    currentLoadUriInstance.current = instanceId;

    try {
      const { data: response } = await apiClient.post("/proxies/link_preview", {
        uri,
      });
      const { data } = response as { data: LinkPreviewResponse };

      const selectedThumbnailUrl =
        value && value.thumbnail
          ? value.thumbnail.url
          : data.imageUrls.length
          ? data.imageUrls[0]
          : null;
      const newSelectedThumbnailUrl = selectedThumbnailUrl
        ? data.imageUrls.indexOf(selectedThumbnailUrl)
        : -1;
      const title =
        value && value.title ? value.title : data.title || undefined;
      const description =
        value && value.description
          ? value.description
          : data.description || undefined;
      const domainName =
        value && value.domainName ? value.domainName : data.domainName;
      let thumbnailWidth: number | undefined =
        value && value.thumbnail ? value.thumbnail.width : undefined;
      let thumbnailHeight: number | undefined =
        value && value.thumbnail ? value.thumbnail.height : undefined;

      if (selectedThumbnailUrl && (!thumbnailWidth || !thumbnailHeight)) {
        [thumbnailWidth, thumbnailHeight] = await getImageDimensions(
          selectedThumbnailUrl
        );
      }

      if (!inputRef.current) {
        return;
      }

      // If the URI has been changed while we've been loading.
      if (currentLoadUriInstance.current !== instanceId) {
        return;
      }

      setSelectedThumbnailUrlIndex(newSelectedThumbnailUrl);
      setThumbnails(
        data.imageUrls.map((imageUrl) => {
          return {
            url: imageUrl,
            width:
              imageUrl === selectedThumbnailUrl ? thumbnailWidth : undefined,
            height:
              imageUrl === selectedThumbnailUrl ? thumbnailHeight : undefined,
          };
        })
      );
      onChange({
        url: uri,
        thumbnail: selectedThumbnailUrl
          ? {
              url: selectedThumbnailUrl,
              width: thumbnailWidth,
              height: thumbnailHeight,
            }
          : undefined,
        title,
        description,
        domainName,
      });
    } catch (e) {
      setError({
        title: "Whoops!",
        message: "We were unable to generate a preview for this link",
      });
      onChange({
        url: uri,
      });
    }

    setLoading(false);
  });

  useEffect(() => {
    if (inputRef.current && focusOnRender) {
      inputRef.current.focus();
    }
  }, [focusOnRender]);

  useEffect(() => {
    if (value !== previousValue && value !== null) {
      const prevUri = previousValue ? previousValue.url : null;
      const newUri = value.url;

      if (newUri !== prevUri) {
        debouncedLoadUriData(newUri);
      }
    }
  }, [debouncedLoadUriData, previousValue, value]);

  return (
    <div className={className}>
      <div className="flex items-center relative focus-within:text-purple-500">
        <Input
          ref={inputRef}
          className="pl-12 pr-4 text-gray-600"
          labelText="Link URL"
          hideLabel={true}
          placeholder="Link to share, e.g. https://seenly.io"
          value={value ? value.url : ""}
          onChange={(e) => {
            onChange({ url: e.target.value });
          }}
          error={error}
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-4">
          {loading ? <LoaderIcon /> : <SearchIcon />}
        </div>
      </div>

      {showSuggestions && (
        <div className="mt-4">
          <h4 className="font-bold text-xs text-gray-300">SUGGESTIONS</h4>
          {candidateLinkAttachments.map((url, index) => {
            const key = `${index}-${url}`;
            return (
              <TertiaryButton
                key={key}
                className="mt-1 w-full"
                onClick={() => onChange({ url })}
                disabled={loading}
              >
                {url}
              </TertiaryButton>
            );
          })}
        </div>
      )}

      {showPreview && (
        <div className="w-full">
          <div className="pt-1 flex items-center">
            <IconButton
              disabled={!canSelectPreviousImage || changingImage !== null}
              onClick={() => previousImage()}
            >
              {changingImage === "prev" ? <LoaderIcon /> : <ArrowLeftIcon />}
            </IconButton>
            <IconButton
              disabled={!canSelectNextImage || changingImage !== null}
              onClick={() => nextImage()}
            >
              {changingImage === "next" ? <LoaderIcon /> : <ArrowRightIcon />}
            </IconButton>
            {canEdit && thumbnails.length > 0 && (
              <span className="ml-auto mr-2 text-sm text-gray-500">
                {`${selectedThumbnailUrlIndex + 1}/${thumbnails.length}`}
              </span>
            )}
          </div>
          <LinkPreviewCard
            className="mt-2 block"
            url={value!.url}
            thumbnailUrl={value!.thumbnail ? value!.thumbnail.url : undefined}
            thumbnailHeight={
              value!.thumbnail ? value!.thumbnail.height : undefined
            }
            thumbnailWidth={
              value!.thumbnail ? value!.thumbnail.width : undefined
            }
            domainName={value!.domainName}
            title={value!.title}
            description={value!.description}
          />
        </div>
      )}

      {renderError({ className: "mt-2" })}
    </div>
  );
};

export default PostAttachmentLink;
