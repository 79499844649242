import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { animated, useSpring } from "@react-spring/web";

import { mergeClassNames, mergeRefs } from "../../libs/components";

interface CheckboxToggleProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type"
  > {
  checkedContent: ReactNode;
  uncheckedContent: ReactNode;
  checkedContentClassName?: string;
  uncheckedContentClassName?: string;
}

const CheckboxToggle = forwardRef<HTMLInputElement, CheckboxToggleProps>(
  (
    {
      checkedContent,
      uncheckedContent,
      onChange,
      className = "",
      checkedContentClassName = "",
      uncheckedContentClassName = "",
      checked,
      defaultChecked,
      ...inputProps
    },
    ref
  ) => {
    const firstRef = useRef(true);
    const localRef = useRef<HTMLInputElement | null>(null);
    const [isChecked, setIsChecked] = useState(
      checked || defaultChecked || false
    );
    const [spring, springApi] = useSpring(() => ({
      x: 0,
    }));

    const localOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);

        if (onChange) {
          onChange(e);
        }
      },
      [onChange]
    );

    useEffect(() => {
      if (localRef.current) {
        setIsChecked(localRef.current.checked);
      }
    }, []);

    useEffect(() => {
      if (isChecked) {
        springApi.start({ x: 0, immediate: firstRef.current });
      } else {
        springApi.start({ x: 100, immediate: firstRef.current });
      }

      firstRef.current = false;
    }, [isChecked, springApi]);

    useEffect(() => {
      if (checked !== undefined) {
        setIsChecked(checked);
      }
    }, [checked]);

    return (
      <label
        className={mergeClassNames(
          "group relative inline-flex items-center border-2 border-gray-100 rounded-lg bg-gray-100 cursor-pointer default-transition hover:bg-purple-100 hover:border-purple-500 focus:bg-purple-100 focus:border-purple-500 focus-within:bg-purple-100 focus-within:border-purple-500",
          className
        )}
        style={{ zIndex: 0 }}
        tabIndex={0}
      >
        <animated.div
          className="absolute top-0 left-0 h-full w-1/2 bg-white rounded-lg pointer-events-none"
          style={{
            transform: spring.x.to((x) => `translate3d(${x}%, 0, 0)`),
            zIndex: -1,
          }}
        ></animated.div>

        <input
          ref={mergeRefs([ref, localRef])}
          className="sr-only"
          type="checkbox"
          onChange={localOnChange}
          tabIndex={-1}
          checked={isChecked}
          {...inputProps}
        />

        <div
          className={mergeClassNames(
            `px-2 py-1 w-1/2 default-transition ${
              isChecked
                ? ""
                : "opacity-25 group-hover:text-purple-900 group-focus-within:text-purple-900"
            }`,
            checkedContentClassName
          )}
          aria-hidden={!isChecked}
        >
          {checkedContent}
        </div>
        <div
          className={mergeClassNames(
            `px-2 py-1 w-1/2 default-transition ${
              !isChecked
                ? ""
                : "opacity-25 group-hover:text-purple-900 group-focus-within:text-purple-900"
            }`,
            uncheckedContentClassName
          )}
          aria-hidden={isChecked}
        >
          {uncheckedContent}
        </div>
      </label>
    );
  }
);

export default CheckboxToggle;
