import React from "react";

interface AnalyticsCommentsSentimentLoadingProps {
  className?: string;
}
const AnalyticsCommentsSentimentLoading: React.FC<
  AnalyticsCommentsSentimentLoadingProps
> = ({ className = "" }) => {
  return (
    <div className={className}>
      <div className="w-full h-10 rounded animate-pulse bg-slate-200"></div>
      <div className="mt-2 w-full flex items-center justify-around">
        {[1, 2, 3, 4].map((_, index) => {
          return (
            <div key={index} className="flex items-center h-full">
              <div className="h-6 w-6 rounded-full animate-pulse bg-slate-200"></div>
              <span className="ml-2 h-4 w-20 rounded animate-pulse bg-slate-200"></span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnalyticsCommentsSentimentLoading;
