import React from "react";

import Card from "./Card";

interface AnalyticsDailySnapshotCardLoadingProps {
  className?: string;
}
const AnalyticsDailySnapshotCardLoading: React.FC<
  AnalyticsDailySnapshotCardLoadingProps
> = ({ className = "" }) => {
  return (
    <Card
      className={className}
      iconContainerClassName="animate-pulse bg-slate-200"
      icon={null}
      title={
        <div className="animate-pulse bg-slate-200 h-4 w-24 rounded-full"></div>
      }
      subtitle={
        <div className="animate-pulse bg-slate-200 h-6 w-12 rounded-full"></div>
      }
    />
  );
};

export default AnalyticsDailySnapshotCardLoading;
