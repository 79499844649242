import React from "react";
import { useContentSwitch } from "../../../libs/hooks/general";
import { getPlatformTypeName } from "../../../libs/platform";
import {
  AddPlatformContent,
  BaseComponentProps,
} from "../../../types/modals/AddPlatform";

import H3 from "../../../components/headings/H3";
import PrimaryButton from "../../../components/buttons/Primary";
import SecondaryButton from "../../../components/buttons/Secondary";
import PlatformIcon from "../../../components/icons/Platform";
import ErrorAlert from "../../../components/alerts/Error";

import { ReactComponent as ErrorImage } from "../../../images/add-platform/error.svg";

interface ErrorProps extends BaseComponentProps {}

const AddPlatformModalError: React.FC<ErrorProps> = () => {
  const { showContent, content } = useContentSwitch<
    AddPlatformContent,
    "error"
  >("error");
  const { platformType, title, message, tryAgainContent } = content.data;
  const platformTypeName = getPlatformTypeName(platformType);

  return (
    <div className="p-8" style={{ width: 600 }}>
      <div className="h-full w-full flex flex-col">
        <H3 className="flex-shrink-0 flex items-center">
          <PlatformIcon className="mr-2" type={platformType} colour={true} />
          Connect {platformTypeName}
        </H3>
        <div className="flex-grow flex flex-col">
          <div className="mt-4 flex">
            <ErrorImage
              className="mx-auto flex-shrink-0 text-red-500"
              height={200}
              width={200}
            />
          </div>
          <ErrorAlert className="mt-8" title={title} message={message} />
          <div className="flex-shrink-0 mt-4">
            <PrimaryButton
              className="mt-4 block w-full"
              onClick={() => showContent(tryAgainContent)}
            >
              Try again
            </PrimaryButton>
            <SecondaryButton
              className="mt-2 block w-full"
              onClick={() => showContent({ id: "home" })}
            >
              Back
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlatformModalError;
