import React from "react";
import Twitter from "twitter-text";

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
export function escapeRegExp(value: string) {
  return value.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function debounce<T extends (...args: any) => void>(
  func: T,
  delay: number
): T {
  let timeoutId: number | null = null;
  let previousCall: number = 0;
  let args: any = undefined;

  const delayedCallback = () => {
    const timeSinceLastCall = Date.now() - previousCall;

    if (timeSinceLastCall > delay) {
      func(...args);
      timeoutId = null;
      previousCall = 0;
      args = undefined;
    } else {
      timeoutId = window.setTimeout(delayedCallback, delay - timeSinceLastCall);
    }
  };

  return ((...newArgs: any) => {
    if (timeoutId === null) {
      timeoutId = window.setTimeout(delayedCallback, delay);
    }

    args = newArgs;
    previousCall = Date.now();
  }) as unknown as T;
}

export function clamp(number: number, min: number, max: number) {
  return Math.min(max, Math.max(min, number));
}

export function clampImageHeight(
  height: number,
  width: number,
  maxHeight: number
) {
  let newHeight = height;
  let newWidth = width;

  if (height > maxHeight) {
    const ratio = maxHeight / height;
    newHeight = height * ratio;
    newWidth = width * ratio;
  }

  return [newHeight, newWidth] as [number, number];
}

export function clampImageWidth(
  height: number,
  width: number,
  maxWidth: number
) {
  let newHeight = height;
  let newWidth = width;

  if (width > maxWidth) {
    const ratio = maxWidth / width;
    newHeight = height * ratio;
    newWidth = width * ratio;
  }

  return [newHeight, newWidth] as [number, number];
}

export function clampImageSize(
  height: number,
  width: number,
  maxHeight: number,
  maxWidth: number
) {
  return clampImageWidth(
    ...clampImageHeight(height, width, maxHeight),
    maxWidth
  );
}

export function isSpaceCharacter(c: string) {
  return /\s/.test(c);
}

export function isPunctuationCharacter(c: string) {
  // Taken from https://github.com/twitter/twitter-text/blob/master/js/src/regexp/punct.js and
  // modified to actually work.
  return /[!'#%&'()*+,\-./:;<=>?@[\]^_{|}~$]/.test(c);
}

export function getWordBoundsAtIndex(
  text: string,
  index: number,
  options: { breakOnPunctuation: boolean } = { breakOnPunctuation: false }
) {
  if (text.length < 1) {
    return [null, null, null] as const;
  }

  const lastIndex = Math.max(0, text.length - 1);
  let start = clamp(index, 0, lastIndex);
  let end = clamp(index, 0, lastIndex);

  if (isSpaceCharacter(text[start])) {
    // Check if we're setting at the end of a word (between last
    // character and a space) and if so then step back 1 character
    // for the search to grab the full word.
    const prevIndex = index - 1;
    start = clamp(prevIndex, 0, lastIndex);
    end = clamp(prevIndex, 0, lastIndex);

    if (isSpaceCharacter(text[start])) {
      return [null, null, null] as const;
    }
  }

  while (start > 0 && !isSpaceCharacter(text[start - 1])) {
    start--;
  }

  while (end <= lastIndex && !isSpaceCharacter(text[end])) {
    end++;
  }

  return [start, end, text.slice(start, end)] as const;
}

export function stripUrlProtocol(url: string) {
  return url.replace(/^https?:\/\/(www\.)?/, "");
}

export function ensureUrlHasProtocol(url: string) {
  return Twitter.regexen.urlHasProtocol.test(url) ? url : `https://${url}`;
}

interface OpenPopupWindowParams {
  url: string;
  width?: number;
  height?: number;
  windowName?: string;
}
export function openPopupWindow({
  url,
  width = 500,
  height = 700,
  windowName,
}: OpenPopupWindowParams) {
  const screenX =
    window.screenX !== undefined
      ? window.screenX
      : window.screenLeft !== undefined
      ? window.screenLeft
      : 0;
  const screenY =
    window.screenY !== undefined
      ? window.screenY
      : window.screenTop !== undefined
      ? window.screenTop
      : 0;
  const windowHeight = window.outerHeight;
  const windowWidth = window.outerWidth;
  const left = screenX + windowWidth / 2 - width / 2;
  const top = screenY + windowHeight / 2 - height / 2;
  return window.open(
    url,
    windowName,
    `width=${width},height=${width},left=${left},top=${top},menubar=0,toolbar=0,location=0,status=0,resizable=0`
  );
}

export function isEqualOrAncestorElement(
  candidate: Element,
  child: Element
): boolean {
  if (candidate === child) {
    return true;
  }

  if (child.parentElement) {
    if (child.parentElement === candidate) {
      return true;
    }

    if (child.parentElement) {
      return isEqualOrAncestorElement(candidate, child.parentElement);
    }
  }

  return false;
}

/**
 * Copied from https://github.com/zhaolihang/fast-deep-copy/blob/master/src/deep-copy.ts
 * because the package isn't built for TypeScript properly.
 *
 * Deep copy the given object considering circular structure.
 * This function caches all nested objects and its copies.
 * If it detects circular structure, use cached copy to avoid infinite loop.
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy<T>(
  obj: T,
  cache: Array<{ original: any; copy: any }> = []
): T {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // if obj is hit, it is in circular structure
  const hit = cache.find((c) => c.original === obj);
  if (hit) {
    return hit.copy;
  }

  const copy = (Array.isArray(obj) ? [] : {}) as T;
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({ original: obj, copy });

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    copy[key] = deepCopy(obj[key], cache);
  });

  return copy;
}

interface JSObject {
  [key: string]: any;
}
// Lol this type.
type ObjectPatch<T extends JSObject> = Partial<{
  // If the object is an indexed object (it doesn't specify every
  // object property by name), e.g. { [key: string]: any }, then we
  // allow the patch values to be a patch or null.
  [K in keyof T]: string extends K
    ? T[K] extends JSObject
      ? ObjectPatch<T[K]> | null
      : T[K] | null
    : // If the property is optional, e.g. { foo?: string }, then we
    // allow it to be deleted with null.
    undefined extends T[K]
    ? JSObject extends T[K]
      ? ObjectPatch<T[K]> | null
      : T[K] | null
    : // Otherwise if it's an object recursively apply the patch.
    T[K] extends JSObject
    ? ObjectPatch<T[K]>
    : T[K];
}>;
export function patchObject<T extends JSObject>(
  obj: T,
  objPatch: ObjectPatch<T>
): T {
  const newObj = { ...obj };

  for (const [key, val] of Object.entries(objPatch)) {
    const prop = key as keyof T;

    if (val === null) {
      delete newObj[prop];
    } else if (typeof val === "object" && !Array.isArray(val)) {
      newObj[prop as keyof T] = patchObject(
        { ...newObj[prop as keyof T] },
        val
      );
    } else {
      newObj[prop as keyof T] = val;
    }
  }

  return newObj;
}

export function isModifierKeyPressed(
  e: React.KeyboardEvent | React.MouseEvent | React.TouchEvent
) {
  return e.altKey || e.shiftKey || e.ctrlKey || e.metaKey;
}

export function randomIntBetween(min: number, max: number) {
  // Min and max inclusive.
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function shuffleArray<T extends any>(arr: T[]): T[] {
  const arrCopy = arr.slice();

  for (let i = arrCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrCopy[i], arrCopy[j]] = [arrCopy[j], arrCopy[i]];
  }

  return arrCopy;
}
