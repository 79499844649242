import { QueryClient, QueryFilters, QueryKey } from "@tanstack/react-query";

type DataPropQueryKey = { dataProp: string; queryKey: QueryKey };
type QueryFilterWithQueryKey = QueryFilters & { queryKey: QueryKey };
type ObjectQueryKey = QueryKey | DataPropQueryKey | QueryFilterWithQueryKey;

export interface ObjectQueryKeys {
  detail?: QueryKey | DataPropQueryKey;
  lists?: ObjectQueryKey[];
  infiniteLists?: ObjectQueryKey[];
}

interface CacheData<T, L> {
  detail?: [QueryKey, T | { [dataProp: string]: T } | undefined | undefined];
  lists?: [QueryKey, L | undefined][];
  infiniteLists?: [QueryKey, L | undefined][];
}

type ListType<T> = { [key: string]: T[] } | T[];

function isDataPropQueryKey(data: ObjectQueryKey): data is DataPropQueryKey {
  return !!(data as DataPropQueryKey).dataProp;
}

function isQueryFilterWithQueryKey(
  data: ObjectQueryKey
): data is QueryFilterWithQueryKey {
  return (
    !isDataPropQueryKey(data) && !!(data as QueryFilterWithQueryKey).queryKey
  );
}

interface CacheObject {
  modified?: string;
}

function isObjectNewer(oldObject?: CacheObject, newObject?: CacheObject) {
  if (
    !!oldObject &&
    !!newObject &&
    !!oldObject.modified &&
    !!newObject.modified
  ) {
    return oldObject.modified < newObject.modified;
  } else {
    return true;
  }
}

type CreateFunc<T> = () => T | ((oldObjects: T[], objectIndex: number) => T[]);

type UpdateFunc<T> = (
  oldObject: T
) => T | ((oldObjects: T[], objectIndex: number) => T[]);

type InternalUpdateFunc<T> = (
  oldObject?: T
) => T | undefined | ((oldObjects: T[], objectIndex: number) => T[]);

type UpdateFuncOrNewObject<T> = InternalUpdateFunc<T> | T;

function internalModifyQueryCacheObject<
  T extends Record<string | number, unknown>
>(
  queryClient: QueryClient,
  queryKeys: ObjectQueryKeys,
  objectId: string,
  updateFuncOrNewObject: UpdateFuncOrNewObject<T> | null,
  objectIdProp: keyof T = "id"
) {
  const previousData: CacheData<T, ListType<T>> = {};

  if (queryKeys.detail) {
    const detailDataProp = isDataPropQueryKey(queryKeys.detail)
      ? queryKeys.detail.dataProp
      : null;
    const detailQueryKey = isDataPropQueryKey(queryKeys.detail)
      ? queryKeys.detail.queryKey
      : queryKeys.detail;

    previousData.detail = [
      detailQueryKey,
      queryClient.getQueryData<T | { [dataProp: string]: T } | undefined>(
        detailQueryKey
      ),
    ];

    if (updateFuncOrNewObject) {
      if (typeof updateFuncOrNewObject === "function") {
        const oldData = previousData.detail[1];
        let newData = oldData;

        if (detailDataProp) {
          const oldObject = oldData
            ? (oldData as { [dataProp: string]: T })[detailDataProp]
            : undefined;

          const updateResponse = updateFuncOrNewObject(oldObject);
          const newObject =
            typeof updateResponse === "function"
              ? updateResponse([], -1)[0]
              : updateResponse;

          if (newObject) {
            if (isObjectNewer(oldObject, newObject)) {
              newData = {
                ...oldData,
                [detailDataProp]: newObject,
              };
            }
          }
        } else {
          const updateResponse = updateFuncOrNewObject(oldData as T);
          const newObject =
            typeof updateResponse === "function"
              ? updateResponse([], -1)[0]
              : updateResponse;

          if (isObjectNewer(oldData, newObject)) {
            newData = newObject;
          }
        }

        if (newData !== oldData) {
          //Only update the cache if the values have changed.
          queryClient.setQueryData<T | { [dataProp: string]: T } | undefined>(
            detailQueryKey,
            newData
          );
        }
      } else {
        const oldData = previousData.detail[1];
        let newData = oldData;

        if (detailDataProp) {
          const oldObject = oldData
            ? (oldData as { [dataProp: string]: T })[detailDataProp]
            : undefined;

          if (isObjectNewer(oldObject, updateFuncOrNewObject)) {
            newData = {
              ...oldData,
              [detailDataProp]: updateFuncOrNewObject,
            };
          }
        } else {
          if (isObjectNewer(oldData, updateFuncOrNewObject)) {
            newData = updateFuncOrNewObject;
          }
        }

        if (newData !== oldData) {
          // Add a new value (or overwrite existing, if it exists).
          queryClient.setQueryData<T | { [dataProp: string]: T } | undefined>(
            detailQueryKey,
            newData
          );
        }
      }
    } else {
      // Delete the existing cache value.
      queryClient.removeQueries(detailQueryKey);
    }
  }

  const buildListQueryDataUpdateFunction =
    (dataProp: string | null) =>
    (oldObjects: ListType<T> | undefined = []) => {
      if (!Array.isArray(oldObjects) && (!dataProp || !oldObjects[dataProp])) {
        return oldObjects;
      }

      const oldObjectsList = Array.isArray(oldObjects)
        ? oldObjects
        : oldObjects[dataProp!];

      if (!oldObjectsList) {
        return oldObjects;
      }

      const itemIndex = oldObjectsList.findIndex(
        (candidate) => candidate[objectIdProp] === objectId
      );

      let newObjectsList = oldObjectsList;

      if (
        typeof updateFuncOrNewObject !== "function" &&
        updateFuncOrNewObject !== null
      ) {
        // We're being asked to add the item to this list.
        if (itemIndex >= 0) {
          const oldObject = newObjectsList[itemIndex];

          if (isObjectNewer(oldObject, updateFuncOrNewObject)) {
            newObjectsList = oldObjectsList.slice();
            newObjectsList[itemIndex] = updateFuncOrNewObject;
          }
        } else {
          newObjectsList = newObjectsList.concat(updateFuncOrNewObject);
        }
      } else if (typeof updateFuncOrNewObject === "function") {
        // We're being asked to update the existing object.
        const oldObject = oldObjectsList[itemIndex];
        const updateResponse = updateFuncOrNewObject(oldObject);

        if (updateResponse) {
          if (typeof updateResponse === "function") {
            newObjectsList = updateResponse(oldObjectsList.slice(), itemIndex);
          } else {
            if (itemIndex >= 0) {
              if (isObjectNewer(oldObject, updateResponse)) {
                newObjectsList = oldObjectsList.slice();
                newObjectsList[itemIndex] = updateResponse;
              }
            } else {
              newObjectsList = oldObjectsList.slice().concat(updateResponse);
            }
          }
        }
      } else if (itemIndex >= 0 && updateFuncOrNewObject === null) {
        // We're being asked to delete the existing object.
        newObjectsList = oldObjectsList.slice();
        newObjectsList.splice(itemIndex, 1);
      }

      if (newObjectsList === oldObjectsList) {
        // We didn't make any changes so just return the old objects
        // as is so that we don't update the object ref or anything.
        return oldObjects;
      } else if (dataProp) {
        return {
          ...oldObjects,
          [dataProp]: newObjectsList,
        };
      } else {
        return newObjectsList;
      }
    };

  if (queryKeys.lists) {
    previousData.lists = [];

    queryKeys.lists.forEach((objectQueryKey) => {
      const dataProp = isDataPropQueryKey(objectQueryKey)
        ? objectQueryKey.dataProp
        : null;
      const queryKeyOrQueryFunction = isDataPropQueryKey(objectQueryKey)
        ? objectQueryKey.queryKey
        : objectQueryKey;

      previousData.lists = previousData.lists!.concat(
        queryClient.getQueriesData<ListType<T>>(
          queryKeyOrQueryFunction as QueryKey
        )
      );

      const listUpdateFunction = buildListQueryDataUpdateFunction(dataProp);

      previousData.lists.forEach(([queryKey, oldData]) => {
        let newData = listUpdateFunction(oldData);

        if (newData !== oldData) {
          // Only update the cached data if it's actually changed.
          queryClient.setQueryData(queryKey, newData);
        }
      });
    });
  }

  if (queryKeys.infiniteLists) {
    previousData.infiniteLists = [];

    queryKeys.infiniteLists.forEach((objectQueryKey) => {
      const dataProp = isDataPropQueryKey(objectQueryKey)
        ? objectQueryKey.dataProp
        : null;
      const queryKeyOrQueryFunction = isDataPropQueryKey(objectQueryKey)
        ? objectQueryKey.queryKey
        : objectQueryKey;

      previousData.infiniteLists = previousData.infiniteLists!.concat(
        queryClient.getQueriesData<ListType<T>>(
          queryKeyOrQueryFunction as QueryKey
        )
      );

      const listUpdateFunction = buildListQueryDataUpdateFunction(dataProp);

      previousData.infiniteLists.forEach(([queryKey, oldData]) => {
        let newData = listUpdateFunction(oldData);

        if (newData !== oldData) {
          // Only update the cached data if it's actually changed.
          queryClient.setQueryData(queryKey, newData);
        }
      });
    });
  }

  return previousData;
}

async function cancelInflightQueries(
  queryClient: QueryClient,
  queryKeys: ObjectQueryKeys
) {
  const cancelPromises: Promise<any>[] = [];

  if (queryKeys.detail) {
    const detailQueryKey = isDataPropQueryKey(queryKeys.detail)
      ? queryKeys.detail.queryKey
      : queryKeys.detail;

    cancelPromises.push(queryClient.cancelQueries(detailQueryKey));
  }

  if (queryKeys.lists) {
    queryKeys.lists.forEach((objectQueryKey) => {
      const queryKey =
        isDataPropQueryKey(objectQueryKey) ||
        isQueryFilterWithQueryKey(objectQueryKey)
          ? objectQueryKey.queryKey
          : objectQueryKey;

      cancelPromises.push(
        queryClient.cancelQueries(
          queryKey,
          isQueryFilterWithQueryKey(objectQueryKey) ? objectQueryKey : undefined
        )
      );
    });
  }

  if (queryKeys.infiniteLists) {
    queryKeys.infiniteLists.forEach((objectQueryKey) => {
      const queryKey =
        isDataPropQueryKey(objectQueryKey) ||
        isQueryFilterWithQueryKey(objectQueryKey)
          ? objectQueryKey.queryKey
          : objectQueryKey;

      cancelPromises.push(
        queryClient.cancelQueries(
          queryKey,
          isQueryFilterWithQueryKey(objectQueryKey) ? objectQueryKey : undefined
        )
      );
    });
  }

  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await Promise.all(cancelPromises);
}

function buildRevertQueryCacheFunction<T extends Record<string | number, any>>(
  queryClient: QueryClient,
  previousData: CacheData<T, ListType<T>>
) {
  return () => {
    if (previousData.detail) {
      queryClient.setQueryData(previousData.detail[0], previousData.detail[1]);
    }

    if (previousData.lists) {
      previousData.lists.forEach(([queryKey, previousData]) => {
        queryClient.setQueryData(queryKey, previousData);
      });
    }

    if (previousData.infiniteLists) {
      previousData.infiniteLists.forEach(([queryKey, previousData]) => {
        queryClient.setQueryData(queryKey, previousData);
      });
    }
  };
}

export function createQueryCacheObject<T extends Record<string | number, any>>(
  queryClient: QueryClient,
  queryKeys: ObjectQueryKeys,
  objectId: string,
  newObjectOrCreateFunc: T | CreateFunc<T>,
  objectIdProp: keyof T = "id"
) {
  return internalModifyQueryCacheObject<T>(
    queryClient,
    queryKeys,
    objectId,
    newObjectOrCreateFunc,
    objectIdProp
  );
}

export function updateQueryCacheObject<T extends Record<string | number, any>>(
  queryClient: QueryClient,
  queryKeys: ObjectQueryKeys,
  objectId: string,
  updateFunc: UpdateFunc<T>,
  objectIdProp: keyof T = "id"
) {
  const internalUpdateFunc: InternalUpdateFunc<T> = (oldObject) => {
    if (!oldObject) {
      return;
    }

    return updateFunc(oldObject);
  };

  return internalModifyQueryCacheObject<T>(
    queryClient,
    queryKeys,
    objectId,
    internalUpdateFunc,
    objectIdProp
  );
}

// TODO: Fix the typing here. The objectIdProp should be mandatory if the
// type T doesn't have an "id" property.
export async function optimisticallyUpdateQueryCacheObject<
  T extends Record<string | number, any>
>(
  queryClient: QueryClient,
  queryKeys: ObjectQueryKeys,
  objectId: string,
  updateFunc: UpdateFunc<T>,
  objectIdProp: keyof T = "id"
) {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await cancelInflightQueries(queryClient, queryKeys);

  const previousData = updateQueryCacheObject<T>(
    queryClient,
    queryKeys,
    objectId,
    updateFunc,
    objectIdProp
  );

  return buildRevertQueryCacheFunction(queryClient, previousData);
}

export function removeQueryCacheObject<T extends Record<string | number, any>>(
  queryClient: QueryClient,
  queryKeys: ObjectQueryKeys,
  objectId: string,
  objectIdProp: keyof T = "id"
) {
  return internalModifyQueryCacheObject<T>(
    queryClient,
    queryKeys,
    objectId,
    null,
    objectIdProp
  );
}
