import { ComponentProps, forwardRef } from "react";
import { Link } from "react-router-dom";
import { mergeClassNames } from "../../libs/components";

const InternalPrimaryButtonLink = forwardRef<
  HTMLAnchorElement,
  ComponentProps<typeof Link>
>(({ className = "", ...props }, ref) => {
  return (
    <Link
      ref={ref}
      className={mergeClassNames(
        "py-3 px-4 flex items-center justify-center rounded-lg font-bold default-transition bg-gradient-to-r from-purple-500 to-blue-500 text-white hover:ring-4 hover:ring-sky-200 focus:ring-4 focus:ring-sky-200",
        className
      )}
      {...props}
    />
  );
});

export default InternalPrimaryButtonLink;
