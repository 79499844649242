import React, { ComponentProps, useState } from "react";

import { Workspace } from "../../types/workspaces";

import ModalContentStack from "../../components/ModalContentStack";

import FreeLimit from "./changeSubscription/FreeLimit";
import FreeTrial from "./changeSubscription/FreeTrial";
import Plans from "./changeSubscription/Plans";
import Payment from "./changeSubscription/Payment";

interface AddWorkspaceModalProps
  extends Omit<
    ComponentProps<typeof ModalContentStack>,
    "children" | "defaultContentId"
  > {
  workspace: Workspace;
  currentPriceId?: string;
  defaultPriceId?: string;
  redirectOnSuccess?: boolean;
  limitExceeded?: boolean;
  close: () => void;
}

const ChangeSubscriptionModal: React.FC<AddWorkspaceModalProps> = ({
  workspace,
  currentPriceId,
  defaultPriceId,
  redirectOnSuccess,
  limitExceeded,
  isVisible,
  close,
  ...modalProps
}) => {
  const [newPriceId, setNewPriceId] = useState<string | null>(null);

  return (
    <ModalContentStack
      {...modalProps}
      defaultContentId={
        limitExceeded
          ? "freeLimit"
          : workspace.hasHadTrial
          ? "plans"
          : "freeTrial"
      }
      isVisible={isVisible}
      close={close}
    >
      <FreeLimit
        contentId="freeLimit"
        workspace={workspace}
        onChange={setNewPriceId}
      />
      <FreeTrial
        contentId="freeTrial"
        workspace={workspace}
        close={close}
        redirectOnSuccess={redirectOnSuccess}
      />
      <Plans
        contentId="plans"
        currentPriceId={
          currentPriceId
            ? currentPriceId
            : workspace.subscription
            ? workspace.subscription.price.remoteId
            : undefined
        }
        defaultPriceId={defaultPriceId}
        onChange={setNewPriceId}
      />
      <Payment
        contentId="payment"
        workspace={workspace}
        priceId={newPriceId || ""}
        close={close}
        currentPriceId={currentPriceId}
        redirectOnSuccess={redirectOnSuccess}
      />
    </ModalContentStack>
  );
};

export default ChangeSubscriptionModal;
