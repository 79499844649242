import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Platform } from "../../types/platforms";
import { ApiResponse } from "../../types/general";
import { useDecoratedReactQuery } from "./general";
import { useApiClient } from "./app";
import {
  getLinkedInAuthorisationCode,
  LINKEDIN_REDIRECT_URI,
} from "../platforms/linkedin";
import {
  getFacebookAuthorisationCode,
  FACEBOOK_REDIRECT_URI,
} from "../platforms/facebook";
import { getTwitterVerifier, TWITTER_REDIRECT_URI } from "../platforms/twitter";
import { PLATFORMS_QUERY_KEYS, WORKSPACES_QUERY_KEYS } from "../queryKeys";
import {
  optimisticallyUpdateQueryCacheObject,
  createQueryCacheObject,
  updateQueryCacheObject,
  removeQueryCacheObject,
} from "../queryCache";
import ErrorCodes from "../errorCodes";
import { countEnabledEntities, getPlatformTypeName } from "../platform";
import { patchObject } from "../utils";
import { Workspace } from "../../types/workspaces";

export const usePlatforms = (workspaceId: string) => {
  const apiClient = useApiClient();
  return useQuery(PLATFORMS_QUERY_KEYS.list({ workspaceId }), async () => {
    const { data: response } = await apiClient.get<ApiResponse<Platform[]>>(
      "/platforms",
      {
        params: {
          workspaceId,
        },
      }
    );

    return response.data;
  });
};

export const usePlatformById = (platformId: string) => {
  const apiClient = useApiClient();
  return useQuery(PLATFORMS_QUERY_KEYS.detail(platformId), async () => {
    const { data: response } = await apiClient.get<ApiResponse<Platform>>(
      `/platforms/${platformId}`
    );
    return response.data;
  });
};

interface CreatePlatformPropsBase {
  workspaceId: string;
}

interface CreateLinkedInPlatformProps extends CreatePlatformPropsBase {
  type: "LINKEDIN";
  code: string;
  redirectUri: string;
}

interface CreateTwitterPlatformProps extends CreatePlatformPropsBase {
  type: "TWITTER";
  token: string;
  verifier: string;
}

interface CreateFacebookPlatformProps extends CreatePlatformPropsBase {
  type: "FACEBOOK";
  code: string;
  redirectUri: string;
}

type CreatePlatformProps =
  | CreateLinkedInPlatformProps
  | CreateTwitterPlatformProps
  | CreateFacebookPlatformProps;

export const useCreatePlatform = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (props: CreatePlatformProps) => {
        const { data: response } = await apiClient.post<
          ApiResponse<Platform, { isNew: boolean }>
        >("/platforms", props);

        return {
          platform: response.data,
          meta: response.meta,
        };
      },
      {
        onSuccess: ({ platform }) => {
          createQueryCacheObject(
            queryClient,
            {
              detail: PLATFORMS_QUERY_KEYS.detail(platform.id),
              lists: [
                PLATFORMS_QUERY_KEYS.list({
                  workspaceId: platform.workspaceId,
                }),
              ],
            },
            platform.id,
            platform
          );
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(
            WORKSPACES_QUERY_KEYS.detail(platform.workspaceId)
          );
        },
      }
    ),
    () => ({ title: "Failed to create platform" })
  );
};

interface UpdatePlatformProps {
  platform: Platform;
  updateProps: {
    entities: {
      [entityId: string]: {
        disabled: boolean;
      } | null;
    };
  };
  optimistic?: boolean;
}
export const useUpdatePlatform = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async ({ platform, updateProps }: UpdatePlatformProps) => {
        const { data: response } = await apiClient.patch<ApiResponse<Platform>>(
          `/platforms/${platform.id}`,
          updateProps
        );

        return response.data;
      },
      {
        onMutate: async ({ platform, updateProps, optimistic = false }) => {
          if (!optimistic) {
            return null;
          }

          const newPlatform = patchObject(platform, updateProps);
          const revertPlatform =
            await optimisticallyUpdateQueryCacheObject<Platform>(
              queryClient,
              {
                detail: PLATFORMS_QUERY_KEYS.detail(platform.id),
                lists: [
                  PLATFORMS_QUERY_KEYS.list({
                    workspaceId: platform.workspaceId,
                  }),
                ],
              },
              platform.id,
              () => newPlatform
            );
          const prevEnabledEntityCount = countEnabledEntities(platform);
          const newEnabledEntityCount = countEnabledEntities(newPlatform);
          const enabledEntitiesDiffCount =
            newEnabledEntityCount - prevEnabledEntityCount;

          if (enabledEntitiesDiffCount !== 0) {
            const revertWorkspace =
              await optimisticallyUpdateQueryCacheObject<Workspace>(
                queryClient,
                {
                  detail: {
                    dataProp: "workspace",
                    queryKey: WORKSPACES_QUERY_KEYS.detail(
                      platform.workspaceId
                    ),
                  },
                  lists: [
                    {
                      dataProp: "workspaces",
                      queryKey: WORKSPACES_QUERY_KEYS.lists(),
                    },
                  ],
                },
                platform.workspaceId,
                (oldWorkspace) => ({
                  ...oldWorkspace,
                  enabledPlatformEntitiesCount: Math.max(
                    0,
                    (oldWorkspace.enabledPlatformEntitiesCount || 0) +
                      enabledEntitiesDiffCount
                  ),
                })
              );

            return { revertPlatform, revertWorkspace };
          } else {
            return { revertPlatform };
          }
        },
        onError: (error, props, context) => {
          if (!context) {
            return;
          }

          const { revertPlatform, revertWorkspace } = context as {
            revertPlatform: () => void;
            revertWorkspace?: () => void;
          };

          revertPlatform();

          if (revertWorkspace) {
            revertWorkspace();
          }
        },
        onSuccess: (platform) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: PLATFORMS_QUERY_KEYS.detail(platform.id),
              lists: [
                PLATFORMS_QUERY_KEYS.list({
                  workspaceId: platform.workspaceId,
                }),
              ],
            },
            platform.id,
            () => platform
          );
        },
        onSettled: (successResponse, errorResponse, { platform }) => {
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(
            WORKSPACES_QUERY_KEYS.detail(platform.workspaceId)
          );
        },
      }
    ),
    () => ({ title: "Failed to update platform" })
  );
};

export const useDeletePlatform = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (platformId: string) => {
        const { data: response } = await apiClient.delete<ApiResponse<null>>(
          `/platforms/${platformId}`
        );
        return response.data;
      },
      {
        onSuccess: (ignore, platformId) => {
          removeQueryCacheObject(
            queryClient,
            {
              detail: PLATFORMS_QUERY_KEYS.detail(platformId),
              lists: [PLATFORMS_QUERY_KEYS.lists()],
            },
            platformId
          );
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.details());
        },
      }
    ),
    () => ({ title: "Failed to delete platform" })
  );
};

export const useSyncPlatform = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useDecoratedReactQuery(
    useMutation(
      async (platformId: string) => {
        const { data: response } = await apiClient.patch<ApiResponse<Platform>>(
          `/platforms/${platformId}/sync`,
          {}
        );
        return response.data;
      },
      {
        onSuccess: (platform) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: PLATFORMS_QUERY_KEYS.detail(platform.id),
              lists: [
                PLATFORMS_QUERY_KEYS.list({
                  workspaceId: platform.workspaceId,
                }),
              ],
            },
            platform.id,
            () => platform
          );
          queryClient.invalidateQueries(WORKSPACES_QUERY_KEYS.lists());
          queryClient.invalidateQueries(
            WORKSPACES_QUERY_KEYS.detail(platform.workspaceId)
          );
        },
      }
    ),
    () => ({ title: "Failed to sync platform" })
  );
};

export const useTwitterRequestToken = () => {
  const apiClient = useApiClient();

  return useDecoratedReactQuery(
    useMutation(async (callbackUrl: string) => {
      const { data: response } = await apiClient.post<
        ApiResponse<{
          token: string;
        }>
      >(`/platforms/twitter/request_token`, { callbackUrl });
      return response.data;
    }),
    () => ({ title: "Failed to get twitter request tokens" })
  );
};

export const useAuthPlatform = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { mutateAsync: requestToken } = useTwitterRequestToken();

  return useDecoratedReactQuery(
    useMutation(
      async (platform: Platform) => {
        let data: any = {};

        if (platform.type === "LINKEDIN") {
          const code = await getLinkedInAuthorisationCode();
          data.code = code;
          data.redirectUri = LINKEDIN_REDIRECT_URI;
        } else if (platform.type === "FACEBOOK") {
          const code = await getFacebookAuthorisationCode("reauthorize");
          data.code = code;
          data.redirectUri = FACEBOOK_REDIRECT_URI;
        } else if (platform.type === "TWITTER") {
          const { token } = await requestToken(TWITTER_REDIRECT_URI);

          if (!token) {
            throw new Error("Unable to get tokens for twitter");
          }

          const verifier = await getTwitterVerifier(token);

          data.token = token;
          data.verifier = verifier;
        }

        const { data: response } = await apiClient.patch<ApiResponse<Platform>>(
          `/platforms/${platform.id}/auth`,
          data
        );
        return response.data;
      },
      {
        onSuccess: (platform) => {
          updateQueryCacheObject(
            queryClient,
            {
              detail: PLATFORMS_QUERY_KEYS.detail(platform.id),
              lists: [
                PLATFORMS_QUERY_KEYS.list({
                  workspaceId: platform.workspaceId,
                }),
              ],
            },
            platform.id,
            () => platform
          );
        },
        onError: (e: any, platform) => {
          if (e.code === ErrorCodes.AUTH.PLATFORM_MISMATCH) {
            // This is gross. We shouldn't be mutating the error object here but I can't work out
            // another way to get the platform passed through to the build error function below.
            e.platform = platform;
          }
        },
      }
    ),
    (e: any) => {
      if (e.code === ErrorCodes.AUTH.PLATFORM_MISMATCH) {
        const platform = e.platform as Platform;
        const platformTypeName = getPlatformTypeName(platform.type);

        return {
          title: "Account mismatch",
          message: `It looks like you're not currently logged into your ${platform.name} account on ${platformTypeName}. Please open ${platformTypeName} in a new tab and log out before trying again.`,
        };
      } else {
        return { title: "Failed to authorise" };
      }
    }
  );
};
