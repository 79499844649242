import React from "react";
import { CalendarIcon } from "@heroicons/react/outline";

import { PostQueue, PostQueueStateScheduled } from "../../../types/postQueues";
import { PlatformEntity } from "../../../types/platforms";

import { usePost } from "../../../libs/hooks/posts";
import { useDateTimeFactory } from "../../../libs/hooks/app";

import ContentSwitchContainer from "../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../components/animations/contentSwitch/Content";
import LoaderIcon from "../../../components/icons/Loader";
import InternalLink from "../../../components/links/Internal";
import PostPreview from "../../../components/posts/Preview";

interface ExplainSmartSchedulesStateScheduledProps {
  platformEntity: PlatformEntity;
  postQueue: PostQueue & {
    state: PostQueueStateScheduled;
  };
  close?: () => void;
  className?: string;
}

const ExplainSmartSchedulesStateScheduledModal: React.FC<
  ExplainSmartSchedulesStateScheduledProps
> = ({ platformEntity, postQueue, close, className = "" }) => {
  const { data: post, isLoading, errorAlert } = usePost(postQueue.state.postId);
  const dateFactory = useDateTimeFactory();
  const scheduledDate =
    post && post.scheduled ? dateFactory(post.scheduled) : null;
  const scheduledDateString = scheduledDate
    ? scheduledDate.toFormat("h:mm a EEE, d MMM")
    : "";
  const scheduledDateUTCISODateString = scheduledDate
    ? scheduledDate.setZone("utc").toISODate()
    : "";

  return (
    <div className={className}>
      <ContentSwitchContainer
        contentId={isLoading ? "loading" : post ? "content" : "error"}
      >
        <ContentSwitchContent contentId="loading">
          <div className="h-48 flex items-center justify-center">
            <LoaderIcon className="h-10 w-10" />
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="content">
          <div className="mt-4">
            <p style={{ width: 0, minWidth: "100%" }}>
              Seenly has projected that the next post from this smart schedule
              should be published at <strong>{scheduledDateString}</strong>.
            </p>
            <p className="mt-2" style={{ width: 0, minWidth: "100%" }}>
              In the mean time, your social will continue to be monitored and
              the projection may change in the event of new data.
            </p>
            <p className="mt-2" style={{ width: 0, minWidth: "100%" }}>
              Once the time is <strong>within 3 hours</strong> of the{" "}
              <strong>projected time</strong> the post will automatically be
              scheduled for publishing and will no longer appear in the smart
              schedule queue and instead will be visible on the calendar.
            </p>

            <div className="mt-8">
              <div className="flex items-center">
                <h4 className="font-bold text-sm text-gray-400">
                  PREVIEW OF POST TO BE PUBLISHED ON{" "}
                  {scheduledDateString.toUpperCase()}
                </h4>

                <InternalLink
                  className="ml-auto flex items-center"
                  to={`/calendar?day=${scheduledDateUTCISODateString}`}
                  onClick={() => close && close()}
                >
                  <CalendarIcon className="mr-1 h-4 w-4" /> Calendar
                </InternalLink>
              </div>
              <PostPreview
                className="mt-2"
                platformEntity={platformEntity}
                post={post!}
              />
            </div>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="error">
          {errorAlert()}
        </ContentSwitchContent>
      </ContentSwitchContainer>
    </div>
  );
};

export default ExplainSmartSchedulesStateScheduledModal;
