import React from "react";
import { useLinkedInEntity } from "../../../../../../../libs/hooks/linkedin";
import { PlatformEntity } from "../../../../../../../types/platforms";

import SelectedBase from "./Base";

interface PostTextAreaMentionSelectedLinkedInProps {
  platformId: string;
  entityType: PlatformEntity["type"];
  entityId: string;
  onDelete: () => void;
  isVisible?: boolean;
}
const PostTextAreaMentionSelectedLinkedIn: React.FC<
  PostTextAreaMentionSelectedLinkedInProps
> = ({ platformId, entityType, entityId, onDelete, isVisible = true }) => {
  const { isLoading, data } = useLinkedInEntity({
    platformId,
    entityType,
    entityId,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SelectedBase
      isLoading={isLoading}
      name={data ? data.name : null}
      vanityName={data ? data.vanityName : undefined}
      pictureUrl={data ? data.pictureUrl : undefined}
      onDelete={onDelete}
    />
  );
};

export default PostTextAreaMentionSelectedLinkedIn;
