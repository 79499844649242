import React, { ComponentProps } from "react";
import { mergeClassNames } from "../../libs/components";

import Badge from "./Badge";

interface NeutralBadgeProps extends ComponentProps<typeof Badge> {}

const NeutralBadge: React.FC<NeutralBadgeProps> = ({
  className = "",
  ...props
}) => {
  return (
    <Badge
      className={mergeClassNames("bg-gray-200 text-gray-700", className)}
      {...props}
    />
  );
};

export default NeutralBadge;
