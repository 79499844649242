import React from "react";
import { Plus } from "react-feather";

import { useShowModal } from "../../../libs/hooks/app";

import H2 from "../../../components/headings/H2";
import PrimaryButton from "../../../components/buttons/Primary";

import { ReactComponent as NoPlatformsImage } from "../../../images/no-platforms.svg";

interface CreatePostModalNoPlatformsProps {
  contentId: "noplatforms";
  close: () => void;
}

const CreatePostModalNoPlatforms: React.FC<CreatePostModalNoPlatformsProps> = ({
  close,
}) => {
  const showModal = useShowModal();

  return (
    <div className="p-8" style={{ width: 500 }}>
      <H2 className="text-2xl text-black w-full">No socials?</H2>
      <p className="mt-2">
        Get started by adding a social platform before scheduling any posts.
      </p>
      <NoPlatformsImage className="mt-4 w-full text-purple-500" height={200} />
      <PrimaryButton
        className="mt-4 w-full"
        onClick={() => {
          showModal("addPlatform", {});
          close();
        }}
      >
        <Plus className="mr-2" />
        Add new social
      </PrimaryButton>
    </div>
  );
};

export default CreatePostModalNoPlatforms;
