function buildKeysFactory<T extends string>(rootKey: T) {
  const keys = {
    all: [rootKey] as const,
    lists: () => [...keys.all, "list"] as const,
    list: (filters: Record<string, any>) => [...keys.lists(), filters] as const,
    infiniteLists: () => [...keys.all, "infiniteList"] as const,
    infiniteList: (filters: Record<string, any>) =>
      [...keys.infiniteLists(), filters] as const,
    details: () => [...keys.all, "detail"] as const,
    detail: (id: string) => [...keys.details(), id] as const,
  };

  return keys;
}

export type QueryKeysFactory = ReturnType<typeof buildKeysFactory>;

export const FILES_QUERY_KEYS = buildKeysFactory("files");
export const INVOICES_QUERY_KEYS = buildKeysFactory("invoices");
export const PAYMENT_METHODS_QUERY_KEYS = buildKeysFactory("paymentMethods");
export const PLATFORMS_QUERY_KEYS = buildKeysFactory("platforms");
export const POSTS_QUERY_KEYS = buildKeysFactory("posts");
export const SUBSCRIPTIONS_QUERY_KEYS = buildKeysFactory("subscriptions");
export const UNINITIALISED_APP_QUERY_KEYS =
  buildKeysFactory("uninitialisedApp");
export const USER_PREFERENCES_QUERY_KEYS = buildKeysFactory("userPreferences");
export const USERS_QUERY_KEYS = buildKeysFactory("users");
export const WORKSPACES_QUERY_KEYS = buildKeysFactory("workspaces");
export const TWITTER_QUERY_KEYS = buildKeysFactory("twitter");
export const FACEBOOK_QUERY_KEYS = buildKeysFactory("facebook");
export const LINKEDIN_QUERY_KEYS = buildKeysFactory("linkedin");
export const RITE_KIT_QUERY_KEYS = buildKeysFactory("riteKit");
export const POST_QUEUES_QUERY_KEYS = buildKeysFactory("postQueues");
export const POST_METRICS_QUERY_KEYS = buildKeysFactory("postMetrics");
export const PUBLISHED_POST_ACTIONS_QUERY_KEYS = buildKeysFactory(
  "publishedPostActions"
);
export const POST_ANALYTICS_QUERY_KEYS = buildKeysFactory("postAnalytics");
export const WORKSPACE_ANALYTICS_QUERY_KEYS =
  buildKeysFactory("workspaceAnalytics");
