import React, { ReactNode, useMemo } from "react";
import {
  ChevronDown as ChevronDownIcon,
  Trash as TrashIcon,
} from "react-feather";

import { Platform, PlatformEntity } from "../../../../types/platforms";
import { FormPublishedPostActionBase } from "../../../../types/publishedPostActions";

import Select from "../../../../components/form/Select";
import H4 from "../../../../components/headings/H4";
import PlatformEntityPicture from "../../../../components/images/profile/PlatformEntity";
import DangerButton from "../../../../components/buttons/Danger";

const DELAY_OPTIONS = [
  {
    text: "Immediately",
    value: "0",
  },
  {
    text: "Within 15 minutes",
    value: "random",
  },
  {
    text: "1 minute",
    value: "1",
  },
  {
    text: "2 minutes",
    value: "2",
  },
  {
    text: "3 minutes",
    value: "3",
  },
  {
    text: "4 minutes",
    value: "4",
  },
  {
    text: "5 minutes",
    value: "5",
  },
  {
    text: "6 minutes",
    value: "6",
  },
  {
    text: "7 minutes",
    value: "7",
  },
  {
    text: "8 minutes",
    value: "8",
  },
  {
    text: "9 minutes",
    value: "9",
  },
  {
    text: "10 minutes",
    value: "10",
  },
  {
    text: "11 minutes",
    value: "11",
  },
  {
    text: "12 minutes",
    value: "12",
  },
  {
    text: "13 minutes",
    value: "13",
  },
  {
    text: "14 minutes",
    value: "14",
  },
  {
    text: "15 minutes",
    value: "15",
  },
];

export interface ActionContainerErrors {
  platformId?: any;
  platformEntityId?: any;
  useRandomDelay?: any;
  delay?: any;
}

interface PublishedPostActionsFormActionContainerProps {
  title: ReactNode;
  platforms: Platform[];
  value: FormPublishedPostActionBase;
  onChange: (newValue: FormPublishedPostActionBase) => void;
  onDelete: () => void;
  children: ReactNode;
  className?: string;
  errors?: ActionContainerErrors;
}
const PublishedPostActionsFormActionContainer: React.FC<
  PublishedPostActionsFormActionContainerProps
> = ({
  title,
  platforms,
  value,
  onChange,
  onDelete,
  errors,
  className = "",
  children,
}) => {
  const platformsAndEntities = useMemo(() => {
    const allEntities = platforms.reduce<
      Array<{ platform: Platform; platformEntity: PlatformEntity }>
    >((carry, platform) => {
      Object.values(platform.entities).forEach((platformEntity) => {
        if (platformEntity.disabled) {
          return;
        }

        carry.push({ platform, platformEntity });
      });

      return carry;
    }, []);

    allEntities.sort((a, b) => {
      return a.platformEntity.name < b.platformEntity.name ? -1 : 1;
    });

    return allEntities.reduce<{
      [platformEntityId: string]: {
        platform: Platform;
        platformEntity: PlatformEntity;
      };
    }>((carry, entity) => {
      carry[entity.platformEntity.id] = entity;
      return carry;
    }, {});
  }, [platforms]);
  const delayValue = value.useRandomDelay
    ? "random"
    : value.delay
    ? "" + value.delay
    : "0";

  const renderPlatformEntityOption = (platformEntityId: string) => {
    const { platformEntity } = platformsAndEntities[platformEntityId];

    return (
      <div className="w-full flex items-center">
        <PlatformEntityPicture
          className="flex-shrink-0 w-9 h-9"
          entity={platformEntity}
        />
        <span className="ml-2 flex-shrink-0 truncate">
          {platformEntity.name}
        </span>
      </div>
    );
  };

  return (
    <div className={className}>
      <div className="flex items-center">
        <H4 className="text-lg default-transition group-focus-within:text-purple-500">
          {title}
        </H4>
        <DangerButton
          className="ml-auto flex items-center"
          size="xs"
          invert={true}
          onClick={onDelete}
        >
          <TrashIcon className="w-4 h-6" />
          <span className="ml-1">Remove</span>
        </DangerButton>
      </div>

      <div className="mt-4 group">
        <H4 className="text-xs text-gray-300 default-transition group-focus-within:text-purple-500">
          WHO
        </H4>
        <Select
          dropdownClassName="mt-2"
          toggleButtonClassName="w-full px-2 py-1"
          labelText="Who should the action actor be"
          options={Object.values(platformsAndEntities).map(
            ({ platformEntity }) => ({
              text: platformEntity.name,
              value: platformEntity.id,
            })
          )}
          value={value.platformEntityId || undefined}
          onChange={(newPlatformEntityId) => {
            const { platform } = platformsAndEntities[newPlatformEntityId];

            onChange({
              ...value,
              platformId: platform.id,
              platformEntityId: newPlatformEntityId,
            });
          }}
          error={!!errors && (!!errors.platformId || !!errors.platformEntityId)}
          hideLabel={true}
          renderOption={({ value }) => renderPlatformEntityOption(value)}
          renderSelectedOption={(option) => {
            return (
              <div className="flex items-center w-full">
                <span className={`truncate mr-2}`}>
                  {option ? renderPlatformEntityOption(option.value) : ""}
                </span>

                <ChevronDownIcon className="flex-shrink-0 ml-auto" />
              </div>
            );
          }}
        />
      </div>

      <div className="mt-4 group">
        <H4 className="text-xs text-gray-300 default-transition group-focus-within:text-purple-500">
          WHEN
        </H4>
        <div className="mt-2 flex items-center">
          <Select
            toggleButtonClassName="px-2 py-1"
            labelText="When should the action happen?"
            options={DELAY_OPTIONS}
            value={delayValue}
            onChange={(newDelayValue) => {
              if (newDelayValue === "random") {
                const newValues = {
                  ...value,
                  useRandomDelay: true,
                };

                delete newValues.delay;

                onChange(newValues);
              } else {
                const newDelay = parseInt(newDelayValue, 10);

                const newValues = {
                  ...value,
                  delay: newDelay,
                };

                delete newValues.useRandomDelay;

                onChange(newValues);
              }
            }}
            error={!!errors && (!!errors.delay || !!errors.useRandomDelay)}
            hideLabel={true}
          />
          <span className="ml-2">after the post is published</span>
        </div>
      </div>

      <div className="mt-4 group">
        <H4 className="text-xs text-gray-300 default-transition group-focus-within:text-purple-500">
          WHAT
        </H4>
        <div className="mt-2">{children}</div>
      </div>
    </div>
  );
};

export default PublishedPostActionsFormActionContainer;
