import React, { ReactNode } from "react";
import { mergeClassNames } from "../../../libs/components";

interface AnalyticsDailySnapshotCardProps {
  className?: string;
  iconContainerClassName?: string;
  subtitleClassName?: string;
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
}
const AnalyticsDailySnapshotCard: React.FC<AnalyticsDailySnapshotCardProps> = ({
  className = "",
  iconContainerClassName = "",
  subtitleClassName = "",
  icon,
  title,
  subtitle,
}) => {
  return (
    <div className={mergeClassNames("flex items-center w-56", className)}>
      <div
        className={mergeClassNames(
          "shrink-0 h-14 w-14 rounded-xl flex items-center justify-center",
          iconContainerClassName
        )}
      >
        <div className="h-6 w-6 text-white">{icon}</div>
      </div>
      <div className="py-1 ml-2 h-14 flex flex-col justify-between truncate">
        <div className="text-xs text-gray-500 uppercase">{title}</div>
        <div className={mergeClassNames("text-3xl", subtitleClassName)}>
          {subtitle}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDailySnapshotCard;
