import React from "react";

import { Subscription } from "../../../types/subscriptions";
import config from "../../../config";
import { useActiveWorkspaceTimeZone } from "../../../libs/hooks/app";
import { mergeClassNames } from "../../../libs/components";

import H2 from "../../../components/headings/H2";

import { ReactComponent as PublishPlanImage } from "../../../images/publish-plan.svg";
import SubscriptionStatusBadge from "../../../components/badges/SubscriptionStatus";

interface SubscriptionPlanSectionProps {
  subscription: Subscription;
  className?: string;
}
const SubscriptionPlanSection: React.FC<SubscriptionPlanSectionProps> = ({
  subscription,
  className = "",
}) => {
  const timeZone = useActiveWorkspaceTimeZone();
  const priceConfig = Object.values(config.prices).find((candidate) => {
    return candidate.id === subscription.price.remoteId;
  });
  const subscriptionName = priceConfig ? priceConfig.displayName : "Unknown";
  const displayPrice = Math.floor(subscription.price.unitAmount / 100);
  const displayCurrency = subscription.price.currency.toUpperCase();
  const displayPerPeriod =
    subscription.price.period === "MONTHLY" ? "month" : "year";

  return (
    <div className={mergeClassNames("flex justify-between", className)}>
      <div className="flex flex-col items-center">
        <H2 className="mt-2">{subscriptionName}</H2>

        <div className="mt-4 text-center">
          <span className="font-bold text-4xl text-purple-500">
            ${displayPrice}
          </span>
          <span className="ml-2 text-xs text-gray-500">{`${displayCurrency} / ${displayPerPeriod}`}</span>
        </div>

        <SubscriptionStatusBadge
          className="mt-4"
          status={subscription.status}
          periodEnd={
            subscription.cancelAtPeriodEnd ? subscription.periodEnd : undefined
          }
          trialEnd={subscription.trialEnd}
          timeZone={timeZone}
        />
      </div>

      <PublishPlanImage className="flex-shrink-0 w-64 h-48 text-purple-500" />
    </div>
  );
};

export default SubscriptionPlanSection;
