export const POST_QUEUE_FREQUENCY_OPTIONS = {
  FASTEST: {
    value: "FASTEST",
    title: "Fastest",
    desc: "~ 2 to 4 times per day",
  },
  FAST: {
    value: "FAST",
    title: "Fast",
    desc: "~ 1 to 2 times per day",
  },
  NORMAL: {
    value: "NORMAL",
    title: "Normal",
    desc: "~ 3 to 4 times per week",
  },
  SLOW: {
    value: "SLOW",
    title: "Slow",
    desc: "~ 1 to 2 times per week",
  },
  SLOWEST: {
    value: "SLOWEST",
    title: "Slowest",
    desc: "~ 1 time per week",
  },
} as const;
