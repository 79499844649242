import React from "react";
import { FullGestureState } from "@use-gesture/react";

export interface ItemPosition {
  item: {
    height: number;
    width: number;
    fixedPosition: {
      top: number;
      left: number;
      bottom: number;
      right: number;
    };
    relativePosition?: {
      top: number;
      left: number;
      bottom: number;
      right: number;
    };
  };
  container?: {
    height: number;
    width: number;
    scrollTop: number;
    fixedPosition: {
      top: number;
      left: number;
      bottom: number;
      right: number;
    };
  };
}

export interface OverPosition {
  item: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
  percent: [number, number];
  container?: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
}

interface GeneralHandlerProps {
  itemId: string;
  type: string;
  itemElement: HTMLElement;
  initial: ItemPosition;
  current: ItemPosition;
  state: Omit<
    FullGestureState<"drag">,
    "event" | "down" | "first" | "last" | "memo"
  >;
  scrollMove: [number, number]; // [x, y].
}

export interface OverDropTargets {
  [dropTargetId: string]: {
    overPosition: OverPosition;
    provided?: any;
  };
}

export interface HandleDragStartProps extends GeneralHandlerProps {
  overDropTargets: OverDropTargets;
}

export interface HandleDragProps extends HandleDragStartProps {
  args?: any;
}

export interface HandleDropStartProps extends GeneralHandlerProps {
  isOver: boolean;
  overPosition: OverPosition;
  dropContainerPosition: {
    height: number;
    width: number;
    fixedPosition: {
      top: number;
      left: number;
      bottom: number;
      right: number;
    };
  };
  overDropTargets: OverDropTargets;
}

export interface HandleDropProps extends HandleDropStartProps {
  args?: any;
}

export interface DragRegisterProps {
  itemId: string;
  type: string;
  itemRef?: React.RefObject<HTMLElement>;
  containerRef?: React.RefObject<HTMLElement>;
  scrollContainerRef?: React.RefObject<HTMLElement>;
  onStart?: (props: HandleDragStartProps) => any;
  onUpdate?: (props: HandleDragProps) => void;
  onEnd?: (props: HandleDragProps) => void;
}

export interface DropRegisterProps {
  dropId: string;
  accept: string[];
  provide?: any;
  onStart?: (props: HandleDropStartProps) => any;
  onUpdate?: (props: HandleDropProps) => void;
  onDrop?: (props: HandleDropProps) => void;
}

export type DragDropValue = {
  drag: (
    props: DragRegisterProps
  ) => (elem: HTMLElement | null) => HTMLElement | null;
  drop: (
    props: DropRegisterProps
  ) => (elem: HTMLElement | null) => HTMLElement | null;
};

const DragDropContext = React.createContext<DragDropValue>({
  drag: () => (elem) => elem,
  drop: () => (elem) => elem,
});
export default DragDropContext;
