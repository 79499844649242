import React, { useState, useContext, useEffect, useRef } from "react";
import Axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import { UserReferralAffiliate } from "../../types/users";

import config from "../../config";
import UnauthenticatedAppContext from "../../contexts/app/Unauthenticated";
import { USERS_QUERY_KEYS } from "../../libs/queryKeys";
import { useCheckForReferrer } from "../../libs/hooks/uninitialisedApp";

import ExpandCollapse from "../../components/animations/ExpandCollapse";
import H1 from "../../components/headings/H1";
import PrimaryButton from "../../components/buttons/Primary";
import LinkButton from "../../components/buttons/Link";
import SignupContainer from "../../components/signup/Container";
import AlertError from "../../components/alerts/Error";
import AlertSuccess from "../../components/alerts/Success";
import LoaderIcon from "../../components/icons/Loader";
import VerificationCodeInput from "../../components/form/input/VerificationCode";

import Splash from "../../images/signup-confirm-splash.svg";

type CreateUserPostData = {
  email: string;
  firstName: string;
  lastName?: string;
  timeZone: string;
  referralId?: string;
  referralAffiliate?: UserReferralAffiliate;
};

type FormValues = {
  confirmationCode: string;
};

const formSchema = Joi.object({
  confirmationCode: Joi.string().required(),
});

const SignupConfirmPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    email: rawEmail,
    password,
    referralId,
    referralAffiliate,
    setReferralId,
    setReferralAffiliate,
  } = useContext(UnauthenticatedAppContext);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [hasResent, setHasResent] = useState(false);
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setFocus,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema),
  });
  const [resendError, setResendError] = useState(false);
  const [verifyError, setVerifyError] = useState<{
    title: string;
    message: React.ReactNode;
  } | null>(null);
  const [askToDisableAdBlock, setAskToDisableAdBlock] = useState(false);
  const isLoading = isVerifying || isResending;
  const email = rawEmail ? rawEmail.toLowerCase() : rawEmail;
  const watchCode = watch("confirmationCode");
  const currentCodeLength = watchCode ? watchCode.length : 0;

  useCheckForReferrer({
    onSuccess: (referrerData) => {
      if (!referralId && referrerData) {
        setReferralId(referrerData.id);

        if (referrerData.affiliate) {
          setReferralAffiliate(referrerData.affiliate);
        }
      }
    },
    onFailedToLoad: (hasReferralParam) => {
      if (hasReferralParam && !referralId) {
        setAskToDisableAdBlock(true);
      }
    },
  });

  const resendConfirmationCode = async () => {
    if (email) {
      setHasResent(true);
      setIsResending(true);
      setResendError(false);
      setVerifyError(null);

      try {
        await Auth.resendSignUp(email);
        setIsResending(false);
      } catch (e) {
        setIsResending(false);
        setResendError(true);
      }
    }
  };

  const onSubmit = async ({ confirmationCode }: FormValues) => {
    if (email && password) {
      setIsVerifying(true);
      setResendError(false);
      setVerifyError(null);

      try {
        await Auth.confirmSignUp(email, confirmationCode);
        await Auth.signIn(email, password);
        const [session, authUser] = await Promise.all([
          Auth.currentSession(),
          Auth.currentAuthenticatedUser(),
        ]);

        const { attributes } = authUser;
        const createUserData: CreateUserPostData = {
          email: attributes.email,
          firstName: attributes.given_name,
          timeZone: attributes.zoneinfo,
        };

        if (attributes.family_name) {
          createUserData.lastName = attributes.family_name;
        }

        if (referralId) {
          createUserData.referralId = referralId;

          if (referralAffiliate) {
            createUserData.referralAffiliate = referralAffiliate;
          }
        }

        await Axios.post(`${config.apiGateway.URL}/users/`, createUserData, {
          headers: {
            Authorization: session.getIdToken().getJwtToken(),
          },
        });
        // Reset the users queries so that they reload now that we've logged in.
        queryClient.resetQueries(USERS_QUERY_KEYS.all);
        navigate("/");
      } catch (e: any) {
        setIsVerifying(false);

        if (e.code === "ExpiredCodeException") {
          // Invalid code. Either a typo or is expired.
          setVerifyError({
            title: "Whoopsie!",
            message: (
              <>
                That code seems to be invalid. Please try again and if that
                still doesn't work then you can{" "}
                <LinkButton onClick={resendConfirmationCode}>
                  resend the code.
                </LinkButton>
              </>
            ),
          });
        } else {
          setVerifyError({
            title: "Something went wrong...",
            message: (
              <>
                Please try entering the code again. If that still doesn't work
                then you can{" "}
                <LinkButton onClick={resendConfirmationCode}>
                  resend the code.
                </LinkButton>
              </>
            ),
          });
        }
      }
    }
  };

  useEffect(() => {
    setFocus("confirmationCode");
  }, [setFocus]);

  useEffect(() => {
    if (currentCodeLength === 6 && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }, [currentCodeLength]);

  return (
    <SignupContainer>
      <div style={{ maxWidth: 600, zIndex: 1 }}>
        <H1 className="my-4">We've shared a little secret</H1>
        <p>
          We've sent a verification code to <strong>{email}</strong>. Please
          check your email for the code and enter it below.
        </p>
        <p className="mt-2 mb-6">
          Didn't receive the code?{" "}
          <LinkButton
            className="inline-flex"
            onClick={resendConfirmationCode}
            id="resend-email-code"
          >
            Resend the code.
          </LinkButton>
        </p>
        <ExpandCollapse>
          {askToDisableAdBlock ? (
            <div className="pt-6">
              <div className="p-4 rounded-lg bg-gradient-to-r from-red-100 to-pink-100">
                <p>Hi there! 👋</p>
                <p className="mt-2">
                  It looks like you've arrived here using a link from one of our
                  friends so we'd like to give you a{" "}
                  <strong>{config.AFFILIATE_DISCOUNT_PERCENT}% discount</strong>{" "}
                  on our paid plans but first we need to determine which friend
                  sent you!
                </p>
                <p className="mt-2">
                  Sometimes ad blockers can prevent us from loading the
                  information we need. Can you please{" "}
                  <strong>
                    temporarily disable your ad blocker and then reload the page
                  </strong>
                  .
                </p>
                <p className="mt-2">
                  If you no longer see this message after you've reloaded the
                  page then please complete the signup process before you turn
                  your ad blocker back on.
                </p>
              </div>
            </div>
          ) : null}
        </ExpandCollapse>
        <ExpandCollapse>
          {hasResent && resendError ? (
            <AlertError
              message="We were unable to resend the code. Please try again in a minute."
              dismissable={true}
              onDismiss={() => setHasResent(false)}
            />
          ) : null}
        </ExpandCollapse>
        <ExpandCollapse>
          {hasResent && !resendError ? (
            <AlertSuccess
              message="We've resent the confirmation code to your email. Please give it a
            few minutes to arrive."
              dismissable={true}
              onDismiss={() => setHasResent(false)}
            />
          ) : null}
        </ExpandCollapse>
        <ExpandCollapse>
          {verifyError ? (
            <AlertError
              title={verifyError.title}
              message={verifyError.message}
              dismissable={true}
              onDismiss={() => setVerifyError(null)}
            />
          ) : null}
        </ExpandCollapse>
        <form className="pt-1" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="confirmationCode"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <VerificationCodeInput
                className="bg-white"
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                labelClassName="mt-4"
                error={!!errors.confirmationCode}
              />
            )}
          />
          <PrimaryButton
            ref={submitButtonRef}
            className="mt-6 w-full text-xl text-white flex items-center"
            type="submit"
            disabled={isResending || isVerifying}
            id="verify-sms"
          >
            {isLoading && <LoaderIcon className="mr-2" />}
            {isResending
              ? "Resending code..."
              : isVerifying
              ? "Verifying..."
              : "Verify"}
          </PrimaryButton>
        </form>
      </div>
      <div
        className="hidden md:block md:h-3/5 md:w-3/5 absolute bottom-0 right-0"
        style={{ width: "60%", height: "60%", zIndex: 0 }}
      >
        <img className="w-full h-full" src={Splash} alt="" />
      </div>
    </SignupContainer>
  );
};

export default SignupConfirmPage;
