import React, { useMemo, ComponentProps } from "react";
import { SubmitHandler } from "react-hook-form";
import { ChevronRight as ChevronRightIcon } from "react-feather";
import { ArrowLeft as ArrowLeftIcon } from "react-feather";
import { DocumentDuplicateIcon, DocumentIcon } from "@heroicons/react/outline";

import { Platform } from "../../../types/platforms";
import { PostFormValues } from "../../../types/posts";
import { useContentStack } from "../../../libs/hooks/general";
import { getPlatformTypeName } from "../../../libs/platform";

import PostForm from "../../forms/Post";

import H2 from "../../../components/headings/H2";
import PrimaryButton from "../../../components/buttons/Primary";
import IconButton from "../../../components/buttons/Icon";

interface CreatePostModalRefineProps {
  contentId: string;
  nextContentId: string;
  isLastRefine: boolean;
  platformType: Platform["type"];
  platforms: Platform[];
  formData: PostFormValues;
  totalPostCount: number;
  timeZone: string;
  updateFormData: (newFormData: PostFormValues) => void;
}

const CreatePostModalRefine: React.FC<CreatePostModalRefineProps> = ({
  nextContentId,
  isLastRefine,
  platformType,
  platforms,
  formData,
  totalPostCount,
  timeZone,
  updateFormData,
}) => {
  const { push, pop } = useContentStack();
  const platformTypeName = getPlatformTypeName(platformType);
  const platformTypeSocials = useMemo(() => {
    return platforms.reduce<ComponentProps<typeof PostForm>["allowedSocials"]>(
      (carry, platform) => {
        if (platform.type === platformType) {
          Object.values(platform.entities).forEach((entity) => {
            if (!entity.disabled && !entity.externalMissing) {
              carry.push({ platform, platformEntityId: entity.id });
            }
          });
        }
        return carry;
      },
      []
    );
  }, [platforms, platformType]);

  const onSubmit: SubmitHandler<PostFormValues> = (data) => {
    updateFormData(data as PostFormValues);
    push(nextContentId);
  };

  return (
    <div className="px-2 pb-2 pt-1 sm:px-8 sm:pb-6">
      <PostForm
        className="mt-2"
        validateImmediately={true}
        defaultValues={formData}
        allowedSocials={platformTypeSocials}
        timeZone={timeZone}
        onSubmit={onSubmit}
        header={
          <>
            <IconButton
              className="absolute -top-1 left-0"
              onClick={() => pop()}
            >
              <ArrowLeftIcon />
            </IconButton>
            <H2 className="ml-2 text-2xl text-black text-center w-full">{`Customise ${platformTypeName} posts`}</H2>
          </>
        }
        footer={() => {
          return (
            <PrimaryButton
              className="mt-8 w-full relative"
              type="submit"
              id={
                isLastRefine
                  ? `create-${totalPostCount}-posts`
                  : `${platformTypeName.toLowerCase()}-post-refined`
              }
            >
              {isLastRefine &&
                (totalPostCount > 1 ? (
                  <DocumentDuplicateIcon className="mr-2 h-6 w-6" />
                ) : (
                  <DocumentIcon className="mr-2 h-6 w-6" />
                ))}
              {isLastRefine
                ? `Create ${totalPostCount} posts`
                : `Apply ${platformTypeName} changes`}

              {!isLastRefine && (
                <div className="pr-4 absolute inset-y-0 right-0 flex items-center">
                  <ChevronRightIcon className="h-6 w6" />
                </div>
              )}
            </PrimaryButton>
          );
        }}
      />
    </div>
  );
};

export default CreatePostModalRefine;
