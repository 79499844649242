import { DraftDecorator } from "draft-js";

import {
  Build,
  BuildDecorators,
  DraftDecoratorComponentProps,
  ENTITY_TYPES,
} from "../../../../../libs/postTextArea";
import Tooltip from "../../../../../components/Tooltip";

const ENTITY_TYPE = ENTITY_TYPES.PLACEHOLDER;

const strategy: DraftDecorator["strategy"] = (
  contentBlock,
  callback,
  contentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    const entity = entityKey ? contentState.getEntity(entityKey) : null;
    return !!entity && entity.getType() === ENTITY_TYPE;
  }, callback);
};

interface BuildSearchComponentProps {
  onClick: (blockKey: string, start: number, end: number) => void;
}
const buildPlaceholderComponent =
  ({
    onClick,
  }: BuildSearchComponentProps): React.FC<DraftDecoratorComponentProps> =>
  ({ children, blockKey, start, end }: DraftDecoratorComponentProps) => {
    return (
      <button
        className="rounded bg-purple-100 text-purple-500 hover:bg-purple-200"
        type="button"
        onClick={() => onClick(blockKey, start, end)}
      >
        <Tooltip content="Click to replace placeholder">{children}</Tooltip>
      </button>
    );
  };

const buildDecorators: BuildDecorators<BuildSearchComponentProps> = (props) => {
  return [
    {
      strategy,
      component: buildPlaceholderComponent(props),
    },
  ];
};

const build: Build<BuildSearchComponentProps> = (props) => {
  return {
    type: ENTITY_TYPE,
    decorators: buildDecorators(props),
  };
};

export default build;
