import React, { useMemo, useState } from "react";
import {
  Save as SaveIcon,
  Copy as CopyIcon,
  Edit as EditIcon,
  ChevronRight as ChevronRightIcon,
  XCircle as XCircleIcon,
  Circle as CircleIcon,
  CheckCircle as CheckCircleIcon,
} from "react-feather";

import {
  Post,
  PostFacebook,
  PostLinkedIn,
  PostTwitter,
} from "../../../types/posts";
import { Platform } from "../../../types/platforms";
import { FormPublishedPostAction } from "../../../types/publishedPostActions";

import { getPlatformTypeName } from "../../../libs/platform";
import { useContentStack } from "../../../libs/hooks/general";

import H2 from "../../../components/headings/H2";
import PlatformIcon from "../../../components/icons/Platform";
import PrimaryButton from "../../../components/buttons/Primary";
import PublishedPostActionIcon from "../../../components/icons/PublishedPostAction";
import PlatformEntityProfilePicture from "../../../components/images/profile/PlatformEntity";
import TertiaryButton from "../../../components/buttons/Tertiary";
import PrimaryTextButton from "../../../components/buttons/PrimaryText";

type ActionFormValues = {
  [postId: string]: FormPublishedPostAction[];
};

interface MultiPostPublishedPostActionsPostsPickerProps {
  contentId: "postsPicker";
  posts: Post[];
  platformsById: { [platformId: string]: Platform };
  actionFormValues: ActionFormValues;
  setActionFormValues: (
    val: ActionFormValues | ((prevValue: ActionFormValues) => ActionFormValues)
  ) => void;
  setPreventModalClose: (canClose: boolean) => void;
  setEditingPost: (post: Post) => void;
}
const MultiPostPublishedPostActionsPostsPicker: React.FC<
  MultiPostPublishedPostActionsPostsPickerProps
> = ({
  posts,
  platformsById,
  actionFormValues,
  setActionFormValues,
  setPreventModalClose,
  setEditingPost,
}) => {
  const { push } = useContentStack();
  const [duplicate, setDuplicate] = useState<{
    type: Platform["type"];
    from: string;
    to: string[];
  } | null>(null);
  const postsByPlatformType = useMemo(() => {
    return posts.reduce<{
      LINKEDIN: PostLinkedIn[];
      TWITTER: PostTwitter[];
      FACEBOOK: PostFacebook[];
    }>(
      (carry, post) => {
        switch (post.type) {
          case "LINKEDIN":
            carry.LINKEDIN.push(post);
            break;
          case "TWITTER":
            carry.TWITTER.push(post);
            break;
          case "FACEBOOK":
            carry.FACEBOOK.push(post);
            break;
        }
        return carry;
      },
      {
        LINKEDIN: [],
        TWITTER: [],
        FACEBOOK: [],
      }
    );
  }, [posts]);
  const isDuplicating = duplicate !== null;

  const handleDuplication = () => {
    if (!duplicate) {
      return;
    }

    const sourceActions = actionFormValues[duplicate.from] || [];
    const newActionFormValues = {
      ...actionFormValues,
    };

    for (const postId of duplicate.to) {
      const existingPostActionFormValues = newActionFormValues[postId] || [];
      const newPostActionFormValues = existingPostActionFormValues.slice();

      for (const sourceAction of sourceActions) {
        const existing = existingPostActionFormValues.find(
          (candidate) => candidate.id === sourceAction.id
        );

        if (!existing) {
          newPostActionFormValues.push({
            ...sourceAction,
            postId,
          });
        }
      }

      newActionFormValues[postId] = newPostActionFormValues;
    }

    setActionFormValues(newActionFormValues);
    setDuplicate(null);
  };

  return (
    <div className="p-2 sm:px-8 sm:pb-8 sm:pt-6">
      <H2 className="shrink-0 w-full flex items-center text-2xl text-black">
        <PublishedPostActionIcon className="h-6 w-6" />
        <span className="ml-2">Manage after publish actions</span>
      </H2>
      {Object.entries(postsByPlatformType).map(
        ([platformType, posts], index1) => {
          if (posts.length < 1) {
            return null;
          }

          const isDuplicatingThisType =
            isDuplicating && platformType === duplicate.type;

          return (
            <div
              key={platformType}
              className={`${index1 === 0 ? "mt-8" : "mt-12"} ${
                isDuplicating && !isDuplicatingThisType
                  ? "opacity-20 pointer-events-none"
                  : ""
              }`}
            >
              <H2 className="shrink-0 w-full flex items-center text-lg text-gray-400">
                <PlatformIcon type={platformType as Platform["type"]} />
                <span className="ml-2">
                  {`${getPlatformTypeName(
                    platformType as Platform["type"]
                  )} posts`}
                </span>
              </H2>
              {posts.map((post) => {
                const platform = platformsById[post.platformId];
                const platformEntity = platform
                  ? platform.entities[post.platformEntityId]
                  : undefined;

                if (!platformEntity) {
                  return null;
                }

                const formValues = actionFormValues[post.id] || [];
                const actionCount = formValues.length;
                const isDuplicatingSource =
                  isDuplicatingThisType && duplicate.from === post.id;
                const isDuplicateRecipient =
                  isDuplicatingThisType && duplicate.to.includes(post.id);

                return (
                  <div key={post.id} className="mt-4 flex items-center">
                    <div className="w-64 flex items-center">
                      <PlatformEntityProfilePicture
                        entity={platformEntity}
                        platformType={post.type}
                      />
                      <div className="ml-2 truncate">
                        <div className="truncate font-bold">
                          {platformEntity.name}
                        </div>
                        {!!platformEntity.username && (
                          <div className="truncate text-gray-500 text-xs">{`@${platformEntity.username}`}</div>
                        )}
                      </div>
                    </div>
                    <div className="ml-2 w-24">{`${actionCount} actions`}</div>
                    {!isDuplicatingThisType && (
                      <div className="ml-2 w-80 flex items-center">
                        <TertiaryButton
                          className="flex items-center"
                          size="sm"
                          disabled={isDuplicating || actionCount < 1}
                          onClick={() =>
                            setDuplicate({
                              type: post.type,
                              from: post.id,
                              to: [],
                            })
                          }
                        >
                          <CopyIcon className="h-4 w-4" />
                          <span className="ml-1">Duplicate actions</span>
                        </TertiaryButton>
                        <TertiaryButton
                          className="ml-2 flex items-center"
                          size="sm"
                          disabled={isDuplicating}
                          onClick={() => {
                            setEditingPost(post);
                            push("editActions");
                          }}
                        >
                          <EditIcon className="h-4 w-4" />
                          <span className="ml-1">Edit actions</span>
                          <ChevronRightIcon className="ml-4 h-4 w-4" />
                        </TertiaryButton>
                      </div>
                    )}
                    {isDuplicatingThisType && isDuplicatingSource && (
                      <div className="ml-2 w-80 flex items-center justify-center">
                        <span className="font-bold text-sm">
                          Duplicate these actions
                        </span>
                      </div>
                    )}
                    {isDuplicatingThisType && !isDuplicatingSource && (
                      <div className="ml-2 w-80 flex items-center justify-center">
                        <PrimaryTextButton
                          className={
                            isDuplicateRecipient ? "" : "text-gray-500 text-sm"
                          }
                          size="sm"
                          onClick={() => {
                            if (isDuplicateRecipient) {
                              setDuplicate((prevValues) => {
                                if (!prevValues) {
                                  return prevValues;
                                }

                                return {
                                  ...prevValues,
                                  to: prevValues.to.filter(
                                    (candidate) => candidate !== post.id
                                  ),
                                };
                              });
                            } else {
                              setDuplicate((prevValues) => {
                                if (!prevValues) {
                                  return prevValues;
                                }

                                return {
                                  ...prevValues,
                                  to: prevValues.to.concat(post.id),
                                };
                              });
                            }
                          }}
                        >
                          {isDuplicateRecipient ? (
                            <CheckCircleIcon className="h-4 w-4" />
                          ) : (
                            <CircleIcon className="h-4 w-4" />
                          )}
                          <span className="ml-1">
                            Add duplicated actions to this post
                          </span>
                        </PrimaryTextButton>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        }
      )}
      {isDuplicating ? (
        <div className="mt-12 w-full flex items-center">
          <TertiaryButton
            className="w-full flex items-center"
            onClick={() => setDuplicate(null)}
          >
            <XCircleIcon className="h-4 w-4" />
            <span className="ml-1">Cancel duplication</span>
          </TertiaryButton>
          <PrimaryButton
            className="ml-2 w-full flex items-center"
            onClick={handleDuplication}
          >
            <CopyIcon className="h-6 w-6" />
            <span className="ml-2">{`Duplicate actions to ${
              duplicate.to.length === 1
                ? "1 post"
                : `${duplicate.to.length} posts`
            }`}</span>
          </PrimaryButton>
        </div>
      ) : (
        <PrimaryButton
          className="mt-12 w-full flex items-center"
          onClick={() => {
            setPreventModalClose(true);
            push("submit");
          }}
        >
          <SaveIcon className="h-6 w-6" />
          <span className="ml-2">Save all actions</span>
        </PrimaryButton>
      )}
    </div>
  );
};

export default MultiPostPublishedPostActionsPostsPicker;
