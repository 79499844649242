import React from "react";
import {
  getLinkedInAuthorisationCode,
  LINKEDIN_REDIRECT_URI,
} from "../../../libs/platforms/linkedin";
import { useActiveWorkspace } from "../../../libs/hooks/app";
import { useCreatePlatform } from "../../../libs/hooks/platforms";

import {
  BaseComponentProps,
  ConnectLinkedIn,
} from "../../../types/modals/AddPlatform";

import ConnectBase from "./ConnectBase";

interface ConnectLinkedInProps extends BaseComponentProps {
  contentId: ConnectLinkedIn["id"];
}

const AddPlatformModalConnectLinkedIn: React.FC<ConnectLinkedInProps> = ({
  contentId,
  ...rest
}) => {
  const workspace = useActiveWorkspace();
  const { mutateAsync: createPlatform } = useCreatePlatform();

  const handleCreatePlatform = async () => {
    const newAuthorisationCode = await getLinkedInAuthorisationCode();

    if (!newAuthorisationCode) {
      throw new Error("Unable to receive user authorisation from LinkedIn");
    }

    return createPlatform({
      workspaceId: workspace.id,
      type: "LINKEDIN",
      code: newAuthorisationCode,
      redirectUri: LINKEDIN_REDIRECT_URI,
    });
  };

  return (
    <ConnectBase
      contentId={contentId}
      tryAgainContent={{ id: contentId }}
      platformType="LINKEDIN"
      createPlatform={handleCreatePlatform}
      {...rest}
    />
  );
};

export default AddPlatformModalConnectLinkedIn;
