import React from "react";

export interface ContentStackContextProps {
  push: (contentId: string) => void;
  pop: () => void;
  clear: () => void;
}

const ContentStackContext = React.createContext<ContentStackContextProps>({
  push: () => {},
  pop: () => {},
  clear: () => {},
});

export default ContentStackContext;
