import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { useActiveWorkspace } from "../libs/hooks/app";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import OfferTrialSection from "../containers/subscriptions/sections/OfferTrial";
import OfferSubscriptionSection from "../containers/subscriptions/sections/OfferSubscription";

const SubscriptionsPage: React.FC = () => {
  const navigate = useNavigate();
  const workspace = useActiveWorkspace();
  const hasSubscription = !!workspace.subscription;
  const hasNeverHadSubscription = !workspace.hasHadTrial && !hasSubscription;
  const hasPreviouslyHadSubscription =
    !!workspace.hasHadTrial && !hasSubscription;

  useEffect(() => {
    if (hasSubscription) {
      navigate(`/subscriptions/${workspace.subscription!.id}`, {
        replace: true,
      });
    }
  }, [hasSubscription, navigate, workspace.subscription]);

  return (
    <AppPageWithHeader>
      {hasNeverHadSubscription && <OfferTrialSection className="mt-10" />}
      {hasPreviouslyHadSubscription && (
        <OfferSubscriptionSection className="mt-10" />
      )}
    </AppPageWithHeader>
  );
};

export default SubscriptionsPage;
