import React, { ReactNode } from "react";
import { useIsFetching } from "@tanstack/react-query";
import {
  CalendarIcon,
  PhotographIcon,
  StarIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/outline";

import { useActiveWorkspace } from "../libs/hooks/app";
import { useShowPlatformRequiresAuthAlert } from "../libs/hooks/alerts";

import BrandLogoLink from "../components/links/BrandLogo";
import NavigationLink from "../components/links/Navigation";
import AlertPing from "../components/animations/AlertCircle";

import UserMenu from "./menus/User";
import WorkspaceMenu from "./menus/Workspace";
import ToggleNavigationDrawerButton from "./buttons/ToggleNavigationDrawer";
import LoaderIcon from "../components/icons/Loader";
import SmartScheduleIcon from "../components/icons/SmartSchedule";
import SocialsIcon from "../components/icons/Socials";

interface NavLinkConfig {
  to: string;
  tooltip: ReactNode;
  icon: (active: boolean) => ReactNode;
}

const AppHeader: React.FC = () => {
  const workspace = useActiveWorkspace();
  const showPlatformRequiresAuthAlert = useShowPlatformRequiresAuthAlert();
  const fetchingCount = useIsFetching();
  const isFetching = fetchingCount > 0;

  const navLinks: NavLinkConfig[] = [];

  navLinks.push({
    to: "/smart_schedules",
    tooltip: "Smart schedules",
    icon: (active) => (
      <SmartScheduleIcon
        className="w-7 h-7"
        fillColour="bg-purple-500"
        fill={active}
      />
    ),
  });

  navLinks.push({
    to: "/calendar",
    tooltip: "Calendar",
    icon: (active) => (
      <CalendarIcon
        className="w-7 h-7"
        fill={active ? "currentColor" : "none"}
        fillOpacity={0.4}
      />
    ),
  });

  navLinks.push({
    to: "/analytics",
    tooltip: "Analytics",
    icon: (active) => (
      <PresentationChartLineIcon
        className="w-7 h-7"
        fill={active ? "currentColor" : "none"}
        fillOpacity={0.4}
      />
    ),
  });

  navLinks.push({
    to: "/socials",
    tooltip: "Socials",
    icon: (active) => (
      <div className="relative">
        <SocialsIcon className="w-7 h-7" fill={active} />
        {showPlatformRequiresAuthAlert && (
          <div className="absolute -top-1 -right-1">
            <AlertPing className="rounded-full bg-red-400">
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </AlertPing>
          </div>
        )}
      </div>
    ),
  });
  navLinks.push({
    to: "/gallery",
    tooltip: "Gallery",
    icon: (active) => (
      <div className="relative">
        {active && (
          <div className="w-5 h-5 rounded absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-purple-500 opacity-40"></div>
        )}
        <PhotographIcon className="w-7 h-7" />
      </div>
    ),
  });
  navLinks.push({
    to: workspace.subscription
      ? `/subscriptions/${workspace.subscription.id}`
      : "/subscriptions",
    tooltip: "Subscription",
    icon: (active) => (
      <StarIcon
        className="w-7 h-7"
        fill={active ? "currentColor" : "none"}
        fillOpacity={0.4}
      />
    ),
  });

  return (
    <header className="flex-shrink-0 relative w-full h-14 bg-white shadow">
      <div className="px-4 absolute inset-y-0 left-0 flex items-center">
        <ToggleNavigationDrawerButton className="mr-4 flex-shrink-0 lg:hidden" />
        <BrandLogoLink width={36} hideName={true} />
        <WorkspaceMenu className="ml-2 hidden lg:flex" />
      </div>
      <nav className="hidden lg:flex w-full h-full items-center justify-center ">
        {navLinks.map(({ to, tooltip, icon: iconFn }, index) => {
          return (
            <NavigationLink
              key={index}
              className={`px-0 py-0 h-full group hover:from-emerald-0 hover:to-sky-0 focus:from-emerald-0 focus:to-sky-0 ${
                index > 0 ? "ml-2" : ""
              }`}
              to={to}
            >
              {({ active }) => (
                <div className="py-1 h-full relative">
                  <div
                    className={`relative h-full w-28 flex flex-col items-center justify-center rounded-lg default-transition ${
                      active
                        ? "text-purple-500"
                        : "text-gray-500 hover:bg-gray-100 group-focus:bg-gray-100"
                    }`}
                  >
                    <div className="relative">
                      {
                        <div
                          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 default-transition ${
                            isFetching && active ? "opacity-100" : "opacity-0"
                          }`}
                        >
                          <LoaderIcon
                            className="w-10 h-10 opacity-50"
                            fill="currentColor"
                          />
                        </div>
                      }
                      {iconFn(active)}
                    </div>
                    <div
                      className="text-xs uppercase"
                      style={{ fontSize: "0.625rem" }}
                    >
                      {tooltip}
                    </div>
                  </div>
                  {active ? (
                    <div className="absolute left-0 bottom-0 w-full h-1 rounded-t-lg bg-purple-500"></div>
                  ) : null}
                </div>
              )}
            </NavigationLink>
          );
        })}
      </nav>
      <div className="px-4 absolute inset-y-0 right-0 flex items-center">
        <UserMenu className="flex-shrink-0" />
      </div>
    </header>
  );
};

export default AppHeader;
