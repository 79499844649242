import { ComponentProps, forwardRef } from "react";
import { mergeClassNames } from "../../libs/components";
import Button from "./Button";
import SecondaryButton from "./Secondary";

interface PageActionButtonProps extends ComponentProps<typeof Button> {}
const PageActionButton = forwardRef<HTMLButtonElement, PageActionButtonProps>(
  ({ className = "", ...props }, ref) => {
    return (
      <SecondaryButton
        ref={ref}
        className={mergeClassNames("py-2 bg-gray-200 text-gray-500", className)}
        {...props}
      />
    );
  }
);

export default PageActionButton;
