import { DateTime } from "luxon";
import React, { useState, useMemo, useRef, createRef, useEffect } from "react";
import { FixedSizeList } from "react-window";
import {
  ChevronDown as ChevronDownIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  X as XIcon,
} from "react-feather";
import { usePrevious } from "../../libs/hooks/general";
import { mergeClassNames } from "../../libs/components";
import DropdownMenu, { DropdownMenuUncontrolledProps } from "./Dropdown";
import Button from "../buttons/Button";
import SecondaryButton from "../buttons/Secondary";
import IconButton from "../buttons/Icon";

interface DatePickerProps {
  value: number | null;
  onChange: (newDate: number | null) => void;
  timeZone: string;
  format?: string;
  className?: string;
  buttonClassName?: string;
  buttonContent?: React.ReactNode;
  hideDropdownIndicator?: boolean;
  xAlign?: "left" | "right";
  closeOnSelect?: boolean;
  onMenuToggle?: DropdownMenuUncontrolledProps["onMenuToggle"];
  highlightWeek?: boolean;
  ButtonComponent?: typeof Button;
  disablePast?: boolean;
  disabled?: boolean;
  allowedDateList?: string[];
}

const DAYS_OF_WEEK = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
const DAYS_PER_ROW = 7;
const DAY_FORMAT = "d";
const MIN_YEAR = 1900;
const MAX_YEAR = 2100;

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  timeZone,
  format = "EEE, d MMM",
  className = "",
  ButtonComponent = SecondaryButton,
  buttonClassName = "",
  buttonContent = null,
  hideDropdownIndicator = buttonContent !== null,
  xAlign = "right",
  closeOnSelect = false,
  highlightWeek = false,
  disablePast = false,
  disabled = false,
  allowedDateList,
  onMenuToggle,
}) => {
  const nowDateTime = DateTime.local().setZone(timeZone);
  const nowISO = nowDateTime.toISO();
  const valueDateTime = value
    ? DateTime.fromSeconds(value, { zone: timeZone })
    : null;
  const today = nowDateTime.endOf("day").toSeconds();
  const prevValue = usePrevious(value);
  const [pickingYear, setPickingYear] = useState(false);
  const [date, setDate] = useState(
    valueDateTime ? valueDateTime.endOf("day").toSeconds() : null
  );
  const [month, setMonth] = useState(
    valueDateTime
      ? valueDateTime.startOf("month").toSeconds()
      : nowDateTime.startOf("month").toSeconds()
  );
  const [didSave, setDidSave] = useState(false);
  const toggleButtonRef = useRef<HTMLButtonElement | null>(null);
  const listRef = createRef<FixedSizeList>();
  const canSave = date !== value;
  const dateBase = useMemo(() => {
    return date ? DateTime.fromSeconds(date, { zone: timeZone }) : null;
  }, [date, timeZone]);
  const monthBase = useMemo(() => {
    return DateTime.fromSeconds(month, { zone: timeZone });
  }, [month, timeZone]);
  const formattedDate = useMemo(() => {
    return valueDateTime ? valueDateTime.toFormat(format) : "";
  }, [format, valueDateTime]);
  const daysOfMonth = useMemo(() => {
    const days: Array<[string | null, number | null, boolean]> = [];
    const startOfMonth = monthBase.startOf("month");
    const dayOfWeek = startOfMonth.weekday - 1;

    if (dayOfWeek) {
      for (let i = 1; i <= dayOfWeek; i++) {
        days.unshift([null, null, true]);
      }
    }

    for (let i = 0; i < startOfMonth.daysInMonth; i++) {
      const day = startOfMonth.plus({ days: i }).endOf("day");

      let disabled = false;

      if (disablePast && day.toISO() < nowISO) {
        disabled = true;
      }

      if (!disabled && allowedDateList) {
        const dayDate = day.toISODate();
        disabled = !allowedDateList.includes(dayDate);
      }

      days.push([day.toFormat(DAY_FORMAT), day.toSeconds(), disabled]);
    }

    const partialWeek = days.length % DAYS_PER_ROW;
    if (partialWeek) {
      const daysRemaining = DAYS_PER_ROW - partialWeek;

      for (let i = 1; i <= daysRemaining; i++) {
        days.push([null, null, true]);
      }
    }

    return days;
  }, [allowedDateList, disablePast, monthBase, nowISO]);

  useEffect(() => {
    if (pickingYear && listRef && listRef.current) {
      const currentYear = monthBase.year;
      const itemNumber = currentYear - MIN_YEAR;
      listRef.current.scrollToItem(
        itemNumber < 0
          ? 0
          : itemNumber > MAX_YEAR - MIN_YEAR
          ? MAX_YEAR - MIN_YEAR - 1
          : itemNumber,
        "center"
      );
    }
  }, [listRef, monthBase, pickingYear]);

  useEffect(() => {
    if (value !== prevValue) {
      setDate(valueDateTime ? valueDateTime.endOf("day").toSeconds() : null);
      setMonth(
        valueDateTime
          ? valueDateTime.startOf("month").toSeconds()
          : DateTime.local().setZone(timeZone).toSeconds()
      );
    }
  }, [prevValue, timeZone, value, valueDateTime]);

  let selectorElement: JSX.Element | null = null;

  if (pickingYear) {
    const currentYear = monthBase.year;
    const currentYearIndex = currentYear - MIN_YEAR;

    selectorElement = (
      <FixedSizeList
        ref={listRef}
        height={300}
        width={"100%"}
        itemCount={MAX_YEAR - MIN_YEAR}
        itemSize={36}
      >
        {({ index, style }) => {
          const year = 1900 + index;
          return (
            <div className="px-2" style={style}>
              <button
                className={`rounded w-full h-full text-center default-transition hover:bg-gray-200 ${
                  index === currentYearIndex ? "text-2xl text-purple-700" : ""
                }`}
                onClick={() => {
                  setMonth(monthBase.set({ year }).endOf("month").toSeconds());
                  setPickingYear(false);
                }}
              >
                {year}
              </button>
            </div>
          );
        }}
      </FixedSizeList>
    );
  } else {
    const rows: JSX.Element[] = [];

    for (let i = 0; i < 6; i++) {
      const rowDays = daysOfMonth.slice(
        i * DAYS_PER_ROW,
        (i + 1) * DAYS_PER_ROW
      );

      let rowClasses = "";
      if (highlightWeek) {
        const containsSelected = !!rowDays.find(
          ([dayString, dayValue]) => dayValue === date
        );

        if (containsSelected) {
          rowClasses =
            "rounded-full text-white bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-purple-600";
        } else {
          rowClasses = "rounded-full hover:bg-gray-200";
        }
      }

      rows.push(
        <div
          key={i}
          className={`flex items-center mx-auto ${rowClasses}`}
          style={{ height: "36px" }}
        >
          {rowDays.map((dayData, index) => {
            const [dayString, dayValue, disabled] = dayData;

            if (dayString !== null && dayValue !== null) {
              let dayClasses = "";
              const isToday = dayValue === today;

              if (highlightWeek) {
                if (isToday) {
                  dayClasses = "text-sky-400 font-bold underline";
                }
              } else {
                const isSelected = dayValue === date;

                if (isSelected) {
                  dayClasses = `rounded-full text-white bg-purple-500 hover:bg-purple-600`;
                } else if (isToday) {
                  dayClasses = `rounded-full font-bold underline ${
                    disabled
                      ? "text-purple-200 cursor-not-allowed"
                      : "text-purple-700 hover:bg-gray-200"
                  }`;
                } else {
                  dayClasses = `rounded-full ${
                    disabled
                      ? "text-gray-200 cursor-not-allowed"
                      : "hover:bg-gray-200"
                  }`;
                }
              }
              return (
                <button
                  key={dayValue}
                  className={`flex items-center justify-center default-transition ${dayClasses}`}
                  style={{ width: "36px", height: "36px" }}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }

                    setDate(dayValue);

                    if (closeOnSelect) {
                      setDidSave(true);
                      onChange(dayValue);

                      if (toggleButtonRef && toggleButtonRef.current) {
                        toggleButtonRef.current.click();
                      }
                    }
                  }}
                  disabled={disabled}
                >
                  {dayString}
                </button>
              );
            } else {
              return (
                <div
                  key={`null-${index}`}
                  style={{ width: "36px", height: "36px" }}
                ></div>
              );
            }
          })}
        </div>
      );
    }

    selectorElement = (
      <>
        <div className="flex items-center p-1">
          <button
            className="flex-shrink-0 rounded-full p-3 default-transition hover:bg-gray-200"
            onClick={() => {
              const prevMonth = monthBase.minus({ months: 1 }).startOf("month");
              setMonth(prevMonth.toSeconds());
            }}
            title="Previous month"
          >
            <ChevronLeftIcon width={20} height={20} />
          </button>
          <div className="flex-grow text-center">
            {monthBase.toFormat("MMMM yyyy")}
          </div>
          <button
            className="flex-shrink-0 rounded-full p-3 default-transition hover:bg-gray-200"
            onClick={() => {
              const nextMonth = monthBase.plus({ months: 1 }).startOf("month");
              setMonth(nextMonth.toSeconds());
            }}
            title="Next month"
          >
            <ChevronRightIcon width={20} height={20} />
          </button>
        </div>
        <div className="flex items-center justify-center">
          {DAYS_OF_WEEK.map((dayOfWeek) => {
            return (
              <div
                key={dayOfWeek}
                className="flex items-center justify-center text-gray-500"
                style={{ width: "36px", height: "36px" }}
              >
                {dayOfWeek}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col">{rows.map((row) => row)}</div>
      </>
    );
  }

  if (buttonContent === null) {
    buttonContent = (
      <>
        {formattedDate ? (
          formattedDate
        ) : (
          <span className="opacity-0 invisible">undefined</span>
        )}
        {!hideDropdownIndicator && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <ChevronDownIcon />
          </div>
        )}
      </>
    );
  }

  return (
    <DropdownMenu
      className={mergeClassNames("inline-block", className)}
      menuClassName="rounded-lg bg-white shadow-xl"
      xAlign={xAlign}
      closeOnSelect={false}
      onMenuToggle={(newIsOpen) => {
        if (!closeOnSelect) {
          if (!newIsOpen && !didSave) {
            setDate(
              valueDateTime ? valueDateTime.endOf("day").toSeconds() : null
            );
            setMonth(
              valueDateTime
                ? valueDateTime.startOf("month").toSeconds()
                : nowDateTime.startOf("month").toSeconds()
            );
          }

          setDidSave(false);
        }

        if (onMenuToggle) {
          onMenuToggle(newIsOpen);
        }
      }}
    >
      <ButtonComponent
        ref={toggleButtonRef}
        className={mergeClassNames(
          `relative text-left truncate ${hideDropdownIndicator ? "" : "pr-10"}`,
          buttonClassName
        )}
        disabled={disabled}
      >
        {buttonContent}
      </ButtonComponent>
      {({ close }) => (
        <div
          className="relative flex flex-col font-normal"
          style={{ width: "300px" }}
        >
          <div className=" rounded-t-lg bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-4">
            <div>
              <button
                className="rounded px-2 py-1 default-transition hover:bg-purple-800"
                onClick={() => setPickingYear(true)}
              >
                <h4 className={pickingYear ? "text-white" : "text-purple-300"}>
                  {dateBase
                    ? dateBase.toFormat("yyyy")
                    : monthBase.toFormat("yyyy")}
                </h4>
              </button>
            </div>
            <div>
              <button
                className="rounded px-2 py-1 default-transition hover:bg-purple-800"
                onClick={() => setPickingYear(false)}
              >
                <h3
                  className={`${
                    pickingYear ? "text-purple-300" : "text-white"
                  } ${highlightWeek ? "text-2xl" : "text-3xl"}`}
                >
                  {dateBase
                    ? highlightWeek
                      ? `${dateBase
                          .startOf("week")
                          .toFormat("d MMM")} - ${dateBase
                          .endOf("week")
                          .toFormat("d MMM")}`
                      : dateBase.toFormat("EEE, d MMM")
                    : "Select a date"}
                </h3>
              </button>
            </div>
          </div>
          <IconButton
            className="absolute top-2 right-2 bg-gradient-none bg-neutral-900 bg-opacity-20 text-white"
            title="Close"
            onClick={() => close()}
          >
            <XIcon />
          </IconButton>
          <div className="mb-2" style={{ height: "302px" }}>
            {selectorElement}
          </div>
          {!closeOnSelect && (
            <div className="flex items-center justify-end px-2 pb-2">
              <button
                className={`mr-auto rounded px-2 py-1 text-lg default-transition ${
                  date !== null
                    ? "text-purple-700 hover:bg-gray-200"
                    : "text-gray-300 cursor-not-allowed"
                }`}
                disabled={date === null}
                onClick={() => {
                  setDate(null);
                }}
              >
                Clear
              </button>
              <button
                className="rounded px-2 py-1 mr-1 text-lg text-gray-700 default-transition hover:text-black hover:bg-gray-200"
                onClick={() => {
                  if (toggleButtonRef && toggleButtonRef.current) {
                    toggleButtonRef.current.click();
                  }
                }}
              >
                Cancel
              </button>
              <button
                className={`rounded px-2 py-1 text-lg default-transition ${
                  canSave
                    ? "text-purple-700 hover:bg-gray-200"
                    : "text-gray-300 cursor-not-allowed"
                }`}
                disabled={!canSave}
                onClick={() => {
                  if (canSave) {
                    setDidSave(true);
                    onChange(date);

                    if (toggleButtonRef && toggleButtonRef.current) {
                      toggleButtonRef.current.click();
                    }
                  }
                }}
              >
                Save
              </button>
            </div>
          )}
        </div>
      )}
    </DropdownMenu>
  );
};

export default DatePicker;
