import { DateTime } from "luxon";
import React from "react";

import { Subscription } from "../../../types/subscriptions";

import {
  useActiveWorkspaceTimeZone,
  useShowModal,
} from "../../../libs/hooks/app";

import DangerButton from "../../../components/buttons/Danger";

interface SubscriptionDangerZoneSectionProps {
  subscription: Subscription;
  className?: string;
}
const SubscriptionDangerZoneSection: React.FC<
  SubscriptionDangerZoneSectionProps
> = ({ subscription, className = "" }) => {
  const showModal = useShowModal();
  const timeZone = useActiveWorkspaceTimeZone();
  const isTrialing = subscription.status === "trialing";
  const isTrialingWithoutPaymentMethod =
    isTrialing && !subscription.paymentMethod;
  const displayPeriodEnd = DateTime.fromISO(subscription.periodEnd)
    .setZone(timeZone)
    .toFormat("MMM d");
  const canCancel =
    !subscription.cancelAtPeriodEnd && subscription.status !== "canceled";

  return (
    <div className={className}>
      <DangerButton
        className="w-full"
        onClick={() => showModal("deleteSubscription", { subscription })}
        disabled={!canCancel}
      >
        Cancel subscription
      </DangerButton>
      {!!subscription.cancelAtPeriodEnd && !isTrialingWithoutPaymentMethod && (
        <p className="mt-4 text-center">
          You subscription has been cancelled and will end on{" "}
          <strong>{displayPeriodEnd}</strong>.
        </p>
      )}
      {!!subscription.cancelAtPeriodEnd && isTrialingWithoutPaymentMethod && (
        <p className="mt-4 text-center">
          Your trial will automatically end on{" "}
          <strong>{displayPeriodEnd}</strong>.
        </p>
      )}
    </div>
  );
};

export default SubscriptionDangerZoneSection;
