import React, { ReactElement, ReactNode, RefObject } from "react";
import SnackBar from "../components/SnackBar";

export interface SnackBoxItem {
  id: string;
  ref: RefObject<HTMLDivElement>;
  snackBarElement: ReactElement<typeof SnackBar>;
}

export interface CreateProps {
  content: ReactNode;
  dismissTrigger?: number | Promise<any>;
  userDismissable?: boolean;
}

export interface ContentStackContextProps {
  items: SnackBoxItem[];
  create: (props: CreateProps) => void;
}

const SnackBoxContext = React.createContext<ContentStackContextProps>({
  items: [],
  create: () => {},
});

export default SnackBoxContext;
