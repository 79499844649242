import { ContentState, convertFromRaw } from "draft-js";
import equal from "fast-deep-equal/es6";
import {
  PublishedPostAction,
  FormPublishedPostAction,
  FormPublishedPostActionBase,
  FormPublishedPostActionLinkedInComment,
  FormPublishedPostActionLinkedInReaction,
  FormPublishedPostActionLinkedInReshare,
  FormPublishedPostActionFacebookComment,
  FormPublishedPostActionTwitterLike,
  FormPublishedPostActionTwitterReply,
  FormPublishedPostActionTwitterRetweet,
  CreatePublishedPostActionProps,
  UpdatePropsPublishedPostAction,
  CreatePublishedPostActionPropsBase,
  CreatePublishedPostActionPropsLinkedInComment,
  CreatePublishedPostActionPropsLinkedInReaction,
  CreatePublishedPostActionPropsLinkedInReshare,
  CreatePublishedPostActionPropsFacebookComment,
  CreatePublishedPostActionPropsTwitterLike,
  CreatePublishedPostActionPropsTwitterReply,
  CreatePublishedPostActionPropsTwitterRetweet,
  UpdatePropsPublishedPostActionBase,
  UpdatePropsPublishedPostActionLinkedInReaction,
  UpdatePropsPublishedPostActionLinkedInComment,
  UpdatePropsPublishedPostActionLinkedInReshare,
  UpdatePropsPublishedPostActionTwitterReply,
  UpdatePropsPublishedPostActionFacebookComment,
} from "../types/publishedPostActions";
import { contentStateToPostText } from "./post";

export function getDisplayNameForLinkedInReactionType(
  reactionType: FormPublishedPostActionLinkedInReaction["reactionType"]
): string {
  switch (reactionType) {
    case "APPRECIATION":
      return "Support";
    case "EMPATHY":
      return "Love";
    case "INTEREST":
      return "Insightful";
    case "LIKE":
      return "Like";
    case "ENTERTAINMENT":
      return "Funny";
    case "PRAISE":
      return "Celebrate";
  }
}

export function getDisplayNameForPublishedPostActionInstance(
  platformType: PublishedPostAction["platformType"],
  type: PublishedPostAction["type"]
) {
  if (platformType === "FACEBOOK") {
    if (type === "COMMENT") {
      return "Comment";
    }
  } else if (platformType === "LINKEDIN") {
    switch (type) {
      case "COMMENT":
        return "Comment";
      case "REACTION":
        return "Reaction";
      case "RESHARE":
        return "Reshare";
    }
  } else if (platformType === "TWITTER") {
    switch (type) {
      case "LIKE":
        return "Like";
      case "REPLY":
        return "Reply";
      case "RETWEET":
        return "Retweet";
    }
  }

  return "";
}

export function buildFormDataForPublishedPostActions(
  actions: PublishedPostAction[]
): FormPublishedPostAction[] {
  return actions.map((action) => {
    const base: FormPublishedPostActionBase = {
      id: action.id,
      postId: action.postId,
      platformId: action.platformId,
      platformEntityId: action.platformEntityId,
    };

    if (action.useRandomDelay) {
      base.useRandomDelay = action.useRandomDelay;
    }

    if (action.delay !== undefined) {
      base.delay = action.delay;
    }

    if (action.platformType === "LINKEDIN") {
      if (action.type === "COMMENT") {
        const formData: FormPublishedPostActionLinkedInComment = {
          ...base,
          platformType: "LINKEDIN",
          type: "COMMENT",
          contentState: convertFromRaw(action.text.contentState),
        };

        return formData;
      } else if (action.type === "REACTION") {
        const formData: FormPublishedPostActionLinkedInReaction = {
          ...base,
          platformType: "LINKEDIN",
          type: "REACTION",
          reactionType: action.reactionType,
        };

        return formData;
      } else {
        const formData: FormPublishedPostActionLinkedInReshare = {
          ...base,
          platformType: "LINKEDIN",
          type: "RESHARE",
          contentState: action.text
            ? convertFromRaw(action.text.contentState)
            : ContentState.createFromText(""),
        };

        return formData;
      }
    } else if (action.platformType === "FACEBOOK") {
      const formData: FormPublishedPostActionFacebookComment = {
        ...base,
        platformType: "FACEBOOK",
        type: "COMMENT",
        contentState: convertFromRaw(action.text.contentState),
      };

      return formData;
    } else {
      if (action.type === "LIKE") {
        const formData: FormPublishedPostActionTwitterLike = {
          ...base,
          platformType: "TWITTER",
          type: "LIKE",
        };

        return formData;
      } else if (action.type === "REPLY") {
        const formData: FormPublishedPostActionTwitterReply = {
          ...base,
          platformType: "TWITTER",
          type: "REPLY",
          contentState: convertFromRaw(action.text.contentState),
        };

        return formData;
      } else {
        const formData: FormPublishedPostActionTwitterRetweet = {
          ...base,
          platformType: "TWITTER",
          type: "RETWEET",
        };

        return formData;
      }
    }
  });
}

export function buildCreateActionPropsForFormAction(
  formAction: FormPublishedPostAction
): CreatePublishedPostActionProps {
  const base: CreatePublishedPostActionPropsBase = {
    postId: formAction.postId,
    platformId: formAction.platformId,
    platformEntityId: formAction.platformEntityId,
  };

  if (formAction.useRandomDelay) {
    base.useRandomDelay = true;
  } else if (formAction.delay) {
    base.delay = formAction.delay;
  }

  if (formAction.platformType === "LINKEDIN") {
    if (formAction.type === "COMMENT") {
      const createProps: CreatePublishedPostActionPropsLinkedInComment = {
        ...base,
        platformType: "LINKEDIN",
        type: "COMMENT",
        text: contentStateToPostText(formAction.contentState),
      };

      return createProps;
    } else if (formAction.type === "REACTION") {
      const createProps: CreatePublishedPostActionPropsLinkedInReaction = {
        ...base,
        platformType: "LINKEDIN",
        type: "REACTION",
        reactionType: formAction.reactionType,
      };

      return createProps;
    } else {
      const createProps: CreatePublishedPostActionPropsLinkedInReshare = {
        ...base,
        platformType: "LINKEDIN",
        type: "RESHARE",
      };

      if (formAction.contentState) {
        createProps.text = contentStateToPostText(formAction.contentState);
      }

      return createProps;
    }
  } else if (formAction.platformType === "FACEBOOK") {
    const createProps: CreatePublishedPostActionPropsFacebookComment = {
      ...base,
      platformType: "FACEBOOK",
      type: "COMMENT",
      text: contentStateToPostText(formAction.contentState),
    };

    return createProps;
  } else {
    if (formAction.type === "LIKE") {
      const createProps: CreatePublishedPostActionPropsTwitterLike = {
        ...base,
        platformType: "TWITTER",
        type: "LIKE",
      };

      return createProps;
    } else if (formAction.type === "REPLY") {
      const formData: CreatePublishedPostActionPropsTwitterReply = {
        ...base,
        platformType: "TWITTER",
        type: "REPLY",
        text: contentStateToPostText(formAction.contentState),
      };

      return formData;
    } else {
      const formData: CreatePublishedPostActionPropsTwitterRetweet = {
        ...base,
        platformType: "TWITTER",
        type: "RETWEET",
      };

      return formData;
    }
  }
}

export function buildUpdateActionPropsForFormAction(
  action: PublishedPostAction,
  formAction: FormPublishedPostAction
): UpdatePropsPublishedPostAction | null {
  if (
    action.platformType !== formAction.platformType ||
    action.type !== formAction.type
  ) {
    return null;
  }

  const updateProps: UpdatePropsPublishedPostActionBase = {};

  const oldUseRandomDelay = action.useRandomDelay || null;
  const newUseRandomDelay = formAction.useRandomDelay || null;

  if (newUseRandomDelay !== oldUseRandomDelay) {
    updateProps.useRandomDelay = newUseRandomDelay;
  }

  const oldDelay = action.delay === undefined ? null : action.delay;
  const newDelay = formAction.delay === undefined ? null : formAction.delay;

  if (newDelay !== oldDelay) {
    updateProps.delay = newDelay;
  }

  if (action.platformType === "LINKEDIN") {
    if (action.type === "COMMENT") {
      const formText = contentStateToPostText(
        (formAction as FormPublishedPostActionLinkedInComment).contentState
      );

      if (!equal(action.text.contentState, formText.contentState)) {
        (updateProps as UpdatePropsPublishedPostActionLinkedInComment).text =
          formText;
      }
    } else if (action.type === "REACTION") {
      if (
        action.reactionType !==
        (formAction as FormPublishedPostActionLinkedInReaction).reactionType
      ) {
        (
          updateProps as UpdatePropsPublishedPostActionLinkedInReaction
        ).reactionType = (
          formAction as FormPublishedPostActionLinkedInReaction
        ).reactionType;
      }
    } else {
      const convertedText = contentStateToPostText(
        (formAction as FormPublishedPostActionLinkedInReshare).contentState
      );
      const actionText = action.text || null;
      const formText = convertedText.plain === "" ? null : convertedText;

      if (!equal(actionText, formText)) {
        (updateProps as UpdatePropsPublishedPostActionLinkedInReshare).text =
          formText;
      }
    }
  } else if (action.platformType === "TWITTER") {
    if (action.type === "REPLY") {
      const formText = contentStateToPostText(
        (formAction as FormPublishedPostActionTwitterReply).contentState
      );

      if (!equal(action.text.contentState, formText.contentState)) {
        (updateProps as UpdatePropsPublishedPostActionTwitterReply).text =
          formText;
      }
    }
  } else {
    const formText = contentStateToPostText(
      (formAction as FormPublishedPostActionFacebookComment).contentState
    );

    if (!equal(action.text.contentState, formText.contentState)) {
      (updateProps as UpdatePropsPublishedPostActionFacebookComment).text =
        formText;
    }
  }

  if (Object.keys(updateProps).length > 0) {
    return updateProps;
  } else {
    return null;
  }
}

export type PublishedPostActionChangePatch = {
  create: CreatePublishedPostActionProps[];
  update: Array<{
    action: PublishedPostAction;
    updateProps: UpdatePropsPublishedPostAction;
  }>;
  delete: string[];
};
export function buildChangePatchForActionFormData(
  actions: PublishedPostAction[],
  formActions: FormPublishedPostAction[]
): PublishedPostActionChangePatch {
  const patch: PublishedPostActionChangePatch = {
    create: [],
    update: [],
    delete: [],
  };
  const deletedActions: PublishedPostAction[] = [];
  const nonDeletedActions: PublishedPostAction[] = [];

  // Find any action that is no longer in the list of form actions.
  for (const action of actions) {
    if (formActions.some((candidate) => candidate.id === action.id)) {
      nonDeletedActions.push(action);
    } else {
      deletedActions.push(action);
    }
  }

  patch.delete = deletedActions.map((deletedAction) => deletedAction.id);

  const createFormActions: FormPublishedPostAction[] = [];
  const nonCreateFormActions: FormPublishedPostAction[] = [];

  // Get any form actions that aren't in the list of non-deleted actions.
  for (const formAction of formActions) {
    if (nonDeletedActions.some((candidate) => candidate.id === formAction.id)) {
      nonCreateFormActions.push(formAction);
    } else {
      createFormActions.push(formAction);
    }
  }

  patch.create = createFormActions.map(buildCreateActionPropsForFormAction);

  for (const formAction of nonCreateFormActions) {
    const action = nonDeletedActions.find(
      (candidate) => candidate.id === formAction.id
    );

    if (!action) {
      continue;
    }

    const updateProps = buildUpdateActionPropsForFormAction(action, formAction);

    if (updateProps) {
      patch.update.push({ action, updateProps });
    }
  }

  return patch;
}
