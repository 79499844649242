import React from "react";
import { mergeClassNames } from "../../../libs/components";

interface PostFormRowProps {
  icon: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  contentContainerClassName?: string;
}

const PostFormRow: React.FC<PostFormRowProps> = ({
  icon,
  content,
  className = "",
  contentContainerClassName = "",
}) => {
  return (
    <div
      className={mergeClassNames(
        "flex text-gray-300 focus-within:text-purple-500",
        className
      )}
    >
      <div className="flex-shrink-0">{icon}</div>
      <div
        className={mergeClassNames(
          "flex-grow text-black",
          contentContainerClassName
        )}
      >
        {content}
      </div>
    </div>
  );
};

export default PostFormRow;
