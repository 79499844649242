import React, { useCallback, useRef } from "react";
import { animated, useSpring } from "@react-spring/web";
import {
  Camera as CameraIcon,
  UploadCloud as UploadCloudIcon,
} from "react-feather";

import { mergeClassNames } from "../../../libs/components";
import { useCurrentUser } from "../../../libs/hooks/app";
import { useUploadFiles } from "../../../libs/hooks/uploads";
import { useUpdateUser } from "../../../libs/hooks/users";

import TertiaryButton from "../../../components/buttons/Tertiary";
import ImageFileInput from "../../../components/form/input/ImageFile";
import LoaderIcon from "../../../components/icons/Loader";
import UserProfilePicture from "../../../components/images/profile/User";

interface ProfilePictureSectionProps {
  className?: string;
}

const ProfilePictureSection: React.FC<ProfilePictureSectionProps> = ({
  className = "",
}) => {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const user = useCurrentUser();
  const [dragSpring, dragSpringApi] = useSpring(() => ({
    opacity: 0,
    processing: 0,
    immediate: (k) => k === "processing",
  }));
  const {
    mutateAsync: updateUser,
    errorAlert: updateUserError,
    isLoading: isUpdatingUser,
  } = useUpdateUser();
  const {
    error: uploadError,
    uploadFiles: uploadImageFiles,
    loading: isUploadingImage,
  } = useUploadFiles({
    owner: {
      type: "USER",
      userId: user.id,
    },
    accept: ["IMAGE"],
    listen: !isUpdatingUser,
    onDragEnter: () => dragSpringApi.start({ opacity: 1 }),
    onDragLeave: () => dragSpringApi.start({ opacity: 0 }),
    onDropStart: (acceptedFiles) => {
      dragSpringApi.start({ processing: 1 });
      // Only allow a single file to be uploaded.
      return acceptedFiles.slice(0, 1);
    },
    onDropEnd: async (fileUploads) => {
      if (fileUploads.length) {
        try {
          const fileUpload = fileUploads[0];
          const fileId = fileUpload.internalFile.id;
          await updateUser({
            user,
            updateProps: { profileImageFileId: fileId },
          });
        } catch (e) {}
      }

      dragSpringApi.start({ opacity: 0, processing: 0 });
    },
  });
  const isLoading = isUpdatingUser || isUploadingImage;
  const hasProfileImage = !!user.profileImage;

  const setProfilePicture = useCallback(
    async (file: File) => {
      try {
        const { fileUploads } = await uploadImageFiles([file]);
        const fileUpload = fileUploads[0];
        const fileId = fileUpload.internalFile.id;
        await updateUser({ user, updateProps: { profileImageFileId: fileId } });
      } catch (e: any) {}
    },
    [updateUser, uploadImageFiles, user]
  );

  const removeProfilePicture = async () => {
    try {
      await updateUser({ user, updateProps: { profileImageFileId: null } });
    } catch (e: any) {}
  };

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputFiles = e.target.files;

    if (inputFiles && inputFiles.length) {
      setProfilePicture(inputFiles[0]);
    }
  };

  return (
    <div className="w-full">
      <ImageFileInput
        ref={imageInputRef}
        multiple={false}
        onChange={handleFileInputChange}
      />
      <div className="w-full flex flex-col items-center justify-center">
        {uploadError({ className: "mt-2" })}
        {updateUserError({ className: "mt-2" })}
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="relative flex-shrink-0 w-52 h-52">
          <UserProfilePicture
            className="h-52 w-52"
            user={user}
            preview={false}
          />

          <TertiaryButton
            className={`absolute top-0 left-0 w-full h-full rounded-full ${
              hasProfileImage
                ? "opacity-0 hover:opacity-75 focus:opacity-75"
                : ""
            }`}
            type="button"
            onClick={() => {
              if (imageInputRef.current) {
                imageInputRef.current.click();
              }
            }}
            disabled={isLoading}
          >
            <CameraIcon
              className={`w-12 h-12 ${
                hasProfileImage ? "text-purple-500" : "text-gray-400"
              }`}
            />
          </TertiaryButton>
          <animated.div
            className="absolute top-0 left-0 w-full h-full rounded-full items-center justify-center text-purple-500 border-2 border-purple-500 border-dashed bg-purple-200"
            style={{
              display: dragSpring.opacity.to((o) => (o > 0 ? "flex" : "none")),
              opacity: dragSpring.opacity,
            }}
          >
            <animated.div
              className="flex flex-col items-center justify-center"
              style={{
                display: dragSpring.processing.to((p) =>
                  p > 0 ? "none" : "flex"
                ),
              }}
            >
              <UploadCloudIcon className="h-11 w-11" />
              <p className="w-40 text-center text-xl">Upload profile picture</p>
            </animated.div>
            <animated.div
              className="flex flex-col items-center justify-center"
              style={{
                display: dragSpring.processing.to((p) =>
                  p > 0 ? "flex" : "none"
                ),
              }}
            >
              <LoaderIcon className="h-11 w-11" />
              <p className="w-40 text-center text-xl">Processing...</p>
            </animated.div>
          </animated.div>
        </div>
        <div className="ml-4 flex flex-col items-center justify-center">
          <TertiaryButton
            className={mergeClassNames("w-52", className)}
            disabled={isLoading}
            onClick={() => {
              if (imageInputRef.current) {
                imageInputRef.current.click();
              }
            }}
          >
            {hasProfileImage ? "Change picture" : "Add picture"}
          </TertiaryButton>
          <TertiaryButton
            className={mergeClassNames("mt-2 w-52", className)}
            disabled={!hasProfileImage || isLoading}
            onClick={() => {
              removeProfilePicture();
            }}
          >
            Remove picture
          </TertiaryButton>
        </div>
      </div>
    </div>
  );
};

export default ProfilePictureSection;
