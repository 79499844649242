import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLogout } from "../libs/hooks/app";

import H1 from "../components/headings/H1";
import LoaderIcon from "../components/icons/Loader";

import { ReactComponent as BrandIconColour } from "../images/brand/colour/logo.svg";
import { ReactComponent as LogoutSplashImage1 } from "../images/logout-splash-1.svg";
import { ReactComponent as LogoutSplashImage2 } from "../images/logout-splash-2.svg";

const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  const { mutateAsync: logout } = useLogout();

  useEffect(() => {
    const initialise = async () => {
      await logout();
      navigate("/login");
    };

    initialise();
  }, [navigate, logout]);

  return (
    <div className="h-screen w-screen absolute flex items-center justify-center">
      <div className="flex items-center">
        <LoaderIcon className="w-10 h-10" />
        <H1 className="ml-3">Goodbye, for now.</H1>
      </div>

      <div className="absolute top-4 left-4 flex items-center">
        <BrandIconColour className="w-8 h-8" />
        <span className="ml-2 font-bold text-3xl">Seenly</span>
      </div>
      <LogoutSplashImage1
        className="h-64 w-52 absolute top-4 right-4 text-purple-500"
        style={{ zIndex: -1 }}
      />
      <LogoutSplashImage2
        className="h-64 w-52 absolute bottom-4 left-4 text-purple-500"
        style={{ zIndex: -1 }}
      />
    </div>
  );
};

export default LogoutPage;
