import { ComponentProps, forwardRef } from "react";
import { mergeClassNames } from "../../libs/components";
import Button from "./Button";

interface SecondaryButtonProps extends ComponentProps<typeof Button> {
  fill?: boolean;
}
const SecondaryButton = forwardRef<HTMLButtonElement, SecondaryButtonProps>(
  ({ className = "", fill = false, ...props }, ref) => {
    const baseClassName = fill
      ? "bg-gradient-to-r from-emerald-200 to-sky-200 text-black default-transition hover:ring-4 hover:ring-sky-300 focus:ring-4 focus:ring-sky-300"
      : "bg-gradient-to-r hover:text-black hover:from-emerald-200 hover:to-sky-200 focus:text-black focus:from-emerald-200 focus:to-sky-200";
    return (
      <Button
        ref={ref}
        className={mergeClassNames(baseClassName, className)}
        {...props}
      />
    );
  }
);

export default SecondaryButton;
