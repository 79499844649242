import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";
import Joi from "joi";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import { Trash as TrashIcon } from "react-feather";
import { CreditCardIcon } from "@heroicons/react/outline";

import { PaymentMethod } from "../../../types/paymentMethods";
import { Workspace } from "../../../types/workspaces";

import {
  useActiveWorkspaceTimeZone,
  useShowModal,
} from "../../../libs/hooks/app";
import { useCreatePaymentMethod } from "../../../libs/hooks/paymentMethods";
import { useAsyncState, useSnackBarFactory } from "../../../libs/hooks/general";

import DangerButton from "../../../components/buttons/Danger";
import PrimaryButton from "../../../components/buttons/Primary";
import TertiaryButton from "../../../components/buttons/Tertiary";
import ChangesSavedSnackBarContent from "../../../components/snackBarContent/ChangesSaved";
import CreditCardInput, {
  OnChangeProps,
} from "../../../components/form/input/CreditCard";

interface FormValues {
  paymentMethod: OnChangeProps;
}

const formSchema = Joi.object({
  paymentMethod: Joi.object({
    empty: Joi.boolean().required(),
    complete: Joi.boolean().required(),
  })
    .required()
    .unknown(false),
});

interface BillingPaymentMethodsSectionProps {
  paymentMethods: PaymentMethod[];
  workspaces: Workspace[];
  className?: string;
}

const BillingPaymentMethodsSection: React.FC<
  BillingPaymentMethodsSectionProps
> = ({ paymentMethods, workspaces, className = "" }) => {
  const [showAddNewPayment, setShowAddNewPayment] = useState(false);
  const timeZone = useActiveWorkspaceTimeZone();
  const showModal = useShowModal();
  const elements = useElements();
  const {
    mutateAsync: createPaymentMethod,
    errorAlert: createPaymentMethodErrorAlert,
    clearErrorAlert: clearCreatePaymentMethodErrorAlert,
  } = useCreatePaymentMethod();
  const createSnackBar = useSnackBarFactory();
  const {
    loading: isSubmitting,
    setLoading: setIsSubmitting,
    loadingText: submitLoadingText,
  } = useAsyncState();
  const {
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema, { convert: false }),
    defaultValues: {
      paymentMethod: {
        empty: true,
        complete: false,
      },
    },
  });
  const watchPaymentMethod = watch("paymentMethod");
  const formHasErrors = Object.keys(errors).length > 0;
  const workspacesBySubscriptionId = useMemo(() => {
    return workspaces.reduce<{ [subscriptionId: string]: Workspace }>(
      (carry, workspace) => {
        if (workspace.subscription) {
          carry[workspace.subscription.id] = workspace;
        }
        return carry;
      },
      {}
    );
  }, [workspaces]);

  const onSubmit: SubmitHandler<FormValues> = async ({
    paymentMethod: paymentMethodData,
  }) => {
    if (isSubmitting) {
      return;
    }

    if (formHasErrors) {
      return;
    }

    if (!paymentMethodData.complete || paymentMethodData.empty) {
      return;
    }

    setIsSubmitting(true);
    clearCreatePaymentMethodErrorAlert();

    // The user has entered a new card so create the payment method.
    const cardElement = elements ? elements.getElement(CardElement) : null;

    if (!cardElement) {
      return;
    }

    try {
      await createPaymentMethod({ cardElement });
      cardElement.clear();

      createSnackBar({
        content: <ChangesSavedSnackBarContent message="Payment method added" />,
      });
    } catch (e) {}

    setIsSubmitting(false);
  };

  return (
    <div className={className}>
      <div className="w-full grid grid-cols-2 gap-4">
        {paymentMethods.map((paymentMethod) => {
          const displayCreated = DateTime.fromISO(paymentMethod.created)
            .setZone(timeZone)
            .toFormat("DD");
          const inUseWorkspaces = paymentMethod.subscriptionIds
            ? paymentMethod.subscriptionIds
                .map((subscriptionId) => {
                  return workspacesBySubscriptionId[subscriptionId];
                })
                .filter((workspace) => workspace !== undefined)
            : [];
          const isInUse = inUseWorkspaces.length > 0;
          const usedByText = isInUse
            ? inUseWorkspaces.length > 1
              ? `Used by ${inUseWorkspaces.length} workspaces`
              : "Used by 1 workspace"
            : "Not in use";

          return (
            <div
              key={paymentMethod.id}
              className="mx-auto p-2 flex-shrink-0 rounded-lg bg-gray-100"
              style={{ width: "23rem" }}
            >
              <div className="p-4 rounded-lg bg-white">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <CreditCardIcon className="flex-shrink-0 w-6 h-6" />
                    <span className="ml-2">{`${paymentMethod.brand[0].toUpperCase()}${paymentMethod.brand.slice(
                      1
                    )}`}</span>
                  </div>
                  <DangerButton
                    className="ml-auto"
                    invert={true}
                    size="sm"
                    onClick={() => {
                      setShowAddNewPayment(false);
                      showModal("deletePaymentMethod", {
                        paymentMethod,
                        workspaces,
                      });
                    }}
                  >
                    <TrashIcon className="w-4 h-4 mr-1" />
                    Delete
                  </DangerButton>
                </div>
                <div className="mt-8 flex items-center justify-between">
                  <div>
                    <div className="font-bold text-xs text-gray-400">
                      NUMBER
                    </div>
                    <div>{`•••• ${paymentMethod.last4}`}</div>
                  </div>
                  <div>
                    <div className="font-bold text-xs text-gray-400">
                      CREATED
                    </div>
                    <div>{displayCreated}</div>
                  </div>
                  <div>
                    <div className="font-bold text-xs text-gray-400">
                      EXPIRY
                    </div>
                    <div>{`${paymentMethod.expMonth}/${paymentMethod.expYear}`}</div>
                  </div>
                </div>
              </div>
              <p className="mt-2 px-2">
                <span className="font-bold">{usedByText}</span>
                {isInUse
                  ? ` • ${inUseWorkspaces
                      .map((workspace) => workspace.name)
                      .join(" • ")}`
                  : ""}
              </p>
            </div>
          );
        })}
      </div>
      {showAddNewPayment && (
        <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="paymentMethod"
            render={({ field: { onChange, onBlur } }) => (
              <CreditCardInput
                labelText="New payment method"
                onChange={onChange}
                onBlur={onBlur}
                disabled={isSubmitting}
                onError={(message) => {
                  if (message) {
                    setError("paymentMethod", { message });
                  } else {
                    clearErrors("paymentMethod");
                  }
                }}
              />
            )}
          />

          {createPaymentMethodErrorAlert({ className: "mt-2" })}

          <PrimaryButton
            type="submit"
            className="mt-2 w-full"
            disabled={
              isSubmitting || formHasErrors || !watchPaymentMethod.complete
            }
          >
            {submitLoadingText({
              loading: "Saving new payment method...",
              default: "Save new payment method",
            })}
          </PrimaryButton>
          <TertiaryButton
            className="mt-2 w-full"
            onClick={() => setShowAddNewPayment(false)}
            disabled={isSubmitting}
          >
            Cancel
          </TertiaryButton>
        </form>
      )}
      {!showAddNewPayment && (
        <PrimaryButton
          className="mt-10 w-full"
          onClick={() => setShowAddNewPayment(true)}
        >
          Add new payment method
        </PrimaryButton>
      )}
    </div>
  );
};

export default BillingPaymentMethodsSection;
