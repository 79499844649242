import React from "react";

import PublishedPostActionIcon from "../icons/PublishedPostAction";

interface CreatedPublishedPostActionsSnackBarContentProps {
  count: number;
}
const CreatedPublishedPostActionsSnackBarContent: React.FC<
  CreatedPublishedPostActionsSnackBarContentProps
> = ({ count }) => {
  return (
    <div className="flex items-center">
      <PublishedPostActionIcon className="flex-shrink-0 w-5 h-5" />
      <span className="ml-2">
        {`${count} After publish action${count > 1 ? "s" : ""} created`}
      </span>
    </div>
  );
};

export default CreatedPublishedPostActionsSnackBarContent;
