import React from "react";

import { MetricPropertyNames } from "../../types/postAnalytics";

import CardLoading from "../../components/analytics/dailySnapshot/CardLoading";

interface AnalyticsDailySnapshotLoadingProps {
  metricPropertyNames: MetricPropertyNames;
  className?: string;
}
const AnalyticsDailySnapshotLoading: React.FC<
  AnalyticsDailySnapshotLoadingProps
> = ({ metricPropertyNames, className = "" }) => {
  return (
    <div className={className}>
      <div className="flex items-center justify-end">
        <div className="ml-2 w-20 h-10 rounded-lg animate-pulse bg-slate-200"></div>
        <div className="ml-2 w-10 h-10 rounded-full animate-pulse bg-slate-200"></div>
        <div className="ml-2 w-10 h-10 rounded-full animate-pulse bg-slate-200"></div>
        <div className="ml-2 w-10 h-10 rounded-full animate-pulse bg-slate-200"></div>
      </div>
      <div className="mt-4 grid grid-cols-3 gap-4">
        {metricPropertyNames.map((metricPropertyName) => {
          return <CardLoading key={metricPropertyName} />;
        })}
      </div>
    </div>
  );
};

export default AnalyticsDailySnapshotLoading;
