import React, {
  createRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Trash as TrashIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
} from "react-feather";

import { ImageAttachment } from "../../../../types/posts";
import { Platform } from "../../../../types/platforms";

import { clamp, clampImageHeight } from "../../../../libs/utils";

import DangerButton from "../../../../components/buttons/Danger";
import IconButton from "../../../../components/buttons/Icon";
import TertiaryButton from "../../../../components/buttons/Tertiary";
import PostImageAttachmentImage from "../../../../components/images/PostAttachment";
import Input from "../../../../components/form/Input";

import { ReactComponent as NoImagesImage } from "../../../../images/images.svg";

import {
  imageAttachmentSchema,
  PLATFORM_MAX_IMAGE_COUNT,
  DEFAULT_MAX_IMAGE_COUNT,
} from "./schemas";
import { isInternalImageAttachment } from "../../../../libs/post";

const MAX_PREVIEW_HEIGHT = 250;

interface PostAttachmentImageProps {
  value: ImageAttachment[];
  onChange: (newValue: ImageAttachment[]) => void;
  selectedPlatformTypes: Platform["type"][];
  showImageAttachments: () => void;
  isImageAttachmentsVisible: boolean;
  error?: boolean;
}
const PostAttachmentImage: React.FC<PostAttachmentImageProps> = ({
  value,
  onChange,
  selectedPlatformTypes,
  showImageAttachments,
  isImageAttachmentsVisible,
  error,
}) => {
  const currentImageCount = value.length;
  const maxImageCount =
    selectedPlatformTypes.length === 1
      ? PLATFORM_MAX_IMAGE_COUNT[selectedPlatformTypes[0]]
      : DEFAULT_MAX_IMAGE_COUNT;
  const hasAtLeastOneImage = currentImageCount > 0;
  const hasMultipleImages = currentImageCount > 1;
  const hasExceededMaxImageCount = currentImageCount > maxImageCount;
  const [errors, setErrors] = useState<{ [index: number]: boolean }>({});
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [imagesXOffset, setImagesXOffset] = useState(0);
  const [hasRenderedInitialOffset, setHasRenderedInitialOffset] =
    useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRefs = useRef<RefObject<HTMLDivElement>[]>([]);

  const handleTitleInputChange = (imageIndex: number) => (newTitle: string) => {
    const newImages = value.slice();
    const newImage = {
      ...newImages[imageIndex],
      title: newTitle,
    };

    newImages.splice(imageIndex, 1, newImage);
    onChange(newImages);
  };

  const handleDeleteImage = (imageIndex: number) => {
    const newImages = value.slice();
    newImages.splice(imageIndex, 1);

    let newVisibleIndex = visibleIndex;

    if (imageIndex < visibleIndex) {
      newVisibleIndex--;
    }

    newVisibleIndex = clamp(
      newVisibleIndex,
      0,
      Math.max(newImages.length - 1, 0)
    );

    setVisibleIndex(newVisibleIndex);

    onChange(newImages);
  };

  useEffect(() => {
    if (error) {
      const newErrors = value.reduce<{ [index: number]: boolean }>(
        (carry, image, index) => {
          const { error } = imageAttachmentSchema.validate(image);
          if (error) {
            carry[index] = true;
          }

          return carry;
        },
        {}
      );

      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [error, value]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (imageRefs.current.length < 1) {
      return;
    }

    const containerWidth = containerRef.current.clientWidth;
    const halfContainerWidth = containerWidth / 2;
    let newImagesXOffset = halfContainerWidth;

    for (let i = 0; i < visibleIndex; i++) {
      const imageRef = imageRefs.current[i];

      if (imageRef && imageRef.current) {
        newImagesXOffset -= imageRef.current.clientWidth;
      }
    }

    const visibleImageRef = imageRefs.current[visibleIndex];

    if (visibleImageRef && visibleImageRef.current) {
      const visibleImageWidth = visibleImageRef.current.clientWidth;
      newImagesXOffset -= Math.floor(visibleImageWidth / 2);
    }

    setImagesXOffset(newImagesXOffset);
  }, [visibleIndex, currentImageCount]);

  useEffect(() => {
    if (imagesXOffset !== 0 && !hasRenderedInitialOffset) {
      setHasRenderedInitialOffset(true);
    }
  }, [hasRenderedInitialOffset, imagesXOffset]);

  return (
    <div className="w-full">
      {hasAtLeastOneImage && (
        <div
          ref={containerRef}
          className="w-full relative overflow-hidden bg-gray-50 border border-gray-100 rounded-lg"
          style={{ height: "23rem" }}
        >
          <div
            className={`h-full absolute top-0 left-0 flex items-center transform-gpu ${
              hasMultipleImages && hasRenderedInitialOffset
                ? "transition-transform"
                : ""
            }`}
            style={{ transform: `translate3d(${imagesXOffset}px, 0, 0)` }}
          >
            {value.map((imageAttachment, index) => {
              const imageRef = createRef<HTMLDivElement>();
              imageRefs.current[index] = imageRef;

              const key = `${
                isInternalImageAttachment(imageAttachment)
                  ? "internal"
                  : "unsplash"
              }${
                isInternalImageAttachment(imageAttachment)
                  ? imageAttachment.fileId
                  : imageAttachment.id
              }`;
              const isError = errors[index];
              const offset = Math.abs(visibleIndex - index);
              const offsetScale = Math.min(offset * 0.1, 0.5);
              const scale = 1 - offsetScale;
              const [clampedImageHeight, clampedImageWidth] = clampImageHeight(
                imageAttachment.height,
                imageAttachment.width,
                MAX_PREVIEW_HEIGHT
              );

              return (
                <div
                  key={key}
                  ref={imageRef}
                  className="shrink-0 p-2 flex flex-col items-center rounded shadow bg-white transition-transform transform-gpu cursor-pointer"
                  style={{ transform: `scale(${scale})` }}
                  onClick={(e) => {
                    if (e.defaultPrevented) {
                      return;
                    }

                    setVisibleIndex(index);
                  }}
                >
                  <div className="group w-full flex flex-col">
                    <div
                      className={`flex items-start pl-1 text-xs font-bold ${
                        isError
                          ? "text-red-500"
                          : "text-gray-400 group-focus-within:text-purple-500"
                      }`}
                    >
                      TITLE
                    </div>

                    <Input
                      className={`py-0 px-1 text-sm text-gray-600 border-2 border-transparent hover:bg-gray-100 focus:bg-white ${
                        isError ? "border-red-500" : "border-transparent"
                      }`}
                      labelText="Image title"
                      hideLabel={true}
                      placeholder="Image title"
                      value={imageAttachment.title}
                      debounceOnChange={handleTitleInputChange(index)}
                      error={isError}
                      focusAfterRender={
                        false /*index === firstNewImageIndex || isError*/
                      }
                    />
                  </div>

                  <div
                    className="mt-2"
                    style={{
                      height: clampedImageHeight,
                      width: clampedImageWidth,
                    }}
                  >
                    <PostImageAttachmentImage
                      imageAttachment={imageAttachment}
                      preview={true}
                    />
                  </div>
                  <div className="mt-1 w-full flex justify-center">
                    <DangerButton
                      className="text-gray-500"
                      title="Remove image from post"
                      invert={true}
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteImage(index);
                      }}
                    >
                      <TrashIcon className="mr-1 h-4 w-4" />
                      Remove from post
                    </DangerButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {hasAtLeastOneImage && (
        <div className="mt-2 h-10 w-full relative flex items-center justify-center">
          <div
            className={
              hasExceededMaxImageCount
                ? "text-red-500 font-bold text-center"
                : ""
            }
          >
            <div>{`${visibleIndex + 1} of ${currentImageCount}`}</div>
            {hasExceededMaxImageCount && (
              <div className="text-sm">{`(maximum of ${maxImageCount} images allowed)`}</div>
            )}
          </div>

          <div className="absolute inset-y-0 right-0 flex items-center">
            <IconButton
              disabled={!hasMultipleImages || visibleIndex === 0}
              onClick={() =>
                setVisibleIndex(
                  clamp(visibleIndex - 1, 0, currentImageCount - 1)
                )
              }
            >
              <ArrowLeftIcon className="h-6 w-6" />
            </IconButton>
            <IconButton
              className="ml-2"
              disabled={
                !hasMultipleImages || visibleIndex === currentImageCount - 1
              }
              onClick={() =>
                setVisibleIndex(
                  clamp(visibleIndex + 1, 0, currentImageCount - 1)
                )
              }
            >
              <ArrowRightIcon className="h-6 w-6" />
            </IconButton>
          </div>
        </div>
      )}

      {!hasAtLeastOneImage && (
        <div className="flex items-center justify-center">
          <NoImagesImage className="h-32 w-32 text-purple-500" />
          <div>
            <p className="ml-2 w-80 text-gray-500">
              Add images to your post by selecting them in the image picker.
            </p>
            {!isImageAttachmentsVisible && (
              <TertiaryButton
                className="mx-auto mt-4"
                size="sm"
                onClick={() => showImageAttachments()}
              >
                Show image picker
              </TertiaryButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PostAttachmentImage;
