import React from "react";
import {
  MessageSquare as MessageSquareIcon,
  CornerUpRight as CornerUpRightIcon,
  Heart as HeartIcon,
  Repeat as RepeatIcon,
  MessageCircle as MessageCircleIcon,
} from "react-feather";
import { ChatAltIcon, ThumbUpIcon } from "@heroicons/react/outline";

import { mergeClassNames } from "../../libs/components";
import { PublishedPostAction } from "../../types/publishedPostActions";

interface PublishedPostActionInstanceIconProps {
  className?: string;
  platformType: PublishedPostAction["platformType"];
  type: PublishedPostAction["type"];
}
const PublishedPostActionInstanceIcon: React.FC<
  PublishedPostActionInstanceIconProps
> = ({ className = "", platformType, type }) => {
  if (platformType === "FACEBOOK") {
    if (type === "COMMENT") {
      return (
        <MessageSquareIcon className={mergeClassNames("h-6 w-6", className)} />
      );
    }
  } else if (platformType === "LINKEDIN") {
    switch (type) {
      case "COMMENT":
        return (
          <ChatAltIcon
            className={mergeClassNames("h-6 w-6", className)}
            style={{ transform: "scale(-1,1)" }}
          />
        );
      case "REACTION":
        return (
          <ThumbUpIcon
            className={mergeClassNames("h-6 w-6", className)}
            style={{ transform: "scale(-1,1)" }}
          />
        );
      case "RESHARE":
        return (
          <CornerUpRightIcon
            className={mergeClassNames("h-6 w-6", className)}
          />
        );
    }
  } else if (platformType === "TWITTER") {
    switch (type) {
      case "LIKE":
        return <HeartIcon className={mergeClassNames("h-6 w-6", className)} />;
      case "REPLY":
        return (
          <MessageCircleIcon
            className={mergeClassNames("h-6 w-6", className)}
          />
        );
      case "RETWEET":
        return <RepeatIcon className={mergeClassNames("h-6 w-6", className)} />;
    }
  }

  return null;
};

export default PublishedPostActionInstanceIcon;
