import React from "react";
import AppPageWithHeader from "../containers/AppPageWithHeader";

const SettingsPage: React.FC = () => {
  return (
    <AppPageWithHeader>
      <h1>Settings</h1>
    </AppPageWithHeader>
  );
};

export default SettingsPage;
