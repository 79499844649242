import React from "react";

interface DeletedFileSnackBarContentProps {
  fileName: string;
}
const DeletedFileSnackBarContent: React.FC<DeletedFileSnackBarContentProps> = ({
  fileName,
}) => {
  return (
    <>
      <strong>{fileName}</strong> deleted
    </>
  );
};

export default DeletedFileSnackBarContent;
