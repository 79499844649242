import React from "react";

import { useTwitterUser } from "../../../../../../../libs/hooks/twitter";

import SelectedBase from "./Base";

interface PostTextAreaMentionSelectedTwitterProps {
  platformId: string;
  twitterUserId: string;
  onDelete: () => void;
  isVisible?: boolean;
}
const PostTextAreaMentionSelectedTwitter: React.FC<
  PostTextAreaMentionSelectedTwitterProps
> = ({ platformId, twitterUserId, onDelete, isVisible = true }) => {
  const { isLoading, data } = useTwitterUser(platformId, twitterUserId);

  if (!isVisible) {
    return null;
  }

  return (
    <SelectedBase
      isLoading={isLoading}
      name={data ? data.name : null}
      vanityName={data ? data.username : undefined}
      pictureUrl={data ? data.profileImageUrl : undefined}
      isVerified={data ? data.isVerified : undefined}
      onDelete={onDelete}
    />
  );
};

export default PostTextAreaMentionSelectedTwitter;
