import React, { useEffect, useRef } from "react";

import { InternalFileImage } from "../../types/files";

import { isImageFileUpload, useUpload } from "../../libs/hooks/uploads";
import { useActiveWorkspaceFileAccessToken } from "../../libs/hooks/app";
import { buildFileUrl } from "../../libs/files";

import LazyImage from "./Lazy";
import UploadingImage from "./Uploading";

interface InternalImageProps {
  file: InternalFileImage;
  preview?: boolean;
  className?: string;
  containerClassName?: string;
}

const InternalImage: React.FC<InternalImageProps> = ({
  file,
  preview = true,
  className = "",
  containerClassName = "",
}) => {
  // First let's check if the file is still being uploaded. If it is then we can
  // just nab the preview url.
  const upload = useUpload(file.id);
  const fileAccessToken = useActiveWorkspaceFileAccessToken();
  // Keep a reference here for us to use because the upload gets removed from the
  // upload state once the file has completed uploading. However there is a window
  // between then and when the file gets loaded.
  const uploadRef = useRef(upload);

  useEffect(() => {
    if (upload) {
      uploadRef.current = upload;
    }
  }, [upload]);

  if (uploadRef.current && isImageFileUpload(uploadRef.current)) {
    let uploadPercent = 100;

    switch (uploadRef.current.status) {
      case "PENDING":
        uploadPercent = 0;
        break;
      case "IN_PROGRESS":
        uploadPercent = uploadRef.current.percent;
        break;
      case "COMPLETE":
        uploadPercent = 100;
        break;
      case "FAILED":
        uploadPercent = 0;
        break;
    }

    return (
      <UploadingImage
        className={className}
        containerClassName={containerClassName}
        src={uploadRef.current.previewUrl}
        alt={file.name}
        percent={uploadPercent}
      />
    );
  }

  if (file.status === "COMPLETE") {
    const imageUrl = buildFileUrl(
      preview && file.preview ? file.preview.path : file.path,
      fileAccessToken || ""
    );
    const blurHash = file.preview ? file.preview.blurHash : undefined;

    uploadRef.current = null;

    return (
      <LazyImage
        className={className}
        containerClassName={containerClassName}
        src={imageUrl}
        blurHash={blurHash}
        alt={file.name}
      />
    );
  }

  return null;
};

export default InternalImage;
