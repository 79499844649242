import { useSpring, animated } from "@react-spring/web";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Blurhash } from "react-blurhash";
import { mergeClassNames } from "../../libs/components";

import LoaderIcon from "../icons/Loader";

interface LazyImageProps {
  src: string;
  alt: string;
  blurHash?: string | null;
  className?: string;
  containerClassName?: string;
  LoadingClassName?: string;
}
const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  blurHash = null,
  className = "",
  containerClassName = "",
  LoadingClassName = "",
}) => {
  const hasBlurHash = blurHash !== null;
  const containerRef = useRef<HTMLDivElement>(null);
  const imageLoadedRef = useRef(false);
  const [blurHashSize, setBlurHashSize] = useState({ height: 0, width: 0 });
  const [loadingSpring, loadingSpringApi] = useSpring(() => ({
    opacity: 0,
    hash: 0,
    spinner: hasBlurHash ? 0 : 1,
    immediate: false,
  }));

  useEffect(() => {
    if (hasBlurHash && containerRef.current) {
      const height = containerRef.current.clientHeight;
      const width = containerRef.current.clientWidth;
      setBlurHashSize({ height, width });

      if (!imageLoadedRef.current) {
        const timeoutId = window.setTimeout(() => {
          if (!imageLoadedRef.current) {
            loadingSpringApi.start({ opacity: 1, hash: 1, immediate: true });
          }
        }, 1000);

        return () => {
          window.clearTimeout(timeoutId);
        };
      }
    }
  }, [hasBlurHash, loadingSpringApi]);

  useEffect(() => {
    if (hasBlurHash) {
      const timeoutId = window.setTimeout(() => {
        if (!imageLoadedRef.current) {
          loadingSpringApi.start({ spinner: 1, immediate: true });
        }
      }, 2000);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [hasBlurHash, loadingSpringApi]);

  return (
    <div
      ref={containerRef}
      className={mergeClassNames(
        "relative w-full h-full flex-shrink-0",
        containerClassName
      )}
    >
      <animated.img
        className={mergeClassNames(
          "max-w-full max-h-full object-contain",
          className
        )}
        src={src}
        alt={alt}
        onLoad={() => {
          imageLoadedRef.current = true;
          loadingSpringApi.start({ opacity: 0, immediate: false });
        }}
        onError={() => {
          imageLoadedRef.current = true;
          loadingSpringApi.start({ opacity: 0, immediate: false });
        }}
        loading="lazy"
        style={{
          opacity: loadingSpring.opacity.to((o) => 1 - o),
        }}
      />
      <animated.div
        className={mergeClassNames(
          "absolute top-0 left-0 bottom-0 right-0 overflow-hidden",
          LoadingClassName
        )}
        style={{
          zIndex: 1,
          opacity: loadingSpring.opacity,
          display: loadingSpring.opacity.to((o) => (o > 0 ? "block" : "none")),
        }}
      >
        {blurHash && (
          <animated.div
            style={{
              opacity: loadingSpring.hash,
              display: loadingSpring.hash.to((o) => (o > 0 ? "block" : "none")),
            }}
          >
            <Blurhash
              hash={blurHash}
              height={blurHashSize.height}
              width={blurHashSize.width}
            />
          </animated.div>
        )}
        <animated.div
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
            blurHash ? "opacity-50" : ""
          }`}
          style={{
            opacity: loadingSpring.spinner,
            display: loadingSpring.spinner.to((o) =>
              o > 0 ? "block" : "none"
            ),
          }}
        >
          <LoaderIcon className="w-12 h-12" />
        </animated.div>
      </animated.div>
    </div>
  );
};

export default LazyImage;
