import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { forwardRef, useEffect, useState } from "react";
import { X as XIcon } from "react-feather";

import IconButton from "./buttons/Icon";

const DEFAULT_TIMEOUT = 5000;

interface SnackBarProps {
  children: React.ReactNode;
  dismiss: () => void;
  userDismissable?: boolean;
  dismissTrigger?: number | Promise<any>;
}

function isTriggerTimeout(
  dismissTrigger: SnackBarProps["dismissTrigger"]
): dismissTrigger is number {
  return typeof dismissTrigger === "number";
}

const SnackBar = forwardRef<HTMLDivElement, SnackBarProps>(
  (
    {
      children,
      dismiss,
      userDismissable = true,
      dismissTrigger = DEFAULT_TIMEOUT,
    },
    ref
  ) => {
    const [initialised, setInitialised] = useState(false);
    const [spring, springApi] = useSpring(() => ({
      width: "0%",
      onRest: () => dismiss(),
      config: isTriggerTimeout(dismissTrigger)
        ? { duration: dismissTrigger, precision: 0.1 }
        : {},
    }));

    useEffect(() => {
      if (!initialised) {
        const initialise = async () => {
          if (isTriggerTimeout(dismissTrigger)) {
            springApi.start({ width: "100%" });
          } else {
            await dismissTrigger;
            dismiss();
          }
        };

        initialise();
        setInitialised(true);
      }
    }, [dismiss, dismissTrigger, initialised, springApi]);

    return (
      <div
        ref={ref}
        className="w-full rounded-lg shadow-lg bg-gray-800 text-white text-sm"
        role="alert"
      >
        <div
          className={`py-3 flex items-center ${
            userDismissable ? "pl-4 pr-2" : "px-4"
          }`}
        >
          <div className="flex-grow overflow-hidden">{children}</div>
          {userDismissable && (
            <IconButton
              className="ml-1 px-1 py-1 flex-shrink-0 bg-gray-600"
              title="Dismiss"
              onClick={() => dismiss()}
            >
              <XIcon className="w-4 h-4" />
            </IconButton>
          )}
        </div>
        {isTriggerTimeout(dismissTrigger) && (
          <animated.div
            className="h-1 rounded-b-lg bg-purple-300"
            style={spring}
          ></animated.div>
        )}
      </div>
    );
  }
);

export default SnackBar;
