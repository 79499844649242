import React from "react";
import { ImageAttachment } from "../../types/posts";

import LazyInternalImage from "./LazyInternal";
import LazyImage from "./Lazy";
import { isUnsplashImageAttachment } from "../../libs/post";

interface PostImageAttachmentImageProps {
  imageAttachment: ImageAttachment;
  preview?: boolean;
  className?: string;
  containerClassName?: string;
}
const PostImageAttachmentImage: React.FC<PostImageAttachmentImageProps> = ({
  imageAttachment,
  preview = true,
  className = "",
  containerClassName = "",
}) => {
  if (isUnsplashImageAttachment(imageAttachment)) {
    return (
      <LazyImage
        className={className}
        containerClassName={containerClassName}
        src={imageAttachment.urls.regular}
        alt={imageAttachment.title}
        blurHash={imageAttachment.blurHash}
      />
    );
  } else {
    return (
      <LazyInternalImage
        className={className}
        containerClassName={containerClassName}
        fileId={imageAttachment.fileId}
        preview={preview}
      />
    );
  }
};

export default PostImageAttachmentImage;
