import React, { forwardRef, useMemo } from "react";
import { AlertTriangle as AlertTriangleIcon } from "react-feather";
import { Platform } from "../../../../types/platforms";
import { platformIsAuthExpired } from "../../../../libs/platform";

import PlatformIcon from "../../../../components/icons/Platform";
import Tooltip from "../../../../components/Tooltip";
import MultiSelectDropdown, {
  MultiSelectDropdownOption,
} from "../../../../components/form/MultiSelect";

import PlatformEntityPicture from "../../../../components/images/profile/PlatformEntity";

interface PostFormSocialsMultiSelectPropsOption
  extends MultiSelectDropdownOption {
  data: {
    platform: Platform;
    entityId: string;
  };
}

interface Selected {
  platform: Platform;
  platformEntityId: string;
}

interface PostFormSocialsMultiSelectProps {
  socials: Array<{ platform: Platform; platformEntityId: string }>;
  selected: Selected[];
  onChange: (newSelected: Selected[]) => void;
  error?: boolean;
  dropdownClassName?: string;
  showPlatformType?: boolean;
}

const PostFormSocialsMultiSelect = forwardRef<
  HTMLButtonElement,
  PostFormSocialsMultiSelectProps
>(
  (
    {
      socials,
      selected,
      onChange,
      error = false,
      dropdownClassName = "",
      showPlatformType = true,
    },
    ref
  ) => {
    const options = useMemo(() => {
      return socials.map<PostFormSocialsMultiSelectPropsOption>(
        ({ platform, platformEntityId }) => {
          return {
            key: `${platform.id}${platformEntityId}`,
            text: platform.entities[platformEntityId].name,
            data: {
              platform,
              entityId: platformEntityId,
            },
          };
        }
      );
    }, [socials]);

    const selectedOptions = selected.reduce<
      PostFormSocialsMultiSelectPropsOption[]
    >((carry, { platform, platformEntityId: entityId }) => {
      const entity = platform.entities[entityId];

      if (entity) {
        carry.push({
          key: `${platform.id}${entityId}`,
          text: entity.name,
          data: {
            platform,
            entityId,
          },
        });
      }

      return carry;
    }, []);

    const renderSelectedOptions = (
      options: PostFormSocialsMultiSelectPropsOption[]
    ) => {
      if (options.length) {
        return (
          <div className="w-full flex items-center">
            {options.map((option, index) => {
              const { platform, entityId } = option.data;
              const entity = platform.entities[entityId];

              return (
                <React.Fragment key={option.key}>
                  <Tooltip
                    className={`h-9 ${index === 0 ? "" : "ml-2"}`}
                    content={
                      <div className="flex items-center">
                        <PlatformIcon
                          className="mr-1 w-6 h-6 text-gray-500"
                          type={platform.type}
                        />
                        {entity.name}
                      </div>
                    }
                  >
                    <PlatformEntityPicture
                      className="w-9 h-9"
                      entity={entity}
                      platformType={
                        showPlatformType ? platform.type : undefined
                      }
                    />
                  </Tooltip>
                  {options.length === 1 && (
                    <span className="ml-2 truncate">{entity.name}</span>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      } else {
        return <div className="h-9 leading-9">Select socials</div>;
      }
    };

    const renderOption = (option: PostFormSocialsMultiSelectPropsOption) => {
      const { platform, entityId } = option.data;
      const entity = platform.entities[entityId];
      const expired = platform.authExpiry
        ? platformIsAuthExpired(platform.authExpiry)
        : false;

      return (
        <div className="w-full flex items-center">
          <PlatformEntityPicture
            className="flex-shrink-0 w-9 h-9"
            entity={entity}
          />
          <span className="ml-2 flex-shrink-0 truncate ">{entity.name}</span>
          {expired && (
            <div className="flex ml-auto text-yellow-500 text-sm font-normal items-center">
              Access needs refreshing
              <AlertTriangleIcon className="ml-1 mr-1 h-4 w-4" />
              <PlatformIcon
                className="ml-1"
                type={platform.type}
                colour={true}
              />
            </div>
          )}
          {!expired && showPlatformType && (
            <PlatformIcon
              className="ml-auto"
              type={platform.type}
              colour={true}
            />
          )}
        </div>
      );
    };

    const handleOnChange = (
      newSelectedOptions: PostFormSocialsMultiSelectPropsOption[]
    ) => {
      onChange(
        newSelectedOptions.map((selectedOption) => ({
          platform: selectedOption.data.platform,
          platformEntityId: selectedOption.data.entityId,
        }))
      );
    };

    return (
      <MultiSelectDropdown
        ref={ref}
        dropdownClassName={dropdownClassName}
        toggleButtonClassName={`py-2 border-2 border-transparent justify-start ${
          error
            ? "border-red-500 hover:border-red-600 focus:border-red-600"
            : "hover:bg-gray-100 hover:border-transparent focus:bg-white focus:border-purple-500"
        }`}
        options={options}
        selectedOptions={selectedOptions}
        renderSelectedOptions={renderSelectedOptions}
        renderOption={renderOption}
        onChange={handleOnChange}
        includeSearch={true}
      />
    );
  }
);

export default PostFormSocialsMultiSelect;
