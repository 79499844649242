import axios from "axios";
import { Auth } from "@aws-amplify/auth";
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useLoadCurrentUser } from "../libs/hooks/users";

import AuthenticatedAppContext, {
  AuthenticatedAppContextState,
} from "../contexts/app/Authenticated";
import UploadProvider from "../providers/Upload";

import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import LoaderIcon from "../components/icons/Loader";
import H2 from "../components/headings/H2";

import config from "../config";

const STRIPE_PROMISE = loadStripe(config.stripe.PUBLIC_KEY);

const App: React.FC = () => {
  // This is just here to force a re-render on logout and have the app context removed
  // when the current users is gone.
  const [appState, setAppState] = useState<AuthenticatedAppContextState | null>(
    null
  );
  const { data, isPlaceholderData, isFetching, isError } = useLoadCurrentUser();
  const currentUser = data ? data.user : null;
  const isEmailVerified = data ? data.isEmailVerified : true;

  useEffect(() => {
    if (currentUser) {
      const apiClient = axios.create({
        baseURL: config.apiGateway.URL,
      });

      // Add this as an interceptor so that it calls currentSession for each ajax
      // request which will refresh our id token automatically in the background in
      // case it expires.
      apiClient.interceptors.request.use(async (config) => {
        const session = await Auth.currentSession();

        if (session) {
          config.headers = {
            ...config.headers,
            Authorization: session.getIdToken().getJwtToken(),
          };
        }

        return config;
      });
      apiClient.interceptors.response.use(undefined, (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          // If the error was from our API then pass the API error response
          // through as the error in try/catch statements.
          return Promise.reject(error.response.data.error);
        }

        return error;
      });

      setAppState({
        currentUser,
        isEmailVerified,
        apiClient,
        logout: () => setAppState(null),
      });
    } else {
      setAppState(null);
    }
  }, [currentUser, isEmailVerified]);

  if ((isFetching && isPlaceholderData && !currentUser) || isError) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        {isError ? (
          <div className="flex items-center">
            <H2 className="text-red-500">
              Error loading user. Please reload the page to try again.
            </H2>
          </div>
        ) : (
          <div className="flex items-center">
            <LoaderIcon className="w-10 h-10" />
          </div>
        )}
      </div>
    );
  } else if (!appState && currentUser) {
    // There is a single render frame between when the user is loaded and the app
    // context is built (because it happens in a useEffect hook) so just return nothing here
    // otherwise we flash the loading screen which looks bad.
    return null;
  } else {
    return appState ? (
      <AuthenticatedAppContext.Provider value={appState}>
        <UploadProvider>
          <Elements stripe={STRIPE_PROMISE}>
            <AuthenticatedApp />
          </Elements>
        </UploadProvider>
      </AuthenticatedAppContext.Provider>
    ) : (
      <UnauthenticatedApp />
    );
  }
};

export default App;
