import React, { useEffect, useRef, useState } from "react";
import { Link as LinkIcon, X as XIcon, Plus as PlusIcon } from "react-feather";
import { PhotographIcon, FilmIcon } from "@heroicons/react/outline";

import { mergeClassNames } from "../../../libs/components";
import { useHasChanged } from "../../../libs/hooks/general";
import { PostAttachment as TPostAttachment } from "../../../types/posts";
import { Platform } from "../../../types/platforms";

import Button from "../../../components/buttons/Button";
import IconButton from "../../../components/buttons/Icon";
import ContentSwitchContainer from "../../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../../components/animations/contentSwitch/Content";
import TertiaryButton from "../../../components/buttons/Tertiary";

import PostAttachmentImage from "./attachment/Image";
import PostAttachmentVideo from "./attachment/Video";
import PostAttachmentLink from "./attachment/Link";
import Tooltip from "../../../components/Tooltip";

interface PostAttachmentProps {
  value: TPostAttachment | null;
  onChange: (newValue: TPostAttachment | null) => void;
  selectedPlatformTypes: Platform["type"][];
  isImageAttachmentsVisible: boolean;
  isVideoAttachmentVisible: boolean;
  showImageAttachments: () => void;
  showVideoAttachment: () => void;
  error?: boolean;
  className?: string;
  canEditLinkPreview?: boolean;
  candidateLinkAttachments?: string[];
}

const PostAttachment: React.FC<PostAttachmentProps> = ({
  value,
  onChange,
  selectedPlatformTypes,
  isImageAttachmentsVisible,
  isVideoAttachmentVisible,
  showImageAttachments,
  showVideoAttachment,
  error,
  className = "",
  canEditLinkPreview = true,
  candidateLinkAttachments,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [curExpanded, setCurExpanded] = useState<
    "image" | "video" | "link" | null
  >(
    value === null
      ? null
      : value.type === "IMAGE"
      ? "image"
      : value.type === "VIDEO"
      ? "video"
      : "link"
  );
  const [automaticallyExpanded, setAutomaticallyExpanded] = useState(
    value !== null
  );
  const hasValueChanged = useHasChanged(value);
  const hasAtLeastOneImage =
    !!value && value.type === "IMAGE" && value.images.length > 0;
  const hasVideo = !!value && value.type === "VIDEO";

  useEffect(() => {
    if (hasValueChanged) {
      if (value !== null && curExpanded === null) {
        if (value.type === "LINK") {
          setCurExpanded("link");
          setAutomaticallyExpanded(true);
        }
      } else if (
        value === null &&
        curExpanded !== null &&
        automaticallyExpanded
      ) {
        setCurExpanded(null);
      }
    }
  }, [automaticallyExpanded, hasValueChanged, value, curExpanded]);

  return (
    <div
      ref={containerRef}
      className={mergeClassNames("relative w-full", className)}
    >
      {curExpanded === null && (
        <div className="h-12 flex items-center">
          <Button
            className="w-full hover:text-purple-500 hover:bg-gray-100 focus:text-purple-500 focus:bg-gray-100"
            onClick={() => {
              setCurExpanded("image");
              showImageAttachments();
            }}
          >
            <PhotographIcon className="shrink-0 w-6 h-6" />
            <span className="ml-2">Images</span>
          </Button>
          <Button
            className="w-full hover:text-purple-500 hover:bg-gray-100 focus:text-purple-500 focus:bg-gray-100"
            onClick={() => {
              setCurExpanded("video");
              showVideoAttachment();
            }}
          >
            <FilmIcon className="shrink-0 w-6 h-6" />
            <span className="ml-2">Video</span>
          </Button>
          <Button
            className="w-full hover:text-purple-500 hover:bg-gray-100 focus:text-purple-500 focus:bg-gray-100"
            onClick={() => setCurExpanded("link")}
          >
            <LinkIcon />
            <span className="ml-2">Link</span>
          </Button>
        </div>
      )}
      {curExpanded !== null && (
        <div className="h-12 flex items-center font-bold">
          {curExpanded === "image" && (
            <>
              <PhotographIcon className="shrink-0 w-6 h-6" />
              <span className="ml-2">Images</span>
            </>
          )}
          {curExpanded === "video" && (
            <>
              <FilmIcon className="shrink-0 w-6 h-6" />
              <span className="ml-2">Video</span>
            </>
          )}
          {curExpanded === "link" && (
            <>
              <LinkIcon />
              <span className="ml-2">Link</span>
            </>
          )}
          <div className="ml-auto flex-shrink-0 flex items-center">
            {curExpanded === "image" &&
              !isImageAttachmentsVisible &&
              hasAtLeastOneImage && (
                <TertiaryButton
                  className="mr-2"
                  size="sm"
                  onClick={() => showImageAttachments()}
                >
                  <PlusIcon className="w-4 h-4 mr-1" />
                  Add more images
                </TertiaryButton>
              )}
            {curExpanded === "video" &&
              !isVideoAttachmentVisible &&
              hasVideo && (
                <TertiaryButton
                  className="mr-2"
                  size="sm"
                  onClick={() => showVideoAttachment()}
                >
                  <PlusIcon className="w-4 h-4 mr-1" />
                  Show video picker
                </TertiaryButton>
              )}
            <Tooltip
              content={`Remove ${
                value
                  ? value.type === "IMAGE"
                    ? "image "
                    : value.type === "VIDEO"
                    ? "video "
                    : "link "
                  : ""
              }attachment from post`}
            >
              <IconButton
                onClick={() => {
                  onChange(null);
                  setCurExpanded(null);
                }}
              >
                <XIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <ContentSwitchContainer contentId={curExpanded || "none"}>
        <ContentSwitchContent contentId="none">
          <div></div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="image">
          <PostAttachmentImage
            value={value && value.type === "IMAGE" ? value.images : []}
            onChange={(newImages) => {
              const hasImages = newImages.length > 0;
              onChange(hasImages ? { type: "IMAGE", images: newImages } : null);
            }}
            selectedPlatformTypes={selectedPlatformTypes}
            showImageAttachments={showImageAttachments}
            isImageAttachmentsVisible={isImageAttachmentsVisible}
            error={error}
          />
        </ContentSwitchContent>
        <ContentSwitchContent contentId="video">
          <PostAttachmentVideo
            value={value && value.type === "VIDEO" ? value.video : null}
            onChange={(newVideo) => {
              onChange(newVideo ? { type: "VIDEO", video: newVideo } : null);
            }}
            selectedPlatformTypes={selectedPlatformTypes}
            showVideoAttachment={showVideoAttachment}
            isVideoAttachmentVisible={isVideoAttachmentVisible}
            error={error}
          />
        </ContentSwitchContent>
        <ContentSwitchContent contentId="link">
          <PostAttachmentLink
            value={value && value.type === "LINK" ? value.link : null}
            onChange={(newLink) => onChange({ type: "LINK", link: newLink })}
            canEdit={canEditLinkPreview}
            focusOnRender={!automaticallyExpanded}
            error={error}
            candidateLinkAttachments={candidateLinkAttachments}
          />
        </ContentSwitchContent>
      </ContentSwitchContainer>
    </div>
  );
};

export default PostAttachment;
