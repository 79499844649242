import React, { useEffect, useState } from "react";

import { useDebouncedFunction } from "../../../../libs/hooks/general";
import { useHashtagSuggestions } from "../../../../libs/hooks/riteKit";

import PrimaryBadge from "../../../../components/badges/Primary";

interface PostTextAreaHashtagSuggestionsProps {
  text: string;
  existing: string[];
  onClick: (newHashtag: string) => void;
}
const PostTextAreaHashtagSuggestions: React.FC<
  PostTextAreaHashtagSuggestionsProps
> = ({ text, existing, onClick }) => {
  const [searchText, setSearchText] = useState(text);
  const hasSearchText = searchText !== "";
  const { data } = useHashtagSuggestions(
    { text: searchText },
    { enabled: hasSearchText, keepPreviousData: true }
  );
  const suggestions = hasSearchText ? data || [] : [];
  const filteredSuggestions = suggestions.filter(
    (suggestion) => !existing.includes(suggestion.hashtag)
  );

  const updateSearchText = useDebouncedFunction((newSearchText: string) => {
    setSearchText(newSearchText);
  });

  useEffect(() => {
    updateSearchText(text);
  }, [text, updateSearchText]);

  return (
    <div className="w-full h-10 overflow-y-hidden overflow-h-auto flex items-center">
      {filteredSuggestions.map((suggestion, index) => {
        return (
          <button
            key={`${index}-${suggestion.hashtag}`}
            type="button"
            onClick={() => onClick(suggestion.hashtag)}
          >
            <PrimaryBadge
              className={`default-transition hover:bg-purple-200 focus:bg-purple-200 ${
                index > 0 ? "ml-2" : ""
              }`}
            >{`#${suggestion.hashtag}`}</PrimaryBadge>
          </button>
        );
      })}
    </div>
  );
};

export default PostTextAreaHashtagSuggestions;
