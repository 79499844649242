import React from "react";
import { Star as StarIcon } from "react-feather";

import config from "../../config";
import { mergeClassNames } from "../../libs/components";
import { useActiveWorkspace, useShowModal } from "../../libs/hooks/app";

import { ReactComponent as AnalyticsImage } from "../../images/analytics.svg";

import H2 from "../../components/headings/H2";
import PrimaryButton from "../../components/buttons/Primary";

interface AnalyticsBodyProps {
  className?: string;
}
const AnalyticsNoSubscription: React.FC<AnalyticsBodyProps> = ({
  className = "",
}) => {
  const showModal = useShowModal();
  const workspace = useActiveWorkspace();

  return (
    <div className={mergeClassNames("flex flex-col items-center", className)}>
      <div style={{ width: 600 }}>
        <H2>Interested in the numbers?</H2>
        <p className="mt-6">
          If you're a numbers person and would like to see the{" "}
          <strong>analytics</strong> for your workspace's performance over time
          then we've got you covered!
        </p>
        {!workspace.hasHadTrial ? (
          <p className="mt-4">
            Try our{" "}
            <strong>{config.prices.PUBLISH_MONTHLY.displayName} plan</strong>{" "}
            for{" "}
            <strong>free for {config.DEFAULT_TRIAL_PERIOD_DAYS} days</strong>{" "}
            (no credit card required) to see the analytics for your posts.
          </p>
        ) : (
          <p className="mt-4">
            Upgrade to our{" "}
            <strong>{config.prices.PUBLISH_MONTHLY.displayName} plan</strong> to
            see the analytics for your posts.
          </p>
        )}
      </div>
      <PrimaryButton
        className="mt-10"
        onClick={() =>
          showModal("changeSubscription", {
            workspace,
            redirectOnSuccess: false,
            defaultPriceId: config.prices.PUBLISH_MONTHLY.id,
          })
        }
      >
        <StarIcon className="mr-2" />
        {!workspace.hasHadTrial
          ? `Start your ${config.DEFAULT_TRIAL_PERIOD_DAYS}-day free trial`
          : `Upgrade to our ${config.prices.PUBLISH_MONTHLY.displayName} plan to see anlytics`}
      </PrimaryButton>
      {!workspace.hasHadTrial && (
        <p className="mt-2">No credit card required!</p>
      )}
      <AnalyticsImage
        className="mt-12 flex-shrink-0 text-purple-500"
        style={{ width: 400, height: 400 }}
      />
    </div>
  );
};

export default AnalyticsNoSubscription;
