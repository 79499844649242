import config from "../config";
import { InternalFile, InvalidPlatform } from "../types/files";
import { getPlatformTypeName } from "./platform";

export const ALLOWED_FILE_SIZES = {
  IMAGE: {
    MIN: 100, // 100 bytes.
    MIN_DESC: "100 B",
    MAX: 4194304, // 4 MB - the FB limit.
    MAX_DESC: "4 MB",
  },
  VIDEO: {
    MIN: 76800, // 75 KB - LinkedIn min.
    MIN_DESC: "75 KB",
    MAX: 5368709120, // 5GB MB - the LinkedIn max.
    MAX_DESC: "5 GB",
  },
} as const;

export const MIME_TYPE_TO_FILE_TYPE = {
  "image/jpeg": "IMAGE",
  "image/png": "IMAGE",
  "video/mp4": "VIDEO",
} as const;

export const FILE_TYPE_TO_MIME_TYPE = {
  IMAGE: ["image/jpeg", "image/png"],
  VIDEO: ["video/mp4"],
} as const;

export const MIME_TYPE_ACCEPT_LIST = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "video/mp4": [".mp4"],
};

export const ALL_MIME_TYPES = Object.keys(MIME_TYPE_ACCEPT_LIST);

export const VIDEO_FILE_RESTRICTIONS = {
  TWITTER: {
    WIDTH_MIN: 32,
    WIDTH_MAX: 1280,
    HEIGHT_MIN: 32,
    HEIGHT_MAX: 1024,
    DURATION_MIN: 0.5, // Seconds.
    DURATION_MAX: 140, // Seconds.
    SIZE_MIN: 0,
    SIZE_MAX: 536870912, // 512 MB in bytes.
  },
  LINKEDIN: {
    WIDTH_MIN: 256,
    WIDTH_MAX: 4096,
    HEIGHT_MIN: 144,
    HEIGHT_MAX: 2304,
    DURATION_MIN: 3, // Seconds.
    DURATION_MAX: 900, // 15 minutes in seconds.
    SIZE_MIN: 76800, // 75 KB in bytes.
    SIZE_MAX: 5368709120, // 5 GB in bytes.
  },
  FACEBOOK: {
    WIDTH_MIN: 0,
    WIDTH_MAX: 1920,
    HEIGHT_MIN: 0,
    HEIGHT_MAX: 1080,
    DURATION_MIN: 0, // Seconds.
    DURATION_MAX: 14400, // 240 minutes in seconds.
    SIZE_MIN: 0, // 75 KB in bytes.
    SIZE_MAX: 10737418240, // 10 GB in bytes.
  },
} as const;

const ONE_KB = 1024;
const ONE_MB = 1048576;
const ONE_GB = 1073741824;

export function formatFileSizeForDisplay(size?: number) {
  if (size === undefined) {
    return "?? KB";
  }

  if (size < ONE_KB) {
    return `${size} bytes`;
  } else if (size < ONE_MB) {
    return `${Math.floor(size / ONE_KB)} KB`;
  } else if (size < ONE_GB) {
    return `${(size / ONE_MB).toFixed(2).replace(/(0+|\.00)$/, "")} MB`;
  } else {
    return `${(size / ONE_GB).toFixed(2).replace(/(0+|\.00)$/, "")} GB`;
  }
}

export function buildFileUrl(path: string, fileAccessToken: string) {
  return `${config.cdn.URL}/${path}?token=${fileAccessToken}`;
}

export function buildDownloadFileUrl(
  path: string,
  fileAccessToken: string,
  fileName: string
) {
  const fileUrl = buildFileUrl(path, fileAccessToken);
  const params = new URLSearchParams({
    "response-content-disposition": `attachment; filename=${fileName}`,
  });
  return `${fileUrl}&${params.toString()}`;
}

function sortByValueAsc(
  a: string | number | undefined,
  b: string | number | undefined
) {
  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined && b !== undefined) {
    return 1;
  } else if (a !== undefined && b === undefined) {
    return -1;
  } else if (a! < b!) {
    return -1;
  } else if (a! > b!) {
    return 1;
  } else {
    return 0;
  }
}

function sortByValueDesc(
  a: string | number | undefined,
  b: string | number | undefined
) {
  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined && b !== undefined) {
    return 1;
  } else if (a !== undefined && b === undefined) {
    return -1;
  } else if (a! < b!) {
    return 1;
  } else if (a! > b!) {
    return -1;
  } else {
    return 0;
  }
}

export function sortCreatedAsc(a: InternalFile, b: InternalFile) {
  return sortByValueAsc(a.created, b.created);
}

export function sortCreatedDesc(a: InternalFile, b: InternalFile) {
  return sortByValueDesc(a.created, b.created);
}

export function sortNameAsc(a: InternalFile, b: InternalFile) {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();

  return sortByValueAsc(aLower, bLower);
}

export function sortNameDesc(a: InternalFile, b: InternalFile) {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();

  return sortByValueDesc(aLower, bLower);
}

export function sortSizeAsc(a: InternalFile, b: InternalFile) {
  return sortByValueAsc(a.size, b.size);
}

export function sortSizeDesc(a: InternalFile, b: InternalFile) {
  return sortByValueDesc(a.size, b.size);
}

export function getDescriptionForInvalidPlatform({
  platformType,
  code,
}: InvalidPlatform) {
  switch (code) {
    case "0001":
      return `Images must be between ${ALLOWED_FILE_SIZES.IMAGE.MIN_DESC} and ${ALLOWED_FILE_SIZES.IMAGE.MAX_DESC}`;
    case "0002":
      return `Image resolution is too large for ${getPlatformTypeName(
        platformType
      )}`;
    case "0003":
      return `Videos for ${getPlatformTypeName(
        platformType
      )} must be between ${formatFileSizeForDisplay(
        VIDEO_FILE_RESTRICTIONS[platformType].SIZE_MIN
      )} and ${formatFileSizeForDisplay(
        VIDEO_FILE_RESTRICTIONS[platformType].SIZE_MAX
      )}`;
    case "0004":
      return `Video resolution for ${getPlatformTypeName(
        platformType
      )} must be between ${VIDEO_FILE_RESTRICTIONS[platformType].WIDTH_MIN}x${
        VIDEO_FILE_RESTRICTIONS[platformType].HEIGHT_MIN
      } and ${VIDEO_FILE_RESTRICTIONS[platformType].WIDTH_MAX}x${
        VIDEO_FILE_RESTRICTIONS[platformType].HEIGHT_MAX
      }`;
    case "0005":
      return `Video duration for ${getPlatformTypeName(
        platformType
      )} must be between ${
        VIDEO_FILE_RESTRICTIONS[platformType].DURATION_MIN
      } seconds and ${
        VIDEO_FILE_RESTRICTIONS[platformType].DURATION_MAX
      } seconds`;
  }
}
