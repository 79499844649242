import React, { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";

import { mergeClassNames } from "../../../libs/components";
import { clamp, isEqualOrAncestorElement } from "../../../libs/utils";
import { useHasChanged } from "../../../libs/hooks/general";

import CheckboxToggle from "../CheckboxToggle";

const NON_NUMBER_REGEX = new RegExp("[^0123456789]");

function formatAsInputValue(hour: number, minute: number) {
  const normalisedHour = hour > 12 ? hour - 12 : hour;
  const hourStr =
    normalisedHour < 10 ? `0${normalisedHour}` : normalisedHour.toString();
  const minuteStr = minute < 10 ? `0${minute}` : minute.toString();

  return `${hourStr}:${minuteStr}`;
}

interface TimeInputProps {
  value: { hour: number; minute: number };
  onChange: (newTime: { hour: number; minute: number }) => void;
  timeZone: string;
  allowPast?: boolean;
  className?: string;
}

const TimeInput: React.FC<TimeInputProps> = ({
  value,
  onChange,
  timeZone,
  allowPast = true,
  className = "",
}) => {
  const now = DateTime.local().setZone(timeZone);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [hour, setHour] = useState(value.hour);
  const [minute, setMinute] = useState(value.minute);
  const [inputValue, setInputValue] = useState(
    formatAsInputValue(value.hour, value.minute)
  );
  const [isPm, setIsPm] = useState(value.hour >= 12);
  const minHour = allowPast ? 0 : now.hour;
  const minMinute = allowPast ? 0 : now.minute;
  const valueHasHasChanged = useHasChanged(`${value.hour}:${value.minute}`);
  const minHourHasHasChanged = useHasChanged(minHour);
  const minMinuteHasHasChanged = useHasChanged(minHour);

  useEffect(() => {
    if (inputRef.current && cursorPosition !== null) {
      inputRef.current.selectionStart = cursorPosition;
      inputRef.current.selectionEnd = cursorPosition;
      setCursorPosition(null);
    }
  }, [cursorPosition]);

  useEffect(() => {
    if (
      (valueHasHasChanged &&
        (value.hour !== hour || value.minute !== minute)) ||
      minHourHasHasChanged ||
      minMinuteHasHasChanged
    ) {
      // The value was changed from the outsite so we should updated.
      let newHour = valueHasHasChanged
        ? value.hour
        : isPm && hour < 12
        ? hour + 12
        : hour;
      let newMinute = valueHasHasChanged ? value.minute : minute;

      if (newHour < minHour || (newHour === minHour && newMinute < minMinute)) {
        newHour = minHour;
        newMinute = minMinute;
      }

      setIsPm(newHour >= 12);
      setHour(newHour);
      setMinute(newMinute);
      setInputValue(formatAsInputValue(newHour, newMinute));

      if (!valueHasHasChanged) {
        onChange({ hour: newHour, minute: newMinute });
      }
    }
  }, [
    hour,
    isPm,
    minHour,
    minHourHasHasChanged,
    minMinute,
    minMinuteHasHasChanged,
    minute,
    onChange,
    value.hour,
    value.minute,
    valueHasHasChanged,
  ]);

  return (
    <div
      ref={containerRef}
      className={mergeClassNames("flex items-center", className)}
      onBlur={() => {
        window.requestAnimationFrame(() => {
          if (
            !containerRef.current ||
            !document.activeElement ||
            !isEqualOrAncestorElement(
              containerRef.current,
              document.activeElement
            )
          ) {
            let newHour = isPm && hour < 12 ? hour + 12 : hour;
            let newMinute = minute;

            if (
              newHour < minHour ||
              (newHour === minHour && newMinute < minMinute)
            ) {
              newHour = minHour;
              newMinute = minMinute;
            }

            setIsPm(newHour >= 12);
            setHour(newHour);
            setMinute(newMinute);
            setInputValue(formatAsInputValue(newHour, newMinute));
            onChange({ hour: newHour, minute: newMinute });
          }
        });
      }}
    >
      <input
        ref={inputRef}
        className="w-14 text-lg bg-transparent focus:text-purple-500 focus:underline"
        value={inputValue}
        onChange={(e) => {
          const inputRef = e.target;
          const oldInputValue = inputValue;
          let newInputValue = inputRef.value.trim();
          let cursor = clamp(inputRef.selectionEnd || 0, 0, 6);

          if (newInputValue.length < 1) {
            setIsPm(false);
            setInputValue(formatAsInputValue(0, 0));
            setHour(0);
            setMinute(0);
            setCursorPosition(0);
            return;
          }

          if (newInputValue.indexOf(":") < 0) {
            const oldInputWithoutColon = `${oldInputValue.slice(
              0,
              2
            )}${oldInputValue.slice(3)}`;

            if (oldInputWithoutColon === newInputValue) {
              // Special case where the user has hit backspace to delete the
              // colon character.
              newInputValue = `${newInputValue.slice(
                0,
                1
              )}:${newInputValue.slice(2)}`;
              cursor--;
            } else if (NON_NUMBER_REGEX.test(newInputValue)) {
              // Pasted something random.
              return;
            } else {
              // Pasted all numbers with no colon so let's just add one
              // for them.
              // Pad with zeros to make sure we get correct length.
              newInputValue = `${newInputValue}00`.slice(0, 2);
              newInputValue = `${newInputValue}:00`;
              // Jump cursor to the second character so that the new input
              // gets calculated correctly.
              cursor = 1;
            }
          }

          const [oldHourStr, oldMinuteStr] = oldInputValue.split(":");
          const [currentHourStr, currentMinuteStr] = newInputValue.split(":");
          let newHourStr = oldHourStr;
          let newMinuteStr = oldMinuteStr;
          let newCursor = cursor;

          if (
            NON_NUMBER_REGEX.test(currentHourStr) ||
            NON_NUMBER_REGEX.test(currentMinuteStr)
          ) {
            return;
          }

          switch (cursor) {
            case 0:
              // Deleted the first hour number.
              newHourStr = `0${currentHourStr}`;
              break;
            case 1:
              newHourStr =
                currentHourStr.length < 2
                  ? // Deleted the second hour number.
                    `${oldHourStr.slice(0, 1)}0`
                  : // Changed the first hour number.
                    `${currentHourStr.slice(0, 1)}${oldHourStr.slice(1, 2)}`;
              break;
            case 2:
              newHourStr =
                currentHourStr.length < 2
                  ? // Unique case where user presses colon after typing first hour
                    // number
                    `0${currentHourStr}`
                  : // Changed second hour number.
                    currentHourStr.slice(0, 2);
              // Skip the colon character.
              newCursor++;
              break;
            case 3:
              newHourStr = currentHourStr.slice(0, 2);
              newMinuteStr =
                currentHourStr.length > 2
                  ? // Added number in front of colon.
                    `${currentHourStr.slice(2, 3)}${oldMinuteStr.slice(1, 2)}`
                  : currentMinuteStr.length < 2
                  ? // Deleted the first minute number.
                    `0${oldMinuteStr.slice(-1)}`
                  : // Changed the first minute number.
                    currentMinuteStr.slice(0, 2);
              newCursor =
                // Move cursor after first minute number unless it was because of
                // a backspace delete.
                currentMinuteStr.length < 2 ? newCursor : newCursor + 1;
              break;
            case 4:
              newHourStr = currentHourStr.slice(0, 2);
              newMinuteStr =
                currentMinuteStr.length < 2
                  ? // Deleted the last minute number.
                    `${oldMinuteStr.slice(0, 1)}0`
                  : // Changed the last minute number.
                    `${currentMinuteStr.slice(0, 1)}${oldMinuteStr.slice(
                      1,
                      2
                    )}`;
              break;
            case 5:
              newHourStr = currentHourStr.slice(0, 2);
              newMinuteStr = currentMinuteStr.slice(0, 2);
              break;
            case 6:
              // Added a new number after the last minute number so
              // shuffle all of the numbers to the left one.
              newHourStr = `${currentHourStr.slice(
                0,
                2
              )}${currentMinuteStr.slice(0, -2)}`.slice(-2);
              newMinuteStr = currentMinuteStr.slice(-2);
              break;
          }

          const [h1, h2] = newHourStr.split("").map((str) => parseInt(str, 10));
          const [m1, m2] = newMinuteStr
            .split("")
            .map((str) => parseInt(str, 10));

          const clampedH1 =
            h2 === 0 && m1 === 0 && m2 === 0 ? h1 : clamp(h1, 0, 2);
          const clampedH2 = clampedH1 < 2 ? h2 : clamp(h2, 0, 3);
          const clampedM1 =
            clampedH1 === 0 && clampedH2 === 0 ? m1 : clamp(m1, 0, 5);

          if (
            clampedH2 === 0 &&
            clampedH1 < 10 &&
            clampedH1 > 2 &&
            newCursor === 1
          ) {
            // Typed a number between 3 and 9 which isn't a valid first
            // digit for an hour so we can assume they want it as the second
            // digit.
            newHourStr = `${clampedH2}${clampedH1}`;
            newCursor = 3;
          } else {
            newHourStr = `${clampedH1}${clampedH2}`;
          }

          newMinuteStr = `${clampedM1}${m2}`;

          let newHour = parseInt(newHourStr, 10);
          let newMinute = parseInt(newMinuteStr, 10);

          if (isNaN(newHour) || isNaN(newMinute)) {
            // Errr wtf?
            return;
          }

          if (newHour >= 12 && newCursor > 2) {
            setIsPm(true);
            setInputValue(formatAsInputValue(newHour, newMinute));
          } else {
            setInputValue(`${newHourStr}:${newMinuteStr}`);
          }

          setHour(newHour);
          setMinute(newMinute);
          setCursorPosition(newCursor);
        }}
        onBlur={() => {
          const [hourStr, minuteStr] = inputValue.split(":");
          const newHour = parseInt(hourStr, 10);
          const newMinute = parseInt(minuteStr, 10);

          if (newHour >= 12) {
            setIsPm(true);
          }

          setHour(newHour);
          setMinute(newMinute);
          setInputValue(formatAsInputValue(newHour, newMinute));
        }}
      />

      <CheckboxToggle
        className="ml-2 text-sm"
        checkedContent="am"
        uncheckedContent="pm"
        checked={!isPm}
        onChange={(e) => {
          const newIsPm = !e.target.checked;

          if (isPm && !newIsPm && hour >= 12) {
            setHour(hour - 12);
          } else if (!isPm && newIsPm && hour < 12) {
            setHour(hour + 12);
          }

          setIsPm(newIsPm);
        }}
      />
    </div>
  );
};

export default TimeInput;
