import React, { ComponentProps } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { mergeClassNames } from "../../libs/components";

interface NavigationLinkProps
  extends Omit<ComponentProps<typeof NavLink>, "className" | "children"> {
  className?: string;
  children: React.ReactNode | React.FC<{ active: boolean }>;
  to: string;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  className = "",
  to,
  children,
  ...props
}) => {
  const match = useMatch({ path: to });
  const active = !!match;

  return (
    <NavLink
      className={({ isActive }) => {
        return mergeClassNames(
          `py-2 px-4 flex items-center font-bold rounded-lg default-transition bg-gradient-to-r hover:text-black hover:from-emerald-200 hover:to-sky-200 focus:text-black focus:from-emerald-200 focus:to-sky-200 ${
            isActive ? "text-purple-500" : ""
          }`,
          className
        );
      }}
      to={to}
      {...props}
    >
      {typeof children === "function" ? children({ active }) : children}
    </NavLink>
  );
};

export default NavigationLink;
