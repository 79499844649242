import {
  RawPostMetricsFacebook,
  RawPostMetricsLinkedIn,
  RawPostMetricsTwitter,
} from "../types/postAnalytics";
import { WorkspaceAnalytics } from "../types/workspaceAnalytics";

export function getPeriodDisplayName(period: WorkspaceAnalytics["period"]) {
  switch (period) {
    case "LAST_7_DAYS":
      return "Last 7 days";
    case "LAST_14_DAYS":
      return "Last 14 days";
    case "LAST_30_DAYS":
      return "Last 30 days";
    case "LAST_60_DAYS":
      return "Last 60 days";
  }
}

export function getLinkedInMetricsForWorkspace() {
  const metrics: Array<keyof RawPostMetricsLinkedIn> = [
    "likeCount",
    "commentCount",
    "clickCount",
    "impressionCount",
    "shareCount",
  ];

  return metrics;
}

export function getFacebookMetricsForWorkspace() {
  const metrics: Array<keyof RawPostMetricsFacebook> = [
    "reactionsTotal",
    "commentCount",
    "impressions",
    "engagedUsers",
  ];

  return metrics;
}

export function getTwitterMetricsForWorkspace() {
  const metrics: Array<keyof RawPostMetricsTwitter> = [
    "likeCount",
    "replyCount",
    "retweetCount",
    "quoteCount",
    "impressionsCount",
    "userProfileClicks",
    "urlLinkClicks",
  ];

  return metrics;
}

export function getMetricsForPlatformType(
  platformType: WorkspaceAnalytics["type"]
) {
  if (platformType === "LINKEDIN") {
    return getLinkedInMetricsForWorkspace();
  } else if (platformType === "FACEBOOK") {
    return getFacebookMetricsForWorkspace();
  } else {
    return getTwitterMetricsForWorkspace();
  }
}
