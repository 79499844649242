import React from "react";
import { animated, useTransition } from "@react-spring/web";

import { useSnackBoxItems } from "../libs/hooks/general";

const AppSnackBox: React.FC = () => {
  const items = useSnackBoxItems();

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0 },
    keys: (item) => item.id,
    enter: (item) => async (next) => {
      await next({
        opacity: 1,
        height: item.ref.current ? item.ref.current.offsetHeight + 8 : 0,
      });
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    config: { tension: 125, friction: 20, precision: 0.1 },
  });

  return (
    <div className="w-72 fixed bottom-4 right-4 z-50 flex flex-col items-center">
      {transitions((style, item) => (
        <animated.div className="w-full" style={style}>
          {item.snackBarElement}
        </animated.div>
      ))}
    </div>
  );
};

export default AppSnackBox;
