import React from "react";
import Joi from "joi";
import { useNavigate } from "react-router-dom";
import { ArrowLeft as ArrowLeftIcon } from "react-feather";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useElements, CardElement } from "@stripe/react-stripe-js";

import { Workspace } from "../../../types/workspaces";
import config from "../../../config";
import {
  useAsyncState,
  useContentStack,
  useSnackBarFactory,
} from "../../../libs/hooks/general";
import {
  useCreatePaymentMethod,
  usePaymentMethods,
} from "../../../libs/hooks/paymentMethods";
import { useCreateSubscription } from "../../../libs/hooks/subscriptions";
import { useCurrentUser } from "../../../libs/hooks/app";

import H2 from "../../../components/headings/H2";
import H3 from "../../../components/headings/H3";
import PrimaryButton from "../../../components/buttons/Primary";
import PaymentMethodSelector, {
  paymentMethodSelectorValueSchema,
  PaymentMethodSelectorValue,
} from "../../../components/form/PaymentMethodSelector";
import CreatedSubscriptionSnackBarContent from "../../../components/snackBarContent/CreatedSubscription ";
import SecondaryButton from "../../../components/buttons/Secondary";

const PLANS = [config.prices.FREE, config.prices.PUBLISH_MONTHLY];

interface FormValues {
  paymentMethod: PaymentMethodSelectorValue;
}

const formSchema = Joi.object({
  paymentMethod: paymentMethodSelectorValueSchema.required(),
});

interface ChangeSubscriptionModalPaymentProps {
  contentId: "payment";
  workspace: Workspace;
  priceId: string;
  close: () => void;
  currentPriceId?: string;
  redirectOnSuccess?: boolean;
}
const ChangeSubscriptionModalPayment: React.FC<
  ChangeSubscriptionModalPaymentProps
> = ({
  workspace,
  priceId,
  close,
  currentPriceId,
  redirectOnSuccess = false,
}) => {
  const { pop } = useContentStack();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const elements = useElements();
  const { data, isLoading: isLoadingPaymentMethods } = usePaymentMethods(
    user.id
  );
  const paymentMethods = data || [];
  const {
    mutateAsync: createPaymentMethod,
    errorAlert: createPaymentMethodErrorAlert,
    clearErrorAlert: clearCreatePaymentMethodErrorAlert,
  } = useCreatePaymentMethod();
  const {
    mutateAsync: createSubscription,
    errorAlert: createSubscriptionErrorAlert,
    clearErrorAlert: clearCreateSubscriptionErrorAlert,
  } = useCreateSubscription();
  const createSnackBar = useSnackBarFactory();
  const {
    loading: isSubmitting,
    setLoading: setIsSubmitting,
    loadingText: submitLoadingText,
  } = useAsyncState();
  const {
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: joiResolver(formSchema, { convert: false }),
    defaultValues: {
      paymentMethod: {
        type: "NEW",
        value: {
          empty: true,
          complete: false,
        },
      },
    },
  });
  const watchPaymentMethod = watch("paymentMethod");
  const hasPaymentMethod = watchPaymentMethod
    ? watchPaymentMethod.type === "NEW"
      ? !watchPaymentMethod.value.empty
      : watchPaymentMethod.value !== null
    : false;
  const normalisedCurrentPriceId = currentPriceId || null;
  const currentIndex = PLANS.findIndex(
    (plan) => plan.id === normalisedCurrentPriceId
  );
  const currentPlan = PLANS[currentIndex];
  const newIndex = PLANS.findIndex((plan) => plan.id === priceId);
  const newPlan = PLANS[newIndex];
  const isUpgrading = currentIndex < newIndex;
  const currentPlanDisplayName = currentPlan
    ? currentPlan.displayName
    : "Unknown";
  const newPlanDisplayName = newPlan ? newPlan.displayName : "Unknown";
  const newPlanDisplayPrice = newPlan
    ? Math.ceil(newPlan.unitAmount / 100)
    : "";
  const newPlanDisplayCurrency = newPlan ? newPlan.currency.toUpperCase() : "";
  const newPlanDisplayPeriod = newPlan
    ? newPlan.billPeriod === "MONTHLY"
      ? "month"
      : "year"
    : "";

  const onSubmit: SubmitHandler<FormValues> = async ({
    paymentMethod: paymentMethodData,
  }) => {
    if (isSubmitting) {
      return;
    }

    if (!hasPaymentMethod) {
      return;
    }

    setIsSubmitting(true);
    clearCreatePaymentMethodErrorAlert();
    clearCreateSubscriptionErrorAlert();

    try {
      let remotePaymentMethodId: string | null = null;

      if (paymentMethodData.type === "NEW" && !paymentMethodData.value.empty) {
        // The user has entered a new card so create the payment method.
        const cardElement = elements ? elements.getElement(CardElement) : null;

        if (!cardElement) {
          return;
        }

        const newPaymentMethod = await createPaymentMethod({ cardElement });
        remotePaymentMethodId = newPaymentMethod.remoteId;
      } else if (paymentMethodData.type === "EXISTING") {
        // The user has selected one of their existing payment methods.
        const paymentMethodId = paymentMethodData.value;
        const paymentMethod = paymentMethods.find(
          (candidate) => candidate.id === paymentMethodId
        );

        if (!paymentMethod) {
          return;
        }

        remotePaymentMethodId = paymentMethod.remoteId;
      }

      const createSubscriptionProps: Parameters<typeof createSubscription>[0] =
        {
          workspaceId: workspace.id,
          remotePriceId: priceId,
          trial: false,
        };

      if (remotePaymentMethodId) {
        createSubscriptionProps.remotePaymentMethodId = remotePaymentMethodId;
      }

      const subscription = await createSubscription(createSubscriptionProps);
      createSnackBar({
        content: (
          <CreatedSubscriptionSnackBarContent
            subscriptionDisplayName={newPlanDisplayName}
          />
        ),
      });

      close();

      if (redirectOnSuccess) {
        navigate(`/subscriptions/${subscription.id}`);
      }
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-8" style={{ width: 600 }}>
      <H2 className="flex-shrink-0 text-2xl text-black w-full">
        {`${newPlanDisplayName} subscription`}
      </H2>
      <p className="mt-4 text-gray-500">
        Choose from one of your existing payment methods or add a new one to use
        for your new subscription.
      </p>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field: { onChange, onBlur, value } }) => (
            <PaymentMethodSelector
              value={value}
              paymentMethods={paymentMethods}
              loading={isLoadingPaymentMethods}
              labelText="Payment method"
              error={!!errors.paymentMethod}
              onChange={onChange}
              onBlur={onBlur}
              onError={(message) => {
                if (message) {
                  setError("paymentMethod", { message });
                } else {
                  clearErrors("paymentMethod");
                }
              }}
            />
          )}
        />
        <H3 className="mt-10 flex-shrink-0 text-2xl text-black w-full">
          Summary
        </H3>
        <p className="mt-2 text-gray-500">
          {`${isUpgrading ? "Upgrading" : "Downgrading"} your `}
          <strong className="text-black">{workspace.name}</strong>
          {" workspace from the "}
          <strong className="text-black">{currentPlanDisplayName}</strong>
          {" subscription to the "}
          <strong className="text-black">{newPlanDisplayName}</strong>
          {" subscription."}
        </p>

        <p className="mt-4 text-gray-500">
          Your first payment is due today and then every month until cancelled.
        </p>

        <hr className="mt-8" />

        <div className="px-2 py-1 flex items-center">
          <div>{`${newPlanDisplayName} subscription`}</div>
          <div className="ml-auto">
            <span className="inline-flex items-center font-bold text-purple-500">
              <span className="text-2xl">$</span>
              <span className="text-4xl">{newPlanDisplayPrice}</span>
            </span>
            <span className="ml-1 text-sm text-gray-500">{`${newPlanDisplayCurrency} per ${newPlanDisplayPeriod}`}</span>
          </div>
        </div>

        <hr />
        {createPaymentMethodErrorAlert({ className: "mt-10" })}
        {createSubscriptionErrorAlert({ className: "mt-10" })}
        <div className="mt-10 flex items-center">
          <SecondaryButton onClick={() => pop()} disabled={isSubmitting}>
            <ArrowLeftIcon className="mr-2" /> Back
          </SecondaryButton>
          <PrimaryButton
            className="ml-auto"
            type="submit"
            disabled={!hasPaymentMethod || isSubmitting}
          >
            {submitLoadingText({
              loading: "Creating subscription",
              default: "Confirm payment",
            })}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default ChangeSubscriptionModalPayment;
