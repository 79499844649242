import React, { ComponentProps } from "react";
import { mergeClassNames } from "../../libs/components";
import ExternalLink from "./External";

const DropdownMenuExternalLink: React.FC<
  ComponentProps<typeof ExternalLink>
> = ({ className = "", ...props }) => {
  return (
    <ExternalLink
      className={mergeClassNames(
        "py-2 px-2 no-underline flex items-center justify-start rounded-lg default-transition w-full text-left font-normal bg-gradient-to-r hover:text-black hover:from-emerald-200 hover:to-sky-200 focus:text-black focus:from-emerald-200 focus:to-sky-200",
        className
      )}
      {...props}
    />
  );
};

export default DropdownMenuExternalLink;
