import React, { Fragment } from "react";

import { mergeClassNames } from "../../libs/components";

interface AnalyticsInteractionsHeatMapLoadingProps {
  className?: string;
}
const AnalyticsInteractionsHeatMapLoading: React.FC<
  AnalyticsInteractionsHeatMapLoadingProps
> = ({ className = "" }) => {
  return (
    <div
      className={mergeClassNames(
        "w-full grid grid-rows-8 grid-cols-13 gap-2",
        className
      )}
    >
      {["Sun", "Sat", "Fri", "Thurs", "Wed", "Tues", "Mon"].map((weekDay) => {
        return (
          <Fragment key={weekDay}>
            <div className="h-6 flex items-center justify-end text-xs text-gray-500">
              {weekDay}
            </div>
            {[...new Array(12)].map((_, index) => {
              return (
                <div
                  key={index}
                  className="rounded animate-pulse bg-slate-200"
                ></div>
              );
            })}
          </Fragment>
        );
      })}
      <div></div>
      {[
        "12am",
        "2am",
        "4am",
        "6am",
        "8am",
        "10am",
        "12pm",
        "2pm",
        "4pm",
        "6pm",
        "8pm",
        "10pm",
      ].map((label) => {
        return (
          <div key={label} className="text-center text-xs text-gray-500">
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default AnalyticsInteractionsHeatMapLoading;
