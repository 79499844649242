import React from "react";
import { Smile as FunnySmileIcon } from "react-feather";
import { HelpCircle as HelpCircleIcon, Smile } from "react-feather";
import {
  ThumbUpIcon,
  CakeIcon,
  HeartIcon,
  LightBulbIcon,
  SupportIcon,
} from "@heroicons/react/outline";

import { mergeClassNames } from "../../libs/components";
import { PublishedPostActionLinkedInReaction } from "../../types/publishedPostActions";

interface PublishedPostActionIconProps {
  reactionType: PublishedPostActionLinkedInReaction["reactionType"];
  className?: string;
}
const PublishedPostActionLinkedInReactionIcon: React.FC<
  PublishedPostActionIconProps
> = ({ reactionType, className = "" }) => {
  switch (reactionType) {
    case "LIKE":
      return (
        <ThumbUpIcon
          className={mergeClassNames("h-6 w-6", className)}
          style={{ transform: "scale(-1,1)" }}
        />
      );
    case "PRAISE":
      return <CakeIcon className={mergeClassNames("h-6 w-6", className)} />;
    case "ENTERTAINMENT":
      return <Smile className={mergeClassNames("h-6 w-6", className)} />;
    case "EMPATHY":
      return <HeartIcon className={mergeClassNames("h-6 w-6", className)} />;
    case "INTEREST":
      return (
        <LightBulbIcon className={mergeClassNames("h-6 w-6", className)} />
      );
    case "APPRECIATION":
      return <SupportIcon className={mergeClassNames("h-6 w-6", className)} />;
  }
};

export default PublishedPostActionLinkedInReactionIcon;
