import React, { ReactNode, useState } from "react";

import {
  useHideModal,
  useIsModalVisible,
  useModalData,
} from "../libs/hooks/app";
import { WorkspaceAppModals } from "../contexts/app/Workspace";

import { ModalProps } from "../components/Modal";

import AddPlatformModal from "./modals/AddPlatform";
import AddWorkspaceModal from "./modals/AddWorkspace";
import UpdateWorkspaceModal from "./modals/UpdateWorkspace";
import CreatePostModal from "./modals/CreatePost";
import DeletePlatformModal from "./modals/DeletePlatform";
import ViewInternalFileModal from "./modals/ViewInternalFile";
import DeletePostModal from "./modals/DeletePost";
import DeleteUserAccountModal from "./modals/DeleteUserAccount";
import DeleteSubscriptionModal from "./modals/DeleteSubscription";
import DeletePaymentMethodModal from "./modals/DeletePaymentMethod";
import EditPostModal from "./modals/EditPost";
import ViewPostModal from "./modals/ViewPost";
import VerifyEmailAddressModal from "./modals/VerifyEmailAddress";
import ChangePasswordModal from "./modals/ChangePassword";
import ChangeSubscriptionModal from "./modals/ChangeSubscription";
import ExplainSmartSchedulesModal from "./modals/ExplainSmartSchedules";
import ExplainSmartSchedulesStateModal from "./modals/ExplainSmartSchedulesState";
import ConfirmCloseModal from "./modals/ConfirmClose";
import MultiPostPublishedPostActions from "./modals/MultiPostPublishedPostActions";
import EditPublishedPostActionsModal from "./modals/EditPublishedPostActions";
import ViewPublishedPostActionsModal from "./modals/ViewPublishedPostActions";

function useModalRenderer<T extends keyof WorkspaceAppModals>(
  modalName: T
): (
  fn: (
    modalProps: {
      isVisible: ModalProps["isVisible"];
      close: NonNullable<ModalProps["close"]>;
      onStatusChange: ModalProps["onStatusChange"];
    },
    modalData: NonNullable<WorkspaceAppModals[T]>
  ) => ReactNode
) => ReactNode {
  const hideModal = useHideModal();
  const isModalVisible = useIsModalVisible();
  const [status, setStatus] =
    useState<Parameters<NonNullable<ModalProps["onStatusChange"]>>[0]>(
      "closed"
    );
  const isVisible = isModalVisible(modalName);
  const modalData = useModalData(modalName);

  return (buildModalFn) => {
    return isVisible || status !== "closed"
      ? buildModalFn(
          {
            isVisible,
            close: () => hideModal(modalName),
            onStatusChange: setStatus,
          },
          modalData!
        )
      : null;
  };
}

function useAddPlatformModal() {
  const render = useModalRenderer("addPlatform");

  return render((modalProps, modalData) => (
    <AddPlatformModal
      defaultContentId={modalData.defaultContentId}
      {...modalProps}
    />
  ));
}

function useCreatePostModal() {
  const render = useModalRenderer("createPost");

  return render((modalProps, modalData) => (
    <CreatePostModal
      defaultValues={modalData.defaultValues}
      forceLoading={modalData.forceLoading}
      {...modalProps}
    />
  ));
}

function useAddWorkspaceModal() {
  const render = useModalRenderer("addWorkspace");

  return render((modalProps) => <AddWorkspaceModal {...modalProps} />);
}

function useUpdateWorkspaceModal() {
  const render = useModalRenderer("updateWorkspace");

  return render((modalProps, modalData) => (
    <UpdateWorkspaceModal workspace={modalData.workspace} {...modalProps} />
  ));
}

function useVerifyEmailAddressModal() {
  const render = useModalRenderer("verifyEmailAddress");

  return render((modalProps) => <VerifyEmailAddressModal {...modalProps} />);
}

function useChangePasswordModal() {
  const render = useModalRenderer("changePassword");

  return render((modalProps) => <ChangePasswordModal {...modalProps} />);
}

function useDeleteUserAccountModal() {
  const render = useModalRenderer("deleteUserAccount");

  return render((modalProps) => <DeleteUserAccountModal {...modalProps} />);
}

function useExplainSmartSchedulesModal() {
  const render = useModalRenderer("explainSmartSchedules");

  return render((modalProps) => <ExplainSmartSchedulesModal {...modalProps} />);
}

function useChangeSubscriptionModal() {
  const render = useModalRenderer("changeSubscription");

  return render((modalProps, modalData) => (
    <ChangeSubscriptionModal
      redirectOnSuccess={modalData.redirectOnSuccess}
      workspace={modalData.workspace}
      currentPriceId={modalData.currentPriceId}
      defaultPriceId={modalData.defaultPriceId}
      limitExceeded={modalData.limitExceeded}
      {...modalProps}
    />
  ));
}

function useDeletePlatformModal() {
  const render = useModalRenderer("deletePlatform");

  return render((modalProps, modalData) => (
    <DeletePlatformModal platform={modalData.platform} {...modalProps} />
  ));
}

function useViewInternalFileModal() {
  const render = useModalRenderer("viewInternalFile");

  return render((modalProps, modalData) => (
    <ViewInternalFileModal file={modalData.file} {...modalProps} />
  ));
}

function useDeletePostModal() {
  const render = useModalRenderer("deletePost");

  return render((modalProps, modalData) => (
    <DeletePostModal
      post={modalData.post}
      platform={modalData.platform}
      {...modalProps}
    />
  ));
}

function useEditPostModal() {
  const render = useModalRenderer("editPost");

  return render((modalProps, modalData) => (
    <EditPostModal
      post={modalData.post}
      platform={modalData.platform}
      {...modalProps}
    />
  ));
}

function useViewPostModal() {
  const render = useModalRenderer("viewPost");

  return render((modalProps, modalData) => (
    <ViewPostModal
      post={modalData.post}
      platform={modalData.platform}
      {...modalProps}
    />
  ));
}

function useDeleteSubscriptionModal() {
  const render = useModalRenderer("deleteSubscription");

  return render((modalProps, modalData) => (
    <DeleteSubscriptionModal
      subscription={modalData.subscription}
      {...modalProps}
    />
  ));
}

function useDeletePaymentMethodModal() {
  const render = useModalRenderer("deletePaymentMethod");

  return render((modalProps, modalData) => (
    <DeletePaymentMethodModal
      paymentMethod={modalData.paymentMethod}
      workspaces={modalData.workspaces}
      {...modalProps}
    />
  ));
}

function useExplainSmartSchedulesStateModal() {
  const render = useModalRenderer("explainSmartSchedulesState");

  return render((modalProps, modalData) => (
    <ExplainSmartSchedulesStateModal
      platform={modalData.platform}
      platformEntity={modalData.platformEntity}
      postQueue={modalData.postQueue}
      {...modalProps}
    />
  ));
}

function useConfirmCloseModal() {
  const render = useModalRenderer("confirmClose");

  return render((modalProps, modalData) => (
    <ConfirmCloseModal onConfirm={modalData.onConfirm} {...modalProps} />
  ));
}

function usePublishedPostActionsModal() {
  const render = useModalRenderer("multiPostPublishedPostActions");

  return render((modalProps, modalData) => (
    <MultiPostPublishedPostActions posts={modalData.posts} {...modalProps} />
  ));
}

function useEditPublishedPostActionsModal() {
  const render = useModalRenderer("editPublishedPostActions");

  return render((modalProps, modalData) => (
    <EditPublishedPostActionsModal post={modalData.post} {...modalProps} />
  ));
}

function useViewPublishedPostActionsModal() {
  const render = useModalRenderer("viewPublishedPostActions");

  return render((modalProps, modalData) => (
    <ViewPublishedPostActionsModal post={modalData.post} {...modalProps} />
  ));
}

const AppModals: React.FC = () => {
  const addPlatformModal = useAddPlatformModal();
  const createPostModal = useCreatePostModal();
  const addWorkspaceModal = useAddWorkspaceModal();
  const updateWorkspace = useUpdateWorkspaceModal();
  const verifyEmailAddressModal = useVerifyEmailAddressModal();
  const changePasswordModal = useChangePasswordModal();
  const deleteUserAccountModal = useDeleteUserAccountModal();
  const changeSubscriptionModal = useChangeSubscriptionModal();
  const deletePlatformModal = useDeletePlatformModal();
  const viewInternalFileModal = useViewInternalFileModal();
  const deletePostModal = useDeletePostModal();
  const editPostModal = useEditPostModal();
  const viewPostModal = useViewPostModal();
  const deleteSubscriptionModal = useDeleteSubscriptionModal();
  const deletePaymentMethodModal = useDeletePaymentMethodModal();
  const explainSmartSchedulesModal = useExplainSmartSchedulesModal();
  const explainSmartSchedulesStateModal = useExplainSmartSchedulesStateModal();
  const confirmCloseModal = useConfirmCloseModal();
  const publishedPostActionsModal = usePublishedPostActionsModal();
  const editPublishedPostActionsModal = useEditPublishedPostActionsModal();
  const viewPublishedPostActionsModal = useViewPublishedPostActionsModal();

  return (
    <>
      {addPlatformModal}
      {createPostModal}
      {addWorkspaceModal}
      {updateWorkspace}
      {verifyEmailAddressModal}
      {changePasswordModal}
      {deleteUserAccountModal}
      {changeSubscriptionModal}
      {deletePlatformModal}
      {viewInternalFileModal}
      {deletePostModal}
      {editPostModal}
      {viewPostModal}
      {deleteSubscriptionModal}
      {deletePaymentMethodModal}
      {explainSmartSchedulesModal}
      {explainSmartSchedulesStateModal}
      {confirmCloseModal}
      {publishedPostActionsModal}
      {editPublishedPostActionsModal}
      {viewPublishedPostActionsModal}
    </>
  );
};

export default AppModals;
