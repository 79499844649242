import React, { useMemo } from "react";

import {
  PostAnalytics,
  PostAnalyticsCommentSentiment,
} from "../../types/postAnalytics";

import { mergeClassNames } from "../../libs/components";
import { formatCountForDisplay } from "../../libs/postMetrics";

import { ReactComponent as NoCommentImage } from "../../images/personal-opinions.svg";

type SentimentAnalysisDatum = {
  sentiment: PostAnalyticsCommentSentiment;
  count: number;
  percent: number;
  displayText: string;
};

function getDisplayNameForSentiment(sentiment: PostAnalyticsCommentSentiment) {
  switch (sentiment) {
    case "POSITIVE":
      return "Positive";
    case "NEGATIVE":
      return "Negative";
    case "MIXED":
      return "Mixed";
    case "NEUTRAL":
      return "Neutral";
  }
}

function getSentimentSortValue(sentiment: PostAnalyticsCommentSentiment) {
  switch (sentiment) {
    case "POSITIVE":
      return 0;
    case "NEGATIVE":
      return 2;
    case "MIXED":
      return 1;
    case "NEUTRAL":
      return 3;
  }
}

function getSentimentBackgroundColourClassName(
  sentiment: PostAnalyticsCommentSentiment
) {
  switch (sentiment) {
    case "POSITIVE":
      return "bg-green-300";
    case "NEGATIVE":
      return "bg-red-300";
    case "MIXED":
      return "bg-amber-300";
    case "NEUTRAL":
      return "bg-blue-300";
  }
}

interface AnalyticsCommentsSentimentProps {
  comments: PostAnalytics["comments"];
  className?: string;
}
const AnalyticsCommentsSentiment: React.FC<AnalyticsCommentsSentimentProps> = ({
  comments,
  className = "",
}) => {
  const data = useMemo<SentimentAnalysisDatum[]>(() => {
    if (!comments) {
      return [];
    }

    const totalCount = comments.count;
    const sentiments: SentimentAnalysisDatum[] = [];
    let remainingPercent = 100;

    for (const [sentiment, count] of Object.entries(comments.sentiment)) {
      const truePercent = Math.round((count / totalCount) * 100);
      const percent = Math.min(truePercent, remainingPercent);

      remainingPercent = Math.max(0, remainingPercent - percent);

      sentiments.push({
        sentiment: sentiment as PostAnalyticsCommentSentiment,
        count,
        percent,
        displayText: `${formatCountForDisplay(
          count
        )} ${getDisplayNameForSentiment(
          sentiment as PostAnalyticsCommentSentiment
        )}`,
      });
    }

    sentiments.sort((a, b) => {
      return (
        getSentimentSortValue(a.sentiment) - getSentimentSortValue(b.sentiment)
      );
    });

    return sentiments;
  }, [comments]);

  if (!comments) {
    return (
      <div className={mergeClassNames("flex flex-col items-center", className)}>
        <NoCommentImage className="h-60 w-60 text-purple-500" />
        <p>Looks like this content hasn't received any comments yet!</p>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="w-full h-10 rounded overflow-hidden">
        {data.map(({ sentiment, percent }) => {
          return (
            <div
              key={sentiment}
              className={`inline-block h-full ${getSentimentBackgroundColourClassName(
                sentiment
              )}`}
              style={{ width: `${percent}%` }}
            ></div>
          );
        })}
      </div>
      <div className="mt-2 w-full flex items-center justify-around">
        {data.map(({ sentiment, displayText }) => {
          return (
            <div key={sentiment} className="flex items-center h-full">
              <div
                className={`h-6 w-6 rounded-full ${getSentimentBackgroundColourClassName(
                  sentiment
                )}`}
              ></div>
              <span className="ml-2">{displayText}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnalyticsCommentsSentiment;
