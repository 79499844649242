import React, { forwardRef } from "react";
import { analyticsLogEvent } from "../../libs/amplitudeAnalytics";

import { mergeClassNames } from "../../libs/components";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: "xs" | "sm";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className = "",
      type = "button",
      disabled,
      onClick,
      size,
      ...props
    },
    ref
  ) => {
    let sizeClasses = "py-3 px-4";

    switch (size) {
      case "xs":
        sizeClasses = "px-2 py-1 text-xs";
        break;
      case "sm":
        sizeClasses = "py-1 px-2 text-sm";
        break;
    }

    let classes = mergeClassNames(
      `flex items-center justify-center rounded-lg font-bold transition-colors ease-in-out duration-200 active:animate-press ${sizeClasses} ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      }`,
      className
    );

    if (disabled) {
      classes = classes.replaceAll(/hover:.*? /g, "");
      classes = classes.replaceAll(/focus:.*? /g, "");
    }

    const internalOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      if (onClick) {
        onClick(e);
      }

      const lastElement = React.Children.toArray(children).pop();
      const text = typeof lastElement === "string" ? lastElement : "";
      const { title, id } = props;
      analyticsLogEvent({ event: "buttonPress", text, title, id });
    };

    return (
      <button
        ref={ref as any}
        type={type}
        className={classes}
        disabled={disabled}
        onClick={internalOnClick}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default Button;
