import React from "react";
import { UploadCloud as UploadCloudIcon } from "react-feather";
import { useUpload } from "../../libs/hooks/uploads";

interface UploadFileSnackBarContentProps {
  fileId: string;
  fileName: string;
}

const UploadFileSnackBarContent: React.FC<UploadFileSnackBarContentProps> = ({
  fileId,
  fileName,
}) => {
  const upload = useUpload(fileId);
  const uploadPercent = upload ? Math.floor(upload.percent) : 100;

  return (
    <div className="w-full flex items-center overflow-hidden">
      <UploadCloudIcon className="shrink-0" />
      <span className="ml-2 truncate">{fileName}</span>
      <strong className="shrink-0 ml-2 text-purple-300">{`${uploadPercent}%`}</strong>
    </div>
  );
};

export default UploadFileSnackBarContent;
