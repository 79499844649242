import React from "react";
import { mergeClassNames } from "../libs/components";

interface DividerProps {
  direction: "vertical" | "horizontal";
  size?: number;
  sizeUnit?: "px" | "rem" | "%";
  className?: string;
}
const Divider: React.FC<DividerProps> = ({
  direction,
  size = 100,
  sizeUnit = "%",
  className = "",
}) => {
  return (
    <div
      className={mergeClassNames("bg-gray-300 mx-3", className)}
      style={
        direction === "vertical"
          ? { width: 1, height: `${size}${sizeUnit}` }
          : { width: `${size}${sizeUnit}`, height: 1 }
      }
    ></div>
  );
};

export default Divider;
