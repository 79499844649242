import React, { ComponentProps, ReactNode, useMemo, useState } from "react";
import { ExternalLink as ExternalLinkIcon } from "react-feather";
import { AnnotationIcon } from "@heroicons/react/outline";

import { Post } from "../../types/posts";

import { useActiveWorkspace } from "../../libs/hooks/app";
import { usePlatforms } from "../../libs/hooks/platforms";
import { usePublishedPostActionsForPost } from "../../libs/hooks/publishedPostActions";

import { ReactComponent as ErrorImage } from "../../images/access-denied.svg";
import { ReactComponent as PublishedPostActionImage } from "../../images/published-post-action.svg";

import Modal from "../../components/Modal";
import ContentSwitchContainer from "../../components/animations/contentSwitch/Container";
import ContentSwitchContent from "../../components/animations/contentSwitch/Content";
import LoaderIcon from "../../components/icons/Loader";
import H2 from "../../components/headings/H2";
import PublishedPostActionIcon from "../../components/icons/PublishedPostAction";
import DangerBadge from "../../components/badges/Danger";
import SuccessBadge from "../../components/badges/Success";
import HighlightBadge from "../../components/badges/Highlight";
import PlatformEntityProfilePicture from "../../components/images/profile/PlatformEntity";
import PublishedPostActionLinkedInReactionIcon from "../../components/icons/PublishedPostActionLinkedInReaction";
import {
  getDisplayNameForLinkedInReactionType,
  getDisplayNameForPublishedPostActionInstance,
} from "../../libs/publishedPostActions";
import PostPreview from "../../components/posts/Preview";
import H3 from "../../components/headings/H3";
import PlatformIcon from "../../components/icons/Platform";
import ExternalLink from "../../components/links/External";
import { getPlatformTypeName } from "../../libs/platform";
import PublishedPostActionInstanceIcon from "../../components/icons/PublishedPostActionInstance";
import TertiaryButton from "../../components/buttons/Tertiary";

interface ViewPublishedPostActionsModalProps
  extends Omit<ComponentProps<typeof Modal>, "children"> {
  post: Post;
  close: () => void;
}

const ViewPublishedPostActionsModal: React.FC<
  ViewPublishedPostActionsModalProps
> = ({ isVisible, close, post, ...modalProps }) => {
  const [expandedActionValues, setExpandedActionValues] = useState<{
    [actionId: string]: boolean;
  }>({});
  const workspace = useActiveWorkspace();
  const {
    isLoading: isLoadingPlatforms,
    isError: isErrorPlatforms,
    data: platformsData,
  } = usePlatforms(workspace.id);
  const {
    isLoading: isLoadingActions,
    isError: isErrorActions,
    data: actionsData,
  } = usePublishedPostActionsForPost(post.id);

  const isLoading = isLoadingPlatforms || isLoadingActions;
  const isError = isErrorPlatforms || isErrorActions;
  const actions = useMemo(() => {
    const newActions = actionsData || [];
    newActions.sort((a, b) => {
      const aDelay = a.useRandomDelay ? 1 : 0 - (a.delay || 0);
      const bDelay = a.useRandomDelay ? 1 : 0 - (b.delay || 0);

      if (aDelay < bDelay) {
        return 1;
      } else if (aDelay > bDelay) {
        return -1;
      } else {
        return 0;
      }
    });
    return newActions;
  }, [actionsData]);
  const platforms = platformsData || [];
  const hasActions = actions.length > 0;
  const postPlatform = platforms.find(
    (candidate) => candidate.id === post.platformId
  );
  const postPlatformEntity = postPlatform
    ? postPlatform.entities[post.platformEntityId]
    : undefined;

  return (
    <Modal {...modalProps} isVisible={isVisible} close={close}>
      <ContentSwitchContainer
        contentId={
          isLoading
            ? "loading"
            : isError
            ? "error"
            : hasActions
            ? "actions"
            : "empty"
        }
      >
        <ContentSwitchContent contentId="loading">
          <div
            className="p-2 sm:p-8 flex items-center justify-center"
            style={{ width: "34rem", height: "28rem" }}
          >
            <div className="flex items-center">
              <LoaderIcon className="h-10 w-10" />
              <span className="ml-2 text-lg">Loading actions...</span>
            </div>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="error">
          <div
            className="p-2 sm:p-8 flex items-center justify-center"
            style={{ width: "34rem" }}
          >
            <div className="flex flex-col items-center">
              <ErrorImage className="h-80 text-red-500" />
              <span className="mt-4 text-lg text-red-500">
                Unable to load actions. Please reload the page and try again.
              </span>
            </div>
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="empty">
          <div
            className="p-2 sm:p-8 flex flex-col items-center"
            style={{ width: "34rem" }}
          >
            <H2 className="w-full flex items-center text-2xl text-black">
              <PublishedPostActionIcon className="h-6 w-6" />
              <span className="ml-2">After publish actions</span>
            </H2>
            <p className="mt-4 w-full">
              This post did not have any after publish actions. Nothing to see
              here.
            </p>
            <PublishedPostActionImage
              className="mt-12 flex-shrink-0 text-purple-500"
              style={{ width: 250, height: 250 }}
            />
          </div>
        </ContentSwitchContent>
        <ContentSwitchContent contentId="actions">
          <div className="p-2 sm:p-8">
            <H2 className="w-full flex items-center text-2xl text-black">
              <PublishedPostActionIcon className="h-6 w-6" />
              <span className="ml-2">After publish actions</span>
            </H2>
            <div className="mt-8 flex items-center text-xs text-gray-300 font-bold">
              <div className="w-28">STATUS</div>
              <div className="ml-2 w-64">WHO</div>
              <div className="ml-2 w-40">WHEN</div>
              <div className="ml-2 w-40">WHAT</div>
              <div className="ml-2">WITH</div>
            </div>

            {actions.map((action) => {
              const platform = platforms.find(
                (candidate) => candidate.id === action.platformId
              );
              const platformEntity = platform
                ? platform.entities[action.platformEntityId]
                : undefined;

              const delay = action.useRandomDelay ? null : action.delay || 0;
              let delayText = "";

              if (delay === null) {
                delayText = "Within 15 minutes";
              } else if (delay === 0) {
                delayText = "Immediately";
              } else {
                delayText = delay === 1 ? "1 minute" : `${delay} minutes`;
              }

              let actionValue: ReactNode = (
                <span className="text-sm text-gray-300">n/a</span>
              );

              switch (action.type) {
                case "COMMENT":
                  actionValue = (
                    <div>
                      <TertiaryButton
                        className="flex item-center"
                        size="sm"
                        onClick={() => {
                          setExpandedActionValues({
                            ...expandedActionValues,
                            [action.id]: !expandedActionValues[action.id],
                          });
                        }}
                      >
                        <AnnotationIcon className="h-6 w-6" />
                        <span className="ml-1">{`${
                          expandedActionValues[action.id] ? "Hide" : "Show"
                        } comment text`}</span>
                      </TertiaryButton>
                      {!!expandedActionValues[action.id] && (
                        <pre
                          className="mt-1 p-2 w-96 border border-gray-100 rounded-lg text-xs bg-gray-50 whitespace-pre-wrap overflow-x-hidden overflow-y-auto"
                          style={{ maxHeight: "5rem" }}
                        >
                          {action.text.plain}
                        </pre>
                      )}
                    </div>
                  );
                  break;
                case "REACTION":
                  actionValue = (
                    <div className="flex items-center">
                      <PublishedPostActionLinkedInReactionIcon
                        reactionType={action.reactionType}
                      />
                      <span className="ml-1">
                        {getDisplayNameForLinkedInReactionType(
                          action.reactionType
                        )}
                      </span>
                    </div>
                  );
                  break;
                case "REPLY":
                  actionValue = (
                    <div>
                      <TertiaryButton
                        className="flex item-center"
                        size="sm"
                        onClick={() => {
                          setExpandedActionValues({
                            ...expandedActionValues,
                            [action.id]: !expandedActionValues[action.id],
                          });
                        }}
                      >
                        <AnnotationIcon className="h-6 w-6" />
                        <span className="ml-1">{`${
                          expandedActionValues[action.id] ? "Hide" : "Show"
                        } reply text`}</span>
                      </TertiaryButton>
                      {!!expandedActionValues[action.id] && (
                        <pre
                          className="mt-1 p-2 w-96 border border-gray-100 rounded-lg text-xs bg-gray-50 whitespace-pre-wrap overflow-x-hidden overflow-y-auto"
                          style={{ maxHeight: "5rem" }}
                        >
                          {action.text.plain}
                        </pre>
                      )}
                    </div>
                  );
                  break;
                case "RESHARE":
                  if (action.text) {
                    actionValue = (
                      <div>
                        <TertiaryButton
                          className="flex item-center"
                          size="sm"
                          onClick={() => {
                            setExpandedActionValues({
                              ...expandedActionValues,
                              [action.id]: !expandedActionValues[action.id],
                            });
                          }}
                        >
                          <AnnotationIcon className="h-6 w-6" />
                          <span className="ml-1">{`${
                            expandedActionValues[action.id] ? "Hide" : "Show"
                          } reshare text`}</span>
                        </TertiaryButton>
                        {!!expandedActionValues[action.id] && (
                          <pre
                            className="mt-1 p-2 w-96 border border-gray-100 rounded-lg text-xs bg-gray-50 whitespace-pre-wrap overflow-x-hidden overflow-y-auto"
                            style={{ maxHeight: "5rem" }}
                          >
                            {action.text.plain}
                          </pre>
                        )}
                      </div>
                    );
                  } else {
                    actionValue = (
                      <span>
                        Reshared <strong>wouthout</strong> text
                      </span>
                    );
                  }
                  break;
              }

              return (
                <div key={action.id} className="mt-4 flex items-center">
                  <div className="w-28">
                    {action.status === "ERROR" && (
                      <DangerBadge>{action.status}</DangerBadge>
                    )}
                    {action.status === "SUCCESS" && (
                      <SuccessBadge>{action.status}</SuccessBadge>
                    )}
                    {action.status !== "ERROR" &&
                      action.status !== "SUCCESS" && (
                        <HighlightBadge>{action.status}</HighlightBadge>
                      )}
                  </div>
                  <div className="ml-2 w-64 flex items-center">
                    {platformEntity && (
                      <PlatformEntityProfilePicture
                        entity={platformEntity}
                        platformType={post.type}
                      />
                    )}
                    {platformEntity && (
                      <div className="ml-2 truncate">
                        <div className="truncate font-bold">
                          {platformEntity.name}
                        </div>
                        {!!platformEntity.username && (
                          <div className="truncate text-gray-500 text-xs">{`@${platformEntity.username}`}</div>
                        )}
                      </div>
                    )}
                    {!platformEntity && (
                      <span className="font-bold">Unknown social account</span>
                    )}
                  </div>
                  <div className="ml-2 w-40">{delayText}</div>
                  <div className="ml-2 w-40 flex items-center">
                    <PublishedPostActionInstanceIcon
                      platformType={action.platformType}
                      type={action.type}
                    />
                    <span className="ml-1">
                      {getDisplayNameForPublishedPostActionInstance(
                        action.platformType,
                        action.type
                      )}
                    </span>
                  </div>
                  <div className="ml-2">{actionValue}</div>
                </div>
              );
            })}
            {postPlatformEntity && (
              <div className="mt-12 flex justify-center">
                <div>
                  <div className="px-4 flex items-center text-gray-400">
                    <H3 className="flex items-center text-xl">
                      <PlatformIcon type={post.type} />
                      <span className="ml-1">Post</span>
                    </H3>
                    {post.externalUrl && (
                      <ExternalLink
                        className="ml-auto flex items-center"
                        href={post.externalUrl}
                      >
                        <ExternalLinkIcon className="h-4 w-4" />
                        <span className="ml-2">
                          View on {getPlatformTypeName(post.type)}
                        </span>
                      </ExternalLink>
                    )}
                  </div>
                  <PostPreview
                    className="mt-2"
                    post={post}
                    platformEntity={postPlatformEntity}
                  />
                </div>
              </div>
            )}
          </div>
        </ContentSwitchContent>
      </ContentSwitchContainer>
    </Modal>
  );
};

export default ViewPublishedPostActionsModal;
