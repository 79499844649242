import React, { useEffect, useRef } from "react";
import { useSnackBarFactory } from "../libs/hooks/general";
import { useUploads } from "../libs/hooks/uploads";

import UploadFileSnackBarContent from "../components/snackBarContent/UploadFile";

const UploadsMonitor: React.FC = () => {
  const uploadsByFileId = useUploads();
  const monitoredUploadsRef = useRef<{ [fileId: string]: true }>({});
  const createSnackBar = useSnackBarFactory();

  useEffect(() => {
    const uploadFileIds = Object.keys(uploadsByFileId);
    const newUploadFileIds = uploadFileIds.filter(
      (fileId) => !monitoredUploadsRef.current[fileId]
    );

    monitoredUploadsRef.current = uploadFileIds.reduce<{
      [fileId: string]: true;
    }>((carry, fileId) => {
      carry[fileId] = true;
      return carry;
    }, {});

    newUploadFileIds.forEach((fileId) => {
      const upload = uploadsByFileId[fileId];

      createSnackBar({
        content: (
          <UploadFileSnackBarContent
            fileId={fileId}
            fileName={upload.internalFile.name}
          />
        ),
        userDismissable: false,
        dismissTrigger: upload.promise,
      });
    });
  }, [createSnackBar, uploadsByFileId]);

  return null;
};

export default UploadsMonitor;
