import twitter from "twitter-text";
import { ContentState } from "draft-js";
import Joi from "joi";

export const CHARACTER_LIMIT = {
  LINKEDIN: 3000,
  TWITTER: 280,
} as const;

export const baseContentStateSchema = Joi.any();

export const linkedinContentStateSchema = baseContentStateSchema.custom(
  (value: ContentState) => {
    const text = value.getPlainText() || "";
    if (text.length > CHARACTER_LIMIT.LINKEDIN) {
      throw new Error(
        `LinkedIn posts can not have more than ${CHARACTER_LIMIT.LINKEDIN} characters`
      );
    }

    return value;
  }
);

export const twitterContentStateSchema = baseContentStateSchema.custom(
  (value: ContentState) => {
    const text = value.getPlainText() || "";
    const { weightedLength } = twitter.parseTweet(text);
    if (weightedLength > CHARACTER_LIMIT.TWITTER) {
      throw new Error(
        `Tweets can not have more than ${CHARACTER_LIMIT.TWITTER} characters`
      );
    }

    return value;
  }
);
