import React, { DetailedHTMLProps, AnchorHTMLAttributes } from "react";
import { mergeClassNames } from "../../libs/components";

const ExternalLink: React.FC<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({
  className = "",
  target = "_blank",
  rel = "noopener noreferrer",
  children,
  ...props
}) => {
  return (
    <a
      className={mergeClassNames(
        "font-bold underline default-transition hover:text-purple-600 focus:text-purple-600",
        className
      )}
      target={target}
      rel={rel}
      {...props}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
