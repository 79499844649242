import React, { useEffect, useMemo, useRef, useState } from "react";
import { animated, useSprings, useTransition } from "@react-spring/web";
import {
  Plus as PlusIcon,
  Filter as FilterIcon,
  ChevronDown as ChevronDownIcon,
  HelpCircle as HelpCircleIcon,
} from "react-feather";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

import { Platform, PlatformEntity } from "../types/platforms";
import { PostQueue } from "../types/postQueues";
import { Post } from "../types/posts";

import { getPlatformTypeName } from "../libs/platform";
import { clamp } from "../libs/utils";
import {
  getAvailablePublishQueuedPosts,
  hasPublishQeueuedPostLimit,
} from "../libs/subscriptions";
import { useDragDrop, useHasChanged } from "../libs/hooks/general";
import {
  useActiveWorkspace,
  useCurrentUser,
  useNow,
  useShowModal,
} from "../libs/hooks/app";
import { usePlatforms } from "../libs/hooks/platforms";
import { usePostQueues } from "../libs/hooks/postQueues";
import { useQueuedPosts } from "../libs/hooks/posts";
import {
  useUpdateWorkspaceUserPreferences,
  useWorkspaceUserPreferences,
} from "../libs/hooks/userPreferences";

import AppPageWithHeader from "../containers/AppPageWithHeader";
import Queue, { QUEUE_DRAG_TYPE } from "../containers/smartSchedules/Queue";
import UpgradeSubscriptionButton from "../containers/buttons/UpgradeSubscription";

import PrimaryButton from "../components/buttons/Primary";
import Select from "../components/form/Select";
import DragAutoScroll from "../components/DragAutoScroll";
import SecondaryButton from "../components/buttons/Secondary";
import PageActionButton from "../components/buttons/PageAction";
import Tooltip from "../components/Tooltip";
import H2 from "../components/headings/H2";
import InternalLink from "../components/links/Internal";

import { ReactComponent as NoPostQueuesImage } from "../images/status-update.svg";

const FILTER_OPTIONS = [null, "FACEBOOK", "TWITTER", "LINKEDIN"] as const;
const QUEUE_PLATFORM_TYPE_ORDER = ["LINKEDIN", "TWITTER", "FACEBOOK"] as const;
const QUEUE_WIDTH_PX = 384;
const QUEUE_GUTTER_WIDTH_PX = 16;
const DEFAULT_POST_QUEUES_REFETCH_INTERVAL = 500; // Milliseconds.

interface QueueData {
  platform: Platform;
  platformEntity: PlatformEntity;
  postQueue: PostQueue;
  posts: Post[];
}

function calculateQueueXPos(index: number) {
  return (
    QUEUE_GUTTER_WIDTH_PX + (QUEUE_GUTTER_WIDTH_PX + QUEUE_WIDTH_PX) * index
  );
}

function buildSortQueueDataFunction(postQueuesOrder: string[]) {
  return (a: QueueData, b: QueueData) => {
    const aIndex = postQueuesOrder.indexOf(a.postQueue.id);
    const bIndex = postQueuesOrder.indexOf(b.postQueue.id);

    if (aIndex >= 0 && bIndex >= 0) {
      return aIndex - bIndex;
    } else if (aIndex >= 0 && bIndex < 0) {
      return -1;
    } else if (aIndex < 0 && bIndex >= 0) {
      return 1;
    } else {
      const aTypeIndex = QUEUE_PLATFORM_TYPE_ORDER.indexOf(a.platform.type);
      const bTypeIndex = QUEUE_PLATFORM_TYPE_ORDER.indexOf(b.platform.type);

      if (aTypeIndex !== bTypeIndex) {
        return aTypeIndex - bTypeIndex;
      } else {
        if (a.platformEntity.name < b.platformEntity.name) {
          return -1;
        } else if (a.platformEntity.name > b.platformEntity.name) {
          return 1;
        }
      }
    }

    return 0;
  };
}

function buildQueueSpringsFunction(
  springIndexToQueueId: string[],
  allQueueIdOrder: string[],
  visibleQueueIdOrder: string[],
  draggedQueueId?: string,
  draggedStartPosition?: number,
  dragging?: boolean,
  immediate?: boolean
): any {
  return (springIndex: number) => {
    const queueId = springIndexToQueueId[springIndex];
    const allPosition = allQueueIdOrder.indexOf(queueId);
    const visiblePosition = visibleQueueIdOrder.indexOf(queueId);
    const position = visiblePosition >= 0 ? visiblePosition : allPosition;

    if (queueId === draggedQueueId) {
      if (dragging) {
        return {
          zIndex: 1,
          x:
            draggedStartPosition !== undefined
              ? calculateQueueXPos(draggedStartPosition)
              : calculateQueueXPos(position),
          immediate: (k: string) => k === "zIndex",
        };
      } else {
        return {
          to: [
            { x: calculateQueueXPos(position) },
            { zIndex: 1, immediate: true },
          ],
        };
      }
    }

    return {
      zIndex: 0,
      x: calculateQueueXPos(position),
      immediate: immediate ? true : (k: string) => k === "zIndex",
    };
  };
}

function generateNewQueueOrder(
  allQueueIdOrder: string[],
  visibleQueueIdOrder: string[],
  allOrderIndex: number,
  visibleOrderIndex: number,
  moveX: number
): [string[], string[]] {
  const newAllQueueIdOrder = allQueueIdOrder.slice();
  const newVisibleQueueIdOrder = visibleQueueIdOrder.slice();
  const visibileQueueXPos = calculateQueueXPos(visibleOrderIndex);
  const queueWidth = QUEUE_GUTTER_WIDTH_PX + QUEUE_WIDTH_PX;
  const newVisibleOrderIndex = clamp(
    Math.round((visibileQueueXPos + moveX) / queueWidth),
    0,
    newVisibleQueueIdOrder.length - 1
  );
  const [movedQueueId] = newVisibleQueueIdOrder.splice(visibleOrderIndex, 1);
  newVisibleQueueIdOrder.splice(newVisibleOrderIndex, 0, movedQueueId);

  const insertAfterQueueId =
    newVisibleOrderIndex === 0
      ? null
      : newVisibleQueueIdOrder[newVisibleOrderIndex - 1];

  newAllQueueIdOrder.splice(allOrderIndex, 1);

  const insertAfterQueueIndex = insertAfterQueueId
    ? newAllQueueIdOrder.indexOf(insertAfterQueueId)
    : -1;

  const newAllOrderIndex = clamp(
    insertAfterQueueIndex + 1,
    0,
    newAllQueueIdOrder.length
  );

  newAllQueueIdOrder.splice(newAllOrderIndex, 0, movedQueueId);

  return [newAllQueueIdOrder, newVisibleQueueIdOrder];
}

const QueuesPage: React.FC = () => {
  const { drop } = useDragDrop();
  const showModal = useShowModal();
  const user = useCurrentUser();
  const workspace = useActiveWorkspace();
  const now = useNow();
  const [postQueuesRefetchInterval, setPostQueuesRefetchInterval] = useState<
    number | false
  >(false);
  const {
    data: workspacePreferences,
    isLoading: isLoadingWorkspacePreferences,
  } = useWorkspaceUserPreferences(user.id, workspace.id);
  const { data: platforms, isLoading: isLoadingPlatforms } = usePlatforms(
    workspace.id
  );
  const { data: postQueues, isLoading: isLoadingPostQueues } = usePostQueues(
    workspace.id,
    {
      refetchInterval: postQueuesRefetchInterval,
    }
  );
  const { data: posts } = useQueuedPosts(workspace.id);
  const { mutateAsync: updateWorkspaceUserPreferences } =
    useUpdateWorkspaceUserPreferences();
  const [platformFilter, setPlatformFilter] = useState<Platform["type"] | null>(
    null
  );
  const [showHidden, setShowHidden] = useState(false);
  const hasPlatformFilterChanged = useHasChanged(platformFilter);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const springIndexToQueueIdRef = useRef<string[]>([]);
  const queueIdToSpringIndexRef = useRef<{ [queueId: string]: number }>({});
  const allQueueIdOrderRef = useRef<string[]>([]);
  const visibleQueueIdOrderRef = useRef<string[]>([]);
  const hasPlatforms = !!platforms && platforms.length > 0;
  const hasPostQueues = !!postQueues && postQueues.length > 0;
  const hasPostsLimit = hasPublishQeueuedPostLimit(workspace);
  const availablePostsCount = getAvailablePublishQueuedPosts(workspace, now);
  const isAtPostLimit = availablePostsCount !== null && availablePostsCount < 1;

  const allQueueData = useMemo(() => {
    if (!platforms || !postQueues || !posts || isLoadingWorkspacePreferences) {
      return [];
    }

    const postQueuesOrder =
      workspacePreferences && workspacePreferences.postQueuesOrder
        ? workspacePreferences.postQueuesOrder
        : [];

    const newQueueData = platforms.reduce<QueueData[]>((carry, platform) => {
      Object.values(platform.entities).forEach((platformEntity) => {
        const postQueue = postQueues.find(
          (candidate) =>
            candidate.platformId === platform.id &&
            candidate.platformEntityId === platformEntity.id
        );

        if (postQueue) {
          const isDisabled = postQueue.status === "DISABLED";

          if (!isDisabled) {
            carry.push({
              platform,
              platformEntity,
              postQueue,
              posts: posts.filter(
                (post) =>
                  post.platformId === platform.id &&
                  post.platformEntityId === platformEntity.id &&
                  post.queueId === postQueue.id
              ),
            });
          }
        }
      });

      return carry;
    }, []);

    newQueueData.sort(buildSortQueueDataFunction(postQueuesOrder));

    const queueIds = newQueueData.map(({ postQueue }) => postQueue.id);
    const haveQueueIdsChanged =
      queueIds.length !== springIndexToQueueIdRef.current.length ||
      queueIds.some(
        (queueId) => queueIdToSpringIndexRef.current[queueId] === undefined
      );

    if (haveQueueIdsChanged) {
      springIndexToQueueIdRef.current = queueIds.map((queueId) => queueId);

      queueIdToSpringIndexRef.current = queueIds.reduce<{
        [queueId: string]: number;
      }>((carry, queueId, index) => {
        carry[queueId] = index;
        return carry;
      }, {});

      allQueueIdOrderRef.current = springIndexToQueueIdRef.current;
    }

    return newQueueData;
  }, [
    isLoadingWorkspacePreferences,
    platforms,
    postQueues,
    posts,
    workspacePreferences,
  ]);

  const filteredQueueData = useMemo(() => {
    const newQueueData = allQueueData.filter(({ platform, postQueue }) => {
      const isMatchingPlatform =
        platformFilter === null || platform.type === platformFilter;
      const isVisible = !postQueue.hidden || showHidden;

      return isMatchingPlatform && isVisible;
    });

    visibleQueueIdOrderRef.current = newQueueData.map(
      ({ postQueue }) => postQueue.id
    );

    return newQueueData;
  }, [allQueueData, platformFilter, showHidden]);

  const [queueSprings, queueSpringsApi] = useSprings<{
    x: number;
    zIndex: number;
  }>(
    allQueueData.length,
    buildQueueSpringsFunction(
      springIndexToQueueIdRef.current,
      allQueueIdOrderRef.current,
      visibleQueueIdOrderRef.current,
      undefined,
      undefined,
      undefined,
      hasPlatformFilterChanged
    ),
    [filteredQueueData.length]
  );

  const transitions = useTransition<QueueData, { opacity: number }>(
    filteredQueueData,
    {
      keys: (item) => item.platformEntity.id,
      from: {
        opacity: 0,
      },
      enter: {
        opacity: 1,
      },
      leave: {
        opacity: 0,
      },
      immediate: hasPlatformFilterChanged,
    }
  );

  const handleDragStart: Parameters<typeof drop>[0]["onStart"] = ({
    itemId,
  }) => {
    const draggedQueueData = filteredQueueData.find(
      (candidate) => candidate.postQueue.id === itemId
    );

    if (!draggedQueueData) {
      return null;
    }

    const allQueueIdOrder = allQueueIdOrderRef.current.slice();
    const visibleQueueIdOrder = visibleQueueIdOrderRef.current.slice();
    const draggedAllOrderIndex = allQueueIdOrder.indexOf(
      draggedQueueData.postQueue.id
    );
    const draggedVisibleOrderIndex = visibleQueueIdOrder.indexOf(
      draggedQueueData.postQueue.id
    );

    return {
      draggedQueueData,
      allQueueIdOrder,
      visibleQueueIdOrder,
      draggedAllOrderIndex,
      draggedVisibleOrderIndex,
      startScrollLeft: scrollContainerRef.current
        ? scrollContainerRef.current.scrollLeft
        : 0,
    };
  };

  const handleDragUpdate: Parameters<typeof drop>[0]["onUpdate"] = ({
    args,
    state,
  }) => {
    if (!args) {
      return;
    }

    const [moveX] = state.movement;
    const {
      draggedQueueData,
      allQueueIdOrder,
      visibleQueueIdOrder,
      draggedAllOrderIndex,
      draggedVisibleOrderIndex,
      startScrollLeft,
    } = args as {
      draggedQueueData: QueueData;
      allQueueIdOrder: string[];
      visibleQueueIdOrder: string[];
      draggedAllOrderIndex: number;
      draggedVisibleOrderIndex: number;
      startScrollLeft: number;
    };

    const currentScrollLeft = scrollContainerRef.current
      ? scrollContainerRef.current.scrollLeft
      : 0;
    const scrollXDiff = currentScrollLeft - startScrollLeft;

    const [newAllQueueIdOrder, newVisibleQueueIdOrder] = generateNewQueueOrder(
      allQueueIdOrder,
      visibleQueueIdOrder,
      draggedAllOrderIndex,
      draggedVisibleOrderIndex,
      moveX + scrollXDiff
    );

    queueSpringsApi.start(
      buildQueueSpringsFunction(
        springIndexToQueueIdRef.current,
        newAllQueueIdOrder,
        newVisibleQueueIdOrder,
        draggedQueueData.postQueue.id,
        draggedVisibleOrderIndex,
        true
      )
    );
  };

  const handleDragEnd: Parameters<typeof drop>[0]["onDrop"] = ({
    args,
    state,
  }) => {
    if (!args) {
      return;
    }

    const [moveX] = state.movement;
    const {
      draggedQueueData,
      allQueueIdOrder,
      visibleQueueIdOrder,
      draggedAllOrderIndex,
      draggedVisibleOrderIndex,
      startScrollLeft,
    } = args as {
      draggedQueueData: QueueData;
      allQueueIdOrder: string[];
      visibleQueueIdOrder: string[];
      draggedAllOrderIndex: number;
      draggedVisibleOrderIndex: number;
      startScrollLeft: number;
    };

    const currentScrollLeft = scrollContainerRef.current
      ? scrollContainerRef.current.scrollLeft
      : 0;
    const scrollXDiff = currentScrollLeft - startScrollLeft;

    const [newAllQueueIdOrder, newVisibleQueueIdOrder] = generateNewQueueOrder(
      allQueueIdOrder,
      visibleQueueIdOrder,
      draggedAllOrderIndex,
      draggedVisibleOrderIndex,
      moveX + scrollXDiff
    );

    queueSpringsApi.start(
      buildQueueSpringsFunction(
        springIndexToQueueIdRef.current,
        newAllQueueIdOrder,
        newVisibleQueueIdOrder,
        draggedQueueData.postQueue.id,
        draggedVisibleOrderIndex,
        false
      )
    );

    allQueueIdOrderRef.current = newAllQueueIdOrder;
    visibleQueueIdOrderRef.current = newVisibleQueueIdOrder;

    updateWorkspaceUserPreferences({
      userId: user.id,
      workspaceId: workspace.id,
      updateProps: {
        postQueuesOrder: newAllQueueIdOrder,
      },
    });
  };

  useEffect(() => {
    if (!isLoadingPlatforms && !isLoadingPostQueues && hasPlatforms) {
      if (hasPostQueues) {
        setPostQueuesRefetchInterval(false);
      } else {
        setPostQueuesRefetchInterval(DEFAULT_POST_QUEUES_REFETCH_INTERVAL);
      }
    }
  }, [hasPlatforms, hasPostQueues, isLoadingPlatforms, isLoadingPostQueues]);

  return (
    <AppPageWithHeader>
      {!isLoadingPostQueues && !hasPostQueues && (
        <div className="pt-20 w-full h-full flex justify-center">
          <div className="relative flex flex-col items-center">
            <div style={{ width: 600 }}>
              <H2>Before we get scheduling</H2>
              <p className="mt-6">
                Add the social media platforms you wish to manage with Seenly
                and after a quick second we'll have your smart schedules up and
                running for you.
              </p>
              <p className="mt-4">
                You can add your first social using the button below or head on
                over to the{" "}
                <InternalLink
                  className="inline-flex items-center"
                  to="/socials"
                >
                  socials page
                </InternalLink>
                .
              </p>
            </div>
            <PrimaryButton
              className="mt-10"
              onClick={() => showModal("addPlatform", {})}
            >
              <PlusIcon className="mr-2" />
              Add your first social account now
            </PrimaryButton>
            <NoPostQueuesImage
              className="mt-12 flex-shrink-0 text-purple-500"
              style={{ width: 500, height: 500 }}
            />
          </div>
        </div>
      )}
      {!isLoadingPostQueues && hasPostQueues && (
        <div className="h-full w-full flex flex-col overflow-hidden">
          <div className="px-4 pt-4 flex-shrink-0 flex items-center flex-wrap">
            <PrimaryButton
              id="create-post-smart-schedule-button"
              onClick={() =>
                isAtPostLimit
                  ? showModal("changeSubscription", {
                      workspace,
                      limitExceeded: true,
                      redirectOnSuccess: false,
                    })
                  : showModal("createPost", {
                      defaultValues: { status: "QUEUED" },
                    })
              }
            >
              <PlusIcon className="shrink-0 h-6 w-6 mr-2" />
              Create content
            </PrimaryButton>

            {hasPostsLimit && (
              <Tooltip
                className="ml-4"
                content={
                  <div className="w-48">
                    <p>
                      The remaining number of posts that can be published by the
                      smart schedules.
                    </p>
                    <p className="mt-2">
                      The quota resets at the end of each calendar month.
                    </p>
                  </div>
                }
              >
                <span
                  className={
                    availablePostsCount !== null && availablePostsCount > 0
                      ? "text-gray-500"
                      : "text-red-500"
                  }
                >{`${availablePostsCount} ${
                  availablePostsCount === 1 ? "post" : "posts"
                } remaining`}</span>
              </Tooltip>
            )}
            {hasPostsLimit && (
              <UpgradeSubscriptionButton
                className="ml-2"
                workspace={workspace}
                context="smartSchedules"
              />
            )}

            <div className="ml-auto flex items-center">
              <SecondaryButton
                className="py-2 text-gray-600"
                onClick={() => showModal("explainSmartSchedules")}
              >
                <HelpCircleIcon className="mr-2 h-6 w-6" />
                How does this work?
              </SecondaryButton>

              <PageActionButton
                className="ml-2"
                onClick={() => setShowHidden(!showHidden)}
              >
                {showHidden ? (
                  <EyeOffIcon className="mr-2 h-6 w-6" />
                ) : (
                  <EyeIcon className="mr-2 h-6 w-6" />
                )}
                {showHidden ? "Hide hidden" : "Show hidden"}
              </PageActionButton>

              <Select
                ButtonElement={PageActionButton}
                toggleButtonClassName="ml-2 font-bold"
                labelText="Filter accounts"
                hideLabel={true}
                hideDropdownIcon={false}
                dropdownXAlign="right"
                value={platformFilter || ""}
                options={FILTER_OPTIONS.map((filterOption) => ({
                  text:
                    filterOption === null
                      ? "All"
                      : getPlatformTypeName(filterOption as Platform["type"]),
                  value: filterOption || "",
                }))}
                onChange={(newFilter) => {
                  setPlatformFilter(
                    (newFilter || null) as Platform["type"] | null
                  );
                }}
                renderSelectedOption={(selectedOption) => {
                  return (
                    <div className="flex items-center w-full">
                      <FilterIcon className="flex-shrink-0 h-6 w-6" />
                      <span className="mx-2 truncate">
                        {selectedOption ? selectedOption.text : ""}
                      </span>
                      <ChevronDownIcon className="flex-shrink-0 h-5 w-5" />
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <DragAutoScroll
            ref={scrollContainerRef}
            id="smartSchedulesPageAutoScroll"
            className="py-4 flex-grow overflow-y-hidden"
            direction="horizontal"
            accept={[QUEUE_DRAG_TYPE]}
          >
            <div
              ref={drop({
                dropId: "SMART_SCHEDULES_PAGE",
                accept: [QUEUE_DRAG_TYPE],
                onStart: handleDragStart,
                onUpdate: handleDragUpdate,
                onDrop: handleDragEnd,
              })}
              className="h-full relative"
              style={{
                width: `${
                  QUEUE_GUTTER_WIDTH_PX +
                  filteredQueueData.length *
                    (QUEUE_GUTTER_WIDTH_PX + QUEUE_WIDTH_PX)
                }px`,
              }}
            >
              {transitions(
                (
                  style,
                  { platform, platformEntity, postQueue, posts },
                  transition,
                  index
                ) => {
                  const springIndex =
                    queueIdToSpringIndexRef.current[postQueue.id];
                  const spring =
                    springIndex >= 0 ? queueSprings[springIndex] : null;

                  return (
                    <animated.div
                      className="absolute top-0 left-0 h-full"
                      style={{
                        width: QUEUE_WIDTH_PX,
                        transform: spring
                          ? spring.x.to((x) => `translate3d(${x}px, 0 ,0)`)
                          : `translate3d(${calculateQueueXPos(index)}px, 0 ,0)`,
                        opacity: style.opacity,
                        zIndex: spring ? spring.zIndex : 0,
                      }}
                    >
                      <Queue
                        className="w-full"
                        platform={platform}
                        platformEntity={platformEntity}
                        postQueue={postQueue}
                        posts={posts}
                        scrollContainerRef={scrollContainerRef}
                      />
                    </animated.div>
                  );
                }
              )}
            </div>
          </DragAutoScroll>
        </div>
      )}
    </AppPageWithHeader>
  );
};

export default QueuesPage;
